import Breadcrumbs from "components/Common/Breadcrumb";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import Search from "components/Common/Search";
import {
  Colors,
  ENTITIES,
  isInternal,
  SELECT_STYLES,
  TOAST_OPTIONS,
  UPLOAD_TYPES,
} from "common";
import {
  filterPermittedEntities,
  formatBytes,
  // getCurrentFileTree,
  getFileIcon,
} from "common/utils";
import Dropzone from "react-dropzone";
import UploadFileModal from "./UploadFileModal";
import CreateFolderModal from "./CreateFolderModal";
import {
  createFolder,
  createReferencedFiles,
  deleteFile,
  downloadFile,
  getReferencedFiles,
  uploadFiles,
} from "store/actions";
import { connect } from "react-redux";
import DocumentsTable from "components/Common/DocumentsTable";
import { toast } from "react-toastify";
import DeleteModal from "components/Common/DeleteModal";
import DeleteIcon from "components/Common/DeleteIcon";

class FileList extends Component {
  constructor(props) {
    super(props);
    let createEntities = filterPermittedEntities(props.permissions, "create");
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    this.state = {
      files: props.files,
      expanded: [],
      viewType: { label: this.props.t("List View"), value: "list" },
      searchText: "",
      modal: false,
      folderModal: false,
      filteredData: props.files,
      inFolder: false,
      isDragging: false,
      isDocumentCreateUpdate:
        createEntities.includes(ENTITIES.DOCUMENT) &&
        updateEntities.includes(ENTITIES.DOCUMENT),
      acceptedFiles: [],
      selectedExistingFile: null,
      uploadType: null,
      showDeleteModal: false,
      fileToBeDeleted: null,
    };
    this.toggleDropzoneDrag = this.toggleDropzoneDrag.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreateFolder = this.handleCreateFolder.bind(this);
    this.toggleFolder = this.toggleFolder.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
  }

  toggleDropzoneDrag(val) {
    if (
      val === this.state.isDragging ||
      this.state.modal ||
      this.state.folderModal
    ) {
      return;
    }
    this.setState({ isDragging: val });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  toggleFolder() {
    this.setState({ folderModal: !this.state.folderModal });
  }

  handleSubmit(values) {
    const {
      path,
      payload: entityPayload,
      onUploadFiles,
      handleGetRelatedFiles,
      showFilesOnly,
      onCreateReferencedFile,
      onGetReferencedFiles,
    } = this.props;
    this.toggle();
    this.setState({ uploadType: values.uploadType });
    if (this.props.isComments) {
      let file = null;
      if (values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER) {
        file = this.state.acceptedFiles[0];
      } else {
        file = values.selectedFile;
      }
      this.props.setFile(file, values);
      return;
    }
    if (values.uploadType.value === UPLOAD_TYPES.FROM_PORTAL) {
      let _payload = {
        ...entityPayload,
        idDocument: values.selectedFile.idDocument,
      };
      onCreateReferencedFile(_payload, () => {
        onGetReferencedFiles(entityPayload);
      });
      return;
    }
    const updates = this.state.files
      .filter(_file => {
        return this.state.acceptedFiles.some(
          _acceptedFile => _acceptedFile.name === _file.fileName
        );
      })
      .map(_item => ({ id: _item.idDocument, name: _item.fileName }));

    let _path = [...JSON.parse(JSON.stringify(path))];
    _path[1] = entityPayload.idEntity;
    let parent = "00000000-0000-0000-0000-000000000000";
    if (_path.length > 2) {
      let parentObj = this.state.files.find(
        file => file.fileName === _path[_path.length - 1]
      );
      parent = parentObj.idDocument;
    }
    const payload = {
      request: {
        updates,
        allowExternalUsers: values.isExternalAllowed,
        allowDownloads: values.isDownloadAllowed,
        allowComments: values.isCommentsAllowed,
        createReference: !!this.props.showUploadType,
        path: _path.join("/").toLowerCase(),
        parent,
      },
      files: this.state.acceptedFiles,
    };
    onUploadFiles(payload, () => {
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("File")),
        TOAST_OPTIONS
      );
      !showFilesOnly && handleGetRelatedFiles(entityPayload);
    });
  }

  handleCreateFolder(values) {
    const {
      onCreateFolder,
      path,
      payload: entityPayload,
      showFilesOnly,
      handleGetRelatedFiles,
    } = this.props;
    let _path = [...JSON.parse(JSON.stringify(path))];
    let parent = "00000000-0000-0000-0000-000000000000";
    _path[1] = entityPayload.idEntity;
    if (_path.length > 2) {
      let parentObj = this.state.files.find(
        file => file.fileName === _path[_path.length - 1]
      );
      parent = parentObj.idDocument;
    }
    const payload = {
      allowExternalUsers: true,
      path: _path.join("/").toLowerCase() + `/${values.name}`,
      parent,
    };
    onCreateFolder(payload, () => {
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Folder")),
        TOAST_OPTIONS
      );
      !showFilesOnly && handleGetRelatedFiles(entityPayload);
    });
  }

  handleFolderClick(file) {
    if (file.isFolder) {
      this.setState({ inFolder: true });
      let path = `${this.props.path.join("/")}/${file.fileName}`;
      this.props.handleTabClick(path);

      if (this.state.viewType?.value == "tile") {
        let folderFiles = [];
        for (const item of this.state.filteredData) {
          if (item.parentDocument) {
            if (item.parentDocument.id === file.idDocument) {
              folderFiles.push(item);
            }
          }
        }

        this.setState({ filteredData: folderFiles });
      }
    } else if (file.isDownloadAllowed) {
      this.setState({ inFolder: false });
      if (isInternal) {
        window.open(file.sharePointURL, "_blank").focus();
      } else {
        if (file.isDownloadAllowed) {
          this.props.download({
            id: file.idDocument,
            fileName: file.fileName,
          });
        }
      }
    }
  }

  async handleDeleteFile() {
    const { onDeleteFile } = this.props;
    try {
      this.props.onDeleteFile();

      const requestObject = {
        request: this.state.fileToBeDeleted.idDocument,
        basicParameters: {
          entity: ENTITIES.DOCUMENT,
        },
      };

      await onDeleteFile(requestObject, () => {
        toast.success(
          this.props.t("SuccessDelete").replace("*", this.props.t("File")),
          TOAST_OPTIONS
        );
        this.setState({
          showDeleteModal: false,
          fileToBeDeleted: null,
        });
        setTimeout(() => {
          window.location.href = "/document-management";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (this.props.uploadButtonOnly) {
      return this.state.isDocumentCreateUpdate ? (
        <div
          style={{
            backgroundColor: Colors.searchBG,
            borderRadius: 10,
            ...(!!this.props.uploadButtonStyle
              ? this.props.uploadButtonStyle
              : {}),
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{
              backgroundColor: "transparent",
              border: "0px",
              paddingLeft: 0,
              paddingRight: 0,
              paddingInlineStart: 10,
              paddingInlineEnd: 10,
            }}
            onClick={this.toggle}
          >
            <i
              className={`${
                this.props.icon
                  ? this.props.icon
                  : "mdi mdi-attachment fa-rotate-90"
              } d-block font-size-18`}
              style={{ color: Colors.grey }}
            ></i>
          </button>
          <UploadFileModal
            modal={this.state.modal}
            toggle={this.toggle}
            handleSubmit={this.handleSubmit}
            isEdit={false}
            files={this.state.acceptedFiles}
            onFilesAccepted={acceptedFiles => {
              this.setState({
                acceptedFiles: acceptedFiles,
              });
            }}
            path={this.props.path}
            showUploadType={!!this.props.showUploadType}
            isComments={this.props.isComments}
          />
        </div>
      ) : (
        <></>
      );
    }

    return (
      <Dropzone
        accept=".pdf, image/*, .xlsx, .xls, .csv, .docx, .pptx"
        multiple={true}
        onDragEnter={() => this.toggleDropzoneDrag(true)}
        onDragLeave={() => this.toggleDropzoneDrag(false)}
        onDrop={() => this.toggleDropzoneDrag(false)}
        noDrag={this.props.showUploadType}
        onDropAccepted={files => {
          let counter = 0;
          let acceptedFiles = files
            .map((file, index) => {
              let _otherFiles = JSON.parse(JSON.stringify([...files]));
              _otherFiles.splice(index, 1);
              let otherFiles = [...this.state.acceptedFiles, ..._otherFiles];
              let fileExists = otherFiles.some(item => item.path === file.path);
              if (fileExists) {
                return false;
              }
              return Object.assign(file, {
                id:
                  this.state.acceptedFiles.length > 0
                    ? this.state.acceptedFiles[
                        this.state.acceptedFiles.length - 1
                      ].id +
                      1 +
                      index
                    : counter++,
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
              });
            })
            .filter(item => !!item);
          this.setState(
            { acceptedFiles: [...this.state.acceptedFiles, ...acceptedFiles] },
            () => {
              if (!this.state.modal) {
                this.toggle();
              }
            }
          );
        }}
        onDropRejected={() => {
          alert(
            "Please select on of the supported file types.\n.pdf, image/*, .xlsx, .xls, .csv, .docx, .pptx"
          );
        }}
      >
        {({ getRootProps }) => (
          <div
            className={classnames({
              dropzone: true,
            })}
            style={{ border: 0, margin: 20 }}
            {...getRootProps()}
          >
            <div
              className={classnames({
                "is-dragging":
                  this.state.isDragging &&
                  !this.state.modal &&
                  !this.state.folderModal,
              })}
            />
            <div
              className="scrollbar"
              style={{
                maxHeight: this.props.height ? this.props.height : "90vh",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <div style={{ marginInlineEnd: 10 }}>
                <Row
                  className="mb-1"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col xl={9}>
                    {this.props.path[0] === "organizations" ||
                    this.props.path[0] === "Organizations" ? (
                      <h4>{this.props.t("OrganizationDocuments")}</h4>
                    ) : this.props.path[0] === "Projects" ? (
                      <h4>{this.props.t("ProjectDocuments")}</h4>
                    ) : (
                      <Breadcrumbs
                        breadcrumbItems={this.props.path}
                        onBreadcrumbClick={this.props.onBreadcrumbClick}
                        isFromDocuments
                      />
                    )}
                  </Col>
                  {!this.props.showUploadType && (
                    <Col xl={3}>
                      <Select
                        styles={SELECT_STYLES}
                        value={this.state.viewType}
                        onChange={value => {
                          this.setState({ viewType: value });
                        }}
                        options={[
                          { label: this.props.t("List View"), value: "list" },
                          { label: this.props.t("Title View"), value: "tile" },
                        ]}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col
                    xl={this.props.showUploadType ? 12 : 8}
                    style={{
                      paddingInlineEnd: this.props.showUploadType ? 0 : 12,
                    }}
                  >
                    <Search
                      value={this.state.searchText}
                      onChange={e => {
                        let searchText = e.target.value;
                        let filteredData = [...this.state.files];
                        filteredData = filteredData.filter(item =>
                          JSON.stringify(item)
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        );
                        this.setState({
                          searchText: e.target.value,
                          filteredData,
                        });
                      }}
                      placeholder={this.props.t("Search Files")}
                      containerStyle={{
                        padding: 20,
                        paddingInlineStart: 0,
                        paddingInlineEnd: 0,
                        backgroundColor: Colors.white,
                        width: "100%",
                      }}
                      style={{
                        backgroundColor: Colors.searchBG,
                        border: 0,
                        padding: 10,
                      }}
                      showIcon
                    />
                  </Col>
                  {this.state.isDocumentCreateUpdate &&
                    !this.props.showUploadType && (
                      <Col
                        xl={4}
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "flex-end",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="text-sm-end"
                            style={{ marginInlineEnd: 10 }}
                          >
                            <Button
                              className="font-16 customTable-button text-nowrap create-folder-btn"
                              onClick={this.toggleFolder}
                            >
                              {this.props.t("Create Folder")}
                            </Button>
                          </div>
                          <div className="text-sm-end">
                            <Button
                              className="font-16 customTable-button text-nowrap upload-btn"
                              onClick={this.toggle}
                            >
                              {this.props.t("Upload")}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    )}
                </Row>
              </div>
              {this.state.viewType.value === "list" ? (
                <DocumentsTable
                  data={this.state.filteredData}
                  handleFolderClick={this.props.handleTabClick}
                  path={this.props.path}
                  onRowClick={row => {
                    if (this.props.showUploadType) {
                      this.setState({ selectedExistingFile: row });
                      this.props.onRowClick(row);
                    }
                  }}
                  showUploadType={this.props.showUploadType}
                  selectedExistingFile={this.state.selectedExistingFile}
                  onDelete={file => {
                    this.setState({
                      showDeleteModal: true,
                      fileToBeDeleted: file,
                    });
                  }}
                />
              ) : (
                <div
                  className="scrollbar"
                  style={{
                    height: "85%",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Row>
                    {this.state.inFolder &&
                      this.state.filteredData.map((file, key) => {
                        console.log("file.isFolder ====>", file.isFolder);
                        return (
                          <Col
                            xl={4}
                            sm={6}
                            key={key}
                            onClick={() => this.handleFolderClick(file)}
                          >
                            <div className="file-tile-view">
                              <Card className="shadow-none border">
                                <CardBody>
                                  <div className="">
                                    <div className="avatar-xs me-3 mb-3">
                                      <div className="avatar-title bg-transparent rounded">
                                        {file.isFolder ? (
                                          <i className="bx bxs-folder text-warning font-size-20"></i>
                                        ) : (
                                          <i
                                            className={getFileIcon(
                                              file.fileName
                                            )}
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-14 text-truncate mb-1 word-wrap-word-break">
                                          <Link to="#" className="text-body">
                                            {file.fileName}
                                          </Link>
                                        </h5>
                                      </div>
                                      <div className="align-self-end ms-2">
                                        {!file.isFolder && (
                                          <p className="text-muted mb-0 text-nowrap">
                                            {formatBytes(file.fileSize)}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </Col>
                        );
                      })}
                    {!this.state.inFolder &&
                      this.state.filteredData.map((file, key) => {
                        if (!file.parentDocument) {
                          return (
                            <Col
                              xl={4}
                              sm={6}
                              key={key}
                              onClick={() => this.handleFolderClick(file)}
                            >
                              <div className="file-tile-view">
                                <Card className="shadow-none border">
                                  <CardBody>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                        className=" mb-3"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title bg-transparent rounded">
                                            {file.isFolder ? (
                                              <i className="bx bxs-folder text-warning font-size-20"></i>
                                            ) : (
                                              <i
                                                className={getFileIcon(
                                                  file.fileName
                                                )}
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                        {file.canDelete && (
                                          <DeleteIcon
                                            onClick={() => {
                                              this.setState({
                                                showDeleteModal: true,
                                                fileToBeDeleted: file,
                                              });
                                            }}
                                            style={{
                                              backgroundColor: Colors.searchBG,
                                              borderRadius: 10,
                                            }}
                                          />
                                        )}
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="overflow-hidden">
                                          <h5 className="font-size-14 text-truncate mb-1 word-wrap-word-break">
                                            <Link to="#" className="text-body">
                                              {file.fileName}
                                            </Link>
                                          </h5>
                                        </div>
                                        <div className="align-self-end ms-2">
                                          {!file.isFolder && (
                                            <p className="text-muted mb-0 text-nowrap">
                                              {formatBytes(file.fileSize)}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            </Col>
                          );
                        }
                      })}
                  </Row>
                </div>
              )}
            </div>
            {!!!this.props.showUploadType && (
              <UploadFileModal
                modal={this.state.modal}
                toggle={this.toggle}
                handleSubmit={this.handleSubmit}
                isEdit={false}
                files={this.state.acceptedFiles}
                onFilesAccepted={acceptedFiles => {
                  this.setState({
                    acceptedFiles: acceptedFiles,
                  });
                }}
                path={this.props.path}
              />
            )}
            <DeleteModal
              onCloseClick={() => this.setState({ showDeleteModal: false })}
              onDeleteClick={this.handleDeleteFile}
              show={this.state.showDeleteModal}
            />
            <CreateFolderModal
              modal={this.state.folderModal}
              toggle={this.toggleFolder}
              handleSubmit={this.handleCreateFolder}
            />
          </div>
        )}
      </Dropzone>
    );
  }
}

FileList.propTypes = {
  path: PropTypes.array,
  onBreadcrumbClick: PropTypes.func,
  t: PropTypes.any,
  files: PropTypes.array,
  payload: PropTypes.any,
  handleGetRelatedFiles: PropTypes.func,
  onUploadFiles: PropTypes.func,
  handleTabClick: PropTypes.func,
  height: PropTypes.string,
  showFilesOnly: PropTypes.bool,
  uploadButtonOnly: PropTypes.bool,
  permissions: PropTypes.any,
  showUploadType: PropTypes.bool,
  onRowClick: PropTypes.func,
  icon: PropTypes.string,
  isComments: PropTypes.bool,
  setFile: PropTypes.func,
  onCreateReferencedFile: PropTypes.func,
  onGetReferencedFiles: PropTypes.func,
  download: PropTypes.func,
  onCreateFolder: PropTypes.func,
  uploadButtonStyle: PropTypes.any,
  onDeleteFile: PropTypes.func,
};

const mapStateToProps = ({ Layout }) => ({
  permissions: Layout.permissions,
});
const mapDispatchToProps = dispatch => ({
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onCreateReferencedFile: (payload, callback) =>
    dispatch(createReferencedFiles(payload, callback)),
  onGetReferencedFiles: (payload, callback) =>
    dispatch(getReferencedFiles(payload, callback)),
  download: payload => dispatch(downloadFile(payload)),
  onCreateFolder: (payload, callback) =>
    dispatch(createFolder(payload, callback)),
  onDeleteFile: (payload, callback) => dispatch(deleteFile(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FileList));
