import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_EXTERNAL_DETAILS,
  GET_INTERNAL_DETAILS,
  REGISTER,
  RESET_PASSWORD,
} from "./actionTypes";
import {
  profileSuccess,
  profileError,
  registerSuccess,
  registerFail,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordSuccess,
  resetPasswordFail,
  changePasswordSuccess,
  changePasswordFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getInternalDetailsCall,
  getExternalDetailsCall,
  registerUser,
  forgotPassword,
  resetPassword,
  changePassword,
} from "../../../helpers/backend_helper";
import { getGenericError } from "common";

function* getInternalUserDetails({ callback }) {
  try {
    const response = yield call(getInternalDetailsCall);
    yield put(profileSuccess({ ...response.responseObject }));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        profileError(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(profileError(error.message));
    }
  }
}

function* getExternalUserDetails({ callback }) {
  try {
    const response = yield call(getExternalDetailsCall);
    yield put(profileSuccess({ ...response.responseObject }));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        profileError(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(profileError(error.message));
    }
  }
}

function* registerUserSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(registerUser, body);
    yield put(registerSuccess({ ...response.responseObject }));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        registerFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(registerFail(error.message));
    }
  }
}

function* forgotPasswordSaga({ payload, callback }) {
  try {
    let body = {
      email: payload.email,
      token: payload.token
    };
    const response = yield call(forgotPassword, body);
    yield put(forgotPasswordSuccess({ ...response.responseObject }));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        forgotPasswordFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(forgotPasswordFail(error.message));
    }
  }
}

function* resetPasswordSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(resetPassword, body);
    yield put(resetPasswordSuccess({ ...response.responseObject }));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        resetPasswordFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(resetPasswordFail(error.message));
    }
  }
}

function* changePasswordSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(changePassword, body);
    yield put(changePasswordSuccess({ ...response.responseObject }));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        changePasswordFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(changePasswordFail(error.message));
    }
  }
}
export function* watchProfile() {
  yield takeEvery(GET_INTERNAL_DETAILS, getInternalUserDetails);
  yield takeEvery(GET_EXTERNAL_DETAILS, getExternalUserDetails);
  yield takeEvery(REGISTER, registerUserSaga);
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
