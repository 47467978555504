import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  Image,
  View,
  Text,
} from "@react-pdf/renderer";
import {
  Table,
  TableCell,
  TableBody,
  TableHeader,
  DataTableCell,
  //   DataTableCell,
} from "@david.kucsai/react-pdf-table";
import { arabicRegex, Colors, CURRENT_LANG } from "common";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import BukraFont from "../../assets/fonts/bukra.ttf";

const BASE_ARRAY = [
  {
    firstName: "John",
    lastName: "Smith",
    dob: new Date(2000, 1, 1),
    country: "Australia",
    phoneNumber: "xxx-0000-0000",
  },
];

const renderList = (array = []) => {
  if (CURRENT_LANG === "en") {
    return array;
  } else {
    return array.reverse();
  }
};

const formatContentDescription = description => {
  let tempDescription = [];
  let isFirstArabicWord = true;
  description
    ?.replace(/<br\s*[\/]?>/gi, "\n")
    ?.split("\n")
    ?.forEach(element => {
      if (arabicRegex.test(element)) {
        isFirstArabicWord
          ? (tempDescription.push("ا" + element + " "),
            (isFirstArabicWord = false))
          : tempDescription.push(" " + element + " ");
      } else {
        tempDescription.push(element);
      }
    });

  tempDescription = tempDescription?.join(" \n ");
  return tempDescription;
};

const AppointmentDetailsPDF = ({ appointmentDetails, t }) => {
  let minutesOfMeeting = [];
  let isFirstArabicWord = true;
  appointmentDetails?.minutesOfMeeting?.split("\n")?.forEach(element => {
    if (arabicRegex.test(element)) {
      isFirstArabicWord
        ? (minutesOfMeeting.push("ا" + element + " "),
          (isFirstArabicWord = false))
        : minutesOfMeeting.push(" " + element + " ");
    } else {
      minutesOfMeeting.push(element);
    }
  });

  minutesOfMeeting = minutesOfMeeting?.join(" \n ");

  return (
    <Document>
      <Page style={styles.container}>
        <Image src="/sta-logo2.png" style={styles.image} />
        <View style={styles.tableContainer}>
          <Table data={BASE_ARRAY} style={{ border: "1px solid green" }}>
            <TableHeader>
              <TableCell style={[styles.cell, styles.header]}>
                {t("PDF_MeetingInformation")}
              </TableCell>
            </TableHeader>
            <TableBody>
              <View style={[styles.column]}>
                <View style={[styles.row, styles.border]}>
                  <View style={[styles.cell, styles.labelCell]}>
                    <Text>{t("PDF_Subject")}</Text>
                  </View>
                  <View style={[styles.cell, styles.valueCell]}>
                    <Text>{appointmentDetails?.subject || ""}</Text>
                  </View>
                </View>
                <View style={[styles.row, styles.border]}>
                  <View style={[styles.cell, styles.labelCell]}>
                    <Text>{t("PDF_Location")}</Text>
                  </View>
                  <View style={[styles.cell, styles.valueCell]}>
                    <Text>{appointmentDetails?.location || ""}</Text>
                  </View>
                </View>
                <View style={[styles.row, styles.border]}>
                  <View style={styles.row}>
                    <View style={[styles.cell, styles.labelCell]}>
                      <Text>{t("PDF_Date")}</Text>
                    </View>
                    <View style={[styles.cell, styles.valueCell]}>
                      <Text>
                        {moment(appointmentDetails?.startAt || "")
                          .locale("en")
                          .format("DD/MM/YYYY")}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.cell, styles.labelCell]}>
                      <Text>{t("PDF_Time")}</Text>
                    </View>
                    <View style={[styles.cell, styles.valueCell]}>
                      <Text>
                        {moment(appointmentDetails?.startAt || "").format(
                          "hh:mma"
                        )}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={[styles.row, styles.border]}>
                  <View style={[styles.cell, styles.labelCell]}>
                    <Text>{t("PDF_MeetingOrganizer")}</Text>
                  </View>
                  <View style={[styles.cell, styles.valueCell]}>
                    <Text>{appointmentDetails?.organizer?.label || ""}</Text>
                  </View>
                </View>
              </View>
            </TableBody>
          </Table>

          <View style={styles.margin} />
          <Table data={appointmentDetails?.agendas || []}>
            <TableHeader>
              <View style={styles.row}>
                <TableCell style={[styles.cell, styles.header, styles.number]}>
                  #
                </TableCell>

                <TableCell style={[styles.cell, styles.header]}>
                  {t("PDF_MeetingAgenda")}
                </TableCell>
              </View>
            </TableHeader>
            <TableBody>
              {React.Children.toArray(
                renderList([
                  <DataTableCell
                    key={1}
                    style={[styles.cell, styles.valueCell, styles.number]}
                    getContent={r => r?.idx || "0"}
                  />,
                  <DataTableCell
                    key={2}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r => r?.title || ""}
                  />,
                  <DataTableCell
                    key={3}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r =>
                      formatContentDescription(r?.description) || ""
                    }
                  />,
                ])
              )}
            </TableBody>
          </Table>

          <View style={styles.margin} />
          <Table data={BASE_ARRAY}>
            <TableHeader>
              <TableCell style={[styles.cell, styles.header]}>
                {t("PDF_PointsDiscussed")}
              </TableCell>
            </TableHeader>
            <TableBody>
              {!!minutesOfMeeting?.length && (
                <DataTableCell
                  style={[styles.cell, styles.valueCell]}
                  getContent={r => minutesOfMeeting}
                />
              )}
            </TableBody>
          </Table>

          <View style={styles.margin} />
          <Table data={appointmentDetails.tasks}>
            <TableHeader>
              <View style={styles.row}>
                <TableCell style={[styles.cell, styles.header, styles.number]}>
                  #
                </TableCell>

                <TableCell style={[styles.cell, styles.header]}>
                  {t("PDF_ActionItems")}
                </TableCell>

                <TableCell style={[styles.cell, styles.header]}>
                  {t("PDF_Responsiblity")}
                </TableCell>

                <TableCell style={[styles.cell, styles.header]}>
                  {t("PDF_CompletionDate")}
                </TableCell>
              </View>
            </TableHeader>
            <TableBody>
              {React.Children.toArray(
                renderList([
                  <DataTableCell
                    key={1}
                    style={[styles.cell, styles.valueCell, styles.number]}
                    getContent={r => r?.idx || "0"}
                  />,
                  <DataTableCell
                    key={2}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r => r?.title || ""}
                  />,
                  <DataTableCell
                    key={3}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r => r?.assignedTo?.name || ""}
                  />,
                  <DataTableCell
                    key={4}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r =>
                      moment(r?.dueDate || "")
                        .locale("en")
                        .format("DD/MM/YYYY")
                    }
                  />,
                ])
              )}
            </TableBody>
          </Table>

          <View style={styles.margin} />
          <Table data={appointmentDetails?.attendees || []}>
            <TableHeader>
              <View style={styles.column}>
                <View style={styles.row}>
                  <TableCell style={[styles.cell, styles.header]}>
                    {t("PDF_Attendees")}
                  </TableCell>
                </View>
                <View style={styles.row}>
                  <TableCell
                    style={[styles.cell, styles.labelCell, styles.number]}
                  >
                    #
                  </TableCell>

                  <TableCell style={[styles.cell, styles.labelCell]}>
                    {t("PDF_Name")}
                  </TableCell>

                  <TableCell style={[styles.cell, styles.labelCell]}>
                    {t("PDF_Entity")}
                  </TableCell>
                </View>
              </View>
            </TableHeader>
            <TableBody>
              {React.Children.toArray(
                renderList([
                  <DataTableCell
                    key={1}
                    style={[styles.cell, styles.valueCell, styles.number]}
                    getContent={r => r?.idx || "0"}
                  />,
                  <DataTableCell
                    key={2}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r => r?.label || ""}
                  />,
                  <DataTableCell
                    key={3}
                    style={[styles.cell, styles.valueCell]}
                    getContent={r =>
                      (CURRENT_LANG === "en"
                        ? r?.organization?.label
                        : r?.organization?.labelAr) ?? ""
                    }
                  />,
                ])
              )}
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  );
};

AppointmentDetailsPDF.propTypes = {
  appointmentDetails: PropTypes.any,
  t: PropTypes.any,
};

const font = {
  family: "Bukra",
  fonts: [{ src: BukraFont }],
};

Font.register(font);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  image: {
    width: 120,
    marginLeft: 55,
    marginTop: 55,
    marginRight: 55,
    marginBottom: 30,
  },
  header: {
    backgroundColor: Colors.staGreen,
    color: Colors.white,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tableContainer: {
    paddingHorizontal: 50,
  },
  cell: {
    padding: 5,
    textAlign: "center",
    fontSize: 12,
    justifyContent: "center",
    fontFamily: "Bukra",
  },
  labelCell: {
    color: Colors.black,
    backgroundColor: Colors.lightGrey,
    width: "30%",
  },
  valueCell: {
    color: Colors.black,
    width: "80%",
    flexWrap: "wrap",
    fontSize: 11,
  },
  row: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: CURRENT_LANG === "en" ? "row" : "row-reverse",
  },
  column: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    flex: 1,
  },
  number: {
    width: "10%",
    flex: 0.1,
  },
  margin: {
    height: 10,
  },
  border: {
    border: "0.5px solid black",
  },
});

export default withTranslation()(AppointmentDetailsPDF);
