import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

class RadialChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 590,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "10%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: props.colors,
        labels: props.labels,
        legend: {
          show: true,
          floating: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          fontSize: "16px",
          position: "left",
          offsetX: 65,
          offsetY: 8,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
            width: 0,
            height: 0,
          },
          formatter: function (seriesName, opts) {
            return opts.w.globals.series[opts.seriesIndex] + " | " + seriesName;
          },
          itemMargin: {
            vertical: 0,
          },
        },
      },
      series: props.series,
    };
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height="290"
        />
      </React.Fragment>
    );
  }
}

RadialChart.propTypes = {
  series: PropTypes.any,
  colors: PropTypes.any,
  labels: PropTypes.any,
};

export default RadialChart;
