import React from "react";
import { Colors } from "common";

const DeleteIcon = ({
  onClick,
  className,
  style,
}: {
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <button
      type="button"
      className="btn btn-primary"
      style={{
        backgroundColor: "transparent",
        border: "0px",
        paddingLeft: 0,
        paddingRight: 0,
        paddingInlineStart: 10,
        paddingInlineEnd: 10,
        ...(!!style ? style : {}),
      }}
      onClick={onClick}
    >
      <i
        className={`mdi mdi-trash-can-outline ${
          !!className ? className : "d-block font-size-18"
        }`}
        style={{ color: Colors.grey }}
      ></i>
    </button>
  );
};

export default DeleteIcon;
