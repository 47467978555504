import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { CardTitle } from "reactstrap";
import PropTypes from "prop-types";
import { randomColor } from "common/utils";
import { Colors } from "common";
import { withTranslation } from "react-i18next";

class GanttChart extends Component {
  constructor(props) {
    super(props);
    let data = props.streams;
    let obj = {};
    data = data.map(item => {
      let name = item.name?.trim();
      if (name in obj) {
        return {
          ...item,
          name: `${name} (${obj[name] + 1})`,
        };
      } else {
        obj[name] = 0;
        return {
          ...item,
        };
      }
    });
    this.state = {
      series: [
        {
          data: data.map(item => ({
            x: item.name,
            y: [
              new Date(item.startDate).getTime(),
              new Date(item.endDate).getTime(),
            ],
            fillColor: randomColor(),
          })),
        },
      ],
      options: {
        annotations: {
          xaxis: [
            {
              x: new Date().getTime(),
              borderColor: Colors.blue,
              label: {
                borderColor: Colors.blue,
                style: {
                  color: "#fff",
                  background: Colors.blue,
                },
                text: "",
              },
            },
          ],
        },
        chart: {
          height: 322,
          type: "rangeBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            dataLabels: {
              hideOverflowingLabels: true,
            },
            borderRadius: 20,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            var label = opts.w.globals.labels[opts.dataPointIndex];
            var a = moment(val[0]);
            var b = moment(val[1]);
            var diff = b.diff(a, "days");
            return label + ": " + diff + (diff > 1 ? " days" : " day");
          },
          style: {
            colors: ["#fff", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: true,
            borderType: "dotted",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: true,
          row: {
            colors: ["#fff", "#fff"],
            opacity: 0.1,
          },
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <CardTitle className="mb-0">{this.props.t("Project Roadmap")}</CardTitle>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          title={"test"}
          type="rangeBar"
          height={322}
        />
      </React.Fragment>
    );
  }
}

GanttChart.propTypes = {
  streams: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(GanttChart);
