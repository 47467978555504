import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CardBody, Col, Row } from "reactstrap";
import {
  getProjectDetail,
  getProjectsOverview,
  getAllProjects,
} from "store/projects/actions";
import { withTranslation } from "react-i18next";
import { Card } from "reactstrap";

// import Loading from "pages/Loading";
import { getEntityLookups } from "store/actions";
import DashboardCard from "common/dashboardCard";

import "./project-level.css";

//icons
import dashboardTaskIcon from "../../../assets/icons/dashboardTask.svg";

import DashedLine from "pages/AllCharts/apex/dashedLine";
import TeamMembers from "./team-members";

import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
// import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import PlannedVsActualChart from "./planned-vs-actual-chart";
import Loading from "pages/Loading";

class allApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // series: [
      //   {
      //     name: this.props.t("Approvals Created"),
      //     data: [20, 70, 50, 60, 30, 30, 13, 13],
      //   },
      //   {
      //     name: this.props.t("Approvals Completed"),
      //     data: [30, 60, 40, 45, 70, 22, 20, 5],
      //   },
      // ],
      // categories: [
      //   "Jul 03",
      //   "Jul 04",
      //   "Jul 05",
      //   "Jul 06",
      //   "Jul 07",
      //   "Jul 08",
      //   "Jul 09",
      //   "Jul 10",
      // ],
      teamMembers: [
        {
          name: "Sammy Kilback",
          role: "Engagement Lead",
          image: avatar1,
          productivityScore: 9,
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          image: avatar2,
          productivityScore: 9,
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          image: avatar4,
          productivityScore: 9,
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          image: avatar2,
          productivityScore: 9,
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          image: avatar4,
          productivityScore: 9,
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          image: avatar2,
          productivityScore: 9,
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          image: avatar4,
          productivityScore: 9,
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          image: avatar2,
          productivityScore: 9,
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          image: avatar4,
          productivityScore: 9,
        },
      ],
      // seriesForPlannedVsActual: [
      //   {
      //     name: this.props.t("Planned").toUpperCase(),
      //     data: [correspondenceOverview?.plannedVsActuals?.planned],
      //   },
      //   {
      //     name: this.props.t("Actual").toUpperCase(),
      //     data: [correspondenceOverview?.plannedVsActuals?.actual],
      //   },
      // ],
      categoriesForPlannedVsActual: [
        this.props.t("Planned"),
        this.props.t("Actual"),
      ],
    };
  }

  render() {
    const {
      loading,
      allApprovals,
      loadingLookups,
      approvalsOverview,
      activeTab,
    } = this.props;
    if (loading || !approvalsOverview) {
      return <Loading />;
    }

    let arrCountCreated = [];
    let arrDateCreated = [];
    approvalsOverview.approvalCreated?.map((coress, index) => {
      arrCountCreated.push(coress.count);
      arrDateCreated.push(coress.date);
    });

    let arrCountCompleted = [];
    let arrDateCompleted = [];

    approvalsOverview.approvalCompleted?.map((coress, index) => {
      arrCountCompleted.push(coress.count);
      arrDateCompleted.push(coress.date);
    });
    return (
      <React.Fragment>
        <Row className="">
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            xxl={3}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("Total Approvals")}
              value={approvalsOverview.numberOfApprovals}
              percentage={approvalsOverview.approvalsVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardTaskIcon}
            />
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            xxl={3}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("Complete Approvals")}
              value={approvalsOverview.numberOfCompletedApprovals}
              percentage={approvalsOverview.completedApprovalsVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardTaskIcon}
            />
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            xxl={3}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("Approvals in Progress")}
              value={approvalsOverview.numberOfInProgressApprovals}
              percentage={approvalsOverview.inProgressVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardTaskIcon}
            />
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            xxl={3}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("Approvals Pending")}
              value={approvalsOverview.numberOfPendingApprovals}
              percentage={approvalsOverview.pendingApprovalsVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardTaskIcon}
            />
          </Col>
        </Row>
        <Row className="row-margin-top">
          <Row className="g-0">
            <Col lg="6" className="g-1 d-flex flex-column">
              <Card className="mt-0">
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <p className="day-top-projects">
                      {this.props.t("Approvals Created vs  Completed")}
                    </p>
                    <p className="team-member-link mx-2">
                      {this.props.t("View Timeline")}
                    </p>
                  </div>
                  <DashedLine
                    key={activeTab}
                    series={[
                      {
                        name: this.props.t("Approvals Created"),
                        data: arrCountCreated,
                      },
                      {
                        name: this.props.t("Approvals Completed"),
                        data: arrCountCompleted,
                      },
                    ]}
                    categories={arrDateCreated}
                  />
                </CardBody>
              </Card>
              <PlannedVsActualChart
                title={this.props.t("Approvals Planned vs Actual")}
                key={activeTab}
                series={[
                  {
                    name: this.props.t("Planned").toUpperCase(),
                    data: [approvalsOverview?.plannedVsActuals?.planned],
                  },
                  {
                    name: this.props.t("Actual").toUpperCase(),
                    data: [approvalsOverview?.plannedVsActuals?.actual],
                  },
                ]}
                categories={this.state.categoriesForPlannedVsActual}
              />
            </Col>
            <Col lg="6" className="g-1">
              <TeamMembers data={approvalsOverview.teamMembers} />
            </Col>
          </Row>
        </Row>
      </React.Fragment>
    );
  }
}
allApprovals.propTypes = {
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
  allApprovals: PropTypes.array,
  onGetProjectsOverview: PropTypes.func,
  onGetAllProjects: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.any,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  activeTab: PropTypes.any,
  approvalsOverview: PropTypes.any,
};
const mapStateToProps = ({ projects, Layout, approvals }) => ({
  loading: projects.loading,
  error: projects.error,
  allApprovals: projects.allApprovals,
  loadingLookups: Layout.loadingLookups,
  approvalsOverview: approvals.approvalsOverview,
});
const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
  onGetProjectsOverview: () => dispatch(getProjectsOverview()),
  onGetAllProjects: () => dispatch(getAllProjects()),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(allApprovals)));
