import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import FormUpload from "pages/Forms/FormUpload";
import CustomSelect from "components/Common/CustomSelect";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { getLookupValue } from "common/utils";
import {
  DEFAULT_ACCEPTED_FILES,
  E_SIGNATURE_TYPE,
  handleArabicDate,
  isInternal,
  SIGNING_ACCEPTED_FILES,
} from "common";

class CreateApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validations: {},
    };
    this.isValid = this.isValid.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.handleValidation(this.props.isEdit);
    }
  }
  componentDidMount() {
    this.handleValidation(this.props.isEdit);
  }

  handleValidation = isEdit => {
    let validations = {};
    if (isEdit) {
      validations = {
        subject: Yup.string().required(this.props.t("errorSubject")),
        dueDate: Yup.date().required(this.props.t("errorDueDate")),
        priority: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorPriority")),
        regarding: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
            entityName: Yup.string().required(),
          })
          .required(this.props.t("errorRegarding")),
      };
    } else {
      validations = {
        subject: Yup.string().required(this.props.t("errorSubject")),
        dueDate: Yup.date().required(this.props.t("errorDueDate")),
        priority: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorPriority")),
        regarding: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
            entityName: Yup.string().required(),
          })
          .required(this.props.t("errorRegarding")),
        approvalType: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorApprovalType")),
        recipients: Yup.array()
          .min(1, this.props.t("errorRecipient"))
          .max(3, this.props.t("errorMaxRecipient"))
          .required(),
      };
    }
    this.setState({ validations });
  };

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    if (!this.props.isEdit && values.approvalType?.value == E_SIGNATURE_TYPE) {
      return this.props.files.length > 0;
    }

    for (let key in this.state.validations) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { isEdit, approval, entityLookups, files, onFilesAccepted } =
      this.props;

    if (!entityLookups) {
      return <></>;
    }

    let regardingOptions = [];

    if (entityLookups?.listRegarding?.length) {
      regardingOptions = [
        { ...entityLookups?.listRegarding[0] },
        { ...entityLookups?.listRegarding[1] },
      ];
    }

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("Update Approval")
            : this.props.t("Create Approval")}
        </ModalHeader>

        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              subject:
                (approval?.approval && approval?.approval?.subject) || "",
              description:
                (approval?.approval && approval?.approval?.description) || "",
              dueDate:
                (approval?.approval &&
                  handleArabicDate(approval?.approval?.dueDate)) ||
                "",
              priority:
                (approval?.approval &&
                  getLookupValue(
                    approval?.approval?.priority?.value,
                    entityLookups.listApprovalPriority
                  )) ||
                "",
              idCorrespondence:
                (approval?.approval &&
                  getLookupValue(
                    approval?.approval?.idCorrespondence,
                    entityLookups.listCorrespondence
                  )) ||
                "",
              regarding:
                (approval?.approval &&
                  getLookupValue(
                    approval?.approval?.idRegarding,
                    regardingOptions
                  )) ||
                "",
              files: (approval?.approval && approval?.approval?.files) || [],
              approvalType: entityLookups.listApprovalType[0],
            }}
            validationSchema={Yup.object().shape(this.state.validations)}
            onSubmit={values => { }}
          >
            {({ errors, status, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Row>
                        <Col sm={6}>
                          <Label className="form-label day-required text-muted">
                            {this.props.t("Name").toUpperCase()}
                          </Label>
                          <Field
                            name="subject"
                            size="big"
                            type="text"
                            className={
                              "form-control" +
                              (errors.subject && touched.subject
                                ? " is-invalid"
                                : "")
                            }
                            value={values.subject}
                          />
                          <ErrorMessage
                            name="subject"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col sm={6}>
                          <Label className="form-label day-required text-muted">
                            {this.props.t("Regarding").toUpperCase()}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("regarding", value);
                            }}
                            value={values.regarding}
                            options={regardingOptions}
                          />
                          <ErrorMessage
                            name="regarding"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-3">
                      <Row>
                        {!isEdit && (
                          <Col sm={6}>
                            <Label className="form-label day-required text-muted">
                              {this.props.t("ApprovalType")}
                            </Label>
                            <CustomSelect
                              className="input"
                              onChange={value => {
                                setFieldValue("approvalType", value);
                              }}
                              value={values.approvalType}
                              options={entityLookups.listApprovalType}
                            />
                            <ErrorMessage
                              name="approvalType"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        )}
                        <Col sm={!isEdit ? 6 : 12}>
                          <Label className="form-label day-required text-muted">
                            {this.props.t("Due Date Form")}
                          </Label>
                          <Field
                            name="dueDate"
                            type="date"
                            className={
                              "form-control" +
                              (errors.dueDate && touched.dueDate
                                ? " is-invalid"
                                : "")
                            }
                            value={values.dueDate}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-3">
                      <Row>
                        <Col sm={6}>
                          <Label className="form-label day-required text-muted">
                            {this.props.t("PriorityForm")}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("priority", value);
                            }}
                            value={values.priority}
                            options={entityLookups.listApprovalPriority}
                          />
                          <ErrorMessage
                            name="priority"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>

                        <Col sm={6}>
                          <Label className="form-label text-muted">
                            {this.props.t("Correspondence Name")}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              const correspondenceValue = value ? value : null;
                              setFieldValue("idCorrespondence", correspondenceValue);
                            }}
                            value={values.idCorrespondence}
                            options={entityLookups.listCorrespondence}
                            isClearable
                          />
                          <ErrorMessage
                            name="idCorrespondence"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                    </div>

                    {!isEdit && (
                      <>
                        <div className="mb-3">
                          <Row>
                            <Col sm={12}>
                              <Label className="form-label day-required text-muted">
                                {this.props.t(
                                  this.props
                                    .t("Max3")
                                    .replace("*", this.props.t("Recipients"))
                                )}
                              </Label>
                              <CustomSelect
                                max={3}
                                className="input"
                                onChange={value => {
                                  setFieldValue("recipients", value);
                                }}
                                value={values.recipients}
                                isMulti
                                options={
                                  isInternal
                                    ? entityLookups.listIndividualsInternal
                                    : entityLookups.listIndividualsExternal
                                }
                              />
                              <ErrorMessage
                                name="recipients"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3">
                          <Col sm={12}>
                            <Label
                              className={
                                "form-label text-muted" +
                                (values.approvalType &&
                                  values.approvalType?.value == E_SIGNATURE_TYPE
                                  ? " day-required"
                                  : "")
                              }
                            >
                              {values.approvalType &&
                                values.approvalType?.value == E_SIGNATURE_TYPE
                                ? this.props.t("Files") + " *"
                                : this.props
                                  .t("Optional")
                                  .replace("*", this.props.t("Files"))}
                            </Label>
                            <FormUpload
                              files={files}
                              onFilesAccepted={onFilesAccepted}
                              multiple={
                                !(
                                  values.approvalType &&
                                  values.approvalType?.value == E_SIGNATURE_TYPE
                                )
                              }
                              accept={
                                values.approvalType &&
                                  values.approvalType?.value == E_SIGNATURE_TYPE
                                  ? SIGNING_ACCEPTED_FILES
                                  : DEFAULT_ACCEPTED_FILES
                              }
                              isInvalid={
                                values.approvalType &&
                                values.approvalType?.value ==
                                E_SIGNATURE_TYPE &&
                                files.length === 0
                              }
                            />
                          </Col>
                        </div>

                        <div className="mb-3">
                          <Row>
                            <Col sm={12}>
                              <Label className="form-label text-muted">
                                {this.props.t("DescriptionForm")}
                              </Label>
                              <Field
                                name="description"
                                type="text"
                                as="textarea"
                                placeholder={this.props.t("enterDes")}
                                className={
                                  "form-control" +
                                  (errors.description && touched.description
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="day-text-end">
                    <div className="day-text-end">
                      <button
                        className="btn customTable-button save-task"
                        disabled={!this.isValid(values, errors)}
                        onClick={e => {
                          e.preventDefault();
                          this.props.handleSubmit(values);
                        }}
                      >
                        {isEdit
                          ? this.props.t("Update")
                          : this.props.t("Initiate")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

CreateApproval.propTypes = {
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  approval: PropTypes.any,
  entityLookups: PropTypes.any,
  files: PropTypes.any,
  onFilesAccepted: PropTypes.func,
};

export default withTranslation()(withRouter(CreateApproval));
