import React, { Component, useEffect, useState } from "react";
import { Card, CardBody, Nav, NavItem } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./file-manager.scss";
import { withTranslation } from "react-i18next";
import Loading from "pages/Loading";
import { CURRENT_LANG } from "common";
import ScrollbalList from "./ScrollabelList";

const FileLeftBar = props => {
  const { mobileStyle, t, entities } = props;
  const [file_collapse, setFile_collapse] = useState(true);
  const [tabId, setTabId] = useState(0);
  const [innerTabId, setInnerTabId] = useState(0);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const list = {
    approvals: {
      entityName: "day_approval",
      displayName: "Approvals",
      displayNameAr: "الموافقات",
    },
    correspondences: {
      entityName: "day_correspondence",
      displayName: "Correspondences",
      displayNameAr: "المراسلات",
    },
    organizations: {
      entityName: "account",
      displayName: "Organizations",
      displayNameAr: "المنظمات",
    },
    projects: {
      entityName: "day_project",
      displayName: "Projects",
      displayNameAr: "المشاريع",
    },
    requests: {
      entityName: "day_request",
      displayName: "Requests",
      displayNameAr: "الطلبات",
    },
    tasks: { entityName: "task", displayName: "Tasks", displayNameAr: "مهام" },
  };

  const handleOuterTabClick = (index, key) => {
    console.log("index:", index, "key", key);
    toggleTab(index, key);
    props.onClick(`/${list[key].displayName}`);
  };

  const toggleTab = (tabID, key) => {
    props.clearList();
    if (tabID === tabId) {
      setTabId(0);
      setInnerTabId(0);
      setSelectedEntity(null);
    } else {
      setTabId(tabID);
      setInnerTabId(0);
      setSelectedEntity(list[key]);
    }
  };

  const handleInnerTabClick = (index, key, item) => {
    const payload = {
      entity: list[key].entityName,
      idEntity: item.id,
    };
    console.log("handleInnerTabClick payload:", payload);
    toggleInnerTab(index);
    props.onClick(`/${list[key].displayName}/${item.title}`, payload);
  };

  const toggleInnerTab = innerTabId => {
    setInnerTabId(innerTabId);
  };

  return (
    <>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody
          style={{
            paddingInlineEnd: 0,
            paddingTop: mobileStyle ? 0 : 20,
          }}
          id="walkthrough--document-management-folders"
        >
          <div
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              display: "flex",
              width: "100%",
            }}
          >
            <Nav
              pills
              className="flex-column"
              style={{
                alignItems: "flex-start",
                display: "flex",
                width: "100%",
              }}
            >
              {Object.keys(list).map((key, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  key={index}
                >
                  <NavItem
                    key={index}
                    style={{
                      width: "100%",
                    }}
                  >
                    <a
                      className={classnames({
                        "mb-2": true,
                        active: tabId === index + 1,
                        "text-primary": tabId === index + 1,
                        "file-tab": true,
                        "file-border-right": tabId === index + 1,
                      })}
                      onClick={() => {
                        handleOuterTabClick(index + 1, key);
                      }}
                    >
                      <h6
                        className={classnames({
                          "mb-0": true,
                          "text-muted": tabId !== index + 1,
                        })}
                      >
                        {CURRENT_LANG === "ar"
                          ? list[key].displayNameAr
                          : list[key].displayName}
                      </h6>
                    </a>
                  </NavItem>

                  {/**  scrollbar list - To be updated to pagination  */}
                  {tabId === index + 1 && (
                    <ScrollbalList
                      oldData={props.entities} // to be deleted after completing all the 6 APIS
                      listKey={key}
                      t={props.t}
                      innerTabId={innerTabId}
                      handleInnerTabClick={handleInnerTabClick}
                    />
                  )}
                </div>
              ))}
            </Nav>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

FileLeftBar.propTypes = {
  onClick: PropTypes.func,
  path: PropTypes.array,
  entities: PropTypes.any,
  t: PropTypes.any,
  clearList: PropTypes.func,
  mobileStyle: PropTypes.bool,
};
export default withTranslation()(FileLeftBar);
