/* eslint-disable react/display-name */
// import React from "react";
// import { Redirect } from "react-router-dom";

// Pages File Manager
import FileManager from "../pages/FileManager/index";

// Pages Calendar
import Calendar from "../pages/Calendar/index";
import CalendarDetails from "../pages/Calendar/calendar-details";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

//Tasks
import Tasks from "../pages/Tasks/tasks";
import TaskDetails from "../pages/Tasks/task-details";
import IndividualDetails from "../pages/Contacts/ContactList/Individual-details";
import OrganizationDetails from "../pages/Contacts/ContactList/organization-details";
import TasksCreate from "../pages/Tasks/tasks-create";

//Projects
// import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectDetails from "../pages/Projects/ProjectOverview/ProjectDetails/project-details";
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview";

// Authentication related pages
import LoginInternal from "../pages/Authentication/LoginInternal";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Home
import Home from "../pages/Home/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Contacts
import ContactsList from "../pages/Contacts/ContactList/contacts-list";

//Blog
import Approvals from "../pages/Approvals";

//Requests
import Requests from "pages/Requests/requests";
import LoginExternal from "pages/Authentication/LoginExternal";
import RequestDetails from "../pages/Requests/RequestDetails/request-details";
import Correspondence from "../pages/Correspondence";
import { ENTITIES } from "common";
import projectLevel from "pages/Dashborads/ProjectLevel/project-level";
import individualLevel from "pages/Dashborads/IndividualLevel/individual-level";
import executiveLevel from "pages/Dashborads/ExecutiveLevel/executive-level";

const authProtectedRoutes = [
  { path: "/home", component: Home, entity: null },

  //profile
  { path: "/profile", component: UserProfile, entity: null },

  //File Manager
  {
    path: "/document-management",
    component: FileManager,
    entities: [ENTITIES.DOCUMENT],
  },

  //calendar
  { path: "/calendar", component: Calendar, entities: [ENTITIES.APPOINTMENT] },
  {
    path: "/calendar-details",
    component: CalendarDetails,
    entities: [ENTITIES.APPOINTMENT],
  },

  // Tasks
  { path: "/tasks", component: Tasks, entities: [ENTITIES.TASK] },
  { path: "/task-details", component: TaskDetails, entities: [ENTITIES.TASK] },
  { path: "/tasks-create", component: TasksCreate, entities: [ENTITIES.TASK] },

  //Projects
  {
    path: "/project-details",
    component: ProjectDetails,
    entities: [ENTITIES.PROJECT],
  },
  {
    path: "/projects-overview",
    component: ProjectsOverview,
    entities: [ENTITIES.PROJECT],
  },

  // Dashboards - Project Level
  {
    path: "/project-level",
    component: projectLevel,
    // entities: [ENTITIES.PROJECT],
  },
  {
    path: "/individual-level",
    component: individualLevel,
    // entities: [ENTITIES.PROJECT],
  },
  {
    path: "/executive-level",
    component: executiveLevel,
    // entities: [ENTITIES.PROJECT],
  },

  // Contacts
  {
    path: "/people",
    component: ContactsList,
    entities: [ENTITIES.INDIVIDUALS, ENTITIES.ORGANIZATION],
  },
  {
    path: "/individual-details",
    component: IndividualDetails,
    entities: [ENTITIES.INDIVIDUALS],
  },

  {
    path: "/organization-details",
    component: OrganizationDetails,
    entities: [ENTITIES.ORGANIZATION],
  },

  // Requests
  { path: "/requests", component: Requests, entities: [ENTITIES.REQUEST] },
  {
    path: "/request-details",
    component: RequestDetails,
    entities: [ENTITIES.REQUEST],
  },

  { path: "/approvals", component: Approvals, entities: [ENTITIES.APPROVAL] },

  //errors
  { path: "/pages-maintenance", component: PagesMaintenance, entity: null },
  { path: "/pages-comingsoon", component: PagesComingsoon, entity: null },
  { path: "/pages-404", component: Pages404, entity: null },
  { path: "/pages-500", component: Pages500, entity: null },

  //correspondence
  {
    path: "/correspondence",
    component: Correspondence,
    entities: [ENTITIES.CORRESPONDENCE],
  },
  { path: "/logout", component: Logout, entities: null },
];

const publicRoutes = [
  { path: "/login-internal", exact: true, component: LoginInternal },
  { path: "/login-external", exact: true, component: LoginExternal },
  { path: "/forgot-password", exact: true, component: ForgetPwd },
  { path: "/register", exact: true, component: Register },

  { path: "/pages-404", exact: true, component: Pages404 },
  { path: "/pages-500", exact: true, component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
