import {
  Colors,
  customLocalStorage,
  isInternal,
  TOAST_OPTIONS,
  USER_TYPES,
} from "common";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, Table } from "reactstrap";
import {
  getExternalDetails,
  getInternalDetails,
  updateUser,
} from "store/actions";

type Props = {
  setDetailsUpdating: (updating: boolean) => void;
};

const NotificationSettings = ({ setDetailsUpdating }: Props) => {
  const { t } = useTranslation();
  // @ts-expect-error: The redux store does not have a RootState type defined
  const { success } = useSelector(state => state?.Profile);
  const notification = success?.notification;
  const dispatch = useDispatch();

  //  If there are no notifications settings in the store, then fetch them from the API.
  useEffect(() => {
    if (notification) return;

    const userType = JSON.parse(customLocalStorage.getItem("userType"));
    if (userType == USER_TYPES.EXTERNAL_USER) dispatch(getExternalDetails());
    else if (userType == USER_TYPES.INTERNAL_USER)
      dispatch(getInternalDetails());
  }, []);

  const handleUserUpdate = async (notification: Object) => {
    const user = {
      idContact: success.idContact,
      firstname: success.firstname,
      lastname: success.lastname,
      email: success.email,
      idRole: success.roleId || "00000000-0000-0000-0000-000000000000", //-
      jobTitle: success.jobTitle,
      idOrganization: success.organizationId, //-
      phoneNumber: success.phoneNumber,
      department: success.department,
      employeeId: success.employeeId,
      profileImage: success.image, //-
      sectors: success.sectors,
      notification: { ...notification },
      isDepartmentChampion: success.isDepartmentChampion
    };

    setDetailsUpdating(true);
    dispatch(
      updateUser(user, () => {
        toast.success(
          t("SuccessUpdate").replace("*", t("Profile")),
          // @ts-expect-error: position property is a string when it is supposed to be a type of ToastPosition
          TOAST_OPTIONS
        );

        if (!isInternal) {
          dispatch(getExternalDetails(() => setDetailsUpdating(false)));
        } else {
          dispatch(getInternalDetails(() => setDetailsUpdating(false)));
        }
      })
    );
    setTimeout(() => {
      setDetailsUpdating(false);
    }, 2000);
  };

  const notifications = [
    {
      name: t("New Projects").toUpperCase(),
      email: true,
      whatsapp: true,
      day: true,
    },
    {
      name: t("New Tasks").toUpperCase(),
      email: true,
      whatsapp: true,
      day: true,
    },
    {
      name: t("New Requests").toUpperCase(),
      email: true,
      whatsapp: true,
      day: true,
    },
    {
      name: t("New Messages").toUpperCase(),
      email: true,
      whatsapp: true,
      day: true,
    },
  ];

  if (!notification) return <></>;

  return (
    <Card>
      <CardBody className="p-1">
        <div className="table-responsive text-left" style={{ width: "100%" }}>
          <Table className="table mb-0 text-left">
            <tbody>
              <tr style={{ textAlign: "start" }}>
                <th className="card-title h6 day-dark-text-bold">
                  {t("Notification Settings")}
                </th>
                <th className="h6 day-dark-text-bold day-align-left">
                  {t("EmailForm")}
                </th>
                <th className="h6 day-dark-text-bold day-align-left">
                  {t("Whatsapp")}
                </th>
                <th className="h6 day-dark-text-bold day-align-left">
                  {t("Portal")}
                </th>
              </tr>
              {Object.entries(notification)?.map(
                ([moduleName, module], index) => (
                  <tr
                    key={"notification" + index}
                    style={{ alignItems: "left" }}
                  >
                    <th
                      className="text-muted"
                      style={{
                        fontWeight: "normal",
                        textAlign: "start",
                      }}
                    // >{`${item.name}`}</th>
                    >
                      {t(`New ${moduleName}s`)?.toUpperCase() || moduleName}
                    </th>
                    {/* @ts-expect-error: module has to be an object (it is) */}
                    {Object.entries(module)?.map(
                      ([platformName, isChecked], index) => (
                        <td key={`module-${platformName}-${index}`}>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input custom-control-input float-start"
                              id="customSwitchsizesm"
                              checked={isChecked ? true : false}
                              style={{ color: Colors.blue }}
                              onChange={e => {
                                handleUserUpdate({
                                  ...notification,
                                  [moduleName]: {
                                    ...notification[moduleName],
                                    [platformName]: e.target.checked ?? false,
                                  },
                                });
                              }}
                            />
                          </div>
                        </td>
                      )
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default NotificationSettings;
