import { get, post, patch } from "./api_helper";
import * as url from "./url_helper";

// Login Method
export const postLoginInternal = data => post(url.LOGIN_INTERNAL, data);
export const postLoginExternal = data => post(url.LOGIN_EXTERNAL, data);
export const registerUser = data => post(url.REGISTER, data);

// PROFILE
export const getInternalDetailsCall = () => get(url.GET_INTERNAL_DETAILS);

export const getExternalDetailsCall = () => get(url.GET_EXTERNAL_DETAILS);

export const forgotPassword = data => post(url.FORGOT_PASSWORD, data);
export const resetPassword = (data, config) =>
  post(url.RESET_PASSWORD, data, config);
export const changePassword = (data, config) =>
  post(url.CHANGE_PASSWORD, data, config);

// APPROVALS
export const getApprovals = () => get(url.GET_APPROVALS);
export const getApprovalDetails = data => get(url.GET_APPROVAL_DETAILS, data);
export const upsertApproval = (data, config) =>
  post(url.UPSERT_APPROVAL, data, config);
export const submitApprovalPath = (data, config) =>
  post(url.SUBMIT_APPROVAL_PATH, data, config);

// CORRESPONDENCE
export const getAllCorres = data => get(url.GET_ALL_CORRESPONDENCE, data);
export const getCorrespondenceDetails = data =>
  get(url.GET_CORRESPONDENCE_DETAILS, data);
export const getComments = (data, config) =>
  get(url.GET_COMMENTS, data, config);
export const upsertCorrespondence = (data, config) =>
  post(url.UPSERT_CORRESPONDENCE, data, config);

// REQUESTS
export const getRequests = data => get(url.GET_REQUESTS, data);
export const getRequestsDashboard = data => get(url.GET_REQUESTS_DASHBOARD, data);
export const getRequestDetails = (data, config) =>
  get(url.GET_REQUEST_DETAILS, data, config);
export const upsertRequest = (body, config) =>
  post(url.UPSERT_REQUEST, body, config);

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => patch(url.UPDATE_EVENT, event);

// delete Record
export const deleteRecord = event => post(url.DELETE_RECORD, event);

// get Organizations
export const getAllOrganizations = data => get(url.GET_ORGANIZATIONS, data);

export const upsertOrganization = (body, config) =>
  post(url.UPSERT_ORGANIZATION, body, config);

// get Organization details
export const getOrganizationDetails = data =>
  get(url.GET_ORGANIZATION_DETAILS, data);
// get messages

// projects
export const getProjectsOverview = (data, config) =>
  get(url.GET_PROJECTS_OVERVIEW, data, config);

export const getAllProjects = (data, config) =>
  get(url.GET_ALL_PROJECTS, data, config);
export const getAllActiveProjects = (data, config) =>
  get(url.GET_ALL_ACTIVE_PROJECTS, data, config);

export const upsertProject = (data, config) =>
  post(url.UPSERT_PROJECT, data, config);

export const deteleProject = (data, config) => {
  post(url.DELETE_PROJECT, data, config);
};
export const getProjectsDashboard = data => get(url.GET_PROJECTS_DASHBOARD, data);

export const upsertStream = (data, config) =>
  post(url.UPSERT_STREAM, data, config);

export const upsertMember = (data, config) =>
  post(url.UPSERT_MEMBER, data, config);

export const getProjectDetails = data => get(url.GET_PROJECT_DETAILS, data);

// dashboard
export const getHome = (body, config) => get(url.GET_HOME, body, config);

export const getLookups = body => get(url.GET_LOOKUPS, body);

export const getGlobalSearch = body => get(url.GET_GLOBAL_SEARCH, body);

export const getNotifications = (body, config) =>
  get(url.GET_NOTIFICATIONS, body, config);

export const updateNotifications = (body, config) =>
  post(url.UPDATE_NOTIFICATIONS, body, config);

export const getPermissions = (body, config) =>
  get(url.GET_PERMISSIONS, body, config);
  export const getDashboardAllProjects = body => get(url.GET_DASHBOARD_ALLPROJECTS, body);

//files
export const getFiles = (body, config) => get(url.GET_FILES, body, config);

export const createFolder = (body, config) =>
  post(url.CREATE_FOLDER, body, config);

export const downloadFile = (body, config) =>
  get(url.DOWNLOAD_FILE, body, config);

export const getReferencedFiles = (body, config) =>
  get(url.GET_DOCUMENT_REFERENCE, body, config);

export const uploadFiles = (body, config) =>
  post(url.UPLOAD_FILES, body, config);

export const createDocumentReference = (body, config) =>
  post(url.CREATE_DOCUMENT_REFERENCE, body, config);

export const getRelatedFiles = (body, config) =>
  get(url.GET_RELATED_FILES, body, config);

export const getFileCategories = (body, config) =>
  get(url.GET_FILES_CATEGORIES, body, config);

export const getFileDetails = (body, config) =>
  post(url.GET_FILE_DETAILS, body, config);

export const getDocumentTasks = (body, config) => get(url.GET_DOCUMENT_TASKS, body, config);
export const getDocumentProjects = (body, config) => get(url.GET_DOCUMENT_PROJECTS, body, config);
export const getDocumentRequests = (body, config) => get(url.GET_DOCUMENT_REQUESTS, body, config);
export const getDocumentApprovals = (body, config) => get(url.GET_DOCUMENT_APPROVALS, body, config);
export const getDocumentOrganizations = (body, config) => get(url.GET_DOCUMENT_ORGANIZATIONS, body, config);
export const getDocumentCorrespondences = (body, config) => get(url.GET_DOCUMENT_CORRESPONDENCES, body, config);





// get tasks
export const getTasks = () => get(url.GET_TASKS);
export const getAllTasks = (data) => get(url.GET_ALL_TASKS,data);
export const getTaskDetails = data => get(url.GET_TASK_DETAILS, data);
export const upsertTask = (body, config) => post(url.UPSERT_TASK, body, config);
export const getTaskDashboard=()=>get(url.GET_TASKS_DASHBOARD)
// get contacts
export const getUsers = (body) => get(url.GET_USERS,body);

// add user
export const addNewUser = (body, config) =>
  post(url.ADD_NEW_USER, body, config);

// update user
export const updateUser = (body, config) => post(url.UPDATE_USER, body, config);

export const getUserProfile = (data, config) =>
  get(url.GET_USER_PROFILE, data, config);

// Calender
export const getAppointments = (data, config) =>
  post(url.GET_APPOINTMENTS, data, config);

// Comments
export const upsertComments = (data, config) =>
  post(url.UPSERT_COMMENTS, data, config);

// Appointmenats
export const upsertAgenda = (data, config) =>
  post(url.UPSERT_AGENDA, data, config);

export const upsertAppointments = (data, config) =>
  post(url.UPSERT_APPOINTMENTS, data, config);

export const getAppointmentDetails = (data, config) =>
  post(url.GET_APPOINTMENT_DETAILS, data, config);

export const sendEmailToParticipants = (data, config) =>
  post(url.SEND_EMAIL_TO_PARTICIPANTS, data, config);

export const getCheck = (data, config) => post(url.GET_CHECK, data, config);

// dashboards
export const getAllProjectsOverview = () => get(url.GET_ALLPROJECTSOVERVIEW);
export const getAllTasksOverview = (data, config) =>
  get(url.GET_ALL_TASKS_OVERVIEW, data, config);
export const getAllCorrespondenceOverview = (data, config) =>
  get(url.GET_CORRESPONDENCES_OVERVIEW, data, config);
export const getAllApprovalsOverview = (data, config) =>
  get(url.GET_ALL_APPROVALS_OVERVIEW, data, config);

export const getIndividualLevel = (data, config) =>
  get(url.GET_INDIVIDUAL_LEVEL, data, config);

export const getExecutiveLevel = (data, config) =>
  get(url.GET_EXECUTIVE_LEVEL, data, config);
export const getProjectsOptions = (data) => get(url.GET_PROJECTS_OPTIONS , data)

//export get_access_token
export const getAccessToken = (data,config) => post(url.GET_ACCESS_TOKEN,data,config); 
export const handleLogoutFromServer = () => get(url.LOGOUT);

export const getLookupCounts = body => get(url.GET_LOOKUP_COUNTS, body);
