import React, { HTMLAttributes } from "react";
import { default as MD } from "markdown-to-jsx";

interface Props extends HTMLAttributes<HTMLElement> {
  children?: string;
}

/**
 *
 * @description A simple wrapper component around the markdown to JSX library component that provides a safer abstraction is the text provided is not properly formatted, omitted or is the wrong type
 */
const Markdown = ({ children }: Props) =>
  typeof children === "string" ? <MD>{children}</MD> : <></>;

export default Markdown;
