import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import moment from "moment";
import ServerTable from "components/Common/ServerTable";
import { getGlobalHeaderStyle, CURRENT_LANG } from "common";
import { randomColor, sortCaretIcons } from "common/utils";
import ProgressBar from "./progressBar";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/scale-subtle.css";
// Table data

class ActiveProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: "",
      modal: false,
      deleteModal: false,
      page: 1,
      sizePerPage: 5,
      projects: props.projects,
      tabletStyle: props.tabletStyle,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,
      largeScreen: false,
    };
    this.resize = this.resize.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleTaskClicks = () => {
    this.setState({ task: "", isEdit: false });
    this.toggle();
  };

  generateColors() {
    let color = randomColor();

    return {
      backgroundColor: color + "1a",
      color: color,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 685 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 550 });
    this.setState({ mobileStyle: window.innerWidth <= 472 });
    this.setState({ largeScreen: window.innerWidth >= 1400 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    const { allProjects } = this.props;
    const projectsColumns = [
      {
        text: "id",
        dataField: "id",
        hidden: true,
      },
      {
        dataField: "titleEn",
        text: this.props.t("PROJECT NAME"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, project) => (
          <div className="d-flex justify-content-start align-items-center">
            <div className="avatar-sm d-flex align-items-center">
              <span
                className="avatar-title rounded"
                style={this.generateColors()}
              >
                {project.titleEn.split(" ")[0]?.charAt(0)
                  ? project.titleEn.split(" ")[0]?.charAt(0)
                  : "" + project.titleEn.split(" ")[1]?.charAt(0)
                  ? project.titleEn.split(" ")[1]?.charAt(0)
                  : ""}
              </span>
            </div>
            <Tippy
              content={
                localStorage.getItem("I18N_LANGUAGE") === "ar"
                  ? project.titleAr
                  : project.titleEn
              }
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/project-details?id=" + project.id}
                  query={project}
                  className="text-dark"
                >
                  {(localStorage.getItem("I18N_LANGUAGE") === "ar"
                    ? project.titleAr.length
                    : project.titleEn.length) >= 10
                    ? localStorage.getItem("I18N_LANGUAGE") === "ar"
                      ? project.titleAr.substring(0, 10) + "..."
                      : project.titleEn.substring(0, 10) + "..."
                    : localStorage.getItem("I18N_LANGUAGE") === "ar"
                    ? project.titleAr
                    : project.titleEn}
                </Link>
              </span>
            </Tippy>
          </div>
        ),
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
      },
      {
        hidden: this.state.mobileStyle,
        text: this.props.t("Owner"),
        dataField: "owner.name",
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, project) => (
          <div className="d-flex justify-content-start align-items-center">
            {project.owner?.image ? (
              <div className="avatar-sm d-flex justify-content-start align-items-center">
                <span
                  className="avatar-title rounded-circle"
                  style={this.generateColors()}
                >
                  <img
                    src={`data:image/png;base64,${project.owner?.image}`}
                    className="rounded-circle"
                    height="34"
                  ></img>
                </span>
              </div>
            ) : (
              <div className="avatar-sm d-flex justify-content-start align-items-center">
                <span
                  className="avatar-title rounded-circle"
                  style={this.generateColors()}
                >
                  {project.owner?.name.charAt(0)}
                </span>
              </div>
            )}
            <Tippy
              content={this.props.t("View More")}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/individual-details?id=" + project.owner?.id}
                  query={project}
                  className="text-dark"
                >
                  {project.owner?.name}
                </Link>
              </span>
            </Tippy>
          </div>
        ),
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "stage.label",
        text: this.props.t("Stage"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
        formatter: (cellContent, project) => (
          <>
            {CURRENT_LANG === "en"
              ? project.stage?.label
              : project.stage?.labelAr}
          </>
        ),
      },
      {
        dataField: "progress",
        text: this.props.t("Progress").toUpperCase(),
        sort: false,
        formatter: (cellContent, project) => (
          <ProgressBar data={project.progress} />
        ),
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        dataField: "dueDate",
        text: this.props.t("Deadline"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
        formatter: (cellContent, project) => (
          <p className="mb-0">
            {moment(project.dueDate).utc().format("DD/MM/YY")}
          </p>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <ServerTable
              columns={projectsColumns}
              data={allProjects}
              onPageChange={this.props.handlePageChange}
              currentPage={this.props.activePageNumber}
              totalDataSize={this.props.totalDataSize}
              sizePerPage={10}
              isActive
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

ActiveProjects.propTypes = {
  projectDetail: PropTypes.any,
  projects: PropTypes.array,
  allProjects: PropTypes.array,
  t: PropTypes.any,
  tabletStyle: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  handlePageChange: PropTypes.any,
  activePageNumber: PropTypes.any,
  totalDataSize: PropTypes.any,
};

export default withTranslation()(withRouter(ActiveProjects));
