import {
  GET_REQUESTS,
  GET_REQUESTS_FAIL,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_DASHBOARD,
  GET_REQUESTS_DASHBOARD_FAIL,
  GET_REQUESTS_DASHBOARD_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAIL,
  GET_COMMENTS_IN_REQUEST_SUCCESS,
  GET_COMMENTS_IN_REQUEST_FAIL,
  GET_COMMENTS_IN_REQUEST,
  UPSERT_REQUEST,
  UPSERT_REQUEST_SUCCESS,
  UPSERT_REQUEST_FAIL,
  GET_REQUEST_FILES,
  GET_REQUEST_FILES_SUCCESS,
  GET_REQUEST_FILES_FAIL,
  DELETE_REQUEST,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_FAIL,
  UPDATE_REQUEST_STATUS_CODE,
} from "./actionTypes";

export const updateRequestStatusCode = payload => ({
  type: UPDATE_REQUEST_STATUS_CODE,
  payload
});

export const getRequests = payload => ({
  type: GET_REQUESTS,
  payload
});

export const getRequestsSuccess = payload => ({
  type: GET_REQUESTS_SUCCESS,
  payload,
});

export const getRequestsFail = error => ({
  type: GET_REQUESTS_FAIL,
  payload: error,
});

export const getRequestsDashboard = payload => ({
  type: GET_REQUESTS_DASHBOARD,
  payload
});

export const getRequestsDashboardSuccess = payload => ({
  type: GET_REQUESTS_DASHBOARD_SUCCESS,
  payload,
});

export const getRequestsDashboardFail = error => ({
  type: GET_REQUESTS_DASHBOARD_FAIL,
  payload: error,
});

export const getRequestDetails = id => ({
  type: GET_REQUEST,
  payload: id,
});

export const getRequestSuccess = payload => ({
  type: GET_REQUEST_SUCCESS,
  payload,
});

export const getRequestFail = error => ({
  type: GET_REQUEST_FAIL,
  payload: error,
});

export const getCommentsInRequest = id => ({
  type: GET_COMMENTS_IN_REQUEST,
  payload: id,
});

export const getCommentsInRequestSuccess = payload => ({
  type: GET_COMMENTS_IN_REQUEST_SUCCESS,
  payload,
});

export const getCommentsInRequestFail = error => ({
  type: GET_COMMENTS_IN_REQUEST_FAIL,
  payload: error,
});

export const upsertRequest = (payload, callback) => ({
  type: UPSERT_REQUEST,
  payload,
  callback,
});

export const upsertRequestSuccess = payload => ({
  type: UPSERT_REQUEST_SUCCESS,
  payload,
});

export const upsertRequestFail = error => ({
  type: UPSERT_REQUEST_FAIL,
  payload: error,
});

export const getRequestFiles = (payload, callback) => ({
  type: GET_REQUEST_FILES,
  payload,
  callback,
});

export const getRequestFilesSuccess = payload => ({
  type: GET_REQUEST_FILES_SUCCESS,
  payload,
});

export const getRequestFilesFail = error => ({
  type: GET_REQUEST_FILES_FAIL,
  payload: error,
});

export const deleteRequest = (payload, callback) => ({
  type: DELETE_REQUEST,
  payload,
  callback,
});

export const deleteRequestSuccess = payload => ({
  type: DELETE_REQUEST_SUCCESS,
  payload,
});

export const deleteRequestFail = error => ({
  type: DELETE_REQUEST_FAIL,
  payload: error,
});
