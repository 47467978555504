import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabPane,
  TabContent,
} from "reactstrap";

import { ErrorMessage, Field, Formik, Form } from "formik";
import FormUpload from "pages/Forms/FormUpload";
import RadioStepper from "components/Common/RadioStepper";
import CustomSelect from "components/Common/CustomSelect";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { handleArabicDate, isInternal } from "common";
import {
  formatLineBreaksInMarkdownFriendlyFormat,
  getLookupValue,
} from "common/utils";
import moment from "moment";

let validations = [{}, {}, {}];

let projectSchemaValidations = [
  Yup.object().shape(validations[0]),
  Yup.object().shape(validations[1]),
  Yup.object().shape(validations[2]),
];
class CreateCorrespondence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      refresh: true,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isEdit) {
        validations = [
          {
            status: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorStatus")),
            securitylevel: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorSecurityLevel")),
            priority: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorPriority")),
            regarding: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
                entityName: Yup.string().required(),
              })
              .required(this.props.t("errorRegarding")),
            dueDate: Yup.date().required(this.props.t("errorDueDate")),
          },
        ];
      } else {
        validations = [
          {
            subject: Yup.string().required(this.props.t("errorSubject")),
            recipients: Yup.array()
              .min(1, this.props.t("errorRecipient"))
              .required(),
            status: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorStatus")),
            securitylevel: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorSecurityLevel")),
            priority: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorPriority")),
            dueDate: Yup.date().required(this.props.t("errorDueDate")),
            regarding: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
                entityName: Yup.string().required(),
              })
              .required(this.props.t("errorRegarding")),
          },
          {
            receivedon: Yup.string().required(this.props.t("errorReceivedon")),
            channelcode: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorChannelCode")),
          },
          {},
        ];
      }
    }
  }

  isValid(values, errors) {
    if (Object.keys(errors)?.length > 0) {
      return false;
    }
    for (let key in validations[this.state.activeTab - 1]) {
      if (
        key === "recipients" &&
        (!Array.isArray(values[key]) || values[key]?.length === 0)
      ) {
        return false;
      }
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      correspondenceData,
      isEdit,
      entityLookups,
      files,
      onFilesAccepted,
    } = this.props;
    let editCorrespondence = correspondenceData?.correspondence;
    let recipientsList= []

    if (isEdit && editCorrespondence && editCorrespondence.recipientsList) {
      recipientsList = editCorrespondence.recipientsList.map(
        item => ({ label: item.name, value: item.id })
      );
    }
    if (!entityLookups) {
      return <></>;
    }

    let regardingOptions = [];

    if (entityLookups?.listRegarding?.length) {
      regardingOptions = [
        { ...entityLookups?.listRegarding[0] },
        { ...entityLookups?.listRegarding[1] },
      ];
    }

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("UpdateCorrespondence")
            : this.props.t("CreateCorrespondence") +
              ` (${this.state.activeTab}/3)`}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              subject: (editCorrespondence && editCorrespondence.subject) || "",
              description:
                (editCorrespondence &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    editCorrespondence.description,
                    true
                  )) ||
                "",
              dueDate:
                (editCorrespondence && editCorrespondence.duedate
                  ? handleArabicDate(
                      moment(editCorrespondence.duedate).format("YYYY-MM-DD")
                    )
                  : "") || "",
              receivedon:
                (editCorrespondence && editCorrespondence.receivedon) || "",
              securitylevel:
                (editCorrespondence &&
                  getLookupValue(
                    editCorrespondence.securityLevel?.value,
                    entityLookups.listCorrespondenceSecurityLevel
                  )) ||
                "",
              priority:
                (editCorrespondence &&
                  getLookupValue(
                    editCorrespondence.priority?.value,
                    entityLookups.listCorrespondencePriority
                  )) ||
                "",
              channelcode:
                (editCorrespondence &&
                  getLookupValue(
                    editCorrespondence.channelcode?.value,
                    entityLookups.listCorrespondenceChannel
                  )) ||
                "",
              regarding:
                (editCorrespondence &&
                  getLookupValue(
                    editCorrespondence.idRegarding,
                    regardingOptions
                  )) ||
                "",
              status:
                (editCorrespondence &&
                  getLookupValue(
                    editCorrespondence.status?.value,
                    entityLookups.listStatus
                  )) ||
                "",
              recipients:
                (editCorrespondence &&
                  getLookupValue(
                    recipientsList,
                    isInternal
                      ? entityLookups.listIndividualsInternal.concat(
                          entityLookups.listIndividualsExternal
                        )
                      : entityLookups.listIndividualsExternal,
                    true
                  )) ||
                [],
              files: (editCorrespondence && editCorrespondence.files) || [],
            }}
            validationSchema={
              projectSchemaValidations[this.state.activeTab - 1]
            }
          >
            {({
              errors,
              status,
              touched,
              values,
              handleChange,
              setTouched,
              setFieldValue,
            }) => (
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            {/* <Col className="col-12"> */}
                            {!isEdit && (
                              <div className="mb-3">
                                <Row>
                                  <Col sm={6}>
                                    <Label className="form-label day-required text-muted">
                                      {this.props.t("Correspondence Name")}
                                    </Label>
                                    <Field
                                      name="subject"
                                      size="big"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.subject && touched.subject
                                          ? " is-invalid"
                                          : "")
                                      }
                                      value={values.subject}
                                    />
                                    <ErrorMessage
                                      name="subject"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col sm={6}>
                                    <Label className="form-label day-required text-muted">
                                      {this.props.t("Recipients")}
                                    </Label>
                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("recipients", value);
                                      }}
                                      value={values.recipients}
                                      isMulti
                                      options={
                                        isInternal
                                          ? [
                                              {
                                                label: this.props.t("Internal"),
                                                options:
                                                  entityLookups.listIndividualsInternal,
                                              },
                                              {
                                                label: this.props.t("External"),
                                                options:
                                                  entityLookups.listIndividualsExternal,
                                              },
                                            ]
                                          : entityLookups.listIndividualsExternal
                                      }
                                    />
                                    <ErrorMessage
                                      name="recipients"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            )}

                            <div className="mb-3">
                              <Row>
                                <Col sm={6}>
                                  <Label className="form-label day-required text-muted">
                                    {this.props.t("Status").toUpperCase()}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("status", value);
                                    }}
                                    value={values.status}
                                    options={entityLookups.listStatus}
                                  />
                                  <ErrorMessage
                                    name="status"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Label className="form-label day-required text-muted">
                                    {this.props.t("Security Level")}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("securitylevel", value);
                                    }}
                                    value={values.securitylevel}
                                    options={
                                      entityLookups.listCorrespondenceSecurityLevel
                                    }
                                  />
                                  <ErrorMessage
                                    name="securitylevel"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Row>
                                <Col sm={6}>
                                  <Label className="form-label day-required text-muted">
                                    {this.props.t("Due Date Form")}
                                  </Label>
                                  <Field
                                    name="dueDate"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.dueDate && touched.dueDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={values.dueDate}
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Label className="form-label day-required text-muted">
                                    {this.props.t("PriorityForm")}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("priority", value);
                                    }}
                                    value={values.priority}
                                    options={
                                      entityLookups.listCorrespondencePriority
                                    }
                                  />
                                  <ErrorMessage
                                    name="priority"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Row>
                                <Col sm={12}>
                                  <Label className="form-label day-required text-muted">
                                    {this.props.t("Regarding").toUpperCase()}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("regarding", value);
                                    }}
                                    value={values.regarding}
                                    options={regardingOptions}
                                  />
                                  <ErrorMessage
                                    name="regarding"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Row>
                                <Col sm={12}>
                                  <Label className="form-label text-muted">
                                    {this.props.t("DescriptionForm")}
                                  </Label>
                                  <Field
                                    name="description"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.description && touched.description
                                        ? " is-invalid"
                                        : "")
                                    }
                                    as="textarea"
                                    value={values.description}
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <div className="mb-3">
                                <Row>
                                  <Col sm={6}>
                                    <Label className="form-label day-required text-muted">
                                      {this.props.t("Received On")}
                                    </Label>
                                    <Field
                                      name="receivedon"
                                      type="date"
                                      className={
                                        "form-control" +
                                        (errors.receivedon && touched.receivedon
                                          ? " is-invalid"
                                          : "")
                                      }
                                      value={values.receivedon}
                                    />
                                  </Col>

                                  <Col sm={6}>
                                    <Label className="form-label day-required text-muted">
                                      {this.props.t("Channel Code")}
                                    </Label>
                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("channelcode", value);
                                      }}
                                      value={values.channelcode}
                                      options={
                                        entityLookups.listCorrespondenceChannel
                                      }
                                    />
                                    <ErrorMessage
                                      name="channelcode"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <div className="mb-3">
                                <Col sm={12}>
                                  <Label className="form-label text-muted my-0">
                                    {this.props
                                      .t("Optional")
                                      .replace("*", this.props.t("Files"))}
                                  </Label>
                                  <FormUpload
                                    files={files}
                                    onFilesAccepted={onFilesAccepted}
                                    multiple
                                  />
                                </Col>
                              </div>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <RadioStepper
                      steps={isEdit ? [1] : [1, 2, 3]}
                      activeTab={this.state.activeTab}
                    />
                    <div className="actions clearfix" style={{ marginTop: 40 }}>
                      <ul className="day-text-end">
                        {this.state.activeTab !== 1 && (
                          <button
                            type="button"
                            className="btn customTable-button"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                            style={{ marginInlineEnd: 10 }}
                          >
                            {this.props.t("Previous")}
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn customTable-button"
                          disabled={!this.isValid(values, errors)}
                          onClick={e => {
                            e.preventDefault();
                            if (isEdit) {
                              this.props.handleSubmit(values);
                            } else {
                              if (this.state.activeTab === 3) {
                                this.props.handleSubmit(values);
                                this.toggleTab(1);
                              } else {
                                this.toggleTab(this.state.activeTab + 1);
                              }
                            }
                          }}
                        >
                          {isEdit
                            ? this.props.t("Update")
                            : this.state.activeTab === 3
                            ? this.props.t("create").toUpperCase()
                            : this.props.t("Next")}
                        </button>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

CreateCorrespondence.propTypes = {
  task: PropTypes.any,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  correspondence: PropTypes.any,
  handleSubmit: PropTypes.func,
  correspondences: PropTypes.array,
  correspondenceDetails: PropTypes.any,
  loading: PropTypes.bool,
  correspondenceData: PropTypes.any,
  isEdit: PropTypes.bool,
  entityLookups: PropTypes.any,
  files: PropTypes.any,
  onFilesAccepted: PropTypes.func,
};

export default withTranslation()(CreateCorrespondence);
