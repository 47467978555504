import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ENTITIES, isInternal, TOAST_OPTIONS } from "common";

import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  getQueryVariable,
  sortCaretIcons,
} from "common/utils";
import {
  createReferencedFiles,
  getAppointmentDetails,
  getCalendarFiles,
  getEntityLookups,
  getReferencedFiles,
  sendEmailToParticipants,
  uploadFiles,
  upsertAgenda,
  upsertAppointments,
  upsertTask,
} from "store/actions";
import Loading from "pages/Loading";
import CreateAgendaModal from "./create-agenda";
import { Colors, getGlobalHeaderStyle } from "common";
import CreateAppointmentModal from "./create-appointment";
import CreateTaskModal from "pages/Tasks/create-task-modal";
import FileItem from "components/Common/FileItem";
import FileList from "pages/FileManager/FileList";
import moment from "moment";
import { toast } from "react-toastify";
import AppointmentDetailsPDF from "components/Common/AppointmentDetailsPDF";
import { pdf } from "@react-pdf/renderer";
import EditIcon from "components/Common/EditIcon";
import Tippy from "@tippy.js/react";
import CustomTable from "../../components/Common/CustomTable";
import StatusText from "components/Common/StatusText";
import fileDownload from "js-file-download";
import Markdown from "components/Common/Markdown";
import {
  changeAppointmentByTimeZoneToUTC,
  changeAppointmentByTimeZoneToUTCForMoM,
} from "../../helpers/date_helper";

class CalendarDetails extends Component {
  constructor(props) {
    let createEntities = filterPermittedEntities(props.permissions, "create");
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    let readEntities = filterPermittedEntities(props.permissions, "read");
    super(props);
    this.state = {
      customActiveTab: "1",
      files: [],
      modal: false,
      taskModal: false,
      isEdit: false,
      itemDetails: {},
      isAgendaUpdate: false,
      editModal: false,
      createEntities,
      updateEntities,
      readEntities,
      isShareLoading: false,
      tabletStyle: props.tabletStyle,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,
      mobileStyle2: props.mobileStyle,
      minutesOfMeeting: "",
    };
    this.toggle = this.toggle.bind(this);
    this.toggleTask = this.toggleTask.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleAgendaCreate = this.handleAgendaCreate.bind(this);
    this.handleAgendaClicks = this.handleAgendaClicks.bind(this);
    this.handleAgendaUpdate = this.handleAgendaUpdate.bind(this);
    this.handleAppointmentUpdate = this.handleAppointmentUpdate.bind(this);
    this.handleTaskCreate = this.handleTaskCreate.bind(this);
    this.handleTaskClicks = this.handleTaskClicks.bind(this);
    this.handleMoMGeneration = this.handleMoMGeneration.bind(this);
    this.handleAddingLines = this.handleAddingLines.bind(this);
    this.handleMinutesOfMeetingUpdate =
      this.handleMinutesOfMeetingUpdate.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleTask() {
    this.setState(prevState => ({
      taskModal: !prevState.taskModal,
    }));
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidMount() {
    const {
      onGetAppointmentsDetails,
      onGetLookups,
      onGetReferencedFiles,
      onGetCalendarFiles,
    } = this.props;
    const appointmentId = getQueryVariable("id");
    const payload = {
      entity: ENTITIES.APPOINTMENT,
      idEntity: appointmentId,
    };
    onGetLookups(ENTITIES.TASK);
    onGetLookups(ENTITIES.APPOINTMENT, () => {
      onGetReferencedFiles(payload, () => {});
      onGetCalendarFiles(payload, () => {
        onGetAppointmentsDetails(appointmentId);
      });
    });
    this.setState({ filteredComments: this.state.comments });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
      //!prevProps.appointmentDetails?.appointment &&
        this.props.appointmentDetails?.appointment
      ) {
        this.setState({
          minutesOfMeeting:
            this.props.appointmentDetails?.appointment.minutesOfMeeting,
        });
      }
    }
  }
  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 2250 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 1780 });
    this.setState({ mobileStyle: window.innerWidth <= 555 });
    this.setState({ mobileStyle2: window.innerWidth <= 440 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  handleAddingLines = str => str.replace(/<br\s*[\/]?>/gi, "\n");

  async handleMoMGeneration(isDownload = false) {
    const { appointmentDetails, onSendEmailToParticipants } = this.props;
    this.setState({ isShareLoading: true }, async () => {
      const data = {
        subject: appointmentDetails.appointment.subject,
        organizer: appointmentDetails.appointment.organizer,
        location: appointmentDetails.appointment.location,
        startAt: changeAppointmentByTimeZoneToUTCForMoM(
          appointmentDetails.appointment.startAt
        ),
        minutesOfMeeting: this.handleAddingLines(
          appointmentDetails.appointment.minutesOfMeeting
        ),
        agendas: appointmentDetails.appointment.lstAgenda.map((item, idx) => ({
          ...item,
          idx: idx + 1,
        })),
        tasks: appointmentDetails.tasks.lstTasks.map((item, idx) => ({
          ...item,
          idx: idx + 1,
        })),
        attendees: [
          ...appointmentDetails.appointment.requiredAttendees,
          ...appointmentDetails.appointment.optionalAttendees,
        ].map((item, idx) => ({ ...item, idx: idx + 1 })),
      };

      const doc = <AppointmentDetailsPDF appointmentDetails={data} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      try{
        const blob = await asPdf.toBlob();
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        const fileName = `Meeting-Report_${moment()
          .locale("en")
          .format("DD/MM/YYYY")}.pdf`;
        if (isDownload) {
          fileDownload(blob, fileName);
          this.setState({ isShareLoading: false });
        } else {
          reader.onloadend = () => {
            var base64data = reader.result;
            const payload = {
              idAppointment: appointmentDetails.appointment.idAppointment,
              fileName,
              attachmentFile: base64data.split(",")[1], // to exclude base64 starting string
            };
            onSendEmailToParticipants(payload, () => {
              toast.success(
                this.props
                  .t("SuccessShare")
                  .replace("*", this.props.t("Appointment")),
                TOAST_OPTIONS
              );
            });
            this.setState({ isShareLoading: false });
          };
        }
      }
      catch(error){
        console.log('Error on generate meeting memo:', error)
        this.setState({ isShareLoading: false });
      }
     
    });
  }

  handleAgendaCreate(values) {
    const { onAddNewAgenda, appointmentDetails, onGetAppointmentsDetails } =
      this.props;
    let agenda = {
      idAppointment: appointmentDetails.appointment?.idAppointment,
      dataAgenda: {
        createdOn: values.createdOn,
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        idAgenda: "00000000-0000-0000-0000-000000000000",
        title: values.title,
      },
    };

    onAddNewAgenda(agenda, () => {
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Agenda")),
        TOAST_OPTIONS
      );
      onGetAppointmentsDetails(appointmentDetails.appointment?.idAppointment);
    });
    this.toggle();
  }

  toggleEdit() {
    this.setState({
      editModal: !this.state.editModal,
      isEdit: true,
    });
  }

  handleTaskCreate(values) {
    const {
      allTasks,
      appointmentDetails,
      onAddNewTask,
      onGetAppointmentsDetails,
    } = this.props;

    let task = {
      idTask: "00000000-0000-0000-0000-000000000000",
      taskNumber: "",
      title: values.name,
      regarding: {
        entityName: ENTITIES.APPOINTMENT,
        label: "",
        id: appointmentDetails.appointment.idAppointment,
      },
      dueDate: values.dueDate,
      createdOn: "",
      priority: {
        label: "",
        value: +values.priority.value,
      },
      assignedBy: values.assignedBy.value,
      assignedTo: {
        name: "",
        id: values.assignedTo.value,
        image: "",
        email: "",
      },
      status: null,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      organization: null,
    };

    onAddNewTask(task, () => {
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Task")),
        TOAST_OPTIONS
      );
      onGetAppointmentsDetails(appointmentDetails.appointment?.idAppointment);
    });
    this.toggleTask();
  }

  handleAgendaUpdate(values) {
    const { appointmentDetails, onGetAppointmentsDetails, onUpdateAgenda } =
      this.props;

    const agendaData = {
      idAppointment: appointmentDetails.appointment?.idAppointment,
      dataAgenda: {
        createdOn: values.createdOn,
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        idAgenda: values.idAgenda,
        title: values.title,
      },
    };
    const appointmentId = getQueryVariable("id");
    onUpdateAgenda(agendaData, () => {
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Agenda")),
        TOAST_OPTIONS
      );
      onGetAppointmentsDetails(appointmentId);
    });
    this.toggle();
  }

  handleAppointmentUpdate(values) {
    const {
      appointmentDetails,
      onUpdateAppointment,
      onGetAppointmentsDetails,
    } = this.props;

    const startBefore = values.startAt;
    const startBefore2 = startBefore.slice(0, 19);
    let str = ":00Z";
    let startAt = startBefore2.concat(str);

    const endBefore = values.endAt;
    const endBefore2 = endBefore.slice(0, 19);
    let endAt = endBefore2.concat(str);

    let attendees = values.attendees?.map(item => ({
      entityName: ENTITIES.INDIVIDUALS,
      id: item.value,
      label: "",
      labelAr: "",
    }));
    let optionalAttendees = values.optionalAttendees?.map(item => ({
      entityName: ENTITIES.INDIVIDUALS,
      id: item.value,
      label: "",
      labelAr: "",
    }));
    const appData = {
      isCancelAppointment: false,
      dataAppointments: {
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        endAt: values.endAt,
        idAppointment: appointmentDetails.appointment?.idAppointment,
        isAllDayEvent: values.checkEvent,
        location: values.location,
        lstAgenda: null,
        idOrganization: values.idOrganization?.value,
        minutesOfMeeting: appointmentDetails.appointment.minutesOfMeeting,
        regarding: {
          entityName: values.regarding.entityName,
          id: values.regarding.value,
        },
        requiredAttendees: attendees,
        optionalAttendees,
        startAt: values.startAt,
        subject: values.subject,
      },
    };

    onUpdateAppointment(appData, () => {
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Appointment")),
        TOAST_OPTIONS
      );
      onGetAppointmentsDetails(appointmentDetails.appointment?.idAppointment);
    });
    this.toggleEdit();
  }

  handleMinutesOfMeetingUpdate(callback) {
    const {
      appointmentDetails,
      onUpdateAppointment,
      onGetAppointmentsDetails,
    } = this.props;
    const getMappedAttendees = (list = []) =>
      list.map(item => ({
        entityName: item.entityName,
        id: item.id,
        label: "",
        labelAr: "",
      }));
    const convertedLines = this.state.minutesOfMeeting.replace(
      /(?:\r\n|\r|\n)/g,
      "<br>"
    );

    const appData = {
      isCancelAppointment: false,
      dataAppointments: {
        description: formatLineBreaksInMarkdownFriendlyFormat(
          appointmentDetails.appointment.description
        ),
        endAt: changeAppointmentByTimeZoneToUTC(
          appointmentDetails.appointment.endAt
        ),
        duration: appointmentDetails.appointment.duration,
        idAppointment: appointmentDetails.appointment?.idAppointment,
        isAllDayEvent: appointmentDetails.appointment.isAllDayEvent,
        location: appointmentDetails.appointment.location,
        lstAgenda: appointmentDetails.appointment.lstAgenda,
        idOrganization: appointmentDetails.appointment.idOrganization,
        minutesOfMeeting: convertedLines,
        regarding: {
          entityName: appointmentDetails.appointment.regarding.entityName,
          id: appointmentDetails.appointment.regarding.id,
        },
        requiredAttendees: getMappedAttendees(
          appointmentDetails.appointment.requiredAttendees
        ),
        optionalAttendees: getMappedAttendees(
          appointmentDetails.appointment.optionalAttendees
        ),
        startAt: changeAppointmentByTimeZoneToUTC(
          appointmentDetails.appointment.startAt
        ),
        subject: appointmentDetails.appointment.subject,
      },
    };
    onUpdateAppointment(appData, () => {
      toast.success(
        this.props
          .t("SuccessUpdate")
          .replace("*", this.props.t("minutesOfMeeting")),
        TOAST_OPTIONS
      );
      onGetAppointmentsDetails(
        appointmentDetails.appointment?.idAppointment,
        () => {
          callback && callback();
        }
      );
    });
  }

  handleAgendaClicks = () => {
    this.setState({ isEdit: false, isAgendaUpdate: false, itemDetails: {} });
    this.toggle();
  };

  handleUpdateAgendaClicks = item => {
    this.setState({ itemDetails: item, isAgendaUpdate: true });
    this.toggle();
  };

  handleCancelAgenda = () => {
    const { appointmentDetails, onUpdateAppointment } = this.props;
    const startBefore = new Date(
      appointmentDetails.appointment.startAt
    ).toISOString();
    const startBefore2 = startBefore.slice(0, 19);
    let str = "Z";
    let startAt = startBefore2.concat(str);

    const endBefore = new Date(
      appointmentDetails.appointment.endAt
    ).toISOString();
    const endBefore2 = endBefore.slice(0, 19);
    let endAt = endBefore2.concat(str);
    let attendees = appointmentDetails.appointment.requiredAttendees?.map(
      item => ({
        entityName: ENTITIES.INDIVIDUALS,
        id: item.id,
        label: "",
        labelAr: "",
      })
    );
    let optionalAttendees =
      appointmentDetails.appointment.optionalAttendees?.map(item => ({
        entityName: ENTITIES.INDIVIDUALS,
        id: item.id,
        label: "",
        labelAr: "",
      }));
    const appData = {
      isCancelAppointment: true,
      dataAppointments: {
        description: formatLineBreaksInMarkdownFriendlyFormat(
          appointmentDetails.appointment.description
        ),
        endAt: endAt,
        idAppointment: appointmentDetails.appointment?.idAppointment,
        isAllDayEvent: appointmentDetails.appointment.isAllDayEvent,
        location: appointmentDetails.appointment.location,
        lstAgenda: null,
        idOrganization: appointmentDetails.appointment.idOrganization,
        minutesOfMeeting: appointmentDetails.appointment.minutesOfMeeting,
        regarding: {
          entityName: appointmentDetails.appointment.regarding.entityName,
          id: appointmentDetails.appointment.regarding.id,
        },
        requiredAttendees: attendees,
        optionalAttendees,
        startAt: startAt,
        subject: appointmentDetails.appointment.subject,
      },
    };
    onUpdateAppointment(appData, () => {
      toast.success(
        this.props
          .t("SuccessCancelled")
          .replace("*", this.props.t("Appointment")),
        TOAST_OPTIONS
      );
      setTimeout(() => {
        window.location.href = "/calendar";
      }, 1000);
    });

    // this.handleAppointmentUpdate(obj, isCancelAppointment);
  };
  handleTaskClicks = () => {
    this.setState({ isEdit: false });
    this.toggleTask();
  };

  renderAttendee(item, idx, isOrganizer = false, isOptional = false) {
    return (
      <Tippy
        content={this.props.t("View More")}
        placement="top-start"
        animation="scale-subtle"
        theme="light"
        arrow={false}
        duration={200}
        delay={[75, 0]}
        distance={5}
        key={idx}
      >
        <Link to={`/individual-details?id=${item.id}`}>
          <div className="avatar-sm profile-user-wid day-row-nowrap my-2">
            <div className="avatar-xs me-1">
              {!!item?.image ? (
                <div className="rounded-circle avatar-sm">
                  <img
                    src={`data:image/png;base64,${item?.image}`}
                    className="rounded-circle avatar"
                    style={{ height: 32, width: 32 }}
                  />
                </div>
              ) : (
                <div
                  className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-md"
                  style={{ padding: 16 }}
                >
                  <i className="bx bxs-user bx-sm"></i>
                </div>
              )}
            </div>
            <p
              className={`text-nowrap mx-2 my-3 calendarText ${
                isOptional && "text-muted"
              }`}
            >
              {item?.label}
            </p>
            <p
              className={`text-nowrap my-3 calendarText ${
                isOptional && "text-muted"
              }`}
            >
              {isOptional
                ? this.props.t("OptionalAttendee")
                : isOrganizer
                ? this.props.t("Organizer")
                : item.isExternalUser
                ? this.props.t("ExternalAttendee")
                : this.props.t("InternalAttendee")}
            </p>
          </div>
        </Link>
      </Tippy>
    );
  }
  render() {
    const {
      loading,
      appointmentDetails,
      allTasks,
      loadingUpload,
      loadingLookups,
      loadingTasks,
    } = this.props;
    const {
      isEdit,
      updateEntities,
      createEntities,
      readEntities,
      isShareLoading,
      itemDetails,
      isAgendaUpdate,
    } = this.state;
    if (
      loading ||
      !appointmentDetails ||
      loadingUpload ||
      loadingLookups ||
      isShareLoading ||
      loadingTasks
    ) {
      return <Loading />;
    }
    const tasksColumns = [
      {
        text: this.props.t("ID"),
        dataField: "id",
        hidden: true,
      },
      {
        hidden: this.state.mobileStyle2,
        dataField: "taskNumber",
        text: this.props.t("Task ID"),
        formatter: (cellContent, task) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/task-details?id=" + task.idTask}
                query={task}
                className="text-primary text-decoration-underline"
              >
                {`#${task.taskNumber}`}
              </Link>
            </h5>
          </>
        ),
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: !this.state.mobileStyle2,
        text: this.props.t("Task Name"),
        dataField: "title",
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
        formatter: (cellContent, task) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/task-details?id=" + task.id}
                query={task}
                className="text-primary text-decoration-underline"
              >
                {task.title}
              </Link>
            </h5>
          </>
        ),
      },
      {
        hidden: this.state.mobileStyle2,
        text: this.props.t("Task Name"),
        dataField: "title",
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        dataField: "regarding.label",
        text: this.props.t("Task is for").toUpperCase(),
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        dataField: "assignedBy.name",
        text: this.props.t("Assigned By"),
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
        formatter: (cell, task) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/individual-details?id=" + task.assignedBy?.id}
                className="text-dark"
              >
                {task.assignedBy?.name}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.mobileStyle,
        dataField: "assignedTo.name",
        text: this.props.t("Assigned To"),
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
        formatter: (cell, task) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/individual-details?id=" + task.assignedTo?.id}
                className="text-dark"
              >
                {task.assignedTo?.name}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "createdOn",
        text: this.props.t("Date Created").toUpperCase(),
        sort: true,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, task) => (
          <>
            <p className="mb-0">{moment(task.createdOn).format("DD/MM/YY")}</p>
          </>
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "dueDate",
        text: this.props.t("Due Date Form"),
        sort: true,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, task) => (
          <>
            <p className="mb-0">{moment(task.dueDate).format("DD/MM/YY")}</p>
          </>
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        dataField: "status",
        text: this.props.t("StatusForm"),
        sort: true,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, task) => (
          <StatusText
            text={task.status ? task.status.label?.toUpperCase() : ""}
          />
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs
            title={this.props.t("Calendar")}
            titleLinkTo={"/calendar"}
            breadcrumbItem={appointmentDetails.appointment.subject}
          />
          <Row className="g-1">
            <Col lg={6} className="g-1">
              <Card>
                <CardBody style={{ height: 572 }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="mainTitle">
                        {this.props.t("PDF_MeetingInformation")}
                      </p>
                    </div>
                    <div className="day-row">
                      <FileList
                        path={[
                          "appointments",
                          appointmentDetails.appointment.subject,
                        ]}
                        payload={{
                          entity: ENTITIES.APPOINTMENT,
                          idEntity:
                            appointmentDetails.appointment.idAppointment,
                        }}
                        files={this.props.files}
                        showFilesOnly
                        uploadButtonOnly
                        showUploadType
                      />
                      {updateEntities.includes(ENTITIES.APPOINTMENT) &&
                        (appointmentDetails.appointment.isOrganizer ||
                          appointmentDetails.appointment.canEdit) && (
                          <EditIcon onClick={this.toggleEdit} />
                        )}
                      {moment().isAfter(
                        moment(appointmentDetails.appointment.startAt)
                      ) && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "transparent",
                            border: "0px",
                          }}
                          onClick={async () => {
                            this.handleMoMGeneration(false);
                          }}
                        >
                          <i
                            className="bx bxs-share-alt d-block font-size-18"
                            style={{ color: Colors.grey }}
                          ></i>
                        </button>
                      )}
                    </div>
                  </div>
                  <p className="mb-1 text-muted">
                    {this.props.t("Meeting Description").toUpperCase()}
                  </p>
                  <Markdown>
                    {appointmentDetails.appointment.description}
                  </Markdown>
                  <p className="mb-1 mt-3 text-muted">
                    {this.props.t("Attachments").toUpperCase()}
                  </p>
                  <div className="day-row-wrap">
                    {this.props.referencedFiles &&
                    this.props.referencedFiles.length > 0
                      ? this.props.referencedFiles
                          .filter(f => (isInternal ? true : !f.isInternal))
                          .map((f, idx) => {
                            return <FileItem file={f} key={idx} />;
                          })
                      : this.props.t("None")}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="mb-1 mt-3 text-muted">
                        {this.props.t("location").toUpperCase()}
                      </p>
                      {appointmentDetails.appointment.location.startsWith(
                        "http"
                      ) ? (
                        <a
                          href={appointmentDetails.appointment.location}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {appointmentDetails.appointment.location}
                        </a>
                      ) : (
                        <p>{appointmentDetails.appointment.location}</p>
                      )}
                    </div>
                    {appointmentDetails.appointment.isOrganizer && (
                      <Button
                        className="customTable-button"
                        size="lg"
                        onClick={this.handleCancelAgenda}
                        style={{ height: "50%", marginTop: 20 }}
                      >
                        {this.props.t("cancel")}
                      </Button>
                    )}
                  </div>
                  <hr className="text-muted" />
                  <p className="mb-1 text-muted">
                    {this.props.t("Attendees").toUpperCase()}
                  </p>
                  <div
                    className="scrollbar"
                    style={{
                      overflowY: "scroll",
                      height: "280px",
                    }}
                  >
                    {this.renderAttendee(
                      appointmentDetails.appointment.organizer,
                      -1,
                      true
                    )}
                    {appointmentDetails.appointment.requiredAttendees?.map(
                      (item, idx) => {
                        return this.renderAttendee(item, idx);
                      }
                    )}
                    {appointmentDetails.appointment.optionalAttendees?.map(
                      (item, idx) => {
                        return this.renderAttendee(item, idx, false, true);
                      }
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} className="g-1">
              <Nav
                tabs
                className="nav-tabs-custom"
                style={{
                  display: "flex",
                  flexGrow: 1,
                  height: 50,
                  backgroundColor: "white",
                }}
              >
                <NavItem
                  style={{
                    display: "flex",
                    height: "100%",
                    flexWrap: "nowrap",
                  }}
                >
                  <NavLink
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    className={classnames({
                      active: this.state.customActiveTab === "1",
                      "text-muted": this.state.customActiveTab !== "1",
                    })}
                    onClick={() => {
                      this.toggleCustom("1");
                    }}
                  >
                    <span className="font-size-18 text-nowrap">
                      {this.props.t("Agenda")}
                    </span>
                  </NavLink>
                </NavItem>
                {readEntities.includes(ENTITIES.TASK) && (
                  <NavItem
                    style={{
                      display: "flex",
                      height: "100%",
                      flexWrap: "nowrap",
                    }}
                  >
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classnames({
                        active: this.state.customActiveTab === "2",
                        "text-muted": this.state.customActiveTab !== "2",
                      })}
                      onClick={() => {
                        this.toggleCustom("2");
                      }}
                    >
                      <span className="d-sm-block font-size-18 text-nowrap">
                        {this.props.t("Tasks")}
                      </span>
                    </NavLink>
                  </NavItem>
                )}
                <NavItem
                  style={{
                    display: "flex",
                    height: "100%",
                    flexWrap: "nowrap",
                  }}
                >
                  <NavLink
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    className={classnames({
                      active: this.state.customActiveTab === "3",
                      "text-muted": this.state.customActiveTab !== "3",
                    })}
                    onClick={() => {
                      this.toggleCustom("3");
                    }}
                  >
                    <span className="d-sm-block font-size-18 text-nowrap">
                      {this.props.t("minutesOfMeeting")}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={this.state.customActiveTab}
                style={{ height: 432, backgroundColor: "white" }}
              >
                <TabPane
                  tabId="1"
                  className="scrollbar"
                  style={{ maxHeight: 433, overflowY: "scroll" }}
                >
                  {appointmentDetails.appointment.lstAgenda?.map(
                    (item, idx) => {
                      return (
                        <Card key={idx} style={{ marginBottom: 4 }}>
                          <CardBody>
                            <>
                              <div className="day-row day-justify-between">
                                <p
                                  className="mainTitle"
                                  style={{ marginBottom: "0" }}
                                >
                                  {item.title}
                                </p>
                                <EditIcon
                                  onClick={() => {
                                    this.handleUpdateAgendaClicks(item);
                                  }}
                                />
                              </div>
                              <Markdown>{item.description}</Markdown>
                              <div className="day-row">
                                <div className="avatar-xs">
                                  {!!item?.addedBy.image ? (
                                    <div className="rounded-circle avatar-sm">
                                      <img
                                        src={`data:image/png;base64,${item?.addedBy.image}`}
                                        className="rounded-circle avatar"
                                        style={{ height: 32, width: 32 }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-md"
                                      style={{ padding: 16 }}
                                    >
                                      <i className="bx bxs-user bx-sm"></i>
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex flex-column">
                                  <p className="text-nowrap my-2 mx-2 font-size-12">
                                    {item.addedBy?.label}
                                  </p>
                                  <p className="text-nowrap font-size-12 text-muted mx-2">
                                    {/* at 12:10pm, 24 April 2022 */}
                                    {`${this.props.t("at")} ${moment(
                                      item.createdOn
                                    ).format("hh:mma, DD MMMM YYYY")}`}
                                  </p>
                                </div>
                              </div>
                            </>
                          </CardBody>
                        </Card>
                      );
                    }
                  )}
                </TabPane>
                <TabPane tabId="2" style={{ maxHeight: 433 }}>
                  <Card>
                    <CardBody>
                      {/* <CardTitle>{this.props.t("Comments")}</CardTitle> */}
                      <CustomTable
                        columns={tasksColumns}
                        data={appointmentDetails.tasks?.lstTasks}
                        addBtnText={this.props.t("Add Task")}
                        placeholderText={this.props.t("Search Tasks")}
                        handleAddClick={this.handleAddTask}
                        hideAddBtn={!createEntities.includes(ENTITIES.TASK)}
                        defaultSortedField="createdOn"
                      />
                    </CardBody>
                  </Card>
                </TabPane>

                <TabPane tabId="3" style={{ height: 433 }}>
                  <Card style={{ height: "100%" }}>
                    <CardBody style={{ height: "100%" }}>
                      <textarea
                        readOnly={!appointmentDetails.appointment.isOrganizer}
                        className={`form-control`}
                        placeholder={this.props.t("Start typing here")}
                        style={{
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        }}
                        value={this.handleAddingLines(
                          this.state.minutesOfMeeting
                        )}
                        onChange={e => {
                          this.setState({ minutesOfMeeting: e.target.value });
                        }}
                      />
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
              {this.state.customActiveTab === "1" &&
                createEntities.includes(ENTITIES.APPOINTMENT) && (
                  <Card>
                    <CardBody>
                      <Button
                        className="customTable-button"
                        size="lg"
                        onClick={this.handleAgendaClicks}
                        style={{
                          marginTop: 10,
                          width: 250,
                          height: 40,
                        }}
                      >
                        {this.props.t("Create Agenda")}
                      </Button>
                    </CardBody>
                  </Card>
                )}
              {this.state.customActiveTab === "2" &&
                createEntities.includes(ENTITIES.TASK) && (
                  <Card>
                    <CardBody>
                      <Button
                        className="customTable-button"
                        size="lg"
                        onClick={this.handleTaskClicks}
                        style={{
                          marginTop: 10,
                          width: 250,
                          height: 40,
                        }}
                      >
                        {this.props.t("Create Task")}
                      </Button>
                    </CardBody>
                  </Card>
                )}
              {this.state.customActiveTab === "3" && (
                <Card>
                  <CardBody>
                    <Button
                      className="customTable-button"
                      size="lg"
                      onClick={() => {
                        this.handleMinutesOfMeetingUpdate(() => {
                          this.handleMoMGeneration(true);
                        });
                      }}
                      style={{
                        marginTop: 10,
                        width: 300,
                        height: 40,
                      }}
                    >
                      {this.props.t("Create Minutes Of Meeting")}
                    </Button>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
        <CreateAgendaModal
          modal={this.state.modal}
          toggle={this.toggle}
          handleSubmit={
            isAgendaUpdate ? this.handleAgendaUpdate : this.handleAgendaCreate
          }
          isEdit={isAgendaUpdate}
          editAgenda={itemDetails}
        />
        <CreateTaskModal
          modal={this.state.taskModal}
          toggle={this.toggleTask}
          handleSubmit={this.handleTaskCreate}
          isEdit={false}
          files={this.state.files}
          onFilesAccepted={files => {
            this.setState({ files });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.TASK]}
        />

        {this.state.editModal && (
          <CreateAppointmentModal
            modal={this.state.editModal}
            isEdit={true}
            editAppointment={appointmentDetails.appointment}
            toggle={this.toggleEdit}
            handleSubmit={this.handleAppointmentUpdate}
            entityLookups={this.props.entityLookups?.[ENTITIES.APPOINTMENT]}
          />
        )}
      </React.Fragment>
    );
  }
}

CalendarDetails.propTypes = {
  calendarDetails: PropTypes.any,
  t: PropTypes.any,
  onGetAppointmentsDetails: PropTypes.func,
  loading: PropTypes.bool,
  appointmentDetails: PropTypes.object,
  onAddNewAgenda: PropTypes.func,
  onUpdateAgenda: PropTypes.func,
  onUpdateAppointment: PropTypes.func,
  entityLookups: PropTypes.any,
  allTasks: PropTypes.array,
  onAddNewTask: PropTypes.func,
  referencedFiles: PropTypes.any,
  files: PropTypes.any,
  onGetReferencedFiles: PropTypes.func,
  onCreateReferencedFile: PropTypes.func,
  onUploadFiles: PropTypes.func,
  onGetLookups: PropTypes.func,
  onGetCalendarFiles: PropTypes.func,
  loadingUpload: PropTypes.bool,
  loadingLookups: PropTypes.bool,
  permissions: PropTypes.any,
  onSendEmailToParticipants: PropTypes.func,
  loadingTasks: PropTypes.bool,
  tabletStyle: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  mobileStyle2: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = ({ calendar, Layout, tasks, files }) => ({
  calendarDetails: calendar.calendarDetails,
  appointmentDetails: calendar.appointmentDetails,
  loading: calendar.loading,
  allTasks: tasks.allTasks,
  loadingTasks: tasks.loading,
  referencedFiles: files.referencedFiles,
  files: calendar.files,
  loadingLookups: Layout.loadingLookups,
  permissions: Layout.permissions,
  entityLookups: Layout.entityLookups,
  loadingUpload: files.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetAppointmentsDetails: (payload, callback) =>
    dispatch(getAppointmentDetails(payload, callback)),
  onAddNewAgenda: (payload, callback) =>
    dispatch(upsertAgenda(payload, callback)),
  onUpdateAgenda: (payload, callback) =>
    dispatch(upsertAgenda(payload, callback)),
  onUpdateAppointment: (payload, callback) =>
    dispatch(upsertAppointments(payload, callback)),
  onAddNewTask: (newTask, callback) => dispatch(upsertTask(newTask, callback)),
  onGetReferencedFiles: (payload, callback) =>
    dispatch(getReferencedFiles(payload, callback)),
  onCreateReferencedFile: (payload, callback) =>
    dispatch(createReferencedFiles(payload, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetCalendarFiles: (payload, callback) =>
    dispatch(getCalendarFiles(payload, callback)),
  onSendEmailToParticipants: (payload, callback) =>
    dispatch(sendEmailToParticipants(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(CalendarDetails)));
