import React from "react";
import PropTypes from "prop-types";
import { Colors } from "common";

const EditIcon = ({ onClick, className, style }) => {
  return (
    <button
      type="button"
      className="btn btn-primary"
      style={{
        backgroundColor: "transparent",
        border: "0px",
        paddingLeft: 0,
        paddingRight: 0,
        paddingInlineStart: 10,
        paddingInlineEnd: 10,
        ...(!!style ? style : {}),
      }}
      onClick={onClick}
    >
      <i
        className={`mdi mdi-pencil ${
          !!className ? className : "d-block font-size-18"
        }`}
        style={{ color: Colors.grey }}
      ></i>
    </button>
  );
};

EditIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.any,
};
export default EditIcon;
