import React, { Component } from "react";
import PropTypes from "prop-types";
import { Colors, COLOR_COMBOS } from "common";
import { withTranslation } from "react-i18next";

class LegendText extends Component {
  render() {
    let { text } = this.props;
    let color = COLOR_COMBOS.yellow.text;
    if (text?.trim()) {
      text = text.toUpperCase();
      if (
        text.includes("PENDING") ||
        text.includes("PROGRESS") ||
        text.includes("EXTERNAL") ||
        text.includes("ONGOING") ||
        text.includes("MEDIUM")
      ) {
        color = Colors.yellow;
      } else if (text.includes("HOLD")) {
        color = COLOR_COMBOS.dark.text;
      } else if (text.includes("OPEN")) {
        color = COLOR_COMBOS.lightBlue.text;
      } else if (text.includes("NEW")) {
        color = COLOR_COMBOS.pink.text;
      } else if (text.includes("FUTURE") || text.includes("DRAFT")) {
        color = COLOR_COMBOS.grey.text;
      } else if (
        text.includes("HIGH") ||
        text.includes("DELAYED") ||
        text.includes("Delayed") ||
        text.includes("ADMIN") ||
        text.includes("URGENT")
      ) {
        color = Colors.red;
      } else if (
        text.includes("TRACK") ||
        text.includes("APPROVED") ||
        text.includes("SIGNED") ||
        text.includes("LOW")
      ) {
        color = Colors.green;
      } else if (text.includes("COMPLETED") || text.includes("INTERNAL")) {
        color = COLOR_COMBOS.blue.text;
      }
    }
    return (
      <div
        className="day-row font-size-12"
        style={{
          marginInlineEnd: 10,
        }}
      >
        <div style={{ width: 14, marginInlineEnd: 5 }}>
          <div
            style={{
              height: 12,
              width: 12,
              backgroundColor: !!this.props.color ? this.props.color : color,
              borderRadius: "50%",
            }}
          />
        </div>
        <p className="mb-0 text-muted">
          {!!this.props.displayText
            ? this.props.t(this.props.displayText)
            : this.props.t(this.props.text)}
        </p>
      </div>
    );
  }
}

LegendText.propTypes = {
  text: PropTypes.string,
  displayText: PropTypes.string,
  color: PropTypes.string,
  t: PropTypes.any,
};

export default withTranslation()(LegendText);
