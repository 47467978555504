import React, { Component } from "react";
import PropTypes from "prop-types";
import adequateIcon from "../../assets/icons/Adequate.svg";
import needHelpIcon from "../../assets/icons/needHelp.svg";
import rockstarIcon from "../../assets/icons/rockstar.svg";
import { withTranslation } from "react-i18next";

class Rating extends Component {
  render() {
    let ratingStyle = "d-flex align-items-center justify-content-center h-25 ";
    let labelStyle = "";
    let value = "";
    let icon = "";
    if (this.props.value < 6.5) {
      value = this.props.t("Needs Help");
      ratingStyle = ratingStyle + "needHelp-dev";
      labelStyle = "needHelp-label";
      icon = needHelpIcon;
    } else if (this.props.value > 6.5 && this.props.value < 8.5) {
      value = this.props.t("Adequate");
      ratingStyle = ratingStyle + "adequate-dev";
      labelStyle = "adequate-label";
      icon = adequateIcon;
    } else if (this.props.value > 8.5) {
      value = this.props.t("Rock Star");
      ratingStyle = ratingStyle + "rockstar-dev";
      labelStyle = "rockstar-label";
      icon = rockstarIcon;
    }
    return (
      <div className={ratingStyle}>
        <div className={labelStyle}>{`${value} (${this.props.value})`}</div>
        <img src={icon} />
      </div>
    );
  }
}

Rating.propTypes = {
  value: PropTypes.number,
  t: PropTypes.any,
};
export default withTranslation()(Rating);
