import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getTasks, getAllTasks, getTaskFiles } from "../../store/tasks/actions";
import { withTranslation } from "react-i18next";
import CreateTaskModal from "./create-task-modal";
import {
  getTaskDetails,
  upsertTask,
  upsertComments,
} from "../../store/tasks/actions";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  getQueryVariable,
} from "common/utils";
import moment from "moment";
import {
  Colors,
  ENTITIES,
  isInternal,
  TOAST_OPTIONS,
  UPLOAD_TYPES,
} from "common";
import Loading from "pages/Loading";
import StatusText from "components/Common/StatusText";
import Comments from "components/Common/Comments";
import FileList from "pages/FileManager/FileList";
import {
  createReferencedFiles,
  getEntityLookups,
  getReferencedFiles,
  uploadFiles,
  deleteTask,
} from "store/actions";
import CustomTextArea from "components/Common/CustomTextArea";
import FileItem from "components/Common/FileItem";
import { toast } from "react-toastify";
import EditIcon from "components/Common/EditIcon";
import DeleteIcon from "components/Common/DeleteIcon";
import Markdown from "components/Common/Markdown";
import DeleteModal from "components/Common/DeleteModal";

class TaskDetails extends Component {
  constructor(props) {
    super(props);
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    this.state = {
      value: "",
      acceptedFiles: [],
      modal: false,
      reason: "",
      action: "1",
      allTasks: [],
      commentTypes: [
        { name: "All", value: "1" },
        { name: "Comments", value: "2" },
        { name: "History", value: "3" },
        { name: "Work log", value: "4" },
      ],
      comment: "",
      selectedCommentType: "1",
      editModal: false,
      deleteModal: false,
      updateEntities,
      attachedFile: null,
      values: null,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleTaskUpdate = this.handleTaskUpdate.bind(this);
    this.handleCommentCreate = this.handleCommentCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteTask = this.handleDeleteTask.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  componentDidMount() {
    const {
      onGetTaskDetails,
      onGetTaskFiles,
      onGetLookups,
      onGetReferencedFiles,
    } = this.props;
    const taskId = getQueryVariable("id");
    const payload = {
      entity: ENTITIES.TASK,
      idEntity: taskId,
    };
    onGetLookups(ENTITIES.TASK, () => {
      onGetReferencedFiles(payload, () => {});
      onGetTaskFiles(payload, () => {
        onGetTaskDetails(taskId);
      });
    });
  }

  toggleEdit() {
    this.setState(prevState => ({
      editModal: !prevState.editModal,
    }));
  }

  toggleDelete() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  }

  async handleDeleteTask() {
    const { onDeleteTask, taskDetails } = this.props;
    try {
      const requestObject = {
        request: taskDetails.task.idTask,

        basicParameters: {
          entity: ENTITIES.TASK,
        },
      };

      await onDeleteTask(requestObject, () => {
        this.setState({
          deleteModal: false,
        });
        toast.success(
          this.props.t("SuccessDelete").replace("*", this.props.t("Task")),
          TOAST_OPTIONS
        );
        setTimeout(() => {
          window.location.href = "/tasks";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleCommentCreate() {
    const {
      onAddNewComment,
      taskDetails,
      onGetTaskDetails,
      onCreateReferencedFile,
      onUploadFiles,
    } = this.props;
    const values = JSON.parse(JSON.stringify(this.state.values));
    const attachedFile = !!this.state.attachedFile
      ? values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER
        ? new File([this.state.attachedFile], this.state.attachedFile.name, {
            type: this.state.attachedFile.type,
          })
        : JSON.parse(JSON.stringify(this.state.attachedFile))
      : false;
    let comment = {
      commentedBy: null,
      commentedOn: "",
      description: formatLineBreaksInMarkdownFriendlyFormat(this.state.comment),
      idComments: "00000000-0000-0000-0000-000000000000",
      regarding: {
        entityName: ENTITIES.TASK,
        id: taskDetails.task?.idTask,
        label: taskDetails.task?.title,
        labelAr: "",
      },
    };

    onAddNewComment(comment, id => {
      if (!!attachedFile) {
        if (values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER) {
          // do file upload
          const payload = {
            request: {
              updates: [],
              allowExternalUsers: values.isExternalAllowed,
              allowDownloads: values.isDownloadAllowed,
              allowComments: values.isCommentsAllowed,
              createReference: true,
              path: `comments/${id}`,
              parent: "00000000-0000-0000-0000-000000000000",
            },
            files: [attachedFile],
          };

          onUploadFiles(payload, () => {
            onGetTaskDetails(taskDetails.task?.idTask);
            toast.success(
              this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
              TOAST_OPTIONS
            );
            this.setState({ comment: "" });
          });
        } else {
          let _payload = {
            idDocument: attachedFile.idDocument,
            entity: ENTITIES.COMMENT,
            idEntity: id,
          };
          onCreateReferencedFile(_payload, () => {
            onGetTaskDetails(taskDetails.task?.idTask);
            toast.success(
              this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
              TOAST_OPTIONS
            );
            this.setState({ comment: "" });
          });
        }
      } else {
        onGetTaskDetails(taskDetails.task?.idTask);
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
          TOAST_OPTIONS
        );
        this.setState({ comment: "" });
      }
    });
  }

  handleTaskUpdate(values) {
    const { taskDetails, onGetTaskDetails, onUpdateTask } = this.props;

    const taskData = {
      idTask: taskDetails.task.id,
      taskNumber: taskDetails.task.taskNumber,
      title: values.name,
      regarding: taskDetails.task.regarding,
      dueDate: values.dueDate,
      priority: {
        label: "",
        value: +values.priority.value,
      },
      assignedBy: taskDetails.task.assignedBy,
      assignedTo: {
        name: "",
        id: values.assignedTo.value,
        image: "",
        email: "",
      },
      status: {
        label: "",
        value: +values.status.value,
      },
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
    };

    onUpdateTask(taskData, () => {
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Task")),
        TOAST_OPTIONS
      );
      onGetTaskDetails(taskDetails.task.id);
    });

    this.toggleEdit();
  }

  render() {
    const { acceptedFiles, updateEntities } = this.state;
    const {
      taskDetails,
      loadingUpload,
      loading,
      loadingLookups,
      tasksPageNumber,
      statusCode,
    } = this.props;
    if (loading || !taskDetails || loadingUpload || loadingLookups) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <CreateTaskModal
            modal={this.state.editModal}
            isEdit={true}
            task={{
              ...taskDetails.task,
              dueDate: taskDetails.task.dueDate
                ? moment(taskDetails.task.dueDate).format("YYYY-MM-DD")
                : "",
            }}
            toggle={this.toggleEdit}
            handleSubmit={this.handleTaskUpdate}
            files={acceptedFiles}
            onFilesAccepted={acceptedFiles => {
              this.setState({ acceptedFiles });
            }}
            entityLookups={this.props.entityLookups?.[ENTITIES.TASK]}
          />
          <DeleteModal
            onCloseClick={this.toggleDelete}
            onDeleteClick={this.handleDeleteTask}
            show={this.state.deleteModal}
          />
          <MetaTags>
            <title>{this.props.t("Tasks Title")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Tasks")}
              titleLinkTo={
                "/tasks?page=" + tasksPageNumber + "&status=" + statusCode
              }
              breadcrumbItem={this.props.t("Task Details")}
            />
            <Row className="g-1">
              <Col sm={9}>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle>
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p className="mainTitle">
                              {taskDetails.task.title}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: -10,
                                marginInlineStart: 4,
                              }}
                            >
                              <div
                                className="text-muted font-size-12"
                                style={{
                                  fontWeight: "normal",
                                  marginInlineEnd: 20,
                                }}
                              >
                                <Link
                                  to={
                                    "/organization-details?id=" +
                                    taskDetails.task.organization.id
                                  }
                                >
                                  <i className="bx bx-user"></i>{" "}
                                  {taskDetails.task.organization.label}
                                </Link>
                              </div>
                              <div
                                className="text-muted font-size-12"
                                style={{
                                  fontWeight: "normal",
                                  marginInlineEnd: 20,
                                }}
                              >
                                <i className="mdi mdi-email-outline"> </i>
                                {taskDetails.task.assignedBy?.name}
                              </div>
                              <div
                                className="text-muted font-size-12"
                                style={{
                                  fontWeight: "normal",
                                  marginInlineEnd: 20,
                                }}
                              >
                                <i className="mdi mdi-clock-time-eight-outline"></i>
                                {this.props.t("Sent")}
                                {moment(taskDetails.task.createdOn).fromNow()}
                                {this.props.t("Due")}
                                {moment(taskDetails.task.dueDate).fromNow()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardTitle>
                      <div>
                        <div className="day-row mt-2">
                          <FileList
                            path={["tasks", taskDetails.task.title]}
                            payload={{
                              entity: ENTITIES.TASK,
                              idEntity: taskDetails.task.id,
                            }}
                            files={this.props.files}
                            showFilesOnly
                            uploadButtonStyle={{
                              marginInlineStart: 5,
                              marginInlineEnd: 5,
                            }}
                            uploadButtonOnly
                            showUploadType
                          />
                          {updateEntities.includes(ENTITIES.TASK) &&
                            taskDetails.task.canEdit && (
                              <EditIcon
                                onClick={this.toggleEdit}
                                style={{
                                  backgroundColor: Colors.searchBG,
                                  borderRadius: 10,
                                  marginInlineStart: 5,
                                  marginInlineEnd: 5,
                                }}
                              />
                            )}
                          {taskDetails.task.canDelete && (
                            <DeleteIcon
                              onClick={this.toggleDelete}
                              style={{
                                backgroundColor: Colors.searchBG,
                                borderRadius: 10,
                                marginInlineStart: 5,
                                marginInlineEnd: 5,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />

                    <Row style={{ marginTop: 30 }}>
                      <p className="text-muted mb-1">
                        {this.props.t("Description").toUpperCase()}
                      </p>
                      <div className="mb-5">
                        <Markdown>{taskDetails.task.description}</Markdown>
                      </div>

                      <p className="text-muted mb-1">
                        {this.props.t("Attachments").toUpperCase()}
                      </p>
                      <div className="day-row-wrap">
                        {this.props.referencedFiles &&
                        this.props.referencedFiles.length > 0
                          ? this.props.referencedFiles
                              .filter(f => (isInternal ? true : !f.isInternal))
                              .map((f, idx) => <FileItem file={f} key={idx} />)
                          : this.props.t("None")}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card style={{ marginTop: -20 }}>
                  <CardBody>
                    <p className="mainTitle mb-0">{this.props.t("Comments")}</p>
                    <Comments
                      comments={taskDetails.comments.lstComments}
                      className="mt-2"
                    />
                    <CustomTextArea
                      onChange={this.handleChange}
                      value={this.state.comment}
                      isAttachment
                      path={["tasks", taskDetails.task.title]}
                      payload={{
                        entity: ENTITIES.TASK,
                        idEntity: taskDetails.task.id,
                      }}
                      files={this.props.files}
                      setFile={(file, values) => {
                        this.setState({ attachedFile: file, values });
                      }}
                    />

                    <div style={{ marginTop: 20 }} className="day-text-end">
                      <Button
                        className="customTable-button px-4"
                        disabled={this.state.comment.length === 0}
                        onClick={this.handleCommentCreate}
                      >
                        {this.props.t("Add")}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={3}>
                <Card style={{ marginBottom: 4, minHeight: 500 }}>
                  <CardBody>
                    <p className="mainTitle">{this.props.t("Summary")}</p>
                    <Row className="my-2">
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("StatusForm")}</Col>
                          <Col sm={6}>
                            <StatusText text={taskDetails.task.status?.label} />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("PriorityForm")}</Col>
                          <Col sm={6}>
                            <StatusText
                              text={
                                taskDetails.task.priority?.label === "Normal"
                                  ? this.props.t("Medium")
                                  : taskDetails.task.priority?.label
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Task Number")}</Col>
                          <Col sm={6}>{taskDetails.task.taskNumber}</Col>
                        </Row>

                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Due Date Form")}</Col>
                          <Col sm={6} className="text-primary">
                            {moment(taskDetails.task.dueDate).format(
                              "DD/MM/YY"
                            )}
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Assigned To")}</Col>
                          <Col sm={6} className="text-primary">
                            <Link
                              to={`/individual-details?id=${taskDetails.task.assignedTo?.id}`}
                            >
                              {taskDetails.task.assignedTo?.name}
                            </Link>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Assigned By")}</Col>
                          <Col sm={6} className="text-primary">
                            <Link
                              to={`/individual-details?id=${taskDetails.task.assignedBy?.id}`}
                            >
                              {taskDetails.task.assignedBy?.name}
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

TaskDetails.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onGetAllTasks: PropTypes.func,
  taskDetails: PropTypes.any,
  allTasks: PropTypes.array,
  t: PropTypes.any,
  onGetTaskDetails: PropTypes.func,
  AllComments: PropTypes.array,
  onUpdateTask: PropTypes.func,
  loading: PropTypes.bool,
  loadingUpload: PropTypes.bool,
  onAddNewComment: PropTypes.func,
  files: PropTypes.array,
  onGetTaskFiles: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  onGetReferencedFiles: PropTypes.any,
  referencedFiles: PropTypes.any,
  onCreateReferencedFile: PropTypes.func,
  onUploadFiles: PropTypes.func,
  onDeleteTask: PropTypes.func,
  tasksPageNumber: PropTypes.any,
  statusCode: PropTypes.any,
};

const mapStateToProps = ({ tasks, files, Layout }) => ({
  tasks: tasks.tasks,
  allTasks: tasks.allTasks,
  taskDetails: tasks.taskDetails,
  AllComments: tasks.comments,
  loading: tasks.loading,
  loadingUpload: files.loading,
  files: tasks.files,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  referencedFiles: files.referencedFiles,
  tasksPageNumber: tasks.tasksPageNumber,
  statusCode: tasks.statusCode,
});

const mapDispatchToProps = dispatch => ({
  onGetTasks: () => dispatch(getTasks()),
  onGetAllTasks: () => dispatch(getAllTasks()),
  onGetTaskDetails: taskId => dispatch(getTaskDetails(taskId)),
  onUpdateTask: (payload, callback) => dispatch(upsertTask(payload, callback)),
  onAddNewComment: (payload, callback) =>
    dispatch(upsertComments(payload, callback)),
  onGetTaskFiles: (payload, callback = () => {}) =>
    dispatch(getTaskFiles(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetReferencedFiles: (payload, callback) =>
    dispatch(getReferencedFiles(payload, callback)),
  onCreateReferencedFile: (payload, callback) =>
    dispatch(createReferencedFiles(payload, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onDeleteTask: (payload, callback) => dispatch(deleteTask(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(TaskDetails)));
