import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_INTERNAL_DETAILS,
  GET_EXTERNAL_DETAILS,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes";

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const getInternalDetails = callback => {
  return {
    type: GET_INTERNAL_DETAILS,
  };
};
export const getExternalDetails = callback => {
  return {
    type: GET_EXTERNAL_DETAILS,
  };
};

export const register = (payload, callback) => {
  return {
    type: REGISTER,
    payload,
    callback,
  };
};

export const registerSuccess = payload => {
  return {
    type: REGISTER_SUCCESS,
    payload,
  };
};
export const registerFail = err => {
  return {
    type: REGISTER_ERROR,
    payload: err,
  };
};

export const forgotPassword = (payload, callback) => {
  return {
    type: FORGOT_PASSWORD,
    payload,
    callback,
  };
};

export const forgotPasswordSuccess = payload => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload,
  };
};
export const forgotPasswordFail = err => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: err,
  };
};

export const resetPassword = (payload, callback) => {
  return {
    type: RESET_PASSWORD,
    payload,
    callback,
  };
};

export const resetPasswordSuccess = payload => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  };
};
export const resetPasswordFail = err => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: err,
  };
};

export const changePassword = (payload, callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload,
    callback,
  };
};

export const changePasswordSuccess = payload => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};
export const changePasswordFail = err => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: err,
  };
};
