import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  GET_APPOINTMENTS,
  UPSERT_AGENDA,
  UPSERT_APPOINTMENTS,
  GET_APPOINTMENT_DETAILS,
  GET_CHECK,
  GET_CALENDAR_FILES,
  SEND_EMAIL_TO_PARTICIPANTS,
} from "./actionTypes";
import {
  getAppointmentsSuccess,
  getAppointmentsFail,
  upsertAgendaSuccess,
  upsertAgendaFail,
  upsertAppointmentsFail,
  upsertAppointmentsSuccess,
  getAppointmentDetailsSuccess,
  getAppointmentDetailsFail,
  getCheckFail,
  getCheckSuccess,
  getCalendarFilesFail,
  getCalendarFilesSuccess,
  sendEmailToParticipantsSuccess,
  sendEmailToParticipantsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getAppointments,
  upsertAgenda,
  upsertAppointments,
  getAppointmentDetails,
  getCheck,
  getRelatedFiles,
  sendEmailToParticipants,
} from "../../helpers/backend_helper";

import { getGenericError } from "common";
// ToastContainer,
import "react-toastify/dist/ReactToastify.css";

function* fetchAppointments({ payload }) {
  try {
    let body = payload;
    const response = yield call(getAppointments, body);
    yield put(getAppointmentsSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getAppointmentsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAppointmentsFail(error.message));
    }
  }
}

function* upsertAgendaSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertAgenda, body);
    yield put(upsertAgendaSuccess(response));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        upsertAgendaFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertAgendaFail(error.message));
    }
  }
}

function* upsertAppointmentSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertAppointments, body);

    yield put(upsertAppointmentsSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertAppointmentsFail(error.response.data.responseStatus.description)
      );
    } else {
      yield put(upsertAppointmentsFail(error.message));
    }
  }
}

function* fetchAppointmentDetails({ payload, callback }) {
  try {
    let body = {
      idAppointment: payload,
      startAt: "",
      endAt: "",
    };

    const response = yield call(getAppointmentDetails, body);
    yield put(getAppointmentDetailsSuccess(response.responseObject));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getAppointmentDetailsFail(
          error.response.data.responseStatus.description
        )
      );
    } else {
      yield put(getAppointmentDetailsFail(error.message));
    }
  }
}

function* fetchCheck({ payload }) {
  try {
    let body = payload;
    const response = yield call(getCheck, body);
    yield put(
      getCheckSuccess({
        lstAppointment: response.responseObject.lstAppointment,
        body,
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(getCheckFail(error.response.responseObject.lstAppointment));
    } else {
      yield put(getCheckFail(error.message));
    }
  }
}

function* fetchCalendarFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(getCalendarFilesSuccess(response.responseObject.lstDocuments));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getCalendarFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getCalendarFilesFail(error.message));
    }
  }
}

function* sendEmailToParticipantsSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(sendEmailToParticipants, body);
    yield put(
      sendEmailToParticipantsSuccess(response.responseObject.lstDocuments)
    );

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        sendEmailToParticipantsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(sendEmailToParticipantsFail(error.message));
    }
  }
}

function* calendarSaga() {
  yield takeEvery(GET_APPOINTMENTS, fetchAppointments);
  yield takeEvery(UPSERT_AGENDA, upsertAgendaSaga);
  yield takeEvery(UPSERT_APPOINTMENTS, upsertAppointmentSaga);
  yield takeEvery(GET_APPOINTMENT_DETAILS, fetchAppointmentDetails);
  yield takeEvery(GET_CHECK, fetchCheck);
  yield takeEvery(GET_CALENDAR_FILES, fetchCalendarFiles);
  yield takeEvery(SEND_EMAIL_TO_PARTICIPANTS, sendEmailToParticipantsSaga);
}

export default calendarSaga;
