/* REQUESTS */
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_REQUESTS_FAIL = "GET_REQUESTS_FAIL";
export const GET_REQUESTS_DASHBOARD="GET_REQUESTS_DASHBOARD"
export const GET_REQUESTS_DASHBOARD_SUCCESS = "GET_REQUESTS_DASHBOARD_SUCCESS";
export const GET_REQUESTS_DASHBOARD_FAIL = "GET_REQUESTS_DASHBOARD_FAIL";

export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS";
export const GET_REQUEST_FAIL = "GET_REQUEST_FAIL";

export const GET_COMMENTS_IN_REQUEST = "GET_COMMENTS_IN_REQUEST";
export const GET_COMMENTS_IN_REQUEST_SUCCESS =
  "GET_COMMENTS_IN_REQUEST_SUCCESS";
export const GET_COMMENTS_IN_REQUEST_FAIL = "GET_COMMENTS_IN_REQUEST_FAIL";

export const UPSERT_REQUEST = "UPSERT_REQUEST";
export const UPSERT_REQUEST_SUCCESS = "UPSERT_REQUEST_SUCCESS";
export const UPSERT_REQUEST_FAIL = "UPSERT_REQUEST_FAIL";

export const GET_REQUEST_FILES = "GET_REQUEST_FILES";
export const GET_REQUEST_FILES_SUCCESS = "GET_REQUEST_FILES_SUCCESS";
export const GET_REQUEST_FILES_FAIL = "GET_REQUEST_FILES_FAIL";

export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAIL = "DELETE_REQUEST_FAIL";

export const UPDATE_REQUEST_STATUS_CODE = "UPDATE_REQUEST_STATUS_CODE"; 
