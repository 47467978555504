import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import ViewTeamModal from "./ViewTeamModal";
import {
  getAllDetails,
  getOrganizationsFiles,
  upsertOrganization,
} from "../../../store/contacts/actions";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Nav,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import { withTranslation } from "react-i18next";
import {
  filterPermittedEntities,
  getBase64,
  getQueryVariable,
  handleCopyToClipboard,
  sortCaretIcons,
} from "common/utils";
import Loading from "pages/Loading";
import CustomTable from "components/Common/CustomTable";
import {
  Colors,
  ENTITIES,
  getGlobalHeaderStyle,
  TOAST_OPTIONS,
  CURRENT_LANG,
} from "common";
import OrganizationModal from "./OrganizationModal";
import FileManager from "pages/FileManager";
import { getEntityLookups, getUsers, addNewUser } from "store/actions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { toast } from "react-toastify";
import IndividualModal from "./IndividualModal";
import moment from "moment";
import EditIcon from "components/Common/EditIcon";
import Tippy from "@tippy.js/react";

const colors = [Colors.yellow, Colors.green, Colors.red, Colors.darkGreen];
let colorsIndex = -1;
class OrganizationDetails extends Component {
  constructor(props) {
    super(props);
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    let readEntities = filterPermittedEntities(props.permissions, "read");
    this.state = {
      customActiveTab: "1",
      organizationDetail: {},
      file: null,
      activeTabId: "1",
      tasksColumns: [
        {
          text: this.props.t("ID"),
          dataField: "idTask",
          hidden: true,
        },
        {
          dataField: "taskNumber",
          text: this.props.t("Task ID"),
          formatter: (cellContent, task) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  to={"/task-details?id=" + task.idTask}
                  query={task}
                  className="text-primary text-decoration-underline"
                >
                  {`#${task.taskNumber}`}
                </Link>
              </h5>
            </>
          ),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
        },
        {
          text: this.props.t("Task Name"),
          dataField: "title",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("30%"),
          align: "start",
        },
        {
          dataField: "projectName",
          text: this.props.t("ProjectForm"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("30%"),
          align: "start",
        },
        {
          dataField: "assignedBy.name",
          text: this.props.t("Assigned By"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          formatter: (cell, task) => (
            <Tippy
              content={this.props.t("View More")}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/individual-details?id=" + task.assignedBy?.id}
                  className="text-dark"
                >
                  {task.assignedBy?.name}
                </Link>
              </span>
            </Tippy>
          ),
        },
      ],
      isUpdateOrg: updateEntities.includes(ENTITIES.ORGANIZATION),
      isDocumentOrg: readEntities.includes(ENTITIES.DOCUMENT),
      modal: false,
      editModal: false,
      addMemberModal: false,
    };

    this.toggleCustom = this.toggleCustom.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleOrgUpdate = this.handleOrgUpdate.bind(this);
    this.toggleAddMember = this.toggleAddMember.bind(this);
    // this.handleMemberAdd = this.handleMemberAdd.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleUserCreate = this.handleUserCreate.bind(this);
  }

  componentDidMount() {
    const {
      onGetOrganizationDetails,
      onGetOrganizationFiles,
      onGetLookups,
      onGetUsers,
    } = this.props;
    const orgId = getQueryVariable("id");

    const payload = {
      entity: ENTITIES.ORGANIZATION,
      idEntity: orgId,
    };
    onGetLookups(ENTITIES.INDIVIDUALS, () => {
      onGetUsers();
    });

    if (this.state.isDocumentOrg) {
      onGetLookups(ENTITIES.ORGANIZATION, () => {
        onGetOrganizationFiles(payload, () => {
          onGetOrganizationDetails(orgId);
        });
      });
    } else {
      onGetLookups(ENTITIES.ORGANIZATION, () => {
        onGetOrganizationDetails(orgId);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        !this.props.loadingUpload &&
        prevProps.loadingUpload !== this.props.loadingUpload
      ) {
        const payload = {
          entity: ENTITIES.ORGANIZATION,
          idEntity: this.props.organizationDetail.idOrganization,
        };
        this.props.onGetOrganizationFiles(payload);
      }
    }
  }

  handleTeamClicks = () => {
    this.setState({ task: "", isEdit: false });
    this.toggle();
  };

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  toggleAddMember() {
    this.setState(prevState => ({
      addMemberModal: !prevState.addMemberModal,
      modal: false,
    }));
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  toggleEdit() {
    this.setState(prevState => ({
      editModal: !prevState.editModal,
    }));
  }

  toggleTab(tab) {
    this.setState({ activeTabId: tab });
  }
  async handleOrgUpdate(values) {
    const {
      organizationDetail,
      onGetOrganizationDetails,
      onUpdateOrganization,
    } = this.props;

    try {
      let image = "";
      if (this.state.file) {
        image = await getBase64(this.state.file);
      }

      const orgId = getQueryVariable("id");
      const org = {
        idOrganization: organizationDetail.idOrganization,
        primaryContactId: values.primaryContact?.value,
        taxNumber: values.taxNumber,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        address: values.address,
        email: values.email,
        telephone: values.phoneNumber,
        profileImage: image,
        idSector: values.sector?.value,
        organizationType: +values.organizationType?.value,
      };

      onUpdateOrganization(org, () => {
        onGetOrganizationDetails(orgId);
        toast.success(
          this.props
            .t("SuccessUpdate")
            .replace("*", this.props.t("Organization")),
          TOAST_OPTIONS
        );
      });
      this.toggleEdit();
    } catch (err) {
      console.error(err);
    }
  }
  async handleUserCreate(values) {
    const { onGetUsers, onAddNewUser } = this.props;

    try {
      let image = "";
      if (this.state.file) {
        image = await getBase64(this.state.file);
      }

      const user = {
        idObject: "00000000-0000-0000-0000-000000000000",
        firstname: values?.name.split(" ")[0]?.trim(),
        lastname: values?.name.split(" ")[1]
          ? values?.name.split(" ")[1]?.trim()
          : "",
        email: values.email,
        idRole: values.role.value,
        jobTitle: values.title,
        idOrganization: values.organization.value,
        phoneNumber: values.phoneNumber,
        department: values.department,
        employeeId: values.employee,
        profileImage: image,
        notification: {
          task: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          approval: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          project: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          appointment: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          correspondence: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          request: {
            email: true,
            whatsapp: true,
            portal: true,
          },
        },
      };
      onAddNewUser(user, () => {
        const { onGetOrganizationDetails } = this.props;
        const orgId = getQueryVariable("id");
        onGetOrganizationDetails(orgId);
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Member")),
          TOAST_OPTIONS
        );
      });
      this.toggleAddMember();
    } catch (err) {
      console.error(err);
    }
  }
  // handleMemberAdd(values) {
  //   const {
  //     organizationDetail,
  //     onGetOrganizationDetails,
  //     onUpdateOrganization,
  //   } = this.props;
  //   const orgId = getQueryVariable("id");
  //   const member = values.member;
  //   const org = {
  //     idOrganization: orgId,
  //     primaryContact: organizationDetail.primaryContact?.value,
  //     taxNumber: organizationDetail.taxNumber,
  //     nameEn: organizationDetail.nameEn,
  //     nameAr: organizationDetail.nameAr,
  //     address: organizationDetail.address,
  //     email: organizationDetail.email,
  //     telephone: organizationDetail.telephone,
  //     profileImage: organizationDetail.image,
  //     idSector: organizationDetail.sector?.value,
  //     organizationType: organizationDetail.organizationType?.value,
  //   };

  //   onUpdateOrganization(org, () => {
  //     onGetOrganizationDetails(orgId);
  //     toast.success(
  //       this.props.t("SuccessAdd").replace("*", this.props.t("Member")),
  //       TOAST_OPTIONS
  //     );
  //   });
  //   this.toggleAddMember();
  // }

  render() {
    const {
      organizationDetail,
      loading,
      error,
      loadingUpload,
      loadingLookups,
    } = this.props;

    if (loading || !organizationDetail || loadingUpload || loadingLookups) {
      return <Loading />;
    }
    const { isUpdateOrg } = this.state;
    const upcomingTasks = organizationDetail.lstTasks.filter(
      task =>
        task?.status?.label?.toLowerCase() !== "canceled" &&
        task?.status?.label?.toLowerCase() !== "completed" &&
        moment(task.dueDate).isAfter(moment(), "day")
    );

    const overdueTasks = organizationDetail.lstTasks.filter(
      _task =>
        _task?.status?.label?.toLowerCase() !== "canceled" &&
        _task?.status?.label?.toLowerCase() !== "completed" &&
        moment(_task.dueDate).isSameOrBefore(moment(), "day")
    );
    const MAX_DISPLAYED_ICONS = 14;
    return (
      <React.Fragment>
        {/* <AddMemberModal
          modal={this.state.addMemberModal}
          org={organizationDetail}
          toggle={this.toggleAddMember}
          entityLookups={this.props.entityLookups?.[ENTITIES.ORGANIZATION]}
          handleSubmit={this.handleMemberAdd}
        /> */}
        <IndividualModal
          modal={this.state.addMemberModal}
          org={organizationDetail}
          toggle={this.toggleAddMember}
          entityLookups={this.props.entityLookups?.[ENTITIES.INDIVIDUALS]}
          handleSubmit={this.handleUserCreate}
        />
        <ViewTeamModal
          modal={this.state.modal}
          team={organizationDetail.listTeams}
          toggle={this.toggle}
          entityLookups={this.props.entityLookups?.[ENTITIES.ORGANIZATION]}
          handleAddClick={this.toggleAddMember}
        />
        <OrganizationModal
          modal={this.state.editModal}
          isEdit={true}
          org={organizationDetail}
          toggle={this.toggleEdit}
          handleSubmit={this.handleOrgUpdate}
          file={this.state.file}
          onFilesAccepted={file => {
            this.setState({ file: file[0] });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.ORGANIZATION]}
        />
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Tasks Title")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs mb={1}>
              <Link
                underline="hover"
                color="inherit"
                to="/people?tab=individuals"
              >
                <span
                  style={{ fontFamily: "DM Sans" }}
                >
                  {this.props.t("People")}
                </span>
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/people?tab=organizations&page="+this.props.orgPageNumber}
              >
                <span
                  style={{ fontFamily: "DM Sans" }}
                >
                  {this.props.t("Organizations")}
                </span>{" "}
              </Link>
              <span style={{ fontFamily: "DM Sans" }}>
                {this.props.t("Organization Details")}
              </span>
            </Breadcrumbs>

            <Row className="g-2">
              <Col sm="4">
                <Card style={{ marginBottom: 5, maxHeight: "100vh" }}>
                  <CardBody>
                    {isUpdateOrg && organizationDetail.canEdit && (
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <EditIcon
                          onClick={this.toggleEdit}
                          style={{
                            backgroundColor: Colors.searchBG,
                            borderRadius: 10,
                            margin: 5,
                          }}
                        />
                      </div>
                    )}
                    <div className="day-column-nowrap day-align-items-center day-w-100">
                      <div
                        className="me-3"
                        style={{ marginTop: "29px", marginBottom: "21px" }}
                      >
                        {!organizationDetail.image ? (
                          <div className="avatar-xs">
                            <span
                              className="avatar-md img-thumbnail. p-2 bd-highlight col-example "
                              style={{ height: "69px", width: "207px" }}
                            ></span>
                          </div>
                        ) : (
                          <img
                            // className="avatar-md img-thumbnail. p-2 bd-highlight col-example "
                            src={`data:image/png;base64,${organizationDetail.image}`}
                            alt=""
                            style={{
                              height: "130px",
                              width: "130px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                      </div>

                      <h5 className="day-dark-text-bold font-size-20">
                        {organizationDetail?.nameEn}
                      </h5>
                      <p className="day-dark-text-bold font-size-14">
                        {organizationDetail?.nameAr}{" "}
                      </p>

                      <div className="day-column-nowrap day-w-100 mt-4 px-2">
                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("Organization ID")}
                          </h6>
                          <div className="day-row-nowrap day-w-40 day-item-flex-end">
                            <h6 className="day-dark-text day-text-nowrap-ellipsis day-w-60 mb-0 mt-0 mx-1">
                              {organizationDetail.organizationNumber}
                            </h6>
                            {!!organizationDetail.organizationNumber && (
                              <button
                                type="button"
                                className="btn btn-primary mb-0 mt-0"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0px",
                                  padding: 0,
                                }}
                                onClick={() => {
                                  handleCopyToClipboard(
                                    organizationDetail.organizationNumber
                                  );
                                  toastr.info(this.props.t("ID Copied"));
                                }}
                              >
                                <i
                                  className="mdi mdi-content-copy d-block font-size-18"
                                  style={{ color: Colors.grey }}
                                ></i>
                              </button>
                            )}
                          </div>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("Sector")}
                          </h6>
                          <h6 className="day-dark-text text-nowrap mb-0 mt-0">
                            {organizationDetail.sector?.label}
                          </h6>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("Type")}
                          </h6>
                          <h6 className="day-dark-text text-nowrap mb-0 mt-0">
                            {organizationDetail.organizationType?.label}
                          </h6>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {!!organizationDetail.sector &&
                            organizationDetail.sector?.label
                              ?.toLowerCase()
                              ?.includes("public")
                              ? this.props.t("Royal Decree")
                              : this.props.t("Tax Reference Number")}
                          </h6>
                          <h6 className="day-dark-text text-nowrap mb-0 mt-0">
                            {organizationDetail.taxNumber}
                          </h6>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("EmailForm")}
                          </h6>
                          <a
                            href={`mailto:${organizationDetail.email}`}
                            className="text-decoration-underline mb-0 mt-0"
                          >
                            {organizationDetail.email}
                          </a>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("Phone Number Form")}
                          </h6>
                          <a
                            href={`tel:${organizationDetail.telephone}`}
                            className="text-decoration-underline mb-0 mt-0"
                          >
                            {organizationDetail.telephone}
                          </a>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("Primary Address").toUpperCase()}
                          </h6>
                          <h6 className="day-dark-text text-nowrap mb-0 mt-0">
                            {organizationDetail.address}
                          </h6>
                        </div>

                        <hr className="mt-3 mb-3 day-w-100" />

                        <div className="day-row-nowrap day-justify-between day-align-items-center day-w-100">
                          <h6 className="text-muted text-nowrap mb-0 mt-0">
                            {this.props.t("Primary Contact")}
                          </h6>
                          <h6 className="day-dark-text text-nowrap mb-0 mt-0">
                            {organizationDetail.primaryContact?.label}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="8">
                <Row className="g-1">
                  <Col lg="12">
                    <Card
                      style={{
                        height: "148px",
                        marginBottom: 1,
                      }}
                    >
                      <CardBody>
                        <CardTitle className="day-row day-justify-between">
                          <h5 className="mb-0 text-nowrap day-dark-text-bold">
                            {this.props.t("All Members")}
                          </h5>
                          {isUpdateOrg && (
                            <Button
                              outline
                              color="primary"
                              className="h-100"
                              onClick={this.toggleAddMember}
                            >
                              {this.props.t("Add Member")}
                            </Button>
                          )}
                        </CardTitle>
                        <div
                          className="scrollbar"
                          style={{
                            height: "40%",
                            overflowY: "hidden",
                            overflowX: "hidden",
                            width: "100%",
                          }}
                        >
                          <div className="day-row-wrap">
                            {organizationDetail.listTeams.map(
                              (member, index) => (
                                <Tippy
                                  key={index}
                                  content={member?.name || ""}
                                  placement="top"
                                  animation="scale-subtle"
                                  theme="light"
                                  arrow={false}
                                  duration={200}
                                  delay={[75, 0]}
                                  distance={5}
                                >
                                  <Link
                                    to={`/individual-details?id=${member.id}`}
                                  >
                                    {index < MAX_DISPLAYED_ICONS - 1 &&
                                      (!!member?.image ? (
                                        <div className="rounded-circle avatar-sm m-1">
                                          <img
                                            src={`data:image/png;base64,${member.image}`}
                                            className="rounded-circle avatar-sm"
                                            alt=""
                                            style={{ height: 39, width: 39 }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="rounded-circle avatar-sm m-1">
                                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-sm h3 p-2">
                                            {member?.name?.charAt(0)}
                                          </span>
                                        </div>
                                      ))}
                                  </Link>
                                </Tippy>
                              )
                            )}
                            <Button
                              style={{
                                outline: "none",
                                padding: "0px",
                                border: "0px",
                                borderRadius: "50px",
                                boxSizing: "none",
                                backgroundColor: "transparent",
                              }}
                              onClick={this.handleTeamClicks}
                            >
                              {organizationDetail.listTeams.length >
                                MAX_DISPLAYED_ICONS && (
                                <div className="avatar-sm">
                                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-sm h5 p-3">
                                    {`+${
                                      organizationDetail.listTeams.length -
                                      MAX_DISPLAYED_ICONS
                                    }`}
                                  </span>
                                </div>
                              )}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="12">
                    <Card
                      style={{
                        height: "220px",
                        marginBottom: 1,
                      }}
                    >
                      <CardBody style={{ height: "100%" }}>
                        <CardTitle>
                          <div className="h5 mb-3 text-nowrap day-dark-text-bold">
                            {this.props.t("Projects")}
                          </div>
                        </CardTitle>

                        <div
                          className="scrollbar"
                          style={{
                            height: "90%",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: "100%",
                          }}
                        >
                          <div className="day-row-wrap day-w-100 day-justify-between">
                            {organizationDetail.listProjects.map(
                              (item, index) => {
                                if (colorsIndex === colors.length - 1) {
                                  colorsIndex = 0;
                                } else {
                                  colorsIndex++;
                                }
                                return (
                                  <Link
                                    to={`/project-details?id=${item.idProject}`}
                                    key={index}
                                    className="day-w-50 mt-3"
                                    style={{
                                      borderBottom: "1px solid rgba(0,0,0,0.2)",
                                    }}
                                  >
                                    <div className="day-row day-justify-between day-align-items-center day-align-center p-1">
                                      <div className="day-row day-align-items-center">
                                        <div
                                          className="mx-2 day-row day-justify-center"
                                          style={{
                                            backgroundColor:
                                              colors[colorsIndex] + "1A",
                                            borderRadius: 5,
                                            height: 42,
                                            width: 42,
                                          }}
                                        >
                                          <p
                                            className="mb-0 h3"
                                            style={{
                                              color: colors[colorsIndex],
                                            }}
                                          >
                                            {CURRENT_LANG === "en"
                                              ? item.titleEn.charAt(0)
                                              : item.titleAr.charAt(0)}
                                          </p>
                                        </div>
                                        <h6 className="text-dark mt-2 day-align-left font-size-14">
                                          {CURRENT_LANG === "en"
                                            ? item.titleEn
                                            : item.titleAr}
                                        </h6>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="12">
                    <Nav
                      tabs
                      className="nav-tabs-custom"
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        height: 50,
                        backgroundColor: Colors.white,
                      }}
                    >
                      <NavItem
                        style={{
                          display: "flex",
                          height: "100%",
                          flexWrap: "nowrap",
                        }}
                      >
                        <NavLink
                          style={{
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            alignItems: "flex-end",
                            display: "flex",
                          }}
                          className={classnames({
                            active: this.state.activeTabId === "1",
                            "text-muted": this.state.activeTabId !== "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                        >
                          <span>{this.props.t("upcoming tasks")}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem
                        style={{
                          display: "flex",
                          height: "100%",
                          flexWrap: "nowrap",
                        }}
                      >
                        <NavLink
                          style={{
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            alignItems: "flex-end",
                            display: "flex",
                          }}
                          className={classnames({
                            active: this.state.activeTabId === "2",
                            "text-muted": this.state.activeTabId !== "2",
                          })}
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                        >
                          <span>{this.props.t("Overdue Tasks")}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTabId}>
                      <TabPane tabId="1">
                        <Card
                          style={{
                            height: "300px",
                            marginBottom: 5,
                          }}
                        >
                          <CustomTable
                            columns={this.state.tasksColumns}
                            data={upcomingTasks}
                            keyField={"idTask"}
                            sizePerPage={3}
                            defaultSortedField="title"
                          />
                        </Card>
                      </TabPane>
                      <TabPane tabId="2">
                        <Card
                          style={{
                            height: "300px",
                            marginBottom: 5,
                          }}
                        >
                          <CustomTable
                            columns={this.state.tasksColumns}
                            data={overdueTasks}
                            keyField={"idTask"}
                            sizePerPage={3}
                            defaultSortedField="title"
                          />
                        </Card>
                      </TabPane>
                    </TabContent>
                  </Col>
                  {this.state.isDocumentOrg && (
                    <Col lg="12">
                      <Card>
                        <CardBody className="p-0">
                          <FileManager
                            showFilesOnly
                            entityFiles={this.props.files}
                            entityPayload={{
                              entity: ENTITIES.PROJECT,
                              idEntity: organizationDetail.idOrganization,
                            }}
                            entityPath={[
                              "organizations",
                              organizationDetail?.nameEn,
                            ]}
                            height={"70vh"}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

OrganizationDetails.propTypes = {
  t: PropTypes.any,
  onGetOrganizationDetails: PropTypes.func,
  organizationDetail: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onUpdateOrganization: PropTypes.func,
  onGetOrganizationFiles: PropTypes.func,
  loadingUpload: PropTypes.bool,
  files: PropTypes.array,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  onGetUsers: PropTypes.any,
  onAddNewUser: PropTypes.any,
  orgPageNumber: PropTypes.any,
};

const mapStateToProps = ({ contacts, files, Layout }) => ({
  organizationDetail: contacts.organizationDetail,
  loading: contacts.loading,
  error: contacts.error,
  loadingUpload: files.loading,
  files: contacts.files,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  orgPageNumber: contacts.orgPageNumber,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: (user, callback) => dispatch(addNewUser(user, callback)),
  onGetOrganizationDetails: orgId => dispatch(getAllDetails(orgId)),
  onUpdateOrganization: (payload, callback) =>
    dispatch(upsertOrganization(payload, callback)),
  onGetOrganizationFiles: (payload, callback = () => {}) =>
    dispatch(getOrganizationsFiles(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OrganizationDetails)));
