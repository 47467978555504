import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";
// import AnimateWhenVisible from "components/AnimateWhenVisible/AnimateWhenVisible";
import CountUp from "react-countup";
class DashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentageColor: props.percentage.toString().indexOf("-"),
    };
  }
  render() {
    return (
      <Card className="card-hover card-height" onClick={this.props.onClick}>
        <CardBody className="mt-3 mx-2 h-158">
          <div className="d-flex justify-content-between">
            <p className="day-card-title text-muted mb-0">{this.props.title}</p>
            <img src={this.props.icon} className="mt-1" />
          </div>
          <div className="d-flex flex-row mb-0">
            <CountUp delay={0} start={0} end={this.props.value} duration={1.5}>
              {({ countUpRef }) => (
                <div>
                  <span className="day-card-number mb-0" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <div className="day-padding-inline-start">
              <CountUp
                delay={0}
                start={0}
                end={this.props.percentage}
                duration={1.5}
              >
                {({ countUpRef }) => (
                  <p
                    className={
                      this.state.percentageColor === 0
                        ? "day-percentage-number-red"
                        : "day-percentage-number-green"
                    }
                  >
                    <span ref={countUpRef} />%
                  </p>
                )}
              </CountUp>
              <p className="day-prev-month-title">
                {this.props.previousMonthTitle}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  percentage: PropTypes.number,
  previousMonthTitle: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.any,
};

export default withTranslation()(DashboardCard);
