import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import contactsSaga from "./contacts/saga";
import approvalsSaga from "./approvals/saga";
import requestsSaga from "./requests/saga";
import corresSaga from "./correspondence/saga";
import filesSaga from "./files/saga";
import dashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(approvalsSaga),
    fork(requestsSaga),
    fork(corresSaga),
    fork(filesSaga),
    fork(dashboardSaga),
  ]);
}
