import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_REQUEST,
  GET_REQUESTS,
  GET_REQUESTS_DASHBOARD,
  UPSERT_REQUEST,
  GET_REQUEST_FILES,
  DELETE_REQUEST,
} from "./actionTypes";
import {
  getRequestsSuccess,
  getRequestsFail,
  getRequestsDashboardSuccess,
  getRequestsDashboardFail,
  getRequestSuccess,
  getRequestFail,
  upsertRequestFail,
  upsertRequestSuccess,
  getRequestFilesSuccess,
  getRequestFilesFail,
  deleteRequestSuccess,
  deleteRequestFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getRequestDetails,
  getRequests,
  getRequestsDashboard,
  upsertRequest,
  getRelatedFiles,
  deleteRecord,
} from "helpers/backend_helper";
import {
  EMPTY_GUID,
  ENTITIES,
  getGenericError,
  idContact,
  idRole,
  isInternal,
} from "common";
function* fetchRequests({payload}) {
  try {
    const body = payload ? {  
      statusCode:payload.statusCode || "100000002",
      pageCount:payload.pageCount,
      pageNumber:payload.pageNumber,
      searchString:payload.searchString.trim(),
    } : {};
    const response = yield call(getRequests, body);
    yield put(
      getRequestsSuccess({
        requests: response.responseObject.listRequests,
        searchString:body.searchString,
        totalRecordCount:response.responseObject.totalRecordCount,
        pageNumber:body.pageNumber,
        statusCode:body.statusCode,
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(
        getRequestsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getRequestsFail(error.message));
    }
  }
}
function* fetchRequestsDashboard() {
  try {
    const body =  {};
    const response = yield call(getRequestsDashboard, body);
    yield put(
      getRequestsDashboardSuccess({
        dashboardData: response.responseObject, 
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(
        getRequestsDashboardFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getRequestsDashboardFail(error.message));
    }
  }
}

function* fetchRequestDetails({ payload }) {
  try {
    let body = {
      id: payload,
      entity: ENTITIES.REQUEST,
    };
    const response = yield call(getRequestDetails, body);
    yield put(getRequestSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getRequestFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getRequestFail(error.message));
    }
  }
}

function* upsertRequestSaga({ payload, callback }) {
  try {
    let body = payload;

    const response = yield call(upsertRequest, body);
    yield put(upsertRequestSuccess(response));
    callback && callback(response.responseObject.idRequest);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertRequestFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertRequestFail(error));
    }
  }
}

function* fetchRequestFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(getRequestFilesSuccess(response.responseObject.lstDocuments));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getRequestFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getRequestFilesFail(error.message));
    }
  }
}

function* deleteRequestSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternalUser: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request.idRequest,
        idOrganizationCallingUser: EMPTY_GUID,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteRequestSuccess(response));
    callback && callback(response.responseObject.idRequest);
  } catch (error) {
    if (error.response) {
      yield put(
        deleteRequestFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertRequestFail(error));
    }
  }
}

function* requestsSaga() {
  yield takeEvery(GET_REQUESTS, fetchRequests);
  yield takeEvery(GET_REQUESTS_DASHBOARD, fetchRequestsDashboard);
  yield takeEvery(GET_REQUEST, fetchRequestDetails);
  yield takeEvery(UPSERT_REQUEST, upsertRequestSaga);
  yield takeEvery(GET_REQUEST_FILES, fetchRequestFiles);
  yield takeEvery(DELETE_REQUEST, deleteRequestSaga);
}

export default requestsSaga;
