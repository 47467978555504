import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Card, CardBody, Collapse, Container } from "reactstrap";
import FileList from "./FileList";
import FileLeftBar from "./FileLeftBar";
import {
  getFileCategories,
  getRelatedFiles,
  getRelatedFilesSuccess,
} from "store/actions";
import { connect } from "react-redux";
import Loading from "pages/Loading";

class FileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: props.entityPath ? props.entityPath : [],
      entityName: "",
      payload: props.entityPayload ? props.entityPayload : null,
      mobileStyle: false,
      fileLeftBarCol: true,
    };
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this);
    this.handleClearList = this.handleClearList.bind(this);
    this.handleGetRelatedFiles = this.handleGetRelatedFiles.bind(this);
    this.FileManagerComponent = this.FileManagerComponent.bind(this);
    this.resize = this.resize.bind(this);
    this.toggleFileLeftBar = this.toggleFileLeftBar.bind(this);
  }

  handleTabClick(path, payload) {
    const _paths = path.split("/").filter(item => !!item);
    this.setState({
      path: _paths,
      entityName: payload ? payload.entityName : "",
    });
    if (_paths.length >= 2) {
      this.toggleFileLeftBar();
    }
    if (payload) {
      this.handleGetRelatedFiles(payload);
    }
  }

  handleGetRelatedFiles(payload) {
    this.setState({ payload }, () => {
      this.props.onGetRelatedFiles(payload);
    });
  }

  handleBreadcrumbClick(index) {
    if (index < 2) {
      let _path = this.state.path.slice(0, 2);
      this.setState({ path: _path });
      return;
    }
    const _paths = this.state.path.slice(0, index + 1);
    this.setState({ path: _paths });
  }

  componentDidMount() {
    const { onGetCategories, showFilesOnly } = this.props;
    if (!showFilesOnly) {
    //  onGetCategories();
    }

    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize() {
    if (window.innerWidth <= 750 && !this.state.mobileStyle) {
      this.setState({ mobileStyle: true });
    } else if (this.state.mobileStyle) {
      this.setState({ mobileStyle: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  handleClearList() {
    this.props.onClearList();
  }

  toggleFileLeftBar() {
    this.setState({ fileLeftBarCol: !this.state.fileLeftBarCol });
  }

  FileManagerComponent = loadingHeight => (
    <div className="d-xl-flex">
      <div className="w-100">
        <div className="d-md-flex">
          {!this.state.mobileStyle ? (
            <FileLeftBar
              onClick={this.handleTabClick}
              entities={this.props.entities}
              clearList={this.handleClearList}
              path={this.state.path}
            />
          ) : (
            <div
              className="accordion accordion-flush mb-2"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header day-bg-white"
                  id="headingFlushThree"
                >
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    onClick={this.toggleFileLeftBar}
                    style={{ cursor: "pointer" }}
                  >
                    {this.state.path.length >= 2 ? (
                      <div className="day-column">
                        <p className="day-dark-text-bold">
                          {this.state.path[0]}
                        </p>
                        <p className="text-primary mb-0">
                          {this.state.path[1]}
                        </p>
                      </div>
                    ) : (
                      this.props.t("Select a category")
                    )}
                  </button>
                </h2>
                <Collapse
                  isOpen={this.state.fileLeftBarCol}
                  className="accordion-collapse"
                >
                  <FileLeftBar
                    onClick={this.handleTabClick}
                    entities={this.props.entities}
                    clearList={this.handleClearList}
                    mobileStyle={this.state.mobileStyle}
                    path={this.state.path}
                  />
                </Collapse>
              </div>
            </div>
          )}
          {(!this.state.mobileStyle ||
            (this.state.mobileStyle && this.state.path.length >= 2)) && (
            <div className="w-100">
              <Card>
                <CardBody style={{ padding: 0 }}>
                  {this.props.relatedLoading ? (
                    <Loading height={loadingHeight ? loadingHeight : ""} />
                  ) : !!this.props.relatedFiles ? (
                    <FileList
                      title={"test"}
                      path={this.state.path}
                      onBreadcrumbClick={this.handleBreadcrumbClick}
                      files={this.props.relatedFiles}
                      payload={this.state.payload}
                      handleGetRelatedFiles={this.handleGetRelatedFiles}
                      handleTabClick={this.handleTabClick}
                      showUploadType={this.props.showUploadType}
                      onRowClick={this.props.onRowClick}
                    />
                  ) : (
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        height: "70vh",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 20,
                      }}
                    >
                      <h1 className="mb-0">
                        {this.props.t("Select Category")}
                      </h1>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        (!prevProps.entities || Object.keys(prevProps.entities).length === 0) &&
        !!this.props.entities &&
        Object.keys(this.props.entities).length > 0
      ) {
        for (let key in this.props.entities) {
          let entity = this.props.entities[key];
          if (entity.data.length > 0) {
            let idEntity = entity.data[0].id;
            let path = [entity.displayName?.toLowerCase(), idEntity];
            let payload = {
              entity: entity.entityName,
              idEntity,
            };

            this.setState({
              path,
              payload,
            });
            this.toggleFileLeftBar();
            break;
          }
        }
      }
    }
  }
  render() {
    const {
      loading,
      categoriesLoading,
      entities,
      showFilesOnly,
      entityFiles,
      height,
      showUploadType,
    } = this.props;

    if (categoriesLoading || loading ) { // || (!showFilesOnly && !entities)
      return <Loading height={showUploadType ? "200px" : ""} />;
    }

    return showFilesOnly ? (
      <FileList
        path={this.state.path}
        onBreadcrumbClick={this.handleBreadcrumbClick}
        files={entityFiles ? entityFiles : []}
        payload={this.state.payload}
        handleGetRelatedFiles={this.handleGetRelatedFiles}
        handleTabClick={this.handleTabClick}
        height={height}
        showFilesOnly={showFilesOnly}
      />
    ) : showUploadType ? (
      this.FileManagerComponent("200px")
    ) : (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              {this.props
                .t("Title")
                .replace("*", this.props.t("Document Management"))}
            </title>
          </MetaTags>
          <Container fluid>
            <h4 className="mb-3">{this.props.t("Document Management")}</h4>

            {this.FileManagerComponent()}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

FileManager.propTypes = {
  t: PropTypes.any,
  onGetFiles: PropTypes.func,
  relatedFiles: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onGetRelatedFiles: PropTypes.func,
  onGetCategories: PropTypes.func,
  entities: PropTypes.any,
  relatedLoading: PropTypes.bool,
  onClearList: PropTypes.func,
  showFilesOnly: PropTypes.bool,
  entityPayload: PropTypes.any,
  entityFiles: PropTypes.any,
  height: PropTypes.string,
  entityPath: PropTypes.array,
  categoriesLoading: PropTypes.bool,
  showUploadType: PropTypes.bool,
  onRowClick: PropTypes.func,
};

const mapStateToProps = ({ files }) => ({
  files: files.files,
  loading: files.loading,
  error: files.error,
  entities: files.categories,
  relatedLoading: files.relatedLoading,
  relatedFiles: files.relatedFiles,
  categoriesLoading: files.categoriesLoading,
});

const mapDispatchToProps = dispatch => ({
  onGetRelatedFiles: payload => dispatch(getRelatedFiles(payload)),
  onGetCategories: () => dispatch(getFileCategories()),
  onClearList: () => dispatch(getRelatedFilesSuccess(null)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FileManager));
