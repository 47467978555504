import { withTranslation } from "react-i18next";
import React from "react";
import { Col, Row } from "reactstrap";
import { Colors, CURRENT_LANG } from "common";
import classnames from "classnames";
import moment from "moment";
import StatusText from "components/Common/StatusText";
import correspondenceIcon from "../../assets/icons/correspondence-home.png";
import projectIcon from "../../assets/icons/projects-home.png";

const Correspondence: React.FC<Props> = ({
  selectedCorrespondence,
  item,
  onClick
}) => {
  return (
    <Row
      onClick={() => {
        onClick(item.idCorrespondence);
      }}
      className={classnames({
        "active-corres":
          selectedCorrespondence?.correspondence?.idCorrespondence ===
          item.idCorrespondence,
      })}
      style={{
        minHeight: 148,
        cursor: "pointer",
        borderBottom: `4px solid ${Colors.searchBG}`,
        marginInlineEnd: 0,
        backgroundColor: Colors.white,
        paddingBottom: 10,
      }}
    >
      <Col lg={12} className="mx-2 mt-3">
        <div className="day-row">
          {item.from?.image ? (
            <img
              src={`data:image/png;base64,${item.from?.image}`}
              alt=""
              className="rounded-circle"
              height="35"
            />
          ) : (
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle"
                style={{ padding: 15 }}
              >
                <p className="mb-0 h5 text-white">
                  {item.from?.name?.charAt(0)}
                </p>
              </span>
            </div>
          )}
          <p className="cardName mx-2 my-2">{item.from?.name}</p>
          <p className="text-muted font-size-12 ms-auto my-2 mx-2">
            {item.createdDate && moment(item.createdDate).format("hh:mma")}
          </p>
        </div>
        <p className="mainTitle mx-5">{item?.subject}</p>
        <div className="day-row text-muted mx-5 day-justify-between day-w-80">
          <div className="day-row">
            <img src={correspondenceIcon} />
            <p className="mb-0 mx-2 text-wrap font-size-12">
              {CURRENT_LANG === "ar"
                ? item?.channelcode?.labelAr
                : item?.channelcode?.label}
            </p>
          </div>
          <div className="day-row">
            <img src={projectIcon} />
            <p className="mb-0 mx-2 text-wrap font-size-12">
              {CURRENT_LANG === "ar"
                ? item.regarding.labelAr
                : item.regarding.label}
            </p>
          </div>
        </div>
        <div className="mx-5">
          {item.priority?.label?.toLowerCase()?.includes("high") && (
            <StatusText
              text={"HIGH PRIORITY"}
              style={{
                marginTop: 10,
                paddingTop: 2,
                paddingBottom: 2,
                width: 100,
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  )
}

interface Props {
  selectedApproval: any;
  selectedCorrespondence: any;
  item: any;
  t: any;
  onClick: (arg: any) => void;
  index: number;
}

export default withTranslation()(Correspondence);
