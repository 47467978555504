import {
  GET_HOME,
  GET_HOME_FAIL,
  GET_HOME_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  loadingNotifications: false,
  error: "",
  home: null,
  notifications: [],
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HOME:
      return {
        ...state,
        loading: true,
      };

    case GET_HOME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        home: action.payload,
        error: "",
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: true,
      };

    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loadingNotifications: false,
        error: action.payload,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        notifications: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export default Dashboard;
