import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAIL,
  GET_APPOINTMENTS,
  UPSERT_AGENDA,
  UPSERT_AGENDA_SUCCESS,
  UPSERT_AGENDA_FAIL,
  UPSERT_APPOINTMENTS,
  UPSERT_APPOINTMENTS_SUCCESS,
  UPSERT_APPOINTMENTS_FAIL,
  GET_APPOINTMENT_DETAILS_SUCCESS,
  GET_APPOINTMENT_DETAILS_FAIL,
  GET_APPOINTMENT_DETAILS,
  GET_CHECK,
  GET_CHECK_SUCCESS,
  GET_CHECK_FAIL,
  GET_CALENDAR_FILES,
  GET_CALENDAR_FILES_SUCCESS,
  GET_CALENDAR_FILES_FAIL,
  SEND_EMAIL_TO_PARTICIPANTS,
  SEND_EMAIL_TO_PARTICIPANTS_SUCCESS,
  SEND_EMAIL_TO_PARTICIPANTS_FAIL,
} from "./actionTypes";
import { changeAppointmentByTimeZone } from "../../helpers/date_helper";

const INIT_STATE = {
  events: [],
  categories: [],
  error: {},
  appointments: [],
  appointmentDetails: null,
  files: null,
  availableAttendees: [],
};

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_EMAIL_TO_PARTICIPANTS:
      return {
        ...state,
        loading: true,
      };

    case SEND_EMAIL_TO_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case SEND_EMAIL_TO_PARTICIPANTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_CALENDAR_FILES:
      return {
        ...state,
        loading: true,
      };

    case GET_CALENDAR_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload,
        error: "",
      };

    case GET_CALENDAR_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      };

    case UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_APPOINTMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_APPOINTMENTS_SUCCESS:
      let _appointments = action.payload.lstAppointment.map(_app => {
        return {
          id: _app.idAppointment,
          description: _app.description,
          title: _app.subject,
          start: _app.startAt,
          end: _app.endAt,
          projectId: _app.regarding?.id,
          projectName: _app.regarding?.label,
          display: "block",
        };
      });

      return {
        ...state,
        appointments: _appointments,
        loading: false,
      };

    case GET_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_AGENDA:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_AGENDA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_AGENDA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_APPOINTMENTS:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        popUp: true,
      };

    case UPSERT_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        popUp: false,
      };

    case GET_APPOINTMENT_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_APPOINTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        appointmentDetails: changeAppointmentByTimeZone(action.payload),
        loading: false,
        error: "",
      };

    case GET_APPOINTMENT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_CHECK:
      return {
        ...state,
        // loading: true,
      };

    case GET_CHECK_SUCCESS:
      let array = [...JSON.parse(JSON.stringify(state.availableAttendees))];
      if (action.payload?.lstAppointment?.length === 0) {
        if (action.payload?.body?.recipient?.id) {
          array.push(action.payload?.body?.recipient?.id);
        }
      }
      return {
        ...state,
        availableAttendees: array,
        error: "",
      };

    case GET_CHECK_FAIL:
      return {
        ...state,
        error: action.payload,
        // loading: false,
      };
    default:
      return state;
  }
};

export default Calendar;
