import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Widget from "common/widget";
import { Colors } from "common";
const TasksDashboard = props => {
  const { data } = props;

  const formatedData = {
    tasks: {
      count1: data.taskSummary?.completedCount,
      count2: data.taskSummary?.delayedCount,
      count3: data.taskSummary?.inProgressCount,
      count4: data.taskSummary?.onHoldCount,
      count5: data.taskSummary?.openCount,
    },
    taskDelayed: {
      count1: data.taskDelayed?.highCount,
      count2: data.taskDelayed?.normalCount,
      count3: data.taskDelayed?.lowCount,
    },
    taskInProgress: {
      count1: data.taskInProgress?.highCount,
      count2: data.taskInProgress?.normalCount,
      count3: data.taskInProgress?.lowCount,
    },
    taskOnHold: {
      count1: data.taskOnHold?.highCount,
      count2: data.taskOnHold?.normalCount,
      count3: data.taskOnHold?.lowCount,
    },
  };

  const dashboardArr = [
    {
      id: "tasks",
      title: props.t("Tasks Summary").toUpperCase(),
      legendText1: props.t("Completed"),
      legendColor1: Colors.staBlue,
      legendValue1: formatedData.tasks.count1 || 0,

      legendText2: props.t("Delayed"),
      legendColor2: Colors.red,
      legendValue2: formatedData.tasks.count2 || 0,

      legendText3: props.t("In Progress"),
      legendColor3: Colors.yellow,
      legendValue3: formatedData.tasks.count3 || 0,

      legendText4: props.t("On Hold"),
      legendColor4: Colors.lightPlum,
      legendValue4: formatedData.tasks.count4 || 0,

      legendText5: props.t("Open"),
      legendColor5: Colors.greenTextBG,
      legendValue5: formatedData.tasks.count5 || 0,
    },
    {
      id: "taskInProgress",
      title: props.t("In Progress Tasks").toUpperCase(),
      legendText1: props.t("High"),
      legendColor1: Colors.red,
      legendValue1: formatedData.taskInProgress.count1 || 0,
      legendText2: props.t("Medium"),
      legendColor2: Colors.green,
      legendValue2: formatedData.taskInProgress.count2 || 0,
      legendText3: props.t("Low"),
      legendColor3: Colors.yellow,
      legendValue3: formatedData.taskInProgress.count3 || 0,
    },
    {
      id: "taskDelayed",
      title: props.t("Delayed Tasks").toUpperCase(),
      legendText1: props.t("High"),
      legendColor1: Colors.red,
      legendValue1: formatedData.taskDelayed.count1 || 0,
      legendText2: props.t("Medium"),
      legendColor2: Colors.green,
      legendValue2: formatedData.taskDelayed.count2 || 0,
      legendText3: props.t("Low"),
      legendColor3: Colors.yellow,
      legendValue3: formatedData.taskDelayed.count3 || 0,
    },

    {
      id: "taskOnHold",
      title: props.t("OnHold Tasks").toUpperCase(),
      legendText1: props.t("High"),
      legendColor1: Colors.red,
      legendValue1: formatedData.taskOnHold.count1 || 0,
      legendText2: props.t("Medium"),
      legendColor2: Colors.green,
      legendValue2: formatedData.taskOnHold.count2 || 0,
      legendText3: props.t("Low"),
      legendColor3: Colors.yellow,
      legendValue3: formatedData.taskOnHold.count3 || 0,
    },
  ];

  return (
    <>
      <Row className="g-1 row-margin-top-nav no-padding-card" id="tasks-charts">
        {dashboardArr.map(item => (
          <Col
            key={item.id}
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            xxl={3}
            style={{
              height: 235,
            }}
          >
            <Widget
              title={item.title}
              legendText1={item.legendText1}
              legendColor1={item.legendColor1}
              legendValue1={item.legendValue1}
              legendText2={item.legendText2}
              legendColor2={item.legendColor2}
              legendValue2={item.legendValue2}
              legendText3={item.legendText3}
              legendColor3={item.legendColor3}
              legendValue3={item.legendValue3}
              legendText4={item.legendText4}
              legendColor4={item.legendColor4}
              legendValue4={item.legendValue4}
              legendText5={item.legendText5}
              legendColor5={item.legendColor5}
              legendValue5={item.legendValue5}
              dataLabelsEnabled
              bigHight={item.id == "tasks"}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

TasksDashboard.propTypes = {
  data: PropTypes.any,
  t: PropTypes.any,
};

export default TasksDashboard;
