import React, { Component } from "react";
import PropTypes from "prop-types";

class Scores extends Component {
  render() {
    return (
      <div
        className="day-row day-align-items-center day-justify-around"
        style={{
          fontSize: 18,
          backgroundColor: this.props.bgColor,
          color: this.props.color,
          flex: 1,
          height: 70,
          borderRadius: 2,
          marginInlineEnd: this.props.marginRight ? 10 : 0,
        }}
      >
        <p className="mb-0">{this.props.value}</p>
        <p className="mb-0">{this.props.text}</p>
      </div>
    );
  }
}

Scores.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  marginRight: PropTypes.bool,
};

export default Scores;
