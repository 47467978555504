import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, Label } from "reactstrap";
import { withTranslation } from "react-i18next";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "toastr/build/toastr.min.css";

// Redux
import { connect } from "react-redux";

import { Colors, CURRENT_LANG, GUID_REGEX, TOAST_OPTIONS } from "common";
import { getQueryVariable } from "common/utils";
import Loading from "pages/Loading";
import i18n from "i18n";
import { register, setLanguage } from "store/actions";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import languageLogo from "../../assets/images/language.svg";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      loading: true,
      loadingBtn: false,
      viewType: {
        label: CURRENT_LANG === "en" ? this.props.t("En") : this.props.t("Ar"),
        value: CURRENT_LANG === "en" ? "en" : "ar",
      },
    };
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.PasswordComponent = this.PasswordComponent.bind(this);

    this.regexChar = /^(?=.*[!@#$%^&*()_\+\-\=]).+$/;
    this.regexUpper = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
    this.regexNumbers = /^(?=.*[1-9]).+$/;
    this.regexemail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  }

  componentDidMount() {
    const id = getQueryVariable("id");
    if (!id || !GUID_REGEX.test(id)) {
      this.props.history.replace("/");
    }
    this.setState({ loading: false });
  }

  handleThemeChange() {}

  handleLanguageChange() {
    let lang = CURRENT_LANG === "en" ? "ar" : "en";
    this.setState({
      language: lang,
    });
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    this.props.setLanguage(lang);
  }

  PasswordComponent = (stateKey, field, disabled = false) => {
    let languagePosition = CURRENT_LANG === "en" ? "right" : "left";

    return (
      <div style={{ position: "relative" }}>
        <input
          name="password"
          placeholder={this.props.t("Enter Password")}
          type={this.state[stateKey] ? "text" : "password"}
          className={"form-control"}
          disabled={disabled}
          {...field}
        />
        <button
          type="button"
          className="btn btn-primary"
          style={{
            backgroundColor: "transparent",
            border: "0px",
            position: "absolute",
            [languagePosition]: 0,
            top: 0,
            zIndex: 10,
          }}
          onClick={() => {
            this.setState({
              [stateKey]: !this.state[stateKey],
            });
          }}
          disabled={disabled}
        >
          <i
            className={`far ${
              this.state[stateKey] ? "fa-eye" : "fa-eye-slash"
            } d-block font-size-18`}
            style={{ color: Colors.grey }}
          ></i>
        </button>
      </div>
    );
  };


  async handleRegister(payload) {
    if (!payload) {
      return;
    }
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.log("Recaptcha has not been loaded");

      return;
    }

    const token = await executeRecaptcha("forgotpassword");
    if (token) {
      const payloadUpdate = {
        ...payload,
        token,
      };
      this.props.onRegisterUser(payloadUpdate, () => {
        toast.success(
          this.props.t("SuccessRegister"),
          TOAST_OPTIONS
        );
        this.props.history.replace("/login-external");
      });
    } else {
      console.log("error no token");
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    let languagePosition = CURRENT_LANG === "en" ? "left" : "right";
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={languageLogo} />
        </components.DropdownIndicator>
      );
    };

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5 mt-5">
          <Container>
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={5} className="mt-5">
                <div className="mt-5">
                  <Row className="justify-content-center day-align-center mb-4">
                    <p className="day-dark-text h2">
                      {this.props.t("Register Title")}
                    </p>
                    <p className="day-dark-text">
                      {this.props.t("Register Description")}
                    </p>
                  </Row>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: "",
                      password: "",
                      confirmPassword: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email(this.props.t("validEmail"))
                        .required(this.props.t("validEmail")),
                      password: Yup.string().required(
                        this.props.t("errorRegisterPassword")
                      ),
                      confirmPassword: Yup.string()
                        .required(this.props.t("errorConfirmNewPassword"))
                        .when("password", {
                          is: val => (val && val.length > 0 ? true : false),
                          then: Yup.string().oneOf(
                            [Yup.ref("password")],
                            this.props.t("errorBothPassword")
                          ),
                        }),
                    })}
                    onSubmit={values => {
                      this.setState(
                        { loadingBtn: true },
                        () => {
                          const id = getQueryVariable("id");
                          let payload = {
                            username: values.email,
                            password: values.password,
                            idContact: id,
                          };
                         this.handleRegister(payload);
                        },
                        () => {
                          this.setState({ loadingBtn: false });
                        }
                      );
                    }}
                  >
                    {({ errors, status, touched, values }) => (
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            {this.props.t("Email")}
                          </Label>

                          <Field
                            name="email"
                            type="text"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                            placeholder={this.props.t("Enter Email")}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="password" className="form-label">
                            {this.props.t("Password")}
                          </Label>

                          <Field name="password">
                            {({ field, form: { touched, errors } }) =>
                              this.PasswordComponent(
                                "showPassword",
                                field,
                                false
                              )
                            }
                          </Field>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="day-column">
                          <div className="day-row mb-2">
                            <i
                              className={
                                values.password.length < 8
                                  ? "bx bx-bx bx-shield font-size-17"
                                  : "bx bx-bx bxs-check-shield font-size-17"
                              }
                              style={{
                                color:
                                  values.password.length < 8 ? "grey" : "green",
                              }}
                            ></i>
                            <p className="mb-0 mx-2">
                              {this.props.t("At least 8")}
                            </p>
                          </div>
                          <div className="day-row mb-2">
                            <i
                              className={
                                !this.regexChar.test(values.password)
                                  ? "bx bx-bx bx-shield font-size-17"
                                  : "bx bx-bx bxs-check-shield font-size-17"
                              }
                              style={{
                                color: !this.regexChar.test(values.password)
                                  ? "grey"
                                  : "green",
                              }}
                            ></i>
                            <p className="mb-0 mx-2">
                              {this.props.t("Must Contain Special")}
                            </p>
                          </div>
                          <div className="day-row mb-2">
                            <i
                              className={
                                !this.regexUpper.test(values.password)
                                  ? "bx bx-bx bx-shield font-size-17"
                                  : "bx bx-bx bxs-check-shield font-size-17"
                              }
                              style={{
                                color: !this.regexUpper.test(values.password)
                                  ? "grey"
                                  : "green",
                              }}
                            ></i>
                            <p className="mb-0 mx-2">
                              {this.props.t("Must Contain Upper")}
                            </p>
                          </div>
                          <div className="day-row mb-2">
                            <i
                              className={
                                !this.regexNumbers.test(values.password)
                                  ? "bx bx-bx bx-shield font-size-17"
                                  : "bx bx-bx bxs-check-shield font-size-17"
                              }
                              style={{
                                color: !this.regexNumbers.test(values.password)
                                  ? "grey"
                                  : "green",
                              }}
                            ></i>
                            <p className="mb-0 mx-2">
                              {this.props.t("Must Contain Letters")}
                            </p>
                          </div>
                        </div>

                        <div className="mb-3 mt-2">
                          <Label for="confirmPassword" className="form-label">
                            {this.props.t("Confirm Password")}
                          </Label>

                          <Field name="confirmPassword">
                            {({ field, form: { touched, errors } }) =>
                              this.PasswordComponent(
                                "showConfirmPassword",
                                field,
                                false
                              )
                            }
                          </Field>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={
                              this.props.loading ||
                              !!!values.email ||
                              !!!values.password ||
                              !!!values.confirmPassword ||
                              values.password.length < 8 ||
                              !this.regexChar.test(values.password) ||
                              !this.regexUpper.test(values.password) ||
                              !this.regexNumbers.test(values.password) ||
                              !this.regexemail.test(values.email) ||
                              values.password !== values.confirmPassword
                            }
                          >
                            {(this.state.loadingBtn || this.props.loading) && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2">
                                {" "}
                              </i>
                            )}
                            {this.props.t("Register")}
                          </button>
                        </div>
                        <div className="mt-3 d-grid">
                          <p className="text-muted text-center">
                            {this.props.t("Trouble")}
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="text-center fixed-bottom mb-2 mt-4" style={{backgroundColor:"var(--bs-body-bg)"}}>
                <Row
                    className="mt-2 day-light-grey font-size-11"
                    style={{ fontWeight: 500 }}
                  >
                    <div className="w-full px-3 mb-2 google-terms">
                      {this.props.t("recaptcha1")}
                      &nbsp;
                      <a
                        href="https://policies.google.com/privacy"
                        className="anchor-tag"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {this.props.t("recaptcha2")}
                      </a>
                      &nbsp;
                      <a
                        href="https://policies.google.com/terms"
                        className="anchor-tag"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {this.props.t("recaptcha3")}
                      </a>
                      &nbsp;
                      {this.props.t("recaptcha4")}
                    </div>
                  </Row>
                  <Col
                    xs={12}
                    className=" day-light-grey font-size-11"
                    style={{ fontWeight: 500 }}
                  >
                    ©{new Date().getFullYear()}.{this.props.t("Rights")}
                  </Col>
                </div>
              </Col>
            </Row>

            <div
              style={{
                position: "absolute",
                top: 100,
                [languagePosition]: 200,
              }}
            >
              <Select
                value={this.state.viewType}
                components={{ DropdownIndicator }}
                onChange={value => {
                  this.setState({ viewType: value });
                  this.handleLanguageChange();
                }}
                options={[
                  { label: this.props.t("En"), value: "en" },
                  { label: this.props.t("Ar"), value: "ar" },
                ]}
              />
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  history: PropTypes.any,
  setLanguage: PropTypes.func,
  onRegisterUser: PropTypes.func,
  googleReCaptchaProps: PropTypes.any,
};

const mapStateToProps = ({ Profile }) => {
  return {
    loading: Profile.loadingRegister,
  };
};
const mapDispatchToProps = dispatch => ({
  setLanguage: lang => dispatch(setLanguage(lang)),
  onRegisterUser: (user, callback) => dispatch(register(user, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withGoogleReCaptcha(Register)));
