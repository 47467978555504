import React, { Component } from "react";
import PropTypes from "prop-types";
import { Progress } from "reactstrap";
import "./progressBar.css";
class ProgressBar extends Component {
  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <Progress
            value={data}
            className="progress-sm"
            style={{
              width: 80,
              marginTop: 8,
            }}
          />
          <span className="text-muted" style={{ marginInlineStart: 10 }}>
            {data}%
          </span>
        </div>
      </React.Fragment>
    );
  }
}

ProgressBar.propTypes = {
  data: PropTypes.number,
};

export default ProgressBar;
