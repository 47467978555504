import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withTranslation } from "react-i18next";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Colors,
  CURRENT_LANG,
  customLocalStorage,
  ENTITIES,
  isInternal,
  TOAST_OPTIONS,
  UPLOAD_TYPES,
} from "common";
import {
  createReferencedFiles,
  deleteRequest,
  getEntityLookups,
  getReferencedFiles,
  getRequestDetails,
  getRequestFiles,
  uploadFiles,
  upsertComments,
  upsertRequest,
} from "store/actions";
import Loading from "pages/Loading";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  getQueryVariable,
} from "common/utils";
import moment from "moment";
import RequestModal from "../RequestModal";
import StatusText from "components/Common/StatusText";
import Comments from "components/Common/Comments";
import CustomTextArea from "components/Common/CustomTextArea";
import FileItem from "components/Common/FileItem";
import FileList from "pages/FileManager/FileList";
import { toast } from "react-toastify";
import EditIcon from "components/Common/EditIcon";
import Markdown from "components/Common/Markdown";
import DeleteModal from "components/Common/DeleteModal";
import DeleteIcon from "components/Common/DeleteIcon";

class RequestDetails extends Component {
  constructor(props) {
    super(props);
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    this.state = {
      modal: false,
      value: "",
      action: "1",
      comment: "",
      attachedFile: null,
      isAddingComment: false,
      values: null,
      commentTypes: [
        { name: "All", value: "1" },
        { name: "Comments", value: "2" },
        { name: "History", value: "3" },
        { name: "Work log", value: "4" },
      ],
      updateEntities,
      selectedCommentType: "1",
      activities: [
        {
          id: "1",
          name: "Amina Assem",
          date: "9:00 am, 27 April",
          activity: "Created a new task",
        },
        {
          id: "2",
          name: "Frida Assem",
          date: "12:09 am, 25 April",
          activity: "Updated task status to ongoing",
        },
        {
          id: "3",
          name: "Sara Haha",
          date: "12:09 am, 25 April",
          activity: "Created Added an attachement",
        },
      ],
      editModal: false,
      showDeleteModal: false,
      files: [],
    };
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleRequestUpdate = this.handleRequestUpdate.bind(this);
    this.handleCommentCreate = this.handleCommentCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
  }

  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  componentDidMount() {
    const {
      onGetLookups,
      onGetRequestDetails,
      onGetReferencedFiles,
      onGetRequestFiles,
    } = this.props;
    const requestId = getQueryVariable("id");

    const payload = {
      entity: ENTITIES.REQUEST,
      idEntity: requestId,
    };
    onGetLookups(ENTITIES.REQUEST, () => {
      onGetReferencedFiles(payload, () => {});
      onGetRequestFiles(payload, () => {
        onGetRequestDetails(requestId);
      });
    });
  }

  toggleEdit() {
    this.setState(prevState => ({
      editModal: !prevState.editModal,
    }));
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleCommentCreate() {
    this.setState({ isAddingComment: true });
    const {
      onGetRequestDetails,
      onAddNewComment,
      request,
      onCreateReferencedFile,
      onUploadFiles,
    } = this.props;

    let requestDetails = request.request;

    const values = JSON.parse(JSON.stringify(this.state.values));
    const attachedFile = !!this.state.attachedFile
      ? values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER
        ? new File([this.state.attachedFile], this.state.attachedFile.name, {
            type: this.state.attachedFile.type,
          })
        : JSON.parse(JSON.stringify(this.state.attachedFile))
      : false;
    let comment = {
      commentedBy: null,
      commentedOn: "",
      description: formatLineBreaksInMarkdownFriendlyFormat(this.state.comment),
      idComments: "00000000-0000-0000-0000-000000000000",
      regarding: {
        entityName: "day_request",
        id: requestDetails?.idRequest,
        label: requestDetails?.subject,
        labelAr: "",
      },
    };

    onAddNewComment(comment, id => {
      if (!!attachedFile) {
        if (values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER) {
          // do file upload
          const payload = {
            request: {
              updates: [],
              allowExternalUsers: values.isExternalAllowed,
              allowDownloads: values.isDownloadAllowed,
              allowComments: values.isCommentsAllowed,
              createReference: true,
              path: `comments/${id}`,
              parent: "00000000-0000-0000-0000-000000000000",
            },
            files: [attachedFile],
          };

          onUploadFiles(payload, () => {
            onGetRequestDetails(request.request?.id);
          });
          toast.success(
            this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
            TOAST_OPTIONS
          );
        } else {
          let _payload = {
            idDocument: attachedFile.idDocument,
            entity: ENTITIES.COMMENT,
            idEntity: id,
          };
          onCreateReferencedFile(_payload, () => {
            onGetRequestDetails(request.request?.id);
            toast.success(
              this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
              TOAST_OPTIONS
            );
          });
        }
      } else {
        onGetRequestDetails(request.request?.id);
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
          TOAST_OPTIONS
        );
      }

      this.setState({ comment: "", isAddingComment: false });
    });
  }

  handleRequestUpdate(values, toggleModal = true) {
    const { request, onGetRequestDetails, onUpdateRequest } = this.props;
    const requestData = {
      subject: values.subject,
      idProject:
        values.idProject?.value || "00000000-0000-0000-0000-000000000000",
      priority: values.priority?.value,
      duedate: values.duedate,
      requestType: values.requestType?.value,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      idOrganization: values.idOrganization?.value,
      idRequest: request.request?.id,
      status: +values.status?.value,
      owner:
        values?.department?.value !== request.request?.department?.value
          ? "00000000-0000-0000-0000-000000000000"
          : values.assignedTo?.value || "00000000-0000-0000-0000-000000000000",
      idDepartment:
        values.department.value || "00000000-0000-0000-0000-000000000000",
      problemStatement: formatLineBreaksInMarkdownFriendlyFormat(
        values.problemStatement
      ),
      initiativeScope: formatLineBreaksInMarkdownFriendlyFormat(
        values.initiativeScope
      ),
      businessImpact: formatLineBreaksInMarkdownFriendlyFormat(
        values.businessImpact
      ),
      keyStakeholders: formatLineBreaksInMarkdownFriendlyFormat(
        values.keyStakeholders
      ),
      benefits: formatLineBreaksInMarkdownFriendlyFormat(values.financial),
      assumptions: formatLineBreaksInMarkdownFriendlyFormat(values.assumptions),
      mitigation: formatLineBreaksInMarkdownFriendlyFormat(values.mitigation),
      resources: formatLineBreaksInMarkdownFriendlyFormat(values.resources),
      deliverables: values.deliverables,
      risks: formatLineBreaksInMarkdownFriendlyFormat(values.risks),
    };

    onUpdateRequest(requestData, () => {
      onGetRequestDetails(request.request?.id);
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Request")),
        TOAST_OPTIONS
      );
      // When the user changes the department or the owner (so long as they are not the original assigner who would always have acess to the request) then forward them back to the request page.
      if (
        (values?.department?.value !== request.request?.department?.value ||
          values.assignedTo.value !== request.request?.owner.value) &&
        request.request?.assignedBy.id !==
          JSON.parse(customLocalStorage.getItem("authUser"))?.contactId
      ) {
        window.location.href = "/requests";
        console.log("Forward");
      }
    });
    toggleModal && this.toggleEdit();
  }

  handleAssignRequestToSelf() {
    const { request, onGetRequestDetails, onUpdateRequest } = this.props;
    const requestData = {
      subject: request.request?.subject,
      idProject:
        request.request.projectName || "00000000-0000-0000-0000-000000000000",
      priority: request.request.priority?.value,
      description: request.request.description,
      idOrganization: request.request.organization?.value,
      requestType: request.request.requestType?.value,

      idRequest: request.request.idRequest,
      status: +request.request.status?.value,
      owner: JSON.parse(customLocalStorage.getItem("authUser")).contactId,
      idDepartment:
        request.request.department.value ||
        "00000000-0000-0000-0000-000000000000",
      problemStatement: request.request.problemStatement,
      initiativeScope: request.request.initiativeScope,
      businessImpact: request.request.businessImpact,
      keyStakeholders: request.request.keyStakeholders,
      benefits: request.request.benefits,
      assumptions: request.request.assumptions,
      mitigation: request.request.mitigation,
      resources: request.request.resources,
      deliverables: request.request.deliverables,
      risks: request.request.risks,
    };

    onUpdateRequest(requestData, () => {
      onGetRequestDetails(request.request.id);
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Request")),
        TOAST_OPTIONS
      );
    });
  }

  async handleDeleteRequest() {
    const { onDeleteRequest, request } = this.props;

    try {
      this.props.onDeleteRequest();

      const requestObject = {
        request: request.request.idRequest,
        basicParameters: {
          entity: ENTITIES.REQUEST,
        },
      };

      await onDeleteRequest(requestObject, () => {
        this.setState({ showDeleteModal: false });
        toast.success(
          this.props.t("SuccessDelete").replace("*", this.props.t("Request")),
          TOAST_OPTIONS
        );
        setTimeout(() => {
          window.location.href = "/requests";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { request, loading, error, loadingLookups, loadingUpload ,pageNumber , statusCode} =
      this.props;

    if (
      loading ||
      !request ||
      loadingLookups ||
      loadingUpload ||
      this.state.isAddingComment
    ) {
      return <Loading />;
    }
    const { updateEntities } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <DeleteModal
            onCloseClick={() => this.setState({ showDeleteModal: false })}
            onDeleteClick={this.handleDeleteRequest}
            show={this.state.showDeleteModal}
          />
          <RequestModal
            modal={this.state.editModal}
            isEdit={true}
            request={request.request}
            toggle={this.toggleEdit}
            handleSubmit={this.handleRequestUpdate}
            files={this.state.files}
            onFilesAccepted={files => {
              this.setState({ files });
            }}
            entityLookups={this.props.entityLookups?.[ENTITIES.REQUEST]}
          />
          <MetaTags>
            <title>
              {this.props
                .t("Title")
                .replace("*", this.props.t("Request Details"))}
            </title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Requests")}
              titleLinkTo={"/requests?page="+pageNumber+"&status="+statusCode}
              breadcrumbItem={this.props.t("Request Details")}
            />
            <Row className="g-1">
              <Col sm={9} className="g-1">
                <Card style={{ marginBottom: 5 }}>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle>
                        <div className="day-row day-justify-between">
                          <div>
                            <h2>{request.request?.subject}</h2>
                            <div className="day-row mt-2">
                              {request.request?.organization?.label && (
                                <div
                                  className="text-muted font-size-12"
                                  style={{
                                    fontWeight: "normal",
                                    marginInlineEnd: 20,
                                  }}
                                >
                                  <i className="mdi mdi-account-outline"> </i>
                                  {request.request?.organization?.label}
                                </div>
                              )}
                              {(request.request?.owner?.label ||
                                request.request?.department?.label) && (
                                <div
                                  className="text-muted font-size-12"
                                  style={{
                                    fontWeight: "normal",
                                    marginInlineEnd: 20,
                                    marginInlineStart: 5,
                                  }}
                                >
                                  <i className="mdi mdi-email-outline"> </i>
                                  {request.request?.owner?.label ||
                                    request.request?.department?.label}
                                </div>
                              )}
                              {request.request?.duedate && (
                                <div
                                  className="text-muted font-size-12"
                                  style={{
                                    fontWeight: "normal",
                                    marginInlineEnd: 20,
                                    marginInlineStart: 5,
                                  }}
                                >
                                  <i className="mdi mdi-clock-time-eight-outline text-muted"></i>
                                  {`${this.props.t("Due")} ${moment(
                                    request.request?.duedate
                                  ).fromNow()}`}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </CardTitle>

                      <div className="day-row mt-2">
                        {request.request?.canAssign &&
                          request.request?.assignedBy?.id !==
                            JSON.parse(customLocalStorage.getItem("authUser"))
                              ?.contactId && (
                            <Button
                              className="font-16 customTable-button text-nowrap"
                              onClick={() => this.handleAssignRequestToSelf()}
                            >
                              {this.props.t("Assign to me")}
                            </Button>
                          )}
                        <FileList
                          path={["requests", request.request?.subject]}
                          payload={{
                            entity: ENTITIES.REQUEST,
                            idEntity: request.request?.idRequest,
                          }}
                          uploadButtonStyle={{
                            marginInlineStart: 5,
                            marginInlineEnd: 5,
                          }}
                          files={this.props.files}
                          showFilesOnly
                          uploadButtonOnly
                          showUploadType
                        />
                        {updateEntities.includes(ENTITIES.REQUEST) &&
                          request.request?.canEdit && (
                            <EditIcon
                              onClick={this.toggleEdit}
                              style={{
                                backgroundColor: Colors.searchBG,
                                borderRadius: 10,
                                marginInlineStart: 5,
                                marginInlineEnd: 5,
                              }}
                            />
                          )}
                        {request.request?.canDelete && (
                          <DeleteIcon
                            onClick={() => {
                              this.setState({ showDeleteModal: true });
                            }}
                            style={{
                              backgroundColor: Colors.searchBG,
                              borderRadius: 10,
                              margin: 5,
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <Row style={{ marginTop: 50 }}>
                      <p className="text-muted mb-0">
                        {this.props.t("DescriptionForm")}
                      </p>
                      <div style={{ marginTop: 8 }}>
                        <Markdown>{request.request?.description}</Markdown>
                      </div>

                      <p className="text-muted mb-1">
                        {this.props.t("Attachments").toUpperCase()}
                      </p>
                      <div className="day-row-wrap">
                        {this.props.referencedFiles &&
                        this.props.referencedFiles.length > 0
                          ? this.props.referencedFiles
                              .filter(f => (isInternal ? true : !f.isInternal))
                              .map((f, idx) => {
                                return <FileItem file={f} key={idx} />;
                              })
                          : this.props.t("None")}
                      </div>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>{this.props.t("Comments")}</CardTitle>

                    <Comments comments={request.comments} className="mt-2" />

                    <CustomTextArea
                      onChange={this.handleChange}
                      value={this.state.comment}
                      isAttachment
                      path={["requests", request.request?.subject]}
                      payload={{
                        entity: ENTITIES.REQUEST,
                        idEntity: request.request?.idRequest,
                      }}
                      files={this.props.files}
                      setFile={(file, values) => {
                        this.setState({ attachedFile: file, values });
                      }}
                    />

                    <div style={{ marginTop: 10 }} className="day-text-end">
                      <Button
                        className="customTable-button"
                        disabled={this.state.comment.length === 0}
                        onClick={this.handleCommentCreate}
                        style={{ paddingInlineStart: 30, paddingInlineEnd: 30 }}
                      >
                        {this.props.t("Add")}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} className="g-1">
                <Card style={{ marginBottom: 5 }}>
                  <CardBody>
                    <p className="mainTitle">{this.props.t("Details")}</p>
                    <Row className="my-2">
                      <div className="day-column day-w-100">
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("StatusForm")}</Col>
                          <Col sm={6}>
                            <StatusText text={request.request?.status?.label} />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Importance")}</Col>
                          <Col sm={6}>
                            <StatusText
                              text={request.request?.priority?.label}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Request Number")}</Col>
                          <Col sm={6}> {request.request?.requestNumber}</Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Request Name")}</Col>
                          <Col sm={6} className="text-primary">
                            {request.request?.subject}
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Assigned To")}</Col>
                          <Col sm={6} className="text-primary">
                            <Link
                              to={`/individual-details?id=${request.request?.owner?.value}`}
                            >
                              {request.request?.owner?.label ||
                                this.props.t("Awaiting")}
                            </Link>
                          </Col>
                        </Row>
                        {request?.request?.department && (
                          <Row className="my-2">
                            <Col sm={6} className="text-uppercase">
                              {this.props.t("Department")}
                            </Col>
                            <Col sm={6} className="text-primary">
                              {CURRENT_LANG === "en"
                                ? request.request?.department?.label
                                : request.request?.department?.labelAr ||
                                  request.request?.department?.label}
                            </Col>
                          </Row>
                        )}
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("OrganizationForm")}</Col>
                          <Col sm={6} className="text-primary">
                            <Link
                              to={`/organization-details?id=${request.request?.organization?.value}`}
                            >
                              {request.request?.organization?.label}
                            </Link>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Request Type")}</Col>
                          <Col sm={6} className="text-primary">
                            {request.request?.requestType?.label}
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Start Date Form")}</Col>
                          <Col sm={6} className="text-primary">
                            {moment(request.request?.createdOn).format(
                              "DD/MM/YYYY"
                            )}
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col sm={6}>{this.props.t("Due Date Form")}</Col>
                          <Col sm={6} className="text-primary">
                            {!!request.request?.duedate
                              ? moment(request.request?.duedate).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

RequestDetails.propTypes = {
  request: PropTypes.any,
  onGetRequestDetails: PropTypes.func,
  t: PropTypes.any,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onUpdateRequest: PropTypes.func,
  onAddNewComment: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  onGetReferencedFiles: PropTypes.func,
  onCreateReferencedFile: PropTypes.func,
  onUploadFiles: PropTypes.func,
  onGetRequestFiles: PropTypes.func,
  files: PropTypes.any,
  referencedFiles: PropTypes.any,
  permissions: PropTypes.any,
  loadingUpload: PropTypes.bool,
  onDeleteRequest: PropTypes.func,
  pageNumber: PropTypes.any,
  statusCode: PropTypes.any,
};

const mapStateToProps = ({ requests, Layout, files }) => ({
  request: requests.request,
  loading: requests.loading,
  error: requests.error,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  files: requests.files,
  referencedFiles: files.referencedFiles,
  permissions: Layout.permissions,
  loadingUpload: files.loading,
  pageNumber: requests.pageNumber,
  statusCode:requests.requestStatusCode
});

const mapDispatchToProps = dispatch => ({
  onGetRequestDetails: id => dispatch(getRequestDetails(id)),
  onUpdateRequest: (payload, callback) =>
    dispatch(upsertRequest(payload, callback)),
  onAddNewComment: (payload, callback) =>
    dispatch(upsertComments(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetReferencedFiles: (payload, callback) =>
    dispatch(getReferencedFiles(payload, callback)),
  onCreateReferencedFile: (payload, callback) =>
    dispatch(createReferencedFiles(payload, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onGetRequestFiles: (payload, callback) =>
    dispatch(getRequestFiles(payload, callback)),
  onDeleteRequest: (payload, callback) =>
    dispatch(deleteRequest(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(RequestDetails)));
