import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import { withTranslation } from "react-i18next";
import {
  getEntityLookups,
  getUserProfile,
  getUserProfileFail,
  getUsers,
  updateUser,
} from "store/actions";
import Loading from "pages/Loading";
import {
  filterPermittedEntities,
  getBase64,
  getQueryVariable,
} from "common/utils";
import {
  Colors,
  CURRENT_LANG,
  customLocalStorage,
  ENTITIES,
  TOAST_OPTIONS,
  USER_TYPES,
} from "common";
import IndividualModal from "./IndividualModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Widget from "common/widget";
import moment from "moment";
import { toast } from "react-toastify";
import EditIcon from "components/Common/EditIcon";

const colors = [Colors.yellow, Colors.green, Colors.red, Colors.darkGreen];
let colorsIndex = -1;
class InidvidualDetails extends Component {
  constructor(props) {
    super(props);
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    this.state = {
      customActiveTab: "1",
      isUpdateIndividual: updateEntities.includes(ENTITIES.INDIVIDUALS),
      file: null,
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.toggleCustom = this.toggleCustom.bind(this);
    this.handleUserUpdate = this.handleUserUpdate.bind(this);
    this.getFirst5Projects = this.getFirst5Projects.bind(this);
  }

  componentDidMount() {
    const { onGetUserDetails, getUserProfileFail, onGetLookups } = this.props;
    const userId = getQueryVariable("id");
    onGetLookups(ENTITIES.INDIVIDUALS, () => {
      onGetUserDetails(userId);
      getUserProfileFail("");
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  async handleUserUpdate(values, toggleModal = true) {
    const { onGetUsers, onUpdateUser, userProfile, onGetUserDetails } =
      this.props;
    try {
      let image = "";
      if (this.state.file) {
        image = await getBase64(this.state.file);
      }

      const user = {
        idContact: userProfile.contactDetails.idContact,
        firstname: values?.name.split(" ")[0]?.trim(),
        lastname: values?.name.split(" ")[1]
          ? values?.name.split(" ")[1]?.trim()
          : "",
        email: values.email,
        idRole: values.role.value || "00000000-0000-0000-0000-000000000000", //-
        jobTitle: values.title,
        idOrganization: values.organization.value, //-
        phoneNumber: typeof values.phoneNumber === 'number' ? values.phoneNumber?.toString() :values.phoneNumber ,
        department: values.department,
        employeeId: values.employee,
        profileImage: image, //-
        isDepartmentChampion:
          userProfile?.contactDetails?.isDepartmentChampion || false,
      };

      onUpdateUser(user, () => {
        toast.success(
          this.props
            .t("SuccessUpdate")
            .replace("*", this.props.t("Individual")),
          TOAST_OPTIONS
        );
        onGetUsers();
        onGetUserDetails(userProfile.contactDetails.idContact);
      });
      {
        toggleModal && this.toggle();
      }
    } catch (err) {
      console.error(err);
    }
  }

  getFirst5Projects(projects = []) {
    if (!projects || !Array.isArray(projects)) {
      return [];
    }
    return projects.length > 5 ? projects.slice(0, 5) : projects;
  }

  render() {
    const { loading, error, userProfile, loadingLookups } = this.props;

    if (loading || !userProfile || loadingLookups) {
      return <Loading />;
    }
    const { isUpdateIndividual } = this.state;
    const delayedTasks = userProfile.tasks.lstTasks.filter(
      task =>
        moment(task.dueDate).isBefore(moment(), "day") &&
        task.status.label?.toLowerCase() !== "completed"
    );
    const dueTodayTasks = userProfile.tasks.lstTasks.filter(task =>
      moment(task.dueDate).isSame(moment(), "day")
    );

    const delayedRequests = userProfile.requests.listRequests.filter(
      request =>
        moment(request.dueDate).isBefore(moment(), "day") &&
        request.status.label?.toLowerCase() !== "completed"
    );
    const dueTodayRequests = userProfile.requests.listRequests.filter(request =>
      moment(request.dueDate).isSame(moment(), "day")
    );

    // import { customLocalStorage, TOAST_OPTIONS, USER_TYPES } from "common";

    // componentDidMount() {
    //   const user = JSON.parse(customLocalStorage.getItem("authUser"));
    //   // to always make sure user is logged in with correct local storage values
    //   if (user) {
    //     this.props.onGetPermissions(() => {
    //       this.props.onGetLookups("contact");
    //       const userType = JSON.parse(customLocalStorage.getItem("userType"));
    //       if (userType == USER_TYPES.EXTERNAL_USER) {
    //         this.props.getExternalDetails();
    //       } else if (userType == USER_TYPES.INTERNAL_USER) {
    //         this.props.getInternalDetails();
    //       }
    //     });
    //   }
    // }
    const user = JSON.parse(customLocalStorage.getItem("authUser"));
    const userType = JSON.parse(customLocalStorage.getItem("userType"));

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Tasks Title")}</title>
          </MetaTags>

          <Container fluid>
            <Breadcrumbs>
              <Link
                underline="hover"
                color="inherit"
                to="/people?tab=individuals"
              >
                {this.props.t("People")}
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/people?tab=individuals&page="+this.props.usersPageNumber}
              >
                {this.props.t("Individual")}
              </Link>
              <span>{this.props.t("Contact Details")}</span>
            </Breadcrumbs>
            <Row>
              <Col lg="4" className="g-1">
                <Card className="mb-0" style={{ maxHeight: "85vh" }}>
                  <CardBody>
                    {isUpdateIndividual && userProfile.contactDetails.canEdit && (
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <EditIcon
                          onClick={this.toggle}
                          style={{
                            backgroundColor: Colors.searchBG,
                            borderRadius: 10,
                            margin: 5,
                          }}
                        />
                      </div>
                    )}
                    <div className="day-column-nowrap day-align-items-center day-w-100">
                      <div className="me-3">
                        {!!userProfile.contactDetails.image ? (
                          <img
                            src={`data:image/png;base64,${userProfile.contactDetails.image}`}
                            alt=""
                            // className="avatar-md  img-thumbnail. p-2 bd-highlight col-example"
                            className="mb-2"
                            style={{
                              height: "130px",
                              width: "130px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <div className="avatar-lg mb-1">
                            <span
                              className="avatar-title rounded-circle"
                              style={{
                                marginInlineStart: "12px",
                              }}
                            >
                              <p className="mb-0" style={{ fontSize: 24 }}>
                                {userProfile.contactDetails.firstname.charAt(0)}
                              </p>
                            </span>
                          </div>
                        )}
                      </div>

                      <h5 className="text-dark">
                        {`${userProfile.contactDetails.firstname ?? ""} ${
                          userProfile.contactDetails.lastname ?? ""
                        }`}
                      </h5>

                      {!userProfile.contactDetails.jobTitle && (
                        <p className="text-dark">
                          {userProfile.contactDetails.jobTitle}
                        </p>
                      )}
                      <p className="text-muted">
                        {CURRENT_LANG === "ar"
                          ? userProfile.contactDetails.organization.labelAr
                          : userProfile.contactDetails.organization.label}
                      </p>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          flexDirection: "column",
                          marginTop: 20,
                          width: "100%",
                        }}
                      >
                        {userType !== USER_TYPES.INTERNAL_USER && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <h6 className="text-muted">
                                {this.props.t("EmployeeIdDetails")}
                              </h6>

                              <h6>
                                {userProfile.contactDetails.employeeId
                                  ? userProfile.contactDetails.employeeId
                                  : ""}
                              </h6>
                            </div>
                            <hr style={{ marginTop: 5 }} />
                            <div className="day-row-nowrap day-justify-between day-align-center">
                              <h6 className="text-muted">
                                {this.props.t("Phone Number Form")}
                              </h6>
                              <a
                                href={`tel:${userProfile.contactDetails.phoneNumber}`}
                                className="text-decoration-underline"
                              >
                                {userProfile.contactDetails.phoneNumber}
                              </a>
                            </div>
                            <hr style={{ marginTop: 5 }} />
                          </>
                        )}
                        <div className="day-row-nowrap day-justify-between day-align-center">
                          <h6 className="text-muted text-uppercase">
                            {this.props.t("Sector")}
                          </h6>
                          <h6>
                            {userProfile.contactDetails.sectors
                              ? userProfile.contactDetails.sectors
                              : ""}
                          </h6>
                        </div>
                        <hr style={{ marginTop: 5 }} />
                        <div className="day-row-nowrap day-justify-between day-align-center">
                          <h6 className="text-muted text-uppercase">
                            {this.props.t("Department")}
                          </h6>
                          <h6>
                            {userProfile.contactDetails.department
                              ? userProfile.contactDetails.department
                              : ""}
                          </h6>
                        </div>

                        <hr style={{ marginTop: 5 }} />
                        <div className="day-row-nowrap day-justify-between day-align-center">
                          <h6 className="text-muted">
                            {this.props.t("EmailForm")}
                          </h6>
                          <a
                            href={`mailto:${userProfile.contactDetails.email}`}
                            className="text-decoration-underline"
                          >
                            {userProfile.contactDetails.email}
                          </a>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="8" className="g-1">
                <Row className="g-1" style={{ marginBottom: 0 }}>
                  <Col lg="6" style={{ margin: 0 }}>
                    <Widget
                      title={this.props.t("Tasks")}
                      legendText1={this.props.t("Delayed")}
                      legendColor1={Colors.red}
                      legendValue1={delayedTasks.length}
                      legendText2={this.props.t("Due Today")}
                      legendColor2={Colors.yellow}
                      legendValue2={dueTodayTasks.length}
                      legendText3={""}
                      legendColor3={""}
                      legendValue3={0}
                      onClick={() => {
                        this.props.history.push("/tasks");
                      }}
                    />
                  </Col>

                  <Col lg="6" style={{ margin: 0 }}>
                    <Widget
                      title={this.props.t("Requests")}
                      legendText1={this.props.t("Delayed")}
                      legendColor1={Colors.red}
                      legendValue1={delayedRequests.length}
                      legendText2={this.props.t("Due Today")}
                      legendColor2={Colors.yellow}
                      legendValue2={dueTodayRequests.length}
                      legendText3={""}
                      legendColor3={""}
                      legendValue3={0}
                      onClick={() => {
                        this.props.history.push("/requests");
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CardTitle style={{ fontWeight: "700" }}>
                          {`${
                            userProfile.contactDetails.firstname
                          }'s ${this.props.t("Projects")}`}
                        </CardTitle>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "column",
                            marginTop: 20,
                            width: "100%",
                          }}
                        >
                          {this.getFirst5Projects(
                            userProfile.contactDetails.project
                          ).map((item, index) => {
                            if (colorsIndex === colors.length - 1) {
                              colorsIndex = 0;
                            } else {
                              colorsIndex++;
                            }
                            return (
                              <Link
                                key={item.idProject}
                                to={`/project-details?id=${item.idProject}`}
                              >
                                <div className="day-row-nowrap day-align-center day-align-items-center day-justify-between p-1">
                                  <div className="day-row day-align-items-center">
                                    <p
                                      className="mb-0 p-2"
                                      style={{
                                        color: colors[colorsIndex],
                                        backgroundColor:
                                          colors[colorsIndex] + "1A",
                                        marginInlineEnd: 20,
                                        borderRadius: 5,
                                        height: 40,
                                        width: 40,
                                      }}
                                    >
                                      {item.titleEn.includes(" ")
                                        ? `${item.titleEn.split(" ")[0][0]}${
                                            item.titleEn.split(" ")[1][0]
                                          }`
                                        : item.titleEn.charAt(0)}
                                    </p>
                                    <div className="day-column">
                                      <h6 className="text-dark mb-1">
                                        {item.titleEn}
                                      </h6>
                                      {item.organization && (
                                        <p className="text-muted mb-0">
                                          {item.organization}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <h6>{item?.owner?.name}</h6>
                                </div>
                                {index <
                                  userProfile.contactDetails.project.length -
                                    1 && <hr style={{ marginTop: 10 }} />}
                              </Link>
                            );
                          })}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <IndividualModal
          modal={this.state.modal}
          isEdit={true}
          user={userProfile.contactDetails}
          toggle={this.toggle}
          handleSubmit={this.handleUserUpdate}
          file={this.state.file}
          onFilesAccepted={file => {
            this.setState({ file: file[0] });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.INDIVIDUALS]}
        />
      </React.Fragment>
    );
  }
}

InidvidualDetails.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  userProfile: PropTypes.any,
  error: PropTypes.string,
  onGetUserDetails: PropTypes.func,
  history: PropTypes.any,
  getUserProfileFail: PropTypes.func,
  onGetUsers: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  usersPageNumber: PropTypes.any,
  orgPageNumber: PropTypes.any,
};

const mapStateToProps = ({ contacts, Layout }) => ({
  error: contacts.error,
  userProfile: contacts.userProfile,
  loading: contacts.loading,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  usersPageNumber: contacts.usersPageNumber,
  orgPageNumber: contacts.orgPageNumber,
});

const mapDispatchToProps = dispatch => ({
  onGetUserDetails: payload => dispatch(getUserProfile(payload)),
  getUserProfileFail: err => dispatch(getUserProfileFail(err)),
  onGetUsers: () => dispatch(getUsers()),
  onUpdateUser: (user, callback) => dispatch(updateUser(user, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(InidvidualDetails)));
