import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_PROJECTS_OVERVIEW,
  GET_ALL_PROJECTS,
  GET_ALL_ACTIVE_PROJECTS,
  GET_PROJECT_DETAILS,
  UPSERT_PROJECT,
  UPSERT_STREAM,
  UPSERT_MEMBER,
  GET_PROJECT_FILES,
  GET_ALLPROJECTSOVERVIEW,
  DELETE_PROJECT,
  DELETE_STREAM,
  DELETE_MEMBER,
  GET_PROJECT_DASHBOARD,
  GET_ALL_PROJECT_DASHBOARD,
  GET_PROJECTS_OPTIONS
} from "./actionTypes";
import {
  getProjectsOverviewSuccess,
  getProjectsOverviewFail,
  getAllProjectsSuccess,
  getAllProjectsFail,
  getAllActiveProjectsSuccess,
  getAllActiveProjectsFail,
  getProjectDetailsSuccess,
  getProjectDetailsFail,
  upsertProjectFail,
  upsertProjectSuccess,
  upsertStreamFail,
  upsertStreamSuccess,
  upsertMemberSuccess,
  upsertMemberFail,
  getProjectFilesSuccess,
  getProjectFilesFail,
  getAllProjectsOverviewSuccess,
  getAllProjectsOverviewFail,
  deleteProjectSuccess,
  deleteProjectFail,
  deleteStreamSuccess,
  deleteStreamFail,
  deleteMemberSuccess,
  deleteMemberFail,
  getProjectsDashboardFail,
  getProjectsDashboardSuccess,
  getAllProjectsDashboardSuccess,
  getAllProjectsDashboardFail,
  getProjectptionsSuccess,
  getProjectptionsFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProjectsOverview,
  getAllProjectsOverview,
  getAllProjects,
  getAllActiveProjects,
  getProjectDetails,
  upsertProject,
  upsertStream,
  upsertMember,
  getRelatedFiles,
  deleteRecord,
  getProjectsDashboard,
  getDashboardAllProjects,
  getProjectsOptions
} from "helpers/backend_helper";
import {
  ENTITIES,
  getGenericError,
  isInternal,
  idRole,
  idContact,
  DEFAULT_OPTION,
} from "common";
import { endTheBar } from "common/utils";

function* fetchAllProjectsOverview() {
  try {
    const response = yield call(getAllProjectsOverview);
    yield put(getAllProjectsOverviewSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getAllProjectsOverviewFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllProjectsOverviewFail(error.message));
    }
  }
}

function* fetchProjectsOverview({ payload }) {
  try {
    let body = {
      id: payload,
    };
    const response = yield call(getProjectsOverview, body);
    yield put(getProjectsOverviewSuccess(response.responseObject));
    endTheBar();
  } catch (error) {
    if (error.response) {
      yield put(
        getProjectsOverviewFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getProjectsOverviewFail(error.message));
    }
  }
}

function* fetchAllProjects({ payload, callback }) {
  const body = payload ? {  
    projectStatus:payload.statusCode || '1',
    pageCount:payload.pageCount,
    pageNumber:payload.pageNumber,
    searchString:payload.searchString.trim(),
  } : {};
  try {
    const response = yield call(getAllProjects,body);
    yield put(
      getAllProjectsSuccess({
        projects: response.responseObject?.lstProjects|| [],
        searchString:body.searchString,
        projectStatus:body.projectStatus,
        totalRecordCount:response.responseObject?.totalRecordCount || 0,
        pageNumber:body.pageNumber || 1
      })
    );
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getAllProjectsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllProjectsFail(error.message));
    }
  }
}
function* fetchAllActiveProjects({ payload, callback }) {
  const body = payload ? {  
    pageCount:payload.pageCount,
    pageNumber:payload.pageNumber,
    projectStatus:null

  } : {};
  try {
    const response = yield call(getAllActiveProjects,body);
    yield put(
      getAllActiveProjectsSuccess({
        projects: response.responseObject.lstProjects || [],
        totalRecordCount:response.responseObject.totalRecordCount || 0,
        pageNumber:body.pageNumber || 1
      })
    );
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getAllActiveProjectsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllActiveProjectsFail(error.message));
    }
  }
}
function* fetchProjectsDashboard() {

  try {
    const response = yield call(getProjectsDashboard , {});

    yield put(
      getProjectsDashboardSuccess({
        dashboardData: response.responseObject || []
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(
        getProjectsDashboardFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getProjectsDashboardFail(error.message));
    }
  }
}

function* upsertProjectSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertProject, body);
    yield put(upsertProjectSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertProjectFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertProjectFail(error.message));
    }
  }
}

function* upsertStreamSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertStream, body);
    yield put(upsertStreamSuccess(response));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        upsertStreamFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertStreamFail(error.message));
    }
  }
}

function* upsertMemberSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertMember, body);
    yield put(upsertMemberSuccess(response));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        upsertMemberFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertMemberFail(error.message));
    }
  }
}

function* fetchProjectDetails({ payload }) {
  try {
    let body = {
      entity: ENTITIES.PROJECT,
      id: payload,
    };
    const response = yield call(getProjectDetails, body);
    yield put(
      getProjectDetailsSuccess({
        projectDetails: response.responseObject,
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(
        getProjectDetailsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getProjectDetailsFail(error.message));
    }
  }
}

function* fetchProjectFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };

    const response = yield call(getRelatedFiles, body);
    yield put(getProjectFilesSuccess(response.responseObject.lstDocuments));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getProjectFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getProjectFilesFail(error.message));
    }
  }
}

function* deleteProjectSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternal: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request.idProject,
        idOrganizationCallingUser: DEFAULT_OPTION.value,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteProjectSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        deleteProjectFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(deleteProjectFail(error.message));
    }
  }
}

function* deleteStreamSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternal: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request.idStream,
        idOrganizationCallingUser: DEFAULT_OPTION.value,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteStreamSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        deleteStreamFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(deleteStreamFail(error.message));
    }
  }
}

function* deleteProjectMemberSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternal: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request.idProjectMember,
        idOrganizationCallingUser: DEFAULT_OPTION.value,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteMemberSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        deleteMemberFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(deleteMemberFail(error.message));
    }
  }
}


function* fetchDashboardAllProjects({payload}) {
  try {
    const body={
      pageCount:payload?.pageCount || 10,
      pageNumber:payload?.pageNumber || 1,
    }
    const response = yield call(getDashboardAllProjects , body);
    yield put(getAllProjectsDashboardSuccess({
      dashboardData:response.responseObject?.lstProjects || [],
      totalRecordCount:response.responseObject?.totalRecordCount,
      dashboardPageNumber:body.pageNumber,
      dashboardPageCount:body.pageCount

      }))
  } catch (error) {
    if (error.response) {
      yield put(
        getAllProjectsDashboardFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllProjectsDashboardFail(error.message));
    }
  }
}

function* fetchProjectOptions({payload}) {
  try {
    const body={
      pageCount:payload?.pageCount || 10,
      pageNumber:payload?.pageNumber || 1,
    }
    const response = yield call(getProjectsOptions , body);
    yield put(getProjectptionsSuccess({
      data:response.responseObject?.lstProjects || [],
      totalRecordCount:response.responseObject?.totalRecordCount,
      listPageNumber:body.pageNumber
      }))
  } catch (error) {
    if (error.response) {
      yield put(
        getProjectptionsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getProjectptionsFail(error.message));
    }
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS_OVERVIEW, fetchProjectsOverview);
  yield takeEvery(GET_ALL_PROJECTS, fetchAllProjects);
  yield takeEvery(GET_ALL_ACTIVE_PROJECTS, fetchAllActiveProjects);
  yield takeEvery(GET_PROJECT_DETAILS, fetchProjectDetails);
  yield takeEvery(UPSERT_PROJECT, upsertProjectSaga);
  yield takeEvery(UPSERT_STREAM, upsertStreamSaga);
  yield takeEvery(UPSERT_MEMBER, upsertMemberSaga);
  yield takeEvery(GET_PROJECT_FILES, fetchProjectFiles);
  yield takeEvery(GET_ALLPROJECTSOVERVIEW, fetchAllProjectsOverview);
  yield takeEvery(DELETE_PROJECT, deleteProjectSaga);
  yield takeEvery(DELETE_STREAM, deleteStreamSaga);
  yield takeEvery(DELETE_MEMBER, deleteProjectMemberSaga);
  yield takeEvery(GET_PROJECT_DASHBOARD, fetchProjectsDashboard);
  yield takeEvery(GET_ALL_PROJECT_DASHBOARD, fetchDashboardAllProjects);  
  yield takeEvery(GET_PROJECTS_OPTIONS, fetchProjectOptions); 
}

export default projectsSaga;
