import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_ALL_CORRESPONDENCE,
  GET_CORRESPONDENCE_DETAILS,
  UPSERT_CORRESPONDENCE,
  GET_CORRESPONDENCE_FILES,
  GET_CORRESPONDENCES_OVERVIEW,
  DELETE_CORRESPONDENCE,
} from "./actionTypes";

import {
  getAllCorresSuccess,
  getAllCorresFail,
  getCorrespondenceDetailsSuccess,
  getCorrespondenceDetailsFail,
  upsertCorrespondenceSuccess,
  upsertCorrespondenceFail,
  getCorrespondenceFilesSuccess,
  getCorrespondenceFilesFail,
  getAllCorrespondenceOverviewSuccess,
  getAllCorrespondenceOverviewFail,
  deleteCorrespondenceSuccess,
  deleteCorrespondenceFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getAllCorres,
  getCorrespondenceDetails,
  upsertCorrespondence,
  getRelatedFiles,
  getAllCorrespondenceOverview,
  deleteRecord,
} from "../../helpers/backend_helper";

import { endTheBar } from "common/utils";
import {
  ENTITIES,
  getGenericError,
  isInternal,
  idRole,
  idContact,
  DEFAULT_OPTION,
} from "common";

function* fetchAllCorrespondenceOverview({ payload }) {
  try {
    let body = {
      projectId: payload,
    };
    const response = yield call(getAllCorrespondenceOverview, body);
    yield put(getAllCorrespondenceOverviewSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getAllCorrespondenceOverviewFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllCorrespondenceOverviewFail(error.message));
    }
  }
}

function* fetchCorres() {
  try {
    const response = yield call(getAllCorres);
    yield put(getAllCorresSuccess(response.responseObject.listCorrespondences));
    endTheBar();
  } catch (error) {
    if (error.response) {
      yield put(
        getAllCorresFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllCorresFail(error.message));
    }
  }
}

function* fetchCorrespondenceDetails({ payload }) {
  try {
    let body = {
      id: payload,
      entity: ENTITIES.CORRESPONDENCE,
    };
    const response = yield call(getCorrespondenceDetails, body);
    yield put(getCorrespondenceDetailsSuccess(response.responseObject));
    endTheBar();
  } catch (error) {
    if (error.response) {
      yield put(
        getCorrespondenceDetailsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getCorrespondenceDetailsFail(error.message));
    }
  }
}

function* upsertCorrespondenceSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertCorrespondence, body);
    yield put(upsertCorrespondenceSuccess(response));
    callback && callback(response.responseObject.idCorrespondence);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertCorrespondenceFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertCorrespondenceFail(error.message));
    }
  }
}

function* fetchCorresFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(
      getCorrespondenceFilesSuccess(response.responseObject.lstDocuments)
    );

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getCorrespondenceFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getCorrespondenceFilesFail(error.message));
    }
  }
}

function* deleteCorrespondenceSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternal: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request,
        idOrganizationCallingUser: DEFAULT_OPTION.value,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);

    yield put(deleteCorrespondenceSuccess(response));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        deleteCorrespondenceFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(deleteCorrespondenceFail(error.message));
    }
  }
}

function* corresSaga() {
  yield takeEvery(GET_ALL_CORRESPONDENCE, fetchCorres);
  yield takeEvery(GET_CORRESPONDENCE_DETAILS, fetchCorrespondenceDetails);
  yield takeEvery(UPSERT_CORRESPONDENCE, upsertCorrespondenceSaga);
  yield takeEvery(GET_CORRESPONDENCE_FILES, fetchCorresFiles);
  yield takeEvery(GET_CORRESPONDENCES_OVERVIEW, fetchAllCorrespondenceOverview);
  yield takeEvery(DELETE_CORRESPONDENCE, deleteCorrespondenceSaga);
}

export default corresSaga;
