import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Redirect,
  // Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import LoadingBar from "react-top-loading-bar";

// const ArabicLangaugeStyles = React.lazy(() =>
//   import("./assets/scss/rtl-mode.scss")
// );

// Import scss
// import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"
// Import fackbackend Configuration file
import Pages404 from "pages/Utility/pages-404";
import {
  getEntityLookups,
  getExternalDetails,
  getInternalDetails,
  getLookups,
  getPermissions,
  profileError,
  setLoadingBarProgress,
} from "./store/actions";
import {
  Colors,
  customLocalStorage,
  ENTITIES,
  isInternal,
  USER_TYPES,
} from "common";
import Pages500 from "pages/Utility/pages-500";
import Loading from "pages/Loading";
import { withTranslation } from "react-i18next";
import LanguageSelector from "LanguageSelector";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "store";
import './App.scss';
// Activating fake backend

// Activating fake firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);
import { GOOGLE_RECAPTCHA_KEY } from "helpers/api_helper";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  componentDidMount() {
    const user = JSON.parse(customLocalStorage.getItem("authUser"));
    const loc = window.location.pathname;
    // to always make sure user is logged in with correct local storage values
    if (!!user) {
      this.props.onGetPermissions(() => {
        if (isInternal) {
          this.props.onGetEntityLookups(ENTITIES.DASHBOARDS, () => {
            this.props.onGetLookups(ENTITIES.INDIVIDUALS);
          });
        } else {
          this.props.onGetLookups(ENTITIES.INDIVIDUALS);
        }
        const userType = JSON.parse(customLocalStorage.getItem("userType"));
        if (userType == USER_TYPES.EXTERNAL_USER) {
          this.props.getExternalDetails();
        } else if (userType == USER_TYPES.INTERNAL_USER) {
          this.props.getInternalDetails();
        }
      });
    } else {
      let isRouteExist = publicRoutes.some(item => item.path === loc);
      if (!isRouteExist) {
        this.props.history.replace("/");
        location.reload();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (!!this.props.user) {
        if (!!this.props.layout.permittedRoutes) {
          const loc = window.location.pathname;
          let isRouteExist = this.props.layout.permittedRoutes?.some(
            item => item.path === loc
          );
          if (!isRouteExist) {
            this.props.history.replace("/");
            location.reload();
          }
        }
        if (
          prevProps.user.firstname !== this.props.user.firstname ||
          prevProps.user.lastname !== this.props.user.lastname ||
          prevProps.user.image !== this.props.user.image
        ) {
          localStorage.setItem(
            "name",
            this.props.user.firstname + " " + this.props.user.lastname
          );
          localStorage.setItem("image", this.props.user.image);
        }
      }
      if (prevProps.layout.lang !== this.props.layout.lang) {
        location.reload();
      }
    }
  }

  render() {
    const Layout = this.getLayout();
    const user = JSON.parse(customLocalStorage.getItem("authUser"));
    if (
      (user &&
        (!this.props.layout.permittedRoutes ||
          (isInternal && !!!this.props.layout.entityLookups))) ||
      this.props.layout.loadingLang
    ) {
      return <Loading />;
    }
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={GOOGLE_RECAPTCHA_KEY || ""}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <React.Fragment>
          <LoadingBar
            progress={this.props.layout.progress}
            onLoaderFinished={() => store.dispatch(setLoadingBarProgress(0))}
            color={Colors.blue}
          />
          <LanguageSelector layout={this.props.layout} />
          <ToastContainer
            rtl={this.props.layout.lang === "ar" ? true : false}
          />
          <Router>
            <Switch>
              {!!!user && <Redirect exact from="/" to="/login-internal" />}
              {!!user && <Redirect exact from="/" to="/home" />}

              {this.props.error ? (
                <Route component={Pages500} />
              ) : (
                <>
                  {!!!user &&
                    publicRoutes.map((route, idx) => (
                      <AppRoute
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={"public" + idx}
                        isAuthProtected={false}
                      />
                    ))}

                  {!!user && !!this.props.layout.permittedRoutes && (
                    <>
                      {this.props.layout.permittedRoutes.map((route, idx) => (
                        <AppRoute
                          path={route.path}
                          layout={Layout}
                          component={route.component}
                          key={"private" + idx}
                          isAuthProtected={true}
                          exact
                        />
                      ))}
                    </>
                  )}
                </>
              )}
              <Route component={Pages404} />
            </Switch>
          </Router>
        </React.Fragment>
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    error: state.Profile.error,
    user: state.Profile.success,
  };
};

App.propTypes = {
  layout: PropTypes.object,
  getExternalDetails: PropTypes.func,
  getInternalDetails: PropTypes.func,
  profileError: PropTypes.func,
  history: PropTypes.any,
  error: PropTypes.string,
  user: PropTypes.any,
  onGetLookups: PropTypes.func,
  onGetPermissions: PropTypes.func,
  location: PropTypes.any,
  t: PropTypes.any,
  onGetEntityLookups: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  getExternalDetails: () => dispatch(getExternalDetails()),
  getInternalDetails: () => dispatch(getInternalDetails()),
  profileError: () => dispatch(profileError()),
  onGetLookups: payload => dispatch(getLookups(payload)),
  onGetEntityLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetPermissions: callback => dispatch(getPermissions(callback)),
});

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App))
);
