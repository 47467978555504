import React, { memo, useRef } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import FileList from "pages/FileManager/FileList";
import { getFileIcon } from "common/utils";
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from "common";
import useAutosizeTextArea from "components/CustomHooks/useAutosizeTextArea";

const CustomTextArea = ({
  onChange,
  value,
  style,
  t,
  isAttachment,
  path,
  payload,
  files,
  setFile,
  disabled,
  isInvalid,
}) => {
  const [currFile, setCurrFile] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [touched, setTouched] = React.useState(false);
  const textAreaRef = useRef(null);

  React.useEffect(() => {
    setFile(currFile, values);
  }, [currFile, values]);

  useAutosizeTextArea(textAreaRef.current, value);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <div className="float-end">
          {isAttachment && !disabled && (
            <FileList
              path={path}
              payload={payload}
              files={files}
              showFilesOnly
              uploadButtonOnly
              showUploadType
              icon={"mdi mdi-attachment fa-rotate-90"}
              isComments
              uploadButtonStyle={{ marginBottom: 5 }}
              setFile={(file, values) => {
                if (!!file.fileName) {
                  setCurrFile({ ...file, name: file.fileName });
                } else {
                  setCurrFile(file);
                }
                setValues(values);
              }}
            />
          )}
        </div>
        <textarea
          className={`form-control ${
            touched &&
            isInvalid &&
            (!!!value || value.length < MIN_COMMENT_LENGTH)
              ? "is-invalid"
              : ""
          }`}
          placeholder={t("Start typing here")}
          style={{
            width: "100%",
            minHeight: 71,
            overflow: "hidden",
            ...(!!style && { ...style }),
          }}
          value={value}
          ref={textAreaRef}
          onClick={() => {
            setTouched(true);
          }}
          onChange={e => {
            setCount(e.target.value.length);
            onChange(e);
          }}
          disabled={disabled}
          minLength={MIN_COMMENT_LENGTH}
          maxLength={MAX_COMMENT_LENGTH}
        />
        <p
          style={{ position: "absolute", bottom: 0, right: 10 }}
          className="mb-0 day-light-grey"
        >
          {`${count}/${MAX_COMMENT_LENGTH}`}
        </p>
      </div>
      {!!currFile && (
        <div className={`day-row-nowrap day-pointer mx-2`}>
          <i className={getFileIcon(currFile.name)}></i>
          <p className="mb-0 day-dark-text font-size-12">{currFile.name}</p>

          <button
            type="button"
            className="btn btn-primary"
            style={{
              backgroundColor: "transparent",
              border: "0px",
            }}
            onClick={() => setCurrFile(null)}
          >
            <i className="bx bx-trash font-size-18 text-danger"></i>
          </button>
        </div>
      )}
    </div>
  );
};

CustomTextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.any,
  t: PropTypes.any,
  isAttachment: PropTypes.bool,
  path: PropTypes.array,
  payload: PropTypes.any,
  files: PropTypes.any,
  setFile: PropTypes.func,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
};
export default memo(withTranslation()(CustomTextArea));
