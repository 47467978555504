import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Widget from "common/widget";
import { Colors } from "common";
const ProjectDashboard = props => {
  const { data = [], onAllProjectsClick } = props;

  const forattedData = {
    allprojects: {
      completedCount: data.projectSummary?.completedCount,
      delayedCount: data.projectSummary?.delayedCount,
      inProgressCount: data.projectSummary?.inProgressCount,
      onHoldCount: data.projectSummary?.onHoldCount,
      draftCount: data.projectSummary?.draftCount,
    },

    delayedProjects: {
      highCount: data.projectDelayed?.highCount,
      mediumCount: data.projectDelayed?.mediumCount,
      lowCount: data.projectDelayed?.lowCount,
    },

    activeProjects: {
      highCount: data.projectInProgress?.highCount,
      mediumCount: data.projectInProgress?.mediumCount,
      lowCount: data.projectInProgress?.lowCount,
    },

    projectOnHold: {
      highCount: data.projectOnHold?.highCount,
      mediumCount: data.projectOnHold?.mediumCount,
      lowCount: data.projectOnHold?.lowCount,
    },
  };
  const dashboardArr = [
    {
      id: "allProjects",
      title: props.t("Projects Summary").toUpperCase(),

      legendText1: props.t("Completed"),
      legendColor1: Colors.staBlue,
      legendValue1: forattedData.allprojects.completedCount || 0,

      legendText2: props.t("Delayed"),
      legendColor2: Colors.red,
      legendValue2: forattedData.allprojects.delayedCount || 0,

      legendText3: props.t("In Progress"),
      legendColor3: Colors.yellow,
      legendValue3: forattedData.allprojects.inProgressCount || 0,

      legendText4: props.t("On Hold"), //props.t("onHold"),
      legendColor4: Colors.lightPlum,
      legendValue4: forattedData.allprojects.onHoldCount || 0,

      legendText5: props.t("Draft"),
      legendColor5: Colors.lightGrey,
      legendValue5: forattedData.allprojects.draftCount || 0,
    },
    {
      id: "activeProjects",
      title: props.t("Projects In Progress").toUpperCase(),
      legendText1: props.t("High"),
      legendColor1: Colors.red,
      legendValue1: forattedData.activeProjects.highCount || 0,
      legendText2: props.t("Medium"),
      legendColor2: Colors.green,
      legendValue2: forattedData.activeProjects.mediumCount || 0,
      legendText3: props.t("Low"),
      legendColor3: Colors.yellow,
      legendValue3: forattedData.activeProjects.lowCount || 0,
    },
    {
      id: "projectDelayed",
      title: props.t("Projects Delayed").toUpperCase(),

      legendText1: props.t("High"),
      legendColor1: Colors.red,
      legendValue1: forattedData.delayedProjects.highCount || 0,

      legendText2: props.t("Medium"),
      legendColor2: Colors.green,
      legendValue2: forattedData.delayedProjects.mediumCount || 0,

      legendText3: props.t("Low"),
      legendColor3: Colors.yellow,
      legendValue3: forattedData.delayedProjects.lowCount || 0,
    },

    {
      id: "projectOnHold",
      title: props.t("Projects OnHold").toUpperCase(),
      legendText1: props.t("High"),
      legendColor1: Colors.red,
      legendValue1: forattedData.projectOnHold.highCount || 0,
      legendText2: props.t("Medium"),
      legendColor2: Colors.green,
      legendValue2: forattedData.projectOnHold.mediumCount || 0,
      legendText3: props.t("Low"),
      legendColor3: Colors.yellow,
      legendValue3: forattedData.projectOnHold.lowCount || 0,
    },
  ];
  const onDataPointSelection = (id, index) => {
    if (id == "allProjects") {
      let statusId = "1";
      if (index == 0) statusId = "100000002"; // completed
      if (index == 1) statusId = "100000001"; // delayed
      if (index == 2) statusId = "1"; // in progress
      if (index == 3) statusId = "100000004"; // on hold
      if (index == 4) statusId = "100000000"; // draft
      onAllProjectsClick(statusId);
    }
  };

  return (
    <>
      <Row className="g-1 no-padding-card" id="projects-charts">
        {dashboardArr.map(item => (
          <Col
            key={item.id}
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            xxl={3}
            style={{
              height: 235,
            }}
            className="g-1"
          >
            <Widget
              title={item.title}
              legendText1={item.legendText1}
              legendColor1={item.legendColor1}
              legendValue1={item.legendValue1}
              legendText2={item.legendText2}
              legendColor2={item.legendColor2}
              legendValue2={item.legendValue2}
              legendText3={item.legendText3}
              legendColor3={item.legendColor3}
              legendValue3={item.legendValue3}
              legendText4={item.legendText4}
              legendColor4={item.legendColor4}
              legendValue4={item.legendValue4}
              legendText5={item.legendText5}
              legendColor5={item.legendColor5}
              legendValue5={item.legendValue5}
              dataLabelsEnabled
              bigHight={item.id == "allProjects"}
              hasPointSelection
              onDataPointSelection={index =>
                onDataPointSelection(item.id, index)
              }
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

ProjectDashboard.propTypes = {
  onAllProjectsClick: PropTypes.func,
  data: PropTypes.any,
  t: PropTypes.any,
};

export default ProjectDashboard;
