// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  SET_ORGANIZATION,
  GET_LOOKUPS,
  GET_LOOKUPS_SUCCESS,
  GET_LOOKUPS_FAIL,
  GET_ENTITIY_LOOKUPS,
  GET_ENTITIY_LOOKUPS_SUCCESS,
  GET_ENTITIY_LOOKUPS_FAIL,
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  SET_LANGUAGE,
  SET_PROGRESS,
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_SUCCESS,
  GET_GLOBAL_SEARCH_FAIL,
  GET_LOOKUPS_COUNT,
  GET_LOOKUPS_COUNT_FAIL,
  GET_LOOKUPS_COUNT_SUCCESS,
} from "./actionTypes";

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";
import { CURRENT_LANG, customLocalStorage, DEFAULT_OPTION,ENTITIES } from "common";
import {
  filterProtectedRoutes,
  getGlobalSearchDataEnhanced,
  getMappedRegarding,
  mapPermissionsToObject,
} from "common/utils";
import { authProtectedRoutes } from "routes";

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  organization: null,
  lookups: null,
  loading: true,
  error: "",
  loadingEntityLookups: true,
  entityLookups: null,
  permissions: null,
  permittedRoutes: null,
  lang: CURRENT_LANG,
  loadingLang: false,
  progress: 0,
  globalSearchData: null,
  loadingSearch: false,
  totalDataSizeForRequests:0,
  totalSizePerStatusRequests:{}
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GLOBAL_SEARCH:
      return {
        ...state,
        loadingSearch: true,
      };

    case GET_GLOBAL_SEARCH_SUCCESS:
      let data = getGlobalSearchDataEnhanced(action.payload);
      return {
        ...state,
        globalSearchData: data,
        loadingSearch: false,
      };

    case GET_GLOBAL_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSearch: false,
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
        loadingLang: true,
      };

    case GET_PERMISSIONS:
      return {
        ...state,
        loading: true,
        permissions: null,
      };

    case GET_PERMISSIONS_SUCCESS:
      let newPermissions = mapPermissionsToObject(action.payload);
      let newPermittedRoutes = filterProtectedRoutes(
        newPermissions || {},
        authProtectedRoutes
      );
      return {
        ...state,
        permissions: newPermissions,
        permittedRoutes: newPermittedRoutes,
        loading: false,
      };

    case GET_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ENTITIY_LOOKUPS:
      return {
        ...state,
        loadingLookups: true,
      };

    case GET_ENTITIY_LOOKUPS_SUCCESS:
      let _entityLookups = JSON.parse(
        JSON.stringify(action.payload.entityLookups)
      );
      if (_entityLookups && _entityLookups.listRegarding) {
        _entityLookups.listRegarding = getMappedRegarding(
          _entityLookups.listRegarding
        );
      }
      let oldState = state.entityLookups;
      return {
        ...state,
        entityLookups: !!oldState
          ? {
              ...state.entityLookups,
              [action.payload.entityName]: _entityLookups,
            }
          : {
              [action.payload.entityName]: _entityLookups,
            },
        loadingLookups: false,
      };

    case GET_ENTITIY_LOOKUPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingLookups: false,
      };

    case SET_ORGANIZATION:
      customLocalStorage.setItem("organization", action.payload);
      return {
        ...state,
        organization: action.payload,
      };

    case GET_LOOKUPS:
      return {
        ...state,
        loading: true,
      };

    case GET_LOOKUPS_SUCCESS:
      let lookups = action.payload;
      lookups.listOrganizations.unshift(DEFAULT_OPTION);
      return {
        ...state,
        lookups,
        loading: false,
      };

    case GET_LOOKUPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
      case GET_LOOKUPS_COUNT_FAIL:
        return {
          ...state,
          error: action.payload
        };
      case GET_LOOKUPS_COUNT:
        return {
          ...state,
        };
      case GET_LOOKUPS_COUNT_SUCCESS:

        return {
          ...state,
          totalDataSizeForRequests: action?.payload?.listModules?.find(item => item.entityName === ENTITIES.REQUEST)?.count,
          totalSizePerStatusRequests: action?.payload?.listModules?.find(item => item.entityName === ENTITIES.REQUEST)
        }
  
   
    default:
      return state;
  }
};

export default Layout;
