import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import { capitalizeFirstLetter } from "common/utils";
import { withTranslation } from "react-i18next";

class Breadcrumbs extends Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-between"
              style={{ paddingBottom: 10, fontSize: 20 }}
            >
              <div className="page-title-left">
                <Breadcrumb listClassName="m-0">
                  {this.props.breadcrumbItems &&
                  this.props.breadcrumbItems.length > 0
                    ? this.props.breadcrumbItems.map((item, index) => (
                        <BreadcrumbItem
                          key={index}
                          active={
                            index === this.props.breadcrumbItems.length - 1
                          }
                        >
                          <a
                            onClick={() => this.props.onBreadcrumbClick(index)}
                            className="font-size-18"
                          >
                            {index === 0
                              ? this.props
                                  .t("DocumentsBreadcrumb")
                                  .replace("*", capitalizeFirstLetter(item))
                              : capitalizeFirstLetter(item)}
                          </a>
                        </BreadcrumbItem>
                      ))
                    : this.props.breadcrumbItem && (
                        <>
                          <BreadcrumbItem onClick={this.props.titleOnClick}>
                            <Link
                              to={
                                this.props.titleLinkTo
                                  ? this.props.titleLinkTo
                                  : window.location.pathname +
                                    window.location.search
                              }
                              className="text-muted font-size-18"
                            >
                              {this.props.title}
                            </Link>
                          </BreadcrumbItem>
                          <BreadcrumbItem active style={{display:'flex'}}>
                            <Link
                              to={
                                window.location.pathname +
                                window.location.search
                              }
                              className="font-size-18"
                            >
                              {this.props.breadcrumbItem}
                            </Link>
                          </BreadcrumbItem>
                        </>
                      )}
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string,
  titleLinkTo: PropTypes.string,
  titleOnClick: PropTypes.func,
  onBreadcrumbClick: PropTypes.func,
  isFromDocuments: PropTypes.bool,
  t: PropTypes.any,
};

export default withTranslation()(Breadcrumbs);
