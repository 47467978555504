import React from "react";
import { useTranslation } from "react-i18next";
// @ts-expect-error: react-meta-tags is not a typed library
import MetaTags from "react-meta-tags";

type Props = {
  title?: string;
  description?: string;
};

const Seo = ({ title, description }: Props) => {
  const { t } = useTranslation();
  return (
    <MetaTags>
      <title>{title ? title : t("Title")}</title>
      <meta
        name="description"
        content={description ?? t("Default SEO Description")}
      />
    </MetaTags>
  );
};

export default Seo;
