import {
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASKS,
  GET_ALL_TASKS_SUCCESS,
  GET_ALL_TASKS_FAIL,
  GET_ALL_TASKS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_DETAILS_FAIL,
  UPSERT_TASK,
  UPSERT_TASK_FAIL,
  UPSERT_TASK_SUCCESS,
  UPSERT_COMMENTS,
  UPSERT_COMMENTS_SUCCESS,
  UPSERT_COMMENTS_FAIL,
  GET_TASK_FILES,
  GET_TASK_FILES_SUCCESS,
  GET_TASK_FILES_FAIL,
  GET_ALL_TASKS_OVERVIEW,
  GET_ALL_TASKS_OVERVIEW_SUCCESS,
  GET_ALL_TASKS_OVERVIEW_FAIL,
  // GET_COMMENTS_IN_TASKS,
  // GET_COMMENTS_IN_TASKS_FAIL,
  // GET_COMMENTS_IN_TASKS_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  GET_TASKS_DASHBOARD,
  GET_TASKS_DASHBOARD_SUCCESS,
  GET_TASKS_DASHBOARD_FAIL

} from "./actionTypes";

const INIT_STATE = {
  tasks: [],
  error: "",
  loading: false,
  allTasks: null,
  comments: [],
  taskDetails: null,
  allTasksOverview: null,
};

const tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
        error: "",
      };
    case GET_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_TASKS:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_TASKS_SUCCESS:
      let _tasks = action.payload.tasks.map(_task => {
        return {
          ..._task,
          id: _task.idTask,
        };
      });
      return {
        ...state,
        allTasks: _tasks,
        loading: false,
        error: "",
        searchString:action.payload.searchString,
        totalDataSize:action.payload.totalRecordCount,
        statusCode:action.payload.statusCode,
        tasksPageNumber:action.payload.pageNumber,
      };
    case GET_ALL_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ALL_TASKS:
      return {
        ...state,
        loading: true,
      };

    case GET_TASK_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_TASK_DETAILS_SUCCESS:
      let _taskDetails = action.payload;
      let taskData = {
        ..._taskDetails,
        task: {
          ..._taskDetails.task,
          id: _taskDetails.task.idTask,
        },
      };

      return {
        ...state,
        taskDetails: taskData,
        loading: false,
        error: "",
      };

    case GET_TASK_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_TASK:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        error: "",
      };

    case DELETE_TASK:
      return {
        ...state,
        loading: true,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_COMMENTS:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_COMMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_TASK_FILES:
      return {
        ...state,
        loading: true,
      };

    case GET_TASK_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        files: action.payload,
      };

    case GET_TASK_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ALL_TASKS_OVERVIEW:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_TASKS_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ALL_TASKS_OVERVIEW_SUCCESS:
      return {
        ...state,
        allTasksOverview: action.payload,
        loading: false,
        error: "",
      };

      case GET_TASKS_DASHBOARD_SUCCESS:
        return {
          ...state,
          dashboardData: action.payload.dashboardData,
          loading: false,
          error: "",
       
        };
      case GET_TASKS_DASHBOARD_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
          dashboardData:[]
        };
      case GET_TASKS_DASHBOARD:
        return {
          ...state,
          loading: true,
        };




    default:
      return state;
  }
};

export default tasks;
