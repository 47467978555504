import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Row, Col, Card, CardBody } from "reactstrap";

// datatable related plugins
import { getAllProjects, upsertProject } from "store/actions";
import moment from "moment";
import CustomTableWithServerSide from "components/Common/CustomTableWithServerSide";
import StatusText from "components/Common/StatusText";
import Loading from "pages/Loading";
import ProjectModal from "../ProjectModal";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  getBase64,
  randomColor,
  sortCaretIcons,
} from "common/utils";
import {
  CURRENT_LANG,
  ENTITIES,
  getGlobalHeaderStyle,
  TOAST_OPTIONS,
} from "common";
import { toast } from "react-toastify";
import Tippy from "@tippy.js/react";
import ProgressBar from "./progressBar";

class allProjectsTable extends Component {
  constructor(props) {
    super(props);
    let createEntities = filterPermittedEntities(props.permissions, "create");

    let searchValue = "";
    // if reqSearch = RequestTypeCode , return the requestType name
    if (props.searchString !== "") {
      searchValue = this.mapIDOfArProjectStage(props.searchString);
    }
    this.state = {
      task: "",
      modal: false,
      deleteModal: false,
      page: 1,
      sizePerPage: 10,
      file: null,
      createEntities,
      tabletStyle: props.tabletStyle,
      tabletStyle2: props.tabletStyle2,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,
      searchString: searchValue || "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleProjectCreate = this.handleProjectCreate.bind(this);
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  mapIDOfArProjectStage = text => {
    if (text == "100000000") return "البدء";
    if (text == "100000001") return "التخطيط";
    if (text == "100000002") return "التنفيذ";
    if (text == "100000003") return "الإغلاق";
    return text;
  };
  generateColors() {
    let color = randomColor();

    return {
      backgroundColor: color + "1a",
      color: color,
    };
  }

  async handleProjectCreate(values) {
    const { onAddNewProject, onGetAllProjects } = this.props;

    try {
      let image = "";
      if (this.state.file) {
        image = await getBase64(this.state.file);
      }
      const project = {
        idProject: "00000000-0000-0000-0000-000000000000",
        actualEndDate: values.actualEndDate,
        actualStartDate: values.actualStartDate,
        department: values.department?.value
          ? {
              entityName: "",
              id: values.department?.value,
              label: "",
            }
          : null,
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        estimatedEndDate: values.estimatedEndDate,
        estimatedStartDate: values.estimatedStartDate,
        image: image,
        lstTeamMember: [],
        objective: values.keyObjectives,
        organization: {
          entityName: "",
          id: values.organization.value,
          label: "",
        },
        owner: {
          image: "",
          email: "",
          id: values.owner?.value,
          name: "",
        },
        priority: {
          label: "",
          value: +values.priority.value,
        },
        projectNumber: "",
        risk: {
          label: "",
          value: +values.risk.value,
        },
        stage: {
          label: "",
          value: +values.stage.value,
        },
        // status: null,
        status: {
          Label: "In Progress",
          Value: 1,
        },
        titleAr: values.projectNameAr,
        titleEn: values.projectNameEn,
        overallBudget: values.overallBudget,
      };

      onAddNewProject(project, id => {
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Project")),
          TOAST_OPTIONS
        );
        onGetAllProjects({
          statusCode: this.props.projectStatusCode,
          pageNumber: 1,
          pageCount: 10,
          searchString: "",
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 933 });
    this.setState({ tabletStyle2: window.innerWidth <= 708 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 600 });
    this.setState({ mobileStyle: window.innerWidth <= 475 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  getShortNAme = project => {
    let shortName = "";
    if (project && project.titleEn) {
      if (project?.titleEn?.split(" ")[0]?.charAt(0)) {
        shortName = project?.titleEn?.split(" ")[0]?.charAt(0);
      } else if ("" + project?.titleEn?.split(" ")[1]) {
        shortName = project?.titleEn?.split(" ")[1]?.charAt(0);
      } else {
        shortName = "";
      }
    }
    return shortName;
  };

  getProjectName = project => {
    let projectDisplayName = project?.titleEn;
    if (project && project.titleEn) {
      const isArabic = localStorage.getItem("I18N_LANGUAGE") === "ar";
      if (isArabic && project?.titleAr) {
        projectDisplayName =
          project.titleAr.length > 10
            ? project.titleAr?.substring(0, 10) + "..."
            : project.titleAr;
      } else if (!isArabic && project?.titleEn) {
        projectDisplayName =
          project.titleEn.length > 10
            ? project.titleEn?.substring(0, 10) + "..."
            : project.titleEn;
      }
    }
    return projectDisplayName;
  };

  render() {
    const { allProjects, loading, listStatus, projectStatusCode } = this.props;
    if (loading) {
      return <Loading />;
    }
    const { createEntities } = this.state;
    const projectsColumns = [
      {
        text: "id",
        dataField: "id",
        hidden: true,
      },
      {
        dataField:
          localStorage.getItem("I18N_LANGUAGE") === "ar"
            ? "titleAr"
            : "titleEn",
        text: this.props.t("PROJECT NAME"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, project) => (
          <div className="d-flex justify-content-start align-items-center">
            <div className="avatar-sm">
              <span
                className="avatar-title rounded"
                style={this.generateColors()}
              >
                {this.getShortNAme(project)}
              </span>
            </div>
            <Tippy
              content={
                localStorage.getItem("I18N_LANGUAGE") === "ar"
                  ? project.titleAr
                  : project.titleEn
              }
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <p className="mt-2 text-wrap">
                <Link
                  to={"/project-details?id=" + project?.id}
                  query={project}
                  className="text-dark"
                >
                  {this.getProjectName(project)}
                </Link>
              </p>
            </Tippy>
          </div>
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        text: this.props.t("Owner"),
        dataField: "owner.name",
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, project) => (
          <div className="d-flex justify-content-start align-items-center">
            {project?.owner?.image ? (
              <div className="avatar-sm d-flex justify-content-start align-items-center">
                <span
                  className="avatar-title rounded-circle"
                  style={this.generateColors()}
                >
                  <img
                    src={`data:image/png;base64,${project?.owner?.image}`}
                    className="rounded-circle"
                    height="34"
                  ></img>
                </span>
              </div>
            ) : (
              <div className="avatar-sm d-flex justify-content-start align-items-center">
                <span
                  className="avatar-title rounded-circle"
                  style={this.generateColors()}
                >
                  {project?.owner?.name.charAt(0)}
                </span>
              </div>
            )}
            <Tippy
              content={this.props.t("View More")}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/individual-details?id=" + project?.owner?.id}
                  query={project}
                  className="text-dark"
                >
                  {project?.owner?.name}
                </Link>
              </span>
            </Tippy>
          </div>
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.tabletStyle2,
        dataField: "stage.label",
        text: this.props.t("Stage"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
        formatter: (cellContent, project) => (
          <>
            {CURRENT_LANG === "en"
              ? project?.stage?.label
              : project?.stage?.labelAr}
          </>
        ),
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "createdDate",
        text: this.props.t("Created Date"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",

        formatter: (cellContent, project) =>
          moment(project?.createdDate).utc().format("DD/MM/YYYY"),
      },
      {
        hidden: this.state.mobileStyle,
        dataField: "progress",
        text: this.props.t("Progress").toUpperCase(),
        sort: false,
        formatter: (cellContent, project) => (
          <ProgressBar data={project?.progress} />
        ),
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        dataField: "status",
        text: this.props.t("Status"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, project) => (
          <StatusText
            text={project.status ? project.status.label?.toUpperCase() : ""}
            projectsTbl
          />
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "dueDate",
        text: this.props.t("Deadline"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
        formatter: (cellContent, project) => (
          <p className="mb-0">
            {moment(project?.dueDate).format("DD/MM/YYYY")}
          </p>
        ),
      },
    ];

    const selectedProjecttStatusValue = listStatus.find(
      status => status.value === projectStatusCode
    );
    const formattedRequestStatusValue = selectedProjecttStatusValue && {
      ...selectedProjecttStatusValue,
      label: `${this.props.t("Status")}: ${selectedProjecttStatusValue.label}`,
      labelAr: `${this.props.t("Status")}: ${
        selectedProjecttStatusValue.labelAr
      }`,
    };

    return (
      <React.Fragment>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CustomTableWithServerSide
                  columns={projectsColumns}
                  data={allProjects}
                  showSearchAndAdd
                  addBtnText={this.props.t("Add Project")}
                  placeholderText={this.props.t("projectSearchPlaceHolder")}
                  handleAddClick={this.toggle}
                  hideAddBtn={!createEntities.includes(ENTITIES.PROJECT)}
                  defaultSortedField="createdDate"
                  dropdownFilterOptions={listStatus}
                  dropdownFilterValue={formattedRequestStatusValue}
                  handleDropdownFilterChange={val =>
                    this.props.updateProjectStatus(val)
                  }
                  onPageChange={this.props.handlePageChange}
                  currentPage={this.props.currentPageIndex}
                  totalDataSize={this.props.totalDataSize}
                  serverSearch={true}
                  onSearchBtnClick={searchString => {
                    this.props.onSearchSubmit(searchString);
                  }}
                  searchVal={this.state.searchString}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ProjectModal
          modal={this.state.modal}
          isEdit={false}
          toggle={this.toggle}
          handleSubmit={this.handleProjectCreate}
          file={this.state.file}
          onFilesAccepted={file => {
            this.setState({ file: file[0] });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.PROJECT]}
        />
      </React.Fragment>
    );
  }
}

allProjectsTable.propTypes = {
  projectDetail: PropTypes.any,
  projects: PropTypes.array,
  allProjects: PropTypes.array,
  onGetAllProjects: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.any,
  onAddNewProject: PropTypes.func,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  search: PropTypes.string,
  tabletStyle: PropTypes.any,
  tabletStyle2: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  listStatus: PropTypes.array,
  projectStatusCode: PropTypes.any,
  totalDataSize: PropTypes.any,
  searchString: PropTypes.string,
  onSearchSubmit: PropTypes.func,
  currentPageIndex: PropTypes.any,
  handlePageChange: PropTypes.func,
  updateProjectStatus: PropTypes.func,
};

const mapStateToProps = ({ projects, Layout }) => ({
  allProjects: projects.allProjects,
  loading: projects.loading,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  projectStatusCode: projects.projectStatusCode,
});

const mapDispatchToProps = dispatch => ({
  onGetAllProjects: payload => dispatch(getAllProjects(payload)),
  onAddNewProject: (payload, callback) =>
    dispatch(upsertProject(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(allProjectsTable)));
