import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "./widget.scss";
import { withTranslation } from "react-i18next";
import { Colors } from "common";
class Widget extends Component {
  constructor(props) {
    super(props);

    let seriesData= [props.legendValue1, props.legendValue2];
    if(props.legendText3) seriesData.push(props.legendValue3)
    if(props.legendText4) seriesData.push(props.legendValue4)
    if(props.legendText5) seriesData.push(props.legendValue5)

    let labelsData=[props.legendText1, props.legendText2]
    if(props.legendText3) labelsData.push(props.legendText3)
    if(props.legendText4) labelsData.push(props.legendText4)
    if(props.legendText5) labelsData.push(props.legendText5)


    let colorsData=[props.legendColor1, props.legendColor2]
    if(props.legendText3) colorsData.push(props.legendColor3)
    if(props.legendText4) colorsData.push(props.legendColor4)
    if(props.legendText5) colorsData.push(props.legendColor5)



    let series = seriesData;
    let data=series
    let options = {
      chart: {
        events: {
          dataPointSelection: function(event, chartContext, config) {
          if(props.hasPointSelection){
            let seriersIndex = config.dataPointIndex || 0
            props.onDataPointSelection(seriersIndex)
          }
        } 
      }
    },
      title: {
        text: props.title,
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: -6,
        style: {
   
          fontWeight: 500,
          color: "#4c4d4cbf",
        },
        className: "text-muted",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                offsetY: 6,
              },
              total: {
                show: true,
              },
            },
          },
        },
      },
      labels: labelsData,
      colors: colorsData,
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        showForNullSeries: true,
        showForZeroSeries: true,
        fontSize:'10px'
      },
      dataLabels: {
        enabled: props.dataLabelsEnabled,
        formatter: function (val, opts) {
          return data[opts.seriesIndex]
        },
        style: {
            fontSize: '11px',
            fontFamily: 'Arial',
            fontWeight: 'lighter',
            colors: ["#fff"],
            
        },
        dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 0,
            color: '#000',
            opacity: 0.45
        }
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 225,
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    };
    let height = props.bigHight?"239":"230";
    if (!!!series[0] && !!!series[1] && !!!series[2]) {
      // Handle configurations regarding empty chart
      series = !!props.legendText3 ? [1, 1, 1] : [1, 1];
      // options.labels = [];
      options.colors = !!props.legendText3
        ? [Colors.lightGrey, Colors.lightGrey, Colors.lightGrey]
        : [Colors.lightGrey, Colors.lightGrey];
      // options.legend.show = false;
      options.responsive[0].options.legend.show = false;
      options.plotOptions.pie.donut.labels.total.formatter = () => {
        return 0;
      };
      options.plotOptions.pie.donut.labels.value.formatter = () => {
        return 0;
      };
      options["tooltip"] = {
        enabled: false,
      };
      options["fill"] = {
        colors: [Colors.white],
      };
      options["grid"] = {
        show: true,
      };
      options["stroke"] = {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      };
      // height = "160";
    }
    this.state = {
      series,
      options,
      height,
      title: props.title,
      value: props.value,
      legendText1: props.legendText1,
      legendText2: props.legendText2,
      legendText3: props.legendText3,
      legendText4: props.legendText4,
      legendText5: props.legendText4,
      legendColor1: props.legendColor1,
      legendColor2: props.legendColor2,
      legendColor3: props.legendColor3,
      legendColor4: props.legendColor4,
      legendColor5: props.legendColor5,
      legendValue1: props.legendValue1,
      legendValue2: props.legendValue2,
      legendValue3: props.legendValue3,
      legendValue4: props.legendValue4,
      legendValue5: props.legendValue5,
    };
  }

  render() {
    return (
      <Card
        className="card-hover piechart-card"
        style={{ marginBottom: 5, height: 230, position: "relative" }}
        onClick={this.props.onClick}
      >
        <CardBody>
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="donut"
            height={this.state.height}
            className="apex-charts"
          />
        </CardBody>
      </Card>
    );
  }
}

Widget.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  legendText1: PropTypes.string,
  legendText2: PropTypes.string,
  legendText3: PropTypes.string,
  legendText4: PropTypes.string,
  legendText5: PropTypes.string,
  legendColor1: PropTypes.string,
  legendColor2: PropTypes.string,
  legendColor3: PropTypes.string,
  legendColor4: PropTypes.string,
  legendColor5: PropTypes.string,
  legendValue1: PropTypes.number,
  legendValue2: PropTypes.number,
  legendValue3: PropTypes.number,
  legendValue4: PropTypes.number,
  legendValue5: PropTypes.number,
  onClick: PropTypes.func,
  t: PropTypes.any,
  dataLabelsEnabled: PropTypes.bool,
  bigHight:PropTypes.bool,
  hasPointSelection:PropTypes.bool,
  onDataPointSelection: PropTypes.func,
};

export default withTranslation()(Widget);
