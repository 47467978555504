import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { Colors, CURRENT_LANG } from "common";
import classnames from "classnames";
import moment from "moment";
import StatusText from "components/Common/StatusText";
import { Col, Row } from "reactstrap";

import projectIcon from "../../assets/icons/projects-home.png";
class ApprovalCard extends Component {
  render() {
    const { item, selectedApproval } = this.props;
    return (
      <Row
        onClick={() => {
          this.props.onClick(item.idApproval);
        }}
        className={classnames({
          "active-approval": selectedApproval?.idApproval === item.idApproval,
        })}
        key={item.idApproval}
        style={{
          minHeight: 148,
          cursor: "pointer",
          borderBottom: `4px solid ${Colors.searchBG}`,
          marginInlineEnd: 0,
          backgroundColor: Colors.white,
          paddingBottom: 10,
        }}
      >
        <Col lg={12} className="mx-2 mt-3">
          <div className="day-row">
            {!!item.requester?.image ? (
              <img
                src={`data:image/png;base64,${item.requester?.image}`}
                alt=""
                className="rounded-circle"
                height="35"
              />
            ) : (
              <div className="avatar-xs">
                <span
                  className="avatar-title rounded-circle"
                  style={{ padding: 15 }}
                >
                  <p className="mb-0 h5 text-white">
                    {item.requester?.name?.trim()?.charAt(0)}
                  </p>
                </span>
              </div>
            )}

            <p className="cardName mx-2 my-2">{item.requester?.name?.trim()}</p>
            <p className="text-muted font-size-12 ms-auto my-2 mx-2">
              {item.createdDate && moment(item.createdDate).format("hh:mma")}
            </p>
          </div>

          <p className="mainTitle mx-5">{item?.subject}</p>
          <div className="day-row text-muted mx-5 day-justify-between day-w-80">
            <div className="day-row">
              <i className="bx bx-time font-size-16 day-light-grey"></i>
              <p className="mb-0 mx-2 text-wrap font-size-12">
                {`${this.props.t("Due")} ${moment(item.duedate).fromNow()}`}
              </p>
            </div>
            <div className="day-row">
              <img src={projectIcon} />
              <p className="mb-0 mx-2 text-wrap font-size-12">
                {CURRENT_LANG === "ar"
                  ? item.regarding.labelAr
                  : item.regarding.label}
              </p>
            </div>
          </div>
          <div className="mx-5">
            {item.priority?.label?.toLowerCase()?.includes("high") && (
              <StatusText
                text={"HIGH PRIORITY"}
                style={{
                  marginTop: 10,
                  paddingTop: 2,
                  paddingBottom: 2,
                  width: 100,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

ApprovalCard.propTypes = {
  selectedApproval: PropTypes.any,
  item: PropTypes.any,
  t: PropTypes.any,
  onClick: PropTypes.func,
};

export default withTranslation()(ApprovalCard);
