import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col, Card, CardBody } from "reactstrap";
import CustomTableWithServerSide from "components/Common/CustomTableWithServerSide";
import Loading from "pages/Loading";
import { Colors, getGlobalHeaderStyle, CURRENT_LANG } from "common";
import LegendTextTable from "components/Common/LegendTextTable";
import Knob from "pages/AllCharts/knob/knob";
// Table data
import { 
  getAllProjectsDashboard
} from "store/projects/actions";


class AllProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: "",
      modal: false,
      deleteModal: false,
      page: 1,
      sizePerPage: 4,
      file: null,
      projectsColumns: [
        {
          text: "id",
          dataField: "id",
          hidden: true,
        },
        {
          dataField: "titleEn", // titleAr
          text: props.t("PROJECT NAME"),
          sort: false,
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          formatter: (cellContent, project) => (
            <>
              <LegendTextTable
                text={
                  CURRENT_LANG === "ar"
                    ? project.titleAr || project.titleEn
                    : project.titleEn
                }
                color={"#07DF9A"}
              />
            </>
          ),
        },
        {
          dataField: "estimatedStartDate",
          text: props.t("START AND END DATE"),
          sort: false,
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          formatter: (cellContent, project) => (
            <>
             
              <div className="d-flex flex-column">
                <div className="d-flex ">
                  <p className="team-member-title me-1"> {props.t("Start:")}</p>
                  <p> {project?.estimatedStartDate.slice(0, 10)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="team-member-title me-1"> {props.t("End:")}</p>
                  <p> {project?.estimatedEndDate.slice(0, 10)}</p>
                </div>
              </div>
             
            </>
          ),
        },
        {
          dataField: "progress",
          text: props.t("Progress").toUpperCase(),
          sort: false,
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          formatter: (cellContent, project) => (
            <Knob
              fromTable={true}
              width={106}
              height={106}
              value={project?.progress || 0}
              total={100}
              bgColor={Colors.knobBgColor}
              fgColor={Colors.knobColor}
              knobColor={Colors.knobColor}
              thickness={0.09}
              lineCap="round"
              onChange={() => {}}
            />
          ),
        },
        {
          dataField: "risks",
          text: props.t("RISKS"),
          sort: false,
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          formatter: (cellContent, project) => (
            <>
              <p className="p-3"> {project?.risk?.label}</p>
              
            </>
          ),
        },
      ],
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.onGetAllProjectsDashboard({pageNumber:1});
  }

  handlePageChange(page){
    this.props.onGetAllProjectsDashboard({pageNumber:page});
  }

  render() {
    const { loading, projectsDashboardData , pageNumber, totalRecordCount} = this.props;
    if (loading) {
      return <Loading />;
    }

    const { projectsColumns } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col lg={12} className="col-margin-top-project-level">
            <Card>
              <CardBody>
                  <CustomTableWithServerSide
                          columns={projectsColumns}
                          data={projectsDashboardData}
                          hideAddBtn={true}
                          onPageChange={this.handlePageChange}
                          currentPage={pageNumber}
                          totalDataSize={totalRecordCount}
                        />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

AllProjects.propTypes = {

  loading: PropTypes.bool,
  t: PropTypes.any,
  onGetAllProjectsDashboard:PropTypes.func,
  activeTab: PropTypes.any,
  onGetAllTasksOverview: PropTypes.func,
  data:PropTypes.array,
  pageNumber:PropTypes.any,
  totalRecordCount: PropTypes.any,
  projectsDashboardData: PropTypes.any,

};

const mapStateToProps = ({ projects }) => ({
  loading: projects.loading,
  error: projects.error,
  projectsDashboardData:projects.dashboardData,
  pageNumber:projects.dashboardPageNumber,
  totalRecordCount:projects.dashboardTtotalRecordCount,
  projectsDashboardData:projects.dashboardData

});

const mapDispatchToProps = dispatch => ({
  onGetAllProjectsDashboard : payload => dispatch(getAllProjectsDashboard(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AllProjects)));
