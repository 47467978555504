import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  DELETE_STREAM,
  DELETE_STREAM_SUCCESS,
  DELETE_STREAM_FAIL,
  GET_PROJECTS_OVERVIEW,
  GET_PROJECTS_OVERVIEW_FAIL,
  GET_PROJECTS_OVERVIEW_SUCCESS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAIL,
  GET_ALL_ACTIVE_PROJECTS_SUCCESS,
  GET_ALL_ACTIVE_PROJECTS_FAIL,
  GET_ALL_PROJECTS,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAIL,
  UPSERT_PROJECT,
  UPSERT_PROJECT_FAIL,
  UPSERT_PROJECT_SUCCESS,
  UPSERT_STREAM,
  UPSERT_STREAM_FAIL,
  UPSERT_STREAM_SUCCESS,
  UPSERT_MEMBER,
  UPSERT_MEMBER_FAIL,
  UPSERT_MEMBER_SUCCESS,
  GET_PROJECT_FILES,
  GET_PROJECT_FILES_FAIL,
  GET_PROJECT_FILES_SUCCESS,
  GET_ALLPROJECTSOVERVIEW,
  GET_ALLPROJECTSOVERVIEW_SUCCESS,
  GET_ALLPROJECTSOVERVIEW_FAIL,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  UPDATE_PROJECT_STATUS_CODE,
  GET_PROJECT_DASHBOARD,
  GET_PROJECT_DASHBOARD_SUCCESS,
  GET_PROJECT_DASHBOARD_FAIL,
  GET_ALL_PROJECT_DASHBOARD,
  GET_ALL_PROJECT_DASHBOARD_SUCCESS,
  GET_ALL_PROJECT_DASHBOARD_FAIL,
  GET_PROJECTS_OPTIONS,
  GET_PROJECTS_OPTIONS_SUCCESS,
  GET_PROJECTS_OPTIONS_FAIL
} from "./actionTypes";

const INIT_STATE = {
  projectStatusCode:'',
  projects: [],

  projectDetail: {},
  error: {},
  projectsOverview: null,
  allProjects: [],
  projectData: null,
  files: [],
  allProjectsOverview: null,
  projectOptions:[],
  loadingOptions:false
};

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        error: "",
        loading: false,
      };

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
        error: "",
        loading: false,
      };

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
        error: "",
        loading: false,
      };

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project =>
          project.id.toString() === action.payload.id.toString()
            ? { project, ...action.payload }
            : project
        ),
      };

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PROJECT:
      return {
        ...state,
        loading: true,
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(
          project => project.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_STREAM:
      return {
        ...state,
        loading: true,
      };

    case DELETE_STREAM_SUCCESS:
      return {
        ...state,
      };

    case DELETE_STREAM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_MEMBER:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PROJECTS_OVERVIEW:
      return {
        ...state,
        loading: true,
      };

    case GET_PROJECTS_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PROJECTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        projectsOverview: action.payload,
        loading: false,
        error: "",
      };

    case GET_ALL_PROJECTS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ALL_PROJECTS_SUCCESS:
      let _allProjects = action.payload.projects.map(_project => {
        return {
          ..._project,
          id: _project.idProject,
          dueDate: _project.actualEndDate
            ? _project.actualEndDate
            : _project.estimatedEndDate,
        };
      });
      const returnObj= {
        ...state,
        allProjects: _allProjects,
        loading: false,
        error: "",
        searchString:action.payload.searchString,
        totalRecordCount:action.payload.totalRecordCount,
        projectStatusCode:action.payload.projectStatus,
        projectsPageNumber:action.payload.pageNumber,
      };
      return returnObj;

      case GET_ALL_ACTIVE_PROJECTS_SUCCESS:
        let _allActiveProjects = action.payload.projects.map(_project => {
          return {
            ..._project,
            id: _project.idProject,
            dueDate: _project.actualEndDate
              ? _project.actualEndDate
              : _project.estimatedEndDate,
          };
        });
        return {
          ...state,
          allActiveProjects: _allActiveProjects,
          activePageNumber:action.payload.pageNumber,
          activeTotalRecordCount:action.payload.totalRecordCount,
          loading: false,
          error: "",
        };
      case GET_ALL_ACTIVE_PROJECTS_FAIL:
          return {
            ...state,
            error: action.payload,
          };

    case GET_PROJECT_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_PROJECT_DETAILS_SUCCESS:
      let _projectDetails = action.payload.projectDetails;
      let projectDetails = {
        ..._projectDetails,
        projectDetails: {
          ..._projectDetails.projectDetails,
          id: _projectDetails.projectDetails.idProject,
          dueDate: _projectDetails.projectDetails.actualEndDate
            ? _projectDetails.projectDetails.actualEndDate
            : _projectDetails.projectDetails.estimatedEndDate,
        },
      };

      return {
        ...state,
        projectData: projectDetails,
        loading: false,
        error: "",
      };

    case GET_PROJECT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_PROJECT:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_STREAM:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_STREAM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_STREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_MEMBER:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case GET_PROJECT_FILES:
      return {
        ...state,
        loading: true,
      };

    case GET_PROJECT_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload,
        error: "",
      };

    case GET_ALLPROJECTSOVERVIEW:
      return {
        ...state,
        loading: true,
      };

    case GET_ALLPROJECTSOVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ALLPROJECTSOVERVIEW_SUCCESS:
      return {
        ...state,
        allProjectsOverview: action.payload,
        loading: false,
        error: "",
      };
    

    case UPDATE_PROJECT_STATUS_CODE:
      return {
        ...state,
        projectStatusCode: action.payload,
      };

      case GET_PROJECT_DASHBOARD_SUCCESS:
      case GET_ALL_PROJECT_DASHBOARD_SUCCESS: // project-level page
        return {
          ...state,
          dashboardData: action.payload.dashboardData,
          loading: false,
          error: "",
          dashboardPageNumber:action.payload.dashboardPageNumber,
          dashboardTtotalRecordCount:action.payload.totalRecordCount,

       
        };
        case GET_PROJECT_DASHBOARD_FAIL:
        case GET_ALL_PROJECT_DASHBOARD_FAIL:  // project-level page
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
        case GET_PROJECT_DASHBOARD:
        case GET_ALL_PROJECT_DASHBOARD:  // project-level page
        return {
          ...state,
          loading: true,
        };



        case GET_PROJECTS_OPTIONS:
          return {
            ...state,
            loadingOptions: true,
          };
    
        case GET_PROJECTS_OPTIONS_SUCCESS:
          const pageNum = action.payload.listPageNumber
          return {
            ...state,
            projectOptions: pageNum == 1 ? action.payload.data : state.projectOptions.concat(action.payload.data) ,
            loadingOptions: false,
            error: "",
            listTotalRecordCount:action.payload?.totalRecordCount,
            listPageNumber:action.payload.listPageNumber
          };
    
        case GET_PROJECTS_OPTIONS_FAIL:
          return {
            ...state,
            error: action.payload,
            loadingOptions: false,
          };
    


    default:
      return state;
  }
};

export default projects;
