import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import MemberItem from "components/Common/MemberItem";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ErrorMessage, Field, Formik } from "formik";
import FormUpload from "pages/Forms/FormUpload";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import CustomSelect from "components/Common/CustomSelect";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getCheck } from "store/actions";
import { ENTITIES } from "common";
import {
  formatLineBreaksInMarkdownFriendlyFormat,
  getLookupValue,
} from "common/utils";
import { CURRENT_LANG } from "common";
import moment from "moment";
// import FormikFieldDateTimePicker from "components/FormikFieldDateTimePicker";

class CreateAppointmentModal extends Component {
  constructor(props) {
    super(props);
    const _validations = {
      subject: Yup.string().required(this.props.t("errorSubject")),
      location: Yup.string().required(this.props.t("errorLocation")),
      regarding: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
          entityName: Yup.string().required(),
        })
        .required(this.props.t("errorRegarding")),
      attendees: Yup.array()
        .min(1, this.props.t("errorMinAttendees"))
        .required(this.props.t("errorAttendees")),
      startAt: Yup.date().required(this.props.t("errorStartDate")),
      endAt: Yup.date()
        .when(
          "startAt",
          (startAt, schema) =>
            startAt && schema.min(startAt, this.props.t("errorBeforeEndDate"))
        )
        .required(this.props.t("errorEndDate")),
    };

    let initialAttendees = [];
    let requiredAttendees = [];
    let optionalAttendees = [];

    if (props.editAppointment?.endAt && props.editAppointment?.startAt) {
      initialAttendees = [...props.editAppointment.requiredAttendees]
        .concat(props.editAppointment.optionalAttendees)
        .map(item => ({ label: item.label, value: item.id, ...item }));

      requiredAttendees = props.editAppointment.requiredAttendees.map(item => ({
        label: item.label,
        value: item.id,
        ...item,
      }));

      optionalAttendees = props.editAppointment.optionalAttendees.map(item => ({
        label: item.label,
        value: item.id,
        ...item,
      }));

      initialAttendees.forEach(item => {
        let checkPayload = {
          startAt: props.editAppointment?.startAt,
          endAt: props.editAppointment?.endAt,
          recipient: {
            entityName: ENTITIES.INDIVIDUALS,
            id: item.value,
            label: "",
            labelAr: "",
          },
        };
        props.onGetCheckData(checkPayload);
      });
    }
    this.state = {
      validations: _validations,
      requiredAttendees,
      optionalAttendees,
      requiredAttendeesOptions: [],
      optionalAttendeesOptions: [],
      duration: "30",
      startAt: null,
      endAt: null,
      options: this.durationOptions,
      durationOptions: [
        { label: "12:00am", value: "00:00" },
        { label: "12:30am", value: "00:30" },
        { label: "01:00am", value: "01:00" },
        { label: "01:30am", value: "01:30" },
        { label: "02:00am", value: "02:00" },
        { label: "02:30am", value: "02:30" },
        { label: "03:00am", value: "03:00" },
        { label: "03:30am", value: "03:30" },
        { label: "04:00am", value: "04:00" },
        { label: "04:30am", value: "04:30" },
        { label: "05:00am", value: "05:00" },
        { label: "05:30am", value: "05:30" },
        { label: "06:00am", value: "06:00" },
        { label: "06:30am", value: "06:30" },
        { label: "07:00am", value: "07:00" },
        { label: "07:30am", value: "07:30" },
        { label: "08:00am", value: "08:00" },
        { label: "08:30am", value: "08:30" },
        { label: "09:00am", value: "09:00" },
        { label: "09:30am", value: "09:30" },
        { label: "10:00am", value: "10:00" },
        { label: "10:30am", value: "10:30" },
        { label: "11:00am", value: "11:00" },
        { label: "11:30am", value: "11:30" },
        { label: "12:00pm", value: "12:00" },
        { label: "12:30pm", value: "12:30" },
        { label: "01:00pm", value: "13:00" },
        { label: "01:30pm", value: "13:30" },
        { label: "02:00pm", value: "14:00" },
        { label: "02:30pm", value: "14:30" },
        { label: "03:00pm", value: "15:00" },
        { label: "03:30pm", value: "15:30" },
        { label: "04:00pm", value: "16:00" },
        { label: "04:30pm", value: "16:30" },
        { label: "05:00pm", value: "17:00" },
        { label: "05:30pm", value: "17:30" },
        { label: "06:00pm", value: "18:00" },
        { label: "06:30pm", value: "18:30" },
        { label: "07:00pm", value: "19:00" },
        { label: "07:30pm", value: "19:30" },
        { label: "08:00pm", value: "20:00" },
        { label: "08:30pm", value: "20:30" },
        { label: "09:00pm", value: "21:00" },
        { label: "09:30pm", value: "21:30" },
        { label: "10:00pm", value: "22:00" },
        { label: "10:30pm", value: "22:30" },
        { label: "11:00pm", value: "23:00" },
        { label: "11:30pm", value: "23:30" },
      ],
    };
    this.isValid = this.isValid.bind(this);
    this.handleCheckAttendee = this.handleCheckAttendee.bind(this);
    this.handleListAttendeeLookupOptions =
      this.handleListAttendeeLookupOptions.bind(this);
      moment.locale("en");
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }

    for (let key in this.state.validations) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  handleListAttendeeLookupOptions(lookups, requiredValues, optionalValues) {
    let mappedOptionalValues = optionalValues.map(item => item.value);
    let mappedRequiredValues = requiredValues.map(item => item.value);
    let requiredAttendeesOptions = lookups.filter(
      option => !mappedOptionalValues.includes(option.value)
    );
    let optionalAttendeesOptions = lookups.filter(
      option => !mappedRequiredValues.includes(option.value)
    );
    this.setState({
      requiredAttendeesOptions,
      optionalAttendeesOptions,
    });
  }

  handleCheckAttendee(payload) {
    const { onGetCheckData } = this.props;
    let startAt = new Date(payload.start).toISOString();
    let endAt = new Date(payload.end).toISOString();

    let checkPayload = {
      startAt,
      endAt,
      recipient: {
        entityName: ENTITIES.INDIVIDUALS,
        id: payload.attendValue[payload.attendValue.length - 1]?.value,
        label: "",
        labelAr: "",
      },
    };

    if (!!checkPayload.recipient.id) {
      onGetCheckData(checkPayload);
    }
  }

  componentDidMount() {
    if (
      !!this.props.entityLookups &&
      Array.isArray(this.props.entityLookups?.listAppointmentAttendees)
    ) {
      this.handleListAttendeeLookupOptions(
        this.props.entityLookups.listAppointmentAttendees,
        this.state.requiredAttendees,
        this.state.optionalAttendees
      );
    }
  }

  durations = [
    "30 mins",
    "1 hr",
    "1.5 hrs",
    "2 hrs",
    "2.5 hrs",
    "3 hrs",
    "3.5 hrs",
    "4 hrs",
    "4.5 hrs",
    "5 hrs",
    "5.5 hrs",
    "6 hrs",
    "6.5 hrs",
    "7 hrs",
    "7.5 hrs",
    "8 hrs",
    "8.5 hrs",
    "9 hrs",
    "9.5 hrs",
    "10 hrs",
    "10.5 hrs",
    "11 hrs",
    "11.5 hrs",
    "12 hrs",
    "12.5 hrs",
    "13 hrs",
    "13.5 hrs",
    "14 hrs",
    "14.5 hrs",
    "15 hrs",
    "15.5 hrs",
    "16 hrs",
    "16.5 hrs",
    "17 hrs",
    "17.5 hrs",
    "18 hrs",
    "18.5 hrs",
    "19 hrs",
    "19.5 hrs",
    "20 hrs",
    "20.5 hrs",
    "21 hrs",
    "21.5 hrs",
    "22 hrs",
    "22.5 hrs",
    "23 hrs",
    "23.5 hrs",
    "24 hrs",
  ];

  render() {
    const {
      editAppointment,
      isEdit,
      files,
      onFilesAccepted,
      availableAttendees,
      entityLookups,
    } = this.props;

    if (!entityLookups) {
      return <></>;
    }
    let styleForButton = CURRENT_LANG == "ar" ? "text-start" : "text-end";

    let regardingOptions = [];

    if (entityLookups?.listRegarding?.length) {
      regardingOptions = [
        { ...entityLookups?.listRegarding[0] },
        { ...entityLookups?.listRegarding[1] },
      ];
    }

    const groupInternalAndExternalUsers = users => {
      return users?.length >= 0
        ? users?.reduce(
            (accumulator, attendee, _, arr) => {
              if (attendee?.attendeeType === "External")
                accumulator?.at(1).options.push(attendee);
              else accumulator?.at(0).options.push(attendee);

              return accumulator;
            },
            [
              {
                label: this.props.t("Internal"),
                options: [],
              },
              {
                label: this.props.t("External"),
                options: [],
              },
            ]
          )
        : users;
    };

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
        onClosed={this.props.onClosed}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("Update Appointment")
            : this.props.t("Create New Appointment")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              subject: (editAppointment && editAppointment.subject) || "",
              location: (editAppointment && editAppointment.location) || "",
              description:
                (editAppointment &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    editAppointment.description
                  )) ||
                "",
              checkEvent:
                (editAppointment && editAppointment.isAllDayEvent) || false,
              regarding:
                (editAppointment &&
                  getLookupValue(
                    editAppointment.regarding?.id,
                    regardingOptions
                  )) ||
                "",
              idOrganization:
                (editAppointment &&
                  getLookupValue(
                    editAppointment.idOrganization,
                    entityLookups.listOrganizations
                  )) ||
                "",
              attendees:
                (editAppointment &&
                  getLookupValue(
                    this.state.requiredAttendees,
                    entityLookups.listAppointmentAttendees,
                    true
                  )) ||
                [],
              optionalAttendees:
                (editAppointment &&
                  getLookupValue(
                    this.state.optionalAttendees,
                    entityLookups.listAppointmentAttendees,
                    true
                  )) ||
                [],
              endAt:
                (editAppointment?.endAt &&
                  editAppointment?.endAt?.substring(
                    0,
                    editAppointment?.endAt?.lastIndexOf(":")
                  )) ||
                "",
              startAt:
                (editAppointment?.startAt &&
                  editAppointment?.startAt?.substring(
                    0,
                    editAppointment?.startAt?.lastIndexOf(":")
                  )) ||
                "",
              startAtTime:
                (editAppointment?.startAt &&
                  this.state.durationOptions[
                    this.state.durationOptions.findIndex(
                      item =>
                        item.value ==
                        editAppointment?.startAt?.split("T")[1]?.slice(0, 5)
                    )
                  ]) ||
                this.state.durationOptions[0],
              endAtTime:
                (editAppointment?.endAt &&
                  this.state.durationOptions[
                    this.state.durationOptions.findIndex(
                      item =>
                        item.value ==
                        editAppointment?.endAt?.split("T")[1]?.slice(0, 5)
                    )
                  ]) ||
                this.state.durationOptions[1],
            }}
            validationSchema={Yup.object().shape(this.state.validations)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Form>
                  <Row>
                    <Col className="col-12">
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label day-required">
                              {this.props.t("SubjectForm")}
                            </Label>
                            <Field
                              name="subject"
                              type="text"
                              value={values.subject}
                              className={
                                "form-control" +
                                (errors.subject && touched.subject
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="subject"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label day-required">
                              {this.props.t("LocationForm")}
                            </Label>
                            <Field
                              name="location"
                              type="text"
                              value={values.location}
                              className={
                                "form-control" +
                                (errors.location && touched.location
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label className="form-label">
                              {this.props.t("DescriptionForm")}
                            </Label>
                            <Field
                              name="description"
                              type="text"
                              value={values.description}
                              className={
                                "form-control" +
                                (errors.description && touched.description
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label day-required">
                              {this.props.t("Organizations")}
                            </Label>
                            <CustomSelect
                              className="input"
                              onChange={value => {
                                setFieldValue("idOrganization", value);
                              }}
                              value={values.idOrganization}
                              options={entityLookups.listOrganizations}
                            />
                            <ErrorMessage
                              name="idOrganization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div
                            className="form-check form-check-primary"
                            style={{ marginTop: 35 }}
                          >
                            <Label className="form-label" htmlFor="checkEvent">
                              {this.props.t("IS ALL DAY EVENT")}
                            </Label>
                            <Field
                              name="checkEvent"
                              id="checkEvent"
                              type="checkbox"
                              as="input"
                              className={"form-check-input"}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <Row>
                          <Col sm={6}>
                            <Label
                              className="form-label day-required"
                              htmlFor="startAt"
                            >
                              {this.props.t("start At")}
                            </Label>

                            <Field
                              required
                              name="startAt"
                              id="startAt"
                              type="date"
                              as="input"
                              icons={{
                                close: "OK",
                              }}
                              value={
                                moment(values.startAt).format("YYYY-MM-DD") ||
                                null
                              }
                              onChange={e => {
                                let val = e.target.value;
                                setFieldValue(
                                  "startAt",
                                  `${val}T${this.state.durationOptions[0].value}`
                                );
                                const defaultEndAt = moment(val).add(
                                  parseInt(this.state.duration),
                                  "m"
                                );

                                setFieldValue(
                                  "endAt",
                                  defaultEndAt.format("YYYY-MM-DDTHH:mm")
                                );

                                this.setState({
                                  startAt: `${val}T${this.state.durationOptions[0].value}`,
                                });
                              }}
                              className={
                                "form-control" +
                                (errors.startAt && touched.startAt
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="startAt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                          <Col sm={6}>
                            {this.state.startAt || editAppointment?.startAt ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    gap: "1rem",
                                    marginTop: "1.7rem",
                                  }}
                                >
                                  <CustomSelect
                                    name="startAtTime"
                                    id="startAtTime"
                                    className="input"
                                    onChange={value => {
                                      const startTime = moment(values.startAt)
                                        .hours(value.value.split(":")[0])
                                        .minutes(value.value.split(":")[1])
                                        .format("YYYY-MM-DDTHH:mm");

                                      this.setState({
                                        startAt: startTime,
                                      });

                                      setFieldValue("startAt", startTime);

                                      const nextIndex =
                                        this.state.durationOptions.indexOf(
                                          value
                                        );

                                      const nextTime =
                                        this.state.durationOptions[
                                          nextIndex + 1
                                        ];

                                      setFieldValue(
                                        "endAt",
                                        moment(values.startAt)
                                          .hours(nextTime.value.split(":")[0])
                                          .minutes(nextTime.value.split(":")[1])
                                          .format("YYYY-MM-DDTHH:mm")
                                      );

                                      const secondHalfOfsplicedOptions =
                                        this.state.durationOptions.slice(
                                          this.state.durationOptions.indexOf(
                                            value
                                          ) + 1,
                                          this.state.durationOptions.length
                                        );
                                      const invertedOptions = [
                                        ...secondHalfOfsplicedOptions,
                                      ];

                                      this.setState({
                                        options: invertedOptions,
                                      });
                                    }}
                                    options={this.state.durationOptions}
                                    value={
                                      this.state.durationOptions[
                                        this.state.durationOptions.findIndex(
                                          item =>
                                            item.value ==
                                            values.startAt
                                              ?.split("T")[1]
                                              ?.slice(0, 5)
                                        )
                                      ]
                                    }
                                  />
                                  {editAppointment?.startAt ||
                                  values?.startAt ? (
                                    <>
                                      <div>-</div>
                                      <CustomSelect
                                        style={{ width: "10rem" }}
                                        name="endAtTime"
                                        id="endAtTime"
                                        className="input"
                                        onChange={value => {
                                          const endTime = moment(values.startAt)
                                            .hours(value.value.split(":")[0])
                                            .minutes(value.value.split(":")[1])
                                            .format("YYYY-MM-DDTHH:mm");

                                          setFieldValue("endAt", endTime);
                                        }}
                                        options={
                                          this.state.options
                                            ? [...this.state.options].map(
                                                (option, index) => {
                                                  return {
                                                    value: option.value,
                                                    label:
                                                      option.label +
                                                      ` (${this.durations[index]})`,
                                                  };
                                                }
                                              )
                                            : [
                                                ...this.state.durationOptions.slice(
                                                  this.state.durationOptions.indexOf(
                                                    values.startAtTime
                                                  ) + 1,
                                                  this.state.durationOptions
                                                    .length
                                                ),
                                              ].map((option, index) => {
                                                return {
                                                  value: option.value,
                                                  label:
                                                    option.label +
                                                    ` (${this.durations[index]})`,
                                                };
                                              })
                                        }
                                        value={
                                          this.state.durationOptions[
                                            this.state.durationOptions.findIndex(
                                              item =>
                                                item.value ==
                                                values.endAt
                                                  ?.split("T")[1]
                                                  ?.slice(0, 5)
                                            )
                                          ]
                                        }
                                      />
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : null}

                            <ErrorMessage
                              name="endAt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-3">
                        <Row>
                          <Col sm={12}>
                            <Label className="form-label day-required">
                              {this.props.t("RegardingForm")}
                            </Label>
                            <CustomSelect
                              className="input"
                              onChange={value => {
                                setFieldValue("regarding", value);
                              }}
                              value={values.regarding}
                              options={regardingOptions}
                            />
                            <ErrorMessage
                              name="regarding"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        </Row>
                      </div>
                      {/* {values.startAt && values.endAt && ( */}
                      <div className="mb-3">
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label day-required text-muted">
                                {this.props.t("AttendeesForm")}
                              </Label>
                              <>
                                <CustomSelect
                                  className="input"
                                  onChange={value => {
                                    setFieldValue("attendees", value);
                                    let payload = {
                                      attendValue: value,
                                      start: values.startAt,
                                      end: values.endAt,
                                    };
                                    this.handleListAttendeeLookupOptions(
                                      entityLookups.listAppointmentAttendees,
                                      value,
                                      values.optionalAttendees
                                    );
                                    this.handleCheckAttendee(payload);
                                  }}
                                  value={values.attendees}
                                  isMulti={true}
                                  controlShouldRenderValue={false}
                                  options={groupInternalAndExternalUsers(
                                    this.state.requiredAttendeesOptions
                                  )}
                                />
                              </>
                              <ErrorMessage
                                name="attendees"
                                component="div"
                                className="invalid-feedback"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                }}
                              >
                                {values.attendees.map((item, index) => {
                                  return (
                                    <MemberItem
                                      key={index}
                                      item={item}
                                      isAttendee
                                      isAvailable={availableAttendees.includes(
                                        item.value
                                      )}
                                      onClick={() => {
                                        let _attendees = JSON.parse(
                                          JSON.stringify([...values.attendees])
                                        );
                                        _attendees = _attendees.filter(
                                          _attende =>
                                            _attende.value !== item.value
                                        );
                                        this.handleListAttendeeLookupOptions(
                                          entityLookups.listAppointmentAttendees,
                                          _attendees,
                                          values.optionalAttendees
                                        );
                                        setFieldValue("attendees", _attendees);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label text-muted">
                                {this.props.t("OptionalAttendeesForm")}
                              </Label>
                              <>
                                <CustomSelect
                                  className="input"
                                  onChange={value => {
                                    setFieldValue("optionalAttendees", value);
                                    let payload = {
                                      attendValue: value,
                                      start: values.startAt,
                                      end: values.endAt,
                                    };
                                    this.handleListAttendeeLookupOptions(
                                      entityLookups.listAppointmentAttendees,
                                      values.attendees,
                                      value
                                    );
                                    this.handleCheckAttendee(payload);
                                  }}
                                  value={values.optionalAttendees}
                                  isMulti={true}
                                  controlShouldRenderValue={false}
                                  options={groupInternalAndExternalUsers(
                                    this.state.optionalAttendeesOptions
                                  )}
                                />
                              </>
                              <ErrorMessage
                                name="attendees"
                                component="div"
                                className="invalid-feedback"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                }}
                              >
                                {values.optionalAttendees.map((item, index) => {
                                  return (
                                    <MemberItem
                                      key={index}
                                      item={item}
                                      isAttendee
                                      isAvailable={availableAttendees.includes(
                                        item.value
                                      )}
                                      onClick={() => {
                                        let _optionalAttendees = JSON.parse(
                                          JSON.stringify([
                                            ...values.optionalAttendees,
                                          ])
                                        );
                                        _optionalAttendees =
                                          _optionalAttendees.filter(
                                            _attende =>
                                              _attende.value !== item.value
                                          );

                                        this.handleListAttendeeLookupOptions(
                                          entityLookups.listAppointmentAttendees,
                                          values.attendees,
                                          _optionalAttendees
                                        );
                                        setFieldValue(
                                          "optionalAttendees",
                                          _optionalAttendees
                                        );
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* )} */}
                      {!isEdit && (
                        <div className="mb-3">
                          <Col sm={12}>
                            <Label className="form-label">
                              {this.props.t("Files")}
                            </Label>
                            <FormUpload
                              files={files}
                              onFilesAccepted={onFilesAccepted}
                              multiple
                            />
                          </Col>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styleForButton}>
                        <button
                          type="button"
                          className="btn customTable-button save-task"
                          disabled={!this.isValid(values, errors)}
                          onClick={e => {
                            e.preventDefault();
                            this.props.handleSubmit(values);
                          }}
                        >
                          {isEdit
                            ? this.props.t("Update")
                            : this.props.t("Create").toUpperCase()}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </LocalizationProvider>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

CreateAppointmentModal.propTypes = {
  task: PropTypes.any,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  isFromAllTasks: PropTypes.bool,
  isFromStream: PropTypes.bool,
  onClosed: PropTypes.func,
  files: PropTypes.array,
  onFilesAccepted: PropTypes.func,
  editAppointment: PropTypes.object,
  onGetCheckData: PropTypes.func,
  availableAttendees: PropTypes.array,
  entityLookups: PropTypes.any,
};

const mapStateToProps = ({ calendar, Layout }) => ({
  loading: calendar.loading,
  permissions: Layout.permissions,
  popUp: calendar.popUp,
  availableAttendees: calendar.availableAttendees,
});

const mapDispatchToProps = dispatch => ({
  onGetCheckData: payload => dispatch(getCheck(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(CreateAppointmentModal)));
