import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
  TabPane,
  TabContent,
  Button,
} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
//Import Breadcrumb
import Search from "components/Common/Search";
import {
  ACTION_TYPES,
  ACTION_TYPES_PATH,
  Colors,
  CURRENT_LANG,
  ENTITIES,
  E_SIGNATURE_TYPE,
  isInternal,
  MIN_COMMENT_LENGTH,
  TOAST_OPTIONS,
  UPLOAD_TYPES,
} from "common";
import "./styles.css";
import ApprovalCard from "./ApprovalCard";
import {
  beginTheBar,
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  getEntityDetailsRoute,
  shortenString,
  stringifyNumber,
} from "common/utils";
import { connect } from "react-redux";
import CreateApproval from "./InitiateApproval";
import {
  clearApproval,
  createReferencedFiles,
  getApprovalDetails,
  getApprovalFiles,
  getApprovals,
  getEntityLookups,
  getReferencedFiles,
  uploadFiles,
  upsertApproval,
  upsertComments,
  submitApprovalPath,
  deleteApproval,
} from "store/actions";
import moment from "moment";
import StatusText from "components/Common/StatusText";
import Comments from "components/Common/Comments";
import FileItem from "components/Common/FileItem";
import FileList from "pages/FileManager/FileList";
import CustomTextArea from "components/Common/CustomTextArea";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Breadcrumb from "components/Common/Breadcrumb";
import EditIcon from "components/Common/EditIcon";
import Markdown from "components/Common/Markdown";
import Seo from "common/Seo";
import DeleteModal from "components/Common/DeleteModal";
import DeleteIcon from "components/Common/DeleteIcon";
// import Loading from "pages/Loading";
class Approvals extends Component {
  constructor(props) {
    super(props);
    let createEntities = filterPermittedEntities(props.permissions, "create");
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    this.state = {
      commentValue: "",
      modal: false,
      action: ACTION_TYPES.APPROVE,
      comment: "",
      activeTabId: "1",
      searchText: "",
      filteredApprovals: null,
      isCreateApproval: createEntities.includes(ENTITIES.APPROVAL),
      isUpdateApproval: updateEntities.includes(ENTITIES.APPROVAL),
      isEdit: false,
      attachedFile: null,
      values: null,
      isDisabledActions: false,
      files: [],
      isUpdatingApproval: false,
      mobileStyle: false,
      showDeleteModal: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.onApprovalSelect = this.onApprovalSelect.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleApprovalCreate = this.handleApprovalCreate.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleActionSubmit = this.handleActionSubmit.bind(this);
    this.handleApprovalPathSubmit = this.handleApprovalPathSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleApprovalUpdate = this.handleApprovalUpdate.bind(this);
    this.setActionValue = this.setActionValue.bind(this);
    this.getFilteredApprovals = this.getFilteredApprovals.bind(this);
    this.resize = this.resize.bind(this);
    this.handleClearApprovalDetails =
      this.handleClearApprovalDetails.bind(this);
    this.handleSetSelectedTab = this.handleSetSelectedTab.bind(this);
    this.handleDeleteApproval = this.handleDeleteApproval.bind(this);
  }

  toggleTab(tabId) {
    this.setState({ activeTabId: tabId });
  }

  handleChange(event) {
    this.setState({
      commentValue: event.target.value,
    });
  }

  onApprovalSelect(id) {
    beginTheBar();
    const payload = {
      entity: ENTITIES.APPROVAL,
      idEntity: id,
    };
    this.props.onGetReferencedFiles(payload, () => {});
    this.props.onGetApprovalFiles(payload, () => {
      this.props.onGetApprovalDetails(id);
    });

    this.setState({ commentValue: "" });
  }

  setActionValue(value) {
    this.setState({ action: value });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      isEdit: false,
    }));
  }

  componentDidMount() {
    const { onGetApprovals, onGetLookups } = this.props;
    beginTheBar();
    onGetLookups(ENTITIES.APPROVAL, () => {
      onGetApprovals();
    });

    window.addEventListener("resize", this.resize);
    this.resize();
  }

  handleApprovalCliks = () => {
    this.setState({ task: "", isEdit: false });
    this.toggle();
  };
  toggleEdit() {
    this.setState(prevState => ({
      editModal: !prevState.editModal,
      isEdit: true,
    }));
  }
  async handleDeleteApproval() {
    const { onDeleteApproval, approval } = this.props;
    try {
      this.props.onDeleteApproval();

      const requestObject = {
        request: approval.approval.idApproval,
        basicParameters: {
          entity: ENTITIES.APPROVAL,
        },
      };

      await onDeleteApproval(requestObject, () => {
        this.setState({ showDeleteModal: false });
        toast.success(
          this.props.t("SuccessDelete").replace("*", this.props.t("Approval")),
          TOAST_OPTIONS
        );
        setTimeout(() => {
          window.location.href = "/approvals";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleSetSelectedTab() {
    let _approvals = this.props.approvals;
    let pendingApprovals = _approvals.filter(
      _approval =>
        _approval.status?.value === ACTION_TYPES.PENDING ||
        _approval.status?.value === ACTION_TYPES.RETURN_WITH_COMMENTS
    );
    if (pendingApprovals.length > 0) {
      this.onApprovalSelect(pendingApprovals[0].idApproval);
    } else {
      let item = _approvals[0];
      this.onApprovalSelect(item.idApproval);
      this.toggleTab(
        item.status?.label?.toLowerCase() === "approved" ? "2" : "3"
      );
    }
  }
  checkIfShouldDisableActions() {
    const disable = () => {
      const name = localStorage.getItem("name");
      let approval = this.props.approval.approval;
      let CurrentApproval = approval?.approvalTask?.filter(
        item => item?.assignedto?.name === name
      );

      return (
        (approval.status?.value !== ACTION_TYPES.PENDING &&
          approval.status?.value !== ACTION_TYPES.RETURN_WITH_COMMENTS) ||
        approval?.requester?.name === name ||
        CurrentApproval[0]?.status?.label !== "Pending"
      );
    };
    this.setState(
      {
        isDisabledActions: disable(),
      },
      () => console.log(this.state.isDisabledActions)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // assign selectedApproval
      if (!this.state.mobileStyle) {
        if (
          prevProps.approvals.length === 0 &&
          this.props.approvals.length > 0
        ) {
          this.handleSetSelectedTab();
        }
        if (
          this.props.approval &&
          prevProps.approval?.approval?.idApproval !==
            this.props.approval?.approval?.idApproval
        ) {
          let approval = this.props.approval.approval;
          this.checkIfShouldDisableActions();
          this.setState(
            {
              action:
                approval.approvalType?.value == E_SIGNATURE_TYPE
                  ? ACTION_TYPES.RETURN_WITH_COMMENTS
                  : ACTION_TYPES.APPROVE,
            },
            () => {
              if (approval?.status?.value === ACTION_TYPES.APPROVE) {
                this.toggleTab("2");
              } else if (approval?.status?.value === ACTION_TYPES.REJECT) {
                this.toggleTab("3");
              } else {
                this.toggleTab("1");
              }
            }
          );
        }
      }
      let filteredApprovals = this.getFilteredApprovals(
        [...this.props.approvals],
        this.state.searchText
      );
      this.setState({ filteredApprovals });
    }
  }

  resize() {
    if (window.innerWidth <= 750 && !this.state.mobileStyle) {
      this.setState({ mobileStyle: true });
    } else if (this.state.mobileStyle) {
      this.setState({ mobileStyle: false });
      if (!!!this.props.approval) {
        this.handleSetSelectedTab();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  handleApprovalPathSubmit() {
    const {
      approval,
      onSubmitApprovalPath,
      onGetApprovals,
      onGetLookups,
      onGetApprovalDetails,
    } = this.props;

    const approvalDetails = approval.approval;
    const name = localStorage.getItem("name");
    let CurrentApproval = approvalDetails?.approvalTask?.filter(
      item => item?.assignedto?.name === name
    );

    const approvalPathSubmitData = {
      idApprovalTask: CurrentApproval[0]?.idApprovalTask,
      status: this.state.action,
      comments: formatLineBreaksInMarkdownFriendlyFormat(
        this.state.commentValue
      ),
      regardingEntity: "day_approval",
      idRegarding: approvalDetails.idApproval,
    };
    onSubmitApprovalPath(approvalPathSubmitData, () => {
      onGetApprovals();
      toast.success(this.props.t("SuccessSign"), TOAST_OPTIONS);
      // this.onApprovalSelect("");
      // this.handleSetSelectedTab();
      onGetApprovalDetails(approval.approval.idApproval, () =>
        this.checkIfShouldDisableActions()
      );
    });
  }
  handleActionSubmit(isSign = false) {
    const {
      onAddNewComment,
      approval,
      onUpdateApproval,
      onUploadFiles,
      onCreateReferencedFile,
      onGetApprovals,
    } = this.props;
    const approvalDetails = approval.approval;
    const values = JSON.parse(JSON.stringify(this.state.values));
    const name = localStorage.getItem("name");
    const attachedFile = !!this.state.attachedFile
      ? values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER
        ? new File([this.state.attachedFile], this.state.attachedFile.name, {
            type: this.state.attachedFile.type,
          })
        : JSON.parse(JSON.stringify(this.state.attachedFile))
      : false;
    let appDetails = approval.approval;

    const newOverallStatus = () => {
      const otherUsersApprovalTasks = approvalDetails.approvalTask.filter(
        item => item.assignedto.name !== name
      );
      // If there is only one approver who is the current user
      if (otherUsersApprovalTasks.length < 1) return ACTION_TYPES_PATH.APPROVE;

      if (
        otherUsersApprovalTasks?.some(
          task => task.status.value === ACTION_TYPES_PATH.REJECT
        )
      ) {
        return ACTION_TYPES.REJECT;
      }
      if (
        otherUsersApprovalTasks?.some(
          task => task.status.value === ACTION_TYPES_PATH.PENDING
        )
      ) {
        return ACTION_TYPES.PENDING;
      }
      if (
        otherUsersApprovalTasks?.some(
          task => task.status.value === ACTION_TYPES_PATH.RETURN_WITH_COMMENTS
        )
      ) {
        return ACTION_TYPES.RETURN_WITH_COMMENTS;
      }

      return ACTION_TYPES.APPROVE;
    };

    const approvalData = {
      idApproval: appDetails.idApproval,
      subject: appDetails.subject,
      duedate: appDetails.duedate,
      status:
        isSign && appDetails.approvalType?.value === E_SIGNATURE_TYPE
          ? newOverallStatus()
          : this.state.action,
      priority: appDetails.priority?.value,
      regardingEntityName: appDetails?.regardingEntity,
      description: formatLineBreaksInMarkdownFriendlyFormat(
        appDetails.description
      ),
      idCorrespondence: appDetails.idCorrespondence,
      idRegarding: appDetails.idRegarding,
      approvalType: appDetails.approvalType?.value,
      recipients:
        appDetails.approvalTask?.map(item => item.assignedto.id) || [],
      sharePointURL: appDetails.sharePointURL || "",
      approverSeqNo:
        appDetails.approvalTask?.findIndex(
          task => task.assignedto?.name === name
        ) + 1 ?? 0,
    };

    let comment = {
      commentedBy: null,
      commentedOn: "",
      description: this.state.commentValue,
      idComments: "00000000-0000-0000-0000-000000000000",
      regarding: {
        entityName: "day_approval",
        id: approvalDetails?.idApproval,
        label: approvalDetails?.subject,
        labelAr: "",
      },
    };

    this.setState({ isUpdatingApproval: true });
    beginTheBar();
    onUpdateApproval(approvalData, () => {
      if (!isSign) {
        onAddNewComment(comment, id => {
          if (!!attachedFile) {
            if (values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER) {
              // do file upload
              const payload = {
                request: {
                  updates: [],
                  allowExternalUsers: values.isExternalAllowed,
                  allowDownloads: values.isDownloadAllowed,
                  allowComments: values.isCommentsAllowed,
                  createReference: true,
                  path: `comments/${id}`,
                  parent: "00000000-0000-0000-0000-000000000000",
                },
                files: [attachedFile],
              };

              onUploadFiles(payload, () => {
                this.onApprovalSelect(approvalDetails?.idApproval);
                onGetApprovals(() => this.checkIfShouldDisableActions());
              });
            } else {
              let _payload = {
                idDocument: attachedFile.idDocument,
                entity: ENTITIES.COMMENT,
                idEntity: id,
              };
              onCreateReferencedFile(_payload, () => {
                this.onApprovalSelect(approvalDetails?.idApproval);
                onGetApprovals(() => this.checkIfShouldDisableActions());
              });
            }
          } else {
            this.setState({
              isDisabledActions: true,
            });
            onGetApprovals();
            this.onApprovalSelect(approvalDetails?.idApproval);
          }
          toast.success(
            this.props
              .t(this.props.t("SuccessUpdate"))
              .replace("*", this.props.t("Approval")),
            TOAST_OPTIONS
          );
        });
        this.setState({ isUpdatingApproval: false });
      } else {
        this.setState({ action: ACTION_TYPES_PATH.APPROVE }, () => {
          this.handleApprovalPathSubmit();
        });
        this.onGetApprovalDetails(approval.approval.idApproval, () => {
          this.checkIfShouldDisableActions();

          const payload = {
            entity: ENTITIES.APPROVAL,
            idEntity: approval.approval.idApproval,
          };
          this.props.onGetReferencedFiles(payload, () => {});
          this.props.onGetApprovalFiles(payload, () => {});
        });
        this.onApprovalSelect(approvalDetails?.idApproval);
      }
    });
    setTimeout(() => {
      this.setState({ isUpdatingApproval: false, commentValue: "" });
    }, 1000);
  }

  handleApprovalCreate(values) {
    const { onAddNewApproval, onGetApprovals, approval, onUploadFiles } =
      this.props;
    let sharePointURL =
      +values.approvalType.value === E_SIGNATURE_TYPE
        ? this.state.files[0].path || this.state.files[0].name
        : "";
    let approvalBody = {
      idApproval: "00000000-0000-0000-0000-000000000000",
      subject: values.subject,
      duedate: values.dueDate,
      status: ACTION_TYPES.PENDING,
      approvalType: values.approvalType.value,
      priority: values.priority?.value,
      regardingEntityName: values.regarding?.entityName,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      idCorrespondence: values.idCorrespondence?.value ?? null,
      idRegarding: values.regarding?.value,
      sharePointURL,

      recipients: values.recipients?.reverse().map(item => item.value),
    };

    beginTheBar();
    onAddNewApproval(approvalBody, id => {
      if (this.state.files.length > 0) {
        const payload = {
          request: {
            updates: [],
            allowExternalUsers: false,
            allowDownloads: true,
            allowComments: true,
            createReference: true,
            path: `approvals/${id}`,
            parent: "00000000-0000-0000-0000-000000000000",
          },
          files: this.state.files,
        };
        onUploadFiles(payload, () => {
          onGetApprovals();
        });
      } else {
        onGetApprovals();
      }
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Approval")),
        TOAST_OPTIONS
      );
    });

    this.toggle();
  }

  handleApprovalUpdate(values) {
    const { approval, onGetApprovalDetails, onUpdateApproval, onGetApprovals } =
      this.props;

    const appDetails = approval?.approval;
    const approvalData = {
      idApproval: appDetails.idApproval,
      subject: values.subject,
      duedate: values.dueDate,
      status: appDetails?.status.value,
      approvalType: appDetails?.approvalType?.value,
      priority: values.priority?.value,
      regardingEntityName: values.regarding?.entityName,
      description: formatLineBreaksInMarkdownFriendlyFormat(
        appDetails.description
      ),
      idCorrespondence: values.idCorrespondence?.value ?? null,
      idRegarding: values.regarding?.value,
      recipients:
        appDetails.approvalTask?.map(item => item.assignedto.id) || [],
      sharePointURL: appDetails.sharePointURL || "",
    };
    beginTheBar();
    onUpdateApproval(approvalData, () => {
      onGetApprovalDetails(approval.approval.idApproval);

      onGetApprovals();
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Approval")),
        TOAST_OPTIONS
      );
    });
    this.toggleEdit();
  }

  getFilteredApprovals(array = [], searchText) {
    if (!searchText) {
      return array;
    }
    let filterdArray = array.filter(item => {
      let _item = JSON.parse(JSON.stringify(item));
      delete _item?.from?.image;
      const arabicPriority = this.props.t(_item?.["priority"]?.label || "");
      const arabicPriorityHigh =
        localStorage.getItem("I18N_LANGUAGE") === "ar" &&
        _item?.["priority"]?.label === "High"
          ? this.props.t("High Priority")
          : this.props.t(_item?.["priority"]?.label || "");
      return (
        JSON.stringify(_item)
          ?.toLowerCase()
          ?.includes(searchText?.trim()?.toLowerCase()) ||
        JSON.stringify(_item)?.includes(searchText?.trim()) ||
        arabicPriority === searchText?.trim() ||
        arabicPriorityHigh === searchText?.trim()
      );
    });
    return filterdArray;
  }

  handleClearApprovalDetails() {
    this.props.onGetApprovals();
    this.props.onClearApproval();
  }
  render() {
    const {
      approvals,
      approval,
      loading,
      error,
      loadingLookups,
      loadingUpload,
    } = this.props;
    const approvalDetails = approval?.approval;
    const name = localStorage.getItem("name");

    if (
      !this.state.filteredApprovals ||
      (!this.state.filteredApprovals &&
        !this.state.mobileStyle &&
        !approvalDetails) ||
      loadingLookups
      // loadingUpload ||
      // this.state.isUpdatingApproval
    ) {
      return <></>;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Seo
            title={this.props
              .t("Title")
              .replace("*", this.props.t("Approvals"))}
          />

          <Container fluid>
            {this.state.mobileStyle && !!approvalDetails && (
              <Row className="mb-4">
                <Breadcrumb
                  title={this.props.t("Approvals")}
                  breadcrumbItem={approvalDetails.subject}
                  titleOnClick={this.handleClearApprovalDetails}
                />
              </Row>
            )}

            <Row
              className={`g-0 my-1 w-100 px-1 row-margin-top-search ${
                this.state.mobileStyle && !!approvalDetails && "py-2 px-2"
              }`}
              style={{
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              {(!this.state.mobileStyle || !!!approvalDetails) && (
                <Col xs={"6"} sm={"8"} md={"9"} lg={"9"} xl={"9"} xxl={"10"}>
                  <Search
                    value={this.state.searchText}
                    onChange={e => {
                      let searchText = e.target.value;
                      let _filteredApprovals = this.getFilteredApprovals(
                        [...approvals],
                        searchText
                      );
                      this.setState({
                        searchText: e.target.value,
                        filteredApprovals: _filteredApprovals,
                      });
                    }}
                    placeholder={this.props.t("Search Approvals")}
                    containerStyle={{
                      padding: 20,
                      backgroundColor: Colors.white,
                    }}
                    style={{
                      backgroundColor: Colors.searchBG,
                      border: 0,
                    }}
                    showIcon
                  />
                </Col>
              )}

              {this.state.isCreateApproval && (
                <Col
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  xxl={2}
                  className="overflow-hidden"
                >
                  <Button
                    className="font-size-16 approval-button px-4 py-1"
                    onClick={this.handleApprovalCliks}
                  >
                    {this.props.t("Initiate Approval")}
                  </Button>
                </Col>
              )}
            </Row>
            <Row className="g-1">
              {(!this.state.mobileStyle || !!!approvalDetails) && (
                <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={3}>
                  <Card className="mb-1">
                    <CardBody
                      style={{
                        height: "75vh",
                        backgroundColor: Colors.white,
                        borderRadius: 5,
                        padding: 0,
                      }}
                    >
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        style={{
                          display: "flex",
                          flexGrow: 1,
                          height: 50,
                        }}
                      >
                        <NavItem
                          style={{
                            display: "flex",
                            height: "100%",
                            flexWrap: "nowrap",
                          }}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                            className={classnames({
                              active: this.state.activeTabId === "1",
                              "text-muted": this.state.activeTabId !== "1",
                            })}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            <span className="">{this.props.t("Pending")}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            display: "flex",
                            height: "100%",
                            flexWrap: "nowrap",
                          }}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                            className={classnames({
                              active: this.state.activeTabId === "2",
                              "text-muted": this.state.activeTabId !== "2",
                            })}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            <span className="">{this.props.t("Approved")}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            display: "flex",
                            height: "100%",
                            flexWrap: "nowrap",
                          }}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                            className={classnames({
                              active: this.state.activeTabId === "3",
                              "text-muted": this.state.activeTabId !== "3",
                            })}
                            onClick={() => {
                              this.toggleTab("3");
                            }}
                          >
                            <span className="">{this.props.t("Rejected")}</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTabId}>
                        <TabPane tabId="1">
                          <div
                            className="scrollbar"
                            style={{
                              height: "65vh",
                              overflow: "scroll",
                              overflowX: "hidden",
                              paddingBottom: 50,
                            }}
                          >
                            {this.state.filteredApprovals
                              .filter(
                                _item =>
                                  _item.status?.value ===
                                    ACTION_TYPES.PENDING ||
                                  _item.status?.value ===
                                    ACTION_TYPES.RETURN_WITH_COMMENTS
                              )
                              .map(item => (
                                <ApprovalCard
                                  key={"__approvalCard__" + item.idApproval}
                                  selectedApproval={approvalDetails}
                                  item={item}
                                  onClick={() =>
                                    this.onApprovalSelect(item.idApproval)
                                  }
                                />
                              ))}
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div
                            className="scrollbar"
                            style={{
                              height: "65vh",
                              overflow: "scroll",
                              overflowX: "hidden",
                              paddingBottom: 50,
                            }}
                          >
                            {this.state.filteredApprovals
                              .filter(
                                _item =>
                                  _item.status?.label?.toLowerCase() ===
                                  "approved"
                              )
                              .map(item => (
                                <ApprovalCard
                                  key={"__approvalCard__" + item.idApproval}
                                  selectedApproval={approvalDetails}
                                  item={item}
                                  onClick={() =>
                                    this.onApprovalSelect(item.idApproval)
                                  }
                                />
                              ))}
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div
                            className="scrollbar"
                            style={{
                              height: "65vh",
                              overflow: "scroll",
                              overflowX: "hidden",
                              paddingBottom: 50,
                            }}
                          >
                            {this.state.filteredApprovals
                              .filter(
                                _item =>
                                  _item.status?.label?.toLowerCase() ===
                                  "rejected"
                              )
                              .map(item => (
                                <ApprovalCard
                                  key={"__approvalCard__" + item.idApproval}
                                  selectedApproval={approvalDetails}
                                  item={item}
                                  onClick={() =>
                                    this.onApprovalSelect(item.idApproval)
                                  }
                                />
                              ))}
                          </div>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {!!approvalDetails && (
                <>
                  <CreateApproval
                    modal={this.state.editModal}
                    isEdit={this.state.isEdit}
                    toggle={this.toggleEdit}
                    handleSubmit={this.handleApprovalUpdate}
                    approval={{
                      ...approval,
                      approval: {
                        ...approvalDetails,
                        dueDate: approvalDetails.duedate
                          ? moment(approvalDetails.duedate).format("YYYY-MM-DD")
                          : "",
                      },
                    }}
                    entityLookups={
                      this.props.entityLookups?.[ENTITIES.APPROVAL]
                    }
                  />
                  <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={6}>
                    <Card style={{ marginBottom: 0 }}>
                      <CardBody
                        style={{ height: "75vh" }}
                        className="overflow-scroll scrollbar"
                      >
                        <CardTitle>
                          <div className="day-row day-w-100 day-justify-between">
                            <p className="mb-0 font-size-15 h4">
                              {approvalDetails.subject}
                            </p>

                            <div className="day-row-wrap day-justify-end">
                              <div className="day-row-wrap day-justify-end">
                                <StatusText
                                  text={approvalDetails.status?.label}
                                />
                                {approvalDetails.priority?.label
                                  ?.toLowerCase()
                                  .includes("high") > 0 && (
                                  <StatusText
                                    text={"High Priority"}
                                    style={{
                                      marginInlineStart: 10,
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </div>
                              <FileList
                                path={["approvals", approval.approval.subject]}
                                payload={{
                                  entity: ENTITIES.APPROVAL,
                                  idEntity: approval.approval.idApproval,
                                }}
                                uploadButtonStyle={{
                                  marginInlineStart: 5,
                                  marginInlineEnd: 5,
                                }}
                                files={this.props.files}
                                showFilesOnly
                                uploadButtonOnly
                                showUploadType
                              />

                              {this.state.isUpdateApproval &&
                                approvalDetails?.status?.value !==
                                  ACTION_TYPES_PATH.REJECT &&
                                approvalDetails?.status?.value !==
                                  ACTION_TYPES_PATH.RETURN_WITH_COMMENTS &&
                                approval.approval.canEdit && (
                                  <EditIcon
                                    onClick={this.toggleEdit}
                                    style={{
                                      backgroundColor: Colors.searchBG,
                                      borderRadius: 10,
                                      marginInlineStart: 5,
                                    }}
                                  />
                                )}
                              {approval.approval.canDelete && (
                                <DeleteIcon
                                  onClick={() => {
                                    this.setState({ showDeleteModal: true });
                                  }}
                                  style={{
                                    backgroundColor: Colors.searchBG,
                                    borderRadius: 10,
                                    margin: 5,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </CardTitle>
                        <div className="day-row day-justify-between mt-3">
                          <div className="day-row">
                            <Link
                              to={
                                "individual-details?id=" +
                                approvalDetails.requester?.id
                              }
                            >
                              {approvalDetails.requester?.image ? (
                                <img
                                  src={`data:image/png;base64,${approvalDetails.requester?.image}`}
                                  alt=""
                                  className="rounded-circle"
                                  height="50"
                                />
                              ) : (
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle">
                                    {approvalDetails.requester?.name.charAt(0)}
                                  </span>
                                </div>
                              )}
                            </Link>
                            <div className="day-column">
                              <Link
                                to={
                                  "individual-details?id=" +
                                  approvalDetails.requester?.id
                                }
                              >
                                <div className="day-row-wrap">
                                  <p
                                    className="mb-0"
                                    style={{ marginInlineStart: 10 }}
                                  >
                                    {`${approvalDetails.requester?.name}`}
                                  </p>
                                  <p
                                    className="mb-0 text-muted font-size-11"
                                    style={{
                                      marginInlineStart: 10,
                                    }}
                                  >
                                    {approvalDetails.requester?.email
                                      ? `<${approvalDetails.requester?.email}>`
                                      : ""}
                                  </p>
                                </div>
                              </Link>
                              <p
                                className="mb-0 text-muted font-size-12"
                                style={{ marginInlineStart: 10 }}
                              >
                                {`${this.props.t("Sent to")}${
                                  approvalDetails.approvalTask?.length > 0
                                    ? ", "
                                    : ""
                                }${approvalDetails.approvalTask
                                  ?.map(
                                    (item, index) =>
                                      item.assignedto?.name.split(" ")[0]
                                  )
                                  .join(", ")}.`}
                              </p>
                            </div>
                          </div>
                          <p className="mb-0 text-muted ">
                            {`${this.props.t("Sent on")} ${moment(
                              approvalDetails.createdDate
                            ).format("MMMM Do hh:mm a")}`}
                          </p>
                        </div>
                        <div className="text-muted day-row day-align-center mt-3">
                          <i className="bx bx-layout"></i>
                          <Link
                            to={getEntityDetailsRoute(
                              approvalDetails.regardingEntity,
                              approvalDetails.idRegarding
                            )}
                          >
                            <p
                              className="mb-0 text-primary font-size-13"
                              style={{
                                marginInlineStart: 5,
                                marginInlineEnd: 20,
                                textDecoration: "underline",
                              }}
                            >
                              {CURRENT_LANG === "ar"
                                ? approvalDetails?.regarding?.labelAr
                                : approvalDetails?.regarding?.label}
                            </p>
                          </Link>
                          <i className="bx bx-time"></i>
                          <p
                            className="mb-0"
                            style={{
                              marginInlineStart: 5,
                              marginInlineEnd: 20,
                              fontSize: 13,
                            }}
                          >
                            {`${this.props.t("Due")} ${moment(
                              approvalDetails.duedate
                            ).format("DD MMMM YYYY")}`}
                          </p>
                          {approvalDetails.approvalType?.value ===
                            E_SIGNATURE_TYPE && (
                            <>
                              <i className="bx bx-file"></i>
                              <p
                                className="mb-0"
                                style={{
                                  marginInlineStart: 5,
                                  marginInlineEnd: 20,
                                  fontSize: 13,
                                }}
                              >
                                {this.props.t("E Signature Required")}
                              </p>
                            </>
                          )}
                        </div>
                        <hr />
                        <Row style={{ marginTop: 20 }}>
                          <p className="mb-1 text-muted">
                            {this.props.t("Message").toUpperCase()}
                          </p>
                          <Markdown>{approvalDetails.description}</Markdown>

                          <p className="mb-1 mt-3 text-muted">
                            {this.props.t("Attachments").toUpperCase()}
                          </p>
                          <div className="day-row-wrap">
                            {this.props.referencedFiles &&
                            this.props.referencedFiles.length > 0 ? (
                              this.props.referencedFiles
                                .filter(f =>
                                  isInternal ? true : !f.isInternal
                                )
                                .map((f, idx) => {
                                  return <FileItem file={f} key={idx} />;
                                })
                            ) : (
                              <div className="mr-10">
                                {this.props.t("None")}
                              </div>
                            )}
                            {approval.approval.approvalType?.value ===
                              E_SIGNATURE_TYPE &&
                              (approval.approval.status?.value ===
                              ACTION_TYPES.APPROVE ? (
                                <StatusText text={this.props.t("Signed")} />
                              ) : (
                                approval?.approval.requester?.name !== name &&
                                (approval?.approval?.status?.value ===
                                  ACTION_TYPES.PENDING ||
                                  approval?.approval?.status?.value ===
                                    ACTION_TYPES.RETURN_WITH_COMMENTS) &&
                                approval?.approval?.approvalTask?.some(item => {
                                  /* Find if user has not signed or returned with comments; if not return false and don't render sign button */
                                  if (item?.assignedto?.name === name) {
                                    return item?.status?.label === "Pending" ||
                                      item?.status?.label === "Returned"
                                      ? true
                                      : false;
                                  } else return false;
                                }) && (
                                  <button
                                    className="btn sign-btn"
                                    onClick={() => {
                                      this.handleActionSubmit(true);
                                    }}
                                  >
                                    {this.props.t("Sign")}
                                  </button>
                                )
                              ))}
                          </div>
                          {!this.state.isDisabledActions && (
                            <>
                              <p className="mb-1 mt-3 text-muted">
                                {this.props.t("Your Action").toUpperCase()}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {approval.approval.approvalType?.value !=
                                  E_SIGNATURE_TYPE && (
                                  <div
                                    className="form-check"
                                    style={{ marginInlineEnd: 10 }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="action"
                                      id="radio1"
                                      value={ACTION_TYPES_PATH.APPROVE}
                                      checked={
                                        this.state.action ===
                                        ACTION_TYPES_PATH.APPROVE
                                      }
                                      onChange={e => {
                                        this.setActionValue(+e.target.value);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="radio1"
                                    >
                                      {this.props.t("Approve")}
                                    </label>
                                  </div>
                                )}
                                <div
                                  className="form-check"
                                  style={{ marginInlineEnd: 10 }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="action"
                                    id="radio2"
                                    value={
                                      ACTION_TYPES_PATH.RETURN_WITH_COMMENTS
                                    }
                                    checked={
                                      this.state.action ===
                                      ACTION_TYPES_PATH.RETURN_WITH_COMMENTS
                                    }
                                    onChange={e => {
                                      this.setActionValue(+e.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="radio2"
                                  >
                                    {this.props.t("Return with Comments")}
                                  </label>
                                </div>
                                <div
                                  className="form-check"
                                  style={{ marginInlineEnd: 10 }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="action"
                                    id="radio3"
                                    value={ACTION_TYPES_PATH.REJECT}
                                    checked={
                                      this.state.action ===
                                      ACTION_TYPES_PATH.REJECT
                                    }
                                    onChange={e => {
                                      this.setActionValue(+e.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="radio3"
                                  >
                                    {this.props.t("Reject")}
                                  </label>
                                </div>
                              </div>

                              <p
                                className=" mt-3 day-dark-text-bold"
                                style={{ marginTop: 10, marginBottom: "-35px" }}
                              >
                                {this.state.action === ACTION_TYPES_PATH.APPROVE
                                  ? this.props
                                      .t("Optional")
                                      .replace("*", this.props.t("Comments"))
                                  : this.props.t("Comments") + " *"}
                              </p>

                              <Comments
                                comments={approval.comments}
                                style={{ height: 180 }}
                              />
                              <div>
                                <CustomTextArea
                                  onChange={this.handleChange}
                                  value={this.state.commentValue}
                                  isAttachment
                                  path={[
                                    "approvals",
                                    approval.approval.subject,
                                  ]}
                                  payload={{
                                    entity: ENTITIES.APPROVAL,
                                    idEntity: approval.approval.idApproval,
                                  }}
                                  files={this.props.files}
                                  setFile={(file, values) => {
                                    this.setState({
                                      attachedFile: file,
                                      values,
                                    });
                                  }}
                                  isInvalid={
                                    this.state.action !==
                                    ACTION_TYPES_PATH.APPROVE
                                  }
                                />

                                <div
                                  style={{ marginTop: 20 }}
                                  className="day-text-end"
                                >
                                  <Button
                                    className="customTable-button"
                                    onClick={() => {
                                      this.handleApprovalPathSubmit(
                                        this.state.commentValue
                                      );
                                      this.setState({
                                        isDisabledActions: true,
                                      });
                                    }}
                                    disabled={
                                      this.state.action ===
                                      ACTION_TYPES_PATH.APPROVE
                                        ? false
                                        : !!!this.state.commentValue ||
                                          this.state.commentValue.length <
                                            MIN_COMMENT_LENGTH
                                    }
                                  >
                                    {this.props.t("Submit")}
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                          {/*  ( <div>
                            
                              <p
                                className="mb-1 mt-3 text-muted"
                                style={{ marginTop: 10 }}
                              >
                                {this.props.t("Comments")}
                              </p>

                              <Comments comments={approval.approvalTask} />
                            </div> )*/}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={3}>
                    <Card style={{ marginBottom: 0 }}>
                      <CardBody style={{ height: "75vh" }}>
                        <CardTitle>{this.props.t("Approval Path")}</CardTitle>
                        <div
                          className="scrollbar"
                          style={{
                            height: "90%",
                            overflow: "scroll",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 30,
                            zIndex: 20,
                            marginBottom: 20,
                            paddingInlineEnd: 10,
                          }}
                        >
                          {approvalDetails.approvalTask.map((item, index) => (
                            <div
                              className="day-row px-4 mb-4"
                              key={"__approvalPath__" + index}
                              style={{
                                position: "relative",
                              }}
                            >
                              <div className="approval-path" />
                              <div className="approval-path-line" />
                              <div className="day-row-flex-start day-justify-between day-w-100">
                                <div className="day-column day-w-100">
                                  <Link
                                    to={
                                      "/individual-details?id=" +
                                      item.assignedto?.id
                                    }
                                  >
                                    <p className="mb-0">
                                      {item.assignedto?.name}
                                    </p>
                                  </Link>

                                  <p className="mb-2 text-muted">
                                    {`${this.props.t(
                                      "Approver"
                                    )} ${this.props.t(
                                      stringifyNumber(
                                        approvalDetails.approvalTask.length -
                                          1 -
                                          index
                                      )
                                    )}`}
                                  </p>
                                  {!!item?.status?.label && (
                                    <StatusText
                                      text={
                                        item?.status?.value ===
                                        ACTION_TYPES_PATH.RETURN_WITH_COMMENTS
                                          ? "Return with Comments"
                                          : item?.status?.label
                                      }
                                      style={{ marginBottom: 10 }}
                                    />
                                  )}
                                  {!!item?.comments && (
                                    <div
                                      className="day-bg p-3 bubble "
                                      style={{
                                        alignSelf: "stretch",
                                        marginTop: 10,
                                      }}
                                    >
                                      <div className="mb-0 day-dark-text">
                                        <Markdown>
                                          {shortenString(item?.comments, 100)}
                                        </Markdown>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {item.status?.value ===
                                  ACTION_TYPES_PATH.APPROVE && (
                                  <div className="day-row day-justify-end day-align-right text-nowrap">
                                    <p className="mb-0 text-muted font-size-10">
                                      {moment(
                                        approvalDetails.approvedon
                                      ).format("hh:mma, DD MMMM")}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          <div
                            className="day-row px-4 mb-2"
                            style={{
                              position: "relative",
                            }}
                          >
                            <div className="approval-path" />
                            <div className="day-row-flex-start day-justify-between day-w-100">
                              <div className="day-column">
                                <Link
                                  to={
                                    "/individual-details?id=" +
                                    approvalDetails.requester?.id
                                  }
                                >
                                  <p className="mb-0">
                                    {approvalDetails.requester?.name}
                                  </p>
                                </Link>

                                <p className="mb-2 text-muted">
                                  {this.props.t("Initiator")}
                                </p>
                              </div>

                              <div className="day-row day-justify-end day-align-right text-nowrap">
                                <p className="mb-0 text-muted font-size-10">
                                  {moment(approvalDetails.createdDate).format(
                                    "hh:mma, DD MMMM"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          {!!approvalDetails.description && (
                            <div className="day-bg p-3 bubble">
                              <div className="mb-0 day-dark-text">
                                <Markdown>
                                  {shortenString(
                                    approvalDetails.description,
                                    100
                                  )}
                                </Markdown>
                              </div>
                            </div>
                          )}
                          {this.props.referencedFiles &&
                            this.props.referencedFiles.length > 0 &&
                            this.props.referencedFiles
                              .filter(f => (isInternal ? true : !f.isInternal))
                              .map((f, idx) => {
                                return <FileItem file={f} key={idx} />;
                              })}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          </Container>
          <DeleteModal
            onCloseClick={() => this.setState({ showDeleteModal: false })}
            onDeleteClick={this.handleDeleteApproval}
            show={this.state.showDeleteModal}
          />
          <CreateApproval
            modal={this.state.modal}
            toggle={this.toggle}
            handleSubmit={this.handleApprovalCreate}
            isEdit={this.state.isEdit}
            entityLookups={this.props.entityLookups?.[ENTITIES.APPROVAL]}
            files={this.state.files}
            onFilesAccepted={files => {
              this.setState({ files });
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

Approvals.propTypes = {
  t: PropTypes.any,
  approval: PropTypes.any,
  approvals: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetApprovals: PropTypes.func,
  onGetApprovalDetails: PropTypes.func,
  onAddNewApproval: PropTypes.func,
  onUpdateApproval: PropTypes.func,
  onSubmitApprovalPath: PropTypes.func,
  onAddNewComment: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  onGetReferencedFiles: PropTypes.func,
  onCreateReferencedFile: PropTypes.func,
  onUploadFiles: PropTypes.func,
  loadingUpload: PropTypes.bool,
  referencedFiles: PropTypes.any,
  files: PropTypes.any,
  onGetApprovalFiles: PropTypes.func,
  onClearApproval: PropTypes.func,
  onDeleteApproval: PropTypes.func,
};
const mapStateToProps = ({ approvals, Layout, files }) => ({
  approvals: approvals.approvals,
  loading: approvals.loading,
  approval: approvals.approval,
  error: approvals.error,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  loadingUpload: files.loading,
  referencedFiles: files.referencedFiles,
  files: approvals.files,
});

const mapDispatchToProps = dispatch => ({
  onGetApprovals: callback => dispatch(getApprovals(callback)),
  onGetApprovalDetails: (id, callback) =>
    dispatch(getApprovalDetails(id, callback)),
  onAddNewApproval: (payload, callback) =>
    dispatch(upsertApproval(payload, callback)),
  onUpdateApproval: (payload, callback) =>
    dispatch(upsertApproval(payload, callback)),
  onSubmitApprovalPath: (payload, callback) =>
    dispatch(submitApprovalPath(payload, callback)),
  onAddNewComment: (payload, callback) =>
    dispatch(upsertComments(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetReferencedFiles: (payload, callback) =>
    dispatch(getReferencedFiles(payload, callback)),
  onCreateReferencedFile: (payload, callback) =>
    dispatch(createReferencedFiles(payload, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onGetApprovalFiles: (payload, callback) =>
    dispatch(getApprovalFiles(payload, callback)),
  onClearApproval: () => dispatch(clearApproval()),
  onDeleteApproval: (payload, callback) =>
    dispatch(deleteApproval(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Approvals));
