import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
  TabPane,
  TabContent,
  Button,
} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
//Import Breadcrumb
import Search from "components/Common/Search";
import {
  Colors,
  CURRENT_LANG,
  ENTITIES,
  getGlobalHeaderStyle,
  isInternal,
  TOAST_OPTIONS,
  UPLOAD_TYPES,
} from "common";
import "./correspondence.css";
import CorrespondenceCard from "./Correspondence";
// import Loading from "pages/Loading";
import CreateCorrespondence from "./Create-correspondence";
import { connect } from "react-redux";

import {
  clearCorrespondence,
  getAllCorres,
  getCorrespondenceDetails,
  getCorrespondenceFiles,
  upsertCorrespondence,
} from "../../store/correspondence/actions";
import moment from "moment";
import {
  createReferencedFiles,
  getEntityLookups,
  getReferencedFiles,
  uploadFiles,
  upsertComments,
  upsertTask,
  deleteCorrespondence,
} from "store/actions";
import StatusText from "components/Common/StatusText";
import Comments from "components/Common/Comments";
import {
  beginTheBar,
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  sortCaretIcons,
} from "common/utils";
// import ActivityComp from "pages/Dashboard/ActivityComp";
import FileList from "pages/FileManager/FileList";
import CustomTextArea from "components/Common/CustomTextArea";
import FileItem from "components/Common/FileItem";
import { toast } from "react-toastify";
import Breadcrumb from "components/Common/Breadcrumb";
import EditIcon from "components/Common/EditIcon";
import DeleteIcon from "components/Common/DeleteIcon";
import Markdown from "components/Common/Markdown";
import DeleteModal from "components/Common/DeleteModal";

class index extends Component {
  constructor(props) {
    super(props);
    let createEntities = filterPermittedEntities(props.permissions, "create");
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    this.state = {
      modal: false,
      taskModal: false,
      taskTableModal: false,
      value: "",
      action: "1",
      comment: "",
      activeTabId: "1",
      searchText: "",
      isCreateTask: createEntities.includes(ENTITIES.TASK),
      isCreateCorres: createEntities.includes(ENTITIES.CORRESPONDENCE),
      isUpdateCorres: updateEntities.includes(ENTITIES.CORRESPONDENCE),
      filteredCorrespondences: null,
      isEdit: false,
      attachedFile: null,
      values: null,
      files: [],
      taskFiles: [],
      mobileStyle: false,
      showDeleteModal: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.onCorrespondenceSelect = this.onCorrespondenceSelect.bind(this);
    this.handleCorrespondenceClicks =
      this.handleCorrespondenceClicks.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleCorrespondenceCreate =
      this.handleCorrespondenceCreate.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleCorrespondenceUpdate =
      this.handleCorrespondenceUpdate.bind(this);
    this.handleCommentCreate = this.handleCommentCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTaskCreate = this.handleTaskCreate.bind(this);
    this.toggleTask = this.toggleTask.bind(this);
    this.getFilteredCorrespondences =
      this.getFilteredCorrespondences.bind(this);

    this.resize = this.resize.bind(this);
    this.handleClearCorrespondence = this.handleClearCorrespondence.bind(this);
    this.handleSetSelectedTab = this.handleSetSelectedTab.bind(this);
    this.handleDeleteCorrespondence =
      this.handleDeleteCorrespondence.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
  }

  toggleTab(tabId) {
    this.setState({ activeTabId: tabId });
  }

  toggle() {
    this.setState({ isEdit: false }, () => {
      this.setState(prevState => ({
        modal: !prevState.modal,
      }));
    });
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  toggleDelete() {
    this.setState(prevState => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  }

  async handleDeleteCorrespondence() {
    const { onDeleteCorrespondence, correspondenceDetails } = this.props;
    try {
      const requestObject = {
        request: correspondenceDetails?.correspondence?.idCorrespondence,
        basicParameters: {
          entity: ENTITIES.CORRESPONDENCE,
        },
      };

      await onDeleteCorrespondence(requestObject, () => {
        this.setState({ showDeleteModal: false });
        toast.success(
          this.props
            .t("SuccessDelete")
            .replace("*", this.props.t("Correspondence")),
          TOAST_OPTIONS
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  onCorrespondenceSelect(id) {
    beginTheBar();
    const payload = {
      entity: ENTITIES.CORRESPONDENCE,
      idEntity: id,
    };
    this.props.onGetReferencedFiles(payload, () => {});

    this.props.onGetCorresFiles(payload, () => {
      this.props.onGetCorresDetails(id);
    });
    this.setState({ value: "", files: [] });
  }

  handleCorrespondenceClicks = () => {
    this.setState({ task: "", isEdit: false });
    this.toggle();
  };

  componentDidMount() {
    beginTheBar();
    const { onGetAllCorres, onGetLookups } = this.props;
    onGetLookups(ENTITIES.TASK, () => {
      onGetLookups(ENTITIES.CORRESPONDENCE, () => {
        onGetAllCorres();
      });
    });

    window.addEventListener("resize", this.resize);
    this.resize();
  }

  toggleEdit() {
    this.setState({ isEdit: true }, () => {
      this.setState(prevState => ({
        editModal: !prevState.editModal,
      }));
    });
  }

  handleSetSelectedTab() {
    let inboxCorrespondences = this.props.correspondences.filter(
      _corres => _corres.category?.toLowerCase() === "inbox"
    );
    if (inboxCorrespondences?.length > 0) {
      this.onCorrespondenceSelect(inboxCorrespondences?.[0]?.idCorrespondence);
    } else {
      let item = this.props.correspondences[0];
      this.onCorrespondenceSelect(item?.idCorrespondence);
      this.toggleTab(item.category?.toLowerCase() === "sent" ? "2" : "3");
    }
  }

  handleCommentCreate(value) {
    const {
      onAddNewComment,
      correspondenceDetails,
      onCreateReferencedFile,
      onUploadFiles,
      onGetAllCorres,
    } = this.props;

    const values = JSON.parse(JSON.stringify(this.state.values));
    const attachedFile = !!this.state.attachedFile
      ? values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER
        ? new File([this.state.attachedFile], this.state.attachedFile.name, {
            type: this.state.attachedFile.type,
          })
        : JSON.parse(JSON.stringify(this.state.attachedFile))
      : false;

    let comment = {
      commentedBy: null,
      commentedOn: "",
      description: formatLineBreaksInMarkdownFriendlyFormat(this.state.value),
      idComments: "00000000-0000-0000-0000-000000000000",
      regarding: {
        entityName: "day_correspondence",
        id: correspondenceDetails?.correspondence?.idCorrespondence,
        label: correspondenceDetails?.correspondence?.subject,
        labelAr: "",
      },
    };
    beginTheBar();
    onAddNewComment(comment, id => {
      if (!!attachedFile) {
        if (values.uploadType.value === UPLOAD_TYPES.FROM_COMPUTER) {
          // do file upload
          const payload = {
            request: {
              updates: [],
              allowExternalUsers: values.isExternalAllowed,
              allowDownloads: values.isDownloadAllowed,
              allowComments: values.isCommentsAllowed,
              createReference: true,
              path: `comments/${id}`,
              parent: "00000000-0000-0000-0000-000000000000",
            },
            files: [attachedFile],
          };

          onUploadFiles(payload, () => {
            this.onCorrespondenceSelect(
              correspondenceDetails?.correspondence?.idCorrespondence
            );
            toast.success(
              this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
              TOAST_OPTIONS
            );
          });
        } else {
          let _payload = {
            idDocument: attachedFile.idDocument,
            entity: ENTITIES.COMMENT,
            idEntity: id,
          };
          onCreateReferencedFile(_payload, () => {
            this.onCorrespondenceSelect(
              correspondenceDetails?.correspondence?.idCorrespondence
            );
            toast.success(
              this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
              TOAST_OPTIONS
            );
          });
        }
      } else {
        this.onCorrespondenceSelect(
          correspondenceDetails?.correspondence?.idCorrespondence
        );
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
          TOAST_OPTIONS
        );
      }

      onGetAllCorres();
      this.setState({ value: "", files: [] });
    });
  }

  handleCorrespondenceCreate(values) {
    const { onAddNewCorrespondence, onGetAllCorres, onUploadFiles } =
      this.props;

    let correspondence = {
      idCorrespondence: "00000000-0000-0000-0000-000000000000",
      subject: values.subject,
      duedate: values.dueDate,
      date: "",
      receivedon: values.receivedon,
      status: values.status?.value,
      // status:"100000001",
      channelcode: +values.channelcode?.value,
      securityLevel: +values.securitylevel.value,
      origin: 100000001,
      priority: +values.priority?.value,
      regardingEntityName: values.regarding.entityName,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      idRegarding: values.regarding.value,
      recipients: values.recipients.map(item => ({ idRecipient: item.value })),
    };
    beginTheBar();
    onAddNewCorrespondence(correspondence, id => {
      if (this.state.files?.length > 0) {
        const payload = {
          request: {
            updates: [],
            allowExternalUsers: false,
            allowDownloads: true,
            allowComments: true,
            createReference: true,
            path: `correspondences/${id}`,
            parent: "00000000-0000-0000-0000-000000000000",
          },
          files: this.state.files,
        };
        onUploadFiles(payload, () => {
          onGetAllCorres();
          toast.success(
            this.props
              .t("SuccessAdd")
              .replace("*", this.props.t("Correspondence")),
            TOAST_OPTIONS
          );
        });
      } else {
        onGetAllCorres();
        toast.success(
          this.props
            .t("SuccessAdd")
            .replace("*", this.props.t("Correspondence")),
          TOAST_OPTIONS
        );
      }
    });

    this.toggle();
  }

  handleCorrespondenceUpdate(values) {
    const {
      correspondenceDetails,
      onGetCorresDetails,
      onUpdateCorrespondence,
      onGetAllCorres,
    } = this.props;
    const correspondence = {
      idCorrespondence: correspondenceDetails?.correspondence?.idCorrespondence,
      subject: correspondenceDetails?.correspondence?.subject,
      duedate: values.dueDate,
      date: correspondenceDetails?.correspondence?.date,
      receivedon: correspondenceDetails?.correspondence?.receivedon,
      status: +values.status?.value,
      securityLevel: +values.securitylevel.value,
      channelcode:
        +correspondenceDetails?.correspondence?.channelcode?.value || "",
      origin: correspondenceDetails?.correspondence?.origin?.value || "",
      priority: +values.priority?.value,
      sender: correspondenceDetails?.correspondence?.from?.id || "",
      regardingEntityName: values.regarding.entityName,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      idRegarding: values.regarding.value,
      recipients: values.recipients.map(item => ({ idRecipient: item.value })),
    };

    beginTheBar();
    onUpdateCorrespondence(correspondence, () => {
      toast.success(
        this.props
          .t("SuccessUpdate")
          .replace("*", this.props.t("Correspondence")),
        TOAST_OPTIONS
      );
      onGetCorresDetails(correspondenceDetails?.correspondence?.idCorrespondence);
      onGetAllCorres();
    });
    this.toggleEdit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (!this.state.mobileStyle) {
        // assign selectedCorrespondences
        if (
          prevProps.correspondences?.length === 0 &&
          this.props.correspondences?.length > 0
        ) {
          this.handleSetSelectedTab();
        }
        if (
          this.props.correspondenceDetails &&
          prevProps.correspondenceDetails?.correspondence?.idCorrespondence !==
            this.props.correspondenceDetails?.correspondence?.idCorrespondence
        ) {
          let category =
            this.props.correspondenceDetails?.correspondence?.category?.toLowerCase();
          if (category === "sent") {
            this.toggleTab("2");
          } else if (category === "draft") {
            this.toggleTab("3");
          } else {
            this.toggleTab("1");
          }
        }
      }
      let filterdArray = this.getFilteredCorrespondences(
        this.props.correspondences,
        this.state.searchText
      );
      this.setState({ filteredCorrespondences: filterdArray });
    }
  }

  resize() {
    if (window.innerWidth <= 750 && !this.state.mobileStyle) {
      this.setState({ mobileStyle: true });
    } else if (this.state.mobileStyle) {
      this.setState({ mobileStyle: false });
      if (!!!this.props.correspondenceDetails) {
        this.handleSetSelectedTab();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  toggleTask() {
    this.setState(prevState => ({
      taskModal: !prevState.taskModal,
    }));
  }

  getFilteredCorrespondences(array = [], searchText) {
    if (!searchText) {
      return array;
    }
    let filterdArray = array.filter(item => {
      let _item = JSON.parse(JSON.stringify(item));
      delete _item?.from?.image;
      const arabicPriority = this.props.t(_item?.["priority"]?.label || "");
      const arabicPriorityHigh =
        localStorage.getItem("I18N_LANGUAGE") === "ar" &&
        _item?.["priority"]?.label === "High"
          ? this.props.t("High Priority")
          : this.props.t(_item?.["priority"]?.label || "");
      return (
        JSON.stringify(_item)
          ?.toLowerCase()
          ?.includes(searchText?.trim()?.toLowerCase()) ||
        JSON.stringify(_item)?.includes(searchText?.trim()) ||
        arabicPriority === searchText?.trim() ||
        arabicPriorityHigh === searchText?.trim()
      );
    });
    return filterdArray;
  }

  handleTaskCreate(values) {
    const { onAddNewTask, correspondenceDetails } = this.props;

    let task = {
      idTask: "00000000-0000-0000-0000-000000000000",
      taskNumber: "",
      title: values.name,
      regarding: {
        entityName: ENTITIES.CORRESPONDENCE,
        label: "",
        id: correspondenceDetails?.correspondence?.idCorrespondence,
      },
      dueDate: values.dueDate,
      createdOn: "",
      priority: {
        label: "",
        value: +values.priority.value,
      },
      assignedBy: values.assignedBy,
      assignedTo: {
        name: "",
        id: values.assignedTo.value,
        image: "",
        email: "",
      },
      status: !!values.status?.value
        ? {
            label: "",
            value: +values.status.value,
          }
        : null,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      organization: null,
    };

    onAddNewTask(task, () => {
      this.onCorrespondenceSelect(
        correspondenceDetails?.correspondence?.idCorrespondence
      );
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Task")),
        TOAST_OPTIONS
      );
    });
    this.toggleTask();
  }

  handleClearCorrespondence() {
    this.props.onGetAllCorres();
    this.props.onClearCorrespondence();
  }
  render() {
    const {
      correspondences,
      correspondenceDetails,
      loading,
      loadingLookups,
      loadingUpload,
    } = this.props;
    if (
      // loading ||
      !this.state.filteredCorrespondences ||
      (!this.state.filteredCorrespondences &&
        !this.state.mobileStyle &&
        !correspondenceDetails) ||
      loadingLookups
      // loadingUpload
    ) {
      return <></>;
    }

    let tasksColumns = [
      {
        text: this.props.t("ID"),
        dataField: "idTask",
        sort: true,
        sortCaret: sortCaretIcons,
        hidden: true,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        text: this.props.t("Task Name"),
        dataField: "title",
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("50%"),
        align: "start",
        formatter: (cellContent, task) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/task-details?id=" + task.idTask}
                query={task}
                className="text-primary text-decoration-underline"
              >
                {task.title}
              </Link>
            </h5>
          </>
        ),
      },
      {
        dataField: "status",
        text: this.props.t("StatusForm"),
        sort: true,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("50%"),
        align: "start",
        formatter: (cellContent, task) => (
          <StatusText
            text={!!task.status ? task.status.label?.toUpperCase() : ""}
          />
        ),
      },
    ];

    return (
      <React.Fragment>
        <CreateCorrespondence
          modal={this.state.modal}
          toggle={this.toggle}
          handleSubmit={this.handleCorrespondenceCreate}
          isEdit={this.state.isEdit}
          entityLookups={this.props.entityLookups?.[ENTITIES.CORRESPONDENCE]}
          files={this.state.files}
          onFilesAccepted={files => {
            this.setState({ files });
          }}
        />

        <DeleteModal
          onCloseClick={() => this.setState({ showDeleteModal: false })}
          onDeleteClick={this.handleDeleteCorrespondence}
          show={this.state.showDeleteModal}
        />

        <div className="page-content">
          <MetaTags>
            <title>
              {this.props
                .t("Title")
                .replace("*", this.props.t("Correspondences"))}
            </title>
          </MetaTags>

          <Container fluid>
            {this.state.mobileStyle && !!correspondenceDetails && (
              <Row className="mb-4">
                <Breadcrumb
                  title={this.props.t("Correspondences")}
                  breadcrumbItem={correspondenceDetails?.correspondence?.subject}
                  titleOnClick={this.handleClearCorrespondence}
                />
              </Row>
            )}

            <Row
              className={`g-0 my-1 w-100 px-1 row-margin-top-search ${
                this.state.mobileStyle && !!correspondenceDetails && "py-2 px-2"
              }`}
              style={{
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              {(!this.state.mobileStyle || !!!correspondenceDetails) && (
                <Col xs={"6"} sm={"7"} md={"8"} lg={"8"} xl={"9"} xxl={"10"}>
                  <Search
                    value={this.state.searchText}
                    onChange={e => {
                      let searchText = e.target.value;
                      this.setState({ searchText }, () => {
                        let _filteredCorrespondences =
                          this.getFilteredCorrespondences(
                            [...correspondences],
                            searchText
                          );

                        this.setState({
                          filteredCorrespondences: _filteredCorrespondences,
                        });
                      });
                    }}
                    placeholder={this.props.t("Search Correspondences")}
                    containerStyle={{
                      padding: 20,
                      backgroundColor: Colors.white,
                    }}
                    style={{
                      backgroundColor: Colors.searchBG,
                      border: 0,
                    }}
                    showIcon
                  />
                </Col>
              )}

              {this.state.isCreateCorres && (
                <Col xs={6} sm={5} md={4} lg={4} xl={3} xxl={2}>
                  <div className="text-sm-center">
                    <Button
                      className="font-size-14 initiate-button py-1"
                      onClick={this.handleCorrespondenceClicks}
                    >
                      {this.props.t("Initiate Correspondence")}
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="g-1">
              <CreateCorrespondence
                modal={this.state.editModal}
                isEdit={this.state.isEdit}
                correspondenceData={correspondenceDetails}
                toggle={this.toggleEdit}
                handleSubmit={this.handleCorrespondenceUpdate}
                entityLookups={
                  this.props.entityLookups?.[ENTITIES.CORRESPONDENCE]
                }
              />

              {(!this.state.mobileStyle || !!!correspondenceDetails) && (
                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={3}>
                  <Card className="mb-1">
                    <CardBody
                      style={{
                        height: "85vh",
                        backgroundColor: Colors.white,
                        borderRadius: 5,
                        padding: 0,
                      }}
                    >
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        style={{
                          display: "flex",
                          flexGrow: 1,
                          height: 50,
                        }}
                      >
                        <NavItem
                          style={{
                            display: "flex",
                            height: "100%",
                            flexWrap: "nowrap",
                          }}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                            className={classnames({
                              active: this.state.activeTabId === "1",
                              "text-muted font-weight-700 font-size-14":
                                this.state.activeTabId !== "1",
                            })}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            <span className="text-nowrap font-size-14">
                              {this.props.t("Inbox")}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            display: "flex",
                            height: "100%",
                            flexWrap: "nowrap",
                          }}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                            className={classnames({
                              active: this.state.activeTabId === "2",
                              "text-muted": this.state.activeTabId !== "2",
                            })}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            <span className="text-nowrap">
                              {this.props.t("Sent")}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            display: "flex",
                            height: "100%",
                            flexWrap: "nowrap",
                          }}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                            className={classnames({
                              active: this.state.activeTabId === "3",
                              "text-muted": this.state.activeTabId !== "3",
                            })}
                            onClick={() => {
                              this.toggleTab("3");
                            }}
                          >
                            <span className="text-nowrap">
                              {this.props.t("Drafts")}
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTabId}>
                        <TabPane tabId="1">
                          <div
                            className="scrollbar"
                            style={{
                              height: "78vh",
                              overflow: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {this.state.filteredCorrespondences
                              .filter(
                                _item =>
                                  _item.category?.toLowerCase() === "inbox"
                              )
                              .map((item, index) => (
                                <CorrespondenceCard
                                  key={index}
                                  selectedCorrespondence={correspondenceDetails}
                                  item={item}
                                  onClick={() =>
                                    this.onCorrespondenceSelect(
                                      item.idCorrespondence
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div
                            className="scrollbar"
                            style={{
                              height: "78vh",
                              overflow: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {this.state.filteredCorrespondences
                              .filter(
                                _item =>
                                  _item.category?.toLowerCase() === "sent"
                              )
                              .map((item, index) => (
                                <CorrespondenceCard
                                  key={index}
                                  selectedCorrespondence={correspondenceDetails}
                                  item={item}
                                  onClick={() =>
                                    this.onCorrespondenceSelect(
                                      item.idCorrespondence
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div
                            className="scrollbar"
                            style={{
                              height: "78vh",
                              overflow: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {this.state.filteredCorrespondences
                              .filter(
                                _item =>
                                  _item.category?.toLowerCase() === "draft"
                              )
                              .map((item, index) => (
                                <CorrespondenceCard
                                  key={index}
                                  selectedCorrespondence={correspondenceDetails}
                                  item={item}
                                  onClick={() =>
                                    this.onCorrespondenceSelect(
                                      item.idCorrespondence
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {!!correspondenceDetails && (
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={9}>
                  <Card className="mb-1">
                    <CardBody
                      style={{ height: "85vh" }}
                      className="overflow-scroll scrollbar"
                    >
                      <CardTitle>
                        <div className="day-row day-w-100 day-justify-between">
                          <p className="mb-0 font-size-15 h4">
                            {correspondenceDetails?.correspondence?.subject}
                          </p>

                          <div className="day-row-wrap day-justify-end">
                            <div className="day-row-wrap day-justify-end">
                              <StatusText
                                text={
                                  correspondenceDetails?.correspondence?.status
                                    ?.label
                                }
                              />
                              {correspondenceDetails?.correspondence?.priority?.label
                                ?.toLowerCase()
                                ?.includes("high") && (
                                <StatusText
                                  text={"HIGH PRIORITY"}
                                  style={{ marginInlineStart: 10 }}
                                />
                              )}
                            </div>
                            <FileList
                              path={[
                                "correspondences",
                                correspondenceDetails?.correspondence?.subject,
                              ]}
                              payload={{
                                entity: ENTITIES.APPROVAL,
                                idEntity:
                                  correspondenceDetails?.correspondence
                                    ?.idCorrespondence,
                              }}
                              uploadButtonStyle={{
                                marginInlineStart: 5,
                                marginInlineEnd: 5,
                              }}
                              files={this.props.files}
                              showFilesOnly
                              uploadButtonOnly
                              showUploadType
                            />
                            {this.state.isUpdateCorres &&
                              correspondenceDetails?.correspondence?.canEdit && (
                                <EditIcon
                                  onClick={this.toggleEdit}
                                  style={{
                                    backgroundColor: Colors.searchBG,
                                    borderRadius: 10,
                                    marginInlineStart: 5,
                                  }}
                                />
                              )}
                            {correspondenceDetails?.correspondence?.canDelete && (
                              <DeleteIcon
                                onClick={this.toggleDelete}
                                style={{
                                  backgroundColor: Colors.searchBG,
                                  borderRadius: 10,
                                  marginInlineStart: 5,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </CardTitle>
                      <div className="day-row day-justify-between mt-3">
                        <Link
                          to={
                            "individual-details?id=" +
                            correspondenceDetails?.correspondence?.from?.id
                          }
                        >
                          <div className="day-row">
                            {correspondenceDetails?.correspondence?.from
                              ?.image ? (
                              <img
                                src={`data:image/png;base64,${correspondenceDetails?.correspondence?.from?.image}`}
                                alt=""
                                className="rounded-circle"
                                height="50"
                              />
                            ) : (
                              <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                  {correspondenceDetails?.correspondence?.from?.name.charAt(
                                    0
                                  )}
                                </span>
                              </div>
                            )}
                            <div className="day-column">
                              <div className="day-row-wrap">
                                <p
                                  className="mb-0"
                                  style={{ marginInlineStart: 10 }}
                                >
                                  {`${correspondenceDetails?.correspondence?.from?.name}`}
                                </p>
                                <p
                                  className="mb-0 text-muted font-size-11"
                                  style={{
                                    marginInlineStart: 10,
                                  }}
                                >
                                  {`<${correspondenceDetails?.correspondence?.from?.email}>`}
                                </p>
                              </div>
                              {correspondenceDetails?.correspondence
                                ?.recipientsList && (
                                <p
                                  className="mb-0 text-muted font-size-11"
                                  style={{ marginInlineStart: 10 }}
                                >
                                  {`${this.props.t("Sent to")}${
                                    correspondenceDetails?.correspondence
                                      .recipientsList?.length > 0
                                      ? ", "
                                      : ""
                                  }${correspondenceDetails?.correspondence?.recipientsList
                                    ?.map(
                                      recipient =>{
                                        console.log("recipient ====>",recipient);
                                        return recipient?.name?.split(" ")?.[0]
                                      }
                                    )
                                    .join(", ")}.`}
                                </p>
                              )}
                            </div>
                          </div>
                        </Link>
                        <p className="mb-0 text-muted ">
                          {`${this.props.t("Sent on")} ${moment(
                            correspondenceDetails?.correspondence?.createdDate
                          ).format("MMMM Do hh:mm a")}`}
                        </p>
                      </div>
                      <div className="text-muted day-row day-align-center mt-3 day-w-100">
                        <div className="day-row mx-2">
                          <i className="bx bx-time mx-1"></i>
                          <p className="mb-0 font-size-14">
                            {`${this.props.t("Due")} ${moment(
                              correspondenceDetails?.correspondence?.duedate
                            ).format("DD MMMM YYYY")}`}
                          </p>
                        </div>

                        <div className="day-row mx-2">
                          <i className="bx bx-layout mx-1"></i>
                          <p className="mb-0 text-primary font-size-13 text-decoration-underline">
                            {CURRENT_LANG === "ar"
                              ? correspondenceDetails?.correspondence
                                  ?.channelcode?.labelAr
                              : correspondenceDetails?.correspondence
                                  ?.channelcode?.label}
                          </p>
                        </div>

                        <div className="day-row mx-2">
                          <i className="fas fa-hashtag mx-1"></i>
                          <p className="mb-0 font-size-13">
                            {
                              correspondenceDetails?.correspondence
                                ?.correspondenceNumber
                            }
                          </p>
                        </div>
                      </div>
                      <hr />
                      <Row style={{ marginTop: 20 }}>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1 text-muted">
                            {this.props.t("Message").toUpperCase()}
                          </p>
                        </div>
                        <Markdown>
                          {correspondenceDetails?.correspondence?.description}
                        </Markdown>
                        <p className="mb-1 mt-3 text-muted">
                          {this.props.t("Attachments").toUpperCase()}
                        </p>

                        <div className="day-row-wrap mb-2">
                          {this.props.referencedFiles &&
                          this.props.referencedFiles?.length > 0
                            ? this.props.referencedFiles
                                .filter(f =>
                                  isInternal ? true : !f.isInternal
                                )
                                .map((f, idx) => {
                                  return <FileItem file={f} key={idx} />;
                                })
                            : this.props.t("None")}
                        </div>

                        <p
                          className="mb-1 mt-3 text-muted day-dark-text-bold"
                          style={{ marginTop: 10 }}
                        >
                          {this.props.t("Comments")}
                        </p>

                        <Comments
                          comments={correspondenceDetails.comments}
                          style={{ height: 180 }}
                        />
                        <CustomTextArea
                          onChange={this.handleChange}
                          value={this.state.value}
                          isAttachment
                          path={[
                            "correspondences",
                            correspondenceDetails?.correspondence?.subject,
                          ]}
                          payload={{
                            entity: ENTITIES.CORRESPONDENCE,
                            idEntity:
                              correspondenceDetails?.correspondence?.idCorrespondence,
                          }}
                          files={this.props.files}
                          setFile={(file, values) => {
                            this.setState({ attachedFile: file, values });
                          }}
                        />

                        <div style={{ marginTop: 20 }} className="day-text-end">
                          <Button
                            className="customTable-button"
                            onClick={this.handleCommentCreate}
                          >
                            {this.props.t("Submit")}
                          </Button>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

index.propTypes = {
  correspondences: PropTypes.array,
  correspondenceDetails: PropTypes.any,
  onGetAllCorres: PropTypes.func,
  onGetCorresDetails: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.any,
  onAddNewCorrespondence: PropTypes.func,
  onUpdateCorrespondence: PropTypes.func,
  isEdit: PropTypes.bool,
  onAddNewComment: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  loadingUpload: PropTypes.bool,
  referencedFiles: PropTypes.any,
  files: PropTypes.any,
  onGetReferencedFiles: PropTypes.func,
  onCreateReferencedFile: PropTypes.func,
  onUploadFiles: PropTypes.func,
  onGetCorresFiles: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onClearCorrespondence: PropTypes.func,
  onDeleteCorrespondence: PropTypes.func,
};

const mapStateToProps = ({ corres, Layout, files, tasks }) => ({
  correspondences: corres.correspondences,
  correspondenceDetails: corres.correspondenceDetails,
  loading: corres.loading,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  loadingUpload: files.loading,
  referencedFiles: files.referencedFiles,
  files: corres.files,
});

const mapDispatchToProps = dispatch => ({
  onGetAllCorres: () => dispatch(getAllCorres()),
  onGetCorresDetails: id => dispatch(getCorrespondenceDetails(id)),
  onAddNewCorrespondence: (payload, callback) =>
    dispatch(upsertCorrespondence(payload, callback)),
  onUpdateCorrespondence: (payload, callback) =>
    dispatch(upsertCorrespondence(payload, callback)),
  onAddNewComment: (payload, callback) =>
    dispatch(upsertComments(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetReferencedFiles: (payload, callback) =>
    dispatch(getReferencedFiles(payload, callback)),
  onCreateReferencedFile: (payload, callback) =>
    dispatch(createReferencedFiles(payload, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onGetCorresFiles: (payload, callback) =>
    dispatch(getCorrespondenceFiles(payload, callback)),
  onAddNewTask: (newTask, callback) => dispatch(upsertTask(newTask, callback)),
  onClearCorrespondence: () => dispatch(clearCorrespondence()),
  onDeleteCorrespondence: (payload, callback) =>
    dispatch(deleteCorrespondence(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(index)));
