import moment from "moment"

export const formatDateToRiyadhTimeZone = (inputDate) =>{
    return moment(inputDate)?.locale("en").utcOffset('+0300');
}
export const formatDateToUTCTimeZone = (inputDate) =>{
    return moment(inputDate)?.locale("en").utcOffset('-0300');
}

export const formatDateToUTCTimeZoneForMom = (inputDate) =>{
    return moment(inputDate)?.locale("en").utcOffset('-0300');
}


export const changeAppointmentByTimeZone = (appointment) =>{
    if(appointment && appointment.appointment && appointment.appointment.startAt){
        const tempAppointment = appointment.appointment;
       
        tempAppointment.startAt = formatDateToRiyadhTimeZone(appointment.appointment.startAt)?.format('YYYY-MM-DDTHH:mm:ss[Z]');
        tempAppointment.endAt = formatDateToRiyadhTimeZone(appointment.appointment.endAt)?.format('YYYY-MM-DDTHH:mm:ss[Z]');      
        appointment.appointment = tempAppointment;
        return appointment;
    }
    return appointment;
}

export const changeAppointmentByTimeZoneToUTC = (inputDate) =>{
    return formatDateToUTCTimeZone(inputDate)?.format('YYYY-MM-DDTHH:mm:ss[Z]');
}
export const changeAppointmentByTimeZoneToUTCForMoM = (inputDate) =>{
    return formatDateToUTCTimeZoneForMom(inputDate)?.format('YYYY-MM-DDTHH:mm:ss[Z]');
}

export const appointmentTime = (inputDate) =>{
    return moment(inputDate).locale("en")?.format('hh:mma');
}