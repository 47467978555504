import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import Rating from "components/Common/rating";
import { randomColor } from "common/utils";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class TeamMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMembers: this.props.data,
    };
    this.generateColors = this.generateColors.bind(this);
  }

  generateColors() {
    let color = randomColor();

    return {
      backgroundColor: color + "1a",
      color: color,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        teamMembers: this.props.data,
      });
    }
  }

  render() {
    const { fromProjectsPage } = this.props;
    let heightStyleTeamMembers = fromProjectsPage ? "697px" : "895px";
    return (
      <React.Fragment>
        <Card>
          <CardBody
            style={{ maxHeight: `${heightStyleTeamMembers}` }}
            className="overflow-scroll scrollbar"
          >
            <CardTitle>
              <p className="day-top-projects">{this.props.t("Team Members")}</p>
            </CardTitle>
            {this.state.teamMembers.map((member, inx) => (
              <div
                key={inx}
                className="day-row day-justify-between day-team-member-border-bottom"
              >
                <div
                  className="day-row day-pointer"
                  onClick={() => {
                    this.props.history.push(
                      `/individual-details?id=${member.id}`
                    );
                  }}
                >
                  {!member.image ? (
                    <div className="avatar-sm me-4">
                      <span
                        className="avatar-title rounded-circle p-4"
                        style={this.generateColors()}
                      >
                        {member.name[0]}
                      </span>
                    </div>
                  ) : (
                    <img
                      src={`data:image/png;base64,${member.image}`}
                      className="avatar-sm rounded-circle card-hover me-4"
                    ></img>
                  )}
                  <div className="d-flex flex-column mt-3">
                    <p>{member.name}</p>
                    <p className="day-team-member-margin-top">{member.role}</p>
                  </div>
                </div>
                <Rating value={member.productivityScore} />
              </div>
            ))}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

TeamMembers.propTypes = {
  data: PropTypes.array,
  fromProjectsPage: PropTypes.bool,
  t: PropTypes.any,
  history: PropTypes.any,
};
export default withTranslation()(withRouter(TeamMembers));
