import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";

const validations = {
  name: Yup.string().required("Please Enter Folder Name"),
};

class CreateFolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isValid = this.isValid.bind(this);
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    for (let key in validations) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="sm"
        centered
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {this.props.t("Create Folder")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape(validations)}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label day-required">
                          {this.props.t("Folder Name")}
                        </Label>
                        <Field
                          name="name"
                          type="text"
                          placeholder={this.props.t("Enter")}
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="day-text-end">
                      <button
                        type="button"
                        className="btn customTable-button save-task"
                        disabled={!this.isValid(values, errors)}
                        onClick={e => {
                          e.preventDefault();
                          this.props.handleSubmit(values);
                        }}
                      >
                        {this.props.t("Create")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

CreateFolderModal.propTypes = {
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
};

export default withTranslation()(CreateFolderModal);
