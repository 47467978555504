import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import {  Container, Row } from "reactstrap";
import {
  getTasks,
  getAllTasks,
  getAllTasksFail,
  getTasksDashboard
} from "../../store/tasks/actions";
import { withTranslation } from "react-i18next";
import AllTasks from "./all-tasks";
import Loading from "pages/Loading";
import { Colors, ENTITIES } from "common";
import moment from "moment";
import { getEntityLookups } from "store/actions";
import TasksDashboard from "./TasksDshboard";
import {getQueryVariable } from "common/utils";

export const PAGE_SIZE = 10;

class Tasks extends Component {
  constructor(props) {
    super(props);

        // read page from query string
        let selectedPage=1;
        let selectedStatus=''
        const lastPage = getQueryVariable("page") ;
        const lastStatus = getQueryVariable("status") ;
        selectedPage=lastPage?lastPage:1
        selectedStatus=lastStatus?lastStatus:''
        

    this.state = {
      customActiveTab: "1",
      allTasks: [],
      tabletStyle: props.tabletStyle,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,

      tasksOverview: [
        { label: "Overdue", value: 1, color: "#D04A02" },
        { label: "In Progress", value: 0, color: "#FFC94C" },
        { label: "Completed", value: 0, color: "#111D4A" },
      ],
      lastPage:selectedPage,
      lastStatus:selectedStatus
    };

  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.allTasks) {
        const inProgressTasks = this.props.allTasks?.filter(
          _task => _task.status?.label?.toLowerCase() === "in progress"
        );
        const delayedTasks = this.props.allTasks?.filter(
          _task =>
            moment(_task.dueDate).isBefore(moment(), "day") &&
            _task.status?.label?.toLowerCase() !== "completed"
        );

        const completedTasks = this.props.allTasks?.filter(
          _task => _task.status?.label?.toLowerCase() === "completed"
        );
        this.setState({
          tasksOverview: [
            {
              label: "Overdue",
              value: delayedTasks.length,
              color: "#FF3B30",
            },
            {
              label: "In Progress",
              value: inProgressTasks.length,
              color: "#F4BD33",
            },
            {
              label: "Completed",
              value: completedTasks.length,
              color: "#389741",
            },
          ],
          overviewSum:
            delayedTasks.length +
            inProgressTasks.length +
            completedTasks.length,
        });
      }
    }
  }

  componentDidMount() {
    const { onGetAllTasks, getAllTasksFail, onGetLookups , statusCode , onGetTasksDashboard} = this.props;
    onGetLookups(ENTITIES.TASK, () => {
      getAllTasksFail("")
      const payload={
        pageNumber:this.state.lastPage || 1,
        pageCount:PAGE_SIZE,
        statusCode: this.state.selectedStatus || statusCode,
        searchString:""
      }
      onGetAllTasks(payload);
      onGetTasksDashboard()
      window.history.pushState(null, null, window.location.pathname); // clear query params

    });

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 915 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 550 });
    this.setState({ mobileStyle: window.innerWidth <= 472 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  updateTasksStatus=staus=>{
    const { onGetAllTasks } = this.props;
    const payload={
      pageNumber:1,
      pageCount:PAGE_SIZE,
      statusCode:staus.value,
      searchString:""
    }
    onGetAllTasks(payload);
  }
  handlePageChange=pageNumber=>{
    const { onGetAllTasks , statusCode , searchString} = this.props;
    const payload={
      pageNumber:pageNumber,
      pageCount:PAGE_SIZE,
      statusCode: statusCode,
      searchString:searchString
    }
    onGetAllTasks(payload);
  }
  validateDate = passedDate => {
    /* in case the user fills a date as "DD/mm/YY", it should be passed to the api as "YYYY-mm-DD" */
const isValidDate = moment(passedDate, 'DD/MM/YY', true).isValid(); 
const isValidDate2= moment(passedDate, 'mm/DD/YY', true).isValid(); 
const isValidDate3= moment(passedDate, 'DD/MM/YYYY', true).isValid(); 
const isValidDate4= moment(passedDate, 'M/DD/YYYY', true).isValid(); 
if(isValidDate || isValidDate2 || isValidDate3 || isValidDate4){
  const currectDateFormat= isValidDate? "DD/MM/YY" :isValidDate2? "MM/DD/YY" :isValidDate3? "DD/MM/YYYY" : "M/DD/YYYY"
  const dateSearch = moment(passedDate,currectDateFormat).locale("en").format('YYYY-MM-DD');
    return dateSearch  
} else {
  return "Invalid date"
}
}
  getTasksWithSearch=(searchString)=>{
    let searchValue=searchString
    const { onGetAllTasks , statusCode} = this.props;
   
      /* check if its a date: */
      let dateSearch = this.validateDate(searchString);
      if(dateSearch !== "Invalid date"){
        searchValue=dateSearch
      }

    onGetAllTasks({
      statusCode: statusCode,
      pageNumber: 1,
      pageCount: PAGE_SIZE,
      searchString: searchValue
    });
 
  }

  render() {
    const { allTasks, loading, error, loadingLookups, loadingUpload ,dashboardData} =
      this.props;

    if (loading || loadingLookups || !allTasks || loadingUpload || !dashboardData) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Tasks Title")}</title>
          </MetaTags>

          <Container fluid>
            <TasksDashboard data={dashboardData} t={this.props.t} />
            {this.props.allTasks && (
              <Row className="g-0" style={{ marginTop: 1 }}>
                <AllTasks 
                tasks={this.props.allTasks}
                totalDataSize={this.props.totalDataSize}
                listStatus={this.props.entityLookups?.task?.listStatus || []}
                searchString={this.props.searchString} 
                onSearchSubmit={(searchString)=>this.getTasksWithSearch(searchString)}
                currentPageIndex={this.props.tasksPageNumber}
                handlePageChange={this.handlePageChange}
                updateStatus={(status)=>this.updateTasksStatus(status)}
                />
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Tasks.propTypes = {
  tasks: PropTypes.array,
  allTasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onGetTasksDashboard: PropTypes.func,
  onGetAllTasks: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.any,
  getAllTasksFail: PropTypes.func,
  error: PropTypes.string,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  loadingUpload: PropTypes.bool,
  tabletStyle: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  totalDataSize :PropTypes.any,
  searchString: PropTypes.any,
  tasksPageNumber: PropTypes.any,
  entityLookups:PropTypes.any,
  statusCode:PropTypes.any,
  dashboardData:PropTypes.any,
};

const mapStateToProps = ({ tasks, Layout, files }) => ({
  tasks: tasks.tasks,
  allTasks: tasks.allTasks,
  loading: tasks.loading,
  loadingLookups: Layout.loadingLookups,
  loadingUpload: files.loading,
  totalDataSize:tasks.totalDataSize,
  searchString:tasks.searchString,
  tasksPageNumber:tasks.tasksPageNumber,
  statusCode:tasks.statusCode,
  entityLookups: Layout.entityLookups,
  dashboardData: tasks.dashboardData

});

const mapDispatchToProps = dispatch => ({
  onGetTasks: () => dispatch(getTasks()),
  onGetAllTasks: (payload) => dispatch(getAllTasks(payload)),
  onGetTasksDashboard: () => dispatch(getTasksDashboard()),
  getAllTasksFail: err => dispatch(getAllTasksFail(err)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Tasks)));
