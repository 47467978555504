/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

/* PROJECTS DETAIL*/
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL";

export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const DELETE_STREAM = "DELETE_STREAM";
export const DELETE_STREAM_SUCCESS = "DELETE_STREAM_SUCCESS";
export const DELETE_STREAM_FAIL = "DELETE_STREAM_FAIL";

export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAIL = "DELETE_MEMBER_FAIL";

export const GET_PROJECTS_OVERVIEW = "GET_PROJECTS_OVERVIEW";
export const GET_PROJECTS_OVERVIEW_FAIL = "GET_PROJECTS_OVERVIEW_FAIL";
export const GET_PROJECTS_OVERVIEW_SUCCESS = "GET_PROJECTS_OVERVIEW_SUCCESS";

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GET_ALL_PROJECTS_FAIL = "GET_ALL_PROJECTS_FAIL";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_PROJECT_DETAILS_FAIL = "GET_PROJECT_DETAILS_FAIL";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";

export const UPSERT_PROJECT = "UPSERT_PROJECT";
export const UPSERT_PROJECT_FAIL = "UPSERT_PROJECT_FAIL";
export const UPSERT_PROJECT_SUCCESS = "UPSERT_PROJECT_SUCCESS";

export const UPSERT_STREAM = "UPSERT_STREAM";
export const UPSERT_STREAM_FAIL = "UPSERT_STREAM_FAIL";
export const UPSERT_STREAM_SUCCESS = "UPSERT_STREAM_SUCCESS";

export const UPSERT_MEMBER = "UPSERT_MEMBER";
export const UPSERT_MEMBER_FAIL = "UPSERT_MEMBER_FAIL";
export const UPSERT_MEMBER_SUCCESS = "UPSERT_MEMBER_SUCCESS";

export const GET_PROJECT_FILES = "GET_PROJECT_FILES";
export const GET_PROJECT_FILES_FAIL = "GET_PROJECT_FILES_FAIL";
export const GET_PROJECT_FILES_SUCCESS = "GET_PROJECT_FILES_SUCCESS";

export const GET_ALLPROJECTSOVERVIEW = "GET_ALLPROJECTSOVERVIEW";
export const GET_ALLPROJECTSOVERVIEW_FAIL = "GET_ALLPROJECTSOVERVIEW_FAIL";
export const GET_ALLPROJECTSOVERVIEW_SUCCESS =
  "GET_ALLPROJECTSOVERVIEW_SUCCESS";

export const UPDATE_PROJECT_STATUS_CODE = "UPDATE_PROJECT_STATUS_CODE";
export const GET_ALL_ACTIVE_PROJECTS = "GET_ALL_ACTIVE_PROJECTS"
export const GET_ALL_ACTIVE_PROJECTS_SUCCESS = "GET_ALL_ACTIVE_PROJECTS_SUCCESS"
export const GET_ALL_ACTIVE_PROJECTS_FAIL = "GET_ALL_ACTIVE_PROJECTS_FAIL"
export const GET_PROJECT_DASHBOARD="GET_PROJECTS_DASHBOAR"
export const GET_PROJECT_DASHBOARD_SUCCESS="GET_PROJECTS_DASHBOAR_SUCCESS"
export const GET_PROJECT_DASHBOARD_FAIL="GET_PROJECTS_DASHBOAR_FAIL"
export const GET_ALL_PROJECT_DASHBOARD= "GET_ALL_PROJECT_DASHBOARD"
export const GET_ALL_PROJECT_DASHBOARD_SUCCESS="GET_ALL_PROJECT_DASHBOARD_SUCCESS"
export const GET_ALL_PROJECT_DASHBOARD_FAIL="GET_ALL_PROJECT_DASHBOARD_FAIL"
export const GET_PROJECTS_OPTIONS= "GET_PROJECTS_OPTIONS"
export const GET_PROJECTS_OPTIONS_SUCCESS="GET_PROJECTS_OPTIONS_SUCCESS"
export const GET_PROJECTS_OPTIONS_FAIL="GET_PROJECTS_OPTIONS_FAIL"

