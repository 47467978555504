import {
  GET_EVENTS,
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  ADD_NEW_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAIL,
  GET_APPOINTMENTS,
  UPSERT_AGENDA,
  UPSERT_AGENDA_SUCCESS,
  UPSERT_AGENDA_FAIL,
  UPSERT_APPOINTMENTS,
  UPSERT_APPOINTMENTS_SUCCESS,
  UPSERT_APPOINTMENTS_FAIL,
  GET_APPOINTMENT_DETAILS,
  GET_APPOINTMENT_DETAILS_SUCCESS,
  GET_APPOINTMENT_DETAILS_FAIL,
  GET_CHECK,
  GET_CHECK_SUCCESS,
  GET_CHECK_FAIL,
  GET_CALENDAR_FILES,
  GET_CALENDAR_FILES_SUCCESS,
  GET_CALENDAR_FILES_FAIL,
  SEND_EMAIL_TO_PARTICIPANTS,
  SEND_EMAIL_TO_PARTICIPANTS_SUCCESS,
  SEND_EMAIL_TO_PARTICIPANTS_FAIL,
} from "./actionTypes";

export const getEvents = () => ({
  type: GET_EVENTS,
});

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
});

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
});

export const addNewEvent = event => ({
  type: ADD_NEW_EVENT,
  payload: event,
});

export const addEventSuccess = event => ({
  type: ADD_EVENT_SUCCESS,
  payload: event,
});

export const addEventFail = error => ({
  type: ADD_EVENT_FAIL,
  payload: error,
});

export const updateEvent = event => ({
  type: UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: GET_CATEGORIES,
});

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});

export const getAppointments = data => ({
  type: GET_APPOINTMENTS,
  payload: data,
});

export const getAppointmentsSuccess = appointments => ({
  type: GET_APPOINTMENTS_SUCCESS,
  payload: appointments,
});

export const getAppointmentsFail = error => ({
  type: GET_APPOINTMENTS_FAIL,
  payload: error,
});

export const upsertAgenda = (payload, callback) => ({
  type: UPSERT_AGENDA,
  payload: payload,
  callback,
});

export const upsertAgendaSuccess = (payload, callback) => ({
  type: UPSERT_AGENDA_SUCCESS,
  payload,
  callback,
});

export const upsertAgendaFail = error => ({
  type: UPSERT_AGENDA_FAIL,
  payload: error,
});

export const upsertAppointments = (payload, callback) => ({
  type: UPSERT_APPOINTMENTS,
  payload: payload,
  callback,
});

export const upsertAppointmentsSuccess = (payload, callback) => ({
  type: UPSERT_APPOINTMENTS_SUCCESS,
  payload,
  callback,
});

export const upsertAppointmentsFail = error => ({
  type: UPSERT_APPOINTMENTS_FAIL,
  payload: error,
});

export const getAppointmentDetails = (id, callback) => ({
  type: GET_APPOINTMENT_DETAILS,
  payload: id,
  callback,
});

export const getAppointmentDetailsSuccess = appointmentDetails => ({
  type: GET_APPOINTMENT_DETAILS_SUCCESS,
  payload: appointmentDetails,
});

export const getAppointmentDetailsFail = error => ({
  type: GET_APPOINTMENT_DETAILS_FAIL,
  payload: error,
});

export const getCheck = id => ({
  type: GET_CHECK,
  payload: id,
});

export const getCheckSuccess = checkAvail => ({
  type: GET_CHECK_SUCCESS,
  payload: checkAvail,
});

export const getCheckFail = error => ({
  type: GET_CHECK_FAIL,
  payload: error,
});

export const getCalendarFiles = (payload, callback) => ({
  type: GET_CALENDAR_FILES,
  payload,
  callback,
});

export const getCalendarFilesSuccess = payload => ({
  type: GET_CALENDAR_FILES_SUCCESS,
  payload,
});

export const getCalendarFilesFail = error => ({
  type: GET_CALENDAR_FILES_FAIL,
  payload: error,
});

export const sendEmailToParticipants = (payload, callback) => ({
  type: SEND_EMAIL_TO_PARTICIPANTS,
  payload,
  callback,
});

export const sendEmailToParticipantsSuccess = payload => ({
  type: SEND_EMAIL_TO_PARTICIPANTS_SUCCESS,
  payload,
});

export const sendEmailToParticipantsFail = error => ({
  type: SEND_EMAIL_TO_PARTICIPANTS_FAIL,
  payload: error,
});
