import {
  GET_ALL_CORRESPONDENCE_SUCCESS,
  GET_ALL_CORRESPONDENCE_FAIL,
  GET_ALL_CORRESPONDENCE,
  GET_CORRESPONDENCE_DETAILS_SUCCESS,
  GET_CORRESPONDENCE_DETAILS_FAIL,
  GET_CORRESPONDENCE_DETAILS,
  GET_COMMENTS,
  GET_COMMENTS_FAIL,
  GET_COMMENTS_SUCCESS,
  UPSERT_CORRESPONDENCE_FAIL,
  UPSERT_CORRESPONDENCE_SUCCESS,
  UPSERT_CORRESPONDENCE,
  GET_CORRESPONDENCE_FILES,
  GET_CORRESPONDENCE_FILES_SUCCESS,
  GET_CORRESPONDENCE_FILES_FAIL,
  GET_CORRESPONDENCES_OVERVIEW,
  GET_CORRESPONDENCES_OVERVIEW_SUCCESS,
  GET_CORRESPONDENCES_OVERVIEW_FAIL,
  CLEAR_CORRESPONDECE,
  DELETE_CORRESPONDENCE,
  DELETE_CORRESPONDENCE_SUCCESS,
  DELETE_CORRESPONDENCE_FAIL,

} from "./actionTypes";

const INIT_STATE = {
  correspondences: [],
  correspondenceDetails: null,
  error: "",
  loading: false,
  comments: [],
  files: null,
  correspondenceOverview: null,
};

const corres = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_CORRESPONDECE:
      return {
        ...state,
        correspondenceDetails: null,
      };
    case GET_CORRESPONDENCE_FILES:
      return {
        ...state,
        loading: true,
      };
    case GET_CORRESPONDENCE_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        loading: false,
        error: "",
      };

    case GET_CORRESPONDENCE_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_CORRESPONDENCE:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        correspondences: action.payload,
        loading: false,
        error: "",
      };

    case GET_ALL_CORRESPONDENCE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CORRESPONDENCE_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_CORRESPONDENCE_DETAILS_SUCCESS:
      return {
        ...state,
        correspondenceDetails: action.payload,
        loading: false,
        error: "",
      };

    case GET_CORRESPONDENCE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COMMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMENTS_SUCCESS:
      let _comments = action.payload.map(_comment => {
        return {
          idComments: _comment.idComments,
          description: _comment.description,
          commentedBy: _comment.commentedBy,
          commentedOn: _comment.commentedOn,
        };
      });
      return {
        ...state,
        comments: _comments,
        loading: false,
        error: "",
      };

    case GET_COMMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPSERT_CORRESPONDENCE:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_CORRESPONDENCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_CORRESPONDENCES_OVERVIEW:
      return {
        ...state,
        loading: true,
      };

    case GET_CORRESPONDENCES_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CORRESPONDENCES_OVERVIEW_SUCCESS:
      return {
        ...state,
        correspondenceOverview: action.payload,
        loading: false,
        error: "",
      };

    case DELETE_CORRESPONDENCE:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_CORRESPONDENCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default corres;
