import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import DashboardCard from "common/dashboardCard";
import dashboardTaskIcon from "../../../assets/icons/dashboardTask.svg";
import dashboardApprovalIcon from "../../../assets/icons/dashboardApproval.svg";
import dashboardCorrespondenceIcon from "../../../assets/icons/dashboardCorrespondence.svg";

import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
// import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import TeamMembers from "../ProjectLevel/team-members";
import Knob from "pages/AllCharts/knob/knob";
import LegendText from "components/Common/LegendText";
import { ENTITIES } from "common";
import classNames from "classnames";
import ExecutiveAttention from "./executive-attention";
import { getEntityLookups, getExecutiveDashboards } from "store/actions";
import KeyHighlights from "./KeyHighlights";
import CustomSelect from "components/Common/CustomSelect";
import { beginTheBar } from "common/utils";
import Loading from "pages/Loading";

class ExecutiveLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customActiveTab: "1",
      selectedProject: null,
      teamMembers: [
        {
          name: "Sammy Kilback",
          role: "Engagement Lead",
          img: avatar1,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
      ],
    };
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.getRiskText = this.getRiskText.bind(this);
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  getRiskText(risk) {
    if (risk.highRisk || risk.mediumRisk) {
      return this.props
        .t("Risks Text")
        .replace("*", risk.highRisk)
        .replace("*", risk.mediumRisk)
        .replace("*", risk.lowRisk);
    } else {
      return this.props.t("Risks Under Control");
    }
  }

  componentDidMount() {
    const { onGetLookups } = this.props;
    onGetLookups(ENTITIES.DASHBOARDS, () => {});
  }

  handleProjectChange(project) {
    beginTheBar();
    this.setState({ selectedProject: project });
    this.props.onGetExecutiveDashboards({
      projectId: project.value || "",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      //handle first time fetching array
      if (
        !!!this.state.selectedProject &&
        !!this.props.entityLookups &&
        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS] &&
        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects
          ?.length > 0
      ) {
        this.handleProjectChange(
          this.props.entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects[0]
        );
      }
    }
  }

  render() {
    const { entityLookups, loadingLookups, executiveOverview, loading } =
      this.props;
    if (!entityLookups || loadingLookups || !executiveOverview) {
      return <Loading />;
    }
    const executiveDetails = executiveOverview.executiveSuccess;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Executive Level")}</title>
          </MetaTags>
          <Container fluid>
            <Row className="day-row-flex-end day-w-100 day-justify-between row-margin-bottom-project-level row-margin-top-search">
              <Col sm={9}>
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classNames({
                        active: this.state.customActiveTab === "1",
                        "text-muted": this.state.customActiveTab !== "1",
                      })}
                      onClick={() => {
                        this.toggleCustom("1");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("Success Story")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classNames({
                        active: this.state.customActiveTab === "2",
                        "text-muted": this.state.customActiveTab !== "2",
                      })}
                      onClick={() => {
                        this.toggleCustom("2");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("Attention Required")}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>

              <Col sm={3}>
                <CustomSelect
                  value={this.state.selectedProject}
                  onChange={this.handleProjectChange}
                  options={
                    entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects ||
                    []
                  }
                />
              </Col>
            </Row>
            <TabContent activeTab={this.state.customActiveTab}>
              <TabPane tabId="1">
                <Row>
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={4}
                    xxl={4}
                    className="g-1 col-margin-top-project-level"
                  >
                    <DashboardCard
                      title={this.props.t("On Track Tasks")}
                      value={executiveDetails.onTrackTasks}
                      percentage={executiveDetails.onTrackTasksVSPreviousMonth}
                      previousMonthTitle={this.props.t("vs Previous Month")}
                      icon={dashboardTaskIcon}
                      onClick={() => {
                        this.props.history.push("/tasks");
                      }}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={4}
                    xxl={4}
                    className="g-1 col-margin-top-project-level"
                  >
                    <DashboardCard
                      title={this.props.t("Completed Approvals")}
                      value={executiveDetails.completedApprovals}
                      percentage={
                        executiveDetails.completedApprovalsVSPreviousMonth
                      }
                      previousMonthTitle={this.props.t("vs Previous Month")}
                      icon={dashboardApprovalIcon}
                      onClick={() => {
                        this.props.history.push("/approvals");
                      }}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={4}
                    xxl={4}
                    className="g-1 col-margin-top-project-level"
                  >
                    <DashboardCard
                      title={this.props.t("Completed Correspondences")}
                      value={executiveDetails.completedCorrespondences}
                      percentage={
                        executiveDetails.completedCorrespondencesVSPreviousMonth
                      }
                      previousMonthTitle={this.props.t("vs Previous Month")}
                      icon={dashboardCorrespondenceIcon}
                      onClick={() => {
                        this.props.history.push("/correspondence");
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="g-1 col-margin-top-project-level"
                  >
                    <Card>
                      <CardBody style={{ maxHeight: "895px" }}>
                        <p className="day-top-projects">
                          {this.props.t("Project Summary")}
                        </p>
                        <div className="d-flex align-items-center justify-content-between my-5">
                          <Knob
                            value={executiveDetails.projectProgress}
                            total={100}
                            bgColor={"#4C4D4C80"}
                            fgColor={"#01B82A"}
                            knobColor={"#01B82A"}
                            thickness={0.09}
                            lineCap="round"
                            onChange={() => {}}
                          />
                          <KeyHighlights executiveDetails={executiveDetails} />
                        </div>
                        <div className="w-75 ms-2">
                          <Row className="border-bottom mx-0">
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 day-project-health-title my-3"
                            >
                              {this.props.t("Schedule")}
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 my-3"
                            >
                              <LegendText
                                text={executiveDetails.projectSummary.schedule}
                              />
                            </Col>
                          </Row>
                          <Row className="border-bottom mx-0">
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 day-project-health-title my-3"
                            >
                              {this.props.t("Risks")}
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 my-3"
                            >
                              <LegendText
                                displayText={this.getRiskText(
                                  executiveDetails.projectSummary.risk
                                )}
                                text={
                                  executiveDetails.projectSummary.risk
                                    .highRisk > 0
                                    ? "HIGH"
                                    : executiveDetails.projectSummary.risk
                                        .mediumRisk > 0
                                    ? "MEDIUM"
                                    : "LOW"
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="border-bottom mx-0">
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 day-project-health-title my-3"
                            >
                              {this.props.t("Approvals")}
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 my-3"
                            >
                              <LegendText
                                text={
                                  executiveDetails.projectSummary
                                    .projectApprovals
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="border-bottom mx-0">
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 day-project-health-title my-3"
                            >
                              {this.props.t("Correspondences")}
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="p-0 my-3"
                            >
                              <LegendText
                                text={
                                  executiveDetails.projectSummary
                                    .projectCorrespondence
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="g-1 col-margin-top-project-level"
                  >
                    <TeamMembers data={executiveDetails.projectTeamMembers} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <ExecutiveAttention />
              </TabPane>
            </TabContent>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ExecutiveLevel.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  onGetExecutiveDashboards: PropTypes.func,
  onGetLookups: PropTypes.func,
  executiveOverview: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = ({ contacts, Layout }) => ({
  loading: contacts.loading,
  executiveOverview: contacts.executiveOverview,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
});

const mapDispatchToProps = dispatch => ({
  onGetExecutiveDashboards: (payload, callback) =>
    dispatch(getExecutiveDashboards(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ExecutiveLevel)));
