import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { withTranslation } from "react-i18next";
import Search from "./Search";
import { Colors, CURRENT_LANG } from "../../common";
import "./table.scss";
import "./customTable.scss";
import CustomSelect from "components/Common/CustomSelect";
import { PAGE_SIZE } from "pages/Requests/requests";
import ServerTable from "components/Common/ServerTable";



class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filteredData: props.data,
      columns: props.columns,
      updatedPageSize:10
    };
    this.handleColumnAlignment = this.handleColumnAlignment.bind(this);

   
  }

  onSearchTextchanges=(text)=>{
    this.setState({
      searchText:text
    });
  }
 
  onSearchBtnClickHandler=(searchText)=>{
    // call api only if search value changed  
    if(searchText.trim() !== this.props.searchVal ){
      this.props.onSearchBtnClick(searchText.trim())
    }
  }

  

  handleColumnAlignment() {
    let _columns = this.props.columns.map(item => ({
      ...item,
      align: CURRENT_LANG === "en" ? "start" : "right",
    }));
    this.setState({ columns: _columns });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.columns) {
        this.handleColumnAlignment();
      }
    this.setState({
          filteredData: this.props.data,
          searchText:this.props.searchVal || ""
        });
      
    }
  }

  componentDidMount() {
    this.handleColumnAlignment();
    this.setState({
      searchText:this.props.searchVal || ""
    });
  }

  render() {
    const {
      data,
      columns,
      dropdownFilterOptions,
      dropdownFilterValue,
      handleDropdownFilterChange,
      serverSearch,
      onSearchBtnClick,
      largeSearch
    } = this.props;
    if (!data || !columns || !this.state.filteredData || !this.state.columns) {
      return <></>;
    }
    const showFilterDropdown = !!dropdownFilterOptions && !!dropdownFilterOptions.length;
    return (
     
              <React.Fragment>
                {this.props.showSearchAndAdd && (
                  <Row className="mb-4" style={{ width: "100%", alignItems: "center" }}>
                    {showFilterDropdown && 
                      <Col
                        xs={12}
                        sm={3}
                        md={2}
                        lg={3}
                        xl={2}
                        xxl={2}
                      >
                        <CustomSelect
                          className="filter-dropdown"
                          onChange={handleDropdownFilterChange}
                          value={dropdownFilterValue}
                          options={dropdownFilterOptions}
                          classNamePrefix={"filter-dropdown"}
                        />
                      </Col>
                    }
                    <Col 
                      xs={12}
                      sm={showFilterDropdown ? 9 : 12}
                      md={showFilterDropdown ? 10 : 12}
                      lg={showFilterDropdown ? 9 : 12}
                      xl={showFilterDropdown ? 10 : 12}
                      xxl={showFilterDropdown ? 10 : 12}>
                      <Row className="mb-0" style={{ width: "100%", alignItems: "center" }}>
                     {/** Search input */}
                      { serverSearch && <> <Col
                           xs={8}
                           sm={8}
                           md={6}
                           lg={largeSearch?9:8}
                           xl={largeSearch?9:8}
                           xxl={largeSearch?9:8}
                        >
                          <Search
                            value={this.state.searchText}
                            onChange={e => {
                              let searchTextinput = e.target.value;
                              this.onSearchTextchanges(searchTextinput);
                            }}
                            placeholder={this.props.placeholderText}
                            containerStyle={{
                              padding: 20,
                              paddingInlineStart: 0,
                              paddingInlineEnd: 0,
                              backgroundColor: Colors.white,
                              width: "100%",
                              
                            }}
                            style={{
                              backgroundColor: Colors.searchBG,
                              border: 0,
                              padding: 10,
                            }}
                            showIcon
                          />
                          
                          
                        </Col>
                          <Col
                          xs={1}
                          sm={1}
                          md={2}
                          lg={largeSearch?1:2}
                          xl={largeSearch?1:2}
                          xxl={largeSearch?1:2}
                        >

                          <div className="text-sm-start">
                            <Button
                              className="font-16 customTable-button text-nowrap"
                              onClick={()=>this.onSearchBtnClickHandler(this.state.searchText)}
                            >
                             {this.props.t("Search")}
                            </Button>
                          </div>
                        </Col>

                    
                        {this.props.tblRefresh && 
                          <Col
                          xs={1}
                          sm={1}
                          md={2}
                          lg={2}
                          xl={2}
                          xxl={2}
                        >

                          <div className="text-sm-end">
                            <Button
                              className="customTable-button text-nowrap refresh-btn"
                              onClick={()=>onSearchBtnClick("")}
                              title={this.props.t("Refresh")}
                            > 
                             <i className="bx bx-rotate-right"></i>
                              </Button>
                          </div>
                        </Col>}
                        </>
                        }
                        {!this.props.hideAddBtn && (
                          <Col
                            xs={4}
                            sm={3}
                            md={4}
                            lg={this.props.fromCorrespondence ? 4 : 2}
                            xl={this.props.fromCorrespondence ? 4 : 2}
                            xxl={this.props.fromCorrespondence ? 4 : 2}
                          >
                            <div className="text-sm-end">
                              <Button
                                className="font-16 customTable-button text-nowrap"
                                onClick={this.props.handleAddClick}
                              >
                                {this.props.addBtnText}
                              </Button>
                            </div>
                          </Col>
                        )}
                    
                
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <ServerTable
                          columns={this.state.columns}
                          data={data}
                          onPageChange={this.props.onPageChange}
                          currentPage={parseInt(this.props.currentPage)}
                          totalDataSize={this.props.totalDataSize}
                          sizePerPage={PAGE_SIZE}/>
                    </div>
                  </Col>
                </Row>
            
              </React.Fragment>
           
    );
  }
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  showSearchAndAdd: PropTypes.bool,
  t: PropTypes.any,
  className: PropTypes.string,
  sizePerPage: PropTypes.number,
  addBtnText: PropTypes.string,
  placeholderText: PropTypes.string,
  handleAddClick: PropTypes.func,
  keyField: PropTypes.string,
  hidePagination: PropTypes.bool,
  defaultSortedField: PropTypes.string,
  defaultSortedOrder: PropTypes.string,
  showSizePerPage: PropTypes.bool,
  expandRow: PropTypes.object,
  rowEvents: PropTypes.any,
  rowStyle: PropTypes.any,
  hideAddBtn: PropTypes.bool,
  search: PropTypes.string,
  fromCorrespondence: PropTypes.bool,
  dropdownFilterOptions: PropTypes.array,
  dropdownFilterValue: PropTypes.any,
  handleDropdownFilterChange: PropTypes.func, 
  totalDataSize:PropTypes.number,
  onPageChange:PropTypes.func,
  currentPage:PropTypes.number,
  serverSearch:PropTypes.bool,
  onSearchBtnClick:PropTypes.func,
  searchVal:PropTypes.string,
  tblRefresh:PropTypes.bool,
  largeSearch:PropTypes.bool,
};

export default withTranslation()(withRouter(Table));
