import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row } from "reactstrap";

interface Props {
  onCloseClick: () => void;
  onDeleteClick: () => void;
  deleteButtonText?: string;
  titleText?: string;
  descriptionText?: string;
  show: boolean;
}

const DeleteModal = ({ onCloseClick, onDeleteClick, show, deleteButtonText, titleText, descriptionText }: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-5 pb-5 delete-modal--container">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-alert-circle-outline delete-modal--warning-icon" />
                <h2>{titleText || t("Modal Are You Sure")}</h2>
                <h4>{descriptionText || t("Modal Delete Warning Message")}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-danger btn-lg me-2"
                  onClick={onDeleteClick}
                >
                  {deleteButtonText || t("Modal Confirm Delete")}
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-lg me-2"
                  onClick={onCloseClick}
                >
                  {t("Modal Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteModal;
