import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from "react-i18next";
import Search from "./Search";
import { Colors, CURRENT_LANG } from "../../common";
import "./table.scss";
import "./customTable.scss";
import { isIsoDate } from "common/utils";
import moment from "moment";
import CustomSelect from "components/Common/CustomSelect";
// import { isIsoDate } from "common/utils";
class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filteredData: props.data,
      columns: props.columns,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleColumnAlignment = this.handleColumnAlignment.bind(this);
  }

  handleSearch(searchText) {
    let filteredData = [...this.props.data];
    let columnKeys = this.props.columns?.filter(item => !item.hidden)
      .map(item => item.dataField);

    columnKeys.push("firstname");
    columnKeys.push("lastname");

    filteredData = filteredData?.filter(item =>
      columnKeys?.some(colKey => {
        let innerItem = item?.[colKey.split(".")[0]];

        if (
          colKey?.includes(".") &&
          !!innerItem &&
          typeof innerItem == "object"
        ) {
          "image" in innerItem && delete innerItem?.image;
          "profilePicture" in innerItem && delete innerItem?.profilePicture;
          "id" in innerItem && delete innerItem?.id;
          "idOrganization" in innerItem && delete innerItem?.idOrganization;
          "value" in innerItem && delete innerItem?.value;
        } else if (
          !!innerItem &&
          typeof innerItem == "string" &&
          isIsoDate(innerItem)
        ) {
          innerItem = moment(innerItem).format("DD/MM/YYYY");
        }

        if(colKey === "status" && typeof item?.[colKey] ==='object'){
          const colText =item?.[colKey]?.label; 
          const colTextArabic = this.props.t(item?.[colKey]?.label || "");
          const colTextArabicUpperCase = this.props.t(item?.[colKey]?.label?.toUpperCase() || "");
          return JSON.stringify(colTextArabic || "")?.toLowerCase()
              .includes(searchText?.trim()?.toLowerCase()) ||
               JSON.stringify(colText || "")
              ?.toLowerCase()
              .includes(searchText?.trim()?.toLowerCase()) ||
               JSON.stringify(colTextArabicUpperCase || "")
              ?.toLowerCase()
              .includes(searchText?.trim()?.toLowerCase());
        }

        if (colKey === "firstname" || colKey === "lastname") {
          innerItem = item?.firstname + " " + item?.lastname;
          if (
            JSON.stringify(innerItem || "")
              .toLowerCase()
              .includes(searchText?.trim()?.toLowerCase())
          ) {
            return JSON.stringify(innerItem || "")
              .toLowerCase()
              .includes(searchText?.trim()?.toLowerCase());
          }
        } else {
          return JSON.stringify(innerItem || "")
            .toLowerCase()
            .includes(searchText?.trim()?.toLowerCase());
        }
      })
    );
    this.setState({
      searchText,
      filteredData,
    });
  }

  handleColumnAlignment() {
    let _columns = this.props.columns.map(item => ({
      ...item,
      align: CURRENT_LANG === "en" ? "start" : "right",
    }));
    this.setState({ columns: _columns });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.columns) {
        this.handleColumnAlignment();
      }
      if (!!this.props.search) {
        let searchText = this.props.search;
        this.handleSearch(searchText);
      } else {
        this.setState({
          filteredData: this.props.data,
        });
      }
    }
  }

  componentDidMount() {
    this.handleColumnAlignment();
  }

  render() {
    const {
      data,
      columns,
      sizePerPage,
      keyField,
      hidePagination,
      defaultSortedField,
      defaultSortedOrder,
      showSizePerPage,
      expandRow,
      rowEvents,
      rowStyle,
      dropdownFilterOptions,
      dropdownFilterValue,
      handleDropdownFilterChange
    } = this.props;
    if (!data || !columns || !this.state.filteredData || !this.state.columns) {
      return <></>;
    }
    const pageOptions = {
      sizePerPage: sizePerPage ? sizePerPage : 10,
      totalSize: this.state.filteredData.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: defaultSortedField
          ? defaultSortedField
          : keyField
          ? keyField
          : "id", // if dataField is not match to any column you defined, it will be ignored.
        order: defaultSortedOrder ? defaultSortedOrder : "desc", // desc or asc
      },
    ];

    const showFilterDropdown = !!dropdownFilterOptions && !!dropdownFilterOptions.length;

    return (
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField={keyField ?? "id"}
        columns={this.state.columns}
        data={this.state.filteredData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField={keyField ?? "id"}
            columns={this.state.columns}
            data={this.state.filteredData}
          >
            {toolkitProps => (
              <React.Fragment>
                {this.props.showSearchAndAdd && (
                  <Row className="mb-0" style={{ width: "100%", alignItems: "center" }}>
                    {showFilterDropdown && 
                      <Col
                        xs={12}
                        sm={3}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                      >
                        <CustomSelect
                          className="filter-dropdown"
                          onChange={handleDropdownFilterChange}
                          value={dropdownFilterValue}
                          options={dropdownFilterOptions}
                          classNamePrefix={"filter-dropdown"}
                        />
                      </Col>
                    }
                    <Col 
                      xs={12}
                      sm={showFilterDropdown ? 9 : 12}
                      md={showFilterDropdown ? 10 : 12}
                      lg={showFilterDropdown ? 10 : 12}
                      xl={showFilterDropdown ? 10 : 12}
                      xxl={showFilterDropdown ? 10 : 12}>
                      <Row className="mb-0" style={{ width: "100%", alignItems: "center" }}>
                        <Col
                          xs={this.props.hideAddBtn ? "12" : "8"}
                          sm={this.props.hideAddBtn ? "12" : "9"}
                          md={this.props.hideAddBtn ? "12" : "8"}
                          lg={
                            this.props.hideAddBtn
                              ? "12"
                              : this.props.fromCorrespondence
                              ? 8
                              : 10
                          }
                          xl={
                            this.props.hideAddBtn
                              ? "12"
                              : this.props.fromCorrespondence
                              ? 8
                              : 10
                          }
                          xxl={
                            this.props.hideAddBtn
                              ? "12"
                              : this.props.fromCorrespondence
                              ? 8
                              : 10
                          }
                        >
                          <Search
                            value={this.state.searchText}
                            onChange={e => {
                              let searchText = e.target.value;
                              this.handleSearch(searchText);
                            }}
                            placeholder={this.props.placeholderText}
                            containerStyle={{
                              padding: 20,
                              paddingInlineStart: 0,
                              paddingInlineEnd: 0,
                              backgroundColor: Colors.white,
                              width: "100%",
                              // border: "1px solid " + Colors.lightGrey,
                            }}
                            style={{
                              backgroundColor: Colors.searchBG,
                              border: 0,
                              padding: 10,
                            }}
                            showIcon
                          />
                        </Col>
                        {!this.props.hideAddBtn && (
                          <Col
                            xs={4}
                            sm={3}
                            md={4}
                            lg={this.props.fromCorrespondence ? 4 : 2}
                            xl={this.props.fromCorrespondence ? 4 : 2}
                            xxl={this.props.fromCorrespondence ? 4 : 2}
                          >
                            <div className="text-sm-end">
                              <Button
                                className="font-16 customTable-button text-nowrap"
                                onClick={this.props.handleAddClick}
                              >
                                {this.props.addBtnText}
                              </Button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={keyField ?? "id"}
                        dataAlign="start"
                        hover
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={"align-middle"} // table-nowrap
                        headerWrapperClasses={"thead-light"}
                        expandRow={expandRow ? expandRow : {}}
                        rowEvents={rowEvents ? rowEvents : {}}
                        rowStyle={rowStyle}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                       // pagination={paginationFactory()}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="day-row day-justify-between overflow-hidden mt-30">
                  {showSizePerPage && (
                    <p className="mb-0 text-muted" style={{ width: "50%" }}>
                      {`${this.props.t("Showing")} ${
                        pageOptions.sizePerPage > pageOptions.totalSize
                          ? pageOptions.totalSize
                          : pageOptions.sizePerPage
                      } ${this.props.t("of")} ${pageOptions.totalSize}`}
                    </p>
                  )}
                  {!hidePagination && (
                    <Col
                      className="pagination pagination-rounded justify-content-end mt-2"
                      style={{
                        padding: 0,
                        margin: 0,
                        marginInlineEnd: 10,
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <PaginationListStandalone {...paginationProps} />
                    </Col>
                  )}
                    </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  showSearchAndAdd: PropTypes.bool,
  t: PropTypes.any,
  className: PropTypes.string,
  sizePerPage: PropTypes.number,
  addBtnText: PropTypes.string,
  placeholderText: PropTypes.string,
  handleAddClick: PropTypes.func,
  keyField: PropTypes.string,
  hidePagination: PropTypes.bool,
  defaultSortedField: PropTypes.string,
  defaultSortedOrder: PropTypes.string,
  showSizePerPage: PropTypes.bool,
  expandRow: PropTypes.object,
  rowEvents: PropTypes.any,
  rowStyle: PropTypes.any,
  hideAddBtn: PropTypes.bool,
  search: PropTypes.string,
  fromCorrespondence: PropTypes.bool,
  dropdownFilterOptions: PropTypes.array,
  dropdownFilterValue: PropTypes.any,
  handleDropdownFilterChange: PropTypes.func, 
};

export default withTranslation()(withRouter(Table));
