import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_HOME,
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from "./actionTypes";
import {
  getHomeSuccess,
  getHomeFail,
  getNotificationsSuccess,
  getNotificationsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getHome,
  getNotifications,
  updateNotifications,
} from "../../helpers/backend_helper";
import { getGenericError } from "common";

function* fetchHome({ payload }) {
  try {
    let body = {
      organizationId: payload,
    };
    const response = yield call(getHome, body);
    yield put(getHomeSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getHomeFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getHomeFail(error.message));
    }
  }
}

function* fetchNotifications() {
  try {
    const response = yield call(getNotifications);
    yield put(
      getNotificationsSuccess(response.responseObject.listNotifications)
    );
  } catch (error) {
    if (error.response) {
      yield put(
        getNotificationsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getNotificationsFail(error.message));
    }
  }
}

function* fetchUpdateNotifications({ payload }) {
  try {
    let body = payload;
    yield call(updateNotifications, body);
  } catch (error) {
    if (error.response) {
      yield put(
        getNotificationsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getNotificationsFail(error.message));
    }
  }
}
function* dashboardSaga() {
  yield takeEvery(GET_HOME, fetchHome);
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications);
  yield takeEvery(UPDATE_NOTIFICATIONS, fetchUpdateNotifications);
}

export default dashboardSaga;
