import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";

class PlannedVsActual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PlannedVsActual: props.data,
      series: props.series,
      categories: props.categories,
      title: props.title,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card style={{ marginTop: -20 }}>
          <CardBody className="overflow-scroll scrollbar">
            <div className="d-flex justify-content-between">
              <p className="day-top-projects">
                {this.props.t(this.state.title)}
              </p>
              <p className="day-top-five mx-2">
                {this.props.t("*Upcoming deadlines and overdue")}
              </p>
            </div>
            <Apaexlinecolumn
              series={this.state.series}
              categories={this.state.categories}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

PlannedVsActual.propTypes = {
  data: PropTypes.array,
  series: PropTypes.any,
  categories: PropTypes.any,
  title: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(PlannedVsActual);
