/* APPROVALS */
export const GET_APPROVALS = "GET_APPROVALS";
export const GET_APPROVALS_SUCCESS = "GET_APPROVALS_SUCCESS";
export const GET_APPROVALS_FAIL = "GET_APPROVALS_FAIL";

export const GET_APPROVAL = "GET_APPROVAL";
export const GET_APPROVAL_SUCCESS = "GET_APPROVAL_SUCCESS";
export const GET_APPROVAL_FAIL = "GET_APPROVAL_FAIL";

export const UPSERT_APPROVAL = "UPSERT_APPROVAL";
export const SUBMIT_APPROVAL_PATH = "SUBMIT_APPROVAL_PATH";
export const UPSERT_APPROVAL_SUCCESS = "UPSERT_APPROVAL_SUCCESS";
export const UPSERT_APPROVAL_FAIL = "UPSERT_APPROVAL_FAIL";

export const GET_APPROVAL_FILES = "GET_APPROVAL_FILES";
export const GET_APPROVAL_FILES_SUCCESS = "GET_APPROVAL_FILES_SUCCESS";
export const GET_APPROVAL_FILES_FAIL = "GET_APPROVAL_FILES_FAIL";
// export const GET_COMMENTS_IN_APPROVAL = "GET_COMMENTS";
// export const GET_COMMENTS_IN_APPROVAL_SUCCESS = "GET_COMMENTS_SUCCESS";
// export const GET_COMMENTS_IN_APPROVAL_FAIL = "GET_COMMENTS_FAIL";
export const GET_ALL_APPROVALS_OVERVIEW = "GET_ALL_APPROVALS_OVERVIEW";
export const GET_ALL_APPROVALS_OVERVIEW_SUCCESS =
  "GET_ALL_APPROVALS_OVERVIEW_SUCCESS";
export const GET_ALL_APPROVALS_OVERVIEW_FAIL =
  "GET_ALL_APPROVALS_OVERVIEW_FAIL";
export const CLEAR_APPROVAL = "CLEAR_APPROVAL";

export const DELETE_APPROVAL = "DELETE_APPROVAL";
export const DELETE_APPROVAL_SUCCESS = "DELETE_APPROVAL_SUCCESS";
export const DELETE_APPROVAL_FAIL = "DELETE_APPROVAL_FAIL";
