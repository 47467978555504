import React from "react";
import PropTypes from "prop-types";
import "./StatusText.scss";
import { useTranslation } from "react-i18next";

const StatusText = ({ text, style, projectsTbl = false }) => {
  const { t, i18n } = useTranslation();
  if (!text || typeof text !== "string") return <></>;

  // This checks if the word exists in the translations files in it's provided or uppercase variant otherwise it will return the original word
  const translatedText = !i18n
    ? text
    : i18n.exists(text)
    ? t(text)
    : i18n.exists(text.toUpperCase())
    ? t(text.toUpperCase())
    : text;

  let comboClassName = "yellow";

  text = text.toUpperCase();
  if (
    text.includes("PENDING") ||
    text.includes("PROGRESS") ||
    text.includes("IN PROGRESS") ||
    text.includes("EXTERNAL") ||
    text.includes("ONGOING")
  ) {
    comboClassName = "yellow";
  } else if (text.includes("HOLD")) {
    comboClassName = "lightPlum";
  } else if (text.includes("ACTIVE") || text.includes("OPEN")) {
    comboClassName = "lightBlue";
  } else if (text.includes("NEW")) {
    comboClassName = "pink";
  } else if (text.includes("FUTURE") || text.includes("DRAFT")) {
    comboClassName = "grey";
  } else if (
    text.includes("HIGH") ||
    text.includes("DELAYED") ||
    text.includes("ADMIN") ||
    text.includes("URGENT") ||
    text.includes("REJECTED")
  ) {
    comboClassName = "red";
  } else if (
    text.includes("TRACK") ||
    text.includes("APPROVED") ||
    text.includes("SIGNED")
  ) {
    comboClassName = "green";
  } else if (
    text.includes("COMPLETED") ||
    text.includes("INTERNAL") ||
    text.includes("LOW")
  ) {
    comboClassName = projectsTbl ? "bluelight" : "blue";
  } else if (text.includes("CANCELLED")) {
    comboClassName = "yellowGreen";
  }
  return (
    <p
      className={`mb-0 day-row day-justify-center font-size-12 status-tag color-combos ${comboClassName}`}
      style={style}
    >
      {translatedText}
    </p>
  );
};

StatusText.propTypes = {
  text: PropTypes.string,
  style: PropTypes.any,
  projectsTbl: PropTypes.bool,
};

export default StatusText;
