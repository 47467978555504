import PropTypes from "prop-types";
import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getNotifications, updateNotifications } from "store/actions";
import moment from "moment";
import { Colors } from "common";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      clearedNotifications: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (!this.state.menu && this.props.notifications.length > 0) {
      let ids = this.props.notifications.map(_not => _not.idNotification);
      this.props.onUpdateNotifications(ids);
      this.setState({ clearedNotifications: true });
    }
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    const { onGetNotifications } = this.props;
    onGetNotifications();
  }

  render() {
    const { loading, notifications } = this.props;
    if (loading) {
      return <></>;
    }
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            {!this.state.clearedNotifications && notifications.length > 0 ? (
              <>
                <i className="bx bx-bell bx-tada" />
                <span className="badge bg-danger rounded-pill">
                  {notifications.length}
                </span>
              </>
            ) : (
              <i className="bx bx-bell" />
            )}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div>
              <Row className="align-items-center p-3">
                <Col>
                  <h6 className="m-0 font-size-15 day-align-start">
                    {this.props.t("Notifications")}
                  </h6>
                </Col>
              </Row>
              <div
                style={{
                  backgroundColor: Colors.lightGrey,
                  height: 1,
                  width: "100%",
                }}
              />
            </div>

            <div
              className="scrollbar"
              style={{
                height: notifications.length > 0 ? "230px" : "100px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {notifications.length === 0 && (
                <div className="day-column day-justify-center day-align-center p-3">
                  <h6 className="mb-0 text-muted font-size-20">
                    {this.props.t("No Notifications")}
                  </h6>
                  <i className="fas fa-info-circle font-size-20 mb-0 mt-2 text-muted" />
                </div>
              )}
              {notifications.map((item, index) => (
                <a key={index} className="text-reset notification-item">
                  <div className="day-row-flex-start p-2">
                    <div
                      className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-sm"
                      style={{
                        padding: 5,
                        height: 30,
                        width: 30,
                        marginInlineEnd: 10,
                      }}
                    >
                      <i className="fas fa-th-list font-size-16 " />
                    </div>

                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-2 day-text-start">
                        {localStorage.getItem("I18N_LANGUAGE") === "ar"
                          ? item.title_ar
                          : item.title_en}
                      </h6>
                      {/* <p className="mt-0 mb-1 text-muted font-size-12">
                        {item.description}
                      </p> */}
                      <div className="font-size-12 text-muted">
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />
                          {localStorage.getItem("I18N_LANGUAGE") === "ar"
                          ? moment(item.date).locale("ar").fromNow()
                          : moment(item.date).locale("en").fromNow()}
                         
                        </p>
                      </div>
                    </div>
                  </div>
                  {index < notifications.length - 1 && (
                    <div
                      style={{
                        backgroundColor: Colors.lightGrey,
                        height: 1,
                        width: "100%",
                      }}
                    />
                  )}
                </a>
              ))}
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
  notifications: PropTypes.array,
  loading: PropTypes.bool,
  onGetNotifications: PropTypes.func,
  onUpdateNotifications: PropTypes.func,
};

const mapStateToProps = ({ Dashboard }) => ({
  notifications: Dashboard.notifications,
  loading: Dashboard.loadingNotifications,
});

const mapDispatchToProps = dispatch => ({
  onGetNotifications: () => dispatch(getNotifications()),
  onUpdateNotifications: arr => dispatch(updateNotifications(arr)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotificationDropdown));
