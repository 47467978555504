/* USERS */
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

/* Organizations*/
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS";
export const GET_ORGANIZATIONS_FAIL = "GET_ORGANIZATIONS_FAIL";

export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  "GET_ORGANIZATION_DETAILS_SUCCESS";
export const GET_ORGANIZATION_DETAILS_FAIL = "GET_ORGANIZATION_DETAILS_FAIL";

export const UPSERT_ORGANIZATION = "UPSERT_ORGANIZATION";
export const UPSERT_ORGANIZATION_SUCCESS = "UPSERT_ORGANIZATION_SUCCESS";
export const UPSERT_ORGANIZATION_FAIL = "UPSERT_ORGANIZATION_FAIL";

export const GET_ORGANIZATION_FILES = "GET_ORGANIZATION_FILES";
export const GET_ORGANIZATION_FILES_SUCCESS = "GET_ORGANIZATION_FILES_SUCCESS";
export const GET_ORGANIZATION_FILES_FAIL = "GET_ORGANIZATION_FILES_FAIL";

export const GET_INDIVIDUAL_LEVEL_DASHBOARDS =
  "GET_INDIVIDUAL_LEVEL_DASHBOARDS";
export const GET_INDIVIDUAL_LEVEL_DASHBOARDS_SUCCESS =
  "GET_INDIVIDUAL_LEVEL_DASHBOARDS_SUCCESS";
export const GET_INDIVIDUAL_LEVEL_DASHBOARDS_FAIL =
  "GET_INDIVIDUAL_LEVEL_DASHBOARDS_FAIL";

export const GET_EXECUTIVE_DASHBOARDS = "GET_EXECUTIVE_DASHBOARDS";
export const GET_EXECUTIVE_DASHBOARDS_SUCCESS =
  "GET_EXECUTIVE_DASHBOARDS_SUCCESS";
export const GET_EXECUTIVE_DASHBOARDS_FAIL = "GET_EXECUTIVE_DASHBOARDS_FAIL";
