import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { CURRENT_LANG, SELECT_STYLES } from "common";
import { withTranslation } from "react-i18next";

const CustomSelect = ({
  onChange,
  options,
  value,
  className,
  isMulti,
  controlShouldRenderValue,
  max,
  disabled,
  t,
  onInputChange,
  onError,
  isClearable,
  classNamePrefix,
  isTaskSelect = false,
}) => {
  // const filterOption = ({ label, value }, string) => {
  //   // default search
  //   if (label.includes(string) || value.includes(string)) return true;

  //   // check if a group as the filter string as label
  //   const groupOptions = options.filter(group =>
  //     group.label.toLocaleLowerCase().includes(string)
  //   );

  //   if (groupOptions) {
  //     for (const groupOption of groupOptions) {
  //       // Check if current option is in group
  //       const option = groupOption.options.find(opt => opt.value === value);
  //       if (option) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // };
  return (
    <div className={className}>
      <Select
        className="sta-custom-select"
        styles={SELECT_STYLES}
        menuPortalTarget={document.body}
        isMulti={isMulti}
        value={value}
        // filterOption={filterOption}
        onChange={value => {
          if (isMulti) {
            if (!!max && value.length > max) {
              return;
            }
          }
          onChange(value);
        }}
        // onKeyUp={onKeyUp}
        onInputChange={onInputChange}
        getOptionLabel={option => {
          if (isTaskSelect && option.label === "Normal") {
              return t("Medium");            
          } else {
            return (
              (CURRENT_LANG === "en" ? option.label : option.labelAr) ||
              option.label
            );
          }
        }}
        isDisabled={disabled}
        options={options}
        controlShouldRenderValue={controlShouldRenderValue}
        placeholder={t("Select Placeholder")}
        noOptionsMessage={() => t("No Options")}
        onError={onError}
        isClearable={isClearable}
        classNamePrefix={classNamePrefix}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  t: PropTypes.any,
  onInputChange: PropTypes.func,
  onError: PropTypes.func,
  isClearable: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  isTaskSelect: PropTypes.bool,
};
export default withTranslation()(CustomSelect);
