import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Approvals
import approvals from "./approvals/reducer";

import requests from "./requests/reducer";

import corres from "./correspondence/reducer";

import files from "./files/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  calendar,
  projects,
  tasks,
  contacts,
  Dashboard,
  approvals,
  requests,
  corres,
  files,
});

export default rootReducer;
