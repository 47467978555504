import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  ADD_FILE_COMMENT,
  GET_FILE_CATEGORIES,
  GET_FILES,
  GET_FILE_DETAILS,
  GET_RELATED_FILES,
  UPLOAD_FILES,
  GET_REFERENCED_FILES,
  CREATE_REFERENCED_FILES,
  DOWNLOAD_FILE,
  CREATE_FOLDER,
  DELETE_FILE,
  GET_DOCUMENT_APPROVALS,
  GET_DOCUMENT_CORRESPONDENCES, 
  GET_DOCUMENT_PROJECTS, 
  GET_DOCUMENT_REQUESTS, 
  GET_DOCUMENT_TASKS,
  GET_DOCUMENT_ORGANIZATIONS,

} from "./actionTypes";
import {
  getFilesSuccess,
  getFilesFail,
  getRelatedFilesSuccess,
  getRelatedFilesFail,
  getFileCategoriesSuccess,
  getFileCategoriesFail,
  getFileDetailsSuccess,
  getFileDetailsFail,
  addFileCommentSuccess,
  addFileCommentFail,
  uploadFilesSuccess,
  uploadFilesFail,
  getReferencedFilesSuccess,
  getReferencedFilesFail,
  createReferencedFilesSuccess,
  createReferencedFilesFail,
  createFolderSuccess,
  createFolderFail,
  downloadFileSuccess,
  deleteFileSuccess,
  deleteFileFail,
  getDocumentApprovalsSuccess,
  getDocumentApprovalsFail,
  getDocumentTasksSuccess,
  getDocumentTasksFail,
  getDocumentProjectsSuccess,
  getDocumentProjectsFail,
  getDocumentRequestsSuccess,
  getDocumentRequestsFail,
  getDocumentOrganizationsSuccess,
  getDocumentOrganizationsFail
} from "./actions";

import {
  EMPTY_GUID,
  ENTITIES,
  getGenericError,
  idContact,
  idRole,
  isInternal,
} from "common";
import {
  createDocumentReference,
  createFolder,
  deleteRecord,
  downloadFile,
  getComments,
  getFileCategories,
  getFiles,
  getReferencedFiles,
  getRelatedFiles,
  uploadFiles,
  upsertComments,
  getDocumentTasks,
  getDocumentProjects,
  getDocumentRequests,
  getDocumentApprovals,
  getDocumentOrganizations,
  getDocumentCorrespondences
} from "helpers/backend_helper";
import fileDownload from "js-file-download";
import { beginTheBar, endTheBar } from "common/utils";


function* fetchFiles() {
  try {
    const response = yield call(getFiles);
    yield put(getFilesSuccess(response.responseObject.lstDocuments));
  } catch (error) {
    if (error.response) {
      yield put(
        getFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getFilesFail(error.message));
    }
  }
}

function* fetchRelatedFiles({ payload, callback }) {
  try {
    let body = {
      entity: payload.entity,
      id: payload.idEntity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(getRelatedFilesSuccess(response.responseObject.lstDocuments));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getRelatedFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getRelatedFilesFail(error.message));
    }
  }
}

function* fetchCategories() {
  try {
    const response = yield call(getFileCategories);
    yield put(getFileCategoriesSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getFileCategoriesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getFileCategoriesFail(error.message));
    }
  }
}

function* fetchFileDetails({ payload }) {
  try {
    let body = {
      id: payload,
      entity: ENTITIES.DOCUMENT,
    };

    const response = yield call(getComments, body);
    yield put(getFileDetailsSuccess(response.responseObject.lstComments));
  } catch (error) {
    if (error.response) {
      yield put(
        getFileDetailsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getFileDetailsFail(error.message));
    }
  }
}

function* fetchAddComment({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertComments, body);
    yield put(addFileCommentSuccess(response.responseObject));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        addFileCommentFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(addFileCommentFail(error.message));
    }
  }
}

function* fetchUploadFile({ payload, callback }) {
  try {
    beginTheBar();
    let body = new FormData();
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    body.append("parameters", JSON.stringify(payload.request));

    payload.files.forEach(file => {
      body.append("files", file);
    });

    const response = yield call(uploadFiles, body, config);
    yield put(uploadFilesSuccess(response.responseObject));
    endTheBar();
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        uploadFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(uploadFilesFail(error.message));
    }
  }
}

function* fetchReferencedFiles({ payload, callback }) {
  try {
    let body = {
      entity: payload.entity,
      id: payload.idEntity,
    };
    const response = yield call(getReferencedFiles, body);
    yield put(
      getReferencedFilesSuccess(response.responseObject.lstDocumentReference)
    );

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getReferencedFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getReferencedFilesFail(error.message));
    }
  }
}

function* createReferencedFile({ payload, callback }) {
  try {
    beginTheBar();
    let body = payload;
    const response = yield call(createDocumentReference, body);
    yield put(createReferencedFilesSuccess(response.responseObject));
    endTheBar();
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        createReferencedFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(createReferencedFilesFail(error.message));
    }
  }
}

function* createFolderSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(createFolder, body);
    yield put(createFolderSuccess(response.responseObject));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        createFolderFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(createFolderFail(error.message));
    }
  }
}

function* downloadFileSaga({ payload }) {
  try {
    let body = { id: payload.id };
    let config = {
      responseType: "blob",
    };

    const response = yield call(downloadFile, body, config);
    fileDownload(response, payload.fileName);
    yield put(downloadFileSuccess());
  } catch (error) {
    if (error.response) {
      yield put(
        createFolderFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(createFolderFail(error.message));
    }
  }
}

function* deleteFileSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternalUser: !isInternal,
        operation: "post",
        idContact: idContact,
        idOrganizationCallingUser: EMPTY_GUID,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteFileSuccess(response.responseObject));

    callback && callback();
  } catch (error) {
    yield put(
      deleteFileFail(
        error?.response?.data?.responseStatus?.description || getGenericError()
      )
    );
  }
}


function* fetchDocumentApprovals({ payload }) {
  try {
    let body = {
      pageNumber: payload.pageNumber || 1,
      pageCount: payload.pageCount || 10,
    };
    const response = yield call(getDocumentApprovals , body);
    yield put(getDocumentApprovalsSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getDocumentApprovalsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
       yield put(getDocumentApprovalsFail(error.message));
    }
  }
}
function* fetchDocumentRequests({ payload }) {
  try {
    let body = {
      pageNumber: payload.pageNumber || 1,
      pageCount: payload.pageCount || 10,
    };
    const response = yield call(getDocumentRequests , body);
    yield put(getDocumentRequestsSuccess({
      data: response.responseObject?.data || [],
      pageNumber:body.pageNumber,
      totalRecordCount:response.responseObject?.totalRecordCount
    }));
  } catch (error) {
    if (error.response) {
      yield put(
        getDocumentRequestsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
     yield put(getDocumentRequestsFail(error.message));
    }
  }
  
}
function* fetchDocumentProjects({ payload }) {
  try {
    let body = {
      pageNumber: payload.pageNumber || 1,
      pageCount: payload.pageCount || 10,
    };
    const response = yield call(getDocumentProjects , body);
    yield put(getDocumentProjectsSuccess({
      data: response.responseObject?.data || [],
      pageNumber:body.pageNumber,
      totalRecordCount:response.responseObject?.totalRecordCount
    }));
  } catch (error) {
    if (error.response) {
      yield put(
        getDocumentProjectsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
     yield put(getDocumentProjectsFail(error.message));
    }
  }
}
function* fetchDocumentOrganizations({ payload }) {
  try {
    let body = {
      pageNumber: payload.pageNumber || 1,
      pageCount: payload.pageCount || 10,
      searchString:payload.searchString || ""
    };
    const response = yield call(getDocumentOrganizations , body);
   yield put(getDocumentOrganizationsSuccess({
      data: response.responseObject?.lstOrganizations || [],
      pageNumber:body.pageNumber,
      searchString:body.searchString,
      totalRecordCount:response.responseObject?.totalRecordCount
     }
   ));
  } catch (error) {
    if (error.response) {
      yield put(
        getDocumentOrganizationsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
     yield put(getDocumentOrganizationsFail(error.message));
    }
  }
}
function* fetchDocumentCorrespondences({ payload }) {
  try {
    let body = {
      pageNumber: payload.pageNumber || 1,
      pageCount: payload.pageCount || 10,
    };
    const response = yield call(getDocumentCorrespondences , body);
   // yield put(getFileCategoriesSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        // getFileCategoriesFail(
        //   error.response?.data?.responseStatus?.description || getGenericError()
        // )
      );
    } else {
     // yield put(getFileCategoriesFail(error.message));
    }
  }
}
function* fetchDocumentTasks({ payload }) {
  try {
    let body = {
      pageNumber: payload.pageNumber || 1,
      pageCount: payload.pageCount || 10,
    };
    const response = yield call(getDocumentTasks , body);
    yield put(getDocumentTasksSuccess({
      data: response.responseObject?.data || [],
      pageNumber:body.pageNumber,
      totalRecordCount:response.responseObject?.totalRecordCount

    }));
  } catch (error) {
    if (error.response) {
      yield put(
        getDocumentTasksFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
     yield put(getDocumentTasksFail(error.message));
    }
  }
}



function* filesSaga() {
  yield takeEvery(GET_FILES, fetchFiles);
  yield takeEvery(GET_RELATED_FILES, fetchRelatedFiles);
  yield takeEvery(GET_FILE_CATEGORIES, fetchCategories);
  yield takeEvery(GET_FILE_DETAILS, fetchFileDetails);
  yield takeEvery(ADD_FILE_COMMENT, fetchAddComment);
  yield takeEvery(UPLOAD_FILES, fetchUploadFile);
  yield takeEvery(GET_REFERENCED_FILES, fetchReferencedFiles);
  yield takeEvery(CREATE_REFERENCED_FILES, createReferencedFile);
  yield takeEvery(DOWNLOAD_FILE, downloadFileSaga);
  yield takeEvery(CREATE_FOLDER, createFolderSaga);
  yield takeEvery(DELETE_FILE, deleteFileSaga);
 // yield takeEvery(GET_DOCUMENT_APPROVALS, fetchDocumentApprovals);
  yield takeEvery(GET_DOCUMENT_REQUESTS, fetchDocumentRequests);
  yield takeEvery(GET_DOCUMENT_PROJECTS, fetchDocumentProjects);
  yield takeEvery(GET_DOCUMENT_ORGANIZATIONS, fetchDocumentOrganizations);
 // yield takeEvery(GET_DOCUMENT_CORRESPONDENCES, fetchDocumentCorrespondences);
  yield takeEvery(GET_DOCUMENT_TASKS, fetchDocumentTasks);
}

export default filesSaga;
