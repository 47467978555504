import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  CardTitle,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import {
  getProjectDetails,
  upsertProject,
  upsertStream,
  upsertMember,
  getProjectFiles,
  deleteProject,
  deleteStream,
  deleteMember,
} from "../../../../store/projects/actions";
import "./project-list.css";
import { toast } from "react-toastify";

import classnames from "classnames";
import ViewTeamModal from "./viewTeamModal";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  getBase64,
  getQueryVariable,
  handleCopyToClipboard,
  shortenString,
  sortCaretIcons,
} from "common/utils";
import Loading from "pages/Loading";
import {
  Colors,
  ENTITIES,
  getGenericError,
  getGlobalHeaderStyle,
  PERMISSIONS,
  TOAST_OPTIONS,
} from "common";
import StatusText from "components/Common/StatusText";
import Widget from "common/widget";
import CustomTable from "components/Common/CustomTable";
import CreateTaskModal from "pages/Tasks/create-task-modal";
import { getEntityLookups, upsertTask } from "store/actions";
import ProjectModal from "../ProjectModal";
import GanttChart from "pages/AllCharts/apex/ganttChart";
import StreamModal from "../StreamModal";
import TeamMemberModal from "../TeamMemberModal";
import FileManager from "pages/FileManager";
import ProgressBar from "../AllProjects/progressBar";
import EditIcon from "components/Common/EditIcon";
import DeleteIcon from "components/Common/DeleteIcon";
import Tippy from "@tippy.js/react";
import Markdown from "components/Common/Markdown";
import DeleteModal from "components/Common/DeleteModal";

// const liStyle = "project-li mb-3 pb-3 mt-3 pt-1";
const MAX_DISPLAYED_ICONS = 17;
class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    let updateEntities = filterPermittedEntities(props.permissions, "update");
    let readEntities = filterPermittedEntities(props.permissions, "read");
    let createEntities = filterPermittedEntities(props.permissions, "create");
    this.state = {
      file: null,
      files: [],
      customActiveTab: "1",
      teamModal: false,
      isExpanded: false,
      editModal: false,
      streamModal: false,
      memberModal: false,
      selectedMember: null,
      isFromStream: false,
      streamValues: null,
      updateEntities,
      readEntities,
      createEntities,
      path: [],
      showDeleteModal: false,
      showStreamDeleteModal: false,
      showTeamMemberDeleteModal: false,
      deleteMemberId: null,
      selectedStream: null,
      isEditStream: false,
      tasksColumns: [
        {
          text: this.props.t("ID"),
          dataField: "idTask",
          sort: true,
          sortCaret: sortCaretIcons,
          hidden: true,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "taskNumber",
          text: this.props.t("Task ID").toUpperCase(),
          formatter: (cellContent, task) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  to={"/task-details?id=" + task.idTask}
                  query={task}
                  className="text-primary text-decoration-underline"
                >
                  {`#${task.taskNumber}`}
                </Link>
              </h5>
            </>
          ),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          text: this.props.t("ُTask Name").toUpperCase(),
          dataField: "title",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "assignedTo.name",
          text: this.props.t("Assigned To"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "dueDate",
          text: this.props.t("Due Date Form"),
          sort: true,
          sortCaret: sortCaretIcons,
          formatter: (cellContent, task) => (
            <>
              <p className="mb-0">{moment(task.dueDate).format("DD/MM/YY")}</p>
            </>
          ),
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "status",
          text: this.props.t("StatusForm"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
          formatter: (cellContent, task) => (
            <StatusText
              text={!!task.status ? task.status.label?.toUpperCase() : ""}
            />
          ),
        },
      ],
      fullTasksColumns: [
        {
          text: this.props.t("ID"),
          dataField: "idTask",
          sort: true,
          sortCaret: sortCaretIcons,
          hidden: true,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "taskNumber",
          text: this.props.t("Task ID"),
          formatter: (cellContent, task) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  to={"/task-details?id=" + task.idTask}
                  query={task}
                  className="text-primary text-decoration-underline"
                >
                  {`#${task.taskNumber}`}
                </Link>
              </h5>
            </>
          ),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          text: this.props.t("Task Name"),
          dataField: "title",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "regarding.label",
          text: this.props.t("RegardingForm"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "assignedBy.name",
          text: this.props.t("Assigned By"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
          formatter: (cell, task) => (
            <Tippy
              content={this.props.t("View More")}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/individual-details?id=" + task.assignedBy?.id}
                  className="text-dark"
                >
                  {task.assignedBy?.name}
                </Link>
              </span>
            </Tippy>
          ),
        },
        {
          dataField: "assignedTo.name",
          text: this.props.t("Assigned To"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
          formatter: (cell, task) => (
            <Tippy
              content={this.props.t("View More")}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/individual-details?id=" + task.assignedTo?.id}
                  className="text-dark"
                >
                  {task.assignedTo?.name}
                </Link>
              </span>
            </Tippy>
          ),
        },
        {
          dataField: "createdOn",
          text: this.props.t("Date Created").toUpperCase(),
          sort: true,
          sortCaret: sortCaretIcons,
          formatter: (cellContent, task) => (
            <>
              <p className="mb-0">
                {moment(task.createdOn).format("DD/MM/YY")}
              </p>
            </>
          ),
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
        {
          dataField: "dueDate",
          text: this.props.t("Due Date Form"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
          formatter: (cellContent, task) => (
            <>
              <p className="mb-0">{moment(task.dueDate).format("DD/MM/YY")}</p>
            </>
          ),
        },
        {
          dataField: "status",
          text: this.props.t("StatusForm"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
          formatter: (cellContent, task) => (
            <StatusText
              text={!!task.status ? task.status.label?.toUpperCase() : ""}
            />
          ),
        },
      ],
      streamColumns: [
        {
          headerStyle: getGlobalHeaderStyle("5%"),
          align: "left",
          text: "",
          dataField: "action",
          sort: false,
          headerFormatter: (cellContent, stream) => (
            <i className="bx bx-dots-vertical-rounded font-size-20"></i>
          ),
          formatter: (cellContent, stream) => (
            <div
              style={{
                display: "flex",
              }}
            >
             {stream.canEdit && <EditIcon
                onClick={() => this.handleEditStream(stream)}
                className={`font-size-20 text-muted`}
              />}
              {stream.canDelete && <DeleteIcon
                onClick={() =>
                  this.setState({
                    selectedStream: stream,
                    showStreamDeleteModal: true,
                  })
                }
                className={`font-size-20 text-muted`}
              />}
            </div>
          ),
        },
        {
          text: this.props.t("Stream ID"),
          dataField: "idStream",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("15%"),
          align: "start",
          formatter: (cellContent, stream) => (
            <h5
              className="font-size-14 mb-0 day-pointer"
              onClick={() => stream.canEdit && this.handleEditStream(stream)}
            >
              {stream.canEdit ? (
                <a className="text-primary text-decoration-underline">
                  {`#${stream.streamNumber}`}
                </a>
              ) : (
                `#${stream.streamNumber}`
              )}
            </h5>
          ),
        },
        {
          text: this.props.t("Stream Name").toUpperCase(),
          dataField: "name",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("15%"),
          align: "start",
          formatter: (cellContent, stream) => (
            <p className="mb-0">
              {localStorage.getItem("I18N_LANGUAGE") === "ar"
                ? stream.nameAr
                : stream.name}
            </p>
          ),
        },
        {
          dataField: "status",
          text: this.props.t("StatusFormStream"),
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
          formatter: (cellContent, stream) => (
            <StatusText
              text={!!stream.status ? stream.status.label?.toUpperCase() : ""}
            />
          ),
        },
        {
          text: this.props.t("DescriptionForm"),
          dataField: "description",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("15%"),
          align: "start",
          formatter: (cell, stream) => (
            <Tippy
              content={formatLineBreaksInMarkdownFriendlyFormat(
                stream.description,
                true
              )}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <div className="mb-0">
                <Markdown>{shortenString(stream.description, 40)}</Markdown>
              </div>
            </Tippy>
          ),
        },

        {
          dataField: "progress",
          text: this.props.t("Progress").toUpperCase(),
          sort: true,
          formatter: (cellContent, stream) => (
            <ProgressBar data={stream.progress} />
          ),
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("15%"),
          align: "start",
        },
        {
          text: this.props.t("Stream Lead").toUpperCase(),
          dataField: "streamOwner.label",
          sort: true,
          sortCaret: sortCaretIcons,
          headerStyle: getGlobalHeaderStyle("15%"),
          align: "start",
          formatter: (cell, stream) => (
            <Link to={`/individual-details?id=${stream.streamOwner?.id}`}>
              {stream.streamOwner?.label}
            </Link>
          ),
        },
        {
          dataField: "dueDate",
          text: this.props.t("Deadline"),
          sort: true,
          sortCaret: sortCaretIcons,
          formatter: (cellContent, stream) => (
            <>
              <p className="mb-0">
                {moment(stream.endDate).format("DD/MM/YY")}
              </p>
            </>
          ),
          headerStyle: getGlobalHeaderStyle("10%"),
          align: "start",
        },
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleTask = this.toggleTask.bind(this);
    this.handleAddTask = this.handleAddTask.bind(this);
    this.handleTaskCreate = this.handleTaskCreate.bind(this);
    this.handleAddStream = this.handleAddStream.bind(this);
    this.handleProjectUpdate = this.handleProjectUpdate.bind(this);
    this.handleUpsertMember = this.handleUpsertMember.bind(this);
    this.toggleStream = this.toggleStream.bind(this);
    this.toggleMember = this.toggleMember.bind(this);
    this.ProjectTasksComponent = this.ProjectTasksComponent.bind(this);
    this.ProjectMembersComponent = this.ProjectMembersComponent.bind(this);
    this.FileManagerComponent = this.FileManagerComponent.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleEditStream = this.handleEditStream.bind(this);
    this.ProjectUpdateComponent = this.ProjectUpdateComponent.bind(this);
    this.RoadmapComponent = this.RoadmapComponent.bind(this);
    this.handleDeleteProject = this.handleDeleteProject.bind(this);
    this.handleDeleteStream = this.handleDeleteStream.bind(this);
    this.handleDeleteTeamMember = this.handleDeleteTeamMember.bind(this);
  }

  toggleExpand() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }
  handleEditStream(stream) {
    this.setState(
      {
        selectedStream: stream,
        isEditStream: true,
        streamValues: null,
      },
      () => {
        this.toggleStream();
      }
    );
  }

  async handleDeleteProject() {
    const { onDeleteProject, projectData } = this.props;
    try {
      const requestObject = {
        request: projectData.projectDetails.idProject,
        basicParameters: {
          entity: ENTITIES.PROJECT,
        },
      };

      await onDeleteProject(requestObject, () => {
        toast.success(
          this.props.t("SuccessDelete").replace("*", this.props.t("Project")),
          TOAST_OPTIONS
        );
        setTimeout(() => {
          window.location.href = "/projects-overview?tab=2";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleDeleteStream() {
    const { onDeleteStream, projectData } = this.props;
    const stream = this.state.selectedStream;
    try {
      if (!stream || !stream.idStream) throw new Error(getGenericError());
      const requestObject = {
        request: stream.idStream,

        basicParameters: {
          entity: ENTITIES.STREAM,
        },
      };

      await onDeleteStream(requestObject, () => {
        toast.success(
          this.props.t("SuccessDelete").replace("*", this.props.t("Stream")),
          TOAST_OPTIONS
        );
        this.setState({
          showStreamDeleteModal: false,
        });
        setTimeout(() => {
          window.location.href = projectData?.projectDetails?.idProject
            ? `/project-details?id=${projectData.projectDetails.idProject}`
            : "/projects-overview";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleDeleteTeamMember() {
    const { onDeleteTeamMember } = this.props;

    try {
      const requestObject = {
        request: this.state.deleteMemberId,
        basicParameters: {
          entity: ENTITIES.MEMBER,
        },
      };
      await onDeleteTeamMember(requestObject, () => {
        toast.success(
          this.props.t("SuccessRemove").replace("*", this.props.t("Member")),
          TOAST_OPTIONS
        );
        this.setState({
          deleteMemberId: null,
          showTeamMemberDeleteModal: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  RoadmapComponent = () => (
    <Col>
      <Card style={{ marginBottom: 5 }}>
        <CardBody>
          <GanttChart streams={this.props.projectData.streams.listStream} />
        </CardBody>
      </Card>
    </Col>
  );

  ProjectTasksComponent = projectData => (
    <Col>
      <Card style={{ marginBottom: 5 }}>
        <CardBody>
          <CardTitle>{this.props.t("Tasks")}</CardTitle>
        </CardBody>
        <CustomTable
          keyField={"idTask"}
          columns={this.state.tasksColumns}
          data={
            projectData.tasks.lstTasks.length > 5
              ? projectData.tasks.lstTasks.slice(0, 5)
              : projectData.tasks.lstTasks
          }
          defaultSortedField="title"
          hidePagination
        />
      </Card>
    </Col>
  );

  ProjectUpdateComponent = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <EditIcon
        onClick={this.toggleEdit}
        style={{
          backgroundColor: Colors.searchBG,
          borderRadius: 10,
          margin: 5,
        }}
      />
      {this.props.projectData.projectDetails.canDelete && (
        <DeleteIcon
          onClick={() => {
            this.setState({ showDeleteModal: true });
          }}
          style={{
            backgroundColor: Colors.searchBG,
            borderRadius: 10,
            margin: 5,
          }}
        />
      )}
    </div>
  );

  ProjectMembersComponent = projectData => (
    <Col
      lg={this.state.isExpanded ? "12" : "3"}
      style={{ paddingInlineStart: 0 }}
    >
      <Card>
        <CardBody>
          {this.state.createEntities.includes(ENTITIES.MEMBER) && (
            <CardTitle className="day-row day-justify-between">
              <h5 className="mb-0">{this.props.t("Team")}</h5>

              {(this.props?.permissions?.createPermissionLevel?.label !==
                PERMISSIONS.NONE ||
                this.props?.permissions?.day_project?.createPermissionLevel
                  ?.label !== null) && (
                <Button
                  outline
                  color="primary"
                  className="h-100"
                  onClick={this.toggleMember}
                >
                  {this.props.t("Add Member")}
                </Button>
              )}
            </CardTitle>
          )}

          <div className="day-column">
            {projectData.projectDetails?.lstTeamMember?.map(
              (item, index) =>
                index < MAX_DISPLAYED_ICONS && (
                  <div key={index} className="day-row day-justify-between mt-2">
                    <Tippy
                      content={this.props.t("View More")}
                      placement="top"
                      animation="scale-subtle"
                      theme="light"
                      arrow={false}
                      duration={200}
                      delay={[75, 0]}
                      distance={5}
                    >
                      <Link
                        to={`/individual-details?id=${item.member.id}`}
                        className="day-row"
                      >
                        {!!item.image ? (
                          <img
                            src={`data:image/png;base64,${item.image}`}
                            className="rounded-circle avatar-sm"
                          />
                        ) : (
                          <div className="avatar-sm">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-xs h1">
                              {item.member?.label?.charAt(0)}
                            </span>
                          </div>
                        )}
                        <div>
                          <p className="mb-0" style={{ marginInlineStart: 10 }}>
                            {item.member.label}
                          </p>

                          {!!item.role && !!item.role.label && (
                            <p
                              className="mb-0 text-muted font-size-10"
                              style={{ marginInlineStart: 10 }}
                            >
                              {item.role.label}
                            </p>
                          )}
                        </div>
                      </Link>
                    </Tippy>
                    {item.canDelete && (
                      <DeleteIcon
                        onClick={() => {
                          this.setState({ deleteMemberId: item.idTeamMember });
                          this.setState({ showTeamMemberDeleteModal: true });
                        }}
                        className={`font-size-20 text-muted`}
                      />
                    )}
                  </div>
                )
            )}
            {projectData.projectDetails.lstTeamMember?.length >
              MAX_DISPLAYED_ICONS && (
              <div className="day-row mt-2">
                <Button
                  style={{
                    outline: "none",
                    padding: "0px",
                    border: "0px",
                    borderRadius: "50px",
                    boxSizing: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={this.handleTeamClicks}
                >
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary h4">
                      {`+${
                        projectData.projectDetails?.lstTeamMember?.length -
                        MAX_DISPLAYED_ICONS
                      }`}
                    </span>
                  </div>
                </Button>

                <p
                  className="mb-0 text-decoration-underline"
                  onClick={this.handleTeamClicks}
                  style={{
                    marginInlineStart: 10,
                    color: Colors.lighBlue,
                    cursor: "pointer",
                  }}
                >
                  {this.props.t("View All")}
                </p>
              </div>
            )}
          </div>

          <TeamMemberModal
            modal={this.state.memberModal}
            toggle={this.toggleMember}
            handleSubmit={this.handleUpsertMember}
            onClosed={() => {
              this.setState({ selectedMember: null });
            }}
            member={this.state.selectedMember}
            isEdit={!!this.state.selectedMember}
            streams={projectData.streams?.listStream?.map(item => ({
              label: item.name,
              value: item.idStream,
            }))}
            entityLookups={this.props.entityLookups?.[ENTITIES.PROJECT]}
            team={projectData.projectDetails.lstTeamMember}
          />
        </CardBody>
      </Card>
    </Col>
  );

  FileManagerComponent = () => (
    <Col>
      <Card>
        <CardBody className="p-1">
          <FileManager
            showFilesOnly
            entityFiles={this.props.files}
            entityPayload={{
              entity: ENTITIES.PROJECT,
              idEntity: this.props.projectData.projectDetails.id,
            }}
            entityPath={[
              "Projects",
              this.props.projectData.projectDetails.titleEn,
            ]}
            height={"70vh"}
          />
        </CardBody>
      </Card>
    </Col>
  );

  handleUpsertMember(values) {
    const { onGetProjectDetails, projectData, onAddMember } = this.props;

    const isOwner =
      projectData.projectDetails.owner?.name === localStorage.getItem("name");

    /*if (!isOwner) {
      toast.error(this.props.t("Insufficient Permission"), TOAST_OPTIONS);
      this.toggleMember();
      return;
    }*/

    const member = {
      idProject: projectData.projectDetails.id,
      teamData: {
        idTeamMember: this.state.selectedMember
          ? this.state.selectedMember.idTeamMember
          : "00000000-0000-0000-0000-000000000000",
        member: {
          entityName: "",
          id: values.member.value,
          label: "",
        },
        role: {
          label: "",
          value: values.role.value,
        },
        stream: values.stream
          ? {
              entityName: "",
              id: values.stream.value,
              label: "",
            }
          : null,
      },
    };
    onAddMember(member, () => {
      onGetProjectDetails(projectData.projectDetails.id);
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Member")),
        TOAST_OPTIONS
      );
    });
    this.toggleMember();
  }

  async handleProjectUpdate(values) {
    const { onUpdateProject, onGetProjectDetails, projectData } = this.props;

    try {
      let image = "";
      if (this.state.file) {
        image = await getBase64(this.state.file);
      }

      const project = {
        idProject: projectData.projectDetails.id,
        projectNumber: projectData.projectDetails.projectNumber,
        actualEndDate: values?.actualEndDate || null,
        actualStartDate: values?.actualStartDate
          ? values?.actualStartDate
          : null,
        department: {
          entityName: "",
          id: values?.department?.value || " ",
          label: "",
        },
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        estimatedEndDate: values.estimatedEndDate,
        estimatedStartDate: values.estimatedStartDate,
        image: image,
        lstTeamMember: [],
        objective: values?.objective,
        organization: {
          entityName: "",
          id: values?.organization?.value,
          label: "",
        },
        owner: {
          image: "",
          email: "",
          id: values?.owner?.value,
          name: "",
        },
        priority: {
          label: "",
          value: +values?.priority?.value,
        },
        projectNumber: "",
        risk: {
          label: "",
          value: +values?.risk?.value,
        },
        stage: {
          label: "",
          value: +values?.stage?.value,
        },
        status: {
          label: "",
          value: +values?.status?.value,
        },
        titleAr: values?.projectNameAr,
        titleEn: values?.projectNameEn,
        overallBudget: values?.overallBudget || 0,
      };

      const updatedProject = onUpdateProject(project);

      setTimeout(() => {
        onGetProjectDetails(projectData.projectDetails.id);
        toast.success(
          this.props.t("SuccessUpdate").replace("*", this.props.t("Project")),
          TOAST_OPTIONS
        );
      }, 1000);
      this.toggleEdit();
    } catch (err) {
      console.error(err);
    }
  }
  handleTeamClicks = () => {
    this.setState({ task: "", isEdit: false });
    this.toggle();
  };

  handleAddStream(values) {
    const { projectData, onGetProjectDetails, onAddStream } = this.props;
    const stream = {
      idProject: projectData.projectDetails.id,
      streamData: {
        department: {
          entityName: "",
          id: values.department.value,
          label: "",
        },
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        endDate: values.endDate,
        idStream: this.state.isEditStream
          ? this.state.selectedStream.idStream
          : "00000000-0000-0000-0000-000000000000",
        name: values.nameEn,
        nameAr: values.nameAr,
        startDate: values.startDate,
        status: !!values.status?.value ? values.status : null,
        progress: values.progress,
        streamOwner: {
          entityName: "",
          id: values.owner?.value,
          label: "",
        },
        lstRelatedTasks: values.tasks.map(item => item.value),
      },
    };

    onAddStream(stream, () => {
      if (this.state.isEditStream) {
        toast.success(
          this.props.t("SuccessUpdate").replace("*", this.props.t("Stream")),
          TOAST_OPTIONS
        );
      } else {
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Stream")),
          TOAST_OPTIONS
        );
      }
      onGetProjectDetails(projectData.projectDetails.id);

      setTimeout(() => {
        this.setState({ isFromStream: false, streamValues: null });
      }, 100);
    });

    this.toggleStream();
  }

  handleTaskCreate(values) {
    const { onAddNewTask, projectData, onGetProjectDetails } = this.props;

    let task = {
      idTask: "00000000-0000-0000-0000-000000000000",
      taskNumber: "",
      title: values.name,
      regarding: {
        entityName: ENTITIES.PROJECT,
        label: "",
        id: projectData.projectDetails.id,
      },
      dueDate: values.dueDate,
      createdOn: "",
      priority: {
        label: "",
        value: +values.priority.value,
      },
      assignedBy: {
        name: "",
        id: null,
        image: "",
        email: "",
      },
      assignedTo: {
        name: "",
        id: values.assignedTo.value,
        image: "",
        email: "",
      },
      status: !!values.status?.value
        ? {
            label: "",
            value: +values.status.value,
          }
        : null,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      organization: null,
    };

    onAddNewTask(task, () => {
      onGetProjectDetails(projectData.projectDetails.id);
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Task")),
        TOAST_OPTIONS
      );
    });
    this.toggleTask();

    if (this.state.isFromStream) {
      this.toggleStream();
    }
  }

  handleAddTask = () => {
    this.setState(
      { task: "", isEdit: false, isFromStream: false, streamValues: null },
      () => {
        this.toggleTask();
      }
    );
  };

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  componentDidMount() {
    const { onGetProjectDetails, onGetProjectFiles, onGetLookups } = this.props;
    const projectId = getQueryVariable("id");
    const payload = {
      entity: ENTITIES.PROJECT,
      idEntity: projectId,
    };
    const tab = getQueryVariable("tab");
    if (tab && (tab === "1" || tab === "2")) {
      setTimeout(() => {
        this.toggleCustom(tab);
      }, 1000);
    }
    if (this.state.readEntities.includes(ENTITIES.DOCUMENT)) {
      onGetLookups(ENTITIES.PROJECT, () => {
        onGetProjectFiles(payload, () => {
          onGetProjectDetails(projectId);
        });
      });
    } else {
      onGetLookups(ENTITIES.PROJECT, () => {
        onGetProjectDetails(projectId);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        !this.props.loadingUpload &&
        prevProps.loadingUpload !== this.props.loadingUpload
      ) {
        const payload = {
          entity: ENTITIES.PROJECT,
          idEntity: this.props.projectData.projectDetails.id,
        };
        this.props.onGetProjectFiles(payload);
      }
    }
  }

  toggleTask() {
    this.setState(prevState => ({
      taskModal: !prevState.taskModal,
    }));
  }

  toggleStream() {
    this.setState(prevState => ({
      streamModal: !prevState.streamModal,
    }));
  }

  toggleMember() {
    this.setState(prevState => ({
      memberModal: !prevState.memberModal,
    }));
  }

  toggle() {
    this.setState(prevState => ({
      teamModal: !prevState.teamModal,
    }));
  }

  toggleEdit() {
    this.setState(prevState => ({
      editModal: !prevState.editModal,
    }));
  }

  render() {
    const {
      loading,
      projectData,
      loadingTasks,
      loadingUpload,
      loadingLookups,
      projectsPageNumber=1,
      projectStatusCode=''
    } = this.props;

    if (
      loading ||
      !projectData ||
      loadingTasks ||
      loadingUpload ||
      loadingLookups
    ) {
      return <Loading />;
    }

    const { updateEntities, readEntities, createEntities } = this.state;
    const delayedTasks = projectData.tasks?.lstTasks?.filter(
      task =>
        moment(task.dueDate).isBefore(moment(), "day") &&
        task.status?.label?.toLowerCase() !== "completed"
    );
    const dueTodayTasks = projectData.tasks?.lstTasks?.filter(
      task =>
        moment(task.dueDate).isSame(moment(), "day") &&
        task.status?.label?.toLowerCase() !== "completed"
    );

    const delayedApprovals = projectData.approvals?.listApprovals?.filter(
      approval =>
        moment(approval.duedate).isBefore(moment(), "day") &&
        approval.status?.label?.toLowerCase() !== "approved"
    );
    const dueTodayApprovals = projectData.approvals?.listApprovals?.filter(
      approval =>
        moment(approval.duedate).isSame(moment(), "day") &&
        approval.status?.label?.toLowerCase() !== "approved"
    );

    const delayedCorrespondences =
      projectData.correspondences?.listCorrespondences?.filter(
        corres =>
          moment(corres.duedate).isBefore(moment(), "day") &&
          corres.status?.label?.toLowerCase() !== "closed"
      );

    const dueTodayCorrespondences =
      projectData.correspondences?.listCorrespondences?.filter(
        corres =>
          moment(corres.duedate).isSame(moment(), "day") &&
          corres?.status?.label?.toLowerCase() !== "closed"
      );

    return (
      <React.Fragment>
        <ViewTeamModal
          modal={this.state.teamModal}
          team={projectData.projectDetails.lstTeamMember}
          toggle={this.toggle}
          onEditMember={member => {
            this.toggle();
            this.setState({ selectedMember: member }, () => {
              this.toggleMember();
            });
          }}
          onDeleteMember={id => {
            //
          }}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t("Projects")}
              breadcrumbItem={
                localStorage.getItem("I18N_LANGUAGE") === "ar"
                  ? projectData.projectDetails.titleAr
                  : projectData.projectDetails.titleEn
              }
              titleLinkTo={"/projects-overview?tab=2&page="+projectsPageNumber+"&status="+projectStatusCode}
            />
            <Nav
              tabs
              className="nav-tabs-custom"
              style={{
                display: "flex",
                flexGrow: 1,
                height: 50,
              }}
            >
              <NavItem
                style={{ display: "flex", height: "100%", flexWrap: "nowrap" }}
              >
                <NavLink
                  style={{ cursor: "pointer", backgroundColor: "transparent" }}
                  className={classnames({
                    active: this.state.customActiveTab === "1",
                    "text-muted": this.state.customActiveTab !== "1",
                  })}
                  onClick={() => {
                    this.toggleCustom("1");
                  }}
                >
                  <span className="font-size-18 text-nowrap">
                    {this.props.t("Overview")}
                  </span>
                </NavLink>
              </NavItem>

              {readEntities.includes(ENTITIES.STREAM) && (
                <NavItem
                  style={{
                    display: "flex",
                    height: "100%",
                    flexWrap: "nowrap",
                  }}
                >
                  <NavLink
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    className={classnames({
                      active: this.state.customActiveTab === "2",
                      "text-muted": this.state.customActiveTab !== "2",
                    })}
                    onClick={() => {
                      this.toggleCustom("2");
                    }}
                  >
                    <span className="font-size-18 text-nowrap">
                      {this.props.t("Streams")}
                    </span>
                  </NavLink>
                </NavItem>
              )}

              {readEntities.includes(ENTITIES.TASK) && (
                <NavItem
                  style={{
                    display: "flex",
                    height: "100%",
                    flexWrap: "nowrap",
                  }}
                >
                  <NavLink
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    className={classnames({
                      active: this.state.customActiveTab === "3",
                      "text-muted": this.state.customActiveTab !== "3",
                    })}
                    onClick={() => {
                      this.toggleCustom("3");
                    }}
                  >
                    <span className="font-size-18 text-nowrap">
                      {this.props.t("Tasks")}
                    </span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={this.state.customActiveTab}>
              <TabPane tabId="1">
                <Row>
                  {this.state.isExpanded && (
                    <Col lg="3" style={{ paddingInlineEnd: 0 }}>
                      <Col sm="12" style={{ minHeight: "61vh" }}>
                        <Card
                          className="card-hover"
                          style={{ marginBottom: 5, height: "100%" }}
                        >
                          <CardBody>
                            {updateEntities.includes(ENTITIES.PROJECT) &&
                              projectData.projectDetails.canEdit &&
                              this.ProjectUpdateComponent()}
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                left: "45%",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0px",
                                }}
                                onClick={this.toggleExpand}
                              >
                                <i
                                  className={`fas fa-angle-${
                                    this.state.isExpanded ? "up" : "down"
                                  } d-block font-size-18`}
                                  style={{ color: Colors.grey }}
                                ></i>
                              </button>
                            </div>
                            <div className="day-row day-justify-between day-w-100 mt-4">
                              <div style={{ flex: 0.5 }}>
                                {!!!projectData.projectDetails.image ? (
                                  <div className="avatar-lg">
                                    <span
                                      className="avatar-title rounded-circle"
                                      style={{ fontSize: 40 }}
                                    >
                                      {projectData.projectDetails.titleEn.includes(
                                        " "
                                      )
                                        ? projectData.projectDetails.titleEn
                                            .split(" ")[0]
                                            .charAt(0) +
                                          projectData.projectDetails.titleEn
                                            .split(" ")[1]
                                            .charAt(0)
                                        : projectData.projectDetails.titleEn.charAt(
                                            0
                                          ) +
                                          projectData.projectDetails.titleEn.charAt(
                                            1
                                          )}
                                    </span>
                                  </div>
                                ) : (
                                  <img
                                    src={`data:image/png;base64,${projectData.projectDetails.image}`}
                                    style={{
                                      width: 96,
                                      height: 96,
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                className="day-column day-flex-1 mt-2"
                                style={{
                                  marginInlineStart: 10,
                                }}
                              >
                                <h4>
                                  {localStorage.getItem("I18N_LANGUAGE") ===
                                  "ar"
                                    ? projectData.projectDetails.titleAr
                                    : projectData.projectDetails.titleEn}
                                </h4>
                                <p className="mb-0">
                                  {projectData.projectDetails.organization
                                    ? projectData.projectDetails.organization
                                        .label
                                    : ""}
                                </p>
                                <div className="day-row">
                                  <p className="mb-0 text-muted mx-2">
                                    {this.props.t("ID") + ":  "}
                                  </p>
                                  <p className="mb-0 mx-2">
                                    {projectData.projectDetails.projectNumber}
                                  </p>

                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "0px",
                                    }}
                                    onClick={() => {
                                      handleCopyToClipboard(
                                        projectData.projectDetails.projectNumber
                                      );
                                      toast.success(
                                        this.props.t("ID Copied"),
                                        TOAST_OPTIONS
                                      );
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-content-copy d-block font-size-18"
                                      style={{ color: Colors.grey }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="day-row day-justify-between day-w-100 mt-2 mb-2">
                              <p className="mb-0 text-muted">
                                {this.props.t("Project Owner").toUpperCase()}
                              </p>
                              <div className="day-row">
                                {!!!projectData.projectDetails.owner?.image ? (
                                  <div className="avatar-xs">
                                    <span
                                      className="avatar-title rounded-circle"
                                      style={{ fontSize: 12 }}
                                    >
                                      {projectData.projectDetails.owner?.name.includes(
                                        " "
                                      )
                                        ? projectData.projectDetails.owner?.name
                                            .split(" ")[0]
                                            .charAt(0) +
                                          projectData.projectDetails.owner?.name
                                            .split(" ")[1]
                                            .charAt(0)
                                        : projectData.projectDetails.owner?.name.charAt(
                                            0
                                          ) +
                                          projectData.projectDetails.owner?.name.charAt(
                                            1
                                          )}
                                    </span>
                                  </div>
                                ) : (
                                  <img
                                    src={`data:image/png;base64,${projectData.projectDetails.owner?.image}`}
                                    style={{
                                      width: 32,
                                      height: 32,
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                                <p
                                  className="mb-0"
                                  style={{ marginInlineStart: 10 }}
                                >
                                  {projectData.projectDetails.owner?.name}
                                </p>
                              </div>
                            </div>

                            <div className="mb-3">
                              <p className="mb-1 text-muted">
                                {this.props.t("Start Date").toUpperCase()}
                              </p>
                              <p className="mb-0">
                                {projectData.projectDetails.actualStartDate
                                  ? moment(
                                      projectData.projectDetails.actualStartDate
                                    ).format("DD-MM-YY")
                                  : projectData.projectDetails
                                      .estimatedStartDate
                                  ? moment(
                                      projectData.projectDetails
                                        .estimatedStartDate
                                    ).format("DD-MM-YY")
                                  : ""}
                              </p>
                            </div>

                            <div className="mb-3">
                              <p className="mb-1 text-muted">
                                {this.props.t("End Date").toUpperCase()}
                              </p>
                              <p className="mb-0 font-size-13">
                                {projectData.projectDetails.actualEndDate
                                  ? moment(
                                      projectData.projectDetails.actualEndDate
                                    ).format("DD-MM-YY")
                                  : projectData.projectDetails.estimatedEndDate
                                  ? moment(
                                      projectData.projectDetails
                                        .estimatedEndDate
                                    ).format("DD-MM-YY")
                                  : ""}
                              </p>
                            </div>

                            <div className="mb-3">
                              <p className="mb-1 text-muted">
                                {this.props.t("Key Risks Form").toUpperCase()}
                              </p>
                              <p className="mb-0 font-size-13">
                                {projectData.projectDetails.objective}
                              </p>
                            </div>

                            <div className="mb-3">
                              <p className="mb-1 text-muted">
                                {this.props.t("Department").toUpperCase()}
                              </p>
                              <p className="mb-0 font-size-13">
                                {projectData.projectDetails.department?.label}
                              </p>
                            </div>

                            <div className="mb-3">
                              <p className="mb-1 text-muted">
                                {this.props.t("Key Objectives").toUpperCase()}
                              </p>
                              <div className="mb-0 font-size-13">
                                <Markdown>
                                  {projectData.projectDetails.description}
                                </Markdown>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                  )}
                  <Col
                    lg={this.state.isExpanded ? "9" : "12"}
                    style={{
                      marginBottom: 5,
                      paddingInlineStart: this.state.isExpanded ? 5 : 12,
                    }}
                  >
                    <Row className="g-1">
                      {!this.state.isExpanded && (
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          lg={3}
                          xl={3}
                          xxl={3}
                          style={{
                            height: 234,
                          }}
                        >
                          <Card
                            className="card-hover"
                            style={{ marginBottom: 5, height: "100%" }}
                          >
                            <CardBody>
                              {updateEntities.includes(ENTITIES.PROJECT) &&
                                projectData.projectDetails.canEdit &&
                                this.ProjectUpdateComponent()}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: 0,
                                  left: "45%",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "0px",
                                  }}
                                  onClick={this.toggleExpand}
                                >
                                  <i
                                    className={`fas fa-angle-${
                                      this.state.isExpanded ? "up" : "down"
                                    } d-block font-size-18`}
                                    style={{ color: Colors.grey }}
                                  ></i>
                                </button>
                              </div>
                              <div className="day-row day-w-100 day-h-100 day-justify-around">
                                <div style={{ flex: 0.5, marginInlineEnd: 10 }}>
                                  {!!!projectData.projectDetails.image ? (
                                    <div className="avatar-md">
                                      <span
                                        className="avatar-title rounded-circle"
                                        style={{ fontSize: 30 }}
                                      >
                                        {projectData.projectDetails?.titleEn?.includes(
                                          " "
                                        )
                                          ? projectData.projectDetails?.titleEn
                                              .split(" ")[0]
                                              ?.charAt(0) +
                                            projectData.projectDetails?.titleEn
                                              .split(" ")[1]
                                              ?.charAt(0)
                                          : projectData.projectDetails?.titleEn?.charAt(
                                              0
                                            ) +
                                            projectData.projectDetails.titleEn?.charAt(
                                              1
                                            )}
                                      </span>
                                    </div>
                                  ) : (
                                    <img
                                      src={`data:image/png;base64,${projectData.projectDetails.image}`}
                                      style={{
                                        width: 96,
                                        height: 96,
                                        borderRadius: "50%",
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="day-column day-flex-1">
                                  <h4>
                                    {localStorage.getItem("I18N_LANGUAGE") ===
                                    "ar"
                                      ? projectData.projectDetails.titleAr
                                      : projectData.projectDetails.titleEn}
                                  </h4>
                                  <p className="mb-0">
                                    {projectData.projectDetails.organization
                                      ? projectData.projectDetails.organization
                                          .label
                                      : ""}
                                  </p>
                                  <div className="day-row">
                                    <p className="mb-0 text-muted">
                                      {this.props.t("ID") + ":  "}
                                    </p>
                                    <p className="mb-0 mx-2">
                                      {projectData.projectDetails.projectNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )}

                      <Col
                        xs={this.state.isExpanded ? "4" : "12"}
                        sm={this.state.isExpanded ? "4" : "12"}
                        md={this.state.isExpanded ? "4" : "6"}
                        lg={this.state.isExpanded ? "4" : "3"}
                        xl={this.state.isExpanded ? "4" : "3"}
                        xxl={this.state.isExpanded ? "4" : "3"}
                        style={{
                          height: 235,
                        }}
                      >
                        <Widget
                          title={this.props.t("Tasks")}
                          legendText1={this.props.t("Delayed")}
                          legendColor1={Colors.red}
                          legendValue1={delayedTasks?.length || 0}
                          legendText2={this.props.t("Due Today")}
                          legendColor2={Colors.yellow}
                          legendValue2={dueTodayTasks?.length || 0}
                          legendText3={""}
                          legendColor3={""}
                          legendValue3={0}
                        />
                      </Col>

                      <Col
                        xs={this.state.isExpanded ? "4" : "12"}
                        sm={this.state.isExpanded ? "4" : "12"}
                        md={this.state.isExpanded ? "4" : "6"}
                        lg={this.state.isExpanded ? "4" : "3"}
                        xl={this.state.isExpanded ? "4" : "3"}
                        xxl={this.state.isExpanded ? "4" : "3"}
                        style={{
                          height: 235,
                        }}
                      >
                        <Widget
                          title={this.props.t("Approvals")}
                          legendText1={this.props.t("Delayed")}
                          legendColor1={Colors.red}
                          legendValue1={delayedApprovals?.length || 0}
                          legendText2={this.props.t("Due Today")}
                          legendColor2={Colors.yellow}
                          legendValue2={dueTodayApprovals?.length || 0}
                          legendText3={""}
                          legendColor3={""}
                          legendValue3={0}
                        />
                      </Col>
                      <Col
                        xs={this.state.isExpanded ? "4" : "12"}
                        sm={this.state.isExpanded ? "4" : "12"}
                        md={this.state.isExpanded ? "4" : "6"}
                        lg={this.state.isExpanded ? "4" : "3"}
                        xl={this.state.isExpanded ? "4" : "3"}
                        xxl={this.state.isExpanded ? "4" : "3"}
                        style={{
                          height: 235,
                        }}
                      >
                        <Widget
                          title={this.props.t("Correspondence")}
                          legendText1={this.props.t("Delayed")}
                          legendColor1={Colors.red}
                          legendValue1={delayedCorrespondences?.length || 0}
                          legendText2={this.props.t("Due Today")}
                          legendColor2={Colors.yellow}
                          legendValue2={dueTodayCorrespondences?.length || 0}
                          legendText3={""}
                          legendColor3={""}
                          legendValue3={0}
                        />
                      </Col>
                    </Row>
                    {this.state.isExpanded && (
                      <>
                        <Col lg={"12"} style={{ marginTop: 5 }}>
                          {this.RoadmapComponent()}
                        </Col>
                        <Row>
                          <Col
                            lg={"8"}
                            style={{
                              paddingInlineEnd: this.state.isExpanded ? 0 : 12,
                            }}
                          >
                            {projectData.tasks.lstTasks.length > 0 &&
                              this.ProjectTasksComponent(projectData)}
                            {readEntities.includes(ENTITIES.DOCUMENT) &&
                              this.FileManagerComponent()}
                          </Col>
                          <Col
                            lg={"4"}
                            style={{
                              paddingInlineStart: this.state.isExpanded
                                ? 5
                                : 12,
                            }}
                          >
                            {this.ProjectMembersComponent(projectData)}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                  {!this.state.isExpanded && (
                    <Col lg={"9"} style={{ paddingInlineEnd: 5 }}>
                      {this.RoadmapComponent()}
                      {projectData.tasks.lstTasks.length > 0 &&
                        this.ProjectTasksComponent(projectData)}

                      {readEntities?.includes(ENTITIES.DOCUMENT) &&
                        this.FileManagerComponent()}
                    </Col>
                  )}

                  {!this.state.isExpanded &&
                    this.ProjectMembersComponent(projectData)}
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField={"idStream"}
                          columns={this.state.streamColumns}
                          data={projectData.streams.listStream}
                          showSearchAndAdd
                          addBtnText={this.props.t("Add Stream")}
                          placeholderText={this.props.t("Search Streams")}
                          handleAddClick={() => {
                            this.setState(
                              {
                                isEditStream: false,
                                selectedStream: null,
                                streamValues: null,
                              },
                              () => {
                                this.toggleStream();
                              }
                            );
                          }}
                          hideAddBtn={!createEntities.includes(ENTITIES.STREAM)}
                          defaultSortedField="name"
                        />
                        <StreamModal
                          modal={this.state.streamModal}
                          toggle={this.toggleStream}
                          handleSubmit={this.handleAddStream}
                          values={this.state.streamValues}
                          isEdit={this.state.isEditStream}
                          tasksOptions={projectData.tasks.lstTasks.map(
                            _task => ({
                              label: _task.title,
                              value: _task.idTask,
                            })
                          )}
                          stream={
                            this.state.selectedStream && {
                              ...this.state.selectedStream,
                              startDate: this.state.selectedStream.startDate
                                ? moment(
                                    this.state.selectedStream.startDate
                                  ).format("YYYY-MM-DD")
                                : "",
                              endDate: this.state.selectedStream.endDate
                                ? moment(
                                    this.state.selectedStream.endDate
                                  ).format("YYYY-MM-DD")
                                : "",
                              lstRelatedTasks:
                                this.state.selectedStream.lstRelatedTasks.map(
                                  item => ({ value: item })
                                ),
                            }
                          }
                          onCreateNewTask={values => {
                            this.setState(
                              { isFromStream: true, streamValues: values },
                              () => {
                                this.toggleStream();
                                this.toggleTask();
                              }
                            );
                          }}
                          entityLookups={
                            this.props.entityLookups?.[ENTITIES.PROJECT]
                          }
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="3">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CustomTable
                          keyField={"idTask"}
                          columns={this.state.fullTasksColumns}
                          data={projectData.tasks.lstTasks}
                          showSearchAndAdd
                          addBtnText={this.props.t("Add Task")}
                          placeholderText={this.props.t("Search Tasks")}
                          handleAddClick={this.handleAddTask}
                          hideAddBtn={!createEntities.includes(ENTITIES.TASK)}
                          defaultSortedField="createdOn"
                        />
                        <CreateTaskModal
                          modal={this.state.taskModal}
                          toggle={this.toggleTask}
                          handleSubmit={this.handleTaskCreate}
                          isEdit={false}
                          isFromStream={this.state.isFromStream}
                          onClosed={() => {
                            if (this.state.isFromStream) {
                              this.toggleStream();
                            }
                          }}
                          files={this.state.files}
                          onFilesAccepted={files => {
                            this.setState({ files });
                          }}
                          entityLookups={
                            this.props.entityLookups?.[ENTITIES.PROJECT]
                          }
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Container>
        </div>

        <DeleteModal
          onCloseClick={() => this.setState({ showDeleteModal: false })}
          onDeleteClick={this.handleDeleteProject}
          show={this.state.showDeleteModal}
        />

        <DeleteModal
          onCloseClick={() => this.setState({ showStreamDeleteModal: false })}
          onDeleteClick={this.handleDeleteStream}
          show={this.state.showStreamDeleteModal}
        />

        <DeleteModal
          onCloseClick={() =>
            this.setState({ showTeamMemberDeleteModal: false })
          }
          onDeleteClick={this.handleDeleteTeamMember}
          show={this.state.showTeamMemberDeleteModal}
          deleteButtonText={this.props.t("Modal Confirm Delete Team Member")}
        />

        <ProjectModal
          modal={this.state.editModal}
          isEdit={true}
          project={{
            projectNumber: projectData.projectDetails.projectNumber,
            owner: projectData.projectDetails.owner?.id,
            projectNameEn: projectData.projectDetails.titleEn,
            projectNameAr: projectData.projectDetails.titleAr,
            organization: projectData.projectDetails.organization?.id,
            department: projectData.projectDetails.department?.id,
            description: projectData.projectDetails.description,
            objective: projectData.projectDetails.objective,
            stage: projectData.projectDetails.stage?.value?.toString(),
            risk: projectData.projectDetails.risk?.value?.toString(),
            estimatedStartDate: projectData.projectDetails.estimatedStartDate
              ? moment(projectData.projectDetails.estimatedStartDate).format(
                  "YYYY-MM-DD"
                )
              : "",
            estimatedEndDate: projectData.projectDetails.estimatedEndDate
              ? moment(projectData.projectDetails.estimatedEndDate).format(
                  "YYYY-MM-DD"
                )
              : "",
            actualStartDate: projectData.projectDetails.actualStartDate
              ? moment(projectData.projectDetails.actualStartDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            actualEndDate: projectData.projectDetails.actualEndDate
              ? moment(projectData.projectDetails.actualEndDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            priority: projectData.projectDetails.priority?.value?.toString(),
            status: projectData.projectDetails.status
              ? projectData.projectDetails.status.value.toString()
              : "",
            image: projectData.projectDetails.image,
            progress: projectData.projectDetails.progress,
            overallBudget: projectData.projectDetails.overallBudget,
            canDelete: projectData.projectDetails.canDelete,
          }}
          toggle={this.toggleEdit}
          handleSubmit={this.handleProjectUpdate}
          entityLookups={this.props.entityLookups?.[ENTITIES.PROJECT]}
          file={this.state.file}
          onFilesAccepted={file => {
            this.setState({ file: file[0] });
          }}
        />
      </React.Fragment>
    );
  }
}

ProjectDetails.propTypes = {
  projects: PropTypes.array,
  className: PropTypes.any,
  onAddNewProject: PropTypes.func,
  t: PropTypes.any,
  onGetProjectDetails: PropTypes.func,
  projectData: PropTypes.any,
  loading: PropTypes.bool,
  loadingTasks: PropTypes.bool,
  onAddNewTask: PropTypes.func,
  onUpdateProject: PropTypes.func,
  onAddStream: PropTypes.func,
  onAddMember: PropTypes.func,
  onGetProjectFiles: PropTypes.func,
  files: PropTypes.array,
  loadingUpload: PropTypes.bool,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  onDeleteProject: PropTypes.func,
  onDeleteStream: PropTypes.func,
  onDeleteTeamMember: PropTypes.func,
  projectsPageNumber: PropTypes.any,
  projectStatusCode: PropTypes.string,
};

const mapStateToProps = ({ projects, tasks, files, Layout }) => ({
  projects: projects.projects,
  projectData: projects.projectData,
  loading: projects.loading,
  loadingTasks: tasks.loading,
  files: projects.files,
  loadingUpload: files.loading,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  projectsPageNumber:projects.projectsPageNumber,
  projectStatusCode:projects.projectStatusCode,
});

const mapDispatchToProps = dispatch => ({
  onGetProjectDetails: projectId => dispatch(getProjectDetails(projectId)),
  onAddNewTask: (newTask, callback) => dispatch(upsertTask(newTask, callback)),
  onUpdateProject: project => dispatch(upsertProject(project)),
  onAddStream: (stream, callback) => dispatch(upsertStream(stream, callback)),
  onAddMember: (member, callback) => dispatch(upsertMember(member, callback)),
  onGetProjectFiles: (payload, callback) =>
    dispatch(getProjectFiles(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onDeleteProject: (payload, callback) =>
    dispatch(deleteProject(payload, callback)),
  onDeleteStream: (payload, callback) =>
    dispatch(deleteStream(payload, callback)),
  onDeleteTeamMember: (payload, callback) =>
    dispatch(deleteMember(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ProjectDetails)));
