import React from "react";
import moment from "moment";

import styles from "./CurrentDateDisplay.module.scss";

export interface CurrentDateDisplayProps {
  // `onMonthChange` will be implemented later when requirements are clarified/amended
  // Track issue here: https://dev.azure.com/digitalservicesme/Day/_workitems/edit/27567/
  onMonthChange?: (previous: boolean) => void;
  onCalendarClicked: () => void;
}

const CurrentDateDisplay: React.FC<CurrentDateDisplayProps> = ({
  onCalendarClicked,
}) => {
  const currentDate = new Date();

  return (
    <>
      <div className={styles.container} onClick={onCalendarClicked}>
        <div className={styles.calendarWidget}>
          <div className={styles.currentMonth}>{ localStorage.getItem("I18N_LANGUAGE") ==="ar" ? moment()?.locale("ar").format("MMM") : moment().locale("en").format("MMM")}</div>
          <div className={styles.currentDay}>{localStorage.getItem("I18N_LANGUAGE") ==="ar" ? moment().locale("ar").format("DD") :  moment().locale("en").format("DD")}</div>
        </div>
        <div className={styles.currentDate}>{ localStorage.getItem("I18N_LANGUAGE") ==="ar" ? moment()?.locale("ar").format("MMMM D") : moment()?.locale("en").format("MMMM D")}</div>
      </div>
    </>
  );
};

export default CurrentDateDisplay;
