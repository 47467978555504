/* TASKS */
export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAIL = "GET_TASKS_FAIL";

export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
export const GET_ALL_TASKS_FAIL = "GET_ALL_TASKS_FAIL";

export const GET_TASK_DETAILS = "GET_TASK_DETAILS";
export const GET_TASK_DETAILS_SUCCESS = "GET_TASK_DETAILS_SUCCESS";
export const GET_TASK_DETAILS_FAIL = "GET_TASK_DETAILS_FAIL";

// export const GET_COMMENTS_IN_TASKS = "GET_COMMENTS_IN_TASKS";
// export const GET_COMMENTS_IN_TASKS_SUCCESS = "GET_COMMENTS_IN_TASKS_SUCCESS";
// export const GET_COMMENTS_IN_TASKS_FAIL = "GET_COMMENTS_IN_TASKS_FAIL";

export const UPSERT_TASK = "UPSERT_TASK";
export const UPSERT_TASK_SUCCESS = "UPSERT_TASK_SUCCESS";
export const UPSERT_TASK_FAIL = "UPSERT_TASK_FAIL";

export const UPSERT_COMMENTS = "UPSERT_COMMENTS";
export const UPSERT_COMMENTS_SUCCESS = "UPSERT_COMMENTS_SUCCESS";
export const UPSERT_COMMENTS_FAIL = "UPSERT_COMMENTS_FAIL";

export const GET_TASK_FILES = "GET_TASK_FILES";
export const GET_TASK_FILES_SUCCESS = "GET_TASK_FILES_SUCCESS";
export const GET_TASK_FILES_FAIL = "GET_TASK_FILES_FAIL";

export const GET_ALL_TASKS_OVERVIEW = "GET_ALL_TASKS_OVERVIEW";
export const GET_ALL_TASKS_OVERVIEW_FAIL = "GET_ALL_TASKS_OVERVIEW_FAIL";
export const GET_ALL_TASKS_OVERVIEW_SUCCESS = "GET_ALL_TASKS_OVERVIEW_SUCCESS";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";

export const GET_TASKS_DASHBOARD="GET_TASKS_DASHBOARD"
export const GET_TASKS_DASHBOARD_SUCCESS="GET_TASKS_DASHBOARD_SUCCESS"
export const GET_TASKS_DASHBOARD_FAIL="GET_TASKS_DASHBOARD_FAIL"
