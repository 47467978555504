import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
  Row,
  Col,
  Card,
  CardBody,
  // Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Label,
} from "reactstrap";
// ToastContainer,
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";
import arLocale from "@fullcalendar/core/locales/ar";
import enLocale from "@fullcalendar/core/locales/en-gb";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

//css
import "@fullcalendar/bootstrap/main.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getEvents,
  addNewEvent,
  updateEvent,
  getAppointments,
  upsertAgenda,
  upsertAppointments,
  getCategories,
  getCheck,
  getEntityLookups,
  uploadFiles,
} from "../../store/actions";
import { withTranslation } from "react-i18next";
import "./calendar-style.css";
import Loading from "pages/Loading";
import Chevron from "../../components/Common/Chevron";

import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  randomEventColorGroup,
} from "common/utils";
import Calendar from "react-calendar";
import { withRouter } from "react-router-dom";

const hashMapOfAppointments = new Map();

import CreateAppointmentModal from "./create-appointment";
// import { ENTITIES, getDaysArray } from "common";
import { ENTITIES, getDaysArray, TOAST_OPTIONS } from "common";
import moment from "moment";
import { toast } from "react-toastify";
import CurrentDateDisplay from "./CurrentDateDisplay/CurrentDateDisplay";

class Calender extends Component {
  constructor(props) {
    super(props);

    const data = {
      startAt: moment().subtract(1, "year").toISOString(),
      endAt: moment().add(1, "year").toISOString(),
      idAppointment: "00000000-0000-0000-0000-000000000000",
    };
    this.handleDateClick = this.handleDateClick.bind(this);
    this.calendarComponentRef = React.createRef();
    let createEntities = filterPermittedEntities(props.permissions, "create");

    this.state = {
      files: [],
      calendarWeekends: true,
      modal: false,
      modalcategory: false,
      isDragBind: false,
      event: {},
      coloredAppointments: [],
      daysWithAppointments: [],
      startDate: new Date(),
      isCalendarCreate: createEntities.includes(ENTITIES.APPOINTMENT),
      appointmentData: data,
    };

    this.toggle = this.toggle.bind(this);
    this.togglecategory = this.togglecategory.bind(this);
    this.handleValidEventSubmitcategory =
      this.handleValidEventSubmitcategory.bind(this);
    this.handleAppointmentCreate = this.handleAppointmentCreate.bind(this);
    this.handleAppointmentClicks = this.handleAppointmentClicks.bind(this);
    this.handleColors = this.handleColors.bind(this);
    // category
    // this.onDrag = this.onDrag.bind(this);
    // this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount = () => {
    const { onGetAppointments, onGetLookups } = this.props;
    onGetLookups(ENTITIES.APPOINTMENT, () => {
      onGetAppointments(this.state.appointmentData);
    });
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { event, modal } = this.state;

    if (prevState.modal !== modal && !modal && !isEmpty(event)) {
      setTimeout(() => {
        this.setState({ event: {}, isEdit: false });
      }, 500);
    }

    if (prevProps !== this.props) {
      if (this.props.appointments) {
        let coloredAppointments = this.props.appointments.map((_app, index) => {
          // fetch and save a random color for this current appointment in loop
          const colorGroup = randomEventColorGroup();
          const appointmentColors = {
            color: colorGroup.fontColor,
            textColor: colorGroup.fontColor,
            // borderColor: color.toUpperCase(),
            backgroundColor: colorGroup.backgroundColor + "4D",
          };
          let projectId = _app.projectId;
          // If project has no regarding it will default to Other
          if (!projectId) {
            const DEFAULT_ID = "Other";
            const DEFAULT_NAME = this.props.t("Other");
            if (!hashMapOfAppointments.has(DEFAULT_ID)) {
              hashMapOfAppointments.set(DEFAULT_ID, appointmentColors);
              return {
                ..._app,
                projectName: DEFAULT_NAME,
                title: _app.title,
                projectId: DEFAULT_ID,
                checked: true,
                ...appointmentColors,
              };
            } else {
              let colors = hashMapOfAppointments.get(DEFAULT_ID);
              return {
                ..._app,
                ...colors,
                projectName: DEFAULT_NAME,
                title: _app.title,
                projectId: DEFAULT_ID,
                checked: true,
              };
            }
          }
          // Check if project exists in hashmap
          if (!hashMapOfAppointments.has(projectId)) {
            hashMapOfAppointments.set(projectId, appointmentColors);

            return {
              ..._app,
              projectName: _app.projectName,
              title: _app.title,
              checked: true,
              ...appointmentColors,
            };
          } else {
            let colors = hashMapOfAppointments.get(projectId);
            return {
              ..._app,
              ...colors,
              projectName: _app.projectName,
              title: _app.title,
              checked: true,
            };
          }
        });

        let daysWithAppointments = [].concat(
          ...coloredAppointments.map(event =>
            getDaysArray(new Date(event.start), new Date(event.end))
          )
        );

        this.setState({
          coloredAppointments,
          daysWithAppointments,
        });
      }
    }
  }

  /**
   * Handling the modal state
   */
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  togglecategory() {
    this.setState(prevState => ({
      modalcategory: !prevState.modalcategory,
    }));
  }

  handleAppointmentCreate(values) {
    const { onAddNewAppointment, onGetAppointments, onUploadFiles } =
      this.props;

    let attendees = values.attendees.map(item => ({
      entityName: ENTITIES.INDIVIDUALS,
      id: item.value,
      label: "",
      labelAr: "",
    }));
    let optionalAttendees = values.optionalAttendees.map(item => ({
      entityName: ENTITIES.INDIVIDUALS,
      id: item.value,
      label: "",
      labelAr: "",
    }));
    let Appointment = {
      isCancelAppointment: false,
      dataAppointments: {
        description: formatLineBreaksInMarkdownFriendlyFormat(
          values.description
        ),
        idAppointment: "00000000-0000-0000-0000-000000000000",
        isAllDayEvent: values.checkEvent,
        location: values.location,
        lstAgenda: [],
        minutesOfMeeting: values.minutesOfMeeting,
        idOrganization: values.idOrganization?.value,
        regarding: {
          entityName: values.regarding.entityName,
          id: values.regarding.value,
        },
        requiredAttendees: attendees,
        optionalAttendees,
        startAt: values.startAt,
        endAt: values.endAt,
        subject: values.subject,
        duration: values.duration,
      },
    };

    onAddNewAppointment(Appointment, id => {
      if (this.state.files.length > 0) {
        const payload = {
          request: {
            updates: [],
            allowExternalUsers: false,
            allowDownloads: true,
            allowComments: true,
            createReference: true,
            path: `appointments/${id}`,
            parent: "00000000-0000-0000-0000-000000000000",
          },
          files: this.state.files,
        };
        onUploadFiles(payload, () => {
          onGetAppointments(this.state.appointmentData);
        });
      } else {
        onGetAppointments(this.state.appointmentData);
      }
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Appointment")),
        TOAST_OPTIONS
      );
    });

    this.toggle();
  }

  /**
   * Handling date click on calendar
   */
  handleDateClick = arg => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    this.setState({ selectedDay: modifiedData });
    this.toggle();
  };

  /**
   * Handling click on event on calendar
   */
  handleEventClick = arg => {
    this.props.history.push(
      "/calendar-details?id=" + arg.event?._def?.publicId
    );
  };

  handleValidEventSubmitcategory = (event, values) => {
    const { onAddNewEvent } = this.props;

    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values["title_category"],
      start: new Date(),
      className: values.event_category
        ? values.event_category + " text-white"
        : "bg-danger text-white",
    };
    // save new event
    onAddNewEvent(newEvent);
    this.togglecategory();
  };

  handleColors = (e, item) => {
    let index = this.state.coloredAppointments.findIndex(
      _item => _item.projectId === item.projectId
    );
    if (index >= 0) {
      let _coloredAppointments = [...this.state.coloredAppointments].map(
        _item => {
          if (_item.projectId === item.projectId) {
            return {
              ..._item,
              checked: e.target.checked,
              display: e.target.checked ? "block" : "none",
            };
          }
          return {
            ..._item,
          };
        }
      );

      this.setState({ coloredAppointments: _coloredAppointments });
    }
  };
  handleAppointmentClicks = () => {
    this.setState({ isEdit: false });
    this.toggle();
  };

  /**
   * On category darg event
   */
  onDrag = event => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  onDrop = event => {
    const date = event["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const { onAddNewEvent } = this.props;
    const draggedEl = event.draggedEl;
    const modifiedData = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: modifiedDate,
      className: draggedEl.className,
    };
    onAddNewEvent(modifiedData);
  };

  render() {
    const { events, categories, appointments, loading, popUp, loadingLookups } =
      this.props;
    const { onAddNewEvent, onUpdateEvent, isFromHomePage, loadingUpload } =
      this.props;
    const {
      isEdit,
      event,
      selectedDay,
      coloredAppointments,
      isCalendarCreate,
    } = this.state;
    const keys = Array.from(hashMapOfAppointments.keys()).concat();
    if (loading || !appointments || loadingLookups || loadingUpload) {
      return <Loading />;
    }
    const currentDate = new Date();
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={
            !isFromHomePage
              ? {}
              : { padding: 0, marginInlineStart: -6, marginInlineEnd: -6 }
          }
        >
          {!isFromHomePage && (
            <MetaTags>
              <title>{this.props.t("Calendar/Portal")}</title>
            </MetaTags>
          )}
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            {!isFromHomePage && (
              <Breadcrumbs
                title="STA"
                breadcrumbItem={this.props.t("Calendar")}
              />
            )}
            <Row className="g-1">
              {!isFromHomePage && (
                <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
                  <Row className="g-1">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={12}
                      xl={12}
                      xxl={12}
                      className="mini-calendar"
                    >
                      <Card>
                        <CardBody>
                          <CurrentDateDisplay
                            onCalendarClicked={() => {
                              this.setState({
                                startDate: currentDate,
                              });
                            }}
                          />
                          <Calendar
                            dateFormat={"EE"}
                            events={coloredAppointments}
                            onChange={date => {
                              this.setState({
                                startDate: date,
                              });
                              this.calendarComponentRef.current
                                .getApi()
                                .gotoDate(new Date(date));
                            }}
                            defaultActiveStartDate={currentDate}
                            onActiveStartDateChange={action => {
                              this.calendarComponentRef.current
                                .getApi()
                                .gotoDate(action.activeStartDate);
                            }}
                            minDate={
                              new Date(this.state.appointmentData.startAt)
                            }
                            maxDate={new Date(this.state.appointmentData.endAt)}
                            // This calendar type has Sunday as the first day of the week
                            calendarType="Hebrew"
                            // Invoke doesDateHaveAnAppointment to apply highlight dot
                            // only on dates containing appointments
                            tileClassName={({ date }) =>
                              this.doesDateHaveAnAppointment(date) &&
                              "react-calendar__tile--highlight"
                            }
                            // Format weekdays on the calendar as "S", "M", etc
                            formatShortWeekday={(_, date) =>
                              localStorage.getItem("I18N_LANGUAGE") === "ar"
                                ? moment(date)
                                    .locale("ar")
                                    .format("dd")
                                    .slice(0, 1)
                                : moment(date)
                                    .locale("en")
                                    .format("dd")
                                    .slice(0, 1)
                            }
                            formatMonth={(locale, date) =>
                              localStorage.getItem("I18N_LANGUAGE") === "ar"
                                ? moment(date).locale("ar").format("MMM")
                                : moment(date).locale("en").format("MMM")
                            }
                            // Display full month on the calendar nav pane only
                            navigationLabel={({ date }) =>
                              localStorage.getItem("I18N_LANGUAGE") === "ar"
                                ? moment(date).locale("ar").format("MMMM")
                                : moment(date).locale("en").format("MMMM")
                            }
                            nextLabel={<Chevron position="next" />}
                            prevLabel={<Chevron position="previous" />}
                            // Set these to null to hide the ">>" and "<<" icons
                            next2Label={null}
                            prev2Label={null}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={12}
                      xl={12}
                      xxl={12}
                      className="mini-calendar"
                    >
                      <Card>
                        <CardBody className="mini-calendar-categories">
                          <p className="mainTitle mx-2">
                            {this.props.t("Categories")}
                          </p>
                          <div
                            className="scrollbar"
                            style={{
                              overflowY: "scroll",
                              height: "271px",
                            }}
                          >
                            {keys.map((item, index) => {
                              let _item = coloredAppointments.find(
                                _app => _app.projectId == item
                              );

                              if (!item || !_item) {
                                return <div key={index}></div>;
                              }
                              return (
                                <div
                                  className="form-check mb-3 mx-3"
                                  key={index}
                                >
                                  <input
                                    checked={_item.checked}
                                    style={{
                                      backgroundColor: _item.color,
                                      borderColor: "transparent",
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id={index}
                                    onChange={e => {
                                      this.handleColors(e, _item);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={index}
                                  >
                                    {_item.projectName}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col
                xs={!isFromHomePage ? "12" : "12"}
                sm={!isFromHomePage ? "12" : "12"}
                md={!isFromHomePage ? "12" : "12"}
                lg={!isFromHomePage ? "7" : "12"}
                xl={!isFromHomePage ? "8" : "12"}
                xxl={!isFromHomePage ? "9" : "12"}
              >
                <Card>
                  <CardBody>
                    {/* fullcalendar control */}
                    <FullCalendar
                      initialView={
                        !isFromHomePage ? "dayGridMonth" : "timeGridDay"
                      }
                      ref={this.calendarComponentRef}
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        listPlugin,
                        interactionPlugin,
                        timeGridPlugin,
                        momentPlugin,
                        momentTimezonePlugin,
                      ]}
                      nowIndicator
                      locale={
                        localStorage.getItem("I18N_LANGUAGE") === "ar"
                          ? arLocale
                          : enLocale
                      }
                      slotDuration={"00:60:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        start: !isFromHomePage
                          ? "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                          : "title",
                        center: "",
                        end: isCalendarCreate ? "myCustomButton" : "",
                      }}
                      customButtons={{
                        myCustomButton: {
                          text: this.props.t("Create Meeting"),
                          click: this.handleAppointmentClicks,
                        },
                      }}
                      buttonText={{
                        dayGridMonth: this.props.t("Month"),
                        timeGridWeek: this.props.t("Week"),
                        timeGridDay: this.props.t("Day"),
                        listWeek: this.props.t("List"),
                      }}
                      // Display only upto 3 events
                      dayMaxEvents={3}
                      eventMaxStack={1}
                      moreLinkContent={({ shortText }) => {
                        return `${shortText} meetings`;
                      }}
                      views={{
                        timeGridDay: {
                          titleFormat:
                            localStorage.getItem("I18N_LANGUAGE") === "ar"
                              ? ""
                              : "[Today:] dddd, D[th] MMMM",
                        },
                        timeGridWeek: {
                          // dayMaxEvents: 1,
                        },
                        listWeek: {
                          listDaySideFormat: "DD MMMM YYYY",
                        },
                      }}
                      businessHours={{
                        startTime: "9:00", // a start time (10am in this example)
                        endTime: "18:00", // an end time (6pm in this example)
                      }}
                      slotLabelFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        meridiem: false,
                        hour12: false,
                      }}
                      eventTimeFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        meridiem: false,
                        hour12: false,
                      }}
                      dayHeaders={!isFromHomePage ? true : false}
                      allDaySlot={false}
                      events={coloredAppointments}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dateClick={this.handleDateClick}
                      eventClick={this.handleEventClick}
                      drop={this.onDrop}
                      height="auto"
                      // scrollTime={time}
                      slotMinTime={"9:00"}
                      slotMaxTime={"18:00"}
                      timeZone={"Asia/Riyadh"}
                    />

                    <Modal
                      isOpen={this.state.modalcategory}
                      toggle={this.togglecategory}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.togglecategory} tag="h4">
                        {this.props.t("Add a category")}
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            title_category:
                              (this.state.event &&
                                this.state.event.title_category) ||
                              "",
                            event_category:
                              (this.state.event &&
                                this.state.event.event_category) ||
                              "Danger",
                          }}
                          validationSchema={Yup.object().shape({
                            title_category: Yup.string().required(
                              "Please Enter Your Event Name"
                            ),
                            event_category: Yup.string().required(
                              "Please Select Your Category"
                            ),
                          })}
                          onSubmit={values => {
                            const newEvent = {
                              id: Math.floor(Math.random() * 100),
                              title: values["title_category"],
                              start: new Date(),
                              className: values.event_category
                                ? values.event_category + " text-white"
                                : "bg-danger text-white",
                            };
                            // save new event
                            onAddNewEvent(newEvent);
                            this.togglecategory();
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form>
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {this.props.t("Event Name")}
                                    </Label>
                                    <Field
                                      name="title_category"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.title_category &&
                                        touched.title_category
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="title_category"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {this.props.t("Select Category")}
                                    </Label>
                                    <Field
                                      name="event_category"
                                      as="select"
                                      className={
                                        "form-control" +
                                        (errors.event_category &&
                                        touched.event_category
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="bg-danger">
                                        {this.props.t("Danger")}
                                      </option>
                                      <option value="bg-success">
                                        {this.props.t("Success")}
                                      </option>
                                      <option value="bg-primary">
                                        {this.props.t("Primary")}
                                      </option>
                                      <option value="bg-danger">
                                        {this.props.t("Info")}
                                      </option>
                                      <option value="bg-dark">
                                        {this.props.t("Dark")}
                                      </option>
                                      <option value="bg-warning">
                                        {this.props.t("Warning")}
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="event_category"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="day-text-end">
                                    <button
                                      type="button"
                                      className="btn btn-light me-2"
                                      onClick={this.togglecategory}
                                    >
                                      {this.props.t("Close")}
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary save-event"
                                    >
                                      {this.props.t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <CreateAppointmentModal
          modal={this.state.modal}
          toggle={this.toggle}
          handleSubmit={this.handleAppointmentCreate}
          isEdit={false}
          files={this.state.files}
          onFilesAccepted={files => {
            this.setState({ files });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.APPOINTMENT]}
        />
      </React.Fragment>
    );
  }

  doesDateHaveAnAppointment(date) {
    return this.state.daysWithAppointments
      .map(day => moment(day).format("MMM Do YY"))
      .includes(moment(date).format("MMM Do YY"));
  }
}

Calender.propTypes = {
  categories: PropTypes.any,
  className: PropTypes.string,
  events: PropTypes.array,
  onAddNewEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
  onGetEvents: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  isFromHomePage: PropTypes.bool,
  t: PropTypes.any,
  appointments: PropTypes.array,
  onGetAppointments: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.any,
  onAddNewAppointment: PropTypes.func,
  permissions: PropTypes.any,
  popUp: PropTypes.bool,
  onGetCheckData: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  permissions: PropTypes.any,
  entityLookups: PropTypes.any,
  onUploadFiles: PropTypes.func,
  loadingUpload: PropTypes.bool,
};

const mapStateToProps = ({ calendar, Layout, files }) => ({
  events: calendar.events,
  categories: calendar.categories,
  appointments: calendar.appointments,
  loading: calendar.loading,
  permissions: Layout.permissions,
  popUp: calendar.popUp,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  loadingUpload: files.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onGetAppointments: payload => dispatch(getAppointments(payload)),
  onAddNewAgenda: payload => dispatch(upsertAgenda(payload)),
  onUpdateAgenda: payload => dispatch(upsertAgenda(payload)),
  onAddNewAppointment: (payload, callback) =>
    dispatch(upsertAppointments(payload, callback)),
  onGetCheckData: payload => dispatch(getCheck(payload)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Calender)));
