import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { getProjectDetail, getAllProjects , getAllActiveProjects , getProjectsDashboard } from "store/projects/actions";
import OverviewChart from "./overviewChart";
import { withTranslation } from "react-i18next";
import AllProjectsTable from "./AllProjects/allProjectsTable";
import { BarColors, CURRENT_LANG, ENTITIES ,Colors } from "common";

import Loading from "pages/Loading";
import ActiveProjects from "./AllProjects/activeProjects";
import { getPlannedProgress, getQueryVariable  , randomColor } from "common/utils";
import { getEntityLookups } from "store/actions";
import moment from "moment";
import "./projects-overview.scss";
import ProjectDashboard from "./ProjectDashboard"
export const PAGE_SIZE = 10;

class ProjectsOverview extends Component {
  constructor(props) {
    super(props);

    // read tab-number from query string
    let selectedTab="1";
    let selectedPage=1;
    let selectedStatus=''
    const lastTab = getQueryVariable("tab");
    const lastPage = getQueryVariable("page") ;
    const lastStatus = getQueryVariable("status") ;
    if (lastTab && (lastTab === "1" || lastTab === "2")) {
      selectedTab=lastTab
      if(lastTab === "2"){
        selectedPage=lastPage?lastPage:1
        selectedStatus=lastStatus?lastStatus:''

      }
    }

    this.state = {
      taskOverview: [
        { label: "Overdue", value: 90, color: "#FF3B30" },
        { label: "Open", value: 80, color: "#3DD5B0" },
        { label: "In Progress", value: 70, color: "#F4BD33" },
        { label: "Completed", value: 40, color: "#389741" },
      ],
      approvalsOverview: [
        { label: "Rejected", value: 90, color: "#FF3B30" },
        { label: "Returned", value: 80, color: "#3DD5B0" },
        { label: "Pending", value: 70, color: "#F4BD33" },
        { label: "Approved", value: 40, color: "#389741" },
      ],
      customActiveTab: selectedTab ,
      options: null,
      series: null,
      largeScreen: false,
      currentPageIndex:selectedPage,
      selectedStatusCode:selectedStatus
    };
    this.getProgressColor = this.getProgressColor.bind(this);
    this.resize = this.resize.bind(this);
    this.handleTopProjectsChart = this.handleTopProjectsChart.bind(this);

 

  }


  toggleCustom(tab , selectedStatusCode='') {
  if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
   }
   if(tab==1){
    this.props.onGetAllActiveProjects({
      pageNumber:1,
      pageCount: PAGE_SIZE,
      searchString: ""
    });
    this.props.onGetProjectsDashboard();
   }
   else if(tab==2){
    this.props.onGetAllProjects({
      statusCode: selectedStatusCode ||  this.props.projectStatusCode,
      pageNumber: 1,
      pageCount: PAGE_SIZE,
      searchString: ""
    });
   }
   
  }

  getProgressColor(actual, planned) {
    if (actual > planned) {
      return BarColors.green;
    } else if (actual < planned) {
      return BarColors.red;
    } else {
      return BarColors.blue;
    }
  }
  generateColors() {
    let color = randomColor();

    return {
      backgroundColor: color + "1a",
      color: color,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (!!this.props.allProjects && Array.isArray(this.props.allProjects)) {
        this.handleTopProjectsChart();
      }
    }
  }

  handleTopProjectsChart() {
    let options = {
      labels: [],
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          formatter: value => value.toFixed(0) + "%",
        },
      },
      stroke: {
        colors: ["transparent"],
        width: 3,
      },
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            let id = config.w.config.labels[config.dataPointIndex];
            this.props.history.push(`/project-details?id=${id}`);
          },

          dataPointMouseEnter: function(event) {
            if(event?.path){
              event.path[0].style.cursor = "pointer";
            }
            
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: value => value.toFixed(0) + "%",
        offsetY: -2,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        forceNiceScale: false,
        max: 100,
        labels: {
          formatter: value => value.toFixed(0) + "%",
        },
        categories: [],
      },
      colors: ["#657DFF", "#07DF9A"],
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 550,
          options: {
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
            },
            stroke: {
              colors: ["transparent"],
              width: 3,
            },
            chart: {
              type: "bar",
              toolbar: {
                show: !1,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: true,
              formatter: value => value.toFixed(0) + "%",
              offsetY: -2,
            },
            grid: {
              yaxis: {
                lines: {
                  show: !1,
                },
              },
            },
            xaxis: {
              forceNiceScale: false,
              max: 100,
              labels: {
                show: false,
                formatter: value => value.toFixed(0) + "%",
              },
              categories: [],
            },
            colors: [Colors.blue, Colors.green],
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
    };
    let series = [
      {
        name: this.props.t("Planned").toUpperCase(),
        data: [],
      },
      {
        name: this.props.t("Actual").toUpperCase(),
        data: [],
      },
    ];

    // 1. sort allProjects based on progrss 
    let chartData= this.props.dashboardData?.lstProjects || []
    if(chartData && chartData.length>0){
      let sortedProjects = JSON.parse(JSON.stringify(chartData));
      sortedProjects = sortedProjects.sort((a, b) => b.progress - a.progress);
  
      let length =
        sortedProjects.length > (this.state.largeScreen ? 15 : 10)
          ? this.state.largeScreen
            ? 15
            : 10
          : sortedProjects.length;
  
      for (let i = 0; i < length; i++) {
        let item = sortedProjects[i];
        if(CURRENT_LANG === "en" && item.titleEn || CURRENT_LANG !== "en"  && item.titleAr){
            series[0].data.push(
              getPlannedProgress(item.actualStartDate, item.estimatedEndDate)
            );
            series[1].data.push(item.progress);
      
            options.xaxis.categories.push(
              CURRENT_LANG === "en" ? item.titleEn : item.titleAr
            );
            options.responsive[0].options.xaxis.categories.push(
              CURRENT_LANG === "en" ? item.titleEn : item.titleAr
            );
            options.labels.push(item.idProject);
          }
    }
      options.responsive[0].options.colors = options.colors;
      this.setState({ options, series });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    const { onGetAllActiveProjects, onGetLookups  , onGetProjectsDashboard , onGetAllProjects} = this.props;

    onGetLookups(ENTITIES.PROJECT, () => {
      if(this.state.customActiveTab==1){
      onGetAllActiveProjects({
        pageNumber: this.state.currentPageIndex,
        pageCount: PAGE_SIZE,
        searchString: ""
      });
      onGetProjectsDashboard();
      } else {
      onGetAllProjects({
          statusCode: this.state.selectedStatusCode || this.props.projectStatusCode ||  "100000001",
          pageNumber: this.state.currentPageIndex,
          pageCount: PAGE_SIZE,
          searchString: ""
        });
      }
 
    });
    window.history.pushState(null, null, window.location.pathname); // clear query params
  }

  resize() {
    if (window.innerWidth >= 1400 && !this.state.largeScreen) {
      this.setState({ largeScreen: true });
      this.handleTopProjectsChart();
    } else if (window.innerWidth < 1400 && this.state.largeScreen) {
      this.setState({ largeScreen: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

// toDo: call getAllProject and set the status to "delayed" , with empty search
  onAllProjectsClick = (statusId) =>{
    this.toggleCustom("2" , statusId);
    }
    getIDOfArProjectStage=(text)=>{
      if(text == 'البدء') return "100000000"
      if(text == 'التخطيط') return "100000001"
      if(text == 'التنفيذ') return "100000002"
      if(text == 'الإغلاق') return "100000003"
      return text;
    }
     isArabic=(text) =>{
      const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
      return pattern.test(text);
  
  }

  validateDate = passedDate => {
    /* in case the user fills a date as "DD/mm/YY", it should be passed to the api as "YYYY-mm-DD" */
const isValidDate = moment(passedDate, 'DD/MM/YY', true).isValid(); 
const isValidDate2= moment(passedDate, 'mm/DD/YY', true).isValid(); 
const isValidDate3= moment(passedDate, 'DD/MM/YYYY', true).isValid(); 
const isValidDate4= moment(passedDate, 'M/DD/YYYY', true).isValid(); 
if(isValidDate || isValidDate2 || isValidDate3 || isValidDate4){
  const currectDateFormat= isValidDate? "DD/MM/YY" :isValidDate2? "MM/DD/YY" :isValidDate3? "DD/MM/YYYY" : "M/DD/YYYY"
  const dateSearch = moment(passedDate,currectDateFormat).locale("en").format('YYYY-MM-DD');
    return dateSearch  
} else {
  return "Invalid date"
}
}
    getProjectsWithSearch=(searchString)=>{
      let searchValue=searchString
     
      const { onGetAllProjects, projectStatusCode } = this.props;

      /* check if its a date: */
      let dateSearch = this.validateDate(searchString);
      if(dateSearch !== "Invalid date"){
        searchValue=dateSearch
      }
      /** in case user search for request type in arabic, then we should pass the typeID to the api instead of name  */
     else if(this.isArabic(searchString)){
        searchValue=this.getIDOfArProjectStage(searchString)
      }
      onGetAllProjects({
        statusCode: projectStatusCode,
        pageNumber: 1,
        pageCount: PAGE_SIZE,
        searchString: searchValue
      });
   
    }

    handlePageChange = pageIndex => {
      const { onGetAllProjects, projectStatusCode ,searchString} = this.props;
      onGetAllProjects({
        statusCode: projectStatusCode,
        pageNumber: pageIndex,
        pageCount: PAGE_SIZE,
        searchString:searchString
      });

    };
    handlePageChangeActive = pageIndex => {
      const {onGetAllActiveProjects} = this.props;
      onGetAllActiveProjects({
        pageNumber: pageIndex,
        pageCount: PAGE_SIZE,
      });

    };

    updateProjectStatus = status =>{
      const { onGetAllProjects } = this.props;
      onGetAllProjects({
        statusCode: status.value,
        pageNumber: 1,
        pageCount: PAGE_SIZE,
        searchString: ''
      });
    }
  

  render() {

    const { loading, loadingLookups , allActiveProjects=[] , dashboardData} = this.props;
    const { options, series , customActiveTab} = this.state;
    const loadingDashboard= customActiveTab=='1' && (!options || !series || !dashboardData )
   // if (loading || loadingLookups || !options || !series || !dashboardData )  {
      if (loading || loadingLookups || loadingDashboard )  {
      return <Loading />;
    }

      return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("STA-Portal")}</title>
          </MetaTags>
          <Container fluid>
           {/** nav items */}
            <Nav tabs className="nav-tabs-custom row-margin-top-nav">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", backgroundColor: "transparent" }}
                  className={classnames({
                    active: this.state.customActiveTab === "1",
                    "text-muted": this.state.customActiveTab !== "1",
                  })}
                  onClick={() => {
                    this.toggleCustom("1");
                  }}
                >
                  <span className="font-size-16 text-nowrap">
                    {this.props.t("Overview")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", backgroundColor: "transparent" }}
                  className={classnames({
                    active: this.state.customActiveTab === "2",
                    "text-muted": this.state.customActiveTab !== "2",
                  })}
                  onClick={() => {
                    this.toggleCustom("2");
                  }}
                >
                  <span className="font-size-16 text-nowrap">
                    {this.props.t("All Projects")}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.customActiveTab}>
              <TabPane tabId="1">
             
               <ProjectDashboard
                 data={dashboardData}
                 onAllProjectsClick={(statusId)=>this.onAllProjectsClick(statusId)}
                 t={this.props.t}/>

               {/** OverviewChart + ActiveProjects table  */}
                <Row className="g-1 d-flex">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={6}
                    className="g-1 flex-grow-1 h-100"
                  >
                    <OverviewChart
                      options={options || {}}
                      series={series || []}
                      title={
                        this.state.largeScreen
                          ? this.props.t("Top * Projects").replace("*", 15)
                          : this.props.t("Top * Projects").replace("*", 10)
                      }
                      largeScreen={this.state.largeScreen}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={6}
                    className="g-1 flex-grow-1 h-100 marginTopActiveProjects"
                  >
                    <Card className="h-100 mb-0 p-card">
                      <CardBody>
                        <CardTitle className="mb-4 day-dark-text-bold">
                          {this.props.t("Active Projects")}
                        </CardTitle>
                         <ActiveProjects 
                          allProjects={allActiveProjects || []}  
                          totalDataSize={this.props.totalActiveDataSize}
                          activePageNumber={this.props.activePageNumber}
                          handlePageChange={this.handlePageChangeActive}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


              </TabPane>
              <TabPane tabId="2">
                {/** prpjects with status and search filters */}
               <AllProjectsTable 
                allProjectsData={this.props.allProjects}
                totalDataSize={this.props.totalDataSize}
                listStatus={this.props.entityLookups?.day_project?.listStatus || []}
                searchString={this.props.searchString} 
                onSearchSubmit={(searchString)=>this.getProjectsWithSearch(searchString)}
                currentPageIndex={this.props.projectsPageNumber}
                handlePageChange={this.handlePageChange}
                updateProjectStatus={(status)=>this.updateProjectStatus(status)}
                /> 
              </TabPane>
            </TabContent>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ProjectsOverview.propTypes = {
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
  onGetAllProjects: PropTypes.func,
  onGetAllActiveProjects: PropTypes.func,
  onGetProjectsDashboard: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.any,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  allProjects: PropTypes.any,
  allActiveProjects: PropTypes.any,
  history: PropTypes.any,
  entityLookups: PropTypes.any,
  projectStatusCode:PropTypes.any,
  totalDataSize:PropTypes.any,
  searchString:PropTypes.string,
  totalActiveDataSize:PropTypes.any,
  projectsPageNumber:PropTypes.any,
  dashboardData:PropTypes.any,
  activePageNumber:PropTypes.any,
};
const mapStateToProps = ({ projects, Layout }) => ({
  allProjects: projects.allProjects,
  allActiveProjects:projects.allActiveProjects,
  loading: projects.loading,
  error: projects.error,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  projectStatusCode:projects.projectStatusCode,
  totalDataSize:projects.totalRecordCount,
  totalActiveDataSize:projects.activeTotalRecordCount,
  searchString:projects.searchString,
  projectsPageNumber:projects.projectsPageNumber,
  activePageNumber:projects.activePageNumber,
  dashboardData:projects.dashboardData
});
const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
  onGetAllProjects: (payload, callback) => dispatch(getAllProjects(payload, callback)),
  onGetAllActiveProjects: (payload, callback) => dispatch(getAllActiveProjects(payload, callback)),
  onGetLookups: (payload, callback) => dispatch(getEntityLookups(payload, callback)),
  onGetProjectsDashboard: () => dispatch(getProjectsDashboard()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ProjectsOverview)));
