import React from "react";
import PropTypes from "prop-types";
import { Colors } from "common";

const MemberItem = ({ item, onClick, isAttendee, isAvailable }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        backgroundColor: Colors.searchBG,
        marginInlineEnd: 5,
        marginTop: 5,
      }}
    >
      <i
        className="far fa-minus-square"
        style={{
          color: Colors.red,
          marginInlineEnd: 5,
          cursor: "pointer",
        }}
        onClick={onClick}
      ></i>
      <p className="mb-0">{item.label}</p>
      {isAttendee &&
        (isAvailable ? (
          <i
            className="far fa-check-circle"
            style={{
              color: Colors.green,
              marginInlineStart: 5,
              cursor: "pointer",
            }}
          ></i>
        ) : (
          <i
            className="mdi mdi-close-circle"
            style={{
              color: Colors.red,
              marginInlineStart: 5,
              cursor: "pointer",
            }}
          ></i>
        ))}
    </div>
  );
};

MemberItem.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.any,
  checkFlag: PropTypes.bool,
  isAttendee: PropTypes.bool,
  isAvailable: PropTypes.bool,
};
export default MemberItem;
