import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LegendText from "components/Common/LegendText";
import { Card, CardBody, Col, Popover, Row } from "reactstrap";
import Knob from "pages/AllCharts/knob/knob";
import { Colors } from "common";
import CustomTable from "components/Common/CustomTable";

const ProjectHealth = ({ t, overview, projectsColumns }) => {
  const [projectStatusModal, setProjectStatusModal] = useState();

  const getProjectHealthTime = (actual = 0, planned = 0, time = 0) => {
    if (actual == planned) {
      return t("On Schedule").replace("*", time);
    } else if (actual > planned) {
      return t("Ahead Schedule").replace("*", time);
    } else {
      return t("Behind Schedule").replace("*", time);
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between mb-4">
          <p className="day-top-projects">{t("Project Health")}</p>
          <LegendText
            color={Colors.yellow}
            displayText={t("Overall Status").toUpperCase()}
            text={overview.overAllStatus}
          />
        </div>
        <div className="d-flex justify-content-between mb-4">
          <div className="day-w-55">
            <Row className="border-bottom mx-0 my-2">
              <Col
                lg="6"
                className="p-0 day-project-health-title"
                id={"projectStatusModal"}
                onMouseEnter={() => {
                  // this.setState({ projectStatusModal: true });
                  setProjectStatusModal(true);
                }}
                onMouseLeave={() => {
                  // this.setState({ projectStatusModal: false });
                  setProjectStatusModal(false);
                }}
              >
                <Popover
                  placement="left" //bottom-right-top-left
                  isOpen={projectsColumns?.length && projectStatusModal}
                  target="projectStatusModal"
                  style={{
                    width: 600,
                    height: 200,
                  }}
                >
                  <Card>
                    <CardBody>
                      {projectsColumns?.length && (
                        <CustomTable
                          sizePerPage={4}
                          columns={projectsColumns}
                          data={overview.taskTooltips}
                          defaultSortedField="employee"
                        />
                      )}
                    </CardBody>
                  </Card>
                </Popover>

                {t("Current Phase")}
              </Col>
              <Col lg="6" className="p-0 day-project-health-subTitle">
                {overview.projectHealth.currentPhase}
              </Col>
            </Row>
            <Row className="border-bottom mx-0 my-3">
              <Col lg="6" className="p-0 day-project-health-title">
                {t("Tasks")}
              </Col>
              <Col lg="6" className="p-0 day-project-health-subTitle">
                {t("Tasks To Be Completed").replace(
                  "*",
                  overview.projectHealth.totalOverDueTasks
                )}
              </Col>
            </Row>
            <Row className="border-bottom mx-0 my-3">
              <Col lg="6" className="p-0 day-project-health-title">
                {t("Time")}
              </Col>
              <Col lg="6" className="p-0 day-project-health-subTitle">
                {getProjectHealthTime(
                  overview.projectHealth.progress,
                  overview.projectHealth.planned,
                  overview.projectHealth.time
                )}
              </Col>
            </Row>
            <Row className="border-bottom mx-0 my-3">
              <Col lg="6" className="p-0 day-project-health-title">
                {t("Workload")}
              </Col>
              <Col lg="6" className="p-0 day-project-health-subTitle">
                {t("Tasks Overdue").replace(
                  "*",
                  overview.projectHealth.totalOverDueTasks
                )}
              </Col>
            </Row>
            <Row className=" mx-0 my-3">
              <Col lg="12" className="p-0 day-project-health-subTitle">
                {t("upcoming tasks")}
              </Col>
            </Row>
          </div>
          <div className="mx-4">
            <Knob
              value={overview.projectHealth.progress}
              total={100}
              bgColor={"#4C4D4C80"}
              fgColor={"#228C2C"}
              knobColor={"#228C2C"}
              thickness={0.09}
              lineCap="round"
              onChange={() => {}}
            />
          </div>
        </div>
        <div style={{ display: "flex", overflowX: "auto", rowGap: "35px" }}>
          {overview.streams.map((item, index) => (
            <React.Fragment key={"_streams_" + index}>
              <div className="day-column day-justify-between">
                <p
                  className="day-card-title text-muted my-2"
                  style={{ minWidth: "85px" }}
                >
                  {item.name}
                </p>
                <p className="knob-card-number">{`${item.progress}%`}</p>
              </div>

              {index < overview.streams.length - 1 && (
                <div
                  style={{
                    marginInlineEnd: "20px",
                    marginInlineStart: "8px",
                    borderRight: "1px solid #4C4D4C40",
                    height: 100,
                    opacity: "0.3",
                  }}
                ></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

ProjectHealth.propTypes = {
  t: PropTypes.any,
  overview: PropTypes.any,
  projectsColumns: PropTypes.any,
};

export default withTranslation()(ProjectHealth);
