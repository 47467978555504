import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  DELETE_STREAM,
  DELETE_STREAM_SUCCESS,
  DELETE_STREAM_FAIL,
  GET_PROJECTS_OVERVIEW,
  GET_PROJECTS_OVERVIEW_FAIL,
  GET_PROJECTS_OVERVIEW_SUCCESS,
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAIL,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_FAIL,
  GET_PROJECT_DETAILS_SUCCESS,
  UPSERT_PROJECT,
  UPSERT_PROJECT_SUCCESS,
  UPSERT_PROJECT_FAIL,
  UPSERT_STREAM,
  UPSERT_STREAM_SUCCESS,
  UPSERT_STREAM_FAIL,
  UPSERT_MEMBER,
  UPSERT_MEMBER_SUCCESS,
  UPSERT_MEMBER_FAIL,
  GET_PROJECT_FILES,
  GET_PROJECT_FILES_SUCCESS,
  GET_PROJECT_FILES_FAIL,
  GET_ALLPROJECTSOVERVIEW,
  GET_ALLPROJECTSOVERVIEW_SUCCESS,
  GET_ALLPROJECTSOVERVIEW_FAIL,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  UPDATE_PROJECT_STATUS_CODE,
  GET_ALL_ACTIVE_PROJECTS,
  GET_ALL_ACTIVE_PROJECTS_SUCCESS,
  GET_ALL_ACTIVE_PROJECTS_FAIL,
  GET_PROJECT_DASHBOARD,
  GET_PROJECT_DASHBOARD_SUCCESS,
  GET_PROJECT_DASHBOARD_FAIL,
  GET_ALL_PROJECT_DASHBOARD,
  GET_ALL_PROJECT_DASHBOARD_SUCCESS,
  GET_ALL_PROJECT_DASHBOARD_FAIL,
  GET_PROJECTS_OPTIONS,
  GET_PROJECTS_OPTIONS_SUCCESS,
  GET_PROJECTS_OPTIONS_FAIL
} from "./actionTypes";

export const getProjectptions = payload => ({
  type: GET_PROJECTS_OPTIONS,
  payload: payload,
});
export const getProjectptionsSuccess = payload => ({
  type: GET_PROJECTS_OPTIONS_SUCCESS,
  payload: payload,
});
export const getProjectptionsFail = payload => ({
  type: GET_PROJECTS_OPTIONS_FAIL,
  payload: payload,
});
export const getProjects = () => ({
  type: GET_PROJECTS,
});

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
});

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
});

export const getProjectDetail = projectId => ({
  type: GET_PROJECT_DETAIL,
  projectId,
});

export const getProjectDetailSuccess = projectDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projectDetails,
});

export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
});

export const addNewProject = project => ({
  type: ADD_NEW_PROJECT,
  payload: project,
});

export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
});

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
});

export const updateProject = project => ({
  type: UPDATE_PROJECT,
  payload: project,
});

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
});

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
});

export const deleteProject = (project, callback) => ({
  type: DELETE_PROJECT,
  payload: project,
  callback,
});

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
});

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
});

export const deleteStream = (stream, callback) => ({
  type: DELETE_STREAM,
  payload: stream,
  callback,
});

export const deleteStreamSuccess = stream => ({
  type: DELETE_STREAM_SUCCESS,
  payload: stream,
});

export const deleteStreamFail = error => ({
  type: DELETE_STREAM_FAIL,
  payload: error,
});

export const getProjectsOverview = payload => ({
  type: GET_PROJECTS_OVERVIEW,
  payload,
});

export const getProjectsOverviewSuccess = project => ({
  type: GET_PROJECTS_OVERVIEW_SUCCESS,
  payload: project,
});

export const getProjectsOverviewFail = error => ({
  type: GET_PROJECTS_OVERVIEW_FAIL,
  payload: error,
});

export const getAllProjects = (payload, callback) => ({
  type: GET_ALL_PROJECTS,
  payload,
  callback,
});

export const getAllActiveProjects = (payload, callback) => ({
  type: GET_ALL_ACTIVE_PROJECTS,
  payload,
  callback,
});
export const getAllActiveProjectsSuccess = project => ({
  type: GET_ALL_ACTIVE_PROJECTS_SUCCESS,
  payload: project,
});

export const getAllActiveProjectsFail = error => ({
  type: GET_ALL_ACTIVE_PROJECTS_FAIL,
  payloadd: error,
});

export const getAllProjectsSuccess = project => ({
  type: GET_ALL_PROJECTS_SUCCESS,
  payload: project,
});

export const getAllProjectsFail = error => ({
  type: GET_ALL_PROJECTS_FAIL,
  payloadd: error,
});

export const getProjectDetails = id => ({
  type: GET_PROJECT_DETAILS,
  payload: id,
});

export const getProjectDetailsSuccess = details => ({
  type: GET_PROJECT_DETAILS_SUCCESS,
  payload: details,
});

export const getProjectDetailsFail = error => ({
  type: GET_PROJECT_DETAILS_FAIL,
  payload: error,
});

export const upsertProject = (payload, callback) => ({
  type: UPSERT_PROJECT,
  payload,
  callback,
});

export const upsertProjectSuccess = payload => ({
  type: UPSERT_PROJECT_SUCCESS,
  payload,
});

export const upsertProjectFail = error => ({
  type: UPSERT_PROJECT_FAIL,
  payload: error,
});

export const upsertStream = (payload, callback) => ({
  type: UPSERT_STREAM,
  payload,
  callback,
});

export const upsertStreamSuccess = payload => ({
  type: UPSERT_STREAM_SUCCESS,
  payload,
});

export const upsertStreamFail = error => ({
  type: UPSERT_STREAM_FAIL,
  payload: error,
});

export const upsertMember = (payload, callback) => ({
  type: UPSERT_MEMBER,
  payload,
  callback,
});

export const upsertMemberSuccess = payload => ({
  type: UPSERT_MEMBER_SUCCESS,
  payload,
});

export const upsertMemberFail = error => ({
  type: UPSERT_MEMBER_FAIL,
  payload: error,
});

export const getProjectFiles = (payload, callback) => ({
  type: GET_PROJECT_FILES,
  payload,
  callback,
});

export const getProjectFilesSuccess = payload => ({
  type: GET_PROJECT_FILES_SUCCESS,
  payload,
});

export const getProjectFilesFail = error => ({
  type: GET_PROJECT_FILES_FAIL,
  payload: error,
});

export const getAllProjectsOverview = callback => ({
  type: GET_ALLPROJECTSOVERVIEW,
  callback,
});

export const getAllProjectsOverviewSuccess = project => ({
  type: GET_ALLPROJECTSOVERVIEW_SUCCESS,
  payload: project,
});

export const getAllProjectsOverviewFail = error => ({
  type: GET_ALLPROJECTSOVERVIEW_FAIL,
  payload: error,
});

export const deleteMember = (payload, callback) => ({
  type: DELETE_MEMBER,
  payload,
  callback,
});

export const deleteMemberSuccess = payload => ({
  type: DELETE_MEMBER_SUCCESS,
  payload,
});

export const deleteMemberFail = error => ({
  type: DELETE_MEMBER_FAIL,
  payload: error,
});

export const updateProjectstStatusCode = payload => ({
  type: UPDATE_PROJECT_STATUS_CODE,
  payload
});

export const getProjectsDashboard = payload => ({
  type: GET_PROJECT_DASHBOARD,
  payload

})

export const getProjectsDashboardSuccess = payload => ({
  type: GET_PROJECT_DASHBOARD_SUCCESS,
  payload,
});

export const getProjectsDashboardFail = error => ({
  type: GET_PROJECT_DASHBOARD_FAIL,
  payload: error,
});


// project-level 
export const getAllProjectsDashboard = payload => ({
  type: GET_ALL_PROJECT_DASHBOARD,
  payload

})
export const getAllProjectsDashboardSuccess = payload => ({
  type: GET_ALL_PROJECT_DASHBOARD_SUCCESS,
  payload,
});
export const getAllProjectsDashboardFail = error => ({
  type: GET_ALL_PROJECT_DASHBOARD_FAIL,
  payload: error,
});