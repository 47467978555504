import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import {
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Popover,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
import { getProjectsOverview, getProjectptions } from "store/projects/actions";
import { withTranslation } from "react-i18next";
import { Colors, ENTITIES, getGlobalHeaderStyle } from "common";
import { Card } from "reactstrap";
import StatusText from "components/Common/StatusText";
// import Loading from "pages/Loading";
import {
  getAllApprovalsOverview,
  getAllCorrespondenceOverview,
  getAllTasksOverview,
  getEntityLookups,
} from "store/actions";
import DashboardCard from "common/dashboardCard";
import "./project-level.css";

//icons
import projectDashboardIcon from "../../../assets/icons/projectDashboard.svg";
import dashboardTaskIcon from "../../../assets/icons/dashboardTask.svg";
import dashboardApprovalIcon from "../../../assets/icons/dashboardApproval.svg";
import dashboardCorrespondenceIcon from "../../../assets/icons/dashboardCorrespondence.svg";
import adequateIcon from "../../../assets/icons/Adequate.svg";

import Scores from "common/Scores";
// import TeamMemberDashboards from "common/teamMemberDashboards";
import classnames from "classnames";
import AllProjects from "./all-projects";
import AllTasks from "./all-tasks";
import AllApprovals from "./all-approvals";
import AllCorrespondence from "./all-correspondence";
import TeamMembers from "./team-members";
import { getAllProjectsOverview } from "../../../store/projects/actions";
import ProjectHealthComponent from "../ProjectHealthComponent";
import RadialChart from "pages/AllCharts/apex/apexdonut";
import CustomSelect from "components/Common/CustomSelect";
import { beginTheBar } from "common/utils";
import Loading from "pages/Loading";
import ServerSideSelect from "./serve-side-select";

class ProjectLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customActiveTab: "1",
      isDropdownOpen: false,
      selectedProject: null,
      tasksStatusModal: false,
      approvalsStatusModal: false,
      correspondenceByPriorityModal: false,
      projectsColumns: [
        {
          dataField: "employee",
          text: props.t("employee"),
          sort: true,
          headerStyle: getGlobalHeaderStyle("25%"),
          align: "start",
        },
        {
          dataField: "task",
          text: props.t("START AND END DATE"),
          sort: true,
          headerStyle: getGlobalHeaderStyle("25%"),
          align: "start",
        },
        {
          dataField: "deadline",
          text: props.t("deadline").toUpperCase(),
          sort: true,
          headerStyle: getGlobalHeaderStyle("25%"),
          align: "start",
        },
        {
          dataField: "priority",
          text: props.t("priority"),
          sort: true,
          headerStyle: getGlobalHeaderStyle("25%"),
          align: "start",
          formatter: (cellContent, task) => (
            <StatusText text={!!task.priority ? task.priority : ""} />
          ),
        },
      ],
    };
    this.toggleCustom = this.toggleCustom.bind(this);
    this.renderTasksStatus = this.renderTasksStatus.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.loadMoreOptions = this.loadMoreOptions.bind(this);
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
    this.loadTabData(tab);
  }


  renderTasksStatus(tasksByStatus) {
    return (
      <Card>
        <CardBody>
          <p className="day-top-projects ms-1">
            {this.props.t("Tasks By Status")}
          </p>
          <Row>
            <RadialChart
              colors={[
                "rgba(76, 77, 76, 0.5)",
                "#FF3B30",
                "#F4BD33",
                "#f1734f",
                "#07DF9A",
              ]}
              labels={[
                this.props.t("ON HOLD"),
                this.props.t("Delayed"),
                this.props.t("IN PROGRESS"),
                this.props.t("Open"),
                this.props.t("COMPLETED"),
              ]}
              series={[
                tasksByStatus.onHoldTasks,
                tasksByStatus.delayedTasks,
                tasksByStatus.inProgressTasks,
                tasksByStatus.openTasks,
                tasksByStatus.completedTasks,
              ]}
            />
          </Row>
        </CardBody>
      </Card>
    );
  }
  renderCorrespondenceByPriority(correspondenceByPriority) {
    return (
      <Card>
        <CardBody>
          <p className="day-top-projects ms-1">
            {this.props.t("Correspondence By Priority")}
          </p>
          <Row>
            <RadialChart
              colors={["#FF3B30", "#F4BD33", "#07DF9A"]}
              labels={[
                this.props.t("High"),
                this.props.t("Normal"),
                this.props.t("Low"),
              ]}
              series={[
                correspondenceByPriority.highCorrespondences,
                correspondenceByPriority.normalCorrespondences,
                correspondenceByPriority.lowCorrespondences,
              ]}
            />
          </Row>
        </CardBody>
      </Card>
    );
  }
  renderApprovalsStatus(approvalsByStatus) {
    return (
      <Card>
        <CardBody>
          <p className="day-top-projects ms-1">
            {this.props.t("Approvals By Status")}
          </p>
          <Row>
            <RadialChart
              colors={[
                "rgba(76, 77, 76, 0.5)",
                "#FF3B30",
                "#F4BD33",
                "#07DF9A",
              ]}
              labels={[
                this.props.t("Returned"),
                this.props.t("Rejected"),
                this.props.t("Pending"),
                this.props.t("Approved"),
              ]}
              series={[
                approvalsByStatus.returnWithComments,
                approvalsByStatus.rejected,
                approvalsByStatus.pending,
                approvalsByStatus.approved,
              ]}
            />
          </Row>
        </CardBody>
      </Card>
    );
  }
 
  handleProjectChange(project) {
    beginTheBar();
   // this.setState({selectedProject: project})
    this.loadTabData(this.state.customActiveTab, project);
  }
  loadTabData(tab, project = this.state.selectedProject) {
    // load data based on selected tab
    if (tab == "3") this.props.onGetAllTasksOverview(project.value);
    else if (tab == "5")
      this.props.onGetAllCorrespondencesOverview(project.value);
    else if (tab == "4") this.props.onGetAllApprovalsOverview(project.value);
    else if (tab == "1") {
      this.props.onGetProjectsOverview(project.value);
    }
  }

  componentDidMount() {
    const { onGetProjectptions } = this.props;
    onGetProjectptions({});
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      //handle first time fetching array
      if (
        !this.state.selectedProject &&
        this.props.projectOptions &&
        this.props.projectOptions.length > 0
      ) {

        if(!this.state.selectedProject){
          this.setState({selectedProject: this.props.projectOptions[0]})
        }
        this.handleProjectChange(
          this.props.projectOptions[0]
        );
      }
    }
  }


  loadMoreOptions(){
    const { onGetProjectptions , listPageNumber } = this.props;
    onGetProjectptions({pageNumber:listPageNumber+1});

  }

  render() {
    const {
      loading,
      projectsOverview,
      projectOptions,
    } = this.props;
    if (
      !projectsOverview ||
      !projectOptions  
    ) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Day-Portal")}</title>
          </MetaTags>

          <Container fluid>
            <Row className="day-row-flex-end day-w-100 day-justify-between row-margin-bottom-project-level row-margin-top-search">
              <Col sm={9}>
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classnames({
                        active: this.state.customActiveTab === "1",
                        "text-muted": this.state.customActiveTab !== "1",
                      })}
                      onClick={() => {
                        this.toggleCustom("1");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("Overview")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classnames({
                        active: this.state.customActiveTab === "2",
                        "text-muted": this.state.customActiveTab !== "2",
                      })}
                      onClick={() => {
                        this.toggleCustom("2");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("All Projects")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classnames({
                        active: this.state.customActiveTab === "3",
                        "text-muted": this.state.customActiveTab !== "3",
                      })}
                      onClick={() => {
                        this.toggleCustom("3");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("All Tasks")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classnames({
                        active: this.state.customActiveTab === "4",
                        "text-muted": this.state.customActiveTab !== "4",
                      })}
                      onClick={() => {
                        this.toggleCustom("4");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("All Approvals")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                      className={classnames({
                        active: this.state.customActiveTab === "5",
                        "text-muted": this.state.customActiveTab !== "5",
                      })}
                      onClick={() => {
                        this.toggleCustom("5");
                      }}
                    >
                      <span className="font-size-16 text-nowrap">
                        {this.props.t("All Correspondence")}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              {this.state.customActiveTab !== "2" && (
                <Col sm={3} className="server-dropdown">
                  <ServerSideSelect
                    defultVal={this.state?.selectedProject?.label}
                    data={projectOptions}
                    onChange={this.handleProjectChange}
                    t={this.props.t}
                    totalRecordCount={this.props.listTotalRecordCount}
                    pageNumber={this.props.listPageNumber}
                    onLoadMore={this.loadMoreOptions}
                    isListLoading={this.props.loadingOptions}
                   
                  />
                </Col>
              )}
            </Row>
            <TabContent activeTab={this.state.customActiveTab}>
              <TabPane tabId="1">
                {this.state.customActiveTab == "1" ? (
                  <>
                    {!loading ? (
                      <>
                        <Row className="row-overview-kpis">
                          <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={3}
                            xxl={3}
                            className="g-1 col-margin-top-project-level"
                          >
                            <DashboardCard
                              title={this.props.t("All Projects")}
                              value={projectsOverview.totalProjects}
                              percentage={
                                projectsOverview.projectVSPreviousMonth
                              }
                              previousMonthTitle={this.props.t(
                                "vs Previous Month"
                              )}
                              icon={projectDashboardIcon}
                              onClick={() => {
                                this.toggleCustom("2");
                              }}
                              tooltipText={this.props.t("All Projects Tooltip")}
                            />
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={3}
                            xxl={3}
                            className="g-1 col-margin-top-project-level"
                            id={"tasksStatusModal"}
                            onMouseEnter={() => {
                              this.setState({ tasksStatusModal: true });
                            }}
                            onMouseLeave={() => {
                              this.setState({ tasksStatusModal: false });
                            }}
                          >
                            <DashboardCard
                              title={this.props.t("All Tasks")}
                              value={projectsOverview.totalTasks}
                              percentage={projectsOverview.taskVSPreviousMonth}
                              previousMonthTitle={this.props.t(
                                "vs Previous Month"
                              )}
                              icon={dashboardTaskIcon}
                              onClick={() => {
                                this.toggleCustom("3");
                              }}
                              tooltipText={this.props.t("All Tasks Tooltip")}
                            />
                            <Popover
                              placement="bottom" //bottom-right-top-left
                              isOpen={this.state.tasksStatusModal}
                              target="tasksStatusModal"
                              style={{
                                width: 450,
                                height: 100,
                              }}
                            >
                              {this.renderTasksStatus(
                                projectsOverview.tasksByStatus
                              )}
                            </Popover>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={3}
                            xxl={3}
                            className="g-1 col-margin-top-project-level"
                            id={"approvalsStatusModal"}
                            onMouseEnter={() => {
                              this.setState({ approvalsStatusModal: true });
                            }}
                            onMouseLeave={() => {
                              this.setState({ approvalsStatusModal: false });
                            }}
                          >
                            <DashboardCard
                              title={this.props.t("All Approvals")}
                              value={projectsOverview.totalApprovals}
                              percentage={
                                projectsOverview.approvalVSPreviousMonth
                              }
                              previousMonthTitle={this.props.t(
                                "vs Previous Month"
                              )}
                              icon={dashboardApprovalIcon}
                              onClick={() => {
                                this.toggleCustom("4");
                              }}
                              tooltipText={this.props.t(
                                "All Approvals Tooltip"
                              )}
                            />
                            <Popover
                              placement="bottom" //bottom-right-top-left
                              isOpen={this.state.approvalsStatusModal}
                              target="approvalsStatusModal"
                              style={{
                                width: 450,
                                height: 100,
                              }}
                            >
                              {this.renderApprovalsStatus(
                                projectsOverview.approvalsByStatus
                              )}
                            </Popover>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={3}
                            xxl={3}
                            className="g-1 col-margin-top-project-level"
                            id={"correspondenceByPriorityModal"}
                            onMouseEnter={() => {
                              this.setState({
                                correspondenceByPriorityModal: true,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({
                                correspondenceByPriorityModal: false,
                              });
                            }}
                          >
                            <DashboardCard
                              title={this.props.t("All Correspondence")}
                              value={projectsOverview.totalCorrespondences}
                              percentage={
                                projectsOverview.correspondenceVSPreviousMonth
                              }
                              previousMonthTitle={this.props.t(
                                "vs Previous Month"
                              )}
                              icon={dashboardCorrespondenceIcon}
                              onClick={() => {
                                this.toggleCustom("5");
                              }}
                              tooltipText={this.props.t(
                                "All Correspondence Tooltip"
                              )}
                            />
                            <Popover
                              placement="left-start" //bottom-right-top-left
                              isOpen={this.state.correspondenceByPriorityModal}
                              target="correspondenceByPriorityModal"
                              style={{
                                width: 450,
                                height: 100,
                              }}
                            >
                              {this.renderCorrespondenceByPriority(
                                projectsOverview.correspondenceByPriority
                              )}
                            </Popover>
                          </Col>
                        </Row>
                        <Row className="row-margin-top">
                          <Col lg="6" className="g-1 d-flex flex-column">
                            <ProjectHealthComponent
                              overview={projectsOverview}
                              projectsColumns={this.state.projectsColumns}
                            />

                            <Card
                              style={{ maxHeight: "244px", marginTop: -20 }}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between">
                                  <p className="day-top-projects">
                                    {this.props.t(
                                      "Employee Productivity Scores"
                                    )}
                                  </p>
                                </div>
                                <div className="day-row day-justify-between mt-2">
                                  <p className="team-member-title mb-2">
                                    {this.props.t("Overall Performance")}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-center adequate-dev">
                                    <div className="adequate-label">
                                      {this.props.t("Adequate")}
                                    </div>
                                    <img src={adequateIcon} />
                                  </div>
                                </div>
                                <div className="day-row day-justify-between day-w-100 mt-2">
                                  <Scores
                                    text={this.props.t("Rock Star")}
                                    value={
                                      projectsOverview.productivityScoreCategory
                                        .rockstar
                                    }
                                    marginRight
                                    bgColor={Colors.greenTextBG}
                                    color={Colors.greenText}
                                  />
                                  <Scores
                                    text={this.props.t("Adequate")}
                                    value={
                                      projectsOverview.productivityScoreCategory
                                        .adequate
                                    }
                                    marginRight
                                    bgColor={Colors.yellowBG}
                                    color={Colors.darkYellow}
                                  />
                                  <Scores
                                    text={this.props.t("Needs Help")}
                                    value={
                                      projectsOverview.productivityScoreCategory
                                        .needHelp
                                    }
                                    bgColor={Colors.redTextBG}
                                    color={Colors.redText}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg="6" className="g-1">
                            <TeamMembers
                              fromProjectsPage={true}
                              data={projectsOverview.teamMembers}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Loading />
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </TabPane>
              <TabPane tabId="2">
                {this.state.customActiveTab == "2" && (
                  <AllProjects key={+this.state.customActiveTab + 2} />
                )}
              </TabPane>
              <TabPane tabId="3">
                {this.state.customActiveTab == "3" && (
                  <AllTasks key={+this.state.customActiveTab + 3} />
                )}
              </TabPane>
              <TabPane tabId="4">
                {this.state.customActiveTab == "4" && (
                  <AllApprovals key={+this.state.customActiveTab + 4} />
                )}
              </TabPane>
              <TabPane tabId="5">
                {this.state.customActiveTab == "5" && (
                  <AllCorrespondence key={+this.state.customActiveTab + 5} />
                )}
              </TabPane>
            </TabContent>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
ProjectLevel.propTypes = {
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  projectsOverview: PropTypes.any,
  onGetProjectsOverview: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.any,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  allProjectsOverview: PropTypes.array,
  onGetAllProjectsOverview: PropTypes.func,
  onGetAllTasksOverview: PropTypes.func,
  allTasksOverview: PropTypes.any,
  onGetAllCorrespondencesOverview: PropTypes.func,
  correspondenceOverview: PropTypes.any,
  approvalsOverview: PropTypes.any,
  onGetAllApprovalsOverview: PropTypes.func,
  onGetProjectptions: PropTypes.func,
  projectOptions: PropTypes.array,
  listTotalRecordCount: PropTypes.any,
  listPageNumber: PropTypes.any,
  listPageCount: PropTypes.any,
  loadingOptions: PropTypes.bool,
};

const mapStateToProps = ({ projects, Layout, tasks, corres, approvals }) => ({
  loading: projects.loading,
  loadingOptions: projects.loadingOptions,
  error: projects.error,
  projectsOverview: projects.projectsOverview,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  allProjectsOverview: projects.allProjectsOverview,
  allTasksOverview: tasks.allTasksOverview,
  correspondenceOverview: corres.correspondenceOverview,
  approvalsOverview: approvals.approvalsOverview,
  projectOptions: projects.projectOptions,
  listTotalRecordCount: projects.listTotalRecordCount,
  listPageNumber: projects.listPageNumber,
  listPageCount: projects.listPageCount,
});
const mapDispatchToProps = dispatch => ({
  onGetProjectsOverview: id => dispatch(getProjectsOverview(id)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
  onGetAllProjectsOverview: () => dispatch(getAllProjectsOverview()),
  onGetAllTasksOverview: id => dispatch(getAllTasksOverview(id)),
  onGetAllCorrespondencesOverview: id =>
    dispatch(getAllCorrespondenceOverview(id)),
  onGetAllApprovalsOverview: id => dispatch(getAllApprovalsOverview(id)),
  onGetProjectptions: payload => dispatch(getProjectptions(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ProjectLevel)));
