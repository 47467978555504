import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import FormUpload from "pages/Forms/FormUpload";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import CustomSelect from "components/Common/CustomSelect";
import { Colors, handleArabicDate, isInternal } from "common";
import { getLookupValue } from "common/utils";
import { formatLineBreaksInMarkdownFriendlyFormat } from "common/utils";

class CreateTaskModal extends Component {
  constructor(props) {
    super(props);
    const _validations = {
      name: Yup.string().required(this.props.t("errorTaskName")),
      assignedBy: Yup.string().required(this.props.t("errorAssignedBy")),
      assignedTo: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(this.props.t("errorAssignedTo")),
      dueDate: Yup.date().required(this.props.t("errorTaskDueDate")),
      priority: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(this.props.t("errorPriority")),
    };
    if (props.isEdit) {
      _validations["status"] = Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(this.props.t("errorStatus"));
    }
    if (props.isFromAllTasks) {
      _validations["regarding"] = Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
          entityName: Yup.string().required(),
        })
        .required(this.props.t("errorRegarding"));
    }
    this.state = {
      validations: _validations,
    };
    this.isValid = this.isValid.bind(this);
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    for (let key in this.state.validations) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      task,
      isEdit,
      isFromAllTasks,
      isFromStream,
      files,
      onFilesAccepted,
      entityLookups,
    } = this.props;
    if (!entityLookups) {
      return <></>;
    }

    let regardingOptions = [];

    if (entityLookups?.listRegarding?.length) {
      regardingOptions = [
        { ...entityLookups?.listRegarding[0] },
        { ...entityLookups?.listRegarding[1] },
      ];
    }

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
        onClosed={this.props.onClosed}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isFromStream && (
            <button
              type="button"
              className="btn btn-primary"
              style={{
                backgroundColor: "transparent",
                border: "0px",
              }}
              onClick={() => {
                this.props.toggle();
              }}
            >
              <i
                className={`bx bx-chevron-left bx-sm`}
                style={{ color: Colors.grey }}
              ></i>
            </button>
          )}
          {isEdit
            ? this.props.t("Update Task")
            : this.props.t("Create New Task")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: (task && task.title) || "",
              description:
                (task &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    task.description,
                    true
                  )) ||
                "",
              assignedBy:
                (task ? task.assignedBy?.name : localStorage.getItem("name")) ||
                "",
              assignedTo:
                (task &&
                  task.assignedTo &&
                  getLookupValue(
                    task.assignedTo?.id,
                    isInternal
                      ? entityLookups.listIndividualsInternal.concat(
                          entityLookups.listIndividualsExternal
                        )
                      : entityLookups.listIndividualsExternal
                  )) ||
                "",
              dueDate: (task && handleArabicDate(task.dueDate)) || "",
              regarding: (task && task.regarding?.id) || "",
              priority:
                (task &&
                  getLookupValue(
                    task.priority?.value,
                    entityLookups.listTaskPriority
                  )) ||
                "",
              status:
                (task &&
                  task.status &&
                  getLookupValue(
                    task.status.value,
                    entityLookups.listStatus
                  )) ||
                "",
              files: (task && task.files) || [],
            }}
            validationSchema={Yup.object().shape(this.state.validations)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    {isFromAllTasks && (
                      <div className="mb-3">
                        <Row>
                          <Col lg={"12"}>
                            <Label className="form-label day-required">
                              {this.props.t("Task is for").toUpperCase()}
                            </Label>
                            <CustomSelect
                              className="input"
                              onChange={value => {
                                setFieldValue("regarding", value);
                              }}
                              value={values.regarding}
                              options={regardingOptions}
                            />
                            <ErrorMessage
                              name="regarding"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}

                    <div className="mb-3">
                      <Row>
                        <Col lg={isEdit ? "6" : "12"}>
                          <Label className="form-label day-required">
                            {this.props.t("PriorityForm")}
                          </Label>
                          <CustomSelect
                            isTaskSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("priority", value);
                            }}
                            value={values.priority}
                            options={entityLookups.listTaskPriority}
                          />
                          <ErrorMessage
                            name="priority"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        {isEdit && (
                          <Col lg="6">
                            <Label className="form-label day-required">
                              {this.props.t("StatusForm")}
                            </Label>
                            <CustomSelect
                              className="input"
                              onChange={value => {
                                setFieldValue("status", value);
                              }}
                              value={values.status}
                              options={entityLookups.listStatus}
                            />
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        )}
                      </Row>
                    </div>

                    <div className="mb-3">
                      <Row>
                        <Col lg="6">
                          <Label className="form-label day-required">
                            {this.props.t("NameForm")}
                          </Label>
                          <Field
                            name="name"
                            type="text"
                            value={values.name}
                            className={
                              "form-control" +
                              (errors.name && touched.name ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>

                        <Col lg="6">
                          <Label className="form-label day-required">
                            {this.props.t("Deadline")}
                          </Label>

                          <Field
                            name="dueDate"
                            type="date"
                            className={
                              "form-control" +
                              (errors.dueDate && touched.dueDate
                                ? " is-invalid"
                                : "")
                            }
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-3">
                      <Row>
                        <Col sm={6}>
                          <Label className="form-label">
                            {this.props.t("Assigned By")}
                          </Label>
                          <Field
                            name="assignedBy"
                            type="text"
                            className={
                              "form-control" +
                              (errors.assignedBy && touched.assignedBy
                                ? " is-invalid"
                                : "")
                            }
                            as="input"
                            disabled
                          />
                          <ErrorMessage
                            name="assignedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col sm={6}>
                          <Label className="form-label day-required">
                            {this.props.t("Assigned To")}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("assignedTo", value);
                            }}
                            value={values.assignedTo}
                            options={
                              isInternal
                                ? [
                                    {
                                      label: this.props.t("Internal"),
                                      options:
                                        entityLookups.listIndividualsInternal,
                                    },
                                    {
                                      label: this.props.t("External"),
                                      options:
                                        entityLookups.listIndividualsExternal,
                                    },
                                  ]
                                : entityLookups.listIndividualsExternal
                            }
                          />
                          <ErrorMessage
                            name="assignedTo"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                    </div>

                    {!isEdit && (
                      <div className="mb-3">
                        <Col sm={12}>
                          <Label className="form-label">
                            {this.props.t("Files")}
                          </Label>
                          <FormUpload
                            files={files}
                            onFilesAccepted={onFilesAccepted}
                            multiple
                          />
                        </Col>
                      </div>
                    )}
                    <div className="mb-3">
                      <Row>
                        <Col sm={12}>
                          <Label className="form-label">
                            {this.props.t("DescriptionForm")}
                          </Label>

                          <Field
                            name="description"
                            type="text"
                            as="textarea"
                            placeholder={this.props.t("enterDes")}
                            className={
                              "form-control" +
                              (errors.description && touched.description
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="day-text-end">
                      <button
                        type="button"
                        className="btn customTable-button save-task"
                        disabled={!this.isValid(values, errors)}
                        onClick={e => {
                          e.preventDefault();
                          this.props.handleSubmit(values);
                        }}
                      >
                        {this.props.isFromStream
                          ? this.props.t("Create and Go Back")
                          : isEdit
                          ? this.props.t("Update")
                          : this.props.t("Create").toUpperCase()}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

CreateTaskModal.propTypes = {
  task: PropTypes.any,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  isFromAllTasks: PropTypes.bool,
  isFromStream: PropTypes.bool,
  onClosed: PropTypes.func,
  files: PropTypes.array,
  onFilesAccepted: PropTypes.func,
  entityLookups: PropTypes.any,
};

export default withTranslation()(CreateTaskModal);
