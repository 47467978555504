import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import CustomSelect from "components/Common/CustomSelect";
import { isInternal } from "common";
import { getLookupValue } from "common/utils";

class TeamMemberModal extends Component {
  constructor(props) {
    super(props);
    const _validations = {
      member: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(this.props.t("errorStreamLead")),
      role: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(this.props.t("errorMemeberRole")),
    };
    let memberOptions = this.getMemberOptions(props);
    this.state = {
      memberOptions,
      validations: _validations,
    };
    this.isValid = this.isValid.bind(this);
    this.getMemberOptions = this.getMemberOptions.bind(this);
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }

    for (let key in this.state.validations) {
      if (!values[key] && key !== "nameAr") {
        return false;
      }
    }
    return true;
  }

  getMemberOptions(_props) {
    let currentMemberIds = _props.team
      .map(item => item?.member?.id)
      .filter(item => !!item);
    let memberOptions = [];
    if (_props.isEdit) {
      if (isInternal) {
        memberOptions = _props.entityLookups.listIndividualsInternal;
      } else {
        memberOptions = _props.entityLookups.listIndividualsExternal;
      }
    } else {
      if (isInternal) {
        memberOptions = _props.entityLookups.listIndividualsInternal.filter(
          item => !currentMemberIds.includes(item.value)
        );
      } else {
        memberOptions = _props.entityLookups.listIndividualsExternal.filter(
          item => !currentMemberIds.includes(item.value)
        );
      }
    }
    return memberOptions;
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (prevProps.isEdit !== this.props.isEdit) {
        let memberOptions = this.getMemberOptions(this.props);
        this.setState({ memberOptions });
      }
    }
  }
  render() {
    const { member, streams, isEdit, entityLookups } = this.props;
    if (!entityLookups) {
      return <></>;
    }

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
        centered={true}
        onClosed={this.props.onClosed}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("Edit Member")
            : this.props.t("Add New Member")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              member:
                (member &&
                  member.member &&
                  getLookupValue(
                    member.member.id,
                    isInternal
                      ? entityLookups.listIndividualsInternal
                      : entityLookups.listIndividualsExternal
                  )) ||
                "",
              stream:
                (member &&
                  member.stream &&
                  getLookupValue(member.stream.id, streams)) ||
                "",
              role:
                (member &&
                  member.role &&
                  getLookupValue(member.role.value, entityLookups.listRoles)) ||
                "",
            }}
            validationSchema={Yup.object().shape(this.state.validations)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <Row>
                      <Col lg={"12"}>
                        <div className="mb-3">
                          <Label className="form-label day-required">
                            {this.props.t("MemberForm")}
                          </Label>

                          <CustomSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("member", value);
                            }}
                            value={values.member}
                            options={this.state.memberOptions}
                          />
                          <ErrorMessage
                            name="member"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label day-required">
                            {this.props.t("RoleForm")}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("role", value);
                            }}
                            value={values.role}
                            options={entityLookups.listRoles}
                          />
                          <ErrorMessage
                            name="role"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">
                            {this.props.t("StreamForm")}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              setFieldValue("stream", value);
                            }}
                            value={values.stream}
                            options={streams}
                          />
                          <ErrorMessage
                            name="stream"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="day-text-end">
                      <button
                        type="button"
                        className="btn customTable-button save-task"
                        disabled={!this.isValid(values, errors)}
                        onClick={e => {
                          e.preventDefault();
                          this.props.handleSubmit(values);
                        }}
                      >
                        {isEdit ? this.props.t("Save") : this.props.t("Add")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

TeamMemberModal.propTypes = {
  member: PropTypes.any,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  streams: PropTypes.array,
  onClosed: PropTypes.func,
  entityLookups: PropTypes.any,
  team: PropTypes.any,
};

export default withTranslation()(TeamMemberModal);
