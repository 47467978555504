import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { formatLineBreaksInMarkdownFriendlyFormat } from "common/utils";
// import { Colors } from "common";

class CreateAgendaModal extends Component {
  constructor(props) {
    super(props);
    const _validations = {
      title: Yup.string().required(this.props.t("errorTitle")),
      description: Yup.string().required(this.props.t("errorDesc")),
    };
    this.state = {
      validations: _validations,
    };
    this.isValid = this.isValid.bind(this);
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }

    for (let key in this.state.validations) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { task, isEdit, isFromStream, editAgenda } = this.props;

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
        onClosed={this.props.onClosed}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("Update Agenda")
            : this.props.t("Create New Agenda")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: (editAgenda && editAgenda.title) || "",
              description:
                (editAgenda &&
                  editAgenda.description?.replace(/<br\s*[\/]?>/gi, "\n")) ||
                "",
              createdOn: (editAgenda && editAgenda.createdOn) || "",
              idAgenda: (editAgenda && editAgenda.idAgenda) || "",
            }}
            validationSchema={Yup.object().shape(this.state.validations)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label day-required">
                            {this.props.t("SubjectForm")}
                          </Label>
                          <Field
                            name="title"
                            type="text"
                            value={values.title}
                            className={
                              "form-control" +
                              (errors.title && touched.title
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label day-required">
                            {this.props.t("DescriptionForm")}
                          </Label>
                          <textarea
                            name="description"
                            value={values.description}
                            className={
                              "form-control" +
                              (errors.description && touched.description
                                ? " is-invalid"
                                : "")
                            }
                            style={{ height: "37px" }}
                            onChange={e => {
                              let val = e.target.value;
                              setFieldValue("description", val);
                            }}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="day-text-end">
                      <button
                        type="button"
                        disabled={!this.isValid(values, errors)}
                        onClick={e => {
                          let newValues = {
                            ...values,
                            description:
                              formatLineBreaksInMarkdownFriendlyFormat(
                                values.description,
                                true
                              ),
                          };
                          e.preventDefault();
                          this.props.handleSubmit(newValues);
                        }}
                        className="btn customTable-button save-task"
                      >
                        {isEdit
                          ? this.props.t("Update")
                          : this.props.t("Create").toUpperCase()}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

CreateAgendaModal.propTypes = {
  task: PropTypes.any,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  isFromAllTasks: PropTypes.bool,
  isFromStream: PropTypes.bool,
  onClosed: PropTypes.func,
  files: PropTypes.array,
  onFilesAccepted: PropTypes.func,
  loading: PropTypes.bool,
  editAgenda: PropTypes.object,
};

export default withTranslation()(CreateAgendaModal);
