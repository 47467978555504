import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabPane,
  TabContent,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormUpload from "pages/Forms/FormUpload";
import RadioStepper from "components/Common/RadioStepper";
import CustomSelect from "components/Common/CustomSelect";
import { DEFAULT_ACCEPTED_IMAEGES, handleArabicDate, isInternal } from "common";
import {
  formatLineBreaksInMarkdownFriendlyFormat,
  getLookupValue,
} from "common/utils";
import { CURRENT_LANG } from "common";

let validations = [{}, {}, {}];

let projectSchemaValidations = [
  Yup.object().shape(validations[0]),
  Yup.object().shape(validations[1]),
  Yup.object().shape(validations[2]),
];
class ProjectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      refresh: true,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isEdit) {
        validations = [
          {
            owner: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Select Owner for Project"),
            projectNameEn: Yup.string().required("Please Enter Project Name"),
            projectNameAr: Yup.string().required("Please Enter Project Name"),
            organization: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Organization"),
            stage: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Project Stage"),
            department: Yup.object()
              .shape({
                label: Yup.string().required(),
                labelAr: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Department"),
            
          },
          {
            risk: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Project Risk Level"),
            estimatedStartDate: Yup.date().required(
              this.props.t("errorEstimatedStart")
            ),
            estimatedEndDate: Yup.date()
              .when(
                "estimatedStartDate",
                (estimatedStartDate, schema) =>
                  estimatedStartDate &&
                  schema.min(
                    estimatedStartDate,
                    this.props.t("errorBeforeEndDate")
                  )
              )
              .required(this.props.t("errorEstimatedEnd")),
            priority: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Project Priority"),
            status: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Project Status"),
          },
          {
            // image: Yup.string(),
          },
        ];
      } else {
        validations = [
          {
            owner: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorOwner")),
            projectNameEn: Yup.string().required(
              this.props.t("errorprojectNameEn")
            ),
            projectNameAr: Yup.string().required(
              this.props.t("errorprojectNameEn")
            ),
            organization: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorOrganizationName")),
            department: Yup.object()
              .shape({
                label: Yup.string().required(),
                labelAr: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required("Please Enter Department"),
            
          },
          {
            stage: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorProjectStage")),
            risk: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorRiskLevel")),
            estimatedStartDate: Yup.date().required(
              this.props.t("errorEstimatedStart")
            ),
            estimatedEndDate: Yup.date()
              .when(
                "estimatedStartDate",
                (estimatedStartDate, schema) =>
                  estimatedStartDate &&
                  schema.min(
                    estimatedStartDate,
                    this.props.t("errorBeforeEndDate")
                  )
              )
              .required(this.props.t("errorEstimatedEnd")),
            priority: Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(this.props.t("errorProjectPriority")),
          },
          {
            // image: Yup.string(),
          },
        ];
      }
    }
    projectSchemaValidations = [
      Yup.object().shape(validations[0]),
      Yup.object().shape(validations[1]),
      Yup.object().shape(validations[2]),
    ];
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    for (let key in validations[this.state.activeTab - 1]) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { project, isEdit, file, onFilesAccepted, entityLookups } =
      this.props;
    if (!entityLookups) {
      return <></>;
    }
    let styleForButton = CURRENT_LANG == "ar" ? "day-text-end" : "day-text-end";

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
      >
       
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {(isEdit
            ? this.props.t("Edit Project")
            : this.props.t("Add Project")) + ` (${this.state.activeTab}/3)`}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              projectNumber: project ? project.projectNumber : "",
              progress: project ? project.progress : 0,
              owner: project
                ? isInternal
                  ? getLookupValue(
                      project.owner,
                      entityLookups.listIndividualsInternal
                    )
                  : getLookupValue(
                      project.owner,
                      entityLookups.listIndividualsExternal
                    )
                : "",
              projectNameEn: project ? project.projectNameEn : "",
              projectNameAr: project ? project.projectNameAr : "",
              organization: project
                ? getLookupValue(
                    project.organization,
                    entityLookups.listOrganizations
                  )
                : "",
              department:
                project && entityLookups.listDepartment
                  ? getLookupValue(
                      project.department,
                      entityLookups.listDepartment
                    )
                  : "",
              description: project
                ? formatLineBreaksInMarkdownFriendlyFormat(
                    project.description,
                    true
                  )
                : "",
              objective:project ? project.objective : "",
              stage: project
                ? getLookupValue(project.stage, entityLookups.listProjectStage)
                : "",
              risk: project
                ? getLookupValue(
                    project.risk,
                    entityLookups.listProjectRiskLevel
                  )
                : "",
              estimatedStartDate: project?.estimatedStartDate
                ? handleArabicDate(project.estimatedStartDate)
                : "",
              estimatedEndDate: project?.estimatedEndDate
                ? handleArabicDate(project.estimatedEndDate)
                : "",
              actualStartDate: project?.actualStartDate
                ? handleArabicDate(project.actualStartDate)
                : null,
              actualEndDate: project?.actualEndDate
                ? handleArabicDate(project.actualEndDate)
                : null,
              priority: project
                ? getLookupValue(project.priority, entityLookups.listPriority)
                : "",
              status: project
                ? getLookupValue(project.status, entityLookups.listStatus)
                : "",
              image: project ? project.image : "",
              overallBudget: project ? project.overallBudget : 0,
            }}
            validationSchema={
              projectSchemaValidations[this.state.activeTab - 1]
            }
          >
            {({
              errors,
              status,
              touched,
              values,
              handleChange,
              setTouched,
              setFieldValue,
            }) => (
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg={"6"}>
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("Owner")}
                                  </Label>

                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("owner", value);
                                    }}
                                    value={values.owner}
                                    options={
                                      isInternal
                                        ? entityLookups.listIndividualsInternal
                                        : entityLookups.listIndividualsExternal
                                    }
                                  />
                                  <ErrorMessage
                                    name="owner"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("Stage")}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("stage", value);
                                    }}
                                    value={values.stage}
                                    options={entityLookups.listProjectStage}
                                  />
                                  <ErrorMessage
                                    name="stage"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("projectNameEn")}
                                  </Label>
                                  <Field
                                    name="projectNameEn"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.projectNameEn &&
                                      touched.projectNameEn
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="projectNameEn"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className={"form-label day-required"}>
                                    {this.props.t("projectNameAr")}
                                  </Label>
                                  <Field
                                    name="projectNameAr"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.projectNameAr &&
                                      touched.projectNameAr
                                        ? " is-invalid"
                                        : "")
                                    }
                                    as="input"
                                    dir="rtl"
                                  />
                                  <ErrorMessage
                                    name="projectNameAr"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("OrganizationForm")}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("organization", value);
                                    }}
                                    value={values.organization}
                                    options={entityLookups.listOrganizations}
                                  />
                                  <ErrorMessage
                                    name="organization"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>

                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className={"form-label day-required"}>
                                    {this.props.t("DepartmentForm")}
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("department", value);
                                    }}
                                    value={values.department}
                                    options={entityLookups.listDepartment}
                                  />
                                  <ErrorMessage
                                    name="department"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label className={"form-label"}>
                                    {this.props.t("OverallBudgetForm")}
                                  </Label>
                                  <Field
                                    name="overallBudget"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.overallBudget &&
                                      touched.overallBudget
                                        ? " is-invalid"
                                        : "")
                                    }
                                    as="input"
                                    min="0"
                                    step="0.5"
                                  />

                                  <ErrorMessage
                                    name="overallBudget"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className={"form-label"}>
                                    {this.props.t("Key Objectives Form")}
                                  </Label>
                                  <Field
                                    name="description"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.description && touched.description
                                        ? " is-invalid"
                                        : "")
                                    }
                                    as="textarea"
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <Label className={"form-label"}>
                                      {this.props.t("Key Risks Form")}
                                    </Label>
                                    <Field
                                      name="objective"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.objective &&
                                        touched.objective
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="objective"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                {isEdit && (
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("Progress").toUpperCase()}
                                      </Label>
                                      <Field
                                        name="progress"
                                        type="number"
                                        className={"form-control"}
                                        disabled
                                        as="input"
                                        min={0}
                                        max={100}
                                      />
                                    </div>
                                  </Col>
                                )}
                                <Col lg={isEdit ? 6 : 12}>
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("Levels of Risks Form")}
                                    </Label>
                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("risk", value);
                                      }}
                                      value={values.risk}
                                      options={
                                        entityLookups.listProjectRiskLevel
                                      }
                                      disabled={!isInternal}
                                    />
                                    <ErrorMessage
                                      name="risk"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("Estimated Start Date")}
                                    </Label>
                                    <Field
                                      name="estimatedStartDate"
                                      type="date"
                                      className={
                                        "form-control" +
                                        (errors.estimatedStartDate &&
                                        touched.estimatedStartDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                      as="input"
                                    />
                                    <ErrorMessage
                                      name="estimatedStartDate"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props
                                        .t(this.props.t("Estimated END Date"))
                                        .toUpperCase()}
                                    </Label>
                                    <Field
                                      name="estimatedEndDate"
                                      type="date"
                                      className={
                                        "form-control" +
                                        (errors.estimatedEndDate &&
                                        touched.estimatedEndDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                      as="input"
                                    />
                                    <ErrorMessage
                                      name="estimatedEndDate"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {isEdit && (
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {this.props.t("Actual Start Date")}
                                      </Label>
                                      <Field
                                        name="actualStartDate"
                                        type="date"
                                        className={
                                          "form-control" +
                                          (errors.actualStartDate &&
                                          touched.actualStartDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        value={values.actualStartDate}
                                        as="input"
                                        onChange={e => {
                                          let val = e.target.value;
                                          setFieldValue(
                                            "actualStartDate",
                                            val === "" ? null : val
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name="actualStartDate"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {this.props
                                          .t(this.props.t("Actual END Date"))
                                          .toUpperCase()}
                                      </Label>
                                      <Field
                                        name="actualEndDate"
                                        type="date"
                                        className={
                                          "form-control" +
                                          (errors.actualEndDate &&
                                          touched.actualEndDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="input"
                                        value={values.actualEndDate || ""}
                                        onChange={e => {
                                          let val = e.target.value;
                                          setFieldValue(
                                            "actualEndDate",
                                            val === "" ? null : val
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name="actualEndDate"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("Priority").toUpperCase()}
                                    </Label>
                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("priority", value);
                                      }}
                                      value={values.priority}
                                      options={entityLookups.listPriority}
                                    />
                                    <ErrorMessage
                                      name="priority"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                {isEdit && (
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("Status").toUpperCase()}
                                      </Label>
                                      <CustomSelect
                                        className="input"
                                        onChange={value => {
                                          setFieldValue("status", value);
                                        }}
                                        value={values.status}
                                        options={entityLookups.listStatus}
                                        disabled={!isInternal}
                                      />
                                      <ErrorMessage
                                        name="status"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg={12}>
                                  <Label className="form-label">
                                    {this.props
                                      .t("Optional")
                                      .replace(
                                        "*",
                                        this.props.t("Profile Picture")
                                      )}
                                  </Label>
                                  <FormUpload
                                    file={file}
                                    onFilesAccepted={onFilesAccepted}
                                    accept={DEFAULT_ACCEPTED_IMAEGES}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <RadioStepper
                      steps={[1, 2, 3]}
                      activeTab={this.state.activeTab}
                    />
                    <div className="actions clearfix" style={{ marginTop: 40 }}>
                      <ul className={styleForButton}>
                        {this.state.activeTab !== 1 && (
                          <button
                            type="button"
                            className="btn customTable-button"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                            style={{ marginInlineEnd: 10 }}
                          >
                            {this.props.t("Previous")}
                          </button>
                        )}

                        <button
                          type="button"
                          className={"btn customTable-button"}
                          disabled={!this.isValid(values, errors)}
                          onClick={e => {
                            e.preventDefault();
                            if (this.state.activeTab === 3) {
                              this.props.handleSubmit(values);
                              this.toggleTab(1);
                            } else {
                              this.toggleTab(this.state.activeTab + 1);
                            }
                          }}
                        >
                          {this.state.activeTab === 3
                            ? isEdit
                              ? this.props.t("Update")
                              : this.props.t("create").toUpperCase()
                            : this.props.t("Next")}
                        </button>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

ProjectModal.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any,
  handleSubmit: PropTypes.func,
  project: PropTypes.any,
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  toggle: PropTypes.func,
  file: PropTypes.any,
  onFilesAccepted: PropTypes.func,
  entityLookups: PropTypes.any,
};

export default withTranslation()(withRouter(ProjectModal));
