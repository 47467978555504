import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  Nav,
  TabPane,
  TabContent,
} from "reactstrap";
import Loading from "pages/Loading";
import CustomTableWithServerSide from "components/Common/CustomTableWithServerSide";
export const PAGE_SIZE = 10;

//Import Breadcrumb
import DeleteModal from "components/Common/DeleteModal";
import {
  getUsers,
  addNewUser,
  getAllOrganizations,
  upsertOrganization,
  getEntityLookups,
} from "store/actions";

import {
  CURRENT_LANG,
  ENTITIES,
  getGlobalHeaderStyle,
  TOAST_OPTIONS,
} from "common";
import "toastr/build/toastr.min.css";
import IndividualModal from "./IndividualModal";
import OrganizationModal from "./OrganizationModal";
import {
  filterPermittedEntities,
  getBase64,
  sortCaretIcons,
} from "common/utils";
import { toast } from "react-toastify";
import Tippy from "@tippy.js/react";
import { getQueryVariable } from "common/utils";

class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    let createEntities = filterPermittedEntities(props.permissions, "create");
    let readEntities = filterPermittedEntities(props.permissions, "read");

    // read page from query string
    let lastPage = 1;
    let lastTab = "1";
    const pageParam = getQueryVariable("page");
    const tabParam = getQueryVariable("tab");
    lastPage = pageParam ? pageParam : 1;
    lastTab = tabParam == "organizations" ? "2" : "1";

    this.state = {
      customActiveTab: lastTab || "1",
      file: null,
      fileOrg: null,
      users: [],
      organizations: [],
      user: "",
      modal: false,
      orgModal: false,
      deleteModal: false,
      createEntities,
      readEntities,
      tabletStyle: props.tabletStyle,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,
      mobileStyle2: props.mobileStyle2,
      lastPage: lastPage,
      userSearchValue: "",
      orgSearchValue: "",
    };

    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.toggleCustom = this.toggleCustom.bind(this);
    this.handleOrganizationCreate = this.handleOrganizationCreate.bind(this);
    this.handleUserCreate = this.handleUserCreate.bind(this);

    window.history.pushState(
      null,
      null,
      window.location.pathname + "?tab=" + tabParam
    ); // clear query page params
  }

  componentDidMount() {
    const { onGetUsers, onGetOrganizations, onGetLookups } = this.props;
    const payload = {
      pageNumber: this.state.lastPage || 1,
      pageCount: PAGE_SIZE,
      searchString: "",
    };
    onGetLookups(ENTITIES.INDIVIDUALS, () => {
      onGetOrganizations(payload);
      onGetUsers(payload);
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 1260 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 800 });
    this.setState({ mobileStyle: window.innerWidth <= 475 });
    this.setState({ mobileStyle2: window.innerWidth <= 750 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  toggle() {
    if (this.state.customActiveTab == "1") {
      this.setState(prevState => ({
        modal: !prevState.modal,
      }));
    } else {
      this.setState(prevState => ({
        orgModal: !prevState.orgModal,
      }));
    }
  }

  toggleCustom(tab) {
    const { onGetUsers, onGetOrganizations } = this.props;
    const payload = {
      pageNumber: 1,
      pageCount: PAGE_SIZE,
      searchString: "",
    };
    this.setState({
      userSearchValue: "",
      orgSearchValue: "",
    });
    if (tab == "1") {
      this.props.onGetLookups(ENTITIES.INDIVIDUALS, () => {
        this.setState({
          customActiveTab: tab,
        });
        onGetUsers(payload);
      });
    } else {
      this.props.onGetLookups(ENTITIES.ORGANIZATION, () => {
        this.setState({
          customActiveTab: tab,
        });
        onGetOrganizations(payload);
      });
    }
  }

  handleUserClicks = () => {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  async handleUserCreate(values) {
    const { onGetUsers, onAddNewUser } = this.props;
    try {
      let image = "";
      if (this.state.file) {
        image = await getBase64(this.state.file);
      }

      const user = {
        idObject: "00000000-0000-0000-0000-000000000000",
        firstname: values.name.split(" ")[0]?.trim(),
        lastname: values.name.split(" ")[1]
          ? values.name.split(" ")[1]?.trim()
          : "",
        email: values.email,
        idRole: values.role.value,
        jobTitle: values.title,
        idOrganization: values.organization.value,
        phoneNumber:
          typeof values.phoneNumber === "number"
            ? values.phoneNumber?.toString()
            : values.phoneNumber,
        department: values.department,
        employeeId: values.employee,
        profileImage: image,
        notification: {
          task: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          approval: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          project: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          appointment: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          correspondence: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          request: {
            email: true,
            whatsapp: true,
            portal: true,
          },
        },
      };

      onAddNewUser(user, async apiResponse => {
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Individual")),
          TOAST_OPTIONS
        );

        if (apiResponse?.idContact)
          this.props.history.push(
            `/individual-details?id=${apiResponse?.idContact}`
          );
        else onGetUsers();
      });
      this.toggle();
    } catch (err) {
      console.error(err);
    }
  }

  async handleOrganizationCreate(values) {
    try {
      let image = "";
      if (this.state.fileOrg) {
        image = await getBase64(this.state.fileOrg);
      }
      const { onGetOrganizations, createOrganization } = this.props;
      const org = {
        nameAr: values.nameAr,
        email: values.email,
        telephone: values.phoneNumber,
        nameEn: values.nameEn,
        address: values.address,
        taxNumber: values.taxNumber,
        profileImage: image,
        primaryContact: "",
        idSector: values.sector?.value,
        organizationType: +values.organizationType?.value,
        // members: values.members,
      };

      createOrganization(org, async apiResponse => {
        toast.success(
          this.props.t("SuccessAdd").replace("*", this.props.t("Organization")),
          TOAST_OPTIONS
        );
        if (apiResponse?.idOrganization)
          return this.props.history.push(
            `/organization-details?id=${apiResponse?.idOrganization}`
          );
        else onGetOrganizations();
      });
      this.toggle();
    } catch (err) {
      console.error(err);
    }
  }
  // Page change : 
  handleIndividualPageChange = pageNumber => {
    const { onGetUsers, usersSearchString } = this.props;
    if (this.state.customActiveTab == "1") {
      const payload = {
        pageNumber: pageNumber,
        pageCount: PAGE_SIZE,
        searchString: usersSearchString,
      };
      onGetUsers(payload);
    }
  };
  handleOrgPageChange = pageNumber => {
    const { onGetOrganizations, orgSearchString } = this.props;
    if (this.state.customActiveTab == "2") {
      const payload = {
        pageNumber: pageNumber,
        pageCount: PAGE_SIZE,
        searchString: orgSearchString,
      };
      onGetOrganizations(payload);
    }
  };
  // Search :
  handleIndividualSearchSubmit = searchVal => {
    const { onGetUsers, usersPageNumber } = this.props;
    if (this.state.customActiveTab == "1") {
      const payload = {
        pageNumber: 1,
        pageCount: PAGE_SIZE,
        searchString: searchVal,
      };
      onGetUsers(payload);
      this.setState({ userSearchValue: searchVal });
    }
  };
  handleOrgSearchSubmit = searchVal => {
    const { onGetOrganizations, orgPageNumber } = this.props;
    if (this.state.customActiveTab == "2") {
      const payload = {
        pageNumber: 1,
        pageCount: PAGE_SIZE,
        searchString: searchVal,
      };
      onGetOrganizations(payload);
      this.setState({ orgSearchValue: searchVal });
    }
  };

  render() {
    const { users, organizations, loading, loadingLookups } = this.props;

    const { isEdit, deleteModal } = this.state;

    const user = this.state.user;

    if (loading || loadingLookups) {
      return <Loading />;
    }
    const { createEntities, readEntities } = this.state;
    let contactListColumns = [
      {
        text: "id",
        dataField: "objectId",
        sort: false,
        sortCaret: sortCaretIcons,
        hidden: true,
      },
      {
        text: this.props.t("NameForm"),
        dataField: "name",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
        formatter: (cellContent, user) => (
          <div className="day-row">
            <div>
              {!user.image ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {user?.firstname.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={`data:image/png;base64,${user?.image}`}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="mx-2">
              <h5 className="font-size-14 mb-0">
                <Link
                  to={"/individual-details?id=" + user?.objectId}
                  query={user}
                  className="text-dark"
                >
                  {`${user?.firstname} ${user?.lastname}`}
                </Link>
              </h5>
            </div>
          </div>
        ),
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        text: this.props.t("Sector"),
        dataField: "sectors",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        text: this.props.t("Department"),
        dataField: "department",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        text: this.props.t("Job Title"),
        dataField: "jobTitle",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
      },
      {
        hidden: this.state.mobileStyle,
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
        text: this.props.t("OrganizationForm"),
        dataField: "organization",
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cell, contact) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/organization-details?id=" + contact.organization?.id}
                className="text-dark"
              >
                {CURRENT_LANG === "ar"
                  ? contact?.organization?.labelAr
                  : contact?.organization?.label}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.tabletStyle,
        headerStyle: getGlobalHeaderStyle("40%"),
        align: "start",
        dataField: "email",
        text: this.props.t("EmailForm"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, user) => (
          <>
            <a
              href={"mailto:" + user.email}
              className="mb-0 text-decoration-underline"
            >
              {user.email}
            </a>
          </>
        ),
      },
      {
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
        text: this.props.t("Phone No"),
        dataField: "phoneNumber",
        sort: false,
        sortCaret: sortCaretIcons,
      },
    ];
    let orgListColumns = [
      {
        text: "id",
        dataField: "idOrganization",
        sort: false,
        sortCaret: sortCaretIcons,
        hidden: true,
      },
      {
        text: this.props.t("Organization"),
        dataField:
          localStorage.getItem("I18N_LANGUAGE") === "ar" ? "nameAr" : "nameEn",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
        formatter: (cellContent, org) => (
          <div className="day-row">
            <div>
              {!org.image ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {org.nameEn.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={`data:image/png;base64,${org.image}`}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="mx-2">
              <h5 className="font-size-14 mb-0">
                <Link
                  to={"/organization-details?id=" + org.idOrganization}
                  query={org}
                  className="text-dark"
                >
                  {localStorage.getItem("I18N_LANGUAGE") === "ar"
                    ? org.nameAr
                    : org.nameEn}
                </Link>
              </h5>
            </div>
          </div>
        ),
      },
      {
        hidden: this.state.mobileStyle2,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
        text: this.props.t("Primary Contact"),
        dataField: "primaryContact.label",
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cell, org) =>
          org.primaryContact && (
            <Tippy
              content={this.props.t("View More")}
              placement="top"
              animation="scale-subtle"
              theme="light"
              arrow={false}
              duration={200}
              delay={[75, 0]}
              distance={5}
            >
              <span className="text-wrap">
                <Link
                  to={"/individual-details?id=" + org.primaryContact?.value}
                  className="text-dark"
                >
                  {org.primaryContact?.label}
                </Link>
              </span>
            </Tippy>
          ),
      },
      {
        hidden: this.state.mobileStyle2,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
        dataField: "email",
        text: this.props.t("EmailForm"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, org) => (
          <>
            <a
              href={"mailto:" + org.email}
              className="mb-0 text-decoration-underline"
            >
              {org.email}
            </a>
          </>
        ),
      },
      {
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "start",
        text: this.props.t("Phone No"),
        dataField: "telephone",
        sort: false,
        sortCaret: sortCaretIcons,
      },
    ];
    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("PeoplePortal")}</title>
          </MetaTags>
          <Container fluid>
            <Nav tabs className="nav-tabs-custom row-margin-top-nav">
              {readEntities.includes(ENTITIES.INDIVIDUALS) && (
                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    className={classnames({
                      active: this.state.customActiveTab === "1",
                      "text-muted": this.state.customActiveTab !== "1",
                    })}
                    onClick={() => {
                      this.toggleCustom("1");
                    }}
                  >
                    <span className="font-size-16 text-nowrap">
                      {this.props.t("Individual")}
                    </span>
                  </NavLink>
                </NavItem>
              )}

              {readEntities.includes(ENTITIES.ORGANIZATION) && (
                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    className={classnames({
                      active: this.state.customActiveTab === "2",
                      "text-muted": this.state.customActiveTab !== "2",
                    })}
                    onClick={() => {
                      this.toggleCustom("2");
                    }}
                  >
                    <span className="font-size-16 text-nowrap">
                      {this.props.t("Organizations")}
                    </span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            <TabContent activeTab={this.state.customActiveTab}>
              <TabPane tabId="1">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CustomTableWithServerSide
                          largeSearch
                          columns={contactListColumns}
                          data={users}
                          showSearchAndAdd
                          addBtnText={this.props.t("Add Individual")}
                          placeholderText={this.props.t("Search")}
                          handleAddClick={this.handleUserClicks}
                          hideAddBtn={
                            !createEntities.includes(ENTITIES.INDIVIDUALS)
                          }
                          defaultSortedField="name"
                          onPageChange={this.handleIndividualPageChange}
                          currentPage={this.props.usersPageNumber}
                          totalDataSize={this.props.usersTotalRecordCount}
                          serverSearch={true}
                          onSearchBtnClick={searchString => {
                            this.handleIndividualSearchSubmit(searchString);
                          }}
                          searchVal={this.state.userSearchValue}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CustomTableWithServerSide
                          largeSearch
                          columns={orgListColumns}
                          data={organizations}
                          showSearchAndAdd
                          addBtnText={this.props.t("Add Organization")}
                          placeholderText={this.props.t("Search Organizations")}
                          handleAddClick={this.handleUserClicks}
                          hideAddBtn={
                            !createEntities.includes(ENTITIES.ORGANIZATION)
                          }
                          defaultSortedField="name"
                          onPageChange={this.handleOrgPageChange}
                          currentPage={this.props.orgPageNumber}
                          totalDataSize={this.props.orgTotalRecordCount}
                          serverSearch={true}
                          onSearchBtnClick={searchString => {
                            this.handleOrgSearchSubmit(searchString);
                          }}
                          searchVal={this.state.orgSearchValue}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Container>

          <IndividualModal
            modal={this.state.modal}
            isEdit={false}
            toggle={this.toggle}
            handleSubmit={this.handleUserCreate}
            file={this.state.file}
            onFilesAccepted={file => {
              this.setState({ file: file[0] });
            }}
            entityLookups={this.props.entityLookups?.[ENTITIES.INDIVIDUALS]}
          />

          <OrganizationModal
            modal={this.state.orgModal}
            isEdit={false}
            toggle={this.toggle}
            handleSubmit={this.handleOrganizationCreate}
            file={this.state.fileOrg}
            onFilesAccepted={fileOrg => {
              this.setState({ fileOrg: fileOrg[0] });
            }}
            entityLookups={this.props.entityLookups?.[ENTITIES.ORGANIZATION]}
          />
        </div>
      </React.Fragment>
    );
  }
}

ContactsList.propTypes = {
  users: PropTypes.array,
  organizations: PropTypes.array,
  organizationDetail: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onGetOrganizations: PropTypes.func,
  onAddNewUser: PropTypes.func,
  t: PropTypes.any,
  error: PropTypes.string,
  loading: PropTypes.bool,
  createOrganization: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  tabletStyle: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  mobileStyle2: PropTypes.any,
  search: PropTypes.string,
  history: PropTypes.any,
  usersSearchString: PropTypes.any,
  usersPageNumber: PropTypes.any,
  usersTotalRecordCount: PropTypes.any,
  orgSearchString: PropTypes.any,
  orgPageNumber: PropTypes.any,
  orgTotalRecordCount: PropTypes.any,
};

const mapStateToProps = ({ contacts, Layout }) => ({
  users: contacts.users,
  organizations: contacts.organizations,
  error: contacts.error,
  loading: contacts.loading,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  usersSearchString: contacts.usersSearchString,
  usersPageNumber: contacts.usersPageNumber,
  usersTotalRecordCount: contacts.usersTotalRecordCount,
  orgSearchString: contacts.orgSearchString,
  orgPageNumber: contacts.orgPageNumber,
  orgTotalRecordCount: contacts.orgTotalRecordCount,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: payload => dispatch(getUsers(payload)),
  onAddNewUser: (user, callback) => dispatch(addNewUser(user, callback)),
  onGetOrganizations: payload => dispatch(getAllOrganizations(payload)),
  createOrganization: (org, callback) =>
    dispatch(upsertOrganization(org, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ContactsList)));
