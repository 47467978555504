/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

export const SET_ORGANIZATION = "SET_ORGANIZATION";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const GET_LOOKUPS = "GET_LOOKUPS";
export const GET_LOOKUPS_FAIL = "GET_LOOKUPS_FAIL";
export const GET_LOOKUPS_SUCCESS = "GET_LOOKUPS_SUCCESS";

export const GET_ENTITIY_LOOKUPS = "GET_ENTITIY_LOOKUPS";
export const GET_ENTITIY_LOOKUPS_FAIL = "GET_ENTITIY_LOOKUPS_FAIL";
export const GET_ENTITIY_LOOKUPS_SUCCESS = "GET_ENTITIY_LOOKUPS_SUCCESS";

export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";

export const SET_PROGRESS = "SET_PROGRESS";

export const GET_GLOBAL_SEARCH = "GET_GLOBAL_SEARCH";
export const GET_GLOBAL_SEARCH_FAIL = "GET_GLOBAL_SEARCH_FAIL";
export const GET_GLOBAL_SEARCH_SUCCESS = "GET_GLOBAL_SEARCH_SUCCESS";

export const GET_LOOKUPS_COUNT = "GET_LOOKUPS_COUNT";
export const GET_LOOKUPS_COUNT_FAIL = "GET_LOOKUPS_COUNT_FAIL";
export const GET_LOOKUPS_COUNT_SUCCESS = "GET_LOOKUPS_COUNT_SUCCESS";
