/* EVENTS */
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_FAIL = "GET_APPOINTMENTS_FAIL";

export const UPSERT_AGENDA = "UPSERT_AGENDA";
export const UPSERT_AGENDA_SUCCESS = "UPSERT_AGENDA_SUCCESS";
export const UPSERT_AGENDA_FAIL = "UPSERT_AGENDA_FAIL";

export const UPSERT_APPOINTMENTS = "UPSERT_APPOINTMENTS";
export const UPSERT_APPOINTMENTS_SUCCESS = "UPSERT_APPOINTMENTS_SUCCESS";
export const UPSERT_APPOINTMENTS_FAIL = "UPSERT_APPOINTMENTS_FAIL";

export const GET_APPOINTMENT_DETAILS = "GET_APPOINTMENT_DETAILS";
export const GET_APPOINTMENT_DETAILS_SUCCESS =
  "GET_APPOINTMENT_DETAILS_SUCCESS";
export const GET_APPOINTMENT_DETAILS_FAIL = "GET_APPOINTMENT_DETAILS_FAIL";

export const GET_CHECK = "GET_CHECK";
export const GET_CHECK_SUCCESS = "GET_CHECK_SUCCESS";
export const GET_CHECK_FAIL = "GET_CHECK_FAIL";

export const GET_CALENDAR_FILES = "GET_CALENDAR_FILES";
export const GET_CALENDAR_FILES_SUCCESS = "GET_CALENDAR_FILES_SUCCESS";
export const GET_CALENDAR_FILES_FAIL = "GET_CALENDAR_FILES_FAIL";

export const SEND_EMAIL_TO_PARTICIPANTS = "SEND_EMAIL_TO_PARTICIPANTS";
export const SEND_EMAIL_TO_PARTICIPANTS_SUCCESS =
  "SEND_EMAIL_TO_PARTICIPANTS_SUCCESS";
export const SEND_EMAIL_TO_PARTICIPANTS_FAIL =
  "SEND_EMAIL_TO_PARTICIPANTS_FAIL";
