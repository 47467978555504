import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  ADD_NEW_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  GET_ORGANIZATION_DETAILS_FAIL,
  GET_ORGANIZATION_DETAILS,
  GET_USER_PROFILE,
  UPDATE_USER,
  UPSERT_ORGANIZATION,
  UPSERT_ORGANIZATION_SUCCESS,
  UPSERT_ORGANIZATION_FAIL,
  GET_ORGANIZATION_FILES,
  GET_ORGANIZATION_FILES_SUCCESS,
  GET_ORGANIZATION_FILES_FAIL,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS_SUCCESS,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS_FAIL,
  GET_EXECUTIVE_DASHBOARDS,
  GET_EXECUTIVE_DASHBOARDS_SUCCESS,
  GET_EXECUTIVE_DASHBOARDS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  users: [],
  userProfile: null,
  error: "",
  loading: false,
  organizations: [],
  organizationDetail: null,
  files: [],
  individualOverview: null,
  executiveOverview: null,
};

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EXECUTIVE_DASHBOARDS:
      return {
        ...state,
        loading: true,
      };
    case GET_EXECUTIVE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        executiveOverview: action.payload,
        loading: false,
        error: "",
      };

    case GET_EXECUTIVE_DASHBOARDS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_INDIVIDUAL_LEVEL_DASHBOARDS:
      return {
        ...state,
        loading: true,
      };
    case GET_INDIVIDUAL_LEVEL_DASHBOARDS_SUCCESS:
      return {
        ...state,
        individualOverview: action.payload,
        loading: false,
        error: "",
      };

    case GET_INDIVIDUAL_LEVEL_DASHBOARDS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_USERS:
    case GET_ORGANIZATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        error: "",
        usersPageNumber:action.payload.pageNumber,
        usersSearchString:action.payload.searchString,
        usersTotalRecordCount:action.payload.totalRecordCount
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload.data,
        loading: false,
        error: "",
        orgPageNumber:action.payload.pageNumber,
        orgSearchString:action.payload.searchString,
        orgTotalRecordCount:action.payload.totalRecordCount
      };
    case GET_ORGANIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ORGANIZATION_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        organizationDetail: action.payload,
        loading: false,
        error: "",
      };

    case GET_ORGANIZATION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_NEW_USER:
      return {
        ...state,
        loading: true,
        notification: {
          task: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          approval: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          project: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          appointment: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          correspondence: {
            email: true,
            whatsapp: true,
            portal: true,
          },
          request: {
            email: true,
            whatsapp: true,
            portal: true,
          },
        },
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
        error: "",
      };

    case UPDATE_USER:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPSERT_ORGANIZATION:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_ORGANIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ORGANIZATION_FILES:
      return {
        ...state,
        loading: true,
      };

    case GET_ORGANIZATION_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        files: action.payload,
      };

    case GET_ORGANIZATION_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default contacts;
