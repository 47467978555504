import usFlag from "../assets/images/flags/us.jpg";
import germany from "../assets/images/flags/germany.jpg";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
    key: "EN",
  },
  ar: {
    label: "Arabic",
    flag: germany,
    key: "AR",
  },
};

export default languages;
