import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";
import { DropdownItem } from "reactstrap";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import {
  CURRENT_LANG,
  customLocalStorage,
  DEFAULT_OPTION,
  isInternal,
} from "common";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  getGlobalSearchAction,
  getHome,
  setOrganization,
  toggleRightSidebar,
} from "../../store/actions";
import { getWindowDimensions } from "common/utils";
import { withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: JSON.parse(customLocalStorage.getItem("authUser")).userName,
      isSearch: true,
      open: false,
      position: "right",
      menuIconShown: false,
      selectedOrg:
        JSON.parse(customLocalStorage.getItem("organization")) ||
        DEFAULT_OPTION,
      mobileStyle: props.mobileStyle,
      searchText: "",
      searchDropdown: false,
    };
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.timeout = 0;
  }
  /**
   * Toggle sidebar
   */

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  handleWindowResize() {
    let dimensions = getWindowDimensions();
    if (dimensions.width <= 992 && !this.state.menuIconShown) {
      this.setState({
        menuIconShown: true,
      });
    } else if (dimensions.width > 992 && this.state.menuIconShown) {
      this.setState({
        menuIconShown: false,
      });
    }
    this.setState({ mobileStyle: window.innerWidth <= 472 });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  render() {
    const name = localStorage.getItem("name");
    const { lookups, globalSearchData, loading } = this.props;
    let today = new Date();
    let curHr = today.getHours();
    let time = null;

    if (curHr < 12) {
      time = "Morning";
    } else if (curHr < 18) {
      time = "Afternoon";
    } else {
      time = "Evening";
    }
    let headerStyle = this.state.mobileStyle
      ? "h5 mb-0 mx-3 text-nowrap"
      : "h2 mb-0 mx-3 text-nowrap";

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="day-row">
              <button
                type="button"
                onClick={this.props.toggleMenuCallback}
                className="btn btn-sm px-3 font-size-20 header-item"
                id="vertical-menu-btn"
                hidden={!this.state.menuIconShown}
              >
                <i className="bx bx bx-menu h2 mb-0"></i>
              </button>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <p className={headerStyle}>
                  {this.props.t(`Good ${time}`) +
                    " " +
                    (name ? name.split(" ")[0] : "")}
                </p>
              </div>
            </div>

            <div className="day-row">
              {false && <LanguageDropdown />}
              <NotificationDropdown />
              <ProfileMenu />

              {!!lookups && (
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={this.state.megaMenuDrp}
                  disabled={!isInternal}
                  toggle={() => {
                    this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                  }}
                >
                  <DropdownToggle
                    className="btn header-item"
                    caret
                    tag="button"
                  >
                    {CURRENT_LANG === "en"
                      ? this.state.selectedOrg.label
                      : this.state.selectedOrg.labelAr}
                    {isInternal && (
                      <i className="mdi mdi-chevron-down mx-1"></i>
                    )}
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-end scrollbar"
                    style={{
                      height: 300,
                      overflowY: "scroll",
                    }}
                  >
                    {lookups?.listOrganizations?.map((item, index) => (
                      <DropdownItem
                        key={"__project__" + index}
                        tag="a"
                        onClick={() => {
                          this.setState({ selectedOrg: item });
                          setOrganization(item);
                          this.props.getHome(item.value);
                        }}
                        style={{
                          backgroundColor:
                            item.value === this.state.selectedOrg.value
                              ? "rgba(0,0,0,0.2)"
                              : "transparent",
                        }}
                      >
                        {localStorage.getItem("I18N_LANGUAGE") === "ar"
                          ? item.labelAr
                          : item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
  toggleMenuCallback: PropTypes.func,
  user: PropTypes.any,
  organization: PropTypes.any,
  setOrganization: PropTypes.func,
  getHome: PropTypes.func,
  lookups: PropTypes.any,
  mobileStyle: PropTypes.any,
  globalSearchData: PropTypes.any,
  loading: PropTypes.bool,
  onGetGlobalSearch: PropTypes.func,
  history: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    organization,
    lookups,
    globalSearchData,
    loadingSearch,
  } = state.Layout;
  const { success } = state.Profile;
  return {
    layoutType,
    showRightSidebar,
    user: success,
    organization,
    lookups,
    globalSearchData,
    loading: loadingSearch,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  setOrganization: () => dispatch(setOrganization()),
  getHome: payload => dispatch(getHome(payload)),
  onGetGlobalSearch: payload => dispatch(getGlobalSearchAction(payload)),
});

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Header)));
