import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";

const MyApp = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(MyApp, document.getElementById("root"));
serviceWorker.unregister();
