import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabPane,
  TabContent,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormUpload from "pages/Forms/FormUpload";
import RadioStepper from "components/Common/RadioStepper";
import CustomSelect from "components/Common/CustomSelect";
import { DEFAULT_ACCEPTED_IMAEGES } from "common";
import { getLookupValue } from "common/utils";

class IndividualModal extends Component {
  constructor(props) {
    super(props);
    let _validations = [
      {
        name: Yup.string().required(props.t("errorName")).matches(
          /^[a-zA-Z\u0600-\u06FF,-\s\d][\s\d\a-zA-Z\u0600-\u06FF,-]*$/i,
          props.t("invalidName")
        ),
         role: Yup.object()
           .shape({
             label: Yup.string().required(),
             value: Yup.string().required(),
           })
         .required(props.t("errorRole")),

         organization: Yup.object()
          .shape({
             label: Yup.string().required(),
            value: Yup.string().required(),
         })
       .required(props.t("errorOrganization")),

        department: Yup.string().required(props.t("errorDepartment")),
        // employee: Yup.string().required(props.t("errorEmployee")),
        // title: Yup.string().required(props.t("errorETitle")),
      },
      {
        email: Yup.string()
          .email(props.t("validEmail"))
          .required(props.t("errorEmEmail")),
        phoneNumber: Yup.string().required(props.t("errorPhone")),
      },
    ];
    if (props.user && !props.user.isExternalParty) {
      _validations = [
        {
          title: Yup.string().required(props.t("errorETitle")),
        },
        {
          phoneNumber: Yup.number().required(props.t("errorPhone")),
        },
      ];
    }
    const _individualSchemaValidations = [
      Yup.object().shape(_validations[0]),
      Yup.object().shape(_validations[1]),
    ];
    this.state = {
      activeTab: 1,
      validations: _validations,
      individualSchemaValidations: _individualSchemaValidations,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    for (let key in this.state.validations[this.state.activeTab - 1]) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { user, isEdit, file, onFilesAccepted, entityLookups, org } =
      this.props;
    if (!entityLookups) {
      return <></>;
    }

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {(isEdit
            ? this.props.t("Edit Individual")
            : this.props.t("Add Individual")) + ` (${this.state.activeTab}/2)`}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name:
                (user && `${user.firstname ?? ""} ${user.lastname ?? ""}`) ||
                "",
              email: (user && user.email) || "",
              role:
                (user &&
                  getLookupValue(user.roleId, entityLookups.listRoles)) ||
                "",
              organization: org
                ? {
                    label: org.nameEn,
                    labelAr: org.nameAr,
                    value: org.idOrganization,
                  }
                : (user &&
                    getLookupValue(
                      user.organization?.id,
                      entityLookups.listOrganizations
                    )) ||
                  "",
              phoneNumber: (user && user.phoneNumber) || "",
              department: (user && user.department) || "",
              employee: (user && user.employeeId) || "",
              title: (user && user.jobTitle) || "",
            }}
            validationSchema={
              this.state.individualSchemaValidations[this.state.activeTab - 1]
            }
          >
            {({
              errors,
              status,
              touched,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("Organization")}
                                  </Label>

                                  <CustomSelect
                                    disabled={isEdit || org} //{isEdit && !user.isExternalParty}
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("organization", value);
                                    }}
                                    value={values.organization}
                                    options={entityLookups.listOrganizations}
                                  />
                                  <ErrorMessage
                                    name="organization"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>

                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("DepartmentForm")}
                                  </Label>
                                  <Field
                                    disabled={isEdit && !user.isExternalParty}
                                    name="department"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.department && touched.department
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="department"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className={"form-label"}>
                                    {this.props.t("Employee ID")}
                                  </Label>
                                  <Field
                                    disabled={isEdit && !user.isExternalParty}
                                    name="employee"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.employee && touched.employee
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="employee"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("Employee Name")}
                                  </Label>
                                  <Field
                                    disabled={isEdit} //{isEdit && !user.isExternalParty}
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name && touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {this.props.t("Job Title").toUpperCase()}
                                  </Label>
                                  <Field
                                    name="title"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.title && touched.title
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("role")}
                                  </Label>
                                  <CustomSelect
                                    disabled={isEdit && !user.isExternalParty}
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("role", value);
                                    }}
                                    value={values.role}
                                    name="role"
                                    options={entityLookups.listRoles}
                                  />
                                  <ErrorMessage
                                    name="role"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("EmailForm")}
                                    </Label>
                                    <Field
                                      disabled={isEdit} //{isEdit && !user.isExternalParty}
                                      name="email"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.email && touched.email
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("Phone Number")}
                                    </Label>
                                    <Field
                                      name="phoneNumber"
                                      type="number"
                                      className={
                                        "custom-numberfield form-control" +
                                        (errors.phoneNumber &&
                                        touched.phoneNumber
                                          ? " is-invalid"
                                          : "")
                                      }
                                      
                                    />
                                    <ErrorMessage
                                      name="phoneNumber"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <Label className="form-label">
                                    {this.props
                                      .t("Optional")
                                      .replace(
                                        "*",
                                        this.props.t("Profile Picture")
                                      )}
                                  </Label>
                                  <FormUpload
                                    file={file}
                                    onFilesAccepted={onFilesAccepted}
                                    accept={DEFAULT_ACCEPTED_IMAEGES}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <RadioStepper
                      steps={[1, 2]}
                      activeTab={this.state.activeTab}
                    />
                    <div className="actions clearfix" style={{ marginTop: 40 }}>
                      <ul className="day-text-end">
                        {this.state.activeTab !== 1 && (
                          <button
                            type="button"
                            className="btn customTable-button"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                            style={{ marginInlineEnd: 10 }}
                          >
                            {this.props.t("Previous")}
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn customTable-button"
                          disabled={!this.isValid(values, errors)}
                          onClick={e => {
                            e.preventDefault();
                            if (this.state.activeTab === 2) {
                              this.props.handleSubmit(values);
                              this.toggleTab(1);
                            } else {
                              this.toggleTab(this.state.activeTab + 1);
                            }
                          }}
                        >
                          {this.state.activeTab === 2
                            ? isEdit
                              ? this.props.t("Update")
                              : this.props.t("Send Invite")
                            : this.props.t("Next")}
                        </button>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

IndividualModal.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any,
  handleSubmit: PropTypes.func,
  user: PropTypes.any,
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  toggle: PropTypes.func,
  file: PropTypes.any,
  onFilesAccepted: PropTypes.func,
  entityLookups: PropTypes.any,
  org: PropTypes.any,
};

export default withTranslation()(IndividualModal);
