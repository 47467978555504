import React, { Component } from "react";
import PropTypes from "prop-types";

import { Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Select, { components } from "react-select";
import i18n from "i18n";

//Social Media Imports
// import TwitterLogin from "react-twitter-auth"

//Import config

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginInternalUser, setLanguage } from "../../store/actions";

// import images
import logo from "../../assets/images/language.svg";
import CarouselPage from "pages/AuthenticationInner/CarouselPage";
import "./Login-style.css";
import { Colors, CURRENT_LANG } from "common";
import Seo from "common/Seo";
class LoginInternal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: {
        label: CURRENT_LANG === "en" ? this.props.t("En") : this.props.t("Ar"),
        value: CURRENT_LANG === "en" ? "en" : "ar",
      },
      email: "",
      password: "",
      showPassword: false,
      width: props.width,
    };
    this.PasswordComponent = this.PasswordComponent.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount() {
    // this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.props.apiError("");
  }
  resize() {
    this.setState({ width: window.innerWidth <= 990 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  handleLanguageChange() {
    let lang = CURRENT_LANG === "en" ? "ar" : "en";
    this.setState({
      language: lang,
    });
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    this.props.setLanguage(lang);
  }

  PasswordComponent = (stateKey, field, disabled = false) => {
    let languagePosition = CURRENT_LANG === "en" ? "right" : "left";

    return (
      <div style={{ position: "relative" }}>
        <input
          name="password"
          placeholder={this.props.t("Enter Password")}
          type={this.state[stateKey] ? "text" : "password"}
          className={"form-control"}
          disabled={disabled}
          {...field}
        />
        <button
          type="button"
          className="btn btn-primary"
          style={{
            backgroundColor: "transparent",
            border: "0px",
            position: "absolute",
            [languagePosition]: 0,
            top: 0,
            zIndex: 10,
          }}
          onClick={() => {
            this.setState({
              [stateKey]: !this.state[stateKey],
            });
          }}
          disabled={disabled}
        >
          <i
            className={`far ${
              this.state[stateKey] ? "fa-eye" : "fa-eye-slash"
            } d-block font-size-18`}
            style={{ color: Colors.grey }}
          ></i>
        </button>
      </div>
    );
  };

  render() {
    let mobileStyle = this.state.width;
    let languagePosition = CURRENT_LANG === "en" ? "left" : "right";
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={logo} />
        </components.DropdownIndicator>
      );
    };

    return (
      <React.Fragment>
        <div>
          <Seo title={this.props.t("loginProtal")} />
          <Container fluid className="p-0">
            <Row className="g-0">
              {mobileStyle ? "" : <CarouselPage />}
              <Col
                className={mobileStyle ? "" : "px-6"}
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                xxl={8}
                style={{
                  backgroundColor: "#F2F5FA",
                }}
              >
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div style={{ textAlign: "center" }}>
                          <h1 className="text-dark">
                            {this.props.t("welcome")}
                          </h1>
                          <p className="text-dark text-nowrap">
                            {this.props.t("welcomeDesc")}
                          </p>
                        </div>

                        <div className="mt-4">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              email: this.state && this.state.email,
                              password: this.state && this.state.password,
                            }}
                            validationSchema={Yup.object().shape({
                              email: Yup.string().required(
                                this.props.t("errorEmail")
                              ),
                              password: Yup.string().required(
                                this.props.t("errorLogginPassword")
                              ),
                            })}
                            onSubmit={values => {
                              this.setState({ loading: true }, () => {
                                this.props.loginInternalUser(
                                  values,
                                  this.props.history
                                );
                              });
                            }}
                          >
                            {({ errors, status, touched, values }) => (
                              <Form className="form-horizontal">
                                <div className="mb-3">
                                  <Label for="email" className="form-label">
                                    {this.props.t("Username")}
                                  </Label>
                                  <Field
                                    name="email"
                                    placeholder={this.props.t("Enter Username")}
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    {this.props.t("Password")}
                                  </Label>

                                  <Field name="password">
                                    {({ field, form: { touched, errors } }) =>
                                      this.PasswordComponent(
                                        "showPassword",
                                        field,
                                        false
                                      )
                                    }
                                  </Field>
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                    disabled={
                                      this.props.loading ||
                                      !!!values.email ||
                                      !!!values.password
                                    }
                                  >
                                    {this.props.loading && (
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2">
                                        {" "}
                                      </i>
                                    )}
                                    {this.props.t("signIn")}
                                  </button>
                                </div>
                                <div className="mt-3 d-grid">
                                  <p className="text-muted text-center">
                                    {this.props.t("Trouble")}
                                  </p>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center text-muted">
                        <p className="mb-0">
                          © {new Date().getFullYear()}. {this.props.t("Rights")}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: 30,
                        [languagePosition]: 20,
                      }}
                    >
                      <Select
                        value={this.state.viewType}
                        components={{ DropdownIndicator }}
                        onChange={value => {
                          this.setState({ viewType: value });
                          this.handleLanguageChange();
                        }}
                        options={[
                          { label: this.props.t("En"), value: "en" },
                          { label: this.props.t("Ar"), value: "ar" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

LoginInternal.propTypes = {
  apiError: PropTypes.any,
  t: PropTypes.any,
  error: PropTypes.any,
  setLanguage: PropTypes.func,
  history: PropTypes.object,
  loginInternalUser: PropTypes.func,
  loading: PropTypes.bool,
  height: PropTypes.any,
  width: PropTypes.any,
};

const mapStateToProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default connect(mapStateToProps, {
  loginInternalUser,
  apiError,
  setLanguage,
})(withTranslation()(withRouter(LoginInternal)));
