import {
  GET_ALL_CORRESPONDENCE,
  GET_ALL_CORRESPONDENCE_FAIL,
  GET_ALL_CORRESPONDENCE_SUCCESS,
  GET_CORRESPONDENCE_DETAILS,
  GET_CORRESPONDENCE_DETAILS_SUCCESS,
  GET_CORRESPONDENCE_DETAILS_FAIL,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAIL,
  GET_COMMENTS,
  UPSERT_CORRESPONDENCE,
  UPSERT_CORRESPONDENCE_SUCCESS,
  UPSERT_CORRESPONDENCE_FAIL,
  GET_CORRESPONDENCE_FILES,
  GET_CORRESPONDENCE_FILES_SUCCESS,
  GET_CORRESPONDENCE_FILES_FAIL,
  GET_CORRESPONDENCES_OVERVIEW,
  GET_CORRESPONDENCES_OVERVIEW_SUCCESS,
  GET_CORRESPONDENCES_OVERVIEW_FAIL,
  CLEAR_CORRESPONDECE,
  DELETE_CORRESPONDENCE,
  DELETE_CORRESPONDENCE_SUCCESS,
  DELETE_CORRESPONDENCE_FAIL,
} from "./actionTypes";

export const getAllCorres = () => ({
  type: GET_ALL_CORRESPONDENCE,
});

export const clearCorrespondence = () => ({
  type: CLEAR_CORRESPONDECE,
});

export const getAllCorresSuccess = correspondences => ({
  type: GET_ALL_CORRESPONDENCE_SUCCESS,
  payload: correspondences,
});

export const getAllCorresFail = error => ({
  type: GET_ALL_CORRESPONDENCE_FAIL,
  payload: error,
});

export const getCorrespondenceDetails = id => ({
  type: GET_CORRESPONDENCE_DETAILS,
  payload: id,
});

export const getCorrespondenceDetailsSuccess = correspondenceDetail => ({
  type: GET_CORRESPONDENCE_DETAILS_SUCCESS,
  payload: correspondenceDetail,
});

export const getCorrespondenceDetailsFail = error => ({
  type: GET_CORRESPONDENCE_DETAILS_FAIL,
  payload: error,
});

export const getComments = () => ({
  type: GET_COMMENTS,
});

export const getCommentsSuccess = id => ({
  type: GET_COMMENTS_SUCCESS,
  payload: id,
});

export const getCommentsFail = error => ({
  type: GET_COMMENTS_FAIL,
  payload: error,
});

export const upsertCorrespondence = (payload, callback) => ({
  type: UPSERT_CORRESPONDENCE,
  payload,
  callback,
});

export const upsertCorrespondenceSuccess = payload => ({
  type: UPSERT_CORRESPONDENCE_SUCCESS,
  payload,
});

export const upsertCorrespondenceFail = error => ({
  type: UPSERT_CORRESPONDENCE_FAIL,
  payload: error,
});

export const getCorrespondenceFiles = (payload, callback) => ({
  type: GET_CORRESPONDENCE_FILES,
  payload,
  callback,
});

export const getCorrespondenceFilesSuccess = payload => ({
  type: GET_CORRESPONDENCE_FILES_SUCCESS,
  payload,
});

export const getCorrespondenceFilesFail = error => ({
  type: GET_CORRESPONDENCE_FILES_FAIL,
  payload: error,
});

export const getAllCorrespondenceOverview = payload => ({
  type: GET_CORRESPONDENCES_OVERVIEW,
  payload,
});

export const getAllCorrespondenceOverviewSuccess = project => ({
  type: GET_CORRESPONDENCES_OVERVIEW_SUCCESS,
  payload: project,
});

export const getAllCorrespondenceOverviewFail = error => ({
  type: GET_CORRESPONDENCES_OVERVIEW_FAIL,
  payload: error,
});

export const deleteCorrespondence = (payload, callback) => ({
  type: DELETE_CORRESPONDENCE,
  payload,
  callback,
});

export const deleteCorrespondenceSuccess = payload => ({
  type: DELETE_CORRESPONDENCE_SUCCESS,
  payload,
});

export const deleteCorrespondenceFail = error => ({
  type: DELETE_CORRESPONDENCE_FAIL,
  payload: error,
});

