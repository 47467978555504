import {
  GET_APPROVALS_SUCCESS,
  GET_APPROVALS_FAIL,
  GET_APPROVALS,
  GET_APPROVAL_SUCCESS,
  GET_APPROVAL_FAIL,
  GET_APPROVAL,
  UPSERT_APPROVAL_SUCCESS,
  UPSERT_APPROVAL_FAIL,
  UPSERT_APPROVAL,
  SUBMIT_APPROVAL_PATH,
  GET_APPROVAL_FILES_SUCCESS,
  GET_APPROVAL_FILES_FAIL,
  GET_APPROVAL_FILES,
  GET_ALL_APPROVALS_OVERVIEW,
  GET_ALL_APPROVALS_OVERVIEW_SUCCESS,
  GET_ALL_APPROVALS_OVERVIEW_FAIL,
  CLEAR_APPROVAL,
  DELETE_APPROVAL,
  DELETE_APPROVAL_SUCCESS,
  DELETE_APPROVAL_FAIL,
  // GET_COMMENTS_IN_APPROVAL,
  // GET_COMMENTS_IN_APPROVAL_FAIL,
  // GET_COMMENTS_IN_APPROVAL_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  approvals: [],
  approval: null,
  error: "",
  loading: false,
  comments: [],
  files: null,
  approvalsOverview: null,
};

const approvals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_APPROVAL:
      return {
        ...state,
        approval: null,
      };
    case GET_APPROVAL_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        loading: false,
        error: "",
      };

    case GET_APPROVAL_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_APPROVAL_FILES:
      return {
        ...state,
        loading: true,
      };

    case GET_APPROVALS_SUCCESS:
      return {
        ...state,
        approvals: action.payload?.reverse(),
        loading: false,
        error: "",
      };

    case GET_APPROVALS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_APPROVALS:
      return {
        ...state,
        loading: true,
      };

    case GET_APPROVAL_SUCCESS:
      return {
        ...state,
        approval: action.payload,
        loading: false,
        error: "",
      };

    case GET_APPROVAL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_APPROVAL:
      return {
        ...state,
        loading: true,
      };
    case UPSERT_APPROVAL:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_APPROVAL_PATH:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_APPROVAL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ALL_APPROVALS_OVERVIEW:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_APPROVALS_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ALL_APPROVALS_OVERVIEW_SUCCESS:
      return {
        ...state,
        approvalsOverview: action.payload,
        loading: false,
        error: "",
      };
    // case GET_COMMENTS_IN_APPROVAL:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case GET_COMMENTS_IN_APPROVAL_SUCCESS:
    //   let _comments = action.payload.map(_comment => {
    //     return {
    //       idComments: _comment.idComments,
    //       description: _comment.description,
    //       commentedBy: _comment.commentedBy,
    //       commentedOn: _comment.commentedOn,
    //     };
    //   });
    //   return {
    //     ...state,
    //     comments: _comments,
    //     loading: false,
    //   };

    // case GET_COMMENTS_IN_APPROVAL_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };
    case DELETE_APPROVAL:
      return {
        ...state,
        loading: true,
      };
    case DELETE_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_APPROVAL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default approvals;
