import PropTypes from "prop-types";
import React, { Component } from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  CardTitle,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb

// actions
import {
  changeTopbarTheme,
  getInternalDetails,
  getExternalDetails,
  setLanguage,
  updateUser,
  changePassword,
} from "../../store/actions";
import { withTranslation } from "react-i18next";
import i18n from "i18n";
import "./profile-style.css";
import {
  Colors,
  CURRENT_LANG,
  customLocalStorage,
  DEFAULT_ACCEPTED_IMAEGES,
  isInternal,
  TOAST_OPTIONS,
  USER_TYPES,
} from "common";
import Loading from "pages/Loading";
import { toast } from "react-toastify";
import EditIcon from "components/Common/EditIcon";
import FormUpload from "pages/Forms/FormUpload";
import { getBase64 } from "common/utils";
import NotificationSettings from "./NotificationSettings";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: 1,
      employeeIdTooltip: false,
      emailTooltip: false,
      roleTooltip: false,
      passwordTooltip: false,
      departmentTooltip: false,
      sectorTooltip: false,
      showOldPass: false,
      showNewPass: false,
      showConfirmedPass: false,
      language: null,
      mode: null,
      passwordModal: false,
      editImageModal: false,
      editModal: false,
      verticalActiveTab: "1",
      isUpadtingUser: false,
      key: "",
      label: "",
      value: "",
    };
    this.toggleVertical = this.toggleVertical.bind(this);
    this.PasswordComponent = this.PasswordComponent.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.renderEditTextModal = this.renderEditTextModal.bind(this);
    this.renderPasswordModal = this.renderPasswordModal.bind(this);
    this.toggleEditImage = this.toggleEditImage.bind(this);
    this.regexChar = /^(?=.*[!@#$%^&*()_\+\-\=]).+$/;
    this.regexUpper = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
    this.regexNumbers = /^(?=.*[1-9]).+$/;
  }

  componentDidMount() {
    const user = JSON.parse(customLocalStorage.getItem("authUser"));

    if (user) {
      // to check if the user is logged in
      const userType = JSON.parse(customLocalStorage.getItem("userType"));
      if (userType == USER_TYPES.EXTERNAL_USER) {
        this.props.getExternalDetails();
      } else if (userType == USER_TYPES.INTERNAL_USER) {
        this.props.getInternalDetails();
      }
    }

    const currentLanguage = CURRENT_LANG;
    const mode = localStorage.getItem("mode") ?? "light";
    this.setState({
      language: currentLanguage,
      mode,
    });
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      });
    }
  }

  PasswordComponent = (stateKey, field, disabled = false) => {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <input
          name="password"
          placeholder={this.props.t("Enter")}
          type={this.state[stateKey] ? "text" : "password"}
          className={"form-control"}
          disabled={disabled}
          {...field}
        />
        <button
          type="button"
          className="btn btn-primary"
          style={{
            backgroundColor: "transparent",
            border: "0px",
            position: "absolute",
            zIndex: 10,
          }}
          onClick={() => {
            this.setState({
              [stateKey]: !this.state[stateKey],
            });
          }}
          disabled={disabled}
        >
          <i
            className={`far ${
              this.state[stateKey] ? "fa-eye" : "fa-eye-slash"
            } d-block font-size-18`}
            style={{ color: Colors.grey }}
          ></i>
        </button>
      </div>
    );
  };

  renderPasswordModal() {
    return (
      <Modal isOpen={this.state.passwordModal} size="lg">
        <ModalHeader
          toggle={() => {
            this.setState({ passwordModal: !this.state.passwordModal });
          }}
          tag="h4"
        >
          {this.props.t("Set New Password")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              password: "",
              newPassword: "",
              confirmNewPassword: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required(this.props.t("errorPassword")),
              newPassword: Yup.string().required(
                this.props.t("errorNewPassword")
              ),
              confirmNewPassword: Yup.string()
                .required(this.props.t("errorConfirmNewPassword"))
                .when("newPassword", {
                  is: val => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    this.props.t("errorBothPassword")
                  ),
                }),
            })}
            onSubmit={values => {
              let payload = {
                currentPassword: values.password,
                newPassword: values.newPassword,
              };
              this.setState({ passwordModal: false });
              this.props.onChangePassword(payload, () => {
                toast.success(
                  this.props
                    .t("SuccessUpdate")
                    .replace("*", this.props.t("Password")),
                  TOAST_OPTIONS
                );
              });
            }}
          >
            {({ errors, status, touched, values }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        {this.props.t("Old Password")}
                      </Label>
                      <Field name="password">
                        {({ field, form: { touched, errors } }) =>
                          this.PasswordComponent("showOldPass", field, false)
                        }
                      </Field>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        {this.props.t("New Password")}
                      </Label>

                      <Field name="newPassword">
                        {({ field, form: { touched, errors } }) =>
                          this.PasswordComponent(
                            "showNewPass",
                            field,
                            !!!values.password
                          )
                        }
                      </Field>
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="day-column mb-2">
                      <div className="day-row mb-1">
                        <i
                          className={
                            values.newPassword.length < 8
                              ? "bx bx-bx bx-shield font-size-17"
                              : "bx bx-bx bxs-check-shield font-size-17"
                          }
                          style={{
                            color:
                              values.newPassword.length < 8 ? "grey" : "green",
                          }}
                        >
                          {" "}
                        </i>
                        <p className="mb-0" style={{ marginInlineStart: 5 }}>
                          {this.props.t("At least 8")}
                        </p>
                      </div>
                      <div className="day-row mb-1">
                        <i
                          className={
                            !this.regexChar.test(values.newPassword)
                              ? "bx bx-bx bx-shield font-size-17"
                              : "bx bx-bx bxs-check-shield font-size-17"
                          }
                          style={{
                            color: !this.regexChar.test(values.newPassword)
                              ? "grey"
                              : "green",
                          }}
                        >
                          {" "}
                        </i>
                        <p className="mb-0" style={{ marginInlineStart: 5 }}>
                          {this.props.t("Must Contain Special")}
                        </p>
                      </div>
                      <div className="day-row mb-1">
                        <i
                          className={
                            !this.regexUpper.test(values.newPassword)
                              ? "bx bx-bx bx-shield font-size-17"
                              : "bx bx-bx bxs-check-shield font-size-17"
                          }
                          style={{
                            color: !this.regexUpper.test(values.newPassword)
                              ? "grey"
                              : "green",
                          }}
                        >
                          {" "}
                        </i>
                        <p className="mb-0" style={{ marginInlineStart: 5 }}>
                          {this.props.t("Must Contain Upper")}
                        </p>
                      </div>
                      <div className="day-row mb-1">
                        <i
                          className={
                            !this.regexNumbers.test(values.newPassword)
                              ? "bx bx-bx bx-shield font-size-17"
                              : "bx bx-bx bxs-check-shield font-size-17"
                          }
                          style={{
                            color: !this.regexNumbers.test(values.newPassword)
                              ? "grey"
                              : "green",
                          }}
                        >
                          {" "}
                        </i>
                        <p className="mb-0" style={{ marginInlineStart: 5 }}>
                          {this.props.t("Must Contain Letters")}
                        </p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        {this.props.t("Confirm Password")}
                      </Label>
                      <Field name="confirmNewPassword">
                        {({ field, form: { touched, errors } }) =>
                          this.PasswordComponent(
                            "showConfirmedPass",
                            field,
                            !!!values.password
                          )
                        }
                      </Field>
                      <ErrorMessage
                        name="confirmNewPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-start">
                      <button
                        type="submit"
                        className="btn btn-primary save-task"
                        disabled={
                          !!!values.password ||
                          !!!values.newPassword ||
                          !!!values.confirmNewPassword ||
                          values.newPassword.length < 8 ||
                          !this.regexChar.test(values.newPassword) ||
                          !this.regexUpper.test(values.newPassword) ||
                          !this.regexNumbers.test(values.newPassword) ||
                          values.newPassword !== values.confirmNewPassword
                        }
                      >
                        {this.props.t("Update")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }

  handleLanguageChange(e) {
    let lang = e.target.value;
    this.setState({
      language: lang,
    });
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    this.props.setLanguage(lang);
  }

  async handleUserUpdate(values) {
    const { onUpdateUser, getExternalDetails, getInternalDetails, success } =
      this.props;
    if (values.image) {
      values.image = await getBase64(values.image);
    }

    let obj = values;
    const user = {
      idContact: success.idContact,
      firstname: success.firstname,
      lastname: success.lastname,
      email: success.email,
      idRole: success.roleId || "00000000-0000-0000-0000-000000000000", //-
      jobTitle: values.jobTitle ? obj[Object.keys(obj)[0]] : success.jobTitle,
      idOrganization: success.organizationId, //-
      phoneNumber: values.phoneNumber 
        ? typeof obj[Object.keys(obj)[0]] === "number" ? obj[Object.keys(obj)?.[0]]?.toString() : obj[Object.keys(obj)?.[0]]
        : success.phoneNumber,
      department: success.department,
      employeeId: success.employeeId,
      profileImage: values.image ? obj[Object.keys(obj)[0]] : success.image, //-
      sectors: success.sectors,
      isDepartmentChampion: this.props.success.isDepartmentChampion ?? false,
    };

    this.setState({ isUpadtingUser: true });
    onUpdateUser(user, () => {
      toast.success(
        this.props.t("SuccessUpdate").replace("*", this.props.t("Profile")),
        TOAST_OPTIONS
      );
      if (!isInternal) {
        getExternalDetails(() => {
          this.setState({ isUpadtingUser: false });
        });
      } else {
        getInternalDetails(() => {
          this.setState({ isUpadtingUser: false });
        });
      }
    });
    setTimeout(() => {
      this.setState({ isUpadtingUser: false });
    }, 2000);
    if (values.image) {
      this.toggleEditImage();
    } else {
      this.toggleEditModal();
    }
  }

  toggleEditModal(key = "", label = "", value = "") {
    if (!!key) {
      this.setState({ key, label, value }, () => {
        this.setState({ editModal: !this.state.editModal });
      });
    } else {
      this.setState({ editModal: !this.state.editModal });
    }
  }

  toggleEditImage() {
    this.setState({ editImageModal: !this.state.editImageModal });
  }

  renderEditTextModal() {
    return (
      <Modal isOpen={this.state.editModal} size="sm" centered>
        <ModalHeader toggle={() => this.toggleEditModal()} tag="h4">
          {this.props.t("Update Profile")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              [this.state.key]: this.state.value || "",
            }}
            validationSchema={Yup.object().shape({
              [this.state.key]: Yup.string().required(),
            })}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">{this.state.label}</Label>
                        <Field
                          name={this.state.key}
                          type={this.state.key === 'phoneNumber' ? 'number' : "text"}
                          placeholder={this.props.t("Enter")}
                          className={
                            "form-control" +
                            (errors[this.state.key] && touched[this.state.key]
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name={this.state.key}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn customTable-button save-task"
                        disabled={!!!values[this.state.key]}
                        onClick={() => {
                          this.handleUserUpdate(values);
                        }}
                      >
                        {this.props.t("Update")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }

  renderEditProfileImage() {
    return (
      <Modal isOpen={this.state.editImageModal} size="md" centered>
        <ModalHeader toggle={() => this.toggleEditImage()} tag="h4">
          {this.props.t("Update Profile Image")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              image: "",
            }}
            validationSchema={Yup.object().shape({
              image: Yup.mixed().required(),
            })}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col lg={12}>
                    <Label className="form-label">
                      {this.props.t("Profile Picture")}
                    </Label>
                    <FormUpload
                      file={values.image}
                      onFilesAccepted={file => {
                        setFieldValue("image", file[0]);
                      }}
                      accept={DEFAULT_ACCEPTED_IMAEGES}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn customTable-button save-task"
                        disabled={!!!values.image}
                        onClick={() => {
                          this.handleUserUpdate(values);
                        }}
                      >
                        {this.props.t("Update")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
  render() {
    const { isUpadtingUser } = this.state;

    if (
      isUpadtingUser ||
      this.props.loading ||
      !this.state.language ||
      !this.state.mode ||
      !!!this.props.success ||
      Object.keys(this.props.success).length === 0
    ) {
      return <Loading />;
    }
    const {
      firstname,
      lastname,
      jobTitle,
      organization,
      email,
      phoneNumber,
      image,
      roleName,
      employeeId,
      sectors,
      department,
      isDepartmentChampion,
    } = this.props.success;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="g-1">
              <Col lg={4}>
                <Card>
                  <CardBody style={{ height: "90vh", paddingInlineEnd: 0 }}>
                    <div className="day-colum day-justify-between day-align-items-center day-w-100 day-h-100">
                      <div className="day-column day-align-items-center day-w-100 day-h-100">
                        <div>
                          {!!image && (
                            <img
                              onClick={this.toggleEditImage}
                              src={`data:image/png;base64,${image}`}
                              alt={`${firstname} ${lastname}`}
                              width={150}
                              height={150}
                              style={{
                                borderRadius: "50%",
                              }}
                              className="day-pointer"
                            />
                          )}
                          <EditIcon
                            onClick={this.toggleEditImage}
                            style={{
                              backgroundColor: Colors.searchBG,
                              borderRadius: 10,
                              marginInlineStart: 5,
                            }}
                          />
                        </div>
                        <div className="day-column-nowrap day-align-items-center day-align-center mt-2">
                          <p className="mb-0 font-size-20 day-dark-text">{`${firstname} ${lastname}`}</p>
                          <p className="mb-0 font-size-14 day-dark-text">{`${
                            jobTitle ? jobTitle : ""
                          }`}</p>
                          {isDepartmentChampion && (
                            <p className="text-muted font-size-12 mb-1">
                              {this.props.t("Department Champion")}
                            </p>
                          )}
                          <p className="text-muted font-size-12">
                            {CURRENT_LANG === "ar"
                              ? organization?.labelAr || organization?.label
                              : organization?.label}
                          </p>
                        </div>
                        <div className="day-column day-align-items-start day-justify-start day-w-100 mt-4">
                          <Nav
                            pills
                            className="flex-column day-align-items-start day-w-100"
                          >
                            <NavItem
                              style={{
                                width: "100%",
                              }}
                            >
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "transparent",
                                  borderRight:
                                    this.state.verticalActiveTab === "1"
                                      ? `3px solid ${Colors.blue}`
                                      : 0,
                                  borderRadius: 0,
                                }}
                                className={classnames({
                                  "mb-2": true,
                                  h6: true,
                                  active: this.state.verticalActiveTab === "1",
                                  "text-muted":
                                    this.state.verticalActiveTab !== "1",
                                  "text-primary":
                                    this.state.verticalActiveTab === "1",
                                })}
                                onClick={() => {
                                  this.toggleVertical("1");
                                }}
                              >
                                {this.props.t("Basics")}
                              </NavLink>
                            </NavItem>
                            <NavItem
                              style={{
                                width: "100%",
                              }}
                            >
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "transparent",
                                  borderRight:
                                    this.state.verticalActiveTab === "2"
                                      ? `3px solid ${Colors.blue}`
                                      : 0,
                                  borderRadius: 0,
                                }}
                                className={classnames({
                                  "mb-2": true,
                                  h6: true,
                                  active: this.state.verticalActiveTab === "2",
                                  "text-muted":
                                    this.state.verticalActiveTab !== "2",
                                  "text-primary":
                                    this.state.verticalActiveTab === "2",
                                })}
                                onClick={() => {
                                  this.toggleVertical("2");
                                }}
                              >
                                {this.props.t("Notification Settings")}
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={8}>
                <TabContent activeTab={this.state.verticalActiveTab}>
                  <TabPane tabId="1">
                    <Card style={{ marginBottom: 5 }}>
                      <CardBody style={{}}>
                        <CardTitle className="h6 day-dark-text-bold">
                          {this.props.t("Personal Information")}
                        </CardTitle>
                        <div className="day-column-nowrap mt-2 day-w-100">
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("EmployeeIdDetails")}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >
                                {employeeId}
                              </h6>
                              <i
                                className="bx bx-bx bx-lock font-size-18 text-muted"
                                id="TooltipTop1"
                              ></i>
                              <Tooltip
                                placement="top"
                                isOpen={this.state.employeeIdTooltip}
                                target="TooltipTop1"
                                toggle={() =>
                                  this.setState({
                                    employeeIdTooltip:
                                      !this.state.employeeIdTooltip,
                                  })
                                }
                              >
                                {`${this.props.t("Contact Admin")}`}
                              </Tooltip>
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("role")}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >{`${
                                isInternal
                                  ? this.props.t("Internal")
                                  : this.props.t("External")
                              }`}</h6>
                              <i
                                className="bx bx-bx bx-lock font-size-18 text-muted"
                                id="TooltipTop4"
                              >
                                {" "}
                              </i>
                              <Tooltip
                                placement="top"
                                isOpen={this.state.roleTooltip}
                                target="TooltipTop4"
                                toggle={() =>
                                  this.setState({
                                    roleTooltip: !this.state.roleTooltip,
                                  })
                                }
                              >
                                {`${this.props.t("Contact Admin")}`}
                              </Tooltip>
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("Sector")}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >
                                {sectors}
                              </h6>
                              <i
                                className="bx bx-bx bx-lock font-size-18 text-muted"
                                id="TooltipTop4"
                              >
                                {" "}
                              </i>
                              <Tooltip
                                placement="top"
                                isOpen={this.state.sectorTooltip}
                                target="TooltipTop4"
                                toggle={() =>
                                  this.setState({
                                    sectorTooltip: !this.state.sectorTooltip,
                                  })
                                }
                              >
                                {`${this.props.t("Contact Admin")}`}
                              </Tooltip>
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("Department")?.toUpperCase()}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >
                                {department}
                              </h6>
                              <i
                                className="bx bx-bx bx-lock font-size-18 text-muted"
                                id="TooltipTop4"
                              >
                                {" "}
                              </i>
                              <Tooltip
                                placement="top"
                                isOpen={this.state.departmentTooltip}
                                target="TooltipTop4"
                                toggle={() =>
                                  this.setState({
                                    departmentTooltip:
                                      !this.state.departmentTooltip,
                                  })
                                }
                              >
                                {`${this.props.t("Contact Admin")}`}
                              </Tooltip>
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("Job Title").toUpperCase()}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >
                                {jobTitle}
                              </h6>

                              <EditIcon
                                onClick={() => {
                                  this.toggleEditModal(
                                    "jobTitle",
                                    this.props.t("Job Title").toUpperCase(),
                                    jobTitle
                                  );
                                }}
                                style={{
                                  padding: 0,
                                }}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("EmailForm")}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >{`${email ? email : ""}`}</h6>
                              <i
                                className="bx bx-bx bx-lock font-size-18 text-muted"
                                id="TooltipTop2"
                              >
                                {" "}
                              </i>
                              <Tooltip
                                placement="top"
                                isOpen={this.state.emailTooltip}
                                target="TooltipTop2"
                                toggle={() =>
                                  this.setState({
                                    emailTooltip: !this.state.emailTooltip,
                                  })
                                }
                              >
                                {`${this.props.t("Contact Admin")}`}
                              </Tooltip>
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("Phone Number Form")}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >{`${phoneNumber ? phoneNumber : ""}`}</h6>

                              <EditIcon
                                onClick={() => {
                                  this.toggleEditModal(
                                    "phoneNumber",
                                    this.props.t("Phone Number").toUpperCase(),
                                    phoneNumber
                                  );
                                }}
                                style={{
                                  padding: 0,
                                }}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("PasswordForm")}
                            </h6>
                            <div className="day-row-nowrap">
                              <h6
                                className="day-dark-text mb-0"
                                style={{ marginInlineEnd: 10 }}
                              >{`**********`}</h6>
                              {isInternal ? (
                                <>
                                  <i
                                    className="bx bx-bx bx-lock font-size-18 text-muted"
                                    id="PasswordTooltip"
                                  >
                                    {" "}
                                  </i>
                                  <Tooltip
                                    placement="top"
                                    isOpen={this.state.passwordTooltip}
                                    target="PasswordTooltip"
                                    toggle={() =>
                                      this.setState({
                                        passwordTooltip:
                                          !this.state.passwordTooltip,
                                      })
                                    }
                                  >
                                    {`${this.props.t("Contact Admin")}`}
                                  </Tooltip>
                                </>
                              ) : (
                                <EditIcon
                                  onClick={() => {
                                    this.setState({
                                      passwordModal: !this.state.passwordModal,
                                    });
                                  }}
                                  style={{
                                    padding: 0,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody style={{}}>
                        <CardTitle className="h6 day-dark-text-bold">
                          {this.props.t("Preferences")}
                        </CardTitle>
                        <div className="day-column-nowrap day-w-100 mt-2">
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("Default Language")}
                            </h6>

                            <div className="day-row">
                              <div
                                className="form-check"
                                style={{ marginInlineEnd: 10 }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="action"
                                  id="radio1"
                                  value="en"
                                  checked={this.state.language === "en"}
                                  onChange={this.handleLanguageChange}
                                />
                                <label
                                  className="form-check-label day-dark-text"
                                  htmlFor="radio1"
                                  style={{ fontWeight: "normal" }}
                                >
                                  {this.props.t("English")}
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{ marginInlineEnd: 10 }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="action"
                                  id="radio2"
                                  value="ar"
                                  checked={this.state.language === "ar"}
                                  onChange={this.handleLanguageChange}
                                />
                                <label
                                  className="form-check-label day-dark-text"
                                  htmlFor="radio2"
                                  style={{ fontWeight: "normal" }}
                                >
                                  {this.props.t("Arabic")}
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>

                        {/* <div className="day-column-nowrap mt-2 day-w-100">
                          <div className="day-row-nowrap day-justify-between day-align-center">
                            <h6 className="text-muted mb-0">
                              {this.props.t("Default Mode")}
                            </h6>

                            <div className="day-row">
                              <div
                                className="form-check"
                                style={{ marginInlineEnd: 10 }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="action2"
                                  id="radio3"
                                  value="light"
                                  checked={this.state.mode === "light"}
                                  onChange={e => {
                                    this.props.changeTopbarTheme("light");
                                    localStorage.setItem("mode", "light");
                                    this.setState({ mode: "light" });
                                  }}
                                />
                                <label
                                  className="form-check-label day-dark-text"
                                  htmlFor="radio3"
                                  style={{ fontWeight: "normal" }}
                                >
                                  {this.props.t("Light")}
                                </label>
                              </div>{" "}
                              <div
                                className="form-check"
                                style={{ marginInlineEnd: 10 }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="action2"
                                  id="radio3"
                                  value="dark"
                                  checked={this.state.mode === "dark"}
                                  onChange={e => {
                                    this.props.changeTopbarTheme("dark");
                                    localStorage.setItem("mode", "dark");
                                    this.setState({ mode: "dark" });
                                  }}
                                />
                                <label
                                  className="form-check-label day-dark-text"
                                  htmlFor="radio3"
                                  style={{ fontWeight: "normal" }}
                                >
                                  {this.props.t("Dark")}
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>*/}
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId="2">
                    <NotificationSettings
                      setDetailsUpdating={updating =>
                        this.setState({ isUpadtingUser: updating ?? false })
                      }
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>

          {this.renderEditTextModal()}
          {this.renderPasswordModal()}
          {this.renderEditProfileImage()}
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  changeTopbarTheme: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
  getInternalDetails: PropTypes.func,
  getExternalDetails: PropTypes.func,
  loading: PropTypes.bool,
  setLanguage: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onChangePassword: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success, loading } = state.Profile;
  return { error, success, loading };
};

const mapDispatchToProps = dispatch => ({
  changeTopbarTheme: theme => dispatch(changeTopbarTheme(theme)),
  getInternalDetails: callback => dispatch(getInternalDetails(callback)),
  getExternalDetails: callback => dispatch(getExternalDetails(callback)),
  setLanguage: lang => dispatch(setLanguage(lang)),
  onUpdateUser: (user, callback) => dispatch(updateUser(user, callback)),
  onChangePassword: (payload, callback) =>
    dispatch(changePassword(payload, callback)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserProfile))
);
