import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_ALL_TASKS,
  GET_TASK_DETAILS,
  UPSERT_TASK,
  UPSERT_COMMENTS,
  GET_TASK_FILES,
  GET_ALL_TASKS_OVERVIEW,
  DELETE_TASK,
  GET_TASKS_DASHBOARD
} from "./actionTypes";

import {
  getAllTasksSuccess,
  getAllTasksFail,
  getTaskDetailsSuccess,
  getTaskDetailsFail,
  upsertTaskSuccess,
  upsertTaskFail,
  upsertCommentsFail,
  upsertCommentsSuccess,
  getTaskFilesSuccess,
  getTaskFilesFail,
  getAllTasksOverviewSuccess,
  getAllTasksOverviewFail,
  deleteTaskSuccess,
  deleteTaskFail,
  getTasksDashboardSuccess,
  getTasksDashboardFail
} from "./actions";

import {
  getAllTasks,
  getTaskDetails,
  upsertTask,
  upsertComments,
  getRelatedFiles,
  getAllTasksOverview,
  deleteRecord,
  getTaskDashboard
} from "helpers/backend_helper";
import {
  getGenericError,
  isInternal,
  idRole,
  idContact,
  DEFAULT_OPTION,
} from "common";

function* fetchAllTasksOverview({ payload }) {
  try {
    let body = {
      projectId: payload,
    };
    const response = yield call(getAllTasksOverview, body);
    yield put(getAllTasksOverviewSuccess(response.responseObject));
    // callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getAllTasksOverviewFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllTasksOverviewFail(error.message));
    }
  }
}

function* fetchAllTasks({payload}) {
  const body = payload ? {  
    taskStatus:payload.statusCode || '100000001',
    pageCount:payload.pageCount || 10,
    pageNumber:payload.pageNumber || 1,
    searchString:payload.searchString?.trim(),
  } : {};

  try {
    const response = yield call(getAllTasks , body);
    yield put(getAllTasksSuccess({
      tasks: response.responseObject?.lstTasks|| [],
      searchString:body.searchString,
      statusCode:body.taskStatus,
      totalRecordCount:response.responseObject?.totalRecordCount || 0,
      pageNumber:body.pageNumber || 1
    })
    )

  } catch (error) {
    if (error.response) {
      yield put(
        getAllTasksFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllTasksFail(error.message));
    }
  }
}

function* fetchTaskDetails({ payload }) {
  try {
    let body = {
      id: payload,
    };

    const response = yield call(getTaskDetails, body);
    yield put(getTaskDetailsSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getTaskDetailsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getTaskDetailsFail(error.message));
    }
  }
}

function* upsertTaskSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertTask, body);
    yield put(upsertTaskSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertTaskFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertTaskFail(error.message));
    }
  }
}

function* upsertCommentsSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertComments, body);
    yield put(upsertCommentsSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertCommentsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertCommentsFail(error.message));
    }
  }
}

function* fetchTaskFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(getTaskFilesSuccess(response.responseObject.lstDocuments));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getTaskFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getTaskFilesFail(error.message));
    }
  }
}

function* deleteTaskSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternal: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request.idTask,
        idOrganizationCallingUser: DEFAULT_OPTION.value,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteTaskSuccess(response));
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        deleteTaskFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(deleteTaskFail(error.message));
    }
  }
}

function* fetchTaskDashboard() {
  try {
    const response = yield call(getTaskDashboard , {});
    yield put(
      getTasksDashboardSuccess({
        dashboardData: response.responseObject || []
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(
        getTasksDashboardFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getTasksDashboardFail(error.message));
    }
  }
}

function* tasksSaga() {
  yield takeEvery(GET_ALL_TASKS, fetchAllTasks);
  yield takeEvery(GET_TASK_DETAILS, fetchTaskDetails);
  yield takeEvery(UPSERT_TASK, upsertTaskSaga);
  yield takeEvery(UPSERT_COMMENTS, upsertCommentsSaga);
  yield takeEvery(GET_TASK_FILES, fetchTaskFiles);
  yield takeEvery(GET_ALL_TASKS_OVERVIEW, fetchAllTasksOverview);
  yield takeEvery(DELETE_TASK, deleteTaskSaga);  
  yield takeEvery(GET_TASKS_DASHBOARD, fetchTaskDashboard); 
}

export default tasksSaga;
