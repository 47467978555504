import React from "react";
import PropTypes from "prop-types";

import styles from "./Chevron.module.scss";

import chevron from "../../assets/icons/chevron-left.svg";

const Chevron = ({ position, disabled = false }) => {
  return (
    <img
      src={chevron}
      className={`${styles.chevron} ${styles[position]} ${
        disabled && styles.disabled
      }`}
    />
  );
};

Chevron.propTypes = {
  position: PropTypes.oneOf(["previous", "next"]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
};

export default Chevron;
