import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  SET_ORGANIZATION,
  GET_LOOKUPS,
  GET_LOOKUPS_FAIL,
  GET_LOOKUPS_SUCCESS,
  GET_ENTITIY_LOOKUPS,
  GET_ENTITIY_LOOKUPS_FAIL,
  GET_ENTITIY_LOOKUPS_SUCCESS,
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  SET_LANGUAGE,
  SET_PROGRESS,
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_FAIL,
  GET_GLOBAL_SEARCH_SUCCESS,
  GET_LOOKUPS_COUNT,
  GET_LOOKUPS_COUNT_FAIL,
  GET_LOOKUPS_COUNT_SUCCESS
} from "./actionTypes";

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeSidebarThemeImage = themeimage => ({
  type: CHANGE_SIDEBAR_THEME_IMAGE,
  payload: themeimage,
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = isopen => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export const setOrganization = payload => ({
  type: SET_ORGANIZATION,
  payload,
});

export const setLanguage = payload => ({
  type: SET_LANGUAGE,
  payload,
});

export const getLookups = payload => ({
  type: GET_LOOKUPS,
  payload,
});

export const getLookupsFail = payload => ({
  type: GET_LOOKUPS_FAIL,
  payload,
});

export const getLookupsSuccess = payload => ({
  type: GET_LOOKUPS_SUCCESS,
  payload,
});

export const getEntityLookups = (payload, callback) => ({
  type: GET_ENTITIY_LOOKUPS,
  payload,
  callback,
});

export const getEntityLookupsFail = payload => ({
  type: GET_ENTITIY_LOOKUPS_FAIL,
  payload,
});

export const getEntityLookupsSuccess = payload => ({
  type: GET_ENTITIY_LOOKUPS_SUCCESS,
  payload,
});

export const getPermissions = callback => ({
  type: GET_PERMISSIONS,
  callback,
});

export const getPermissionsFail = payload => ({
  type: GET_PERMISSIONS_FAIL,
  payload,
});

export const getPermissionsSuccess = payload => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload,
});

export const setLoadingBarProgress = value => ({
  type: SET_PROGRESS,
  payload: value,
});

export const getGlobalSearchAction = payload => ({
  type: GET_GLOBAL_SEARCH,
  payload,
});

export const getGlobalSearchFail = payload => ({
  type: GET_GLOBAL_SEARCH_FAIL,
  payload,
});

export const getGlobalSearchSuccess = payload => ({
  type: GET_GLOBAL_SEARCH_SUCCESS,
  payload,
});


export const getLookupsCount = payload => ({
  type: GET_LOOKUPS_COUNT,
  payload,
});

export const getLookupsCountFail = payload => ({
  type: GET_LOOKUPS_COUNT_FAIL,
  payload,
});

export const getLookupsCountSuccess = payload => ({
  type: GET_LOOKUPS_COUNT_SUCCESS,
  payload,
});
