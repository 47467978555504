export const GET_ALL_CORRESPONDENCE = "GET_ALL_CORRESPONDENCE";

export const GET_ALL_CORRESPONDENCE_FAIL = "GET_ALL_CORRESPONDENCE_FAIL";

export const GET_ALL_CORRESPONDENCE_SUCCESS = "GET_ALL_CORRESPONDENCE_SUCCESS";

export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAIL = "GET_COMMENTS_FAIL";
export const GET_CORRESPONDENCE_DETAILS = "GET_CORRESPONDENCE_DETAILS";
export const GET_CORRESPONDENCE_DETAILS_SUCCESS =
  "GET_CORRESPONDENCE_DETAILS_SUCCESS";
export const GET_CORRESPONDENCE_DETAILS_FAIL =
  "GET_CORRESPONDENCE_DETAILS_FAIL";

export const UPSERT_CORRESPONDENCE = "UPSERT_CORRESPONDENCE";
export const UPSERT_CORRESPONDENCE_SUCCESS = "UPSERT_CORRESPONDENCE_SUCCESS";
export const UPSERT_CORRESPONDENCE_FAIL = "UPSERT_CORRESPONDENCE_FAIL";

export const GET_CORRESPONDENCE_FILES = "GET_CORRESPONDENCE_FILES";
export const GET_CORRESPONDENCE_FILES_SUCCESS =
  "GET_CORRESPONDENCE_FILES_SUCCESS";
export const GET_CORRESPONDENCE_FILES_FAIL = "GET_CORRESPONDENCE_FILES_FAIL";

export const GET_CORRESPONDENCES_OVERVIEW = "GET_CORRESPONDENCES_OVERVIEW";
export const GET_CORRESPONDENCES_OVERVIEW_SUCCESS =
  "GET_CORRESPONDENCES_OVERVIEW_SUCCESS";
export const GET_CORRESPONDENCES_OVERVIEW_FAIL =
  "GET_CORRESPONDENCES_OVERVIEW_FAIL";
export const CLEAR_CORRESPONDECE = "CLEAR_CORRESPONDECE";

export const DELETE_CORRESPONDENCE = "DELETE_CORRESPONDENCE";
export const DELETE_CORRESPONDENCE_SUCCESS = "DELETE_CORRESPONDENCE_SUCCESS";
export const DELETE_CORRESPONDENCE_FAIL = "DELETE_CORRESPONDENCE_FAIL";

