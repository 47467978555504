import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const KeyHighlights = ({ t, executiveDetails }) => (
  <div className="executive-paragraph p-4 ms-3">
    <p className="day-top-projects mb-3">{t("Key Highlights")}</p>
    <div className="day-column">
      {executiveDetails.projectSummary.streams.map((item, index) => {
        return (
          <div key={index} className="day-row">
            {"• "}
            <strong
              className="text-primary day-pointer mx-1"
              onClick={() => {
                history.push(
                  `/project-details?id=${this.state.selectedProject.value}&tab=2`
                );
              }}
            >{`${item.name}`}</strong>
            {` ${t("is")} ${item.status?.label || ""} ${t("with")} ${
              item.progress
            }%.`}
          </div>
        );
      })}
    </div>
  </div>
);

KeyHighlights.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  executiveDetails: PropTypes.any,
};
export default withTranslation()(withRouter(KeyHighlights));
