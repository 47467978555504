import PropTypes from "prop-types";
import {
  Row,
  Col,
} from "reactstrap";
import Widget from "common/widget";
import { Colors } from "../../common";
const HomeDashboard = props => {
const {
    widgetSize,
    isTaskRead,
    isRequestsRead,
    isApprovalRead,
    isCorresRead,
    data
} = props

const dashboardArr=[
  {
    id:"Tasks",
    isVisble:isTaskRead,
    title:props.t("Tasks"),
    legendText1:props.t("Delayed"),
    legendColor1:Colors.red,
    legendValue1:data.tasks.delayedCount || 0,
    legendText2:props.t("Due Today"),
    legendColor2:Colors.yellow,
    legendValue2:data.tasks.dueTodayCount || 0,
    legendText3:props.t("Completed"),
    legendColor3:Colors.staBlue,
    legendValue3:data.tasks.completedCount || 0,
    link:"/tasks"
    
  },
  {
    id:"Requests",
    isVisble:isRequestsRead,
    title:props.t("Requests"),
    legendText1:props.t("Delayed"),
    legendColor1:Colors.red,
    legendValue1:data.requests.delayedCount || 0,
    legendText2:props.t("Due Today"),
    legendColor2:Colors.yellow,
    legendValue2:data.requests.dueTodayCount || 0,
    legendText3:props.t("Completed"),
    legendColor3:Colors.staBlue,
    legendValue3:data.requests.completedCount || 0,
    link:"/requests"
    
  },
  {
    id:"Approvals",
    isVisble:isApprovalRead,
    title:props.t("Approvals"),
    legendText1:props.t("Delayed"),
    legendColor1:Colors.red,
    legendValue1:data.approvals.delayedCount || 0,
    legendText2:props.t("Due Today"),
    legendColor2:Colors.yellow,
    legendValue2:data.approvals.dueTodayCount || 0,
    legendText3:"",
    legendColor3:"",
    legendValue3:0,
    link:"/approvals"
    
  },
  {
    id:"Correspondence",
    isVisble:isCorresRead,
    title:props.t("Correspondence"),
    legendText1:props.t("Delayed"),
    legendColor1:Colors.red,
    legendValue1:data.correspondences.delayedCount || 0,
    legendText2:props.t("Due Today"),
    legendColor2:Colors.yellow,
    legendValue2:data.correspondences.dueTodayCount || 0,
    legendText3:"",
    legendColor3:"",
    legendValue3:0,
    link:"/correspondence"
    
  }
]
return(<>
<Row className="g-1" id="walkthrough--homepage-charts">

{dashboardArr.map((item) => item.isVisble && 
    <Col
    key={item.id}
    xs={12}
    sm={12}
    md={6}
    lg={widgetSize}
    xl={widgetSize}
    xxl={widgetSize}
    style={{
      height: 235,
    }}
  >
    <Widget
      title={item.title}
      legendText1={item.legendText1}
      legendColor1={item.legendColor1}  
      legendValue1={item.legendValue1}
      legendText2={item.legendText2}
      legendColor2={item.legendColor2}  
      legendValue2={item.legendValue2}
      legendText3={item.legendText3}
      legendColor3={item.legendColor3}  
      legendValue3={item.legendValue3}
      onClick={() => {
        props.history.push(item.link);
      }}
      dataLabelsEnabled
    />
  </Col>
  )
}

</Row>
</>)

}

HomeDashboard.propTypes = {
    widgetSize: PropTypes.any,
    isTaskRead: PropTypes.any,
    isRequestsRead: PropTypes.any,
    isApprovalRead: PropTypes.any,
    isCorresRead: PropTypes.any,
    data: PropTypes.any,
    t: PropTypes.any,
    history: PropTypes.any,
  };
  
export default HomeDashboard