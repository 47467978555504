import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  UPDATE_USER,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_DETAILS,
  UPSERT_ORGANIZATION,
  GET_ORGANIZATION_FILES,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS,
  GET_EXECUTIVE_DASHBOARDS,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  getOrganizationsSuccess,
  getOrganizationsFail,
  getDetailsSuccess,
  getDetailsFail,
  upsertOrganizationFail,
  upsertOrganizationSuccess,
  getOrganizationsFilesSuccess,
  getOrganizationsFilesFail,
  getIndividualLevelDashboardsSuccess,
  getIndividualLevelDashboardsFail,
  getExecutiveDashboardsSuccess,
  getExecutiveDashboardsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserProfile,
  addNewUser,
  updateUser,
  getAllOrganizations,
  getOrganizationDetails,
  upsertOrganization,
  getRelatedFiles,
  getIndividualLevel,
  getExecutiveLevel,
} from "../../helpers/backend_helper";
import { getGenericError } from "common";
import { endTheBar } from "common/utils";

function* fetchIndividualLevelDetails({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(getIndividualLevel, body);
    yield put(getIndividualLevelDashboardsSuccess(response.responseObject));
    endTheBar();
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getIndividualLevelDashboardsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getIndividualLevelDashboardsFail(error.message));
    }
  }
}

function* fetchExecutiveDashboards({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(getExecutiveLevel, body);
    yield put(getExecutiveDashboardsSuccess(response.responseObject));
    endTheBar();
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getExecutiveDashboardsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getExecutiveDashboardsFail(error.message));
    }
  }
}
function* fetchUsers({payload}) {
  try {
    const body={
      pageNumber:payload?.pageNumber || 1,
      pageCount:payload?.pageCount || 10,
      searchString:payload.searchString || ""
    }
    const response = yield call(getUsers , body);
    yield put(getUsersSuccess({
      data: response.responseObject.lstIndividuals || [],
      pageNumber:body.pageNumber,
      searchString:body.searchString,
      totalRecordCount:response.responseObject.totalRecordCount

    }));
  } catch (error) {
    if (error.response) {
      yield put(
        getUsersFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getUsersFail(error.message));
    }
  }
}

function* fetchUserProfile({ payload }) {
  try {
    let body = {
      id: payload,
      entity: "contact",
    };
    const response = yield call(getUserProfile, body);
    yield put(getUserProfileSuccess({ ...response.responseObject }));
  } catch (error) {
    if (error.response) {
      yield put(
        getUserProfileFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getUserProfileFail(error.message));
    }
  }
}

function* onAddNewUser({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(addNewUser, body);
    yield put(addUserSuccess(response));
    callback && callback(response?.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        addUserFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(addUserFail(error.message));
    }
  }
}

function* onUpdateUser({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(updateUser, body);
    yield put(updateUserSuccess(response));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        updateUserFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(updateUserFail(error.message));
    }
  }
}

function* fetchOrganizations({payload}) {
  try {
    const body={
      pageNumber:payload?.pageNumber || 1,
      pageCount:payload?.pageCount || 10,
      searchString:payload.searchString || ""
    }
    const response = yield call(getAllOrganizations,body);
    yield put(getOrganizationsSuccess({
      data:response.responseObject?.lstOrganizations || [],
      pageNumber:body.pageNumber,
      searchString:body.searchString,
      totalRecordCount:response.responseObject?.totalRecordCount
    }));
  } catch (error) {
    if (error.response) {
      yield put(
        getOrganizationsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getOrganizationsFail(error.message));
    }
  }
}

function* fetchOrganizationDetails({ payload }) {
  try {
    let body = {
      id: payload,
    };
    const response = yield call(getOrganizationDetails, body);
    yield put(getDetailsSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getDetailsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getDetailsFail(error.message));
    }
  }
}

function* upsertOrganizationSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertOrganization, body);
    yield put(upsertOrganizationSuccess(response));
    callback && callback(response?.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertOrganizationFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertOrganizationFail(error.message));
    }
  }
}

function* fetchOrganizationFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(
      getOrganizationsFilesSuccess(response.responseObject.lstDocuments)
    );
    callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getOrganizationsFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getOrganizationsFilesFail(error.message));
    }
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(GET_ORGANIZATIONS, fetchOrganizations);
  yield takeEvery(GET_ORGANIZATION_DETAILS, fetchOrganizationDetails);
  yield takeEvery(UPSERT_ORGANIZATION, upsertOrganizationSaga);
  yield takeEvery(GET_ORGANIZATION_FILES, fetchOrganizationFiles);
  yield takeEvery(GET_INDIVIDUAL_LEVEL_DASHBOARDS, fetchIndividualLevelDetails);
  yield takeEvery(GET_EXECUTIVE_DASHBOARDS, fetchExecutiveDashboards);
}

export default contactsSaga;
