import classnames from "classnames";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getDocumentTasks,
  getDocumentProjects,
  getDocumentRequests,
  getOrganizations
} from "store/actions";
import { NavItem } from "reactstrap";
import { CURRENT_LANG } from "common";
import PropTypes from "prop-types";
import Loading from "pages/Loading";
const ScrollbalList = props => {
  const { t, innerTabId, handleInnerTabClick, listKey, oldData } = props;
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isServerData, setIsServerData] = useState(false); // to be deleted after integrating with all 6 APIs

  useEffect(() => {
    // call api to return thr sublist ok the tab , subList
    const type = listKey;
    setLoading(true);
    if (type == "tasks") {
      setIsServerData(true);
      props.onGetDocumentTasks({
        pageNumber: 1,
        pageCount: 10,
      });
    } else if (type == "projects") {
      setIsServerData(true);
      props.onGetDocumentProjects({
        pageNumber: 1,
        pageCount: 10,
      });
    } else if (type == "requests") {
      setIsServerData(true);
      props.onGetDocumentRequests({
        pageNumber: 1,
        pageCount: 10,
      });
    } else if (type == "organizations") {
        setIsServerData(true);
        props.onGetOrganizations({
          pageNumber: 1,
          pageCount: 10,
        });
      } 
      else {
      setIsServerData(false);
      if ( oldData && oldData[type]) {
        setData(oldData[type].data);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 900);

    return () => {};
  }, [listKey]);

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
    loadDataByType(10, pageNumber + 1);
  };

  const loadDataByType = (pageCount, pageNumber) => {
    const type = listKey;
    const payload = {
      pageNumber: pageNumber,
      pageCount: pageCount,
    };
    if (type == "tasks") {
      props.onGetDocumentTasks(payload);
    } else if (type == "projects") {
      props.onGetDocumentProjects(payload);
    } else if (type == "requests") {
      props.onGetDocumentRequests(payload);
    } else if (type == "organizations") {
        props.onGetOrganizations(payload);
      }
  };

  return (
    <>
      {loading ? (
        <Loading height={"70px"} noPadding />
      ) : (
        <>
          <div
            className="scrollbar"
            style={{
              height: "auto",
              maxHeight:"400px",
              overflow: "scroll",
              overflowX: "hidden",
              paddingInlineStart: 30,
              paddingInlineEnd: 10,
            }}
          >
            {props.loadingList && !props.subList ? (
              <Loading height={"70px"} noPadding />
            ) : isServerData && props.subList?.length > 0 ? (
              <>
                {props.subList.map((item, index) => (
                  <NavItem
                    key={item.id}
                    style={{
                      width: "100%",
                    }}
                    className={classnames({
                      "mb-4": true,
                    })}
                  >
                    <a
                      onClick={() => {
                        handleInnerTabClick(index + 1, listKey, item);
                      }}
                    >
                      <h6
                        className={classnames({
                          "mb-0": true,
                          "text-muted": innerTabId !== index + 1,
                          "text-primary": innerTabId === index + 1,
                        })}
                      >
                        {CURRENT_LANG === "ar"
                          ? item.titleAr || item.title
                          : item.title}
                      </h6>
                    </a>
                  </NavItem>
                ))}
                {props.loadingList && <Loading height={"70px"} noPadding />}
                {props.subList.length != props.totalRecordCount && (
                  <button
                    className="btn btn-primary btn-load-more"
                    onClick={loadMore}
                  >
                    {t("Load more")}
                  </button>
                )}
              </>
            ) : // to be deleted after integrating with all 6 APIs:
            data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <NavItem
                    key={item.id}
                    style={{
                      width: "100%",
                    }}
                    className={classnames({
                      "mb-4": true,
                    })}
                  >
                    <a
                      onClick={() => {
                        handleInnerTabClick(index + 1, listKey, item);
                      }}
                    >
                      <h6
                        className={classnames({
                          "mb-0": true,
                          "text-muted": innerTabId !== index + 1,
                          "text-primary": innerTabId === index + 1,
                        })}
                      >
                        {CURRENT_LANG === "ar"
                          ? item.titleAr || item.title
                          : item.title}
                      </h6>
                    </a>
                  </NavItem>
                ))}
              </>
            ) : (
              <div>
                <p className="mb-0 text-muted">{t("No Items Found")}</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ files }) => ({
  loadingList: files.loadingList,
  subList: files.subList,
  totalRecordCount: files.totalRecordCount,
});

const mapDispatchToProps = dispatch => ({
  onGetDocumentTasks: payload => dispatch(getDocumentTasks(payload)),
  onGetDocumentProjects: payload => dispatch(getDocumentProjects(payload)),
  onGetDocumentRequests: payload => dispatch(getDocumentRequests(payload)),
  onGetOrganizations: payload => dispatch(getOrganizations(payload)),
   
});

ScrollbalList.propTypes = {
  subList: PropTypes.array,
  t: PropTypes.any,
  innerTabId: PropTypes.any,
  handleInnerTabClick: PropTypes.func,
  listKey: PropTypes.any,
  loadingList: PropTypes.bool,
  oldData: PropTypes.any,
  totalRecordCount: PropTypes.any,
  onGetDocumentTasks: PropTypes.func,
  onGetDocumentProjects: PropTypes.func,
  onGetDocumentRequests: PropTypes.func,
  onGetOrganizations: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrollbalList);
