import {
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAIL,
  GET_REQUESTS,
  GET_REQUESTS_DASHBOARD,
  GET_REQUESTS_DASHBOARD_FAIL,
  GET_REQUESTS_DASHBOARD_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAIL,
  GET_COMMENTS_IN_REQUEST_SUCCESS,
  GET_COMMENTS_IN_REQUEST_FAIL,
  GET_COMMENTS_IN_REQUEST,
  UPSERT_REQUEST_SUCCESS,
  UPSERT_REQUEST_FAIL,
  UPSERT_REQUEST,
  GET_REQUEST_FILES_SUCCESS,
  GET_REQUEST_FILES_FAIL,
  GET_REQUEST_FILES,
  DELETE_REQUEST,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_FAIL,
  UPDATE_REQUEST_STATUS_CODE
} from "./actionTypes";

const INIT_STATE = {
  requestStatusCode: null,
  requests: [],
  error: "",
  loading: false,
  request: null,
  searchString:"",
  dashboardData:[]
};

const requests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_REQUEST_STATUS_CODE:
      return {
        ...state,
        requestStatusCode: action.payload,
      };
    case GET_REQUESTS_SUCCESS:
      let _requests = action.payload?.requests?.map(_request => { 
        return {
          ..._request,
          id: _request.idRequest,
        };
      });
      return {
        ...state,
        requests: _requests,
        loading: false,
        error: "",
        searchString:action.payload.searchString,
        totalRecordCount: action.payload.totalRecordCount,
        pageNumber:action.payload.pageNumber,
        requestStatusCode: action.payload.statusCode,
      };
    case GET_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_REQUESTS:
      return {
        ...state,
        loading: true,
      };
  
      case GET_REQUESTS_DASHBOARD_SUCCESS:
        return {
          ...state,
          dashboardData: action.payload.dashboardData,
          loading: false,
          error: "",
       
        };
      case GET_REQUESTS_DASHBOARD_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case GET_REQUESTS_DASHBOARD:
        return {
          ...state,
         // loading: true,
        };
    
      case GET_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case GET_REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          ...action.payload,
          request: {
            ...action.payload.request,
            id: action.payload.request.idRequest,
          },
        },
        loading: false,
        error: "",
      };
    case GET_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        request:{}
      };

    case GET_COMMENTS_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMENTS_IN_REQUEST_SUCCESS:
      let _comments = action.payload.map(_comment => {
        return {
          idComments: _comment.idComments,
          description: _comment.description,
          commentedBy: _comment.commentedBy,
          commentedOn: _comment.commentedOn,
        };
      });
      return {
        ...state,
        comments: _comments,
        loading: false,
        error: "",
      };

    case GET_COMMENTS_IN_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPSERT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPSERT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPSERT_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_REQUEST_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        loading: false,
        error: "",
      };

    case GET_REQUEST_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_REQUEST_FILES:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default requests;
