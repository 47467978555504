import React, { Component } from "react";
import PropTypes from "prop-types";
import { ENTITIES, CURRENT_LANG } from "common";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  Progress,
  CardTitle,
} from "reactstrap";
import { connect } from "react-redux";
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
//import action
import { getHome } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";
import Calendar from "pages/Calendar";
import "./dashboard.scss";
import StatusText from "components/Common/StatusText";
import Loading from "pages/Loading";
import { filterPermittedEntities, getPlannedProgress } from "common/utils";

import tasksIcon from "../../assets/icons/tasks-home.svg";
import correspondence from "../../assets/icons/correspondence-home.svg";
import approvals from "../../assets/icons/approvals-home.svg";
import meetings from "../../assets/icons/calendar-home.svg";
import HomeDashboard from "./HomeDashboard";
import Seo from "common/Seo";

class Dashboard extends Component {
  constructor(props) {
    let readEntities = filterPermittedEntities(props.permissions, "read");
    super(props);
    this.state = {
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly",
      isCalendarRead: readEntities.includes(ENTITIES.APPOINTMENT),
      isTaskRead: readEntities.includes(ENTITIES.TASK),
      isApprovalRead: readEntities.includes(ENTITIES.APPROVAL),
      isCorresRead: readEntities.includes(ENTITIES.CORRESPONDENCE),
      isRequestsRead: readEntities.includes(ENTITIES.REQUEST),
      readEntities,
    };

    this.togglemodal = this.togglemodal.bind(this);
    this.togglesubscribemodal = this.togglesubscribemodal.bind(this);
    this.getProgressColor = this.getProgressColor.bind(this);
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }));
  };

  componentDidMount() {
    const { onGetHome, organization } = this.props;
    const organizationId = !!organization
      ? organization?.value
      : "00000000-0000-0000-0000-000000000000";
    onGetHome(organizationId);
  }

  getProgressColor(actual, planned) {
    if (actual > planned) {
      return "actualprogress1";
    } else if (actual < planned) {
      return "actualprogress2";
    } else {
      return "actualprogress3";
    }
  }
  render() {
    const { loading, home, loadingNotifications, loadingLayout, lookups } =
      this.props;
    if (loading || loadingNotifications || !home || loadingLayout || !lookups) {
      return <Loading />;
    }
    const {
      isCalendarRead,
      readEntities,
      isApprovalRead,
      isTaskRead,
      isCorresRead,
      isRequestsRead,
    } = this.state;

    const widgetSize =
      12 /
      [isCorresRead, isApprovalRead, isRequestsRead, isTaskRead].filter(
        item => item
      ).length;

      const dashboardData= {
        tasks: readEntities.includes(ENTITIES.TASK) ? home.tasks.taskCount:[],
        requests:readEntities.includes(ENTITIES.REQUEST)?  home.requests.requestCount:[],
        approvals: readEntities.includes(ENTITIES.APPROVAL)? home.approvals.approvalsCount:[],
        correspondences: home.correspondences?.correspondenceCount
      }

    const projects = home.projects.lstProjects
      .sort((a, b) => b.progress - a.progress)
    let FontStyle =
      CURRENT_LANG === "ar"
        ? { fontFamily: "Bukra" }
        : { fontFamily: "DM Sans" };

    return (
      <React.Fragment>
        <div className="page-content">
          <Seo title={this.props.t("Dashboard Title")} />
          <Container fluid>
            <Row className="g-1 row-margin-top-nav">
             
             <HomeDashboard
             widgetSize={widgetSize}
             isTaskRead={isTaskRead}
             isRequestsRead={isRequestsRead}
             isApprovalRead={isApprovalRead}
             isCorresRead={isCorresRead}
             t={this.props.t}
             history={this.props.history}
             data={dashboardData}
             />
        
              <Row className="g-1" style={{ marginTop: -4 }}>
                {readEntities.includes(ENTITIES.PROJECT) && (
                  <Col xl={isCalendarRead ? "6" : "12"}>
                    {projects.length === 0 && (
                      <Card>
                        <CardBody>
                          <h5 className="day-dark-text-bold font-size-16 px-4 pt-2">
                            {this.props.t("Projects")}
                          </h5>
                        </CardBody>
                      </Card>
                    )}
                    {projects.map((item, index) => (
                      <Row
                        key={"_protfolio_" + index}
                        className="g-0 projects-card"
                        style={{
                          marginBottom: -20,
                        }}
                      >
                        <Card
                          onClick={() => {
                            this.props.history.push(
                              `/project-details?id=${item.idProject}`
                            );
                          }}
                          className="card-hover pt-4"
                        >
                          {index === 0 && (
                            <h5 className="day-dark-text-bold font-size-16 px-4 pt-2">
                              {this.props.t("Projects")}
                            </h5>
                          )}
                          <CardBody>
                            <Row>
                              <div className="card-titleContainer">
                                <CardTitle
                                  className="mainTitle2"
                                  style={{ marginTop: -10, marginBottom: 19 }}
                                >
                                  {CURRENT_LANG === "ar"
                                    ? item?.titleAr || item.title
                                    : item.title}
                                </CardTitle>
                                <StatusText text={item.status?.label} />
                              </div>
                            </Row>
                            <Row>
                              <Col sm="12" className="my-2">
                                <div className="progress-label">
                                  {this.props.t("Planned")}
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                  <Progress
                                    value={parseInt(
                                      getPlannedProgress(
                                        item.actualStartDate,
                                        item.estimatedEndDate
                                      )
                                    )}
                                    color={"plannedprogress"}
                                    className="progress-md"
                                    style={{
                                      height: 6,
                                      borderRadius: "100px",
                                      marginInlineEnd: 16,
                                    }}
                                  />
                                  <p
                                    // style={{ marginTop: 1 }}
                                    className="text-muted progress-text"
                                  >
                                    {`${parseInt(
                                      getPlannedProgress(
                                        item.actualStartDate,
                                        item.estimatedEndDate
                                      )
                                    )}%`}
                                  </p>
                                </div>
                                <div className="progress-label">
                                  {this.props.t("Actual")}
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                  <Progress
                                    value={item.progress}
                                    color={this.getProgressColor(
                                      item.progress,
                                      getPlannedProgress(
                                        item.actualStartDate,
                                        item.estimatedEndDate
                                      )
                                    )}
                                    className="progress-md"
                                    style={{
                                      height: 6,
                                      borderRadius: "100px",
                                      marginInlineEnd: 16,
                                    }}
                                  />
                                  <p
                                    style={{ marginInlineStart: 16 }}
                                    className="text-muted progress-text"
                                  >
                                    {`${parseInt(item.progress)}%`}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <div className="span-icon d-flex flex-row justify-content-between flex-wrap">
                              <div className="text-muted d-flex flex-row align-items-center">
                                <img src={tasksIcon} />
                                <span
                                  style={{
                                    paddingInlineStart: 8,
                                    fontStyle: "normal",
                                    letterSpacing: "0.4px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    ...FontStyle,
                                  }}
                                >
                                  {`${item.countOfTasks} ${this.props.t(
                                    "tasks"
                                  )}`}
                                </span>
                              </div>
                              <div className="text-muted d-flex flex-row align-items-center">
                                <img src={correspondence}></img>
                                <span
                                  style={{
                                    paddingInlineStart: 8,
                                    ...FontStyle,
                                    fontStyle: "normal",
                                    letterSpacing: "0.4px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                  }}
                                >
                                  {`${
                                    item.countOfCorrespondences
                                  } ${this.props.t("correspondence")}`}
                                </span>
                              </div>

                              <div className="text-muted d-flex flex-row align-items-center">
                                <img src={approvals}></img>
                                <span
                                  style={{
                                    paddingInlineStart: 8,
                                    ...FontStyle,
                                    fontStyle: "normal",
                                    letterSpacing: "0.4px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                  }}
                                >
                                  {`${item.countOfApprovals} ${this.props.t(
                                    "approvals"
                                  )}`}
                                </span>
                              </div>

                              <div className="text-muted d-flex flex-row align-items-center">
                                <img src={meetings}></img>
                                <span
                                  style={{
                                    paddingInlineStart: 8,
                                    ...FontStyle,
                                    fontStyle: "normal",
                                    letterSpacing: "0.4px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                  }}
                                >
                                  {`${item.countOfDueMeetings} ${this.props.t(
                                    "meetings today"
                                  )}`}
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Row>
                    ))}
                  </Col>
                )}
                {isCalendarRead && (
                  <Col xl="6">
                    <Calendar isFromHomePage={true} />
                  </Col>
                )}
              </Row>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={this.state.subscribemodal}
          role="dialog"
          autoFocus={true}
          data-toggle="modal"
          centered
          toggle={this.togglesubscribemodal}
        >
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                onClick={() => this.setState({ subscribemodal: false })}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-4">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light  rounded-circle text-primary h1">
                    <i className="mdi mdi-email-open"></i>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">Subscribe !</h4>
                    <p className="text-muted font-size-14 mb-4">
                      Subscribe our newletter and get notification to stay
                      update.
                    </p>

                    <div className="input-group  rounded bg-light">
                      <Input
                        type="email"
                        className="form-control bg-transparent border-0"
                        placeholder="Enter Email address"
                      />
                      <Button color="primary" type="button" id="button-addon2">
                        <i className="bx bxs-paper-plane"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabindex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>Order Details</ModalHeader>
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#SK2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p>

              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage1} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Solid Color T-Shirt
                          </h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage2} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Hoodie (Blue)
                          </h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  onGetHome: PropTypes.func,
  history: PropTypes.any,
  home: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  loadingNotifications: PropTypes.bool,
  organization: PropTypes.any,
  loadingLayout: PropTypes.bool,
  lookups: PropTypes.any,
  history: PropTypes.any,
  permissions: PropTypes.any,
  loadingLookups: PropTypes.bool,
  appointments: PropTypes.any,
};

const mapStateToProps = ({ Dashboard, Layout, calendar }) => ({
  home: Dashboard.home,
  loading: Dashboard.loading,
  loadingNotifications: Dashboard.loadingNotifications,
  error: Dashboard.error,
  organization: Layout.organization,
  loadingLayout: Layout.loading,
  lookups: Layout.lookups,
  permissions: Layout.permissions,
});

const mapDispatchToProps = dispatch => ({
  onGetHome: payload => dispatch(getHome(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard));
