import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { withTranslation } from "react-i18next";
import CustomTable from "components/Common/CustomTable";
import { getGlobalHeaderStyle } from "common";
import EditIcon from "components/Common/EditIcon";

class ViewTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      menu: false,
    };

    this.toggle.bind(this);
  }

  toggle() {
    this.setState({ menu: !this.state.menu });
  }

  render() {
    const { team } = this.props;
    const teamMembers = team?.length;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "member.label",
        text: "Full Name",
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
      },
      {
        dataField: "emailaddress",
        text: "Email",
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
      },
      {
        dataField: "role.label",
        text: "Role",
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
      },
      {
        dataField: "idTeamMember",
        text: "Action",
        align: "start",
        headerStyle: getGlobalHeaderStyle("10%"),
        formatter: (cellContent, member) => (
          <Row>
            <EditIcon onClick={() => this.props.onEditMember(member)} />
          </Row>
        ),
      },
    ];

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
        scrollable={true}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {`${this.props.t("Team")} (${teamMembers} ${this.props.t(
            "Members"
          )})`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomTable
                    data={team}
                    columns={columns}
                    keyField="idTeamMember"
                    defaultSortedField="member.label"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

ViewTeamModal.propTypes = {
  team: PropTypes.array,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  onEditMember: PropTypes.func,
  onDeleteMember: PropTypes.func,
};

export default withTranslation()(ViewTeamModal);
