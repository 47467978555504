import React from "react";
import PropTypes from "prop-types";
import { getFileIcon } from "common/utils";
import { connect } from "react-redux";
import { downloadFile } from "store/actions";

const FileItem = ({ file, download, downloadLoading, downloadingFileId }) => {
  let icon = getFileIcon(file.fileName);
  return (
    <div
      className={`day-row-nowrap ${
        file.isDownloadAllowed && "day-pointer"
      } mr-10`}
      onClick={() => {
        if (file.isDownloadAllowed) {
          download({
            id: file.idDocument,
            fileName: file.fileName,
          });
        }
      }}
    >
      <i className={icon}></i>
      <p
        className={`mb-0 text-${
          file.isDownloadAllowed ? "primary" : "muted"
        } font-size-12`}
      >
        {file.fileName}
      </p>

      {downloadLoading && downloadingFileId == file.idDocument && (
        <i className="bx bx-loader bx-spin font-size-16 align-middle mx-2"></i>
      )}
    </div>
  );
};

FileItem.propTypes = {
  file: PropTypes.any,
  download: PropTypes.func,
  downloadLoading: PropTypes.bool,
  downloadingFileId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  download: payload => dispatch(downloadFile(payload)),
});

const mapStateToProps = ({ files }) => ({
  downloadLoading: files.downloadLoading,
  downloadingFileId: files.downloadingFileId,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileItem);
