import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

class Apaexlinecolumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.series,
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "45%",
            endingShape: "rounded",
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          squareSymbol: true,
          markers: {
            radius: 12,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetX: 25,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        colors: ["#657DFF", "#07DF9A"],
        xaxis: {
          categories: props.categories,
        },
        yaxis: {
          max: 100,
          labels: {
            align: 'center',
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
          className="apex-charts"
        />
      </React.Fragment>
    );
  }
}
Apaexlinecolumn.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
  t: PropTypes.any,
};

export default Apaexlinecolumn;
