import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import CustomTable from "components/Common/CustomTable";
import { getGlobalHeaderStyle } from "common";

class ViewTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      menu: false,
    };

    this.toggle.bind(this);
  }

  toggle() {
    this.setState({ menu: !this.state.menu });
  }

  render() {
    const { team } = this.props;
    const teamMembers = team?.length;
    const columns = [
      {
        dataField: "name",
        text: "Full Name",
        headerStyle: getGlobalHeaderStyle("50%"),
        align: "start",
      },
      {
        dataField: "email",
        text: "Email",
        headerStyle: getGlobalHeaderStyle("50%"),
        align: "start",
      },
    ];

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
        scrollable={true}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {`${this.props.t("Team")} (${teamMembers} ${this.props.t(
            "Members"
          )})`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomTable
                    data={team}
                    columns={columns}
                    defaultSortedField="name"
                    placeholderText={this.props.t("Search Team")}
                    showSearchAndAdd
                    addBtnText={this.props.t("Add Member")}
                    handleAddClick={this.props.handleAddClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

ViewTeamModal.propTypes = {
  team: PropTypes.array,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleAddClick: PropTypes.func,
};

export default withTranslation()(ViewTeamModal);
