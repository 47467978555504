import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { Button, Col, Row } from "reactstrap";
import {
  Colors,
  CURRENT_LANG,
  ENTITIES,
  isInternal,
  paths,
  TOAST_OPTIONS,
} from "common";
import "./sidebar-style.scss";
import { connect } from "react-redux";
import {
  filterPermittedEntities,
  handleLogout,
  isRTL,
  shortenString,
} from "common/utils";

import home from "../../assets/icons/home.svg";
import dashboardsIcon from "../../assets/icons/dashboardsIcon.svg";
import projects from "../../assets/icons/projects.svg";
import tasks from "../../assets/icons/tasks.svg";
import requests from "../../assets/icons/requests.svg";
import approvals from "../../assets/icons/approvals.svg";
import calendar from "../../assets/icons/calendar.svg";
import correspondence from "../../assets/icons/correspondence.svg";
import documentIcon from "../../assets/icons/document.svg";
import people from "../../assets/icons/people.svg";
import Tippy from "@tippy.js/react";
import { toast } from "react-toastify";
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readEntities: filterPermittedEntities(props.permissions, "read"),
      icons: {
        home: home,
        projects: projects,
        tasks: tasks,
        requests: requests,
        approvals: approvals,
        calendar: calendar,
        correspondence: correspondence,
        document: documentIcon,
        people: people,
      },
    };
    this.refDiv = React.createRef();
    this.toggleHoverIcon = this.toggleHoverIcon.bind(this);
    this.renderSideBarItem = this.renderSideBarItem.bind(this);
    this.handleErrorShow = this.handleErrorShow.bind(this);
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");

    let prevLocation = localStorage.getItem("prevLocation");

    for (let i = 0; i < items.length; ++i) {
      if (
        this.props.location.pathname === items[i].pathname ||
        (!paths.includes(this.props.location.pathname) &&
          items[i].pathname === prevLocation)
      ) {
        localStorage.setItem("prevLocation", this.props.location.pathname);

        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  toggleHoverIcon(name, icon) {
    this.setState({
      icons: {
        ...this.state.icons,
        [name]: icon,
      },
    });
  }

  renderSideBarItem(icon, title, to) {
    return (
      <li className="side-bar-btn mb-3">
        <Link to={to} className="day-row">
          <img src={icon} className="me-2" />
          <span className="mb-0 h4 font-size-12 text-white">{title}</span>
        </Link>
      </li>
    );
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  getChevronDirectionClass = () => {
    const isPageRTL = isRTL();

    const closeState = isPageRTL ? "left" : "right";
    const openState = isPageRTL ? "right" : "left";

    return `bx-chevron-${this.props.toggleMenuIcon ? openState : closeState}`;
  };

  handleErrorShow() {
    if (
      !(
        !!this.props.entityLookups &&
        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS] &&
        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects
          ?.length > 0
      )
    ) {
      toast.warn(this.props.t("Empty Projects"), TOAST_OPTIONS);
    }
  }
  render() {
    const name = localStorage.getItem("name");
    const image = localStorage.getItem("image");
    const { readEntities } = this.state;
    let FontStyle =
      CURRENT_LANG === "ar"
        ? { fontFamily: "Bukra" }
        : { fontFamily: "DM Sans" };

    return (
      <React.Fragment>
        <SimpleBar ref={this.refDiv}>
          <div
            id="sidebar-menu"
            className="scrollbar"
            style={{
              overflowX: "hidden",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                // height: "55vh",
              }}
              className="metismenu list-unstyled"
              id="side-menu"
            >
              <div
                onClick={this.props.toggleMenu}
                style={{
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: -55,
                  marginBottom: 20,
                  zIndex: 20,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1e5223",
                    borderRadius: "50%",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className={`bx ${this.getChevronDirectionClass()} bx-md`}
                    style={{
                      color: Colors.white,
                    }}
                  ></i>
                </div>
              </div>
              {this.renderSideBarItem(home, this.props.t("Home"), "/home")}

              {readEntities.includes(ENTITIES.PROJECT) &&
                this.renderSideBarItem(
                  projects,
                  this.props.t("Projects"),
                  "/projects-overview"
                )}

              {readEntities.includes(ENTITIES.TASK) &&
                this.renderSideBarItem(tasks, this.props.t("Tasks"), "/tasks")}

              {readEntities.includes(ENTITIES.REQUEST) &&
                this.renderSideBarItem(
                  requests,
                  this.props.t("Requests"),
                  "/requests"
                )}

              {readEntities.includes(ENTITIES.APPROVAL) &&
                this.renderSideBarItem(
                  approvals,
                  this.props.t("Approvals"),
                  "/approvals"
                )}
              {readEntities.includes(ENTITIES.APPOINTMENT) &&
                this.renderSideBarItem(
                  calendar,
                  this.props.t("Calendar"),
                  "/calendar"
                )}

              {readEntities.includes(ENTITIES.CORRESPONDENCE) &&
                this.renderSideBarItem(
                  correspondence,
                  this.props.t("Correspondence"),
                  "/correspondence"
                )}

              {readEntities.includes(ENTITIES.DOCUMENT) &&
                this.renderSideBarItem(
                  documentIcon,
                  this.props.t("Document Management"),
                  "/document-management"
                )}

              {(readEntities.includes(ENTITIES.INDIVIDUALS) ||
                readEntities.includes(ENTITIES.ORGANIZATION)) &&
                this.renderSideBarItem(
                  people,
                  this.props.t("People Organisation"),
                  "/people"
                )}
              {isInternal && (
                <li className="side-bar-btn">
                  <Link
                    to="/#"
                    onClick={this.handleErrorShow}
                    className="has-arrow d-flex"
                  >
                    <img src={dashboardsIcon} className="me-2" />
                    <span className="mb-0 h4 font-size-12 text-white me-3">
                      {this.props.t("Dashboards")}
                    </span>
                  </Link>

                  <ul
                    className="sub-menu"
                    aria-expanded="false"
                    style={FontStyle}
                    hidden={
                      !(
                        !!this.props.entityLookups &&
                        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS] &&
                        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS]
                          .listRequestProjects?.length > 0
                      )
                    }
                  >
                    <li>
                      <Link to="/project-level">
                        {this.props.t("Project Level")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/individual-level">
                        {this.props.t("Individual Level")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/executive-level">
                        {this.props.t("Executive Level")}
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
          {!this.props.toggleMenuIcon ? (
            <div
              className="sidebar-footer day-column-reverse day-align-items-center day-justify-between"
              // style={{
              //   height: "30vh",
              // }}
            >
              <div className="day-column day-align-items-center">
                <Link to="/profile">
                  {image !== "null" ? (
                    <img
                      src={`data:image/png;base64,${image}`}
                      height={40}
                      width={40}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle">
                        {name ? name.charAt(0) : ""}
                      </span>
                    </div>
                  )}
                </Link>

                <Link to="/" onClick={handleLogout} className="logout-btn">
                  <i
                    className={
                      "mdi mdi-logout fa-lg" +
                      (CURRENT_LANG === "ar" ? " mdi-rotate-180" : "")
                    }
                  />
                </Link>
              </div>
            </div>
          ) : (
            <div className="sidebar-footer">
              <Row className="day-row">
                <Col lg={8}>
                  <Link to="/profile" className="day-row">
                    <Col lg={4} className="day-row day-align-items-end">
                      {!!image && image !== "null" ? (
                        <img
                          src={`data:image/png;base64,${image}`}
                          height={40}
                          width={40}
                          style={{ borderRadius: "50%" }}
                        />
                      ) : (
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle avatar-title-footer">
                            {name ? name.charAt(0) : ""}
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col lg={8} className="mx-2">
                      <Tippy
                        content={name || ""}
                        placement="top"
                        animation="scale-subtle"
                        theme="light"
                        arrow={false}
                        duration={200}
                        delay={[75, 0]}
                        distance={5}
                        enabled={name?.length > 12}
                      >
                        <span
                          className="font-size-14 text-white"
                          style={{
                            marginInlineStart: 10,
                          }}
                        >
                          {shortenString(name, 12)}
                        </span>
                      </Tippy>
                    </Col>
                  </Link>
                </Col>
                <Col lg={3} className="mx-2">
                  <Button
                    className="btn-logout day-row day-justify-center"
                    outline
                    style={{
                      height: 35,
                      width: 30,
                    }}
                    onClick={() => {
                      // this.props.history.replace("/logout");
                      handleLogout();
                    }}
                  >
                    <i
                      className={
                        "mdi mdi-logout font-size-20" +
                        (CURRENT_LANG === "ar" ? " mdi-rotate-180" : "")
                      }
                    />
                  </Button>
                </Col>
              </Row>
              <Col
                xs={12}
                className="mt-4 text-white font-size-11 h6 mb-0"
                style={{ fontWeight: 500 }}
              >
                {`© ${new Date().getFullYear()} ${this.props.t("Rights")}`}
              </Col>
            </div>
          )}
        </SimpleBar>
      </React.Fragment>
    );
  }
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  toggleMenu: PropTypes.func,
  toggleMenuIcon: PropTypes.bool,
  history: PropTypes.any,
  user: PropTypes.any,
  permissions: PropTypes.any,
  entityLookups: PropTypes.any,
};

const mapStateToProps = ({ Profile, Layout }) => ({
  user: Profile.success,
  permissions: Layout.permissions,
  entityLookups: Layout.entityLookups,
});

export default connect(mapStateToProps)(
  withTranslation()(withRouter(SidebarContent))
);
