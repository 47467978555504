import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Colors,
  ENTITIES,
  getGlobalHeaderStyle,
  isInternal,
  MAX_COMMENT_LENGTH,
  TOAST_OPTIONS,
} from "../../common";
import {
  formatBytes,
  formatLineBreaksInMarkdownFriendlyFormat,
  getCurrentFileTree,
  getFileIcon,
  sortCaretIcons,
} from "common/utils";
import moment from "moment";
import Comments from "./Comments";
import Loading from "pages/Loading";
import CustomTable from "./CustomTable";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { addFileComment, downloadFile, getFileDetails } from "store/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DeleteIcon from "./DeleteIcon";

class DocumentsTable extends Component {
  constructor(props) {
    super(props);
    let nonExpandable = props.data
      .filter(
        item =>
          item &&
          (item.isFolder || !item.isCommentsAllowed || props.showUploadType)
      )
      .map(_item => _item.idDocument);
    let filteredFiles = getCurrentFileTree(props.path, props.data);
    this.state = {
      nonExpandable: nonExpandable || [],
      comment: "",
      isFetchingComments: false,
      filteredFiles,
      fileColumns: [
        {
          text: "id",
          dataField: "idDocument",
          sort: true,
          sortCaret: sortCaretIcons,
          hidden: true,
        },
        {
          headerStyle: getGlobalHeaderStyle("5%"),
          align: "start",
          text: "",
          dataField: "isFolder",
          sort: true,
          sortCaret: sortCaretIcons,
          headerFormatter: (cellContent, file) => (
            <i className={getFileIcon("")}></i>
          ),
          formatter: (cellContent, file) =>
            file.isFolder ? (
              <i className="bx bxs-folder text-warning font-size-20"></i>
            ) : (
              <i className={getFileIcon(file.fileName)}></i>
            ),
        },
        {
          headerStyle: getGlobalHeaderStyle("25%"),
          align: "start",
          text: props.t("Name"),
          dataField: "fileName",
          sort: true,
          sortCaret: sortCaretIcons,
        },
        {
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          text: props.t("Last Modified"),
          dataField: "modifiedDate",
          sort: true,
          sortCaret: sortCaretIcons,
          formatter: (cell, file) => (
            <>{moment(file.modifiedDate).format("DD/MM/YYYY")}</>
          ),
        },
        {
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          text: props.t("Uploaded By"),
          dataField: "modifiedBy",
          sort: true,
          sortCaret: sortCaretIcons,
        },
        {
          headerStyle: getGlobalHeaderStyle("20%"),
          align: "start",
          text: props.t("Size"),
          dataField: "fileSize",
          sort: true,
          sortCaret: sortCaretIcons,
          formatter: (cell, file) =>
            !file.isFolder ? <>{formatBytes(file.fileSize)}</> : <></>,
        },
        {
          headerStyle: getGlobalHeaderStyle("5%"),
          align: "start",
          text: "",
          dataField: "action",
          sort: false,
          headerFormatter: (cellContent, file) => (
            <i className="bx bx-dots-vertical-rounded font-size-20"></i>
          ),
          formatter: (cellContent, file) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {file.isDownloadAllowed && !file.isFolder ? (
                isInternal ? (
                  <a href={file.sharePointURL} target="_blank" rel="noreferrer">
                    <i
                      className={`mdi mdi-link-variant text-muted font-size-20`}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </a>
                ) : (
                  <i
                    onClick={() => {
                      if (file.isDownloadAllowed) {
                        this.props.download({
                          id: file.idDocument,
                          fileName: file.fileName,
                        });
                      }
                    }}
                    className={`bx bx-download text-muted font-size-20`}
                    style={{ cursor: "pointer" }}
                  ></i>
                )
              ) : (
                <></>
              )}
              {file.canDelete && (
                <DeleteIcon
                  onClick={() =>
                    this.props.onDelete && this.props.onDelete(file)
                  }
                  style={{
                    backgroundColor: Colors.searchBG,
                    borderRadius: 10,
                    margin: 5,
                  }}
                />
              )}
            </div>
          ),
        },
      ],
    };
    this.CommentRenderer = this.CommentRenderer.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  CommentRenderer = props => (
    <div
      style={{
        backgroundColor: Colors.searchBG,
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        padding: 25,
      }}
    >
      <div style={{ flex: 1 }} />

      <div
        style={{
          flex: 3,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          textAlign: "start",
        }}
      >
        <p style={{ color: Colors.grey }}>{this.props.t("Comments")}</p>
        <Comments comments={this.props?.comments} />
        <textarea
          className="form-control mt-4"
          placeholder={this.props.t("Start typing here")}
          maxLength={MAX_COMMENT_LENGTH}
          value={this.state.comment}
          onChange={e => {
            this.setState({ comment: e.target.value });
          }}
        />

        <div style={{ marginTop: 20 }} className="day-text-end">
          <Button
            color="primary px-4 py-1"
            disabled={!!!this.state.comment}
            onClick={() => this.handleAddComment(props.data)}
          >
            {this.props.t("Add")}
          </Button>
        </div>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );

  handleAddComment(document) {
    this.setState({ isFetchingComments: true }, () => {
      const payload = {
        commentedBy: null,
        commentedOn: "",
        description: formatLineBreaksInMarkdownFriendlyFormat(
          this.state.comment
        ),
        idComments: "00000000-0000-0000-0000-000000000000",
        regarding: {
          entityName: ENTITIES.DOCUMENT,
          id: document.idDocument,
          label: "",
          labelAr: "",
        },
      };
      this.props.onUpsertComment(payload, () => {
        this.props.onGetFileDetails(document.idDocument);
        this.setState({ isFetchingComments: false, comment: "" });
      });
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Comment")),
        TOAST_OPTIONS
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.data && this.props.data.length > 0) {
        let nonExpandable = this.props.data
          .filter(
            item =>
              item &&
              (item.isFolder ||
                !item.isCommentsAllowed ||
                !!this.props.showUploadType)
          )
          .map(_item => _item.idDocument);
        let filteredFiles = getCurrentFileTree(
          this.props.path,
          this.props.data
        );
        this.setState({ nonExpandable, filteredFiles });
      }
    }
  }

  handleRowClick(e, row, rowIndex) {
    if (row.isFolder) {
      this.props.handleFolderClick(
        `${this.props.path.join("/")}/${row.fileName}`
      );
    } else {
      if (
        this.props.onRowClick &&
        typeof this.props.onRowClick === "function"
      ) {
        this.props.onRowClick(row);
      }
    }
  }
  render() {
    return (
      <CustomTable
        data={this.state.filteredFiles}
        columns={this.state.fileColumns}
        sizePerPage={14}
        keyField="idDocument"
        defaultSortedField="fileName"
        defaultSortedOrder="desc"
        showSizePerPage
        rowStyle={(row, rowIndex) => {
          if (
            !!this.props.selectedExistingFile &&
            row.idDocument === this.props.selectedExistingFile.idDocument
          ) {
            return {
              backgroundColor: Colors.veryLightBlue,
            };
          }
        }}
        expandRow={{
          renderer: data => {
            return this.props.detailsLoading ||
              this.state.isFetchingComments ? (
              <Loading height={"200px"} />
            ) : (
              <this.CommentRenderer data={data} />
            );
          },
          expandHeaderColumnRenderer: () => <></>,
          nonExpandable: this.state.nonExpandable,
          className: "expand-column",
          headerStyle: getGlobalHeaderStyle("5%"),
          expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
            if (!expandable) {
              return <></>;
            }
            return expanded ? (
              <i className="bx bx-chevron-down font-size-20"></i>
            ) : (
              <i className="bx bx-chevron-right font-size-20"></i>
            );
          },
          onExpand: (row, isExpand) => {
            if (isExpand) {
              this.props.onGetFileDetails(row.idDocument);
              this.setState({ expanded: [row.idDocument] });
            } else {
              this.setState({ expanded: [] });
            }
          },
          expanded: this.state.expanded,
          showExpandColumn: true,
          onlyOneExpanding: true,
          expandByColumnOnly: true,
        }}
        rowEvents={{
          onClick: this.handleRowClick,
        }}
      />
    );
  }
}

DocumentsTable.propTypes = {
  data: PropTypes.any,
  comments: PropTypes.array,
  onGetFileDetails: PropTypes.func,
  onRowClick: PropTypes.func,
  t: PropTypes.any,
  detailsLoading: PropTypes.bool,
  isFetchingComments: PropTypes.bool,
  onUpsertComment: PropTypes.func,
  fileDetails: PropTypes.any,
  detailsLoading: PropTypes.bool,
  handleFolderClick: PropTypes.func,
  path: PropTypes.array,
  showUploadType: PropTypes.bool,
  selectedExistingFile: PropTypes.any,
  download: PropTypes.func,
  onDelete: PropTypes.func,
};

const mapStateToProps = ({ files }) => ({
  fileDetails: files.fileDetails,
  detailsLoading: files.detailsLoading,
  comments: files.comments,
});

const mapDispatchToProps = dispatch => ({
  onGetFileDetails: payload => dispatch(getFileDetails(payload)),
  onUpsertComment: (payload, callback) =>
    dispatch(addFileComment(payload, callback)),
  download: payload => dispatch(downloadFile(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DocumentsTable));
