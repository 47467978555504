import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../../store/actions";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

// import logo from "../../assets/images/Day _logo_white.svg";
import logo from "../../assets/images/sta-logo.png";
import { Colors } from "common";
import { isRTL } from "common/utils";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: props.isSidebarOpen,
      toggleMenuIcon: true,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        isSidebarOpen: this.props.isSidebarOpen,
      });
    }
  }

  toggleMenu() {
    this.props.toggleMenuCallback();
    this.setState({ toggleMenuIcon: !this.state.toggleMenuIcon });
  }

  getChevronDirectionClass = () => {
    const isPageRTL = isRTL();

    const closeState = isPageRTL ? "left" : "right";
    const openState = isPageRTL ? "right" : "left";

    return `bx-chevron-${this.state.toggleMenuIcon ? openState : closeState}`;
  };

  render() {
    const { isSidebarOpen } = this.state;
    return (
      <React.Fragment>
        <div className="vertical-menu">
          {/* {isSidebarOpen && ( */}
          <div
            className="navbar-brand-box"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/home" className="logo logo-light">
              <span className="logo-lg">
                <img src={logo} alt=""  width="100" />
              </span>
            </Link>

            <div
              onClick={this.toggleMenu}
              style={{
                backgroundColor: "#389741",
                borderRadius: "50%",
                width: 30,
                height: 30,
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <i
                className={`bx ${this.getChevronDirectionClass()} bx-md`}
                style={{
                  color: Colors.white,
                }}
              ></i>
            </div>
          </div>
          {/* )} */}
          <div data-simplebar className="h-100">
            <SidebarContent
              toggleMenu={this.toggleMenu}
              toggleMenuIcon={this.state.toggleMenuIcon}
            />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  type: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  toggleMenuCallback: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
