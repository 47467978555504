import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { Card, CardBody } from "reactstrap";
import "./tasks.css";
//Import Breadcrumb
import DeleteModal from "components/Common/DeleteModal";
import { withTranslation } from "react-i18next";
import CreateTaskModal from "./create-task-modal";
import { getAllTasks, getTasks, uploadFiles, upsertTask } from "store/actions";
import {
  CURRENT_LANG,
  ENTITIES,
  getGlobalHeaderStyle,
  TOAST_OPTIONS,
} from "common";
import StatusText from "components/Common/StatusText";
import CustomTableWithServerSide from "components/Common/CustomTableWithServerSide";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
  sortCaretIcons,
} from "common/utils";
import { toast } from "react-toastify";
import Tippy from "@tippy.js/react";
export const PAGE_SIZE = 10;

class AllTasks extends Component {
  constructor(props) {
    super(props);
    let createEntities = filterPermittedEntities(props.permissions, "create");
    this.node = React.createRef();
    this.state = {
      tasks: [],
      allTasks: [],
      files: [],
      task: "",
      modal: false,
      deleteModal: false,
      createEntities,
      tabletStyle: props.tabletStyle,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,
      mobileStyle2: props.mobileStyle,
      searchString: "" 
    };
    this.handleTaskClick = this.handleTaskClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleAddTask = this.handleAddTask.bind(this);
    this.handleTaskCreate = this.handleTaskCreate.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleTaskCreate(values) {
    const { onAddNewTask, onGetAllTasks, onUploadFiles } = this.props;

    let task = {
      idTask: "00000000-0000-0000-0000-000000000000",
      taskNumber: "",
      title: values.name,
      regarding: {
        entityName: values.regarding.entityName,
        label: "",
        id: values.regarding.value,
      },
      dueDate: values.dueDate,
      createdOn: "",
      priority: {
        label: "",
        value: +values.priority.value,
      },
      assignedBy: {
        name: "",
        id: null,
        image: "",
        email: "",
      },
      assignedTo: {
        name: "",
        id: values.assignedTo.value,
        image: "",
        email: "",
      },
      status: null,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
    };

    onAddNewTask(task, id => {
      const { onGetAllTasks , statusCode} = this.props;
      if (this.state.files.length > 0) {
        const payload = {
          request: {
            updates: [],
            allowExternalUsers: false,
            allowDownloads: true,
            allowComments: true,
            createReference: true,
            path: `tasks/${id}`,
            parent: "00000000-0000-0000-0000-000000000000",
          },
          files: this.state.files,
        };
        onUploadFiles(payload, () => {
          onGetAllTasks({
            pageNumber:1,
            pageCount:PAGE_SIZE,
            statusCode:statusCode,
            searchString:""
          });
        });
      } else {
        onGetAllTasks({
          pageNumber:1,
          pageCount:PAGE_SIZE,
          statusCode:statusCode,
          searchString:""
        });
      }
      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Task")),
        TOAST_OPTIONS
      );
    });
    this.toggle();
  }

  handleAddTask = () => {
    this.setState({ task: "", isEdit: false });
    this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handleTaskClick = arg => {
    const task = arg;

    this.setState({
      task: {
        id: task.id,
        name: task.name,
        designation: task.designation,
        email: task.email,
        tags: task.tags,
        projects: task.projects,
      },
      isEdit: true,
    });

    this.toggle();
  };
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 915 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 675 });
    this.setState({ mobileStyle: window.innerWidth <= 555 });
    this.setState({ mobileStyle2: window.innerWidth <= 440 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const { tasks, allTasks , listStatus ,statusCode , searchString} = this.props;
    const { isEdit, deleteModal, createEntities } = this.state;

    const tasksColumns = [
      {
        text: this.props.t("ID"),
        dataField: "id",
        hidden: true,
      },
      {
        hidden: this.state.mobileStyle2,
        dataField: "taskNumber",
        text: this.props.t("Task ID"),
        formatter: (cellContent, task) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/task-details?id=" + task.id}
                query={task}
                className="text-primary text-decoration-underline"
              >
                {`#${task.taskNumber}`}
              </Link>
            </h5>
          </>
        ),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: !this.state.mobileStyle2,
        text: this.props.t("Task Name"),
        dataField: "title",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("30%"),
        align: "start",
        formatter: (cellContent, task) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/task-details?id=" + task.id}
                query={task}
                className="text-primary text-decoration-underline"
              >
                {task.title}
              </Link>
            </h5>
          </>
        ),
      },
      {
        hidden: this.state.mobileStyle2,
        text: this.props.t("Task Name"),
        dataField: "title",
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.mobileStyle,
        dataField:
          CURRENT_LANG === "ar" ? "regarding.labelAr" : "regarding.label",
        text: this.props.t("Task is for").toUpperCase(),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        dataField: "assignedBy.name",
        text: this.props.t("Assigned By"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
        formatter: (cell, task) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/individual-details?id=" + task.assignedBy?.id}
                className="text-dark"
              >
                {task.assignedBy?.name}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        dataField: "assignedTo.name",
        text: this.props.t("Assigned To"),
        sort: false,
        sortCaret: sortCaretIcons,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
        formatter: (cell, task) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/individual-details?id=" + task.assignedTo?.id}
                className="text-dark"
              >
                {task.assignedTo?.name}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "createdOn",
        text: this.props.t("Date Created").toUpperCase(),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, task) => (
          <>
            <p className="mb-0">{moment(task.createdOn).utc().format("DD/MM/YYYY")}</p>
          </>
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        hidden: this.state.tabletStyle,
        dataField: "dueDate",
        text: this.props.t("Due Date Form"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, task) => (
          <>
            <p className="mb-0">{moment(task.dueDate).utc().format("DD/MM/YYYY")}</p>
          </>
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
      {
        dataField: "status",
        text: this.props.t("STATUS"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, task) => (
          <StatusText text={task.status ? task.status.label?.toUpperCase() : ""} />
        ),
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "start",
      },
    ];


    const selectedStatusValue = listStatus.find(status => status.value === statusCode )
    const formattedRequestStatusValue = selectedStatusValue && {...selectedStatusValue, 
      label: `${this.props.t("Status")}: ${selectedStatusValue.label}`, 
      labelAr: `${this.props.t("Status")}: ${selectedStatusValue.labelAr}`}
const searchVal = searchString || ""
    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Card>
          <CardBody>
        
             <CustomTableWithServerSide
                  columns={tasksColumns}
                  data={allTasks}
                  showSearchAndAdd
                  addBtnText={this.props.t("Add Task")}
                  placeholderText={this.props.t("Search Tasks")}
                  handleAddClick={this.toggle}
                  hideAddBtn={!createEntities.includes(ENTITIES.TASK)}
                  defaultSortedField="createdOn"
                 
                  dropdownFilterOptions={listStatus}
                  dropdownFilterValue={formattedRequestStatusValue}
                  handleDropdownFilterChange={(val)=>this.props.updateStatus(val)}
                 
                  onPageChange={this.props.handlePageChange}
                  currentPage={this.props.currentPageIndex}
                  totalDataSize={this.props.totalDataSize}
                  
                  serverSearch={true}
                  onSearchBtnClick={(searchString)=>{this.props.onSearchSubmit(searchString)}}
                  searchVal={searchVal} 
                />  
          </CardBody>
        </Card>
        <CreateTaskModal
          modal={this.state.modal}
          toggle={this.toggle}
          handleSubmit={this.handleTaskCreate}
          isEdit={false}
          isFromAllTasks={true}
          files={this.state.files}
          onFilesAccepted={files => {
            this.setState({ files });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.TASK]}
        />
      </React.Fragment>
    );
  }
}

AllTasks.propTypes = {
  tasks: PropTypes.array,
  allTasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  onGetAllTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  onUploadFiles: PropTypes.func,
  tabletStyle: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  mobileStyle2: PropTypes.any,
  handlePageChange : PropTypes.any,
  currentPageIndex: PropTypes.any,
  totalDataSize: PropTypes.any,
  onSearchSubmit:PropTypes.func,
  updateStatus:PropTypes.func,
  listStatus: PropTypes.array,
  searchString: PropTypes.string,
  statusCode: PropTypes.string,
};

const mapStateToProps = ({ tasks, Layout }) => ({
  //   tasks: tasks.tasks,
  allTasks: tasks.allTasks,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  statusCode: tasks.statusCode,
});

const mapDispatchToProps = dispatch => ({
  onGetTasks: () => dispatch(getTasks()),
  onGetAllTasks: (payload) => dispatch(getAllTasks(payload)),
  onAddNewTask: (newTask, callback) => dispatch(upsertTask(newTask, callback)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AllTasks)));
