import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

class DashedLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.series,
      options: {
        legend: {
          position: "top",
          horizontalAlign: "center",
          squareSymbol: true,
        },
        chart: { zoom: { enabled: false }, toolbar: { show: false } },
        colors: ["#657DFF", "#07DF9A"],
        dataLabels: { enabled: false },
        stroke: { width: [2, 2], curve: "straight" },
        markers: { size: 6, hover: { sizeOffset: 2 } },
        xaxis: {
          categories: props.categories,
        },
        yaxis: {
          max: 100,
          min: 0,
          labels: {
            formatter: value =>
              !isNaN(value) && value >= 0 ? value.toFixed(0) : "",
          },
        },
        grid: { borderColor: "#f1f1f1" },
      },
    };
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height="373"
          className="apex-charts"
        />
      </React.Fragment>
    );
  }
}
DashedLine.propTypes = {
  series: PropTypes.any,
  categories: PropTypes.array,
};

export default DashedLine;
