import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Colors, isInternal } from "common";
import "./comments.scss";
import FileItem from "./FileItem";
import { Link } from "react-router-dom";
import Markdown from "components/Common/Markdown";
class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: props.comments?.reverse(),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (prevProps.comments?.length !== this.props.comments?.length) {
        this.setState({
          comments: this.props.comments?.reverse(),
        });
      }
    }
  }
  render() {
    if (this.state.comments && this.state.comments?.length === 0) {
      return <></>;
    }
    return (
      <div
        className={"scrollbar comments-container p-2 " + this.props.className}
        style={{
          maxHeight: 300,
          overflow: "scroll",
          overflowX: "hidden",
          backgroundColor: Colors.searchBG,
          ...this.props.style,
        }}
      >
        {this.state.comments?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                marginTop: index > 0 ? 10 : 0,
                borderBottom:
                  index < this.props.comments?.length - 1
                    ? `1px solid ${Colors.lightGrey}`
                    : 0,
                marginBottom: index === this.props.comments?.length - 1 ? 20 : 0,
              }}
            >
              <Link to={"/individual-details?id=" + item.commentedBy?.id}>
                <div className="avatar-xs me-3">
                  {!!item.commentedBy?.image ? (
                    <div className="rounded-circle avatar-sm">
                      <img
                        src={`data:image/png;base64,${item.commentedBy?.image}`}
                        className="rounded-circle avatar"
                        style={{ height: 40, width: 40 }}
                      />
                    </div>
                  ) : (
                    <div
                      className="avatar-title rounded-circle bg-primary bg-soft text-primary avatar-sm"
                      style={{ padding: 10 }}
                    >
                      <i className="bx bxs-user bx-sm"></i>
                    </div>
                  )}
                </div>
              </Link>
              <div>
                <Link to={"/individual-details?id=" + item.commentedBy?.id}>
                  <h5 className="font-size-14 mb-1">
                    {item.commentedBy?.name}
                    <small
                      className="text-muted"
                      style={{ marginInlineStart: 10 }}
                    >
                      {this.props.t("at") +
                        " " +
                        moment(item.commentedOn).format("hh:mma, DD MMMM YYYY")}
                    </small>
                  </h5>
                </Link>
                <div className="font-size-12 text-break">
                  <Markdown>{item.description}</Markdown>
                </div>
                {!!item.lstDocumentReference &&
                  Array.isArray(item.lstDocumentReference) &&
                  item.lstDocumentReference
                    .filter(f => (isInternal ? true : !f.isInternal))
                    .map((f, idx) => <FileItem file={f} key={idx} />)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
Comments.propTypes = {
  t: PropTypes.any,
  comments: PropTypes.array,
  style: PropTypes.any,
  className: PropTypes.string,
};
export default memo(withTranslation()(Comments));
