import {
  ADD_FILE_COMMENT,
  ADD_FILE_COMMENT_FAIL,
  ADD_FILE_COMMENT_SUCCESS,
  GET_FILE_CATEGORIES,
  GET_FILE_CATEGORIES_FAIL,
  GET_FILE_CATEGORIES_SUCCESS,
  GET_FILES,
  GET_FILES_FAIL,
  GET_FILES_SUCCESS,
  GET_FILE_DETAILS,
  GET_FILE_DETAILS_FAIL,
  GET_FILE_DETAILS_SUCCESS,
  GET_RELATED_FILES,
  GET_RELATED_FILES_FAIL,
  GET_RELATED_FILES_SUCCESS,
  UPLOAD_FILES,
  UPLOAD_FILES_FAIL,
  UPLOAD_FILES_SUCCESS,
  GET_REFERENCED_FILES,
  GET_REFERENCED_FILES_SUCCESS,
  GET_REFERENCED_FILES_FAIL,
  CREATE_REFERENCED_FILES,
  CREATE_REFERENCED_FILES_SUCCESS,
  CREATE_REFERENCED_FILES_FAIL,
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAIL,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  DELETE_FILE_SUCCESS,
  DELETE_FILE,
  DELETE_FILE_FAIL,
  GET_DOCUMENT_APPROVALS,
  GET_DOCUMENT_APPROVALS_SUCCESS,
  GET_DOCUMENT_APPROVALS_FAIL,
  GET_DOCUMENT_TASKS,
  GET_DOCUMENT_TASKS_SUCCESS,
  GET_DOCUMENT_TASKS_FAIL, 
  GET_DOCUMENT_PROJECTS,
  GET_DOCUMENT_PROJECTS_SUCCESS,
  GET_DOCUMENT_PROJECTS_FAIL, 
  GET_DOCUMENT_REQUESTS,
  GET_DOCUMENT_REQUESTS_SUCCESS,
  GET_DOCUMENT_REQUESTS_FAIL, 
  GET_DOCUMENT_ORGANIZATIONS,
  GET_DOCUMENT_ORGANIZATIONS_SUCCESS,
  GET_DOCUMENT_ORGANIZATIONS_FAIL, 
} from "./actionTypes";

export const getFiles = () => ({
  type: GET_FILES,
});

export const getFilesSuccess = payload => ({
  type: GET_FILES_SUCCESS,
  payload,
});

export const getFilesFail = error => ({
  type: GET_FILES_FAIL,
  payload: error,
});

export const getRelatedFiles = (payload, callback) => ({
  type: GET_RELATED_FILES,
  payload,
  callback,
});

export const getRelatedFilesSuccess = payload => ({
  type: GET_RELATED_FILES_SUCCESS,
  payload,
});

export const getRelatedFilesFail = error => ({
  type: GET_RELATED_FILES_FAIL,
  payload: error,
});

export const getFileCategories = payload => ({
  type: GET_FILE_CATEGORIES,
  payload,
});

export const getFileCategoriesSuccess = payload => ({
  type: GET_FILE_CATEGORIES_SUCCESS,
  payload,
});

export const getFileCategoriesFail = error => ({
  type: GET_FILE_CATEGORIES_FAIL,
  payload: error,
});

export const getFileDetails = payload => ({
  type: GET_FILE_DETAILS,
  payload,
});

export const getFileDetailsSuccess = payload => ({
  type: GET_FILE_DETAILS_SUCCESS,
  payload,
});

export const getFileDetailsFail = error => ({
  type: GET_FILE_DETAILS_FAIL,
  payload: error,
});

export const addFileComment = (payload, callback) => ({
  type: ADD_FILE_COMMENT,
  payload,
  callback,
});

export const addFileCommentSuccess = payload => ({
  type: ADD_FILE_COMMENT_SUCCESS,
  payload,
});

export const addFileCommentFail = error => ({
  type: ADD_FILE_COMMENT_FAIL,
  payload: error,
});

export const uploadFiles = (payload, callback) => ({
  type: UPLOAD_FILES,
  payload,
  callback,
});

export const uploadFilesSuccess = payload => ({
  type: UPLOAD_FILES_SUCCESS,
  payload,
});

export const uploadFilesFail = error => ({
  type: UPLOAD_FILES_FAIL,
  payload: error,
});

export const getReferencedFiles = (payload, callback) => ({
  type: GET_REFERENCED_FILES,
  payload,
  callback,
});

export const getReferencedFilesSuccess = payload => ({
  type: GET_REFERENCED_FILES_SUCCESS,
  payload,
});

export const getReferencedFilesFail = error => ({
  type: GET_REFERENCED_FILES_FAIL,
  payload: error,
});

export const createReferencedFiles = (payload, callback) => ({
  type: CREATE_REFERENCED_FILES,
  payload,
  callback,
});

export const createReferencedFilesSuccess = payload => ({
  type: CREATE_REFERENCED_FILES_SUCCESS,
  payload,
});

export const createReferencedFilesFail = error => ({
  type: CREATE_REFERENCED_FILES_FAIL,
  payload: error,
});

export const createFolder = (payload, callback) => ({
  type: CREATE_FOLDER,
  payload,
  callback,
});

export const createFolderSuccess = payload => ({
  type: CREATE_FOLDER_SUCCESS,
  payload,
});

export const createFolderFail = error => ({
  type: CREATE_FOLDER_FAIL,
  payload: error,
});

export const downloadFile = payload => ({
  type: DOWNLOAD_FILE,
  payload,
});

export const downloadFileSuccess = payload => ({
  type: DOWNLOAD_FILE_SUCCESS,
  payload,
});
export const downloadFileFail = payload => ({
  type: DOWNLOAD_FILE_FAIL,
  payload,
});
export const deleteFile = (payload, callback) => ({
  type: DELETE_FILE,
  payload,
  callback,
});

export const deleteFileSuccess = payload => ({
  type: DELETE_FILE_SUCCESS,
  payload,
});

export const deleteFileFail = error => ({
  type: DELETE_FILE_FAIL,
  payload: error,
});

export const getDocumentApprovals = payload => ({
  type: GET_DOCUMENT_APPROVALS,
  payload: payload,
})
export const getDocumentApprovalsSuccess = payload => ({
  type: GET_DOCUMENT_APPROVALS_SUCCESS,
  payload: payload,
})
export const getDocumentApprovalsFail = payload => ({
  type: GET_DOCUMENT_APPROVALS_FAIL,
  payload: payload,
})

export const getDocumentTasks = payload => ({
  type: GET_DOCUMENT_TASKS,
  payload: payload,
})
export const getDocumentTasksSuccess = payload => ({
  type:GET_DOCUMENT_TASKS_SUCCESS,
  payload: payload,
})
export const getDocumentTasksFail = payload => ({
  type: GET_DOCUMENT_TASKS_FAIL,
  payload: payload,
})



export const getDocumentProjects = payload => ({
  type: GET_DOCUMENT_PROJECTS,
  payload: payload,
})
export const getDocumentProjectsSuccess = payload => ({
  type: GET_DOCUMENT_PROJECTS_SUCCESS,
  payload: payload,
})
export const getDocumentProjectsFail = payload => ({
  type: GET_DOCUMENT_PROJECTS_FAIL,
  payload: payload,
})


export const getDocumentRequests = payload => ({
  type: GET_DOCUMENT_REQUESTS,
  payload: payload,
})
export const getDocumentRequestsSuccess = payload => ({
  type: GET_DOCUMENT_REQUESTS_SUCCESS,
  payload: payload,
})
export const getDocumentRequestsFail = payload => ({
  type: GET_DOCUMENT_REQUESTS_FAIL,
  payload: payload,
})
export const getOrganizations = payload => ({
  type: GET_DOCUMENT_ORGANIZATIONS,
  payload: payload,
})
export const getDocumentOrganizationsSuccess = payload => ({
  type: GET_DOCUMENT_ORGANIZATIONS_SUCCESS,
  payload: payload,
})
export const getDocumentOrganizationsFail = payload => ({
  type: GET_DOCUMENT_ORGANIZATIONS_FAIL,
  payload: payload,
})


