export const GET_FILES = "GET_FILES";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAIL = "GET_FILES_FAIL";

export const GET_RELATED_FILES = "GET_RELATED_FILES";
export const GET_RELATED_FILES_SUCCESS = "GET_RELATED_FILES_SUCCESS";
export const GET_RELATED_FILES_FAIL = "GET_RELATED_FILES_FAIL";

export const GET_FILE_CATEGORIES = "GET_FILE_CATEGORIES";
export const GET_FILE_CATEGORIES_SUCCESS = "GET_FILE_CATEGORIES_SUCCESS";
export const GET_FILE_CATEGORIES_FAIL = "GET_FILE_CATEGORIES_FAIL";

export const GET_FILE_DETAILS = "GET_FILE_DETAILS";
export const GET_FILE_DETAILS_SUCCESS = "GET_FILE_DETAILS_SUCCESS";
export const GET_FILE_DETAILS_FAIL = "GET_FILE_DETAILS_FAIL";

export const ADD_FILE_COMMENT = "ADD_FILE_COMMENT";
export const ADD_FILE_COMMENT_SUCCESS = "ADD_FILE_COMMENT_SUCCESS";
export const ADD_FILE_COMMENT_FAIL = "ADD_FILE_COMMENT_FAIL";

export const UPLOAD_FILES = "UPLOAD_FILES";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_FAIL = "UPLOAD_FILES_FAIL";

export const GET_REFERENCED_FILES = "GET_REFERENCED_FILES";
export const GET_REFERENCED_FILES_SUCCESS = "GET_REFERENCED_FILES_SUCCESS";
export const GET_REFERENCED_FILES_FAIL = "GET_REFERENCED_FILES_FAIL";

export const CREATE_REFERENCED_FILES = "CREATE_REFERENCED_FILES";
export const CREATE_REFERENCED_FILES_SUCCESS =
  "CREATE_REFERENCED_FILES_SUCCESS";
export const CREATE_REFERENCED_FILES_FAIL = "CREATE_REFERENCED_FILES_FAIL";

export const CREATE_FOLDER = "CREATE_FOLDER";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAIL = "CREATE_FOLDER_FAIL";

export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAIL = "DOWNLOAD_FILE_FAIL";

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";

export const GET_DOCUMENT_APPROVALS_SUCCESS = "GET_DOCUMENT_APPROVALS_SUCCESS"
export const GET_DOCUMENT_APPROVALS_FAIL = "GET_DOCUMENT_APPROVALS_FAIL"
export const GET_DOCUMENT_APPROVALS= "GET_DOCUMENT_APPROVALS"

export const GET_DOCUMENT_CORRESPONDENCES = "GET_DOCUMENT_CORRESPONDENCES"
export const GET_DOCUMENT_CORRESPONDENCES_FAIL = "GET_DOCUMENT_CORRESPONDENCES_FAIL"
export const GET_DOCUMENT_CORRESPONDENCES_SUCCESS= "GET_DOCUMENT_CORRESPONDENCES_SUCCESS"

export const GET_DOCUMENT_ORGANIZATIONS = "GET_DOCUMENT_ORGANIZATIONS"
export const GET_DOCUMENT_ORGANIZATIONS_FAIL = "GET_DOCUMENT_ORGANIZATIONS_FAIL"
export const GET_DOCUMENT_ORGANIZATIONS_SUCCESS= "GET_DOCUMENT_ORGANIZATIONS_SUCCESS"

export const GET_DOCUMENT_TASKS = "GET_DOCUMENT_TASKS"
export const GET_DOCUMENT_TASKS_FAIL = "GET_DOCUMENT_TASKS_FAIL"
export const GET_DOCUMENT_TASKS_SUCCESS= "GET_DOCUMENT_TASKS_SUCCESS"

export const GET_DOCUMENT_PROJECTS= "GET_DOCUMENT_PROJECTS"
export const GET_DOCUMENT_PROJECTS_FAIL = "GET_DOCUMENT_PROJECTS_FAIL"
export const GET_DOCUMENT_PROJECTS_SUCCESS= "GET_DOCUMENT_PROJECTS_SUCCESS"

export const GET_DOCUMENT_REQUESTS = "GET_DOCUMENT_REQUESTS"
export const GET_DOCUMENT_REQUESTS_FAIL = "GET_DOCUMENT_REQUESTS_FAIL"
export const GET_DOCUMENT_REQUESTS_SUCCESS= "GET_DOCUMENT_REQUESTS_SUCCESS"