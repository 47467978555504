import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_APPROVAL,
  GET_APPROVALS,
  GET_APPROVAL_FILES,
  UPSERT_APPROVAL,
  SUBMIT_APPROVAL_PATH,
  GET_ALL_APPROVALS_OVERVIEW,
  DELETE_APPROVAL,
} from "./actionTypes";
import {
  getApprovalsSuccess,
  getApprovalsFail,
  getApprovalSuccess,
  getApprovalFail,
  upsertApprovalSuccess,
  upsertApprovalFail,
  getApprovalFilesSuccess,
  getApprovalFilesFail,
  getAllApprovalsOverviewSuccess,
  getAllApprovalsOverviewFail,
  deleteApprovalSuccess,
  deleteApprovalFail,
} from "./actions";

import {
  getAllApprovalsOverview,
  getApprovalDetails,
  getApprovals,
  getRelatedFiles,
  upsertApproval,
  submitApprovalPath,
  deleteRecord,
} from "helpers/backend_helper";
import {
  EMPTY_GUID,
  ENTITIES,
  getGenericError,
  idContact,
  idRole,
  isInternal,
} from "common";
import { endTheBar } from "common/utils";

function* fetchAllApprovalsOverview({ payload }) {
  try {
    let body = {
      projectId: payload,
    };
    const response = yield call(getAllApprovalsOverview, body);
    yield put(getAllApprovalsOverviewSuccess(response.responseObject));
  } catch (error) {
    if (error.response) {
      yield put(
        getAllApprovalsOverviewFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getAllApprovalsOverviewFail(error.message));
    }
  }
}

function* fetchApprovals(callback) {
  try {
    const response = yield call(getApprovals);
    yield put(
      getApprovalsSuccess(response.responseObject.approvals.listApprovals)
    );
    endTheBar();
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        getApprovalsFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getApprovalsFail(error.message));
    }
  }
}

function* fetchApprovalsDetails({ payload, callback }) {
  let body = {
    id: payload,
    entity: ENTITIES.APPROVAL,
  };
  try {
    const response = yield call(getApprovalDetails, body);
    yield put(getApprovalSuccess(response.responseObject));
    endTheBar();
    callback && callback(response.responseObject);
  } catch (error) {
    if (error.response) {
      yield put(
        getApprovalFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getApprovalFail(error.message));
    }
  }
}

function* upsertApprovalSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(upsertApproval, body);
    yield put(upsertApprovalSuccess(response));
    callback && callback(response.responseObject.idApproval);
  } catch (error) {
    if (error.response) {
      yield put(
        upsertApprovalFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertApprovalFail(error.message));
    }
  }
}
function* upsertApprovalPathSaga({ payload, callback }) {
  try {
    let body = payload;
    const response = yield call(submitApprovalPath, body);
    yield put(upsertApprovalSuccess(response));
    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        upsertApprovalFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertApprovalFail(error.message));
    }
  }
}

function* fetchApprovalFiles({ payload, callback }) {
  try {
    let body = {
      id: payload.idEntity,
      entity: payload.entity,
    };
    const response = yield call(getRelatedFiles, body);
    yield put(getApprovalFilesSuccess(response.responseObject.lstDocuments));

    callback && callback();
  } catch (error) {
    if (error.response) {
      yield put(
        getApprovalFilesFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(getApprovalFilesFail(error.message));
    }
  }
}
function* deleteApprovalSaga({ payload, callback }) {
  try {
    let body = {
      basicParameters: {
        entity: payload.basicParameters.entity,
        isExternalUser: !isInternal,
        operation: "post",
        idContact: idContact,
        idObject: payload.request.idRequest,
        idOrganizationCallingUser: EMPTY_GUID,
        idRole: idRole,
        canDelete: true,
      },
      request: payload.request,
    };
    const response = yield call(deleteRecord, body);
    yield put(deleteApprovalSuccess(response));
    callback && callback(response.responseObject.idApproval);
  } catch (error) {
    if (error.response) {
      yield put(
        deleteApprovalFail(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(upsertApprovalFail(error.message));
    }
  }
}

function* approvalsSaga() {
  yield takeEvery(GET_APPROVALS, fetchApprovals);
  yield takeEvery(GET_APPROVAL, fetchApprovalsDetails);
  yield takeEvery(UPSERT_APPROVAL, upsertApprovalSaga);
  yield takeEvery(SUBMIT_APPROVAL_PATH, upsertApprovalPathSaga);
  yield takeEvery(GET_APPROVAL_FILES, fetchApprovalFiles);
  yield takeEvery(GET_ALL_APPROVALS_OVERVIEW, fetchAllApprovalsOverview);
  yield takeEvery(DELETE_APPROVAL, deleteApprovalSaga);
}

export default approvalsSaga;
