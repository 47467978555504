import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { getEntityLookups, getIndividualLevelDashboards } from "store/actions";
import DashboardCard from "common/dashboardCard";

import projectDashboardIcon from "../../../assets/icons/projectDashboard.svg";
import dashboardTaskIcon from "../../../assets/icons/dashboardTask.svg";
import dashboardApprovalIcon from "../../../assets/icons/dashboardApproval.svg";

import Rating from "components/Common/rating";
import TopProjectsStatus from "./top-projects-status";
import { ENTITIES } from "common";
import ReactApexChart from "react-apexcharts";
import ProjectHealthComponent from "../ProjectHealthComponent";
import CustomSelect from "components/Common/CustomSelect";
import { beginTheBar } from "common/utils";
import Loading from "pages/Loading";

const BAR_CHART_OPTIONS = {
  chart: {
    type: "bar",
    height: 350,
    toolbar: {
      show: false,
    },
    offsetX: -55,
  },
  plotOptions: {
    bar: {
      // horizontal: false,
    },
  },
  stroke: {
    width: 0,
  },
  yaxis: {
    show: false,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  xaxis: {
    show: false,
    categories: ["Planned", "Actual"],
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return parseInt(val);
      },
    },
  },
  fill: {
    opacity: 1,
  },
  colors: ["#F4BD33", "#D93954"],
  legend: {
    // offsetX: -7,
    offsetY: 10,
    show: true,
    markers: {
      width: 8,
      height: 8,
      radius: 12,
    },
  },
};
class IndividualLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProject: null,
      selectedIndividual: null,
      seriesForPlannedVsActualTasks: [
        {
          name: "Planned",
          data: [46, 57, 59, 54],
        },
        {
          name: "Actual",
          data: [-54, -83, -29, -67],
        },
      ],
      categoriesForPlannedVsActualTasks: ["Task1", "Task2", "Task3", "Task4"],
    };
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleIndividualChange = this.handleIndividualChange.bind(this);
  }

  componentDidMount() {
    const { onGetLookups } = this.props;
    onGetLookups(ENTITIES.DASHBOARDS, () => {});
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      //handle first time fetching array
      if (
        !!!this.state.selectedProject &&
        !!this.props.entityLookups &&
        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS] &&
        !!this.props.entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects
          ?.length > 0
      ) {
        this.handleProjectChange(
          this.props.entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects[0]
        );
      }
    }
  }

  handleProjectChange(project) {
    beginTheBar();
    this.setState({ selectedProject: project, selectedIndividual: null });
    this.props.onGetIndividualLevelDashboards({
      projectId: project.value || "",
    });
  }

  handleIndividualChange(individual) {
    beginTheBar();
    this.setState({ selectedIndividual: individual });
    this.props.onGetIndividualLevelDashboards({
      projectId: this.state.selectedProject?.value || "",
      individualId: individual.value || "",
    });
  }

  render() {
    const { entityLookups, loadingLookups, individualOverview, loading } =
      this.props;
    if (!entityLookups || loadingLookups || !individualOverview) {
      return <Loading />;
    }
    const projects = individualOverview.projectIndividualsProgress.slice(0, 5);
    const projectsProgressSeries = [
      {
        name: this.props.t("Progress"),
        data: projects.map(item => item.progress),
      },
    ];
    const projectsProgressCategories = projects.map(item => item.projectName);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Individual Level")}</title>
          </MetaTags>
          <Container fluid>
            <Row className="day-row-flex-end day-justify-end day-w-100 g-0 mb-5">
              <Col sm={2} className={"mx-2"}>
                <CustomSelect
                  value={this.state.selectedIndividual}
                  onChange={this.handleIndividualChange}
                  options={individualOverview.teamMember}
                />
              </Col>
              <Col sm={2}>
                <CustomSelect
                  value={this.state.selectedProject}
                  onChange={this.handleProjectChange}
                  options={
                    entityLookups?.[ENTITIES.DASHBOARDS].listRequestProjects ||
                    []
                  }
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                xxl={6}
                className="g-1 col-margin-top-project-level"
              >
                <Card>
                  <CardBody style={{ height: 319 }}>
                    <div className="d-flex flex-column align-items-center my-4 day-h-100">
                      {!!individualOverview?.individualDetails ? (
                        <>
                          {!!individualOverview?.individualDetails?.image && (
                            <img
                              src={`data:image/png;base64,${individualOverview?.individualDetails?.image}`}
                              className="avatar-xl rounded-circle card-hover"
                            />
                          )}

                          <p className="day-top-projects my-2">
                            {individualOverview?.individualDetails?.name}
                          </p>
                          <p className="day-team-member-margin-top">
                            {individualOverview?.individualDetails?.role}
                          </p>
                          <Rating
                            value={
                              individualOverview?.individualDetails
                                ?.productivityScore
                            }
                          />
                        </>
                      ) : (
                        <div
                          className="day-row day-justify-center"
                          style={{ height: 240 }}
                        >
                          <h3 className="mb-0">
                            {this.props.t("Please Select Individual")}
                          </h3>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Row className="g-1">
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="g-1 col-margin-top-project-level"
                  >
                    <DashboardCard
                      title={this.props.t("All Projects")}
                      value={individualOverview.numberOfProjects}
                      percentage={individualOverview.projectsVsPreviousMonth}
                      previousMonthTitle={this.props.t("vs Previous Month")}
                      icon={projectDashboardIcon}
                      onClick={() => {
                        this.props.history.push("/projects-overview");
                      }}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="g-1 col-margin-top-project-level"
                  >
                    <DashboardCard
                      title={this.props.t("All Tasks")}
                      value={individualOverview.numberOfTasks}
                      percentage={individualOverview.tasksVsPreviousMonth}
                      previousMonthTitle={this.props.t("vs Previous Month")}
                      icon={dashboardTaskIcon}
                      onClick={() => {
                        this.props.history.push("/tasks");
                      }}
                    />
                  </Col>
                  <Col xs={12} className="g-1 col-margin-top-project-level">
                    <DashboardCard
                      title={this.props.t("All Approvals")}
                      value={individualOverview.numberOfApprovals}
                      percentage={individualOverview.approvalsVsPreviousMonth}
                      previousMonthTitle={this.props.t("vs Previous Month")}
                      icon={dashboardApprovalIcon}
                      onClick={() => {
                        this.props.history.push("/approvals");
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="g-1">
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <TopProjectsStatus
                  series={projectsProgressSeries}
                  categories={projectsProgressCategories}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Card style={{ marginTop: -20, height: 442 }}>
                  <CardBody className="overflow-scroll scrollbar">
                    <div className="d-flex justify-content-between">
                      <p className="day-top-projects">
                        {this.props.t("Tasks Planned vs Actual")}
                      </p>
                      <p className="day-top-five mx-2">
                        {this.props.t("*Upcoming deadlines and overdue")}
                      </p>
                    </div>
                    <ReactApexChart
                      options={BAR_CHART_OPTIONS}
                      series={[
                        {
                          name: this.props.t("Planned"),
                          data: [individualOverview.plannedVsActual.planned],
                        },
                        {
                          name: this.props.t("Actual"),
                          data: [individualOverview.plannedVsActual.actual],
                        },
                      ]}
                      type="bar"
                      height={310}
                      width={240}
                      className="apex-charts-dashboards"
                    />
                  </CardBody>
                </Card>
                {/* <PlannedVsActualChart
                  series={this.state.seriesForPlannedVsActualTasks}
                  categories={this.state.categoriesForPlannedVsActualTasks}
                  title={this.props.t("Tasks Planned vs Actual")}
                /> */}
              </Col>
            </Row>
            <Row className="g-1 row-margin-top">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <ProjectHealthComponent overview={individualOverview} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

IndividualLevel.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  loadingLookups: PropTypes.bool,
  entityLookups: PropTypes.any,
  onGetIndividualLevelDashboards: PropTypes.func,
  onGetLookups: PropTypes.func,
  individualOverview: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = ({ contacts, Layout }) => ({
  loading: contacts.loading,
  individualOverview: contacts.individualOverview,
  loadingLookups: Layout.loadingLookups,
  entityLookups: Layout.entityLookups,
});

const mapDispatchToProps = dispatch => ({
  onGetIndividualLevelDashboards: (payload, callback) =>
    dispatch(getIndividualLevelDashboards(payload, callback)),
  onGetLookups: (payload, callback) =>
    dispatch(getEntityLookups(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(IndividualLevel)));
