import {
  GET_APPROVAL,
  GET_APPROVALS,
  GET_APPROVALS_FAIL,
  GET_APPROVALS_SUCCESS,
  GET_APPROVAL_FAIL,
  GET_APPROVAL_SUCCESS,
  UPSERT_APPROVAL,
  UPSERT_APPROVAL_SUCCESS,
  UPSERT_APPROVAL_FAIL,
  GET_APPROVAL_FILES,
  GET_APPROVAL_FILES_SUCCESS,
  GET_APPROVAL_FILES_FAIL,
  GET_ALL_APPROVALS_OVERVIEW,
  GET_ALL_APPROVALS_OVERVIEW_SUCCESS,
  GET_ALL_APPROVALS_OVERVIEW_FAIL,
  CLEAR_APPROVAL,
  SUBMIT_APPROVAL_PATH,
  DELETE_APPROVAL,
  DELETE_APPROVAL_SUCCESS,
  DELETE_APPROVAL_FAIL,
  // GET_COMMENTS_IN_APPROVAL_SUCCESS,
  // GET_COMMENTS_IN_APPROVAL_FAIL,
  // GET_COMMENTS_IN_APPROVAL,
} from "./actionTypes";

export const getApprovals = callback => ({
  type: GET_APPROVALS,
  callback,
});

export const clearApproval = () => ({
  type: CLEAR_APPROVAL,
});

export const getApprovalsSuccess = payload => ({
  type: GET_APPROVALS_SUCCESS,
  payload,
});

export const getApprovalsFail = error => ({
  type: GET_APPROVALS_FAIL,
  payload: error,
});

export const getApprovalDetails = (id, callback) => ({
  type: GET_APPROVAL,
  payload: id,
  callback,
});

export const getApprovalSuccess = payload => ({
  type: GET_APPROVAL_SUCCESS,
  payload,
});

export const getApprovalFail = error => ({
  type: GET_APPROVAL_FAIL,
  payload: error,
});

export const upsertApproval = (payload, callback) => ({
  type: UPSERT_APPROVAL,
  payload,
  callback,
});
export const submitApprovalPath = (payload, callback) => ({
  type: SUBMIT_APPROVAL_PATH,
  payload,
  callback,
});

export const upsertApprovalSuccess = payload => ({
  type: UPSERT_APPROVAL_SUCCESS,
  payload,
});

export const upsertApprovalFail = error => ({
  type: UPSERT_APPROVAL_FAIL,
  payload: error,
});

export const getApprovalFiles = (payload, callback) => ({
  type: GET_APPROVAL_FILES,
  payload,
  callback,
});

export const getApprovalFilesSuccess = payload => ({
  type: GET_APPROVAL_FILES_SUCCESS,
  payload,
});

export const getApprovalFilesFail = error => ({
  type: GET_APPROVAL_FILES_FAIL,
  payload: error,
});

export const getAllApprovalsOverview = payload => ({
  type: GET_ALL_APPROVALS_OVERVIEW,
  payload,
});

export const getAllApprovalsOverviewSuccess = project => ({
  type: GET_ALL_APPROVALS_OVERVIEW_SUCCESS,
  payload: project,
});

export const getAllApprovalsOverviewFail = error => ({
  type: GET_ALL_APPROVALS_OVERVIEW_FAIL,
  payload: error,
});

// export const getCommentsInApproval = id => ({
//   type: GET_COMMENTS_IN_APPROVAL,
//   payload: id,
// });

// export const getCommentsInApprovalSuccess = payload => ({
//   type: GET_COMMENTS_IN_APPROVAL_SUCCESS,
//   payload,
// });

// export const getCommentsInApprovalFail = error => ({
//   type: GET_COMMENTS_IN_APPROVAL_FAIL,
//   payload: error,
// });

export const deleteApproval = (payload, callback) => ({
  type: DELETE_APPROVAL,
  payload,
  callback,
});
export const deleteApprovalSuccess = payload => ({
  type: DELETE_APPROVAL_SUCCESS,
  payload,
});

export const deleteApprovalFail = error => ({
  type: DELETE_APPROVAL_FAIL,
  payload: error,
});
