import {
  GET_TASKS,
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  GET_ALL_TASKS,
  GET_ALL_TASKS_FAIL,
  GET_ALL_TASKS_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_DETAILS_FAIL,
  UPSERT_TASK,
  UPSERT_TASK_SUCCESS,
  UPSERT_TASK_FAIL,
  UPSERT_COMMENTS,
  UPSERT_COMMENTS_SUCCESS,
  UPSERT_COMMENTS_FAIL,
  GET_TASK_FILES,
  GET_TASK_FILES_SUCCESS,
  GET_TASK_FILES_FAIL,
  GET_ALL_TASKS_OVERVIEW,
  GET_ALL_TASKS_OVERVIEW_SUCCESS,
  GET_ALL_TASKS_OVERVIEW_FAIL,
  // GET_COMMENTS_IN_TASKS_SUCCESS,
  // GET_COMMENTS_IN_TASKS_FAIL,
  // GET_COMMENTS_IN_TASKS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  GET_TASKS_DASHBOARD,
  GET_TASKS_DASHBOARD_SUCCESS,
  GET_TASKS_DASHBOARD_FAIL

} from "./actionTypes";

export const getTasks = () => ({
  type: GET_TASKS,
});

export const getTasksSuccess = tasks => ({
  type: GET_TASKS_SUCCESS,
  payload: tasks,
});

export const getTasksFail = error => ({
  type: GET_TASKS_FAIL,
  payload: error,
});

export const getAllTasks = (payload) => ({
  type: GET_ALL_TASKS,
  payload
});

export const getAllTasksSuccess = payload => ({
  type: GET_ALL_TASKS_SUCCESS,
  payload: payload,
});

export const getAllTasksFail = error => ({
  type: GET_ALL_TASKS_FAIL,
  payload: error,
});

export const getTaskDetails = id => ({
  type: GET_TASK_DETAILS,
  payload: id,
});

export const getTaskDetailsSuccess = taskDetails => ({
  type: GET_TASK_DETAILS_SUCCESS,
  payload: taskDetails,
});

export const getTaskDetailsFail = error => ({
  type: GET_TASK_DETAILS_FAIL,
  payload: error,
});

// export const getCommentsInTask = id => ({
//   type: GET_COMMENTS_IN_TASKS,
//   payload: id,
// });

// export const getCommentsInTaskSuccess = payload => ({
//   type: GET_COMMENTS_IN_TASKS_SUCCESS,
//   payload,
// });

// export const getCommentsInTaskFail = error => ({
//   type: GET_COMMENTS_IN_TASKS_FAIL,
//   payload: error,
// });
export const upsertTask = (payload, callback) => ({
  type: UPSERT_TASK,
  payload,
  callback,
});

export const upsertTaskSuccess = payload => ({
  type: UPSERT_TASK_SUCCESS,
  payload,
});

export const upsertTaskFail = error => ({
  type: UPSERT_TASK_FAIL,
  payload: error,
});

export const upsertComments = (payload, callback) => ({
  type: UPSERT_COMMENTS,
  payload,
  callback,
});

export const upsertCommentsSuccess = payload => ({
  type: UPSERT_COMMENTS_SUCCESS,
  payload,
});

export const upsertCommentsFail = error => ({
  type: UPSERT_COMMENTS_FAIL,
  payload: error,
});

export const getTaskFiles = (payload, callback) => ({
  type: GET_TASK_FILES,
  payload,
  callback,
});

export const getTaskFilesSuccess = payload => ({
  type: GET_TASK_FILES_SUCCESS,
  payload,
});

export const getTaskFilesFail = error => ({
  type: GET_TASK_FILES_FAIL,
  payload: error,
});

export const getAllTasksOverview = payload => ({
  type: GET_ALL_TASKS_OVERVIEW,
  payload,
});

export const getAllTasksOverviewSuccess = project => ({
  type: GET_ALL_TASKS_OVERVIEW_SUCCESS,
  payload: project,
});

export const getAllTasksOverviewFail = error => ({
  type: GET_ALL_TASKS_OVERVIEW_FAIL,
  payload: error,
});

export const deleteTask = (payload, callback) => ({
  type: DELETE_TASK,
  payload,
  callback,
});

export const deleteTaskSuccess = payload => ({
  type: DELETE_TASK_SUCCESS,
  payload,
});

export const deleteTaskFail = error => ({
  type: DELETE_TASK_FAIL,
  payload: error,
});

export const getTasksDashboard = () => ({
  type: GET_TASKS_DASHBOARD

})

export const getTasksDashboardSuccess = payload => ({
  type: GET_TASKS_DASHBOARD_SUCCESS,
  payload,
});

export const getTasksDashboardFail = error => ({
  type: GET_TASKS_DASHBOARD_FAIL,
  payload: error,
});