import React from "react";
import Widget from "common/widget";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Colors} from "common";

const RequestDashboard = props => {
    const{data} = props;
        return(
        <Row className="g-1 row-margin-top-nav">
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={3}
          xl={3}
          xxl={3}
          style={{
            height: 235,
          }}
        >
          <Widget
            title={props.t("Requests").toUpperCase()}
            legendText1={props.t("Delayed")}
            legendColor1={Colors.red}
            legendValue1={data.requestByStatus?.delayedCount}
            legendText2={props.t("Completed")}
            legendColor2={Colors.staBlue}
            legendValue2={data.requestByStatus?.completedCount}
            legendText3={""}
            legendColor3={""}
            legendValue3={0}
            dataLabelsEnabled
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={3}
          xl={3}
          xxl={3}
          style={{
            height: 235,
          }}
        >
          <Widget
            title={props.t("Ongoing").toUpperCase()}
            legendText1={props.t("Delayed")}
            legendColor1={Colors.red}
            legendValue1={data.requestByOngoing?.delayedCount}
            legendText2={props.t("Due Today")}
            legendColor2={Colors.yellow}
            legendValue2={data.requestByOngoing?.dueTodayCount}
            legendText3={""}
            legendColor3={""}
            legendValue3={0}
            dataLabelsEnabled
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={6}
          lg={3}
          xl={3}
          xxl={3}
          style={{
            height: 235,
          }}
        >
          <Widget
            title={props.t("Requests By Importance").toUpperCase()}
            legendText1={props.t("High")}
            legendColor1={Colors.purple}
            legendValue1={data.requestByImportance?.highCount}
            legendText2={props.t("Low")}
            legendColor2={Colors.lightPurple}
            legendValue2={data.requestByImportance?.lowCount}
            legendText3={""}
            legendColor3={""}
            legendValue3={0}
            dataLabelsEnabled
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={3}
          xl={3}
          xxl={3}
          style={{
            height: 235,
          }}
        >
          <Widget
            title={props.t("Delayed By Importance").toUpperCase()}
            legendText1={props.t("High")}
            legendColor1={Colors.purple}
            legendValue1={data.delayedRequestByImportance?.highCount}
            legendText2={props.t("Medium")}
            legendColor2={Colors.yellow}
            legendValue2={data.delayedRequestByImportance?.mediumCount}
            legendText3={props.t("Low")}
            legendColor3={Colors.lightPurple}
            legendValue3={data.delayedRequestByImportance?.lowCount}
            dataLabelsEnabled
          />
        </Col>
      </Row>
    )
}

RequestDashboard.propTypes = {
    data: PropTypes.any,
    t: PropTypes.any,
  };
export default React.memo(RequestDashboard)