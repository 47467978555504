import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_EXTERNAL_DETAILS,
  GET_INTERNAL_DETAILS,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  apiError: "",
  success: "",
  loading: false,
  loadingRegister: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      state = { ...state, loading: true };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, loading: false, apiError: "" };
      break;
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, apiError: action.payload, loading: false };
      break;
    case RESET_PASSWORD:
      state = { ...state, loadingRegister: true };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = { ...state, loadingRegister: false, apiError: "" };
      break;
    case RESET_PASSWORD_ERROR:
      state = { ...state, apiError: action.payload, loadingRegister: false };
      break;
    case FORGOT_PASSWORD:
      state = { ...state, loadingRegister: true };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      state = { ...state, loadingRegister: false, apiError: "" };
      break;
    case FORGOT_PASSWORD_ERROR:
      state = { ...state, apiError: action.payload, loadingRegister: false };
      break;
    case REGISTER:
      state = { ...state, loadingRegister: true };
      break;
    case REGISTER_SUCCESS:
      state = { ...state, loadingRegister: false, apiError: "" };
      break;
    case REGISTER_ERROR:
      state = { ...state, apiError: action.payload, loadingRegister: false };
      break;
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loading: false, error: "" };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case GET_INTERNAL_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_EXTERNAL_DETAILS:
      state = { ...state, loading: true };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
