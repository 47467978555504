import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Card, CardBody, Col, Row } from "reactstrap";

import DashboardCard from "common/dashboardCard";

import dashboardTaskIcon from "../../../assets/icons/dashboardTask.svg";
import dashboardApprovalIcon from "../../../assets/icons/dashboardApproval.svg";
import dashboardCorrespondenceIcon from "../../../assets/icons/dashboardCorrespondence.svg";

import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import TeamMembers from "../ProjectLevel/team-members";
import Knob from "pages/AllCharts/knob/knob";
import LegendText from "components/Common/LegendText";
import { connect } from "react-redux";
import Loading from "pages/Loading";
import KeyHighlights from "./KeyHighlights";

class ExecutiveAttention extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMembers: [
        {
          name: "Sammy Kilback",
          role: "Engagement Lead",
          img: avatar1,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
        {
          name: "Ellis Considine",
          role: "BI Lead",
          img: avatar2,
          rating: "Adequate (6.1)",
        },
        {
          name: "Nettie Mills",
          role: "Engagement Lead",
          img: avatar4,
          rating: "Adequate (6.1)",
        },
      ],
    };
    this.getRiskText = this.getRiskText.bind(this);
  }

  getRiskText(risk) {
    if (risk.highRisk || risk.mediumRisk) {
      return this.props
        .t("Risks Text")
        .replace("*", risk.highRisk)
        .replace("*", risk.mediumRisk)
        .replace("*", risk.lowRisk);
    } else {
      return this.props.t("Risks Under Control");
    }
  }
  render() {
    const { executiveOverview } = this.props;
    if (!executiveOverview) {
      return <Loading />;
    }
    const executiveDetails = executiveOverview.executiveAttention;
    return (
      <React.Fragment>
        <MetaTags>
          <title>{this.props.t("Executive Level")}</title>
        </MetaTags>
        <Row>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={4}
            xxl={4}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("Overdue Tasks")}
              value={executiveDetails.overdueTasks}
              percentage={executiveDetails.overdueTasksVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardTaskIcon}
              onClick={() => {
                this.props.history.push("/tasks");
              }}
            />
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={4}
            xxl={4}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("Rejected Approvals")}
              value={executiveDetails.rejectedApprovals}
              percentage={executiveDetails.rejectedVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardApprovalIcon}
              onClick={() => {
                this.props.history.push("/approvals");
              }}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={4}
            xxl={4}
            className="g-1 col-margin-top-project-level"
          >
            <DashboardCard
              title={this.props.t("At Risk Correspondences")}
              value={executiveDetails.atRiskCorrespondence}
              percentage={executiveDetails.atRiskCorrespondenceVsPreviousMonth}
              previousMonthTitle={this.props.t("vs Previous Month")}
              icon={dashboardCorrespondenceIcon}
              onClick={() => {
                this.props.history.push("/correspondence");
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="g-1 col-margin-top-project-level"
          >
            <Card>
              <CardBody style={{ height: "895px" }}>
                <p className="day-top-projects">
                  {this.props.t("Project Summary")}
                </p>
                <div className="d-flex align-items-center justify-content-between my-5">
                  <Knob
                    value={executiveDetails.atRisk}
                    total={100}
                    bgColor={"#4C4D4C80"}
                    fgColor={"#01B82A"}
                    knobColor={"#01B82A"}
                    thickness={0.09}
                    lineCap="round"
                    onChange={() => {}}
                    text={this.props.t("At Risk")}
                  />
                  <KeyHighlights executiveDetails={executiveDetails} />
                </div>
                <div className="w-75 ms-2">
                  <Row className="border-bottom mx-0">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 day-project-health-title my-3"
                    >
                      {this.props.t("Schedule")}
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 my-3"
                    >
                      <LegendText
                        text={executiveDetails.projectSummary.schedule}
                      />
                    </Col>
                  </Row>
                  <Row className="border-bottom mx-0">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 day-project-health-title my-3"
                    >
                      {this.props.t("Risks")}
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 my-3"
                    >
                      <LegendText
                        displayText={this.getRiskText(
                          executiveDetails.projectSummary.risk
                        )}
                        text={
                          executiveDetails.projectSummary.risk.highRisk > 0
                            ? "HIGH"
                            : executiveDetails.projectSummary.risk.mediumRisk >
                              0
                            ? "MEDIUM"
                            : "LOW"
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="border-bottom mx-0">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 day-project-health-title my-3"
                    >
                      {this.props.t("Approvals")}
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 my-3"
                    >
                      <LegendText
                        text={executiveDetails.projectSummary.projectApprovals}
                      />
                    </Col>
                  </Row>
                  <Row className="border-bottom mx-0">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 day-project-health-title my-3"
                    >
                      {this.props.t("Correspondences")}
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="p-0 my-3"
                    >
                      <LegendText
                        text={
                          executiveDetails.projectSummary.projectCorrespondence
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="g-1 col-margin-top-project-level"
          >
            <TeamMembers data={executiveDetails.projectTeamMembers} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

ExecutiveAttention.propTypes = {
  t: PropTypes.any,
  executiveOverview: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({
  executiveOverview: contacts.executiveOverview,
});
export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(ExecutiveAttention)));
