import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USERS,
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  GET_ORGANIZATION_DETAILS_FAIL,
  UPSERT_ORGANIZATION,
  UPSERT_ORGANIZATION_SUCCESS,
  UPSERT_ORGANIZATION_FAIL,
  GET_ORGANIZATION_FILES,
  GET_ORGANIZATION_FILES_SUCCESS,
  GET_ORGANIZATION_FILES_FAIL,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS_SUCCESS,
  GET_INDIVIDUAL_LEVEL_DASHBOARDS_FAIL,
  GET_EXECUTIVE_DASHBOARDS,
  GET_EXECUTIVE_DASHBOARDS_SUCCESS,
  GET_EXECUTIVE_DASHBOARDS_FAIL,
} from "./actionTypes";

export const getUsers = (payload) => ({
  type: GET_USERS,
  payload:payload
});

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
});

export const getUserProfile = id => ({
  type: GET_USER_PROFILE,
  payload: id,
});

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
});

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
});

export const addNewUser = (user, callback) => ({
  type: ADD_NEW_USER,
  payload: user,
  callback,
});

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
});

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
});

export const updateUser = (user, callback) => ({
  type: UPDATE_USER,
  payload: user,
  callback,
});

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
});

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const getAllOrganizations = (payload) => ({
  type: GET_ORGANIZATIONS,
  payload:payload
});

export const getOrganizationsSuccess = organizations => ({
  type: GET_ORGANIZATIONS_SUCCESS,
  payload: organizations,
});

export const getOrganizationsFail = error => ({
  type: GET_ORGANIZATIONS_FAIL,
  payload: error,
});

export const getAllDetails = id => ({
  type: GET_ORGANIZATION_DETAILS,
  payload: id,
});

export const getDetailsSuccess = organizationDetail => ({
  type: GET_ORGANIZATION_DETAILS_SUCCESS,
  payload: organizationDetail,
});

export const getDetailsFail = error => ({
  type: GET_ORGANIZATION_DETAILS_FAIL,
  payload: error,
});

export const upsertOrganization = (payload, callback) => ({
  type: UPSERT_ORGANIZATION,
  payload,
  callback,
});

export const upsertOrganizationSuccess = payload => ({
  type: UPSERT_ORGANIZATION_SUCCESS,
  payload,
});

export const upsertOrganizationFail = error => ({
  type: UPSERT_ORGANIZATION_FAIL,
  payload: error,
});

export const getOrganizationsFiles = (payload, callback) => ({
  type: GET_ORGANIZATION_FILES,
  payload,
  callback,
});

export const getOrganizationsFilesSuccess = payload => ({
  type: GET_ORGANIZATION_FILES_SUCCESS,
  payload,
});

export const getOrganizationsFilesFail = error => ({
  type: GET_ORGANIZATION_FILES_FAIL,
  payload: error,
});

export const getIndividualLevelDashboards = (payload, callback) => ({
  type: GET_INDIVIDUAL_LEVEL_DASHBOARDS,
  payload,
  callback,
});

export const getIndividualLevelDashboardsSuccess = payload => ({
  type: GET_INDIVIDUAL_LEVEL_DASHBOARDS_SUCCESS,
  payload,
});

export const getIndividualLevelDashboardsFail = error => ({
  type: GET_INDIVIDUAL_LEVEL_DASHBOARDS_FAIL,
  payload: error,
});

export const getExecutiveDashboards = (payload, callback) => ({
  type: GET_EXECUTIVE_DASHBOARDS,
  payload,
  callback,
});

export const getExecutiveDashboardsSuccess = payload => ({
  type: GET_EXECUTIVE_DASHBOARDS_SUCCESS,
  payload,
});

export const getExecutiveDashboardsFail = error => ({
  type: GET_EXECUTIVE_DASHBOARDS_FAIL,
  payload: error,
});
