import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "./barchar.scss";
class OverviewChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Card className="mb-0">
        <CardBody>
          <CardTitle className="day-dark-text-bold">
            {this.props.title}
          </CardTitle>
          <ReactApexChart
            options={this.props.options}
            series={this.props.series}
            type="bar"
            height={this.props.largeScreen ? "862" : "574"}
            className="apex-charts1 apex-charts ltr"
          />
        </CardBody>
      </Card>
    );
  }
}

OverviewChart.propTypes = {
  options: PropTypes.object,
  series: PropTypes.array,
  title: PropTypes.string,
  largeScreen: PropTypes.bool,
};

export default OverviewChart;
