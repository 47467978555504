import React, { Suspense, useEffect } from "react";

import PropTypes from "prop-types";
import Loading from "pages/Loading";
const EnglishTheme = React.lazy(() => import("./EnglishTheme"));
const ArabicTheme = React.lazy(() => import("./ArabicTheme"));

const LanguageSelector = ({ layout }) => {
  useEffect(() => {
    layout.lang === "ar"
      ? document.body.setAttribute("dir", "rtl")
      : document.body.setAttribute("dir", "ltr");
  }, [layout.lang]);

  return (
    <div>
      <Suspense fallback={<Loading />}>
        {layout.lang === "ar" && <ArabicTheme />}
        {layout.lang === "en" && <EnglishTheme />}
      </Suspense>
    </div>
  );
};

LanguageSelector.propTypes = {
  layout: PropTypes.any,
};

export default LanguageSelector;
