//LOGIN
export const LOGIN_INTERNAL = "/Authentication/InternalLogin";
export const LOGIN_EXTERNAL = "/Authentication/ExternalLogin";
export const REGISTER = "/Authentication/Registration";
export const FORGOT_PASSWORD = "/Authentication/ForgotPasswordEmail";
export const RESET_PASSWORD = "/Authentication/ResetPassword";
export const CHANGE_PASSWORD = "/Authentication/ChangePassword";

//DASHBOARD
export const GET_HOME = "/Home";
export const GET_NOTIFICATIONS = "/Notifications/Get";
export const UPDATE_NOTIFICATIONS = "/Notifications/Update";
export const GET_LOOKUPS = "/Lookups";
export const GET_GLOBAL_SEARCH = "/Lookups/Search";
export const GET_PERMISSIONS = "/Authentication/Permissions";

//FILES
export const GET_FILES = "/Document/GetDocuments";
export const GET_RELATED_FILES = "/Document/GetRelatedDocuments";
export const GET_FILES_CATEGORIES = "/Document/GetDocumentCategories";
export const GET_FILE_DETAILS = "/Document/GetDocumentById";
export const UPLOAD_FILES = "/Document/UploadDocument";
export const DOWNLOAD_FILE = "/Document/DownloadDocument";
export const CREATE_FOLDER = "/Document/CreateFolder";
export const GET_DOCUMENT_REFERENCE = "/DocumentReference/GetDocumentReference";
export const CREATE_DOCUMENT_REFERENCE =
  "/DocumentReference/CreateDocumentReference";
export const GET_DOCUMENT_TASKS = "/Document/Categories/Tasks"
export const GET_DOCUMENT_PROJECTS = "/Document/Categories/Projects"
export const GET_DOCUMENT_REQUESTS = "/Document/Categories/Requests"
export const GET_DOCUMENT_APPROVALS = "/Document/Categories/Approvals"
export const GET_DOCUMENT_ORGANIZATIONS = "/Organization/GetOrganizations"
export const GET_DOCUMENT_CORRESPONDENCES = "/Document/Categories/Correspondences"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const GET_INTERNAL_DETAILS = "/Contact/GetContactDetails";
export const GET_EXTERNAL_DETAILS = "/Contact/GetContactDetails";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//REQUESTS
export const GET_REQUESTS = "/Request/GetAll";
export const GET_REQUEST_DETAILS = "/Request/GetDetails";
export const UPSERT_REQUEST = "/request/upsert";
export const GET_REQUESTS_DASHBOARD="/Request/Dashboard";
//APPROVALS
export const GET_APPROVALS = "/Approval/GetAll";
export const GET_APPROVAL_DETAILS = "/Approval/GetDetails";
export const UPSERT_APPROVAL = "/approval/upsert";
export const SUBMIT_APPROVAL_PATH = "/approval/UpdateApprovalTaskStatus";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";
export const GET_APPOINTMENTS = "/Appointment/GetAppointments";
export const GET_CHECK = "/Appointment/CheckAvailability";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const GET_PROJECTS_OVERVIEW = "/Project/ProjectOverview";
export const GET_ALL_PROJECTS = "/Project/GetAllProjects";
export const GET_ALL_ACTIVE_PROJECTS ="/Project/GetActiveProjects"
export const GET_PROJECT_DETAILS = "/Project/Getbyid";
export const UPSERT_PROJECT = "/Project/CreateUpdateProject";
export const UPSERT_STREAM = "/Stream/CreateAndUpdateStream";
export const UPSERT_MEMBER = "/Project/CreateAndUpdateTeamMember";
export const DELETE_PROJECT = "/Project/DeleteProject";
export const GET_PROJECTS_DASHBOARD="/Project/Dashboard"
export const GET_PROJECTS_OPTIONS="/Project/GetProjectsLoV"

//RECORDS
export const DELETE_RECORD = "/Record/DeleteRecord";

//TASKS
export const GET_TASKS = "/tasks";
export const GET_ALL_TASKS = "/Task/GetTasks";
export const GET_TASK_DETAILS = "/task/GetTaskById";
export const UPSERT_TASK = "/Task/CreateUpdateTask";
export const GET_TASKS_DASHBOARD='/Task/Dashboard'

//CONTACTS
export const GET_USERS = "/Contact/GetContactsDetails";
export const GET_USER_PROFILE = "/Contact/GetContactDetailsById";
export const ADD_NEW_USER = "/Contact/CreateContact";
export const UPDATE_USER = "/Contact/UpdateContact";
export const DELETE_USER = "/delete/user";
export const GET_ORGANIZATIONS = "/Organization/GetOrganizations";
export const GET_ORGANIZATION_DETAILS = "/Organization/GetOrganizationDetails";
export const UPSERT_ORGANIZATION = "/Organization/Upsert";

// Correspondence
export const GET_ALL_CORRESPONDENCE = "/Correspondence/GetAll";
export const GET_CORRESPONDENCE_DETAILS = "/Correspondence/GetById";
export const UPSERT_CORRESPONDENCE = "/correspondence/upsert";

// comments
export const GET_COMMENTS = "/comment/getcomments";
export const UPSERT_COMMENTS = "/Comment/CreateComment";

// Appointments
export const UPSERT_AGENDA = "/Appointment/CreateAndUpdateAgenda";
export const UPSERT_APPOINTMENTS = "/Appointment/CreateAndUpdateAppointment";
export const GET_APPOINTMENT_DETAILS = "/Appointment/GetAppointments";
export const SEND_EMAIL_TO_PARTICIPANTS =
  "/Appointment/SendReportToMeetingParticipants";

// DASHBOARDS
export const GET_ALLPROJECTSOVERVIEW = "/Project/AllProjectOverview";
export const GET_ALL_TASKS_OVERVIEW = "/Task/TaskOverview";
export const GET_CORRESPONDENCES_OVERVIEW =
  "/Correspondence/CorrespondenceOverview";
export const GET_ALL_APPROVALS_OVERVIEW = "/approval/ApprovalsOverview";
export const GET_INDIVIDUAL_LEVEL = "/Contact/IndividualOverview";
export const GET_EXECUTIVE_LEVEL = "/Project/ExecutiveOverview";
export const GET_DASHBOARD_ALLPROJECTS = "/Project/AllProjectsOverview"

//REFRESH TOKEN
export const GET_ACCESS_TOKEN = '/Authentication/RefreshAccessToken';
export const LOGOUT = '/Authentication/Logout';

export const GET_LOOKUP_COUNTS = "/Lookups/GetModulesCount";





