import sjcl from "sjcl";
const SALT = "day_portal_key_rzf*m;q?ZyH()y2t~Vn9A?3bk8*k8jsk";
import iconSuccess from "../assets/icons/toast-success.svg";
import iconError from "../assets/icons/toast-error.svg";
import iconWarning from "../assets/icons/toast-warning.svg";
// import { randomColor } from "common/utils";

export const BarColors = {
  green: "#07DF9A",
  yellow: "#F4BD33",
  red: "#D93954",
  blue: "#657DFF",
};
export const Colors = {
  red: "#FF3B30",
  red2: "#D93954",
  white: "#ffffff",
  lightGrey: "#d3d3d3",
  searchBG: "#F5F7F9",
  blue: "#389741",
  staBlue: "#657DFF",
  purple: "#793FF9",
  lightPurple: "#A760FF",
  lighBlue: "#4577C9",
  veryLightBlue: "rgba(101, 125, 255, 0.2)",
  yellow: "#F4BD33",
  grey: "rgba(76, 77, 76, 0.75)",
  homeIconsColor: "rgba(76, 77, 76, 0.5)",
  // statuses
  darkYellow: "#9C7513",
  yellowBG: "rgba(255, 182, 0, 0.4)",
  darkText: "#4C4D4C",
  darkTextBG: "rgba(76, 77, 76, 0.15)",
  redText: "#8C0006",
  redTextBG: "rgba(255, 59, 48, 0.4)",
  greenText: "#00855A",
  greenTextBG: "rgba(7, 223, 154, 0.4)",
  blueText: "#1329A0",
  blueTextBG: "rgba(101, 125, 255, 0.4)",
  greyText: "#4C4D4CBF",
  greyTextBG: "rgba(76, 77, 76, 0.1)",
  pink: "#451BC8",
  pinkBG: "#9747FF8A",
  // end statuses
  orange: "#EB8C00",
  green: "#07DF9A",
  darkGreen: "#899878",
  black: "black",
  lightBlue: "#00A37B",
  lightBlueBG: "#3DC2C240",
  staGreen: "#389741",
  staDark: "rgba(40, 40, 40, 1)",
  staDarkBg: "rgba(60, 60, 60, 1)",
  knobColor: "#228C2C",
  knobBgColor: "#4C4D4C80",
  lightPlum: "#c9b0c9",
  bluelightColor: "#1329a0",
  bluelightBackground: "#bed7ff"

};

export const brandDarkColors = [
  "#389741",
  "#3E632D",
  "#FF5600",
  "#BE0000",
  "#78006E",
  "#009AAC",
];

export const paths = [
  "/home",
  "/projects-overview",
  "/tasks",
  "/requests",
  "/approvals",
  "/calendar",
  "/correspondence",
  "/document-management",
  "/people",
  "/individual-level",
  "/project-level",
  "/executive-level",
];

export const calendarColors = [
  { fontColor: "#5328DC", backgroundColor: "#7D57F6" },
  { fontColor: "#348599", backgroundColor: "#57C0DA" },
  { fontColor: "#981329", backgroundColor: "#D93954" },
  { fontColor: "#1E8060", backgroundColor: "#69DCAF" },
  { fontColor: "#AE7C00", backgroundColor: "#F4BD33" },
];

export const customLocalStorage = {
  setItem: (key, object) => {
    let stringified = JSON.stringify(object);
    const data = sjcl.encrypt(SALT, stringified);
    localStorage.setItem(key, data);
  },
  getItem: key => {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryped = sjcl.decrypt(SALT, encryptedData);
      return decryped;
    }
    return false;
  },
};

export const USER_TYPES = {
  EXTERNAL_USER: "EXTERNAL",
  INTERNAL_USER: "INTERNAL",
};

export const NODE_ENV = "development";

export const arabicRegex = /^[\u0621-\u064A\s\p{N}]+$/;

export const DEFAULT_ACCEPTED_FILES =
  ".pdf, .png, .jpeg, .jpg, .xlsx, .xls, .csv, .doc, .docx, .pptx";

export const DEFAULT_ACCEPTED_IMAEGES = ".png, .jpeg, .jpg";

export const SIGNING_ACCEPTED_FILES = ".pdf";

export const MAX_COMMENT_LENGTH = 500;
export const MIN_COMMENT_LENGTH = 6;
export const I18N_LANGUAGE = "I18N_LANGUAGE";

export const getGenericError = () => {
  if (CURRENT_LANG === "en") {
    return "Something went wrong!";
  } else {
    return "هناك خطأ ما!";
  }
};

export const handleArabicDate = date => {
  let formatedDate;
  CURRENT_LANG === "ar"
    ? (formatedDate = date.replace(/[٠-٩۰-۹]/g, a => a.charCodeAt(0) & 15))
    : (formatedDate = date);

  return formatedDate;
};

export const ENTITIES = {
  ORGANIZATION: "account",
  INDIVIDUALS: "contact",
  PROJECT: "day_project",
  TASK: "task",
  REQUEST: "day_request",
  APPROVAL: "day_approval",
  APPOINTMENT: "appointment",
  CORRESPONDENCE: "day_correspondence",
  STREAM: "day_stream",
  DOCUMENT: "day_documents",
  COMMENT: "day_comment",
  MEMBER: "day_teammember",
  DASHBOARDS: "dashboard",
};

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const DEFAULT_OPTION = {
  value: EMPTY_GUID,
  label: "All",
  labelAr: "الكل",
};

export const isInternal =
  JSON.parse(customLocalStorage.getItem("userType")) ===
  USER_TYPES.INTERNAL_USER;

export const idContact =
  JSON.parse(customLocalStorage.getItem("authUser")).contactId ?? EMPTY_GUID;

export const idRole =
  JSON.parse(customLocalStorage.getItem("authUser")).roleId ?? EMPTY_GUID;

export const PERMISSIONS = {
  NONE: "NONE",
  ALL: "ALL",
  OWN: "OWN",
};

export const getGlobalHeaderStyle = (width = "10%", extraStyle = {}) => ({
  color: "rgba(0,0,0,0.4)",
  backgroundColor: Colors.searchBG,
  width: width,
  fontWeight: "normal",
  whiteSpace: "noWrap",
  textTransform: "uppercase",
  textAlign: "start",
  ...(!!extraStyle &&
    typeof extraStyle === "object" &&
    !Array.isArray(extraStyle) &&
    extraStyle),
});

/**
 * It takes a start date and an end date and returns an array of dates between the two dates
 * @param start - The start date of the range.
 * @param end - The end date of the range.
 * @returns An array of dates between the start and end dates.
 */
export const getDaysArray = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const UPLOAD_TYPES = {
  FROM_COMPUTER: 1,
  FROM_PORTAL: 2,
};

export const ACTION_TYPES = {
  PENDING: 100000000,
  APPROVE: 100000001,
  REJECT: 100000002,
  RETURN_WITH_COMMENTS: 100000003,
};

// check this with Adeel
export const ACTION_TYPES_PATH = {
  PENDING: 100000003,
  APPROVE: 100000000,
  REJECT: 100000001,
  RETURN_WITH_COMMENTS: 100000002,
};

export const CURRENT_LANG = localStorage.getItem("I18N_LANGUAGE") ?? "en";

export const AUTH_TOKEN = JSON.parse(customLocalStorage.getItem("token")) ?? "";
export const REFRESH_TOKEN =
  JSON.parse(customLocalStorage.getItem("refreshToken")) ?? "";

const iconByErrorType = {
  success: iconSuccess,
  error: iconError,
  warning: iconWarning,
};

export const TOAST_OPTIONS = {
  position: "top-center",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  icon: ({ type }) => <img src={`${iconByErrorType[type]}`} />,
};

export const GUID_REGEX =
  /(^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$)/;

export const E_SIGNATURE_TYPE = 100000001;

export const COLOR_COMBOS = {
  yellow: {
    text: Colors.darkYellow,
    background: Colors.yellowBG,
  },
  dark: {
    text: Colors.darkText,
    background: Colors.darkTextBG,
  },
  grey: {
    text: Colors.greyText,
    background: Colors.greyTextBG,
  },
  green: {
    text: Colors.greenText,
    background: Colors.greenTextBG,
  },
  red: {
    text: Colors.redText,
    background: Colors.redTextBG,
  },
  blue: {
    text: Colors.blueText,
    background: Colors.blueTextBG,
  },
  lightBlue: {
    text: Colors.lightBlue,
    background: Colors.lightBlueBG,
  },
  pink: {
    text: Colors.pink,
    background: Colors.pinkBG,
  },
  lightBlueTag: {
    text: Colors.bluelightColor,
    background: Colors.bluelightBackground,
  },
};

export const NEW_INITIATIVE = "New Initiative";
// TODO translate
export const INTERNAL_LABEL = CURRENT_LANG === "en" ? "Internal" : "Internal";
export const EXTERNAL_LABEL = CURRENT_LANG === "en" ? "External" : "External";

export const LOGO_DIMENSIONS = {
  HEIGHT: 130,
  WIDTH: 130,
};

export const SELECT_STYLES = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menuList: base => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "0.375rem",
      scrollBarWidth: "thin",
      scrollBarColor: "#bbbbbb #ffffff0f",
    },
    "::-webkit-scrollbar-track": {
      background: "#ffffff0f",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#bbbbbb",
      borderRadius: "999px",
    },
  }),
  groupHeading: base => ({
    ...base,
    fontSize: 16,
    color: "#389741",
    fontWeight: "900",
    backgroundColor: "#ebf5ec",
    // paddingTop: 5,
    // paddingBottom: 5,
  }),
};
