import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import CustomSelect from "components/Common/CustomSelect";
import { EMPTY_GUID, handleArabicDate, isInternal } from "common";
import RadioStepper from "components/Common/RadioStepper";
import MemberItem from "components/Common/MemberItem";
import {
  formatLineBreaksInMarkdownFriendlyFormat,
  getLookupValue,
} from "common/utils";

let validations = [{}, {}];
class StreamModal extends Component {
  constructor(props) {
    super(props);
    validations = [
      {
        nameEn: Yup.string().required(props.t("errorNameEn")),
        owner: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(props.t("errorStreamLead")),
        startDate: Yup.date().required(props.t("errorStartDate")),
        endDate: Yup.date()
          .when(
            "startDate",
            (startDate, schema) =>
              startDate && schema.min(startDate, props.t("errorBeforeEndDate"))
          )
          .required(props.t("errorEstimatedEnd")),
        department: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(props.t("errorSelectDepartment")),
        progress: Yup.number()
          .min(0, "Minimum value is 0")
          .max(100, " Maximum value is 100")
          .required("Please Enter Project Progress"),
      },
      {
        // tasks: Yup.array().min(1, props.t("tasksMinOne")),
      },
    ];
    this.state = {
      activeTab: !!props.values ? 2 : 1,
      refresh: false,
    };
    this.isValid = this.isValid.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleValidations = this.handleValidations.bind(this);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    for (let key in validations[this.state.activeTab - 1]) {
      if (key === "tasks" || key === "progress") {
        if (
          this.props.isEdit &&
          key === "progress" &&
          (values[key] < 0 || values[key] > 100)
        ) {
          return false;
        }
      } else if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (prevProps.isEdit !== this.props.isEdit) {
        this.handleValidations();
      }
    }
  }
  componentDidMount() {
    this.handleValidations();
  }

  handleValidations() {
    if (this.props.isEdit) {
      validations = [
        {
          ...validations[0],
          status: Yup.object()
            .shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
            .required(this.props.t("errorStatus")),
        },
        { ...validations[1] },
      ];
    } else {
      if ("status" in validations[0]) {
        delete validations[0].status;
      }
    }
    this.setState({ refresh: !this.state.refresh });
  }

  render() {
    const { stream, isEdit, entityLookups, tasksOptions } = this.props;
    const constructDepartmentData = () =>
      !entityLookups.listDepartment?.find(
        item => item.value === stream?.department?.id
      )
        ? entityLookups.listDepartment.concat({
            label: stream?.department?.label || null,
            labelAr: stream?.department?.labelAr || null,
            value: stream?.department?.id || EMPTY_GUID,
          })
        : entityLookups.listDepartment;
    if (!entityLookups) {
      return <></>;
    }
    return (
      <Modal
        onClosed={() => {
          this.setState({ activeTab: 1 });
        }}
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("Update Stream") + ` (${this.state.activeTab}/2)`
            : this.props.t("Create New Stream") +
              ` (${this.state.activeTab}/2)`}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={
              !!this.props.values
                ? {
                    ...this.props.values,
                  }
                : {
                    nameEn: (stream && stream?.name) || "",
                    nameAr: (stream && stream?.nameAr) || "",
                    owner:
                      (stream &&
                        getLookupValue(
                          stream.streamOwner.id,
                          isInternal
                            ? entityLookups.listIndividualsInternal
                            : entityLookups.listIndividualsExternal
                        )) ||
                      "",
                    startDate:
                      (stream &&
                        stream?.startDate &&
                        handleArabicDate(stream?.startDate)) ||
                      "",
                    endDate:
                      (stream &&
                        stream?.endDate &&
                        handleArabicDate(stream?.endDate)) ||
                      "",
                    description:
                      (stream &&
                        formatLineBreaksInMarkdownFriendlyFormat(
                          stream.description,
                          true
                        )) ||
                      "",
                    department:
                      (stream &&
                        getLookupValue(
                          stream?.department?.id,
                          constructDepartmentData()
                        )) ||
                      "",
                    status:
                      (stream &&
                        getLookupValue(
                          stream.status.value,
                          entityLookups.listStreamStatus
                        )) ||
                      "",
                    tasks:
                      (stream &&
                        getLookupValue(
                          stream.lstRelatedTasks,
                          tasksOptions,
                          true
                        )) ||
                      [],
                    progress: stream && stream.progress ? stream.progress : 0,
                  }
            }
            validationSchema={Yup.object().shape(
              validations[this.state.activeTab - 1]
            )}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col className="col-12">
                                <Row>
                                  <Col lg={isEdit ? "6" : "12"}>
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("Stream Lead")}
                                      </Label>

                                      <CustomSelect
                                        className="input"
                                        onChange={value => {
                                          setFieldValue("owner", value);
                                        }}
                                        value={values.owner}
                                        options={
                                          isInternal
                                            ? entityLookups.listIndividualsInternal
                                            : entityLookups.listIndividualsExternal
                                        }
                                      />
                                      <ErrorMessage
                                        name="owner"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>

                                  {isEdit && (
                                    <Col lg={"6"}>
                                      <div className="mb-3">
                                        <Label className="form-label day-required">
                                          {this.props
                                            .t("Progress")
                                            .toUpperCase()}
                                        </Label>
                                        <Field
                                          name="progress"
                                          type="number"
                                          className={
                                            "form-control" +
                                            (errors.progress && touched.progress
                                              ? " is-invalid"
                                              : "")
                                          }
                                          as="input"
                                          min={0}
                                          max={100}
                                        />

                                        <ErrorMessage
                                          name="progress"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("nameEn")}
                                      </Label>
                                      <Field
                                        name="nameEn"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.nameEn && touched.nameEn
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="nameEn"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {this.props.t("nameAr")}
                                      </Label>
                                      <Field
                                        name="nameAr"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.nameAr && touched.nameAr
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="input"
                                        dir="rtl"
                                      />
                                      <ErrorMessage
                                        name="nameAr"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("Start Date Form")}
                                      </Label>
                                      <Field
                                        name="startDate"
                                        type="date"
                                        className={
                                          "form-control" +
                                          (errors.startDate && touched.startDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="input"
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("End Date Form")}
                                      </Label>
                                      <Field
                                        name="endDate"
                                        type="date"
                                        className={
                                          "form-control" +
                                          (errors.endDate && touched.endDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="input"
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={isEdit ? "6" : "12"}>
                                    <div className="mb-3">
                                      <Label className="form-label day-required">
                                        {this.props.t("DepartmentForm")}
                                      </Label>
                                      <CustomSelect
                                        className="input"
                                        onChange={value => {
                                          setFieldValue("department", value);
                                        }}
                                        value={values.department}
                                        options={constructDepartmentData()}
                                      />
                                      <ErrorMessage
                                        name="department"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                  {isEdit && (
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label className="form-label day-required">
                                          {this.props.t("StatusFormStream")}
                                        </Label>
                                        <CustomSelect
                                          className="input"
                                          onChange={value => {
                                            setFieldValue("status", value);
                                          }}
                                          value={values.status}
                                          options={
                                            entityLookups.listStreamStatus
                                          }
                                        />
                                        <ErrorMessage
                                          name="status"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {this.props.t("DescriptionForm")}
                                      </Label>
                                      <Field
                                        name="description"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.description &&
                                          touched.description
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {this.props.t("TasksForm")}
                                    </Label>
                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("tasks", value);
                                      }}
                                      value={values.tasks}
                                      options={tasksOptions}
                                      isMulti={true}
                                      controlShouldRenderValue={false}
                                    />
                                    <ErrorMessage
                                      name="address"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <a
                                className="mt-1 mb-1 text-decoration-underline"
                                onClick={() => {
                                  this.props.onCreateNewTask(values);
                                }}
                              >
                                {this.props.t("Create New Task")}
                              </a>
                              <Row>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {values.tasks.map((item, index) => {
                                    return (
                                      <MemberItem
                                        key={index}
                                        item={item}
                                        onClick={() => {
                                          let _tasks = [...values.tasks];
                                          _tasks = _tasks.filter(
                                            _task => _task.value !== item.value
                                          );
                                          setFieldValue("tasks", _tasks);
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>

                    <RadioStepper
                      steps={[1, 2]}
                      activeTab={this.state.activeTab}
                    />
                    <div className="actions clearfix" style={{ marginTop: 40 }}>
                      <ul className="day-text-end">
                        {this.state.activeTab !== 1 && (
                          <button
                            type="button"
                            className="btn customTable-button"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                            style={{ marginInlineEnd: 10 }}
                          >
                            {this.props.t("Previous")}
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn customTable-button"
                          disabled={!this.isValid(values, errors)}
                          onClick={e => {
                            e.preventDefault();
                            if (this.state.activeTab === 2) {
                              this.props.handleSubmit(values);
                              this.toggleTab(1);
                            } else {
                              this.toggleTab(this.state.activeTab + 1);
                            }
                          }}
                        >
                          {this.state.activeTab === 2
                            ? isEdit
                              ? this.props.t("Update")
                              : this.props.t("Create").toUpperCase()
                            : this.props.t("Next")}
                        </button>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

StreamModal.propTypes = {
  stream: PropTypes.any,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  tasksOptions: PropTypes.array,
  onCreateNewTask: PropTypes.func,
  values: PropTypes.any,
  entityLookups: PropTypes.any,
};

export default withTranslation()(StreamModal);
