import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabPane,
  TabContent,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormUpload from "pages/Forms/FormUpload";
import RadioStepper from "components/Common/RadioStepper";
import CustomSelect from "components/Common/CustomSelect";
import { DEFAULT_ACCEPTED_IMAEGES } from "common";
import { getLookupValue, getMergedIndividualsLookup } from "common/utils";

class OrganizationModal extends Component {
  constructor(props) {
    super(props);
    const _validations = [
      {
        nameEn: Yup.string().required(this.props.t("errorNameEn")),
        sector: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorSector")),
        organizationType: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorOrganizationType")),
      },
      {
        address: Yup.string().required(this.props.t("errorAddress")),
        email: Yup.string()
          .required(this.props.t("errorEmail"))
          .email(this.props.t("validEmail")),
        phoneNumber: Yup.string().required(this.props.t("errorPhoneNum")),
      },
    ];

    const _organizationSchemaValidations = [
      Yup.object().shape(_validations[0]),
      Yup.object().shape(_validations[1]),
    ];
    this.state = {
      activeTab: 1,
      validations: _validations,
      organizationSchemaValidations: _organizationSchemaValidations,
      sector: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleSectorSet = this.handleSectorSet.bind(this);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  isValid(values, errors) {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    for (let key in this.state.validations[this.state.activeTab - 1]) {
      if (!values[key]) {
        return false;
      }
    }
    return true;
  }

  handleSectorSet() {
    if (
      !!!this.state.sector &&
      this.props.isEdit &&
      !!this.props.org &&
      !!this.props.entityLookups
    ) {
      this.setState({
        sector: this.props.org
          ? getLookupValue(
              this.props.org.sector?.value,
              this.props.entityLookups.listOrganizationSector
            )
          : null,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.handleSectorSet();
    }
  }
  componentDidMount() {
    this.handleSectorSet();
  }
  render() {
    const { org, isEdit, file, onFilesAccepted, entityLookups } = this.props;
    if (!entityLookups) {
      return <></>;
    }

    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {(isEdit
            ? this.props.t("Edit Organization")
            : this.props.t("Add Organization")) +
            ` (${this.state.activeTab}/2)`}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              taxNumber: org ? org.taxNumber : "",
              nameEn: org ? org.nameEn : "",
              nameAr: org ? org.nameAr : "",
              sector: org
                ? getLookupValue(
                    org.sector?.value,
                    entityLookups.listOrganizationSector
                  )
                : "",
              organizationType: org
                ? getLookupValue(
                    org.organizationType?.value,
                    entityLookups.listOrganizationType
                  )
                : "",
              primaryContact:
                (org?.primaryContact?.value &&
                  getLookupValue(
                    org.primaryContact.value,
                    getMergedIndividualsLookup(
                      entityLookups.listIndividualsInternal,
                      entityLookups.listIndividualsExternal
                    )
                  )) ||
                "",
              address: org ? org.address : "",
              email: org ? org.email : "",
              phoneNumber: org ? org.telephone : "",
            }}
            validationSchema={
              this.state.organizationSchemaValidations[this.state.activeTab - 1]
            }
          >
            {({
              errors,
              status,
              touched,
              values,
              handleChange,
              setTouched,
              setFieldValue,
            }) => (
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("nameEn")}
                                  </Label>
                                  <Field
                                    name="nameEn"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.nameEn && touched.nameEn
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="nameEn"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {this.props.t("nameAr")}
                                  </Label>
                                  <Field
                                    name="nameAr"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.nameAr && touched.nameAr
                                        ? " is-invalid"
                                        : "")
                                    }
                                    as="input"
                                    dir="rtl"
                                  />
                                  <ErrorMessage
                                    name="nameAr"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("Sector")}
                                  </Label>

                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      this.setState({ sector: value });
                                      setFieldValue("sector", value);
                                    }}
                                    value={values.sector}
                                    options={
                                      entityLookups.listOrganizationSector
                                    }
                                  />
                                  <ErrorMessage
                                    name="sector"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {!!this.state.sector &&
                                    this.state.sector?.label
                                      ?.toLowerCase()
                                      ?.includes("public")
                                      ? this.props.t("Royal Decree")
                                      : this.props.t("Tax Reference Number")}
                                  </Label>
                                  <Field
                                    name="taxNumber"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.taxNumber && touched.taxNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="taxNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label className="form-label day-required">
                                    {this.props.t("External")}
                                  </Label>

                                  <CustomSelect
                                    className="input"
                                    onChange={value => {
                                      setFieldValue("organizationType", value);
                                    }}
                                    value={values.organizationType}
                                    options={entityLookups.listOrganizationType}
                                  />
                                  <ErrorMessage
                                    name="organizationType"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              {isEdit && (
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("Primary Contact")}
                                    </Label>

                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("primaryContact", value);
                                      }}
                                      value={values.primaryContact}
                                      options={getMergedIndividualsLookup(
                                        entityLookups.listIndividualsInternal,
                                        entityLookups.listIndividualsExternal
                                      )}
                                    />
                                    <ErrorMessage
                                      name="primaryContact"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("Primary Address")}
                                    </Label>
                                    <Field
                                      name="address"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.address && touched.address
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="address"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("contactEmail")}
                                    </Label>
                                    <Field
                                      name="email"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.email && touched.email
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label className="form-label day-required">
                                      {this.props.t("contactPhone")}
                                    </Label>
                                    <Field
                                      name="phoneNumber"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.phoneNumber &&
                                        touched.phoneNumber
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="phoneNumber"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <Label className="form-label">
                                    {this.props
                                      .t("Optional")
                                      .replace(
                                        "*",
                                        this.props.t("Profile Picture")
                                      )}
                                  </Label>
                                  <FormUpload
                                    file={file}
                                    onFilesAccepted={onFilesAccepted}
                                    accept={DEFAULT_ACCEPTED_IMAEGES}
                                    validateDimensions
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <RadioStepper
                      steps={[1, 2]}
                      activeTab={this.state.activeTab}
                    />
                    <div className="actions clearfix" style={{ marginTop: 40 }}>
                      <ul className="day-text-end">
                        {this.state.activeTab !== 1 && (
                          <button
                            type="button"
                            className="btn customTable-button"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                            style={{ marginInlineEnd: 10 }}
                          >
                            {this.props.t("Previous")}
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn customTable-button"
                          disabled={!this.isValid(values, errors)}
                          onClick={e => {
                            e.preventDefault();
                            if (this.state.activeTab === 2) {
                              this.props.handleSubmit(values);
                              this.toggleTab(1);
                            } else {
                              this.toggleTab(this.state.activeTab + 1);
                            }
                          }}
                        >
                          {this.state.activeTab === 2
                            ? isEdit
                              ? this.props.t("Update")
                              : this.props.t("create").toUpperCase()
                            : this.props.t("Next")}
                        </button>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

OrganizationModal.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any,
  handleSubmit: PropTypes.func,
  org: PropTypes.any,
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  toggle: PropTypes.func,
  file: PropTypes.any,
  onFilesAccepted: PropTypes.func,
  entityLookups: PropTypes.any,
};

export default withTranslation()(withRouter(OrganizationModal));
