import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";

class TopProjectStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.series,
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "18rem",
            endingShape: "rounded",
            borderRadius: 7,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          squareSymbol: true,
          markers: {
            radius: 12,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        // colors: ["#F4BD33"],
        colors: [
          function ({ value, seriesIndex, w }) {
            if (value < 40) {
              return "#F4BD33";
            } else {
              return "#07DF9A";
            }
          },
        ],

        xaxis: {
          categories: props.categories,
          offsetX: 0,
        },
        yaxis: {
          max: 100,
          labels: {
            formatter: value => value.toFixed(0) + "%",
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card style={{ marginTop: -20, height: 442 }}>
          <CardBody>
            <p className="day-top-projects">
              {this.props.t("Projects Progress")}
            </p>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={350}
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

TopProjectStatus.propTypes = {
  data: PropTypes.array,
  series: PropTypes.any,
  categories: PropTypes.any,
  horizontal: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(TopProjectStatus);
