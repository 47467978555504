import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, Label } from "reactstrap";
import { withTranslation } from "react-i18next";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "toastr/build/toastr.min.css";

// Redux
import { connect } from "react-redux";

import { Colors, CURRENT_LANG, GUID_REGEX, TOAST_OPTIONS } from "common";
import { getQueryVariable } from "common/utils";
import Loading from "pages/Loading";
import i18n from "i18n";
import {
  forgotPassword,
  register,
  resetPassword,
  setLanguage,
} from "store/actions";
// import logo from "../../assets/images/logo-top-bar.svg";
import { toast } from "react-toastify";

import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      loading: true,
      loadingBtn: false,
      isFromLink: false,
      isSubmittedEmail: false,
      payload: null,
    };
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.PasswordComponent = this.PasswordComponent.bind(this);
    this.handleSendLink = this.handleSendLink.bind(this);

    this.regexChar = /^(?=.*[!@#$%^&*()_\+\-\=]).+$/;
    this.regexUpper = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
    this.regexNumbers = /^(?=.*[1-9]).+$/;
  }

  componentDidMount() {
    const id = getQueryVariable("id");
    if (id && id!== "" && id?.length >= 15) {
      this.setState({ isFromLink: true });
    }
    this.setState({ loading: false });
  }

  handleThemeChange() {}

  handleLanguageChange() {
    let lang = CURRENT_LANG === "en" ? "ar" : "en";
    this.setState({
      language: lang,
    });
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    this.props.setLanguage(lang);
  }

  PasswordComponent = (stateKey, field, disabled = false) => {
    return (
      <div style={{ position: "relative" }}>
        <input
          name="password"
          placeholder={this.props.t("Enter Password")}
          type={
            this.state[stateKey]
              ? this.props.t("text")
              : this.props.t("Password")
          }
          className={"form-control"}
          disabled={disabled}
          {...field}
        />
        <button
          type="button"
          className="btn btn-primary"
          style={{
            backgroundColor: "transparent",
            border: "0px",
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 10,
          }}
          onClick={() => {
            this.setState({
              [stateKey]: !this.state[stateKey],
            });
          }}
          disabled={disabled}
        >
          <i
            className={`far ${
              this.state[stateKey] ? "fa-eye" : "fa-eye-slash"
            } d-block font-size-18`}
            style={{ color: Colors.grey }}
          ></i>
        </button>
      </div>
    );
  };

  async handleSendLink(payload) {
    if (!payload) {
      return;
    }
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.log("Recaptcha has not been loaded");

      return;
    }

    const token = await executeRecaptcha("forgotpassword");
    if (token) {
      const payloadUpdate = {
        email: payload,
        token,
      };
      this.props.onForgotPassword(payloadUpdate);
    } else {
      console.log("error no token");
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        {/* <div style={{ position: "absolute", top: 10, right: 20 }}>
          <div className="day-row mt-2">
            <a className="mb-0" onClick={this.handleLanguageChange}>
              <p className="mb-0 mx-3 font-size-16 day-light-grey">
                {CURRENT_LANG === "en"
                  ? this.props.t("Ar")
                  : this.props.t("En")}
              </p>
            </a>
            <a className="mb-0" onClick={this.handleThemeChange}>
              <i className="bx bx-message-minus h5 mb-0 mt-1 day-light-grey" />
            </a>
          </div>
        </div> */}

        {/* <div style={{ position: "absolute", top: 10, left: 20 }}>
          <div className="day-row mt-2">
            <img
              src={logo}
              alt=""
              className="auth-logo-dark"
              style={{ width: 100, height: 40 }}
            />
          </div>
        </div> */}
        <div className="account-pages my-5 pt-sm-5 mt-5">
          <Container>
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={5} className="mt-5">
                <div className="mt-5">
                  {this.state.isFromLink ? (
                    <Row className="day-row day-justify-center day-align-center mb-4">
                      <p className="day-dark-text h2">
                        {this.props.t("Forgot Password Title 2")}
                      </p>
                      <p className="day-dark-text">
                        {this.props.t("Forgot Password Descripton 2")}
                      </p>
                    </Row>
                  ) : !this.state.isSubmittedEmail ? (
                    <Row className="justify-content-center day-align-center mb-4">
                      <p className="day-dark-text h2">
                        {this.props.t("Forgot")}
                      </p>
                      <p className="day-dark-text">
                        {this.props.t("Forgot Password Descripton 1")}
                      </p>
                    </Row>
                  ) : (
                    <Row className="day-row day-justify-center day-align-center mb-4">
                      <p className="day-dark-text h2">
                        {this.props.t("Check Email Title")}
                      </p>
                      <p className="day-dark-text mb-4 text-nowrap">
                        {this.props.t("Check Email Description 1")}
                        <br />
                        {this.props.t("Check Email Description 3")}
                      </p>
                      <div
                        className="day-row day-align-center day-justify-center"
                        style={{ cursor: "pointer", columnGap: "5px" }}
                      >
                        <p className="day-light-grey mb-0">
                          {this.props.t("Check Email Description 2")}
                        </p>
                        {this.props.loading ? (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle mx-2"></i>
                        ) : (
                          <p
                            className="day-light-blue mb-0"
                            onClick={() =>
                              this.handleSendLink(this.state.payload)
                            }
                          >
                            {this.props.t("Resend")}
                          </p>
                        )}
                      </div>
                    </Row>
                  )}

                  {this.state.isFromLink ? (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={Yup.object().shape({
                        password: Yup.string().required(
                          "Please Enter Your Password"
                        ),
                        confirmPassword: Yup.string()
                          .required("Please Enter Your Password Confirmation")
                          .when("password", {
                            is: val => (val && val.length > 0 ? true : false),
                            then: Yup.string().oneOf(
                              [Yup.ref("password")],
                              "Both password need to be the same"
                            ),
                          }),
                      })}
                      onSubmit={values => {
                        this.setState({ loadingBtn: true }, () => {
                          const id = getQueryVariable("id");
                          let payload = {
                            newPassword: values.password,
                            idContact: id,
                          };
                          console.log("reset password*****")
                          this.props.onResetPassword(payload, () => {
                            toast.success(
                              this.props
                                .t("SuccessUpdate")
                                .replace("*", this.props.t("Password")),
                              TOAST_OPTIONS
                            );
                            this.props.history.replace("/login-external");
                          });
                          setTimeout(() => {
                            this.setState({ loadingBtn: false });
                          }, 1000);
                        });
                      }}
                    >
                      {({ errors, status, touched, values }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="password" className="form-label">
                              {this.props.t("Password")}
                            </Label>

                            <Field name="password">
                              {({ field, form: { touched, errors } }) =>
                                this.PasswordComponent(
                                  "showPassword",
                                  field,
                                  false
                                )
                              }
                            </Field>
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="day-column">
                            <div className="day-row mb-2">
                              <i
                                className={
                                  values.password.length < 8
                                    ? "bx bx-bx bx-shield font-size-17"
                                    : "bx bx-bx bxs-check-shield font-size-17"
                                }
                                style={{
                                  color:
                                    values.password.length < 8
                                      ? "grey"
                                      : "green",
                                }}
                              ></i>
                              <p className="mb-0 mx-2">
                                {this.props.t("At least 8")}
                              </p>
                            </div>
                            <div className="day-row mb-2">
                              <i
                                className={
                                  !this.regexChar.test(values.password)
                                    ? "bx bx-bx bx-shield font-size-17"
                                    : "bx bx-bx bxs-check-shield font-size-17"
                                }
                                style={{
                                  color: !this.regexChar.test(values.password)
                                    ? "grey"
                                    : "green",
                                }}
                              ></i>
                              <p className="mb-0 mx-2">
                                {this.props.t("Must Contain Special")}
                              </p>
                            </div>
                            <div className="day-row mb-2">
                              <i
                                className={
                                  !this.regexUpper.test(values.password)
                                    ? "bx bx-bx bx-shield font-size-17"
                                    : "bx bx-bx bxs-check-shield font-size-17"
                                }
                                style={{
                                  color: !this.regexUpper.test(values.password)
                                    ? "grey"
                                    : "green",
                                }}
                              ></i>
                              <p className="mb-0 mx-2">
                                {this.props.t("Must Contain Upper")}
                              </p>
                            </div>
                            <div className="day-row mb-2">
                              <i
                                className={
                                  !this.regexNumbers.test(values.password)
                                    ? "bx bx-bx bx-shield font-size-17"
                                    : "bx bx-bx bxs-check-shield font-size-17"
                                }
                                style={{
                                  color: !this.regexNumbers.test(
                                    values.password
                                  )
                                    ? "grey"
                                    : "green",
                                }}
                              ></i>
                              <p className="mb-0 mx-2">
                                {this.props.t("Must Contain Letters")}
                              </p>
                            </div>
                          </div>

                          <div className="mb-3 mt-2">
                            <Label for="confirmPassword" className="form-label">
                              {this.props.t("Confirm Password")}
                            </Label>

                            <Field name="confirmPassword">
                              {({ field, form: { touched, errors } }) =>
                                this.PasswordComponent(
                                  "showConfirmPassword",
                                  field,
                                  false
                                )
                              }
                            </Field>
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={
                                this.props.loading ||
                                !!!values.password ||
                                !!!values.confirmPassword ||
                                values.password.length < 8 ||
                                !this.regexChar.test(values.password) ||
                                !this.regexUpper.test(values.password) ||
                                !this.regexNumbers.test(values.password) ||
                                values.password !== values.confirmPassword
                              }
                            >
                              {(this.state.loadingBtn ||
                                this.props.loading) && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2">
                                  {" "}
                                </i>
                              )}
                              {this.props.t("Set Password")}
                            </button>
                          </div>
                          <div className="mt-3 d-grid">
                            <p className="text-muted text-center">
                              {this.props.t("Trouble")}
                            </p>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    !this.state.isSubmittedEmail && (
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email(this.props.t("validEmail"))
                            .required(this.props.t("validEmail")),
                        })}
                        onSubmit={values => {
                          this.setState({ loadingBtn: true }, () => {
                            this.handleSendLink(values.email);
                            this.setState({
                              loadingBtn: false,
                              isSubmittedEmail: true,
                              payload: values.email,
                            });
                          });
                        }}
                      >
                        {({ errors, status, touched, values }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                {this.props.t("Email")}
                              </Label>

                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder={this.props.t("Enter Email")}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={
                                  this.props.loading ||
                                  Object.keys(errors).length > 0 ||
                                  !!!values.email
                                }
                              >
                                {(this.state.loadingBtn ||
                                  this.props.loading) && (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2">
                                    {" "}
                                  </i>
                                )}
                                {this.props.t("Send Link")}
                              </button>
                            </div>
                            <div className="mt-3 d-grid">
                              <p className="text-muted text-center">
                                {this.props.t("Trouble")}
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )
                  )}
                </div>
                <div className="text-center fixed-bottom mb-2">
                  <Row
                    className="mt-4 day-light-grey font-size-11"
                    style={{ fontWeight: 500 }}
                  >
                    <div className="w-full px-3 mb-2 google-terms">
                      {this.props.t("recaptcha1")}
                      &nbsp;
                      <a
                        href="https://policies.google.com/privacy"
                        className="anchor-tag"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {this.props.t("recaptcha2")}
                      </a>
                      &nbsp;
                      <a
                        href="https://policies.google.com/terms"
                        className="anchor-tag"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {this.props.t("recaptcha3")}
                      </a>
                      &nbsp;
                      {this.props.t("recaptcha4")}
                    </div>
                  </Row>
                  <Col
                    xs={12}
                    className="mt-4 day-light-grey font-size-11"
                    style={{ fontWeight: 500 }}
                  >
                    ©{new Date().getFullYear()}. {this.props.t("Rights")}
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPassword.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  history: PropTypes.any,
  setLanguage: PropTypes.func,
  onRegisterUser: PropTypes.func,
  onForgotPassword: PropTypes.func,
  onResetPassword: PropTypes.func,
  googleReCaptchaProps: PropTypes.any,
};

const mapStateToProps = ({ Profile }) => {
  return {
    loading: Profile.loadingRegister,
  };
};
const mapDispatchToProps = dispatch => ({
  setLanguage: lang => dispatch(setLanguage(lang)),
  onRegisterUser: (user, callback) => dispatch(register(user, callback)),
  onForgotPassword: (payload, callback) =>
    dispatch(forgotPassword(payload, callback)),
  onResetPassword: (payload, callback) =>
    dispatch(resetPassword(payload, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withGoogleReCaptcha(ForgetPassword)));
