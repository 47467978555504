import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_EXTERNAL_USER,
  LOGIN_INTERNAL_USER,
  LOGOUT_USER,
} from "./actionTypes";
import { loginSuccess, apiError } from "./actions";

import {
  postLoginExternal,
  postLoginInternal,
  handleLogoutFromServer
} from "../../../helpers/backend_helper";
import { customLocalStorage, getGenericError, USER_TYPES } from "common";

function* loginInternalUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLoginInternal, {
      username: user.email,
      password: user.password,
    });

    const _user = {
      uid: response.responseObject.user.objectId,
      orgId: response.responseObject.user.organizationId,
      roleId: response.responseObject.user.roleId,
      contactId: response.responseObject.user.idContact,
    };
    yield put(loginSuccess(_user)); // triggeres reducer
    customLocalStorage.setItem("userType", USER_TYPES.INTERNAL_USER);

    customLocalStorage.setItem("authUser", _user);
    customLocalStorage.setItem("token", response.responseObject.token);
    //refresh token
    customLocalStorage.setItem("refreshToken",response.responseObject.refreshAccessToken);
    history.push("/home");
    window.location.reload();
  } catch (error) {
    if (error.response) {
      yield put(
        apiError(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(apiError(error.message));
    }
  }
}

function* loginExternalUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLoginExternal, {
      username: user.email,
      password: user.password,
    });
    console.log("***** login external *****");
    const _user = {
      uid: response.responseObject.user.objectId,
      orgId: response.responseObject.user.organizationId,
      roleId: response.responseObject.user.roleId,
      contactId: response.responseObject.user.idContact,
    };
    yield put(loginSuccess(_user));
    customLocalStorage.setItem("userType", USER_TYPES.EXTERNAL_USER);    
    customLocalStorage.setItem("authUser", _user);
    customLocalStorage.setItem("token", response.responseObject.token);
    customLocalStorage.setItem("refreshToken",response.responseObject.refreshAccessToken?.result);
   
    history.push("/home");
    window.location.reload();
  } catch (error) {
    if (error.response) {
      yield put(
        apiError(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(apiError(error.message));
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    let userType = JSON.parse(customLocalStorage.getItem("userType"));

    const response = yield call(handleLogoutFromServer);
    localStorage.clear();


    if (userType === USER_TYPES.EXTERNAL_USER) {
      history.push("/login-external");
    } else {
      history.push("/login-internal");
    }
    // setTimeout(() => {
    //   if (userType === USER_TYPES.EXTERNAL_USER) {
    //     history.replace("/login-external");
    //   } else {
    //     history.replace("/login-internal");
    //   }
    // }, 500);
    window.location.reload();
  } catch (error) {
    if (error.response) {
      yield put(
        apiError(
          error.response?.data?.responseStatus?.description || getGenericError()
        )
      );
    } else {
      yield put(apiError(error.message));
    }
  }
}

// main function
function* authSaga() {
  yield takeEvery(LOGIN_INTERNAL_USER, loginInternalUser);
  yield takeEvery(LOGIN_EXTERNAL_USER, loginExternalUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
