import {
  GET_FILES_SUCCESS,
  GET_FILES_FAIL,
  GET_FILES,
  GET_RELATED_FILES_SUCCESS,
  GET_RELATED_FILES_FAIL,
  GET_RELATED_FILES,
  GET_FILE_CATEGORIES_SUCCESS,
  GET_FILE_CATEGORIES_FAIL,
  GET_FILE_CATEGORIES,
  GET_FILE_DETAILS_SUCCESS,
  GET_FILE_DETAILS_FAIL,
  GET_FILE_DETAILS,
  ADD_FILE_COMMENT_SUCCESS,
  ADD_FILE_COMMENT_FAIL,
  ADD_FILE_COMMENT,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAIL,
  UPLOAD_FILES,
  GET_REFERENCED_FILES_SUCCESS,
  GET_REFERENCED_FILES_FAIL,
  GET_REFERENCED_FILES,
  CREATE_REFERENCED_FILES_SUCCESS,
  CREATE_REFERENCED_FILES_FAIL,
  CREATE_REFERENCED_FILES,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAIL,
  CREATE_FOLDER,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  DOWNLOAD_FILE,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL,
  GET_DOCUMENT_CORRESPONDENCES, 
  GET_DOCUMENT_APPROVALS,
  GET_DOCUMENT_APPROVALS_SUCCESS,
  GET_DOCUMENT_APPROVALS_FAIL, 
  GET_DOCUMENT_TASKS,
  GET_DOCUMENT_TASKS_SUCCESS,
  GET_DOCUMENT_TASKS_FAIL, 
  GET_DOCUMENT_PROJECTS,
  GET_DOCUMENT_PROJECTS_SUCCESS,
  GET_DOCUMENT_PROJECTS_FAIL, 
  GET_DOCUMENT_REQUESTS,
  GET_DOCUMENT_REQUESTS_SUCCESS,
  GET_DOCUMENT_REQUESTS_FAIL,
  GET_DOCUMENT_ORGANIZATIONS,
  GET_DOCUMENT_ORGANIZATIONS_SUCCESS,
  GET_DOCUMENT_ORGANIZATIONS_FAIL
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  relatedLoading: false,
  loadingList: false,
  detailsLoading: false,
  categoriesLoading: false,
  downloadLoading: false,
  downloadingFileId: null,
  files: [],
  relatedFiles: null,
  categories: null,
  comments: [],
  referencedFiles: [],
  subList:null
};

const files = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
        error: "",
      };

    case DOWNLOAD_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        downloadLoading: false,
      };
    case DOWNLOAD_FILE:
      return {
        ...state,
        downloadLoading: true,
        downloadingFileId: action.payload.id,
      };
    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case CREATE_FOLDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CREATE_FOLDER:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REFERENCED_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case CREATE_REFERENCED_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CREATE_REFERENCED_FILES,GET_DOCUMENT_APPROVALS:
      return {
        ...state,
        loading: true,
      };
    case GET_REFERENCED_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        referencedFiles: action.payload,
      };

    case GET_REFERENCED_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_REFERENCED_FILES:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPLOAD_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPLOAD_FILES:
      return {
        ...state,
        loading: true,
      };
    case GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        loading: false,
        error: "",
      };

    case GET_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_FILES:
      return {
        ...state,
        loading: true,
      };

    case GET_FILE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoriesLoading: false,
        error: "",
      };

    case GET_FILE_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        categoriesLoading: false,
      };
    case GET_FILE_CATEGORIES:
      return {
        ...state,
        categoriesLoading: true,
      };

    case GET_RELATED_FILES_SUCCESS:
      return {
        ...state,
        relatedFiles: action.payload,
        relatedLoading: false,
        error: "",
      };

    case GET_RELATED_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
        relatedLoading: false,
      };
    case GET_RELATED_FILES:
      return {
        ...state,
        relatedLoading: true,
      };

    case GET_FILE_DETAILS_SUCCESS:
      return {
        ...state,
        comments: action.payload,
        detailsLoading: false,
        error: "",
      };

    case GET_FILE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        detailsLoading: false,
      };
    case GET_FILE_DETAILS:
      return {
        ...state,
        detailsLoading: true,
      };

    case ADD_FILE_COMMENT_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        error: "",
      };

    case ADD_FILE_COMMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        detailsLoading: false,
      };
    case ADD_FILE_COMMENT:
      return {
        ...state,
        detailsLoading: true,
      };

    case DELETE_FILE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
  
  

      case GET_DOCUMENT_APPROVALS:{
        return {
          ...state,
          loadingList: true,
          error: "",
        };
      }
      case GET_DOCUMENT_APPROVALS_SUCCESS:{
        return {
          ...state,
          data: action.payload,
          loadingList: false,
          error: "",
        };
      }
      case GET_DOCUMENT_APPROVALS_FAIL:{
        return {
          ...state,
          error: action.payload,
          loadingList: false,
         
        };
      }

      case GET_DOCUMENT_TASKS:{
        return {
          ...state,
          loadingList: true,
          error: "",
        };
      }
      case GET_DOCUMENT_TASKS_SUCCESS:{
        const pageNum = action.payload.pageNumber
        return {
          ...state,
          subList: pageNum==1 ? action.payload.data : state.subList.concat(action.payload.data) ,
          loadingList: false,
          totalRecordCount:action.payload.totalRecordCount,
          error: "",

        };
      }
      case GET_DOCUMENT_TASKS_FAIL:{
        return {
          ...state,
          error: action.payload,
          loadingList: false,
         
        };
      }


      case GET_DOCUMENT_PROJECTS:{
        return {
          ...state,
          loadingList: true,
          error: "",
        };
      }
      case GET_DOCUMENT_PROJECTS_SUCCESS:{
        const pageNum = action.payload.pageNumber
        return {
          ...state,
          subList: pageNum==1 ? action.payload.data : state.subList.concat(action.payload.data) ,
          loadingList: false,
          totalRecordCount:action.payload.totalRecordCount,
          error: "",

        };
      }
      case GET_DOCUMENT_PROJECTS_FAIL:{
        return {
          ...state,
          error: action.payload,
          loadingList: false,
         
        };
      }

      case GET_DOCUMENT_REQUESTS:{
        return {
          ...state,
          loadingList: true,
          error: "",
        };
      }
      case GET_DOCUMENT_REQUESTS_SUCCESS:{
        const pageNum = action.payload.pageNumber
        return {
          ...state,
          subList: pageNum==1 ? action.payload.data : state.subList.concat(action.payload.data) ,
          loadingList: false,
          totalRecordCount:action.payload.totalRecordCount,
          error: "",

        };
      }
      case GET_DOCUMENT_REQUESTS_FAIL:{
        return {
          ...state,
          error: action.payload,
          loadingList: false,
         
        };
      }

      case GET_DOCUMENT_ORGANIZATIONS:{
        return {
          ...state,
          loadingList: true,
          error: "",
        };
      }
      case GET_DOCUMENT_ORGANIZATIONS_SUCCESS:{
        debugger;
        const pageNum = action.payload.pageNumber
       let formattedData = action.payload?.data?.map(org => { 
        return {
          ...org,
          id: org.idOrganization,
          title:org.nameEn,
          titleAr:org.nameAr

        };
      });
        return {
          ...state,
          subList: pageNum==1 ? formattedData : state.subList.concat(formattedData) ,
          loadingList: false,
          totalRecordCount:action.payload.totalRecordCount,
          error: "",

        };
      }
      case GET_DOCUMENT_ORGANIZATIONS_FAIL:{
        return {
          ...state,
          error: action.payload,
          loadingList: false,
         
        };
      }

    default:
      return state;
  }
};

export default files;
