import React, { Component } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import Dropzone from "react-dropzone";

// Breadcrumb
import PropTypes from "prop-types";
import { Colors, DEFAULT_ACCEPTED_FILES } from "common";
import { withTranslation } from "react-i18next";
import { formatBytes } from "common/utils";

import Resizer from "react-image-file-resizer";

const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      130,
      130,
      "PNG",
      100,
      0,
      uri => {
        resolve(uri);
      },
      "file"
    );
  });
class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: props.files ? props.files : [],
      file: null,
    };
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.FilePreviewComponent = this.FilePreviewComponent.bind(this);
  }

  handleAcceptedFiles = files => {
    if (this.props.multiple) {
      let counter = 0;
      files = files
        .map((file, index) => {
          let uniqueId =
            this.state.selectedFiles.length > 0
              ? this.state.selectedFiles[this.state.selectedFiles.length - 1]
                  .id +
                1 +
                index
              : counter++;
          let _otherFiles = JSON.parse(JSON.stringify([...files]));
          _otherFiles.splice(index, 1);
          let otherFiles = [...this.state.selectedFiles];
          let fileExists = otherFiles.some(item => item.path === file.path);
          if (fileExists) {
            return false;
          }
          return Object.assign(file, {
            id: uniqueId,
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          });
        })
        .filter(item => !!item);
      const _files = [...this.state.selectedFiles, ...files];
      this.setState({ selectedFiles: _files });
      this.props.onFilesAccepted(_files);
    } else {
      Object.assign(files[0], {
        id: 0,
        preview: URL.createObjectURL(files[0]),
        formattedSize: formatBytes(files[0].size),
      });
      this.setState({ file: files[0] });
      this.props.onFilesAccepted([files[0]]);
    }
  };

  handleDeleteFile = id => {
    if (this.props.multiple) {
      let files = [...this.state.selectedFiles].filter(item => item.id !== id);
      this.setState({ selectedFiles: files });
      this.props.onFilesAccepted(files);
    } else {
      this.setState({ file: null });
      this.props.onFilesAccepted([]);
    }
  };

  FilePreviewComponent = (f, handleDeletion) => (
    <Row className="align-items-center justify-content-space-between">
      <Col lg={2}>
        {f.type?.includes("image") && (
          <img
            data-dz-thumbnail=""
            height="80"
            className="avatar-sm rounded bg-light"
            alt={f.name}
            src={f.preview}
          />
        )}
      </Col>
      <Col lg={8}>
        <p className="mb-0 text-muted font-weight-bold">{f.name}</p>
        <p className="mb-0">
          <strong>{f.formattedSize}</strong>
        </p>
      </Col>

      <Col
        lg={2}
        style={{
          textAlign: "end",
        }}
      >
        <button
          type="button"
          className="btn btn-primary"
          style={{
            backgroundColor: "transparent",
            border: "0px",
          }}
          onClick={() => handleDeletion(f.id)}
        >
          <i className="bx bx-trash font-size-18 text-danger"></i>
        </button>
      </Col>
    </Row>
  );
  render() {
    const { file } = this.state;
    return (
      <React.Fragment>
        <Container fluid={true}>
          <Row>
            <Col
              className="col-12"
              style={{ marginInlineStart: -6, marginTop: 4 }}
            >
              <Dropzone
                disabled={this.props.disabled}
                accept={
                  this.props.accept ? this.props.accept : DEFAULT_ACCEPTED_FILES
                }
                multiple={!!this.props.multiple}
                onDropAccepted={(acceptedFiles, rejectedFiles) => {
                  this.handleAcceptedFiles(acceptedFiles);
                }}
                getFilesFromEvent={async event => {
                  // todo: remove this validation thing
                  if (this.props.validateDimensions) {
                    const files =
                      event.target.files || event.dataTransfer.files;
                    const promises = [];
                    for (let index = 0; index < files.length; index++) {
                      try {
                        const file = files[index];
                        const imageFile = await resizeFile(file);

                        const promise = new Promise((resolve, reject) => {
                          const image = new Image(
                            imageFile.width,
                            imageFile.height
                          );
                          let url;
                          image.onload = function () {
                            imageFile.width = image.width;
                            imageFile.height = image.height;
                            resolve(imageFile);
                          };
                          url = URL.createObjectURL(imageFile);
                          image.src = url;
                        });
                        promises.push(promise);
                      } catch (err) {
                        console.log(err);
                      }
                    }
                    return await Promise.all(promises);
                  } else {
                    const files =
                      event.target.files || event.dataTransfer.files;
                    const promises = [];
                    for (let index = 0; index < files.length; index++) {
                      const file = files[index];
                      const promise = new Promise((resolve, reject) => {
                        resolve(file);
                      });
                      promises.push(promise);
                    }
                    return await Promise.all(promises);
                  }
                }}
              >
                {({ getRootProps, getInputProps, fileRejections }) => (
                  <div
                    className="dropzone"
                    style={{
                      minHeight: 150,
                      border: this.props.isInvalid ? "1px solid red" : 0,
                    }}
                  >
                    <div
                      className="dz-message needsclick"
                      {...getRootProps()}
                      style={{
                        fontSize: 10,
                        backgroundColor: Colors.searchBG,
                        borderRadius: 10,
                        padding: 40,
                      }}
                    >
                      <input {...getInputProps()} />
                      <div className="day-row day-justify-center">
                        <i
                          className="mdi mdi-file-upload-outline mb-0 text-muted bx-sm"
                          style={{ marginInlineEnd: 5 }}
                        >
                          {" "}
                        </i>
                        <h6 className="mb-0">
                          {this.props.multiple
                            ? this.props.t("Drop Files")
                            : this.props.t("Drop File")}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between my-1">
                      <p className="mb-0 text-muted form-label mx-2">
                        {`${this.props.t("File Types")} ${
                          this.props.accept
                            ? this.props.accept
                            : DEFAULT_ACCEPTED_FILES
                        }`}
                      </p>
                    </div>
                    {fileRejections.map(({ errors }, index) => (
                      <React.Fragment key={index}>
                        {errors.map((e, index) => (
                          <div
                            className="invalid-feedback d-block font-size-14"
                            key={index}
                          >
                            {e.message}
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews mt-0" id="file-previews">
                {!!this.props.multiple &&
                this.state.selectedFiles.length > 0 ? (
                  <div
                    className="scrollbar"
                    style={{
                      height: 200,
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {this.state.selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-0 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            {this.FilePreviewComponent(
                              f,
                              this.handleDeleteFile
                            )}
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                ) : (
                  !!file && (
                    <Card className="mt-0 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        {this.FilePreviewComponent(file, this.handleDeleteFile)}
                      </div>
                    </Card>
                  )
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

FormUpload.propTypes = {
  onFilesAccepted: PropTypes.func,
  accept: PropTypes.string,
  files: PropTypes.any,
  t: PropTypes.any,
  multiple: PropTypes.bool,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  validateDimensions: PropTypes.bool,
};

export default withTranslation()(FormUpload);
