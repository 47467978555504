import React, { Component } from "react";
import PropTypes from "prop-types";

class LegendTextTable extends Component {
  render() {
    return (
      <div className="d-flex align-items-center">
        <div
          style={{
            height: 12,
            width: 12,
            backgroundColor: this.props.color,
            marginInlineEnd: 8,
            borderRadius: "50%",
          }}
        />
        <p className="mb-0 day-table-title text-nowrap">{this.props.text}</p>
      </div>
    );
  }
}

LegendTextTable.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

export default LegendTextTable;
