import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./radio-steppers.scss";

class RadioStepper extends Component {
  render() {
    return (
      <div style={{ position: "relative" }}>
        <hr
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {this.props.steps.map((step, index) => (
            <input
              key={`radio_step${step}`}
              className={classnames({
                "form-check-input": true,
                "passed-step":
                  index < this.props.steps.indexOf(this.props.activeTab),
              })}
              type="radio"
              name="step"
              id={`radio_step${step}`}
              value={step}
              style={{
                marginInlineEnd: 10,
              }}
              checked={this.props.activeTab == step}
              onChange={() => {
                // this is to avoid console error
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}

RadioStepper.propTypes = {
  activeTab: PropTypes.any,
  steps: PropTypes.array,
};

export default RadioStepper;
