import {
  GET_HOME,
  GET_HOME_SUCCESS,
  GET_HOME_FAIL,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS,
} from "./actionTypes";

export const getHome = payload => ({
  type: GET_HOME,
  payload,
});

export const getHomeSuccess = tasks => ({
  type: GET_HOME_SUCCESS,
  payload: tasks,
});

export const getHomeFail = error => ({
  type: GET_HOME_FAIL,
  payload: error,
});

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
});

export const getNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});

export const updateNotifications = payload => ({
  type: UPDATE_NOTIFICATIONS,
  payload,
});
