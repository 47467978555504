import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import AllRequests from "./all-requests";
import { getRequests, updateRequestStatusCode , getRequestsDashboard } from "store/requests/actions";
import Loading from "pages/Loading";
import { Colors, CURRENT_LANG, ENTITIES, getGlobalHeaderStyle } from "common";
import moment from "moment";
import RequestDashboard from './RequestDashboard'
import { getEntityLookups } from "store/actions";
import { sortCaretIcons } from "common/utils";
import StatusText from "components/Common/StatusText";
import Tippy from "@tippy.js/react";
import {getQueryVariable } from "common/utils";

export const PAGE_SIZE = 10;

class Requests extends Component {
  constructor(props) {
    super(props);
    // read page from query string
    let selectedPage=1;
    let selectedStatus=''
    const lastPage = getQueryVariable("page") ;
    const lastStatus = getQueryVariable("status") ;
    selectedPage=lastPage?lastPage:1
    selectedStatus=lastStatus?lastStatus:''

    this.state = {
      tabletStyle: props.tabletStyle,
      betweenTabletAndMobile: props.betweenTabletAndMobile,
      mobileStyle: props.mobileStyle,
      currentPageIndex: selectedPage || 1,
      detailedCounts: [],
      lastStatus:selectedStatus
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.isRequestTypeList = this.isRequestTypeList.bind(this)
    this.isArabic = this.isArabic.bind(this)
  }

  componentDidMount() {
    const { onGetLookups , onGetRequstsDashboard , onGetRequests , requestStatusCode } = this.props;
    onGetLookups(ENTITIES.REQUEST);
    onGetRequstsDashboard();
    window.addEventListener("resize", this.resize.bind(this));
    onGetRequests({
      statusCode: this.state.lastStatus || requestStatusCode,
      pageNumber: this.state.currentPageIndex,
      pageCount: PAGE_SIZE,
      searchString: ""
    });
    window.history.pushState(null, null, window.location.pathname); // clear query params

    this.resize();
  }
  resize() {
    this.setState({ tabletStyle: window.innerWidth <= 1260 });
    this.setState({ betweenTabletAndMobile: window.innerWidth <= 800 });
    this.setState({ mobileStyle: window.innerWidth <= 545 });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

 

  handlePageChange = pageIndex => {
    const { onGetRequests, requestStatusCode } = this.props;
    this.setState({ currentPageIndex: pageIndex });
    onGetRequests({
      statusCode: requestStatusCode,
      pageNumber: pageIndex,
      pageCount: PAGE_SIZE,
      searchString:this.props.searchString
    });
  };
  updateRequestsStatus=staus=>{
    const { onGetRequests } = this.props;
    this.setState({ currentPageIndex: 1 });
    const payload={
      pageNumber:1,
      pageCount:PAGE_SIZE,
      statusCode:staus.value,
      searchString:""
    }
    onGetRequests(payload);
  }

 
  isRequestTypeList=(text)=>{
    if(text == 'طلب مشروع') return "100000000"
    if(text == 'إقتراح') return "100000001"
    if(text == 'طلب مستند') return "100000002"
    if(text == 'فكرة') return "100000003"
    if(text == 'طلب عام') return "100000004"
    if(text == 'مبادرة جديدة') return "100000005"
    if(text == 'مبادرة موجودة') return "100000006"
    return text;
  }
   isArabic=(text) =>{
    const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    return pattern.test(text);

}
validateDate = passedDate => {
  /* in case the user fills a date as "DD/mm/YY", it should be passed to the api as "YYYY-mm-DD" */
const isValidDate = moment(passedDate, 'DD/MM/YY', true).isValid(); 
const isValidDate2= moment(passedDate, 'mm/DD/YY', true).isValid(); 
const isValidDate3= moment(passedDate, 'DD/MM/YYYY', true).isValid(); 
const isValidDate4= moment(passedDate, 'M/DD/YYYY', true).isValid(); 
if(isValidDate || isValidDate2 || isValidDate3 || isValidDate4){
const currectDateFormat= isValidDate? "DD/MM/YY" :isValidDate2? "MM/DD/YY" :isValidDate3? "DD/MM/YYYY" : "M/DD/YYYY"
const dateSearch = moment(passedDate,currectDateFormat).locale("en").format('YYYY-MM-DD');
  return dateSearch  
} else {
return "Invalid date"
}
}

  // on search button click:
  getRequestWithSearch = searchString => {
    let searchValue=searchString
    const { onGetRequests, requestStatusCode } = this.props;
    
    /* check if its a date: */
    let dateSearch = this.validateDate(searchString);
      if(dateSearch !== "Invalid date"){
        searchValue=dateSearch
      }

    /** in case user search for request type in arabic, then we should pass the typeID to the api instead of name  */
   else if(this.isArabic(searchString)){
      searchValue=this.isRequestTypeList(searchString)
    }

   onGetRequests({
      statusCode: requestStatusCode,
      pageNumber: 1,
      pageCount: PAGE_SIZE,
      searchString:searchValue
    }); 
  };

  render() {
    const { requests, loading, error, loadingLookups, loadingUpload } =
      this.props;
    if (loading || loadingLookups || loadingUpload) {
      return <Loading />;
    }

    let requestsColumns = [
      {
        hidden: this.state.mobileStyle,
        headerStyle: getGlobalHeaderStyle("10%"),
        dataField: "id",
        align: "left",
        text: this.props.t("REQUEST ID"),
        formatter: (cellContent, request) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/request-details?id=" + request.id}
                className="text-primary text-decoration-underline"
              >
                {`#${request.requestNumber}`}
              </Link>
            </h5>
          </>
        ),
        sort: false,
        sortCaret: sortCaretIcons,
      },
      {
        hidden: !this.state.mobileStyle,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "left",
        text: this.props.t("Request Name"),
        dataField: "subject",
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, request) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                to={"/request-details?id=" + request.id}
                className="text-primary text-decoration-underline"
              >
                {request.subject}
              </Link>
            </h5>
          </>
        ),
      },

      {
        hidden: this.state.mobileStyle,
        headerStyle: getGlobalHeaderStyle("20%"),
        align: "left",
        text: this.props.t("Request Name"),
        dataField: "subject",
        sort: false,
        sortCaret: sortCaretIcons,
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "left",
        text: this.props.t("Request Type"),
        dataField: "requestType.label",
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, request) => (
          <>
            {CURRENT_LANG === "en"
              ? request.requestType?.label
              : request.requestType?.labelAr}
          </>
        ),
      },
      {
        hidden: this.state.betweenTabletAndMobile,
        headerStyle: getGlobalHeaderStyle("15%"),
        align: "left",
        dataField: "assignedBy.name",
        text: this.props.t("Assigned By"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cell, request) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/individual-details?id=" + request.assignedBy?.id}
                className="text-dark"
              >
                {request.assignedBy?.name}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.mobileStyle,
        headerStyle: getGlobalHeaderStyle("15%"),
        align: "left",
        dataField: "owner.label",
        text: this.props.t("Assigned To"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cell, request) => (
          <Tippy
            content={this.props.t("View More")}
            placement="top"
            animation="scale-subtle"
            theme="light"
            arrow={false}
            duration={200}
            delay={[75, 0]}
            distance={5}
          >
            <span className="text-wrap">
              <Link
                to={"/individual-details?id=" + request.owner?.value}
                query={request}
                className="text-dark"
              >
                {request.owner?.label}
              </Link>
            </span>
          </Tippy>
        ),
      },
      {
        hidden: this.state.mobileStyle,
        headerStyle: getGlobalHeaderStyle("15%"),
        align: "left",
        dataField: "department.label",
        text: this.props.t("Department"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cell, request) => (
          <span className="text-wrap text-dark">
            {CURRENT_LANG === "en"
              ? request.department?.label
              : request.department?.labelAr || ""}
          </span>
        ),
      },
      {
        hidden: this.state.tabletStyle,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "left",
        dataField: "createdOn",
        text: this.props.t("Date Created").toUpperCase(),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, request) => (
          <>
            <p className="mb-0">
              {request.createdOn &&
                moment(request.createdOn).utc().format("DD/MM/YY")}
            </p>
          </>
        ),
      },
      {
        hidden: this.state.tabletStyle,
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "left",
        dataField: "duedate",
        text: this.props.t("Due Date Form"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, request) => (
          <>
            <p className="mb-0">
              {request.duedate && moment(request.duedate).utc().format("DD/MM/YY")}
            </p>
          </>
        ),
      },
      {
        headerStyle: getGlobalHeaderStyle("10%"),
        align: "left",
        dataField: "status",
        text: this.props.t("STATUS"),
        sort: false,
        sortCaret: sortCaretIcons,
        formatter: (cellContent, request) => (
          <StatusText
            text={request.status ? request.status.label?.toUpperCase() : ""}
          />
        ),
      },
    ];


    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              {this.props.t("Title").replace("*", this.props.t("Requests"))}
            </title>
          </MetaTags>
          <Container fluid>
           
           <RequestDashboard
           t={this.props.t}
           data={this.props.requestsDashboard}
            />

            <Row className="g-0" style={{ marginTop: 0 }}>
              <AllRequests
                requests={requests}
                listStatus={
                  this.props.entityLookups?.day_request?.listStatus || []
                }
                requestsColumns={requestsColumns}
                currentPageIndex={this.state.currentPageIndex}
                handlePageChange={this.handlePageChange}
                totalDataSize={this.props.totalRecordCount || 0}
                pageCount={PAGE_SIZE}
                getRequestWithSearch={(searchText)=>this.getRequestWithSearch(searchText)}
                reqSearch={this.props.searchString}
                updateStatus={(status)=>this.updateRequestsStatus(status)}
              />

            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Requests.propTypes = {
  t: PropTypes.any,
  requests: PropTypes.array,
  searchString:PropTypes.string,
  totalRecordCount:PropTypes.number,
  requestStatusCode: PropTypes.any,
  entityLookups: PropTypes.any,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onUpdateStatusCode: PropTypes.func,
  onGetRequests: PropTypes.func,
  onGetLookups: PropTypes.func,
  loadingLookups: PropTypes.bool,
  loadingUpload: PropTypes.bool,
  tabletStyle: PropTypes.any,
  betweenTabletAndMobile: PropTypes.any,
  mobileStyle: PropTypes.any,
  totalSizePerStatusRequests: PropTypes.any,
  onGetRequstsDashboard: PropTypes.func,
  requestsDashboard:PropTypes.any,
};

const mapStateToProps = ({ requests, Layout, files }) => ({
  requests: requests.requests,
  searchString: requests.searchString,
  requestsDashboard: requests.dashboardData,
  totalRecordCount:requests.totalRecordCount,
  error: requests.error,
  loading: requests.loading,
  loadingLookups: Layout.loadingLookups,
  loadingUpload: files.loading,
  entityLookups: Layout.entityLookups,
  requestStatusCode: requests.requestStatusCode,
  totalSizePerStatusRequests: Layout.totalSizePerStatusRequests,
});

const mapDispatchToProps = dispatch => ({
  onUpdateStatusCode: payload => dispatch(updateRequestStatusCode(payload)),
  onGetRequests: payload => dispatch(getRequests(payload)),
  onGetLookups: (payload, callback) => dispatch(getEntityLookups(payload, callback)),
  onGetRequstsDashboard: () => dispatch(getRequestsDashboard()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Requests)));
