
import BootstrapTable from 'react-bootstrap-table-next';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import PropTypes from "prop-types";

const ServerTable = props =>{
    const {
        columns,
        data,
        onPageChange,
        currentPage,
        totalDataSize,
        sizePerPage,
    } = props

 return(< div className='server-card'>
    <BootstrapTable 
    keyField='id' 
    data={ data } 
    columns={ columns } 
    />
    <PaginationControl
        page={currentPage}
        between={2} // Max items in left and right when page is in center, it’s minimum value is 1
        total={totalDataSize} //Total of items
        limit={sizePerPage} // Max items per page
        changePage={(page) => onPageChange(page)}
        className={'endd'}
        style={{justifyContent:'end'}}
        ellipsis={2}
    />

</div>
 )
}
ServerTable.propTypes = {
    data: PropTypes.array,
    columns:PropTypes.any,
    onPageChange:PropTypes.any,
    currentPage:PropTypes.any,
    totalDataSize:PropTypes.any,
    sizePerPage: PropTypes.number,
} 

export default ServerTable
