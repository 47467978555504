import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabPane,
  TabContent,
  Card,
  CardBody,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import FormUpload from "pages/Forms/FormUpload";
import CustomSelect from "components/Common/CustomSelect";
import { getLookupValue } from "common/utils";
import { Colors, customLocalStorage, isInternal, NEW_INITIATIVE ,handleArabicDate } from "common";
import RadioStepper from "components/Common/RadioStepper";
import { formatLineBreaksInMarkdownFriendlyFormat } from "common/utils";
import moment from 'moment'

Yup.addMethod(
  Yup.object,
  "isDepartmentOrAssignedToFilled",
  function (errorMessage, oppositeFieldName) {
    return this.test(
      `test-opposite-filled`,
      errorMessage,
      function (value, { parent }) {
        const { path, createError } = this;
        // If my opposite field does not have a value and the current field doesnt have a value then error out
        if (!parent[oppositeFieldName] && !value)
          return createError({ path, message: errorMessage });
        return true;
      }
    );
  }
);

class RequestModal extends Component {
  constructor(props) {
    super(props);

    const _validations = [
      {
        subject: Yup.string().required(this.props.t("errorSubject")),
        requestType: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorRequestType")),
        // idProject: Yup.object()
        //   .shape({
        //     label: Yup.string().required(),
        //     value: Yup.string().required(),
        //   })
        //   .required(this.props.t("errorSelectProject")),
        // idOrganization: Yup.object()
        //   .shape({
        //     label: Yup.string().required(),
        //     value: Yup.string().required(),
        //   })
        // .required(this.props.t("errorSelectOrganization")),
        duedate: Yup.date().required(this.props.t("errorDueDate")),
        priority: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .required(this.props.t("errorSelectPriority")),
        department: Yup.object()
          .isDepartmentOrAssignedToFilled(
            this.props.t("errorAssignRequest"),
            "assignedTo"
          )
          .optional()
          .nullable(),
        assignedTo: Yup.object()
          .isDepartmentOrAssignedToFilled(
            this.props.t("errorAssignRequest"),
            "department"
          )
          .optional()
          .nullable(),
      },
      {},
      {},
      {
        //totalCost
        //operatingExpenditure
        //capitalExpenditure
        deliverables: Yup.array().of(
          Yup.object().shape({
            subject: Yup.string().required("subject is required"),
            operatingExpenditure: Yup.number()
              .integer()
              .required("OperatingExpenditure is required"),
            capitalExpenditure: Yup.number()
              .integer()
              .required("capitalExpenditure is required"),
            operatingExpenditure: Yup.number()
              .integer()
              .required("OperatingExpenditure is required"),
            totalCost: Yup.number().integer().required("TotalCost is required"),
          })
        ),
      },
    ];

    if (props.isEdit) {
      _validations["status"] = Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(this.props.t("errorStatus"));
    }
    const _requestSchemaValidations = [
      Yup.object().shape(_validations[0]),
      Yup.object().shape(_validations[1]),
      Yup.object().shape(_validations[2]),
      Yup.object().shape(_validations[3]),
    ];
    this.state = {
      activeTab: 1,
      validations: _validations,
      requestSchemaValidations: _requestSchemaValidations,
      requestType: null,
      departmentDropDownDisabled: this.props.isEdit,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  isValid(values, errors) {
    for (const key in this.state.validations[this.state.activeTab - 1]) {
      if (!values[key] && !(key === "assignedTo" || key === "department")) {
        return false;
      }
    }
    if (Object.keys(errors).length > 0) {
      /*if ( ((errors.assignedTo || errors.department) && values.assignedTo) ||!values.department) */
      /** if department checked , user should select department, else user should select assignedTo */
      const isDeptChecked = this.state.departmentDropDownDisabled;
      if (
        (isDeptChecked && !values.department) ||
        (!isDeptChecked && !values.assignedTo)
      )
        return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (!!this.props.isEdit || !!this.props.request) {
        this.setState({
          requestType:
            this.props.request &&
            getLookupValue(
              this.props.request.requestType?.value,
              this.props.entityLookups.listRequestType
            ),
        });
      }
    }
  }
  render() {
    const { org, isEdit, request, files, onFilesAccepted, entityLookups } =
      this.props;

    const currentUserEntityLookupValue = {
      label: localStorage.getItem("name"),
      labelAr: localStorage.getItem("name"),
      value: JSON.parse(customLocalStorage.getItem("authUser")).contactId,
    };
    const requestCreatedByCurrentUser =
      request?.assignedBy?.id === currentUserEntityLookupValue?.value ?? false;

    const constructAssignToData = () => {
      const internalUserEntities = entityLookups.listIndividualsInternal;
      const externalUserEntities = entityLookups.listIndividualsExternal;

      // Helper function that checks if a given user id is included in the internal or external user lookups already
      const userPartOfIndividualLookups = id =>
        [...internalUserEntities, ...externalUserEntities].find(
          item => item?.value === id
        )
          ? true
          : false;

      const dropdownEntities = isInternal
        ? [
            {
              label: this.props.t("Internal"),
              options:
                isEdit &&
                isInternal &&
                !requestCreatedByCurrentUser &&
                !userPartOfIndividualLookups(currentUserEntityLookupValue.value)
                  ? [...internalUserEntities, currentUserEntityLookupValue]
                  : internalUserEntities,
            },
            {
              label: this.props.t("External"),
              options:
                isEdit &&
                !isInternal &&
                !requestCreatedByCurrentUser &&
                !userPartOfIndividualLookups(currentUserEntityLookupValue.value)
                  ? [...externalUserEntities, currentUserEntityLookupValue]
                  : externalUserEntities,
            },
          ]
        : externalUserEntities;

      // This looks at whether or not the current request owner is included as part of the internal or external user entities looks.If not it appends the owner
      if (
        request?.owner?.value &&
        !userPartOfIndividualLookups(request?.owner?.value)
      )
        return isInternal
          ? [{ label: "", options: [request?.owner] }, ...dropdownEntities]
          : dropdownEntities.concat(request?.owner);

      return dropdownEntities;
    };

    if (!entityLookups) {
      return <></>;
    }
    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size={"lg"}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {isEdit
            ? this.props.t("Edit Request") +
              (this.state.requestType?.label === NEW_INITIATIVE
                ? ` (${this.state.activeTab}/4)`
                : "")
            : this.props.t("Add Request") +
              (this.state.requestType?.label === NEW_INITIATIVE
                ? ` (${this.state.activeTab}/4)`
                : "")}
        </ModalHeader>

        <ModalBody>
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            initialValues={{
              subject: (request && request.subject) || "",
              idProject:
                (request &&
                  getLookupValue(
                    request.projectName,
                    entityLookups.listRequestProjects
                  )) ||
                "",
              priority:
                (request &&
                  getLookupValue(
                    request.priority?.value,
                    entityLookups.listRequestPriority
                  )) ||
                "",
              status:
                (request &&
                  getLookupValue(
                    request.status?.value,
                    entityLookups.listStatus
                  )) ||
                "",
                duedate:
                request && handleArabicDate(moment(request.duedate).utc().format('yyyy-MM-DD')) || "",
              description:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.description,
                    true
                  )) ||
                "",
              requestType:
                (request &&
                  getLookupValue(
                    request.requestType?.value,
                    entityLookups.listRequestType
                  )) ||
                "",
              idOrganization:
                (request &&
                  getLookupValue(
                    request.organization?.value,
                    entityLookups.listOrganizations
                  )) ||
                entityLookups.listOrganizations?.[0],
              assignedTo:
                (request &&
                  getLookupValue(
                    request?.owner?.value,
                    isInternal
                      ? entityLookups.listIndividualsInternal
                          .concat(entityLookups.listIndividualsExternal)
                          .concat(currentUserEntityLookupValue)
                          .concat(request?.owner)
                      : entityLookups.listIndividualsExternal
                          .concat(currentUserEntityLookupValue)
                          .concat(request?.owner)
                  )) ||
                "",
              department:
                (request &&
                  getLookupValue(
                    request?.department?.value,
                    !entityLookups?.listDepartment?.find(
                      item => item.value === request?.department?.value
                    )
                      ? entityLookups.listDepartment?.concat(
                          request?.department
                        )
                      : entityLookups.listDepartment
                  )) ||
                "",
              files: (request && request.files) || [],
              deliverables: (request && request.deliverables) || [],
              resources:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.resources,
                    true
                  )) ||
                "",
              mitigation:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.mitigation,
                    true
                  )) ||
                "",
              risks:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.risks,
                    true
                  )) ||
                "",
              assumptions:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.assumptions,
                    true
                  )) ||
                "",
              financial:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.benefits,
                    true
                  )) ||
                "",
              keyStakeholders:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.keyStakeholders,
                    true
                  )) ||
                "",
              businessImpact:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.businessImpact,
                    true
                  )) ||
                "",
              initiativeScope:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.initiativeScope,
                    true
                  )) ||
                "",
              problemStatement:
                (request &&
                  formatLineBreaksInMarkdownFriendlyFormat(
                    request.problemStatement,
                    true
                  )) ||
                "",
            }}
            validationSchema={
              this.state.requestSchemaValidations[this.state.activeTab - 1]
            }
            onSubmit={values => {
              // this.props.handleSubmit(values);
            }}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <>
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="body"
                        >
                          <TabPane tabId={1}>
                            <Form>
                              <div className="mb-3">
                                <Row>
                                  <Col lg={"12"}>
                                    <Label className="form-label day-required">
                                      {this.props.t("OrganizationForm")}
                                    </Label>

                                    <CustomSelect
                                      className="input"
                                      onChange={value => {
                                        setFieldValue("idOrganization", value);
                                      }}
                                      value={values.idOrganization}
                                      options={entityLookups.listOrganizations}
                                    />
                                    <ErrorMessage
                                      name="organization"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                              </div>

                              <div className="mb-3">
                                <Row>
                                  <Col sm={12}>
                                    <Label className="form-label day-required">
                                      {this.props.t("SubjectForm")}
                                    </Label>
                                    <Field
                                      name="subject"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.subject && touched.subject
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="subject"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                              </div>

                              <Row>
                                <Col className="col-12">
                                  <div className="mb-3">
                                    <Row>
                                      <Col sm={12}>
                                        <Label className="form-label day-required">
                                          {this.props.t("Request Type")}
                                        </Label>
                                        <CustomSelect
                                          className="input"
                                          onChange={value => {
                                            setFieldValue("requestType", value);
                                            this.setState({
                                              requestType: value,
                                            });
                                          }}
                                          value={values.requestType}
                                          options={
                                            entityLookups.listRequestType
                                          }
                                        />
                                        <ErrorMessage
                                          name="requestType"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>

                                      {/*<Col sm={6}>
                                        <Label className="form-label">
                                          {this.props.t("ProjectForm")}
                                        </Label>
                                        <CustomSelect
                                          className="input"
                                          onChange={value => {
                                            setFieldValue("idProject", value);
                                          }}
                                          value={values.idProject}
                                          options={
                                            entityLookups.listRequestProjects
                                          }
                                          isClearable
                                        />
                                        <ErrorMessage
                                          name="idProject"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        </Col>*/}
                                    </Row>
                                  </div>

                                  <div className="mb-3">
                                    <Row>
                                      <Col sm={6}>
                                        <Label className="form-label day-required">
                                          {this.props.t("Importance")}
                                        </Label>
                                        <CustomSelect
                                          className="input"
                                          onChange={value => {
                                            setFieldValue("priority", value);
                                          }}
                                          value={values.priority}
                                          options={
                                            entityLookups.listRequestPriority
                                          }
                                        />
                                        <ErrorMessage
                                          name="priority"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                      <Col sm={6}>
                                        <Label
                                          className={`form-label ${
                                            ((!values.department &&
                                              !values.assignedTo) ||
                                              values.assignedTo ||
                                              isEdit) &&
                                            "day-required"
                                          }`}
                                        >
                                          {this.props.t("Assigned To")}
                                        </Label>
                                        <CustomSelect
                                          className="input"
                                          onChange={value => {
                                            setFieldValue("assignedTo", value);
                                            if (
                                              !this.state
                                                .departmentDropDownDisabled &&
                                              !isEdit
                                            )
                                              setFieldValue("department", "");
                                          }}
                                          disabled={
                                            !isEdit
                                              ? this.state
                                                  .departmentDropDownDisabled
                                              : false
                                          }
                                          value={values.assignedTo}
                                          options={constructAssignToData()}
                                        />
                                        <ErrorMessage
                                          name="assignedTo"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  {/* Only show the department dropdown while editing if there is no request owner */}
                                  {!(isEdit && request?.owner?.value) && (
                                    <div className="mb-3">
                                      <Row>
                                        {!isEdit && (
                                          <Col sm={6}>
                                            <div className="day-row">
                                              <div
                                                className="form-check form-switch"
                                                style={{
                                                  marginInlineStart: 10,
                                                }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-control-input float-start"
                                                  id="departmentDisabled"
                                                  checked={
                                                    this.state
                                                      .departmentDropDownDisabled ??
                                                    false
                                                  }
                                                  style={{
                                                    color: "var(--blue)",
                                                  }}
                                                  onChange={e => {
                                                    this.setState({
                                                      departmentDropDownDisabled:
                                                        e.currentTarget.checked
                                                          ? true
                                                          : false,
                                                    });
                                                    this.isValid(
                                                      values,
                                                      errors
                                                    );
                                                  }}
                                                />
                                              </div>{" "}
                                              <Label
                                                className="form-label"
                                                style={{ marginBottom: 0 }}
                                                htmlFor="departmentDisabled"
                                              >
                                                {this.props.t(
                                                  "Assign to Department"
                                                )}
                                              </Label>
                                            </div>
                                          </Col>
                                        )}
                                        <Col sm={!isEdit && 6}>
                                          <Label
                                            className={`form-label ${
                                              ((!values.assignedTo &&
                                                !values.department) ||
                                                values.department ||
                                                (this.state
                                                  .departmentDropDownDisabled &&
                                                  !isEdit &&
                                                  !values.assignedTo)) &&
                                              "day-required"
                                            }`}
                                          >
                                            {this.props.t("Department")}
                                          </Label>
                                          <CustomSelect
                                            className="input"
                                            onChange={value => {
                                              setFieldValue(
                                                "department",
                                                value
                                              );
                                              isEdit &&
                                                setFieldValue("assignedTo", "");
                                              if (
                                                this.state
                                                  .departmentDropDownDisabled &&
                                                !isEdit
                                              )
                                                setFieldValue("assignedTo", "");
                                            }}
                                            value={values.department}
                                            options={
                                              request?.department?.value &&
                                              !entityLookups.listDepartment?.find(
                                                item =>
                                                  item.value ===
                                                  request?.department?.value
                                              )
                                                ? entityLookups.listDepartment.concat(
                                                    request?.department
                                                  )
                                                : entityLookups.listDepartment
                                            }
                                            disabled={
                                              !isEdit
                                                ? !this.state
                                                    .departmentDropDownDisabled
                                                : false
                                            }
                                          />
                                          <ErrorMessage
                                            name="department"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  )}

                                  {/* Due date:  */}
                                  <div className="mb-3">
                                    <Row>
                                      <Col lg="6">
                                        <Label className="form-label day-required">
                                          {this.props.t("Deadline")}
                                        </Label>

                                        <Field
                                         value={values.duedate}
                                          name="duedate"
                                          type="date"
                                          className={
                                            "form-control" +
                                            (errors.duedate && touched.duedate
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                           <ErrorMessage
                                            name="duedate"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                      </Col>
                                    </Row>
                                  </div>

                                  {isEdit && (
                                    <div className="mb-3">
                                      <Row>
                                        <Col sm={12}>
                                          <Label className="form-label day-required">
                                            {this.props.t("StatusForm")}
                                          </Label>
                                          <CustomSelect
                                            className="input"
                                            onChange={value => {
                                              setFieldValue("status", value);
                                            }}
                                            value={values.status}
                                            options={entityLookups.listStatus}
                                          />
                                          <ErrorMessage
                                            name="status"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                  <div className="mb-3">
                                    <Row>
                                      <Col>
                                        <Label className="form-label">
                                          {this.props.t("DescriptionForm")}
                                        </Label>
                                        <Field
                                          name="description"
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.description &&
                                            touched.description
                                              ? " is-invalid"
                                              : "")
                                          }
                                          as="textarea"
                                        />
                                        <ErrorMessage
                                          name="description"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  {!isEdit && (
                                    <div className="mb-3">
                                      <Col sm={12}>
                                        <Label className="form-label">
                                          {this.props.t("Files")}
                                        </Label>
                                        <FormUpload
                                          files={files}
                                          onFilesAccepted={onFilesAccepted}
                                          multiple
                                        />
                                      </Col>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Form>
                          </TabPane>

                          {values.requestType.label === NEW_INITIATIVE && (
                            <>
                              <TabPane tabId={2}>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Problem Statement")}
                                      </Label>
                                      <Field
                                        name="problemStatement"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.problemStatement &&
                                          touched.problemStatement
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="problemStatement"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Initiative Scope")}
                                      </Label>
                                      <Field
                                        name="initiativeScope"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.initiativeScope &&
                                          touched.initiativeScope
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="initiativeScope"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>

                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Business Impact")}
                                      </Label>
                                      <Field
                                        name="businessImpact"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.businessImpact &&
                                          touched.businessImpact
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="businessImpact"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Key stakeholders")}
                                      </Label>
                                      <Field
                                        name="keyStakeholders"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.keyStakeholders &&
                                          touched.keyStakeholders
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="keyStakeholders"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </TabPane>
                              <TabPane tabId={3}>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Financial")}
                                      </Label>
                                      <Field
                                        name="financial"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.financial && touched.financial
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="financial"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Assumptions")}
                                      </Label>
                                      <Field
                                        name="assumptions"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.assumptions &&
                                          touched.assumptions
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="assumptions"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Risks")}
                                      </Label>
                                      <Field
                                        name="risks"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.risks && touched.risks
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="risks"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <Row>
                                    <Col sm={12}>
                                      <Label className="form-label">
                                        {this.props.t("Mitigation")}
                                      </Label>
                                      <Field
                                        name="mitigation"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.mitigation &&
                                          touched.mitigation
                                            ? " is-invalid"
                                            : "")
                                        }
                                        as="textarea"
                                      />
                                      <ErrorMessage
                                        name="mitigation"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <Col sm={12}>
                                    <Label className="form-label">
                                      {this.props.t("Resources")}
                                    </Label>
                                    <Field
                                      name="resources"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.resources && touched.resources
                                          ? " is-invalid"
                                          : "")
                                      }
                                      as="textarea"
                                    />
                                    <ErrorMessage
                                      name="resources"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </div>
                              </TabPane>
                              <TabPane tabId={4}>
                                <div
                                  className="scrollbar"
                                  style={{
                                    maxHeight: 500,
                                    overflow: "scroll",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <FieldArray
                                    name="deliverables"
                                    render={({ push, remove }) => (
                                      <Form>
                                        <Col sm={12} className="day-w-100">
                                          <Label className="form-label">
                                            {this.props
                                              .t("Deliverables")
                                              .toUpperCase()}
                                          </Label>
                                          {values.deliverables?.length > 0 &&
                                            values.deliverables.map(
                                              (deliverable, index) => (
                                                <Row
                                                  key={index}
                                                  className="day-row day-align-items-center day-w-100"
                                                  style={{
                                                    marginTop: 20,
                                                  }}
                                                >
                                                  <Col sm={3}>
                                                    <Label className="table-label day-required">
                                                      {this.props.t(
                                                        "mainDeliverables"
                                                      )}
                                                    </Label>
                                                    <Field
                                                      name={`deliverables.${index}.subject`}
                                                      type="text"
                                                      className={"form-control"}
                                                    />
                                                  </Col>
                                                  <Col sm={3}>
                                                    <Label className="table-label day-required">
                                                      {this.props.t(
                                                        "capitalExpenditures"
                                                      )}
                                                    </Label>
                                                    <Field
                                                      name={`deliverables.${index}.capitalExpenditure`}
                                                      as="input"
                                                      type="number"
                                                      className={"form-control"}
                                                    />
                                                  </Col>
                                                  <Col sm={3}>
                                                    <Label className="table-label day-required">
                                                      {this.props.t(
                                                        "operationalExpenditures"
                                                      )}
                                                    </Label>
                                                    <Field
                                                      name={`deliverables.${index}.operatingExpenditure`}
                                                      as="input"
                                                      type="number"
                                                      className={"form-control"}
                                                    />
                                                  </Col>
                                                  <Col sm={2}>
                                                    <Label className="table-label day-required">
                                                      {this.props.t(
                                                        "totalCost"
                                                      )}
                                                    </Label>
                                                    <Field
                                                      name={`deliverables.${index}.totalCost`}
                                                      as="input"
                                                      type="number"
                                                      className={"form-control"}
                                                    />
                                                  </Col>
                                                  <Col sm={1}>
                                                    <i
                                                      className="far fa-minus-square font-size-18"
                                                      style={{
                                                        color: Colors.red,
                                                        cursor: "pointer",
                                                        marginTop: 30,
                                                      }}
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                    ></i>
                                                  </Col>
                                                </Row>
                                              )
                                            )}
                                          <button
                                            type="button"
                                            className="btn customTable-button float-end"
                                            style={{
                                              marginTop: 30,
                                              marginInlineEnd: 10,
                                            }}
                                            onClick={() =>
                                              push({
                                                subject: "",
                                                capitalExpenditure: "",
                                                operatingExpenditure: "",
                                                totalCost: "",
                                              })
                                            }
                                          >
                                            {this.props.t("Add")}
                                          </button>
                                        </Col>
                                      </Form>
                                    )}
                                  />
                                </div>
                              </TabPane>
                            </>
                          )}
                        </TabContent>
                      </div>
                      <RadioStepper
                        steps={
                          values.requestType.label === NEW_INITIATIVE
                            ? [1, 2, 3, 4]
                            : [1]
                        }
                        activeTab={this.state.activeTab}
                      />
                      <div
                        className="actions clearfix"
                        style={{ marginTop: 40 }}
                      >
                        <ul className="day-text-end">
                          {this.state.activeTab > 1 && (
                            <button
                              type="button"
                              className="btn customTable-button"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                              style={{ marginInlineEnd: 10 }}
                            >
                              {this.props.t("Previous")}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn customTable-button"
                            disabled={!this.isValid(values, errors)}
                            onClick={e => {
                              e.preventDefault();
                              if (values.requestType.label !== NEW_INITIATIVE) {
                                this.props.handleSubmit(values);
                              } else {
                                if (this.state.activeTab === 4) {
                                  this.props.handleSubmit(values);
                                  this.toggleTab(1);
                                } else {
                                  this.toggleTab(this.state.activeTab + 1);
                                }
                              }
                            }}
                          >
                            {values.requestType.label === NEW_INITIATIVE &&
                            this.state.activeTab < 4
                              ? this.props.t("Next").toUpperCase()
                              : isEdit
                              ? this.props.t("Update").toUpperCase()
                              : this.props.t("Create").toUpperCase()}
                          </button>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

RequestModal.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any,
  handleSubmit: PropTypes.func,
  org: PropTypes.any,
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  toggle: PropTypes.func,
  orgMembers: PropTypes.array,
  request: PropTypes.object,
  files: PropTypes.array,
  onFilesAccepted: PropTypes.func,
  entityLookups: PropTypes.any,
};

export default withTranslation()(RequestModal);
