import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import "./project-level.css";
import Loading from "pages/Loading";
import { CURRENT_LANG } from "common";

const ServerSideSelect = props => {
  const {
    defultVal = "Select project",
    data = [],
    onChange,
    t,
    onLoadMore,
    isListLoading,
    totalRecordCount,
  } = props;

  const [showList, setShowList] = useState(false);
  const [selectVal, setSelectVal] = useState(defultVal);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const toggelList = () => setShowList(!showList);

  const onOptionSelected = option => {
    toggelList();
    const optionName = CURRENT_LANG === "ar" ? option.labelAr :option.label
    setSelectVal(optionName);
    onChange(option);
  };
  const onLoadMoreClick = ()=>{
    setIsLoadingList(true)
    onLoadMore()
  }

useEffect(() => {
  setIsLoadingList(false)
},[data]);

const substringText = (str , limit) =>{
if(str.length > limit) str = str.substring(0,limit)+"..";
return str
}

return (
    <>
      <Button
        className="db-btn text-nowrap form-select"
        onClick={toggelList}
        title={selectVal}
      >
        {" "}
      {substringText(selectVal,22)}  
      </Button>
      {/* server-side-options */}
      <ul className={showList ? "dropdown-menu  d-block" : "dropdown-menu "}>
        {data.map(option => (
          <li key={option.value}>
            <a
              className="dropdown-item"
              onClick={() => onOptionSelected(option)}
              title={CURRENT_LANG === "ar" ? option.labelAr :option.label}
            >
              {substringText(CURRENT_LANG === "ar" ? option.labelAr :option.label , 30)}

    
            </a>
          </li>
        ))}
        <li>
           {isListLoading? <Loading height={"100px"} noPadding hideText /> : 
          totalRecordCount > data.length && (
            <button
              className="m-3 btn btn-primary btn-load-more"
              onClick={onLoadMoreClick}
            >
              {t("Load more")}
            </button>
          )
          
       } 
        </li>
      </ul>
    </>
  );
};

ServerSideSelect.propTypes = {
  data: PropTypes.any,
  defultVal: PropTypes.any,
  onChange: PropTypes.func,
  t: PropTypes.any,
  onLoadMore: PropTypes.func,
  isListLoading: PropTypes.bool,
  totalRecordCount: PropTypes.any,
};
export default ServerSideSelect;
