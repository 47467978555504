import React, { Component } from "react";
import { Button, InputGroup } from "reactstrap";
import PropTypes from "prop-types";
import { Colors } from "common";

class Search extends Component {
  render() {
    return (
      <div
        style={
          !!this.props.containerStyle
            ? this.props.containerStyle
            : { marginBottom: 10 }
        }
      >
        <label
          className="visually-hidden"
          htmlFor="inlineFormInputGroupApprovalSearch"
        >
          {this.props.placeholder}
        </label>
        <InputGroup>
          {this.props.showIcon && (
            <div
              style={{
                backgroundColor: Colors.searchBG,
                display: "flex",
                alignItems: "center",
                paddingInlineStart: 10,
                border: 0,
              }}
            >
              <i className="bx bx-search"></i>
            </div>
          )}
          <input
            type="text"
            className="form-control"
            id="inlineFormInputGroupApprovalSearch"
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
            style={this.props.style}
          />
{this.props.hasBtn && 
            <Button className="btn-light-gray" onClick={this.props.onSearchBtnClick}>
            <i className="bx bx-search"></i>
              </Button> 
  }
        </InputGroup>
      </div>
    );
  }
}

Search.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  containerStyle: PropTypes.any,
  style: PropTypes.any,
  showIcon: PropTypes.bool,
  hasBtn :PropTypes.bool,
  onSearchBtnClick: PropTypes.func,
};

export default Search;
