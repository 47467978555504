import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container } from "reactstrap";
import "./requests.css";

import { withTranslation } from "react-i18next";
import { ENTITIES, TOAST_OPTIONS } from "common";
import RequestModal from "./RequestModal";
import { getRequests, uploadFiles, upsertRequest, updateRequestStatusCode } from "store/actions";
import {
  filterPermittedEntities,
  formatLineBreaksInMarkdownFriendlyFormat,
} from "common/utils";
import { toast } from "react-toastify";
import CustomTableWithServerSide from "../../components/Common/CustomTableWithServerSide";


class AllRequests extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const createEntities = filterPermittedEntities(props.permissions, "create");
    let searchValue=''
    // if reqSearch = RequestTypeCode , return the requestType name 
    if(props.reqSearch !== ''){
      searchValue= this.mapRequestTypNumberWithText(props.reqSearch)
    }
    this.state = {
      request: "",
      modal: false,
      files: [],
      deleteModal: false,
      createEntities,
      searchText:searchValue|| ''
    };
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleRequestCreate = this.handleRequestCreate.bind(this);
    this.updateRequestStatusCode = this.updateRequestStatusCode.bind(this);
  }
  mapRequestTypNumberWithText=(text)=>{
    if(text == "100000000") return 'طلب مشروع'
    if(text == "100000001") return  'إقتراح'
    if(text == "100000002") return 'طلب مستند'
    if(text == "100000003") return 'فكرة'
    if(text == "100000004") return 'طلب عام'
    if(text == "100000005") return 'مبادرة جديدة'
    if(text == "100000006") return 'مبادرة موجودة'
    return text
  }
  updateRequestStatusCode(option) {
    this.props.onUpdateStatusCode(option.value)
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleRequestClciks = () => {
    this.setState({ request: "", isEdit: false });
    this.toggle();
  };
  handleRequestSearch = (text) => {
    this.props.getRequestWithSearch(text)
  };
  handleRequestCreate(values) {
    const { onAddNewRequest, onGetRequests, onUploadFiles } = this.props;
    let request = {
      subject: values.subject,
      idProject:
        values.idProject?.value || "00000000-0000-0000-0000-000000000000",
      priority: values.priority?.value,
      requestType: values.requestType?.value,
      description: formatLineBreaksInMarkdownFriendlyFormat(values.description),
      idOrganization: values.idOrganization?.value,
      owner: values.assignedTo?.value || "00000000-0000-0000-0000-000000000000",
      idDepartment:
        values.department?.value || "00000000-0000-0000-0000-000000000000",
      problemStatement: formatLineBreaksInMarkdownFriendlyFormat(
        values.problemStatement
      ),
      initiativeScope: formatLineBreaksInMarkdownFriendlyFormat(
        values.initiativeScope
      ),
      businessImpact: formatLineBreaksInMarkdownFriendlyFormat(
        values.businessImpact
      ),
      keyStakeholders: formatLineBreaksInMarkdownFriendlyFormat(
        values.keyStakeholders
      ),
      benefits: formatLineBreaksInMarkdownFriendlyFormat(values.financial),
      assumptions: formatLineBreaksInMarkdownFriendlyFormat(values.assumptions),
      mitigation: formatLineBreaksInMarkdownFriendlyFormat(values.mitigation),
      resources: formatLineBreaksInMarkdownFriendlyFormat(values.resources),
      deliverables: values.deliverables,
      risks: formatLineBreaksInMarkdownFriendlyFormat(values.risks),
      duedate:values.duedate,
    };

    onAddNewRequest(request, id => {
      const getAllReqPayload={
      statusCode: this.props.requestStatusCode,
      pageNumber: 1,
      pageCount: 10,
      searchString:''
      }
      if (this.state.files.length > 0) {
        const payload = {
          request: {
            updates: [],
            allowExternalUsers: false,
            allowDownloads: true,
            allowComments: true,
            createReference: true,
            path: `requests/${id}`,
            parent: "00000000-0000-0000-0000-000000000000",
          },
          files: this.state.files,
        };
        onUploadFiles(payload, () => {
         onGetRequests(getAllReqPayload);
        });
      } else {
        onGetRequests(getAllReqPayload);
      }

      toast.success(
        this.props.t("SuccessAdd").replace("*", this.props.t("Request")),
        TOAST_OPTIONS
      );
    });
    this.toggle();
  }

  handleRequestClick = arg => {
    const request = arg;

    this.setState({
      request: {
        id: request.id,
        name: request.name,
        designation: request.designation,
        email: request.email,
        tags: request.tags,
        projects: request.projects,
      },
      isEdit: true,
    });

    this.toggle();
  };

  
  render() {
    const { requests, listStatus, requestStatusCode } = this.props;
    const { createEntities } = this.state;
    const selectedRequestStatusValue = listStatus.find(status => status.value === requestStatusCode)
    const formattedRequestStatusValue = selectedRequestStatusValue && {...selectedRequestStatusValue, 
      label: `${this.props.t("Status")}: ${selectedRequestStatusValue.label}`, 
      labelAr: `${this.props.t("Status")}: ${selectedRequestStatusValue.labelAr}`}
    const  searchText= this.props.reqSearch ? this.state.searchText : ""
    return (
      <React.Fragment>
        <Container fluid className="g-0">
          <Col lg="12">
            <Card>
              <CardBody>
                <CustomTableWithServerSide
                  columns={this.props.requestsColumns}
                  data={requests}
                  showSearchAndAdd
                  addBtnText={this.props.t("Add Request")}
                  placeholderText={this.props.t("reqSearchPlaceHolder")}
                  handleAddClick={this.handleRequestClciks}
                  hideAddBtn={!createEntities.includes(ENTITIES.REQUEST)}
                  defaultSortedField="createdOn"
                  dropdownFilterOptions={listStatus}
                  dropdownFilterValue={formattedRequestStatusValue}
                //  handleDropdownFilterChange={this.updateRequestStatusCode}
                  handleDropdownFilterChange={(val)=>this.props.updateStatus(val)}
                  onPageChange={this.props.handlePageChange}
                  currentPage={this.props.currentPageIndex}
                  totalDataSize={this.props.totalDataSize}
                  serverSearch={true}
                  onSearchBtnClick={this.handleRequestSearch}
                  searchVal={searchText}
                  tblRefresh={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Container>

        <RequestModal
          modal={this.state.modal}
          toggle={this.toggle}
          handleSubmit={this.handleRequestCreate}
          files={this.state.files}
          onFilesAccepted={files => {
            this.setState({ files });
          }}
          entityLookups={this.props.entityLookups?.[ENTITIES.REQUEST]}
        />
      </React.Fragment>
    );
  }
}

AllRequests.propTypes = {
  requests: PropTypes.array,
  onGetTasks: PropTypes.func,
  t: PropTypes.any,
  className: PropTypes.string,
  onAddNewRequest: PropTypes.func,
  onGetRequests: PropTypes.func,
  entityLookups: PropTypes.any,
  permissions: PropTypes.any,
  onUploadFiles: PropTypes.func,
  requestsColumns: PropTypes.any,
  listStatus: PropTypes.array,
  requestStatusCode: PropTypes.any,
  onUpdateStatusCode: PropTypes.func,
  handlePageChange : PropTypes.func,
  totalDataSize:PropTypes.number,
  currentPageIndex:PropTypes.number,
  pageCount:PropTypes.number,
  getRequestWithSearch:PropTypes.func,
  reqSearch:PropTypes.string,
  updateStatus:PropTypes.func,

};

const mapStateToProps = ({ requests, Layout }) => ({
  requests: requests.requests,
  entityLookups: Layout.entityLookups,
  permissions: Layout.permissions,
  requestStatusCode: requests.requestStatusCode,
});

const mapDispatchToProps = dispatch => ({
  onAddNewRequest: (newRequest, callback) =>
    dispatch(upsertRequest(newRequest, callback)),
  onGetRequests: payload => dispatch(getRequests(payload)),
  onUploadFiles: (payload, callback) =>
    dispatch(uploadFiles(payload, callback)),
  onUpdateStatusCode: (payload) => dispatch(updateRequestStatusCode(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AllRequests)));

// export default withTranslation()(withRouter(AllRequests));
