import {
  brandDarkColors,
  calendarColors,
  CURRENT_LANG,
  customLocalStorage,
  ENTITIES,
  EXTERNAL_LABEL,
  INTERNAL_LABEL,
  PERMISSIONS,
  USER_TYPES,
} from "common";
import { handleLogoutFromServer } from "../helpers/backend_helper";
import moment from "moment";
import store from "store";
import { setLoadingBarProgress } from "store/actions";

var special = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
  "Fifteenth",
  "Sixteenth",
  "Seventeenth",
  "Eighteenth",
  "Nineteenth",
];
var deca = [
  "Twent",
  "Thirt",
  "Fort",
  "Fift",
  "Sixt",
  "Sevent",
  "Eight",
  "Ninet",
];

export function stringifyNumber(n: number) {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
}

export function getQueryVariable(variable: any) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getFileIcon(name?: string) {
  if (
    !name ||
    typeof name !== "string" ||
    !name.trim() ||
    !name.includes(".")
  ) {
    return "mdi mdi-file font-size-20 text-secondary";
  }
  const fileType = name.split(".")[name.split(".").length - 1]?.toLowerCase();
  switch (fileType) {
    case "xls":
    case "xlsx":
      return "mdi mdi-microsoft-excel text-success font-size-20";

    case "doc":
    case "docx":
      return "mdi mdi-microsoft-word text-primary font-size-20";

    case "ppt":
    case "pptx":
      return "mdi mdi-microsoft-powerpoint text-danger font-size-20";

    case "pdf":
      return "mdi mdi-file-pdf text-danger font-size-20";
    case "png":
    case "jpeg":
    case "jpg":
      return "mdi mdi-file-image text-danger font-size-20";
    default:
      return "mdi mdi-file font-size-20 text-secondary";
  }
}

export function handleCopyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // @ts-expect-error: Split does not exist on type  ArrayBuffer
    reader.onload = () => resolve(reader?.result?.split(",")[1]);
    reader.onerror = error => reject(error);
  });

export const getPlannedProgress = (startDate?: Date, endDate?: Date) => {
  if (!startDate || !endDate) {
    return 0;
  }
  const today = new Date();
  const daysFromStart = moment(today).diff(startDate, "days")
  const durationInDays = moment(endDate).diff(startDate, "days")
  const value =  (daysFromStart / durationInDays) * 100;
  return value > 100 ? 100 : value < 0 ? 0 : value;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const randomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const randomBrandDarkColor = () => {
  return brandDarkColors[Math.floor(Math.random() * brandDarkColors.length)];
};

export const randomEventColorGroup = () =>
  calendarColors[Math.floor(Math.random() * calendarColors.length)];

export const getCurrentFileTree = (
  path: Array<any> = [],
  files: Array<any> = []
) => {
  if (!path || !files) {
    return [];
  }
  let filteredFiles = [];
  if (path.length <= 2) {
    filteredFiles = files.filter(file => !!!file.parentDocument);
    return filteredFiles;
  }
  let currentFolderName = path[path.length - 1];
  filteredFiles = files.filter(
    file =>
      file.parentDocument && file.parentDocument.label === currentFolderName
  );
  return filteredFiles;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLookupValue = (
  value: any,
  lookups: Array<any> = [],
  isMulti = false
) => {
  if (
    value === null ||
    value === undefined ||
    lookups === null ||
    lookups === undefined ||
    !Array.isArray(lookups) ||
    lookups.length == 0
  ) {
    return null;
  }
  if (isMulti) {
    if (!Array.isArray(value) || lookups.length === 0) {
      return [];
    }
    let values = value.map(_value => _value.value);
    return lookups.filter(option => values.includes(option.value));
  } else {
    let mappedRegardings: Array<any> = [];
    if ("options" in lookups[0]) {
      lookups.forEach(item => {
        mappedRegardings = mappedRegardings.concat(item.options);
      });
    }

    if (mappedRegardings.length > 0) {
      let indexOfValue = mappedRegardings.findIndex(
        item => item.value == value
      );
      return indexOfValue >= 0 ? mappedRegardings[indexOfValue] : null;
    } else {
      let indexOfValue = lookups.findIndex(item => item.value == value);
      return indexOfValue >= 0 ? lookups[indexOfValue] : null;
    }
  }
};

export const getMappedRegarding = (array: Array<any> = []) => {
  let mappedArray = array.map(item => {
    return {
      label: CURRENT_LANG === "en" ? item.displayName : item.displayNameAr,
      options: Array.isArray(item.listData) ? item.listData : [],
    };
  });
  return mappedArray;
};

export const mapPermissionsToObject = (permissions: Array<any> = []) => {
  if (!permissions || !Array.isArray(permissions)) {
    return null;
  }
  let obj: any = {};
  permissions.forEach(item => {
    if (
      !(
        item.createPermissionLevel?.label?.toUpperCase() === PERMISSIONS.NONE &&
        item.deletePermissionLevel?.label?.toUpperCase() === PERMISSIONS.NONE &&
        item.readPermissionLevel?.label?.toUpperCase() === PERMISSIONS.NONE &&
        item.updatePermissionLevel?.label?.toUpperCase() === PERMISSIONS.NONE
      )
    ) {
      obj[item.entityname] = {
        ...item,
      };
    }
  });
  return obj;
};

export const filterProtectedRoutes = (
  permissions: any = {},
  routes: Array<any> = []
) => {
  if (!routes || !Array.isArray(routes)) {
    return [];
  }
  if (
    !permissions ||
    typeof permissions !== "object" ||
    Object.keys(permissions).length === 0
  ) {
    return routes;
  }
  let filteredRoutes = routes.filter(route => {
    if (!!route.entities) {
      for (let entity of route.entities) {
        if (
          permissions[entity] &&
          permissions[entity].readPermissionLevel.label.toUpperCase() !==
            PERMISSIONS.NONE
        ) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  });
  return filteredRoutes;
};

export const filterPermittedEntities = (permissions = {}, type = "read") => {
  if (
    !permissions ||
    typeof permissions !== "object" ||
    Object.keys(permissions).length === 0
  ) {
    return [];
  }
  if (
    type !== "read" &&
    type !== "create" &&
    type !== "update" &&
    type !== "delete"
  ) {
    return [];
  }
  let array = [];
  for (let key in permissions) {
    if (
      // @ts-expect-error
      permissions[key]?.[`${type}PermissionLevel`] &&
      // @ts-expect-error
      permissions[key]?.[`${type}PermissionLevel`]?.label?.toUpperCase() !==
        PERMISSIONS.NONE
    ) {
      array.push(key);
    }
  }
  return array;
};

export const sortCaretIcons = (order?: "asc" | "desc", column?: any) => {
  if (!order)
    return (
      <>
        <i className="bx bx-chevron-up font-size-14"></i>
        <i
          className="bx bx-chevron-down font-size-14"
          style={{ marginInlineStart: -5 }}
        ></i>
      </>
    );
  else if (order === "asc")
    return <i className="bx bx-chevron-up font-size-14"></i>;
  else if (order === "desc")
    return <i className="bx bx-chevron-down font-size-14"></i>;
  return null;
};

export const serializeObjectToUrl = (obj: any = {}) => {
  if (!obj || typeof obj !== "object" || Object.keys(obj).length === 0) {
    return "";
  }
  let str = [];
  for (let key in obj)
    if (!!obj[key]) {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }
  return str.join("&");
};

export const handleLogout = async () => {
  let userType = JSON.parse(customLocalStorage.getItem("userType"));
  let nextUrl =
    userType === USER_TYPES.EXTERNAL_USER
      ? "/login-external"
      : "/login-internal";
  const response = await handleLogoutFromServer();
  localStorage.clear();
  window.location.replace(window.location.origin + nextUrl);
  
  return;
};

export const handleLogoutForInvalidAccess = () =>{
  let userType = JSON.parse(customLocalStorage.getItem("userType"));
  let nextUrl =
    userType === USER_TYPES.EXTERNAL_USER
      ? "/login-external"
      : "/login-internal";
  localStorage.clear();
  window.location.replace(window.location.origin + nextUrl);
  return;
}


export const shortenString = (str: string, length: number) => {
  if (
    !str ||
    typeof str !== "string" ||
    !length ||
    typeof length !== "number"
  ) {
    return "";
  }
  if (str.length > length) {
    return str.substring(0, length) + "...";
  } else {
    return str;
  }
};

/**
 * It returns true if the document's body has a dir attribute with a value of rtl
 */
export const isRTL = () => document.body.getAttribute("dir") === "rtl";

export const getLoadingText = (index: number, currentText: string) => {
  let arWords = [
    "كُن جميلًا",
    "خلك خُرافي",
    "في اللمسات الأخيرة",
    "يالله وصلنا",
  ];
  let enWords = [
    "Building Awesomeness",
    "Adding Rockstars",
    "Finishing Final Touches",
    "Almost There",
  ];
  let text = CURRENT_LANG === "en" ? enWords[index] : arWords[index];
  if (text === currentText) {
    if (index === enWords.length - 1) {
      return CURRENT_LANG === "en" ? enWords[index - 1] : arWords[index - 1];
    } else {
      return CURRENT_LANG === "en" ? enWords[index + 1] : arWords[index + 1];
    }
  } else {
    return text;
  }
};

export const getEntityDetailsRoute = (entityName: string, entityId: string) => {
  if (!entityName || !entityId) {
    return "";
  }
  let route = `?id=${entityId}`;
  if (entityName === ENTITIES.PROJECT) {
    route = `/project-details${route}`;
  } else if (entityName === ENTITIES.INDIVIDUALS) {
    route = `/individual-details${route}`;
  } else if (entityName === ENTITIES.TASK) {
    route = `/task-details${route}`;
  } else if (entityName === ENTITIES.REQUEST) {
    route = `/request-details${route}`;
  } else if (entityName === ENTITIES.APPOINTMENT) {
    route = `/calendar-details${route}`;
  } else if (entityName === ENTITIES.ORGANIZATION) {
    route = `/organization-details${route}`;
  } else {
    return "";
  }

  return route;
};

export const getMergedIndividualsLookup = (
  internalList: Array<any> = [],
  externalList: Array<any> = [],
  itemsFilteredOut: Array<any> = []
) => {
  if (
    !internalList ||
    !externalList ||
    !Array.isArray(internalList) ||
    !Array.isArray(externalList)
  ) {
    return [];
  }
  let filteredIds: Array<any> = [];
  if (!!itemsFilteredOut && Array.isArray(itemsFilteredOut)) {
    filteredIds = itemsFilteredOut.map(item => item.value);
  }

  let data: {
    label: string;
    options: Array<any>;
  }[] = [
    { label: INTERNAL_LABEL, options: [] },
    { label: EXTERNAL_LABEL, options: [] },
  ];
  internalList.forEach(item => {
    if (filteredIds.length === 0 || !filteredIds.includes(item.value)) {
      data[0].options.push(item);
    }
  });
  externalList.forEach(item => {
    if (filteredIds.length === 0 || !filteredIds.includes(item.value)) {
      data[1].options.push(item);
    }
  });
  return data;
};

export const getHeightAndWidthFromDataUrl = (dataURL: string) =>
  new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

export const beginTheBar = () => {
  let i = Math.floor(Math.random() * 40) + 10;
  store.dispatch(setLoadingBarProgress(i));
};

export const endTheBar = () => {
  store.dispatch(setLoadingBarProgress(100));
};

export const getGlobalSearchDataEnhanced = (data: Array<any>) => {
  if (!data || typeof data !== "object") {
    return [];
  }
  let filteredData = [];
  for (let key in data) {
    if (
      !!data[key].data &&
      Array.isArray(data[key].data) &&
      data[key].data.length > 0
    ) {
      filteredData.push(data[key]);
    }
  }
  return filteredData;
};

export const isIsoDate = (str: string) => {
  return (
    str.includes("-") &&
    str.includes("Z") &&
    str.includes("T") &&
    str.includes(":")
  ); // valid date
};

/**
 * @description A simple utility function that returns a string where all the default line breaks ('\n') with html br tags ('<br/>')
 * @param1 - boolean (flips the functionality turning <br/> tags into '\n')
 */
export const formatLineBreaksInMarkdownFriendlyFormat = (
  str: string,
  reversed?: boolean
) => {
  if (typeof str !== "string") return str;

  return reversed === true
    ? str?.replaceAll("<br/>", "\n")
    : str?.replaceAll("\n", "<br/>");
};

