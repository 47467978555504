import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";
// import logo from "../../assets/images/Day _logo_white.svg";
import logo from "../../assets/images/sta-logo.png";


export default class CarouselPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          xxl={4}
          style={{
            backgroundColor: "#004d25",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundColor: "#48bf53	",
              padding: 100,
              borderRadius: "50%",
            }}
          >
            <div
              style={{
                backgroundColor: "#11823b",
                padding: 100,
                borderRadius: "50%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#004d25",

                  padding: 100,
                  borderRadius: "50%",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  className="auth-logo-dark"
                  style={{ width: 300, height: 300 }}
                />
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}
