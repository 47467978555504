import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Field, Formik } from "formik";
import FormUpload from "pages/Forms/FormUpload";
import { withTranslation } from "react-i18next";
import { isInternal, UPLOAD_TYPES } from "common";
import CustomSelect from "components/Common/CustomSelect";
import FileManager from ".";

class UploadFileModal extends Component {
  constructor(props) {
    super(props);
    let uploadOptions = [
      { label: props.t("From Computer"), value: UPLOAD_TYPES.FROM_COMPUTER },
      { label: props.t("From Portal"), value: UPLOAD_TYPES.FROM_PORTAL },
    ];
    this.state = {
      uploadType: uploadOptions[0],
      uploadOptions,
      isFileSelected: false,
      selectedFile: null,
    };
    this.isValid = this.isValid.bind(this);
  }

  isValid() {
    const { files } = this.props;
    if (files.length === 0) {
      return false;
    }
    return true;
  }

  render() {
    const { file, path, files, onFilesAccepted, showUploadType, isComments } =
      this.props;
    return (
      <Modal
        isOpen={this.props.modal}
        className={this.props.className}
        size={showUploadType ? "xl" : "lg"}
      >
        <ModalHeader toggle={this.props.toggle} tag="h4">
          {this.props.t("File Upload")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: file ? file.name : "",
              isDownloadAllowed: file ? file.isDownloadAllowed : !isInternal,
              isCommentsAllowed: file ? file.isCommentsAllowed : !isInternal,
              isExternalAllowed: file ? !file.isInternalOnly : !isInternal,
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    {showUploadType && (
                      <div className="mb-3">
                        <Col lg="6">
                          <Label className="form-label day-required">
                            {this.props.t("Upload Type")}
                          </Label>
                          <CustomSelect
                            className="input"
                            onChange={value => {
                              this.setState({ uploadType: value });
                            }}
                            value={this.state.uploadType}
                            options={this.state.uploadOptions}
                          />
                        </Col>
                      </div>
                    )}
                    {showUploadType ? (
                      <>
                        <Label className="form-label day-required">
                          {this.props.t("Files")}
                        </Label>
                        {this.state.uploadType.value ===
                        UPLOAD_TYPES.FROM_COMPUTER ? (
                          <div className="mb-3">
                            <Col sm={12}>
                              <FormUpload
                                files={files}
                                onFilesAccepted={files => {
                                  onFilesAccepted(files);
                                }}
                                multiple={!!!isComments}
                              />
                            </Col>
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Col sm={12}>
                              <FileManager
                                height={"70vh"}
                                showUploadType
                                onRowClick={row => {
                                  this.setState({
                                    isFileSelected: true,
                                    selectedFile: row,
                                  });
                                }}
                              />
                            </Col>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mb-3">
                        <Col sm={12}>
                          <Label className="form-label day-required">
                            {this.props.t("Files")}
                          </Label>
                          <FormUpload
                            files={files}
                            onFilesAccepted={onFilesAccepted}
                            multiple
                          />
                        </Col>
                      </div>
                    )}
                    {this.state.uploadType.value ===
                      UPLOAD_TYPES.FROM_COMPUTER && (
                      <div className="mb-3">
                        <Row>
                          <Col
                            sm={4}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 30,
                            }}
                          >
                            <div className="form-check form-check-primary">
                              <Field
                                name="isExternalAllowed"
                                id="isExternalAllowed"
                                type="checkbox"
                                disabled={!isInternal}
                                as="input"
                                className={"form-check-input"}
                              />
                              <Label
                                className="form-label"
                                htmlFor="isExternalAllowed"
                              >
                                {this.props.t("Allow External")}
                              </Label>
                            </div>
                          </Col>

                          <Col
                            sm={4}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 30,
                            }}
                          >
                            <div className="form-check form-check-primary">
                              <Field
                                name="isDownloadAllowed"
                                id="isDownloadAllowed"
                                type="checkbox"
                                disabled={!isInternal}
                                as="input"
                                className={"form-check-input"}
                              />
                              <Label
                                className="form-label"
                                htmlFor="isDownloadAllowed"
                              >
                                {this.props.t("Allow Downloads")}
                              </Label>
                            </div>
                          </Col>

                          <Col
                            sm={4}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 30,
                            }}
                          >
                            <div className="form-check form-check-primary">
                              <Field
                                name="isCommentsAllowed"
                                id="isCommentsAllowed"
                                type="checkbox"
                                disabled={!isInternal}
                                as="input"
                                className={"form-check-input"}
                              />
                              <Label
                                className="form-label"
                                htmlFor="isCommentsAllowed"
                              >
                                {this.props.t("Allow Comments")}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="day-text-end">
                      <button
                        type="button"
                        className={`btn customTable-button save-task`}
                        disabled={
                          !!showUploadType
                            ? this.state.uploadType.value ===
                              UPLOAD_TYPES.FROM_COMPUTER
                              ? files.length === 0
                              : !this.state.isFileSelected
                            : !this.isValid()
                        }
                        // disabled={!this.state.isFileSelected}
                        // disabled={true}
                        onClick={e => {
                          e.preventDefault();
                          this.props.handleSubmit({
                            ...values,
                            uploadType: this.state.uploadType,
                            selectedFile: this.state.selectedFile,
                          });
                        }}
                      >
                        {isComments
                          ? this.props.t("Submit")
                          : this.props.t("Upload")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

UploadFileModal.propTypes = {
  file: PropTypes.any,
  t: PropTypes.any,
  className: PropTypes.string,
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  files: PropTypes.any,
  onFilesAccepted: PropTypes.func,
  path: PropTypes.array,
  showUploadType: PropTypes.bool,
  selectedExistingFile: PropTypes.any,
  isComments: PropTypes.bool,
};
export default withTranslation()(UploadFileModal);
