import React, { useEffect } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getLoadingText } from "common/utils";

let index = Math.floor(Math.random() * 4);
const Loading = ({ height, t, hideText , noPadding}) => {
  const [text, setText] = React.useState(getLoadingText(index));
  useEffect(() => {
    // create interval
    index = Math.floor(Math.random() * 4);
    const interval = setInterval(() => {
      index = Math.floor(Math.random() * 4);
      let _text = getLoadingText(index, text);
      setText(_text);
    }, 3000);

    // clean up interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <React.Fragment>
      <div className={noPadding?"":"page-content"}>
        <Container fluid>
          <div
            className="day-column day-justify-center day-align-items-center"
            style={{
              height: height ? height : "90vh",
            }}
          >
            <div
              className={
                "spinner-border text-primary " + (!hideText ? "m-1 mb-3" : "")
              }
              role="status"
            >
              <span className="sr-only">{t("Loading...")}</span>
            </div>
            {!hideText && <span className="day-light-grey">{text}</span>}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

Loading.propTypes = {
  height: PropTypes.string,
  t: PropTypes.any,
  hideText: PropTypes.bool,
  noPadding: PropTypes.any,
};

export default withTranslation()(Loading);
